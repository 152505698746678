import {UPLOAD_TRANSFER_LEFT_OVER,UPLOAD_TRANSFER_LEFT_OVER_TH,DISPATCH_ADD_RECEIPTS_TO_STORE,DISPATCH_GET_RECEIPTS_TO_STORE}from '../../contant/Gov/countEat/dispatch'
import { DISPATCH_ITEM_INFO_BY_USER_ID_TO_STORE } from '../../contant/Gov/softFood'

export const UploadTransferLeftOver=(item)=>{
  return{
    type:UPLOAD_TRANSFER_LEFT_OVER,
    payload:{item}
  }
}

export const UploadTransferLeftOverTh=(item)=>{
  return{
    type:UPLOAD_TRANSFER_LEFT_OVER_TH,
    payload:{item}
  }
}

export const DispatchAddReceiptsToStore=(item)=>{
  return{
    type:DISPATCH_ADD_RECEIPTS_TO_STORE,
    payload:{item}
  }
}

export const DispatchGetReceiptsToStore = (item)=>{
    return {
      type:DISPATCH_GET_RECEIPTS_TO_STORE,
      payload:{item}
    }
}

export const DispatchItemInfoByUserIdToStore=(item)=>{
  return{
    type:DISPATCH_ITEM_INFO_BY_USER_ID_TO_STORE,
    payload:{item}
  }
}