import React, { Component,Fragment } from 'react';
import {GROUP_USER} from "../../../../contant/index"
import style from './style'
import validate from './validate'
import {reduxForm,Field} from 'redux-form'
import {withStyles,Grid, DialogContent,DialogActions,Button} from '@material-ui/core'
import {connect} from 'react-redux'
import {bindActionCreators,compose} from 'redux'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import * as actionsUserPage from '../../../../actions/pageUser'
import renderSelectField from '../../../formHelper/Select/index'
import InfoUserForm from '../AddInfoUserForm/index'
import DepartmentFormStaff from '../AddDepartmentStaff/index'
import DepartmentFormVip from '../AddDepartmentVip/index'
import DepartmentFormPrivate from '../AddDepartmenPrivate/index'
import _ from 'lodash'

class index extends Component {
  constructor(props){
    super(props)
    this.state={
      titleForm:'Thêm mới tài khoản',
      groupUser:GROUP_USER,
      groupParent:[],
      exit_id:false,
      findValue:{},
      nameValueSelect:'',
      formComponent:'',
      title:'',
      idGroup:''      
    }
  }

  handleFormAdminGov =(id,InfoUser,number)=>{  
      if(id){   
           let findName = InfoUser.filter(item=>item._id===id)           
                  this.setState({
                  exit_id:true,
                  nameValueSelect:findName[0].name,
                  valueId:findName[0]._id,
                  idGroup:number
            })
          }       
  }

  handleFormVip =(id,InfoUser,number)=>{  
    if(id){   
         let findName = InfoUser.filter(item=>item._id===id)           
                this.setState({
                exit_id:true,
                nameValueSelect:findName[0].name,
                valueId:findName[0]._id,
                idGroup:number,               
          })
        }       
}

  handleFormStaff =(id,InfoUser,groupParent,number)=>{  
    if(id){   
         let findName = InfoUser.filter(item=>item._id===id)         
                this.setState({
                exit_id:true,
                nameValueSelect:findName[0].name,
                valueId:findName[0]._id,
                idGroup:number,
                groupParent:groupParent[0] //nhom cha trong cac phong ban
          })
        }       
}

  StateChangeButton=()=>{
  let {invalid,submitting} = this.props
  let {titleForm,nameValueSelect,valueId,idGroup,groupParent} = this.state 
  let  {exit_id}= this.state
  
  let xhtml =''
      if(exit_id===true &&(idGroup===5||idGroup===1)){
          return(<InfoUserForm 
                title={titleForm}
                name={nameValueSelect}
                valueId={valueId}
                
              />)
      }else if(exit_id===true&&idGroup===2){       
       return(<DepartmentFormStaff
              title={titleForm}
              name={nameValueSelect}
              valueId={valueId}  
              groupParent={groupParent}                          
          />
       )
      }else if(exit_id===true&&idGroup===3){
        return(<DepartmentFormVip
              title={titleForm}
              name={nameValueSelect}
              valueId={valueId}                         
        />
        )
      }else if(exit_id===true&&idGroup===4){
        return(<DepartmentFormPrivate
              title={titleForm}
              name={nameValueSelect}
              valueId={valueId}
        />
        )
      }
      else{
      xhtml= ( 
        <Button disabled={invalid||submitting}  color="primary" type="submit" variant="contained">
          Tiếp tục
        </Button>)
      }
  return xhtml
}

 handleSubmitForm=(value)=>{    
    let {QuanTri,NhaNuoc,NhanVien,TuNhan,Vip,listInfoUser,handleOpenCloseModal,listDepartment} = this.props 
    let {hideFormUser,donotAddForm} = handleOpenCloseModal  
        value.idGroup = +value.idGroup       
        if(value.idGroup ===1){                  
            let idTrung  =[]           
            QuanTri.map((item)=>listInfoUser.filter(result=>{
                if(result.userId===item._id){
                    idTrung.push(item)
                }     
                return true   
            }))                 
           let diff = _.difference(QuanTri,idTrung)    
           if(diff.length===0){       
            donotAddForm() 
            hideFormUser()
           }                 
          this.setState({
              titleForm:'Nhóm Quản trị',              
              groupUser:diff,
              title:'Quan tri',                     
          })    
          {this.handleFormAdminGov(value._id,QuanTri,1)}                                 
        }else if(value.idGroup ===5){          
          let idTrung    =[]           
          NhaNuoc.map((item)=>listInfoUser.filter(result=>{
                if(result.userId===item._id){
                     idTrung.push(item)
                }        
                return true               
            }))         
           let diff = _.difference(NhaNuoc,idTrung)        
            if(diff.length===0){                   
                donotAddForm()
                hideFormUser()
            }           
           this.setState({
            titleForm:'Nhóm Nhà nước',
            groupUser:diff,
            title:'Nha Nuoc'                
          })          
          {this.handleFormAdminGov(value._id,diff,5)}  
        
        }else if(value.idGroup ===2){
         // console.log('nhan vien')
          let idTrung=[]
          let findNameQuanTriWhenSelect = QuanTri.map(item=>
            listInfoUser.filter(result=>result.userId===item._id)
          )         
          NhanVien.map(item=>listDepartment.filter(result=>{
            if(result.userId===item._id){
              idTrung.push(item)
            }
            return true
          }))             
          let diff = _.difference(NhanVien,idTrung)        
            if(diff.length===0){       
                donotAddForm() 
                hideFormUser()
            }           
          this.setState({
            titleForm:'Nhóm Nhân Viên',
            groupUser:diff,
            title:'Nhân viên',
            groupParent:findNameQuanTriWhenSelect              
          })
         {this.handleFormStaff(value._id,diff,findNameQuanTriWhenSelect,2)}                       
          // //kiem tra ton tai _id trong Model department
        }else if(value.idGroup ===3){
         // console.log('vip')
          let idTrung=[]               
          Vip.map(item=>listDepartment.filter(result=>{
            if(result.userId===item._id){
              idTrung.push(item)
            }
            return true
          }))             
          let diff = _.difference(Vip,idTrung)        
            if(diff.length===0){       
                donotAddForm() 
                hideFormUser()
            }       
            //console.log(diff)    
          this.setState({
            titleForm:'Nhóm Vip',
            groupUser:diff,
            title:'Vip',                  
          })                 
          {this.handleFormVip(value._id,diff,3)}
           //kiem tra ton tai _id trong Model department
        }else if(value.idGroup ===4){
          //console.log('tu nhan')
          let idTrung=[]  
          TuNhan.map(item=>listDepartment.filter(result=>{
            if(result.userId===item._id){
              idTrung.push(item)
            }
            return true
          }))             
          let diff = _.difference(TuNhan,idTrung)        
            if(diff.length===0){       
                donotAddForm() 
                hideFormUser()
            }           
           // console.log(diff)
          this.setState({
            titleForm:'Nhóm Cá nhân',
            groupUser:diff,
            title:'Tư nhân',
          })           
          {this.handleFormVip(value._id,diff,4)} 
           //kiem tra ton tai _id trong Model deparment
        }    
  }

 renderSelectGroup =()=>{
    let {groupUser} = this.state
    let checkGroupUser = groupUser.map(item=>item._id)
      let xhtml = ''
          xhtml = (
            <Fragment>
                <Grid item md={12}>                
                  <Field
                      margin="dense"            
                      label={checkGroupUser[0]?"Tên account của quý khách":"Nhóm tài khoản"}
                      name={checkGroupUser[0]?"_id":"idGroup"}                      
                      type="text"
                      component={renderSelectField}                                        
                      fullWidth             
                    >     
                    <option/>                
                          {groupUser.map((item,index)=>{
                          return(
                          <option value={
                            item.value?item.value:item._id
                          }                     
                          key={index}>{item.value?' Tài khoản ' : ' '} 
                             {item.name}
                          </option>                       
                          )
                     })}
                    </Field>                      
                </Grid>               
            </Fragment>
          )
      return xhtml 
}

contentform = ()=>{
  let {handleOpenCloseModal,handleSubmit} = this.props           
  let {hideFormUser} = handleOpenCloseModal 
  let xhtml = ''
    xhtml = (
      <form onSubmit={handleSubmit(this.handleSubmitForm)} autoComplete="off">
            <DialogContent>
                  {this.renderSelectGroup()}
            </DialogContent>
            <DialogActions>           
            <Button onClick={hideFormUser} color="default" variant="contained" >
                 Hủy
            </Button>       
         {this.StateChangeButton()}
        </DialogActions>   
    </form>
    )
  return xhtml 
}

form = ()=>{
  let xhtml = ''
  let {handleOpenCloseModal,open,classes} = this.props    
  let {titleForm}  = this.state
  let {hideFormUser} = handleOpenCloseModal    
    xhtml = (
      <Dialog open={open} onClose={hideFormUser}  aria-labelledby="form-dialog-title">
            <div className={classes.bgcolor}>            
              <div className={classes.textCv}> {titleForm} </div>
              <CloseIcon className={classes.icon} onClick={hideFormUser}/>
            </div>
           {this.contentform()}
        </Dialog> 
    )
  return xhtml    
}

  render() {   
    return (
     <Fragment>
        {this.form()}
    </Fragment>                  
    )
 }
}

const mapStateToProps = state =>{
  return {   
    open:state.userPage.showFormUser,
    close:state.userPage.showForm,    
    listAllUser:state.userPage.listAllUser,
    NhaNuoc:state.userPage.listUserGroupNhaNuoc,
    NhanVien:state.userPage.listUserGroupNhanVien,
    QuanTri:state.userPage.listUserGroupQuanTri,
    TuNhan:state.userPage.listUserGroupTuNhan,
    Vip:state.userPage.listUserGroupVip,
    DoiTac:state.userPage.listUserGroupDoiTac,  
    listInfoUser:state.userPage.listAllInfoUser,
    listDepartment:state.userPage.listDepartment,
    GetData:state.userPage.GetData,//nhom nha nuoc
  }
}

const mapDispatchStateToProps = dispatch =>{
return{
  handleOpenCloseModal: bindActionCreators(actionsUserPage,dispatch)  
  }
}
const FORM_NAME = "FORM_ACCOUNT"
const withReduxForm = reduxForm({
  form:FORM_NAME,
  validate
})

const withConnect = connect(mapStateToProps,mapDispatchStateToProps)




export default compose(
withStyles(style),
withConnect,  
withReduxForm 
)(index)