import { DISPATCH_GET_INFO_CUS_KHACH_HANG_TO_STORE, GET_LIST_JOB_STAFF_TO_KHACH_HANG_TO_STORE,
DISPATCH_HOP_DONG_INVOICE_TO_KHACH_HANG_TO_STORE
} from "../../contant/LOGIN_PAGE/OpenNavBarKhachHang";

let initialState={
    ListJob:[],
    ListProduct:[],
    ListDetailProduct:[],
    ListJobStaff:[],
    ListInvoice:[]
}

 //const CREATE_SUCCESS = "Khởi Tạo Thành Công"
 //const UPDATE_SUCCESS = "Cập Nhật Thành Công"
// const UPDATE_FAIL    = "Cập Nhật Lỗi"
const IndexReducer =(state=initialState,action)=>{
            switch (action.type) {   
                case DISPATCH_HOP_DONG_INVOICE_TO_KHACH_HANG_TO_STORE:
                    console.log(action.payload.item.Invoice)
                    return{
                        ...state,
                        ListInvoice:action.payload.item.Invoice
                    }
                case GET_LIST_JOB_STAFF_TO_KHACH_HANG_TO_STORE:
                    return{
                        ...state,
                        ListJobStaff:action.payload.item
                    }
                case DISPATCH_GET_INFO_CUS_KHACH_HANG_TO_STORE:
                    return{
                        ...state,
                        ListJob:action.payload.item.ListJob,
                        ListProduct:action.payload.item.ListProduct,
                        ListDetailProduct:action.payload.item.ListDetailProduct
                    }

                default:   return state;
            }
}

export default IndexReducer