export const mmyy=(m,y)=>{
  let t1= {}
  let t2 ={}
  let t3 ={m,y}

  let a =[t1,t2]
   if(m===1){
    return( a=[
       t1={
        m:12,
        y:y-1
      },
      t2={
        m:m+1,
        y
      },
    t3
  ])
   }else if(m===12){
  return( a=[  t1={
       m:m-1,
       y
     },
     t2={
      m:1,
      y:y+1
     },
    t3])
   }else{
    return( a=[
      t1={
       m:m-1,
       y:y
     },
     t2={
      m:m+1,
      y:y
    },t3])
   }
}