import axiosServices    from '../../../../api-services/api'
import { API_ENDPOINT } from '../../../../contant'

const urlCreateZaloAppAccessTokenApi    = "CreateZaloAppAccessToken"
const urlGetAccessTokenApi              = "GetAccessToken"
const urlUpdateAccessTokenApi           = "UpdateAccessToken"


export const CreateZaloAppAccessTokenApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlCreateZaloAppAccessTokenApi}`,item)
}

export const GetAccessTokenApi=()=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetAccessTokenApi}`)
}

export const UpdateAccessTokenApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateAccessTokenApi}`,item)
}