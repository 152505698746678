import React from 'react'
import style from './style'
import {withStyles} from '@material-ui/core'
import loadingImg from '../../asset/img/loading2.gif'
import {connect} from 'react-redux'


class Uiloading extends React.Component{
    showLoading =() =>{
          let xhtml = null
           let {classes,loading} = this.props
           if(loading){
                xhtml=(
                  <div className={classes.loading}>
                    <img src={loadingImg} className={classes.loadingImg} alt="anhloading"/>
                 </div>
                )
              }
          return xhtml
    }

  render() {   
    return (
       this.showLoading()
    )
  }
}

const mapStateToProps = (state)=>{
return{
    loading:state.uiLoading.loading
 }
}

const mapDispatchStateToProps = null

export default withStyles(style)(connect(mapStateToProps,mapDispatchStateToProps)(Uiloading)) 