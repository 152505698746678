export const TO_CHUC ="tổ chức"
export const CA_NHAN = "cá nhân"
export const LABEL_ADDRESS_TO_CHUC = "Địa chỉ cơ quan"
export const LABEL_ADDRESS_CA_NHAN = "Địa chỉ nhà riêng"


//kiem tra ten dia chi so dt trung nhau thi loi
export const CheckExitNameToAddCustomer = (arr,item)=>{
   return arr.filter(v=>v.infoCustumer.name.toLowerCase()===item.infoCustumer.name.toLowerCase()&&
                    v.infoCustumer.addressPrimary.toLowerCase()===item.infoCustumer.addressPrimary.toLowerCase()&&
                    v.infoCustumer.phone===item.infoCustumer.phone
   )  
}

export const CheckExitNameToEditCustomer=(arr,currentItem,item)=>{
      let arrCustumerIsNotItem = CheckExitNameToUpdateCustomer(arr,currentItem)
      return CheckExitNameToAddCustomer(arrCustumerIsNotItem,item)

}


const CheckExitNameToUpdateCustomer = (arr,item)=>{
   return arr.filter(v=>v.infoCustumer.name.toLowerCase()!==item.infoCustumer.name.toLowerCase()&&
                    v.infoCustumer.addressPrimary.toLowerCase()!==item.infoCustumer.addressPrimary.toLowerCase()&&
                    v.infoCustumer.phone!==item.infoCustumer.phone
   )  
}

export const CheckExistBranch=(arr,item)=>{
   return arr.filter(v=>v.address.toLowerCase()===item.address.toLowerCase())
}

export const handleNewArrAddressDeparment=(arr)=>{
   return arr.map(v=>v.address)
}