import React from 'react'
import {DialogActions,DialogContentText,Button,Typography,DialogContent} from '@material-ui/core'

const Index=({fn,alertText,btn})=>{
  return (
  <DialogContent>
    <DialogContentText>
      <Typography component="span" color="secondary">{alertText}</Typography>
    </DialogContentText>
    <DialogActions>
        <Button color="secondary" variant="contained" onClick={()=>fn()}>{btn}</Button>
    </DialogActions>
  </DialogContent>
  )
}

export default Index