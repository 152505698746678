import _ from "lodash"
import { showDDMMYY, showYYMMDD } from "../../../../../../helper/dateTime/handleDMY"

export const HandleFindMeal=(arr,item)=>{       
        if(item===undefined)return null
        let find         = arr.filter(v=>showYYMMDD(v.dateTime)===showYYMMDD(item.dateTime))
        if(find.length!==0){
            let findMenu = find.filter(v=>v.menu!==null)
            if(findMenu.length!==0){
                    return findMenu[0]
            }
            return null
        } 
}

export const FindNameMaterail =(arr,item)=>{
        let result =arr.filter(v=>v._id===item)
        return result[0].nameMaterial
}

export const FilterByDateImMonthMeal=(arr,item)=>{
        return arr.filter(v=>showDDMMYY(v.dateTime)===item)
}

export const ConvertYYMMDD=(item)=>{
                let result = item.split('/')
                return `${result[2]}/${result[1]}/${result[0]}`
}

export const HandleMenuMeal=(menu)=>{
        //console.log(menu)
        if(menu!==null&&menu!==undefined){
                let splitSang = menu.sang.split(/,/)       
                if(splitSang.length===2){
                return [menu].map(v=>{
                        return{
                                ...v,
                                sang:splitSang[0].trim(),
                                sangPhu:splitSang[1].trim()
                        }
                })[0]
                }
                if(splitSang.length!==2){
                        return [menu].map(v=>{
                                return{
                                        ...v,
                                        sang:splitSang[0].trim(),
                                        sangPhu:""
                                }
                        })[0]
                }    
        }
       
}

export const HandleListMealStandard=(arr)=>{
      let filterArr = arr.filter(v=>v.numberEat!==0)
      let result = filterArr.map(v=>{
                let {materials,moneyEat,numberEat} = v
                let sumEat = moneyEat*numberEat               
                let sumMaterials = HandleMaterial(materials)
                if(sumEat===sumMaterials)return v                
       })
       return result.filter(v=>v!==undefined)
}

const HandleMaterial=(materials)=>{
      return materials.map(v=>v.mass*v.price/100).reduce((x,y)=>x+y,0)
}

export const FilterByItemMealAge =(arr,item)=>{
        if(item!==null&&item!==undefined){
              return arr.filter(v=>v.idTableInAge===item.idTableInAge&&v!==undefined&&(v.menu!==undefined||v.menu!==null))
        }
        //return arr.filter(v=>v!==undefined&&(v.menu!==undefined||v.menu!==null))
      
}

export const HandleListNutrikidMaterial=(arrNutrikid,arrMaterial)=>{       
        let ItemMealAge = arrNutrikid.map(v=>v.idTableInAge)
        let NewItemMealAge = [...new Set(ItemMealAge)]
        return NewItemMealAge.map(value=>{
                let ArrByMealAge = FilterByMealAge(arrNutrikid,value)
                let arrResult = HandleManyNutrikid(ArrByMealAge,arrMaterial,value)
                return {idTableInAge:value,
                        arrResult,
                }            
        })
}

const HandleManyNutrikid=(arr,arrMaterial)=>{
      let handleResult = arr.map(value=>{
               return HandleMaterials(value.materials,value,arrMaterial)               
        })
        let newArrIdName       = HandleNewArrIdName(_.flatten(handleResult))        
        return FilterByNameMaterial(newArrIdName,_.flatten(handleResult))       
}

const FilterByNameMaterial=(arrId,arrMaterial)=>{    
      return  arrId.map(v=>{
              let arrItem  = arrMaterial.filter(vv=>vv.idNameMaterial===v)
              let TBMass   = +(((arrItem.map(v=>v.mass).reduce((x,y)=>x+y,0))/arrItem.length).toFixed(2))
              let TBNumberEat = +(((arrItem.map(v=>v.numberEat).reduce((x,y)=>x+y,0))/arrItem.length).toFixed(2))                   
              let TB1Mass1Tre = +(((arrItem.map(v=>v.mass1Tre).reduce((x,y)=>x+y,0))/arrItem.length).toFixed(2))
              let TBTien1Tre  = +(((arrItem.map(v=>v.tien1Tre).reduce((x,y)=>x+y,0))/arrItem.length).toFixed(2))
              let TBPrice     = +(((arrItem.map(v=>v.price).reduce((x,y)=>x+y,0))/arrItem.length).toFixed(2))
              let TBMoneyEat  = +(((arrItem.map(v=>v.moneyEat).reduce((x,y)=>x+y,0))/arrItem.length).toFixed(2))
                return {arr:arrItem,
                        idNameMaterial:v,
                        nameMaterial:arrItem[0].itemMaterial.nameMaterial,
                        TBMass,
                        TBNumberEat,
                        TB1MassNumberEat:+((TBMass/TBNumberEat).toFixed(2)),
                        TB1Mass1Tre,
                        TBTien1Tre,
                        TBPrice,
                        TBMoneyEat
                }
        })
}

const HandleNewArrIdName=(arr)=>{
        let result = arr.map(v=>v.idNameMaterial)        
        return [...new Set(result)]
}

const FindNameMaterial=(id,arr)=>{                
        return arr.filter(v=>v._id===id)[0]
}

const HandleMaterials=(arr,item,arrMaterial)=>{
                return arr.map(v=>{
                        return{
                                ...v,
                                numberEat:item.numberEat,
                                moneyEat:item.moneyEat,                               
                                mass1Tre:+(((v.mass/item.numberEat).toFixed(2))),
                                tien1Tre:+(+((v.mass/item.numberEat*v.price/100)).toFixed(2)),
                                itemMaterial:FindNameMaterial(v.idNameMaterial,arrMaterial),
                                nameMaterial:FindNameMaterial(v.idNameMaterial,arrMaterial).nameMaterial,

                        }
                })
}

const FilterByMealAge=(arr,idTableInAge)=>{
        return arr.filter(v=>v.idTableInAge===idTableInAge)
}

