import {fork,call,put,take,delay,

  takeLatest} from 'redux-saga/effects'
import * as TypePageUser from '../../contant/pageUser'
import * as uiLoading from '../../actions/uiLoading'
import {getUser,addInfoUser,getInfoUser,listDepartment,getListDepartment,ResetPassword,ResetAccount} from '../../api-services/user'
import * as actionPageUser from '../../actions/pageUser'
import {STATUS_CODE} from '../../contant/index'
import {RESET_PASSWORD_ADMIN,RESET_ACCOUNT_PASSWORD,
  //ALERT_RESET_ACCOUNT
} from '../../contant/users'
import {ResetPassSuccess,AccountIsNotDefine,ResetAccountSuccess, 
  //AlertResetAccount
} from '../../actions/users'


function* getListUserGroup(){
    while(true){
      yield take(TypePageUser.SHOW_FORM_USER)
      const res   = yield call(getUser)
      const res_2 = yield call(getInfoUser) 
      const res_3 = yield call(getListDepartment)            
        yield put(actionPageUser.getListGroupNhaNuoc(res.data.data,res_2.data.data))
        yield put(actionPageUser.getListAllInfoUser(res_2.data.data))              
        yield put(actionPageUser.getAllListDepartment(res_3.data.data))             
    }
}

function* addFormInfoUserSaga({payload}){   
    yield put(uiLoading.loadingSuccess())
    yield delay(150)
    const res = yield call(addInfoUser,payload)    
    if(res.status===STATUS_CODE.SUCCESS){
      yield put(actionPageUser.addFormInfoUserSuccess())
    }else{
      yield put(actionPageUser.addFormInfoUserFail())
    }    
    yield put(uiLoading.loadingFail())
}

function* addFormDepartmentSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  yield delay(150) 
  const res = yield call(listDepartment,payload)
  if(res.status===STATUS_CODE.SUCCESS){
    yield put(actionPageUser.addFormInfoUserSuccess())
  }else {
    yield put(actionPageUser.addFormInfoUserFail())
  }
  yield put(uiLoading.loadingFail())
}

function* donotAddFormSaga() {
  yield take(TypePageUser.DO_NOT_ADD_FORM)
  yield put(actionPageUser.hideFormUser())
}

function* ResetPasswordAdminSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let item={userId:payload.item.userId}
  let res = yield call(ResetPassword,item)
  if(res.data.result="success"){
    yield put(ResetPassSuccess())
    yield put(uiLoading.loadingFail())
  }else{
    alert('Liên hệ với quản trị nếu đợi quá lâu')
  }  
}

function* ResetAccountPassowrdSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(ResetAccount,payload.data)
  if(res.data.result="success"){
    if(res.data.message==="fail"){
      yield put(AccountIsNotDefine())
    }
    if(res.data.message==="success"){
      yield put(ResetAccountSuccess())
    }
    yield put(uiLoading.loadingFail())
  }else{
    alert('Liên hệ với quản trị nếu đợi quá lâu')
  }  
}

function* pageUserSaga(){
    yield fork(getListUserGroup)
    yield takeLatest(TypePageUser.ADD_FORM_INFO_USER, addFormInfoUserSaga)
    yield takeLatest(TypePageUser.DO_NOT_ADD_FORM, donotAddFormSaga)
    yield takeLatest(TypePageUser.ADD_FORM_DEPARTMENT, addFormDepartmentSaga)
    yield takeLatest(RESET_PASSWORD_ADMIN,ResetPasswordAdminSaga)
    yield takeLatest(RESET_ACCOUNT_PASSWORD,ResetAccountPassowrdSaga)
 }

export default pageUserSaga