import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_ALERT } from '../../contant/GioiThieu';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Index() {
  const dispatch                    = useDispatch()
  const AlertGioiThieu              = useSelector(state=>state.GioiThieuWeb.AlertGioiThieu)
  const OpenAlert                   = useSelector(state=>state.GioiThieuWeb.OpenAlert)
  const ValueAlert                  = useSelector(state=>state.GioiThieuWeb.ValueAlert)
  const classes = useStyles();
  

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({type:CLOSE_ALERT})
  };

  return (
    <div className={classes.root}>      
      <Snackbar open={OpenAlert} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={ValueAlert==='fail'?'error':'success'}>
          {AlertGioiThieu}
        </Alert>
      </Snackbar>    
    </div>
  );
}
