import moment from 'moment'
import _ from 'lodash'


export const CheckDayFoodCoutEatForClassAccout=(date,FilterListStudent,filterArrCheckFood,arrSeven,ClassTmp)=>{
  let Num                         = numberDate(showMM(date),showYYYY(date))
  let sumTotalStudentEat          = handleFindSumStudentEat(FilterListStudent,Num,filterArrCheckFood,date,arrSeven,ClassTmp)
  let findSumCheckDayFoodInDay    = handleCheckDayFoodInDay(Num,filterArrCheckFood,date)  
  return {sumTotalStudentEat,findSumCheckDayFoodInDay}
 }

const ArrNumDate =(number)=>{
  let num =[]
    for (let i=1; i <= number; i++) {
      num.push(i)          
    }
return num
}

const showYYYY = (value)=>{
  return moment(value).local('vi').format('YYYY')
}

const showMM = (value)=>{
  return moment(value).local('vi').format('MM')
}

const handleMM = (m)=>{  
  let str = String(m)     
   if(str.length===1){
     return ('0'+m)      
   }else {
     return m
   }   
}

const showYYMMDD = (value)=>{
  return moment(value).local('vi').format('YYYY-MM-DD')
} 

const numberDate =(_m,_y)=>{
  let number = 0
  _m = +_m
  _y = +_y
  if((_m===1)||(_m===3)||(_m===5)||(_m===7)||(_m===8)||(_m===10)||(_m===12)){    
   return  number = 31
 }else if((_m===4)||(_m===6)||(_m===9)||(_m===11)){      
   return  number = 30
 }else if((_y%4===0)&&(_m===2)){    
   return number = 29
 }else if((_y%4!==0&&(_m===2))){   
   return number = 28
 }  
}

const showDateName = (value)=>{
  return moment(value).local('vi').format('dddd')
}

const handleCheckDayFoodInDay=(Num,arrCheckFood,date)=>{
  let resultSum = ArrNumDate(Num).map((i)=>{
    let DD       = handleMM(i)
    let MM       = showMM(date)
    let YYYY     = showYYYY(date)
    let ItemDate = showYYMMDD(`${YYYY}/${MM}/${DD}`)
    let FilterFindArrCheckFood = arrCheckFood.filter(va=>showYYMMDD(va.dateNow)===ItemDate)        
    if(FilterFindArrCheckFood.length===0||FilterFindArrCheckFood[0].nameStudentNotEat.length===0){
      return 0
    }
   let findItem = FilterFindArrCheckFood[0].nameStudentNotEat.length
   return findItem         
  })
  resultSum.push(resultSum.reduce((x,y)=>x+y,0))
  return resultSum
}

const handleFindSumStudentEat=(FilterListStudent,Num,arrCheckFood,date,arrSeven,ClassTmp)=>{
  
  return FilterListStudent.map(item=>{ 
    let name     = item.fullName
    let arrSum   = ArrNumDate(Num).map((i)=>{          
    let DD       = handleMM(i)
    let MM       = showMM(date)
    let YYYY     = showYYYY(date)
    let ItemDate = showYYMMDD(`${YYYY}/${MM}/${DD}`)
    let NameDate = 'thứ bảy'   
    if(ClassTmp.length!==0){
        let CheckStudentExist = ClassTmp[0].students.some(v=>v.idStudent===item._id&&v.active===true)
        if(CheckStudentExist){
          return 'TMP'//nghi tam thoi
        }
    }
      if(showDateName(ItemDate)===NameDate){
        if(arrSeven.length===0){
          return 0
        }
        if(arrSeven.length!==0){
          let check = _.first(arrSeven).nameStudentEatSevenDay.some(v=>v.idStudent===item._id)        
          if(check  !==true)return 0    
        }       
      }
      let FilterFindArrCheckFood         = arrCheckFood.filter(va=>showYYMMDD(va.dateNow)===ItemDate)  
      if(FilterFindArrCheckFood.length===0) return 0
      let findItemStudentNotEat          = FilterFindArrCheckFood[0].nameStudentNotEat.filter(value=>value.idStudent===item._id)
      if(FilterFindArrCheckFood.length!==0&&findItemStudentNotEat.length===0) return false
      if(FilterFindArrCheckFood.length!==0&&findItemStudentNotEat.length!==0) return true
   })         
    arrSum.push(arrSum.reduce((x,y)=>{
    if(x!=='TMP'&&y!=='TMP'){
      return x+y
     }}    
    ))
      let result={
        name,
        arrSum
      }
      return result       
    })
}

