import * as TypeTask from '../contant/task'
import {toastSuccess,toastError} from '../helper/toastHelper'
import * as notifi from '../contant/notification'

const initialState = {
  LiskTask:[],
  taskEditting:'',
  List:[]
}

const TaskReducers = (state=initialState,action)=>{
  switch (action.type){
    case TypeTask.TASK_LIST:
          return {
            ...state,
            LiskTask:[],
            List:[]            
          }
    
    case TypeTask.TASK_LOADING_SUCCESS:        
   //toastSuccess(notifi.NOTIFI_LOADING_SUCCESS)
      return {
        ...state,
        LiskTask:action.payload,
        List:[]
      }

    case TypeTask.TASK_LOADING_FALING:
      return{
        ...state,
        LiskTask:[],
        List:[]
      }
      
    case TypeTask.FILTER_TASK:
     // let {keyword} = action.payload     
      return{
        ...state,               
      }

      case TypeTask.FILTER_TASK_SUCCESS: 
           let {dataFilter} = action.payload          
          return{
            ...state,
            LiskTask:dataFilter,
            List:dataFilter
          }

      case TypeTask.ADD_TASK:
        return{
          ...state                    
        }

       case TypeTask.ADD_TASK_SUCCESS:     
       toastSuccess(notifi.NOTIFI_ADD_SUCCEESS)       
        return{
          ...state,
          LiskTask:[action.payload].concat(state.LiskTask),
          List:[action.payload].concat(state.LiskTask),
        }

        case TypeTask.ADD_TASK_FAIL:
        toastError(notifi.NOTIFI_FAIL)
        return{
          ...state
        }

      case TypeTask.TASK_EDITTING:        
        let {task} = action.payload
       // console.log(task) ok
        return{
          ...state,
          taskEditting:task
        }

      case TypeTask.UPDATE_TASK:                    
          return{
            ...state            
          }

      case TypeTask.UPDATE_TASK_SUCCESS:{   
        toastSuccess(notifi.NOTIFI_EDIT_SUCCESS)     
        let {data} = action.payload      
        let {LiskTask} = state       
        let index = LiskTask.findIndex(item=>item._id===data._id)            
        if(index!==-1){
            let newListTask =[
            ...LiskTask.slice(0,index),
            data,
            ...LiskTask.slice(index+1)
            ]
            return{
            ...state,         
            LiskTask:newListTask,
            List:newListTask            
          }
        }    
        return {...state }    
      }

      case TypeTask.UPDATE_TASK_FAIL:{
        return {
          ...state
        }
      }

      case TypeTask.DELETE_TASK:        
        return{...state }  

      case TypeTask.DELETE_TASK_SUCCESS:        
        let{data} = action.payload    
        toastSuccess(notifi.NOTIFI_DELETE_SUCCESS)    
        return{
          ...state,
          LiskTask:state.LiskTask.filter(item=>item._id!==data),
          List:state.LiskTask.filter(item=>item._id!==data)
        }
      
      case TypeTask.DELETE_TASK_FAIL:
        toastError(notifi.NOTIFI_FAIL)        
        return{...state}
      
  
    default:return state
  }
}

export default TaskReducers


