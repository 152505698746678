import {call,put,takeLatest} from 'redux-saga/effects'
import {ADD_CATEGORY,GET_CATEGORY,ADD_MATERIAL_MANAGER_REPO,UPDATE_MATERIAL_MANAGER_REPO,UDPATE_CATEGORY_MANAGER_REPO,ADD_MATERIALS_MANAGER_REPO,ADD_IMPORT_MATERIAL, GET_LIST_IMPORT_MATERIAL,DELETE_MATERIAL_MAMAGER_REPO, DELETE_CATEGORY_MATERIAL_REPO,
  UPDATE_IMPORT_MATERIAL,DELETE_IMPORT_MATERIAL, AUTO_IMPORT, GET_LIST_IMPORT_STAFF,
  LOGOUT,DDOS_ATTACK,UPDATE_ITEM_IMPORT_STAFF, UPDATE_AUTO_IMPORT
} from '../Contant'
import {AddCategoryApi,
  AutoImportApi,
   GetCategoryApi,AddMaterialApi,UpdateMaterialApi,UpdateCategoryApi,AddMaterialsApi,AddImportMaterialsApi,
  GetImportMaterialsApi,DeleteMaterialApi,DeleteCategoryApi,UpdateImportMaterialApi, DeleteImportMaterialApi,
 // AddAutoImportApi,
   GetListImportStaffApi, DdosAttackSagaApi, UpdateItemImportStaffApi, GetAutoImportApi, //AutoImportApi
} from '../Api'
import {LoadingNow,LoadingStop,OpenAlertSuccess} from '../../../../../../Actions/helper'
import {ALERT_TEXT_SUCCESS,ALERT_DELETE_SUCCESS} from '../../../../../../Contant/helperText'
import {DispatchItemAddCategoryToStore,DispatchListCategoryToStore,CloseUploadExcel,DispatchItemImportMaterial,DispatchGetListImportMaterialToStore, DispatchGetListImportStaffToStore,  
}from '../Action'
import {ArrListCateDetailByWeb} from './Fn'
import {push} from 'connected-react-router'
import {REDIRECT_LOGIN_PAGE} from '../../../../../../../contant/auth'


function* AddCategorySaga({payload}){
    yield put(LoadingNow())
    let res = yield call(AddCategoryApi,payload.item)
    if(res.data.result==="success"){
        yield put(LoadingStop())
        yield put(DispatchItemAddCategoryToStore(res.data.data))
    }else{
      alert('AddCategorySaga')
    }
}

function* AutoImportSaga({payload}){
  yield put(LoadingNow())     
    let res = yield call(GetAutoImportApi)    
     if(res.data.result==="success"){
         let {arrPhatDatAll,arrPhatDatSanpham} = res.data.data
         let result = ArrListCateDetailByWeb(arrPhatDatAll,arrPhatDatSanpham,payload.item)
         let {ItemAddCategory,
         // ItemAddAutoImport
        } =result
        //yield call(AddAutoImportApi,ItemAddAutoImport)
        yield call(AddCategoryApi,ItemAddCategory)   
            yield put(LoadingStop())      
            yield put(OpenAlertSuccess('dữ liệu tạo tự động thành công'))
      }
}

function* UpdateAutoImportSaga({payload}){
  yield call(AutoImportApi,payload.item)
  //console.log(res)
}

function* GetCategorySaga({payload}){
       yield put(LoadingNow())
       let res = yield call(GetCategoryApi,payload.item)       
       if(res.data.result==="success"){        
        yield put(LoadingStop())
        //console.log(res.data.data)
        yield put(DispatchListCategoryToStore(res.data.data))
    }else{
      alert('GetCategorySaga')
    }
}

function* AddMaterialSaga({payload}){
    yield put(LoadingNow())
    let res = yield call(AddMaterialApi,payload.item)
    if(res.data.result==="success"){
      yield put(LoadingStop())
      let item ={
        userId:res.data.data.userId
      }
      let res2 = yield call(GetCategoryApi,item)
      yield put(OpenAlertSuccess(ALERT_TEXT_SUCCESS))
      yield put(DispatchListCategoryToStore(res2.data.data))
  }else{
    alert('AddMaterialSaga')
  }
}

function* UpdateMaterialMangerRepoSaga({payload}){
  yield put(LoadingNow())
  let res = yield call(UpdateMaterialApi,payload.item[0])
  if(res.data.result==="success"){
    yield put(LoadingStop())   
    yield put(OpenAlertSuccess(ALERT_TEXT_SUCCESS))
  }else{
    alert('UpdateMaterialMangerRepoSaga')
  }
}

function* UpdateCategoryManagerRepoSaga({payload}){
  yield put(LoadingNow())
  let res = yield call(UpdateCategoryApi,payload.item[0])
  if(res.data.result==="success"){
    yield put(LoadingStop())   
    yield put(OpenAlertSuccess(ALERT_TEXT_SUCCESS))
  }else{
    alert('UpdateCategoryManagerRepoSaga')
  }
}

function* AddMaterialsManagerRepoSaga({payload}){
  yield put(LoadingNow())
  let res = yield call(AddMaterialsApi,payload.item)
  if(res.data.result==="success"){
    yield put(LoadingStop())   
    yield put(OpenAlertSuccess(ALERT_TEXT_SUCCESS))
    yield put(CloseUploadExcel())
   let item={
        userId:payload.item[0].userId
     }
    let res2 = yield call(GetCategoryApi,item)
    yield put(DispatchListCategoryToStore(res2.data.data))
  }else{
    alert('AddMaterialsManagerRepoSaga')
  }
}

function* AddImportMaterialSaga({payload}){
  yield put(LoadingNow())
  let res = yield call(AddImportMaterialsApi,payload.item)
  if(res.data.result==="success"){
    yield put(LoadingStop())   
    yield put(OpenAlertSuccess(ALERT_TEXT_SUCCESS))   
    yield put(DispatchItemImportMaterial(res.data.data))
  }else{
    alert('AddImportMaterialSaga')
  }
}

function* GetListImportMaterialSaga({payload}){
    yield put(LoadingNow())
    let res = yield call(GetImportMaterialsApi,payload.item)    
    if(res.data.result==="success"){
      yield put(LoadingStop())   
      //console.log(res.data.data)
      yield put(DispatchGetListImportMaterialToStore(res.data.data))
    }else{
      alert('GetListImportMaterialSaga')
    }
}

function* DeleteMaterialManagerRepoSaga({payload}){
    yield put(LoadingNow())
    let res = yield call(DeleteMaterialApi,payload.item[0])
    if(res.data.result==="success"){
      yield put(LoadingStop())   
    }else{
      alert('DeleteMaterialManagerRepoSaga')
    }
}

function* DeleteCategoryMaterialRepoSaga({payload}){
    yield put(LoadingNow())
    let res = yield call(DeleteCategoryApi,payload.item)   
    if(res.data.result==="success"){
      yield put(LoadingStop())   
    }else{
      alert('DeleteMaterialManagerRepoSaga')
    }
}

function* UpdateImportMaterialSaga({payload}){
  yield put(LoadingNow())
  let res = yield call(UpdateImportMaterialApi,payload.item)
  if(res.data.result==="success"){
    yield put(LoadingStop())   
    yield put(OpenAlertSuccess(ALERT_TEXT_SUCCESS))   
  }else{
    alert('UpdateImportMaterialSaga')
  }
}

function* DeleteImportMaterialSaga({payload}){
  yield put(LoadingNow())
  let res = yield call(DeleteImportMaterialApi,payload.item)
  if(res.data.result==="success"){
    yield put(LoadingStop())       
    yield put(OpenAlertSuccess(ALERT_DELETE_SUCCESS))   
  }else{
    alert('DeleteImportMaterialSaga')
  }
}

function* GetListImportStaffSaga({payload}){
  //console.log(payload)
    yield put(LoadingNow())
    let res = yield call(GetListImportStaffApi,payload.item) 
    //console.log(res)
    if(res.data.result==='success'){  
      yield put(DispatchGetListImportStaffToStore(res.data.data))
      yield put(LoadingStop())      
    }
}

function* LogoutSaga(){
    yield put(push(REDIRECT_LOGIN_PAGE))
}

function* DdosAttackSaga({payload}){
    yield call(DdosAttackSagaApi,payload.item)
}

function* UpdateItemImportStaffSaga({payload}){
  yield put(LoadingNow())
  let res = yield call(UpdateItemImportStaffApi,payload.item)
  if(res.data.result==='success'){
    yield put(LoadingStop())
  }
}


function* CategoriesMangerRepoSaga(){
  //viet tai day
  yield takeLatest(ADD_CATEGORY,AddCategorySaga)
  yield takeLatest(AUTO_IMPORT,AutoImportSaga)
  yield takeLatest(UPDATE_AUTO_IMPORT,UpdateAutoImportSaga)
  yield takeLatest(GET_CATEGORY,GetCategorySaga)
  yield takeLatest(ADD_MATERIAL_MANAGER_REPO,AddMaterialSaga)
  yield takeLatest(UPDATE_MATERIAL_MANAGER_REPO,UpdateMaterialMangerRepoSaga)
  yield takeLatest(UDPATE_CATEGORY_MANAGER_REPO,UpdateCategoryManagerRepoSaga)
  yield takeLatest(ADD_MATERIALS_MANAGER_REPO,AddMaterialsManagerRepoSaga)
  yield takeLatest(ADD_IMPORT_MATERIAL,AddImportMaterialSaga)
  yield takeLatest(GET_LIST_IMPORT_MATERIAL,GetListImportMaterialSaga)
  yield takeLatest(DELETE_MATERIAL_MAMAGER_REPO,DeleteMaterialManagerRepoSaga)
  yield takeLatest(DELETE_CATEGORY_MATERIAL_REPO,DeleteCategoryMaterialRepoSaga)
  yield takeLatest(UPDATE_IMPORT_MATERIAL,UpdateImportMaterialSaga)
  yield takeLatest(DELETE_IMPORT_MATERIAL,DeleteImportMaterialSaga)
  yield takeLatest(GET_LIST_IMPORT_STAFF,GetListImportStaffSaga)
  yield takeLatest(LOGOUT,LogoutSaga)
  yield takeLatest(DDOS_ATTACK,DdosAttackSaga)
  yield takeLatest(UPDATE_ITEM_IMPORT_STAFF,UpdateItemImportStaffSaga)
}



export default CategoriesMangerRepoSaga