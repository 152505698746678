import React from 'react';
//import { showYYYY } from '../../../../../../../../helper/dateTime/handleDMY';
import { MainContent, MainContent2, MainDiv } from './styled';

const Index = () => {
    //const CurrentDay = Date.now()
    return (
        <MainDiv>
            <MainContent>
              1500 USER ACCOUNT ĐĂNG KÝ-TOP 10 THƯƠNG HIỆU MẠNH ASEAN 2024
                {/* HAPPY WOMEN DAY {showYYYY(CurrentDay)} */}
           </MainContent>
           <MainContent2>
            {/* QUỐC TẾ PHỤ NỮ {showYYYY(CurrentDay)} */}
           </MainContent2>
        </MainDiv>        
    );
};

export default Index;