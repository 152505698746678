import { showDDMMYY } from '../../../../helper/dateTime/handleDMY'
import uuidv4 from 'uuid'
import {  updateArrByIdChamCong,DeleteItemByIdQrcode } from '../../../../helper/fnAllways'

export const handleChamCongToSaveDB=(ItemChamComg,chamCong)=>{   
        let filterByDate = ItemChamComg.chamCongs.filter(v=>showDDMMYY(v.dateTime)===showDDMMYY(chamCong.dateTime)&&chamCong.typeOf===v.typeOf&&v.numberCase===chamCong.numberCase)
                if(filterByDate.length===0){
                    let resultChamCong ={
                      id:uuidv4(),
                      typeOf:chamCong.typeOf,
                      dateTime:chamCong.dateTime,    
                      numberCase:chamCong.numberCase
                    }
                    return {
                      _id:ItemChamComg._id,
                      userId:ItemChamComg.userId,
                      idStaff:ItemChamComg.idStaff,
                      chamCongs:ItemChamComg.chamCongs.concat(resultChamCong)
                    }
              }

                         let update = filterByDate.map(v=>{
                           return{
                             ...v,
                             dateTime:chamCong.dateTime,                             
                           }
                         })[0]
                        return [ItemChamComg].map(v=>{
                           return{
                             ...v,
                             chamCongs:updateArrByIdChamCong(v.chamCongs,update)
                           }
                         })[0]              
    
}

export const DeleteBaoNghiChamCong=(arr,item)=>{    
    return  [arr].map(v=>{
        return{
          ...v,
          chamCongs:DeleteItemByIdQrcode(v.chamCongs,item)
        }
      })[0]
}