import * as TypeForm from '../contant/taskForm'

var initialState = {
  _id:'',
  title:'',
  component:null, 
  showForm:false
}

const formTask = (state=initialState,action)=>{
  switch (action.type) {
    case TypeForm.SHOW_FORM_TASK:
          return {
            ...state,
            showForm:true
          }

    case TypeForm.HIDE_FORM_TASK:
          return {
            ...state,
            _id:'',
            showForm:false,
            title:'',
            component:null
          }
    
    case TypeForm.CHANGE_FORM_TITLE:
          return{
            ...state,
            title:action.payload
          }
    
    case TypeForm.CHANGE_FORM_CONTENT:
          return{
            ...state,
            component:action.payload
          }
  
    default: return state
      
  }
}

export default formTask