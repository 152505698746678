import React from 'react';
import Logo from './huythanh.png'
import { MainTextSlide,ImgLogo } from './style';

const Index = () => {
    return (
        <MainTextSlide>
                <ImgLogo src={Logo}/>
        </MainTextSlide>
    );
};

export default Index;