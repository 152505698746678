import { DeleteItemById } from "../../../../../helper/fnAllways"

export const HandleSelectDepartment =(arrDepartment,itemDepartment)=>{
  let department = arrDepartment.taskJob.map(v=>{
    return {
      idDepartment:v.idDepartmentCustomer
    }
  })
  let result     = itemDepartment.map(v=>{
    let index    = department.findIndex(vv=>vv.idDepartment===v._id)
    if(index===-1){
      return{
        ...v
      }
    }
  })
  return result.filter(v=>v!==undefined)
}

export const NewItemProcessAfterDeleteDepartment=(arr,item)=>{
  return arr.map(v=>{
    return {
      ...v,
      taskJob:DeleteItemById(v.taskJob,item)
    }
  })[0]
}