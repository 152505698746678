import React, { useState } from 'react'
import {Grid,DialogContent,DialogActions,Button,TextField} from '@material-ui/core'
import {useStyles} from './style'
import * as TypePerson from '../../../../../contant/table/Person'
import {useDispatch} from 'react-redux'

 const FormEditGov = props =>{
    const {editForm,handleCloseEdit,handleAlertColorEdit,      
    }=props   
    const userId = editForm.userId   
    const infoUserId = editForm.infoUserId
    const [tel,setTel] = useState(editForm.tel)
    const [address,setAddress] = useState(editForm.address)
    const [email,setEmail] = useState(editForm.email)
    const [humanName,setHumanName] = useState(editForm.humanName)    
    const [avatar] = useState(editForm.avatar)  
    const [signature] = useState(editForm.signature)
    const dispatch = useDispatch()
    const submitFormEdit=e=>{
            e.preventDefault()
            let newData ={tel,address,email,humanName,userId,infoUserId,avatar,signature
            }
            //console.log(newData)
            dispatch({
              type:TypePerson.EDIT_PERSON,
              payload:{newData}
            })
            handleCloseEdit()
            handleAlertColorEdit(userId,'colorAlert')
    }
  const classes = useStyles()
    return (
      <>       
         <form onSubmit={submitFormEdit}
           autoComplete="off"
           >         
          <DialogContent>  
            <Grid container spacing={1}>                               
                  <Grid item md={6}>
                    <TextField
                        onChange={e=>setHumanName(e.target.value)}
                        autoFocus
                        margin="dense"      
                        label="Nhân viên"
                        name="humanName"                        
                        type="text"                     
                        fullWidth  
                        defaultValue={humanName}           
                      />       
                     {(humanName==='')?(<p className={classes.colorError}>Không được để trống</p>):''} 
                  </Grid>                  
                  <Grid item md={6}>
                    <TextField
                        onChange={e=>setTel(e.target.value)}
                        autoFocus
                        margin="dense"          
                        label="Số điên thoại"
                        name="tel"                       
                        type="text"                      
                        fullWidth   
                        defaultValue={tel}          
                      />       
                      {(tel==='')?(<p className={classes.colorError}>Không được để trống</p>):''} 
                  </Grid>                  
                  <Grid item md={6}>
                    <TextField
                        onChange={e=>setAddress(e.target.value)}
                        autoFocus
                        margin="dense"          
                        label="Địa chỉ"
                        name="address"                        
                        type="text"                      
                        fullWidth    
                        defaultValue={address}         
                      />       
                      {(address==='')?(<p className={classes.colorError}>Không được để trống</p>):''} 
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                        onChange={e=>setEmail(e.target.value)}
                        autoFocus
                        margin="dense"        
                        label="Email"
                        name="email"                        
                        type="email"                      
                        fullWidth      
                        defaultValue={email}       
                      />       
                      {(email==='')?(<p className={classes.colorError}>Không được để trống</p>):''} 
                  </Grid>    
                   
                  <Grid item md={6}>
                    <TextField
                        autoFocus
                        margin="dense"           
                        label="Ảnh đại diện"
                        name="avatar"                        
                        type="text"
                        value='chua nhap lieu'
                        fullWidth             
                      />       
                  </Grid>            
                  <Grid item md={6}>
                    <TextField
                        autoFocus
                        margin="dense"           
                        label="Chữ Ký"
                        name="signature"                        
                        type="text"
                         value='Chua nhap lieu'
                        fullWidth             
                      />       
                  </Grid>                  
               </Grid>       
          </DialogContent>
          <DialogActions>           
            <Button onClick ={handleCloseEdit} color="default" variant="contained" >
              Hủy
          </Button>
          
           {((humanName==='')||(tel==='')||(address==='')||(email==='')) ? (
             <Button  variant="contained" disabled>
              Lưu lại
           </Button>
           ):
           (
            <Button  color="primary" type="submit"  variant="contained">
              Lưu lại
            </Button>
           )
           }
          </DialogActions>   
        </form>    
     </>             
    )
  }

export default FormEditGov
 