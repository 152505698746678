import styled,{keyframes} from "styled-components";
import { MainColor } from '../../main/component/color'
import {device} from './device'

export const IconFooter = styled.a`

`

export const DivQrcode = styled.div`
    margin:auto;
    width:80px;
    height:auto;   
    transition:0.5s;
    margin-left:0;
`


export const DivQrcodeFooter = styled.a`
       margin-left:100px;
`

export const LineFooter =styled.div`
        @media ${device.mobile}{
            width:100%;
            height:3px;
            background:${MainColor};        
        }
`

export const gradient = keyframes`
    100% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    0% {
        background-position: 0% 0%;
    }
`

export const MainFooter=styled.div`
    width:100%;    
`

export const MainFooterUp=styled.div`
    padding:20px;
    background:#171616;
        transition-delay:2s;
        background: linear-gradient(315deg,#34495e 10%, rgba(0, 0, 0, 0.87) 38%, #3498db 68%, #212121 85%, rgba(0, 0, 0, 0.87) 98%);
        animation: ${gradient} 15s ease infinite;
        background-size: 200% 200%;
        background-attachment: fixed;
        color:white;
`

export const MainLeftUp = styled.div`
        margin-top:20px;
`

export const FooterImg=styled.img`
        height:120px;
`

export const MainRightUp = styled.div`

`
export const FooterLogo=styled.div`
    
`
export const MainFooterDown = styled.div`
    background:#545454;
`