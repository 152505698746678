import * as TypeGov from '../../../contant/Gov/softFood'
import {DISPATCH_ADD_DATE_MEAL_TO_STORE,DISPATCH_LIST_ALL_DATE_MEAL,DATE_NOW} from '../../../contant/Gov/student/dispatch'
import {toastSuccess,toastError} from '../../../helper/toastHelper'
import {getDateMeal,handleCheckExistDate, numberDateOfMonth, showDD, showYYYY} from '../../../helper/dateTime/handleDMY'
import { DONG_BO_THUC_DON_TUAN_MEAL } from '../../../ComponentGov/SoftFoodTabMain/Nutrikid/contant/nutrikid'
import { updateArrById } from '../../../helper/fnAllways'


const initialState = {
  DateInput:'',//xac dinh account nao dang truy cap vao tk nha nuoc
  MealInput:{},
  ListDateMeal:[],
  listDMY:[],
  ListDateMealUser:[],//sua dung mang nay de lay cac gia tri ra
  dateMealUser:[],//tap hop cac Nam cua 1 user
  dateMealUserM:[],//tap hop cac thang cua 1 user
  ListDateMealByUserId:[],
  ListDateMealMonthTable:[],
  showTableList:false,
  updateDateMeal:{},
  showMY:'',
  showPrint:false,
  ListWeekMeal:[],
  _M_Y:'',
  showFormPrint:false,
  ListAllDateMeal:[],
  DateMeal:null,
  DateNow:Date.now(),
  CheckExistDateMeal:null,
  CheckExistDateMealInput:null,
  //ItemDongBoMeal:null
}

const dateInputReducer = (state=initialState,action)=>{
    switch (action.type) {
       case TypeGov.GET_DATE_INPUT:                      
         return{
           ...state,
           DateInput:action.payload.DateInput,
           CheckExistDateMealInput:handleCheckExistDate(state.ListAllDateMeal,action.payload.DateInput)
         }   

        case TypeGov.LIST_DATE_MEAL:             
        return{
          ...state,
          ListDateMeal:action.payload.list
        }
                 
        case DISPATCH_LIST_ALL_DATE_MEAL:
          return{
            ...state,
            ListAllDateMeal:action.payload.arr,
            DateMeal:getDateMeal(state.ListAllDateMeal,state.DateNow),
            CheckExistDateMeal:handleCheckExistDate(state.ListAllDateMeal,state.DateNow),
            CheckExistDateMealInput:handleCheckExistDate(state.ListAllDateMeal,state.DateInput)
          }

        case DISPATCH_ADD_DATE_MEAL_TO_STORE:
          let newArr = state.ListAllDateMeal.concat(action.payload.item)
          return{
            ...state,
            ListAllDateMeal:state.ListAllDateMeal.concat(action.payload.item),
            DateMeal:getDateMeal(newArr,state.DateNow),
            CheckExistDateMeal:handleCheckExistDate(newArr,state.DateNow),
            ListDateMealByUserId:state.ListDateMealByUserId.concat(action.payload.item)
          }
        case DATE_NOW:
         return {
            ...state,
            DateNow:action.payload.date
          }

        //add
      //  case TypeGov.ADD_DATE_MEAL_DB:
      //       return{
      //         ...state,
      //         ListDateMealByUserId:(state.ListDateMealUser).concat(action.payload.data)              
      //       }  

        case TypeGov.ADD_DATE_MEAL_DB_SUCCESS:   
           toastSuccess('Thêm dữ liệu thành công')          
        return{
          ...state,
        }  

        case TypeGov.ADD_DATE_MEAL_DB_FAIL:   
           toastError('Lỗi, kiểm tra lại, hoặc liên hệ nhà cung cấp')
        return{
          ...state          
        }
        //add

        //insert
        case TypeGov.INSERT_DATE_MEAL_DB:         
          return{
            ...state,
            ListDateMealMonthTable:action.payload.value,
            ListDateMealByUserId:(state.ListDateMealUser).concat(action.payload.data)              
            //mang chua thang va nam
          }
        //insert

        //update
          case TypeGov.UPDATE_DATE_MEAL_DB:               
                  return {
                  ...state,
                  ListDateMealMonthTable:action.payload.list,//mang chua thang va nam
                  ListDateMealByUserId:action.payload.arrListAllUser.arr
                  }
              //}                       
         //update

         //update success
             
         //update success
        case TypeGov.LIST_DATE_MEAL_BY_USERID:         
          return{
            ...state,
            ListDateMealByUserId:action.payload.data
          }

          case TypeGov.LIST_DATE_MONTH_YEAR:        
          return{
            ...state,
            listDMY:action.payload.result
          }       

        case TypeGov.CHECK_WEEK_FOOD_USER_LOGIN:          
          return{
            ...state,
            ListDateMealUser:action.payload.result,
            dateMealUser:action.payload.dateMealUser,
            dateMealUserM:action.payload.dateMealUserM
          }    

        case TypeGov.LIST_DATE_MEAL_MONTH_TABLE:        
          return{
            ...state,
            ListDateMealMonthTable:action.payload.value        
         
          }
          case TypeGov.LIST_WEEK:                   
            return{
              ...state,
              ListWeekMeal:action.payload.ListWeekMeal
              }
          case TypeGov.SHOW_TABLE_LIST:
            return{
              ...state,
              showTableList:true,
              showMY:action.payload.showMY,
              _M_Y:action.payload._M_Y
            }

            case DONG_BO_THUC_DON_TUAN_MEAL:
            toastSuccess('Cập nhật thành công')
            let ItemDongBoMeal = action.payload.item
            let _m = +(showDD(ItemDongBoMeal.dateMenu))
            let _y = +(showYYYY(ItemDongBoMeal.dateMenu))
            let UpdateDateMealById = updateArrById(state.ListDateMealByUserId,ItemDongBoMeal)
            let UpdateDateMealTable = numberDateOfMonth(_m,_y,UpdateDateMealById)             
            
            return{
                ...state,
                ListDateMealByUserId:UpdateDateMealById,
                ListDateMealMonthTable:UpdateDateMealTable
            }
          
          
           
          
      default: return state      
    }
}

export default dateInputReducer