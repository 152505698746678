import React,{Suspense, useEffect,useState} from 'react';
import {Box, Button, Grid} from '@material-ui/core'
import {useSelector,useDispatch} from 'react-redux'
import {OPEN_DETAIL_INFO_GOV,CLOSE_DETAIL_INFO_GOV,OPEN_SETTING_ADVANCE,CLOSE_SETTING_ADVANCE,
OPEN_TEMPLETE,CLOSE_TEMPLETE,LIST_BY_USERID_TEMPLETE,SHOW_LIST_VIEW_TEMPLETE,CLOSE_SHOW_LIST_VIEW_TEMPLETE,
OPEN_FORM_SIGN_REGIS,
CLOSE_FORM_SIGN_REGIS,
//GET_FORM_SIGN_REGIS_BY_USERID,
OPEN_UPDATE_FORM_SIGN_REGIS,CLOSE_UPDATE_FORM_SIGN_REGIS
} from '../../../contant/Gov/login'
import {LIST_INFO_USER_DETAIL} from '../../../contant/Gov/login'
import InfoGov from './infoGov'
import OpenSettingForm from './SettingAdvance/index'
import FormTemplete from './Templete/index'
import ListViewTemplete from './Templete/ListRowView/index'
import FormSignRegisComponent from './FormSignRegis/index'
import FormUpdateRegis from './FormSignRegis/Update'
import SettingsIcon from '@material-ui/icons/Settings';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import GroupIcon from '@material-ui/icons/Group';
import TheatersIcon from '@material-ui/icons/Theaters';
import YouTuBeComponent from './youtube'

const Index=React.memo(()=>{
  const dispatch            = useDispatch()
  const [data,setData]      = useState(false)   
  //const userId              = useSelector(state=>state.GovLogin.userId)   
      // useEffect(() => {
      //   if(userId!==""){
      //      //return()=>{
      //      dispatch({
      //         type:GET_FORM_SIGN_REGIS_BY_USERID,
      //         payload:{item:{userId}}
      //       })
      //    }           
      // //  }       
      // },[])
        useEffect(() => {
            dispatch({
                type:LIST_BY_USERID_TEMPLETE
              })
            dispatch({
            type:LIST_INFO_USER_DETAIL
            })      
        },[data])
  const ItemFormSignRegis         = useSelector(state=>state.GovLogin.ItemFormSignRegis)
  const ItemGov                   = useSelector(state=>state.GovLogin.ListGov)
  const OpenInfoGov               = useSelector(state=>state.GovLogin.OpenInfoGov)
  const OpenSettingAdvance        = useSelector(state=>state.GovLogin.OpenSettingAdvance) 
  const OpenTemplete              = useSelector(state=>state.GovLogin.OpenTemplete)
  const OpenViewTemplete          = useSelector(state=>state.GovLogin.OpenViewTemplete)
  const OpenFormSignRegis         = useSelector(state=>state.GovLogin.OpenFormSignRegis)
  const OpenUpdateFormSignRegis   = useSelector(state=>state.GovLogin.OpenUpdateFormSignRegis)

  const ListCost            = useSelector(state=>state.CostStudent.listCostStudent)
  const ItemTemplete        = useSelector(state=>state.GovLogin.ItemTemplete)

  const ShowInfo=()=>{
      dispatch({
          type:OPEN_DETAIL_INFO_GOV
      })
  }
  const handleClose=()=>{
    dispatch({
      type:CLOSE_DETAIL_INFO_GOV
    })
  }
  const CloseSettingAdance = ()=>{
    dispatch({
      type:CLOSE_SETTING_ADVANCE
    })
  }
  const OpenFormSetting=()=>{
    dispatch({
      type:OPEN_SETTING_ADVANCE
    })
  }
  const OpenFormTemplete=()=>{    
       dispatch({
          type:OPEN_TEMPLETE //add
        })            
   }
  const OpenFormViewTemplete =()=>{
      dispatch({
        type:LIST_BY_USERID_TEMPLETE
      })
      dispatch({
        type:SHOW_LIST_VIEW_TEMPLETE
      })
  }
  const CloseFormTemplete =()=>{
      dispatch({
          type:CLOSE_TEMPLETE
      })
  }
  const CloseFormViewTemplete=()=>{
    dispatch({
      type:CLOSE_SHOW_LIST_VIEW_TEMPLETE
    })
  }
  //chu ky bieu mau
  const FormSignRegis=()=>{
      dispatch({
        type:OPEN_FORM_SIGN_REGIS
      })
  }
  const CloseFormSignRegis=()=>{
    dispatch({
      type:CLOSE_FORM_SIGN_REGIS
    })
  }

  const EditFormSignRegis=()=>{
      dispatch({
        type:OPEN_UPDATE_FORM_SIGN_REGIS
      })
  }

  const CloseFormUpdateFormRegis=()=>{
    dispatch({
      type:CLOSE_UPDATE_FORM_SIGN_REGIS
    })
  }
  const ShowButtonSettingAdvance =()=>{
    if(ListCost.length!==0){
      return(
        <>
          <Button variant="outlined" size="small" color="secondary" onClick={()=>OpenFormSetting()} ><MonetizationOnIcon/> Phí các loại</Button>&nbsp;
          <Button variant="outlined" size="small" color="primary"   onClick={()=>ItemTemplete.length!==0?OpenFormViewTemplete():OpenFormTemplete()} style={{color:'#ecf0f1'}}><FormatAlignRightIcon/> Biểu mẫu</Button>&nbsp;
          {ItemFormSignRegis!==undefined?
          <Button variant="outlined" size="small"  color="primary" onClick={()=>EditFormSignRegis()} style={{color:'white'}}><GroupIcon/> Chức danh</Button>
          :<Button variant="outlined" size="small" color="inherit" onClick={()=>FormSignRegis()} style={{color:'#ecf0f1'}} ><GroupIcon/>Chức danh</Button>}
        </>
      )
    }else{
      return(
        <></>
      )
    }   
   }
   const [open,setOpen] = useState(false)

  return (
    <>
      <Grid item xs="12">
          {open&&<YouTuBeComponent
          open={open}
          handleClose={()=>setOpen(false)}
        />}
        <Box display="flex" justifyContent="flex-end" style={{margin:5}}>
          <Button onClick={()=>setOpen(true)} variant='contained' size="small" color='secondary'><TheatersIcon/>Xem Hướng Dẫn</Button> 
        </Box>
        <Box  display="flex" justifyContent="center" style={{margin:5}}>
            <Suspense fallback={()=>{}} style={{background:'red'}}>
    
        {OpenUpdateFormSignRegis&&<FormUpdateRegis
              open={OpenUpdateFormSignRegis}
              handleClose={CloseFormUpdateFormRegis}
        />}
        {OpenFormSignRegis&&<FormSignRegisComponent
              open={OpenFormSignRegis}
              handleClose={CloseFormSignRegis}
        />}
        {OpenViewTemplete&&(<ListViewTemplete
            open={OpenViewTemplete}
            handleClose={CloseFormViewTemplete}
        />)}
        {OpenTemplete&&<FormTemplete
            open={OpenTemplete}
            handleClose={CloseFormTemplete}
        />} 
        {OpenInfoGov&&(<InfoGov
              open={OpenInfoGov}
              handleClose={handleClose}
        />)}
        {OpenSettingAdvance&&<OpenSettingForm
            open={OpenSettingAdvance}
            handleClose={CloseSettingAdance}
        />}
        {ItemGov.activeSoftFood===true?(
          <div style={{marginTop:30}}>
                <Button variant="outlined" size="small" color="secondary" onClick={()=>ShowInfo()}><SettingsIcon/>Khai Báo Thông Tin</Button>&nbsp;
                {ShowButtonSettingAdvance()}
        </div>
        ):("")}
            </Suspense>
          </Box>          
      </Grid>
    </>
  );
})

export default Index