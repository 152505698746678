import { DISPATCH_GET_INFO_CUS_KHACH_HANG_TO_STORE,GET_LIST_JOB_STAFF_TO_KHACH_HANG_TO_STORE ,
    DISPATCH_HOP_DONG_INVOICE_TO_KHACH_HANG_TO_STORE
} from "../contant/LOGIN_PAGE/OpenNavBarKhachHang";

export const DispatchHopDongInvoiceToKhachHangToStore =item=>{
    return{
        type:DISPATCH_HOP_DONG_INVOICE_TO_KHACH_HANG_TO_STORE,
        payload:{item}
    }
}


export const DispatchGetInfoCusKhachHangToStore =item=>{
    return{
        type:DISPATCH_GET_INFO_CUS_KHACH_HANG_TO_STORE,
        payload:{item}
    }
}

export const GetListJobStaffToKhachHangToStore = item =>{
    return{
        type:GET_LIST_JOB_STAFF_TO_KHACH_HANG_TO_STORE,
        payload:{item}
    }
}
