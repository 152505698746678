import React, { Suspense } from 'react';
import {Dialog,Box} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import AddComponent from  './Add'

const Index=React.memo(({open,handleClose})=> {  
  return (      
    <Suspense fallback={()=>{}}>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">       
        <Box textAlign="center">
               <Alert variant="filled" severity="info">Tên Chức danh</Alert>
        </Box>       
         <AddComponent handleClose={handleClose}/>
      </Dialog>
    </Suspense>
  );
})

export default Index
