import {SHOW_OPEN_ADD_REPO,CLOSE_OPEN_ADD_REPO,DISPATCH_VALUE_ADD_CREATE_REPO_TO_STORE,
    DISPATCH_ADD_SUCCESS_CREATE_REPO_TO_STORE,SHOW_OPEN_VIEW_REPO,CLOSE_OPEN_VIEW_REPO, DISPATCH_LIST_ALL_CREATE_REPO_TO_STORE,
    UPDATE_CREATE_REPO_TO_DB,
    OPEN_VIEW_INFO_REPO,
    CLOSE_VIEW_INFO_REPO,UPDATE_VIEW_INFO_REPO
} from '../Contant/Person/createRepo'
import {updateArrById} from './fn'
import {sortArrAsc}from '../../helper/tableList/tableList'

export const initialState={
      OpenAddCreateRepo:false,
      OpenViewCreateRepo:false,
      ItemAddCreateRepo:"",
      ListCreateRepo:[],
      ItemRepo:"",
      OpenViewInfoRepo:false
}

const CreateRepoReducer=(state=initialState,action)=>{
    switch (action.type) {
      case SHOW_OPEN_ADD_REPO:
          return{
            ...state,
            OpenAddCreateRepo:true
          }
      case CLOSE_OPEN_ADD_REPO:
        return{
          ...state,
          OpenAddCreateRepo:false
        }          
      
        case SHOW_OPEN_VIEW_REPO:
          return{
            ...state,
            OpenViewCreateRepo:true
          }
      case CLOSE_OPEN_VIEW_REPO:
        return{
          ...state,
          OpenViewCreateRepo:false
        }      
        
      case DISPATCH_VALUE_ADD_CREATE_REPO_TO_STORE:
        return {
          ...state,
          ItemAddCreateRepo:action.payload.item
        }
      case DISPATCH_ADD_SUCCESS_CREATE_REPO_TO_STORE:
        return{
          ...state,
          ListCreateRepo:sortArrAsc(state.ListCreateRepo.concat(action.payload.item))
        }
      case DISPATCH_LIST_ALL_CREATE_REPO_TO_STORE:
        return {
          ...state,
          ListCreateRepo:sortArrAsc(action.payload.item)
        }
      case UPDATE_CREATE_REPO_TO_DB:
        return{
          ...state,
          ListCreateRepo:sortArrAsc(updateArrById(state.ListCreateRepo,action.payload.item))
        } 
      case OPEN_VIEW_INFO_REPO:
        return{
          ...state,
          OpenViewInfoRepo:true,
          ItemRepo:action.payload.item
        }
      case CLOSE_VIEW_INFO_REPO:
        return{
          ...state,
          OpenViewInfoRepo:false,
          ItemRepo:""
        }
      case UPDATE_VIEW_INFO_REPO:
        return{
          ...state,
          ListCreateRepo:sortArrAsc(updateArrById(state.ListCreateRepo,action.payload.item)),
          ItemRepo:action.payload.item
        }
      
    
      default: return state
    }
}

export default CreateRepoReducer

