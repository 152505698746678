import React, { useState,useEffect } from 'react';
import { Grid ,Container,Box} from '@material-ui/core';
import { MainFooter ,MainFooterDown,MainFooterUp,MainLeftUp,FooterImg,MainRightUp, LineFooter, IconFooter,
     //DivQrcodeFooter, DivQrcode
    } from '../../style/MainCss/footer';
//import LogoGDT from '../../assest/img/gdt.jpg'
import HuyThanhIcare from './huythanhicare.png'
import Titok   from '../../assest/img/titok.png'
import Facebook   from '../../assest/img/facebook.png'
import Youtube from '../../assest/img/You.png'
import Instagram from '../../assest/img/instagram.png'
//import { MainColor } from './color';
import {  IMG_ICON,  ListIcon } from '../../style/MainCss/team_member';
import { Tooltip } from '@material-ui/core';
import { GenerateQrcodeIMG } from '../../../../WebMamNon/component/footer/fn';
import { makeStyles } from '@material-ui/core/styles';
import { OnlineFake } from '../../../../../../utils/OnlineFake';
//import {  ImgQrcode } from '../../../../WebMamNon/component/footer/style';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  function ChangeTooltip(props) {
    const classes = useStylesBootstrap();
    
    return <Tooltip arrow classes={classes} {...props} />;
  }

const Footer = () => {
    const [qrcodeLink,setQrcodeLink] = useState(null)
    useEffect(()=>{               
        const fetchData = async () => {
           let result = await GenerateQrcodeIMG('https://cdgdt.com/')
           setQrcodeLink(result)
        }               
        fetchData()
        .catch(console.error)         
    },[qrcodeLink])
    return (
        <Grid>
                               <MainFooter class="footer" id="CONTACT">
                                            <MainFooterUp id="main-footer_up">
                                                                        <Container maxWidth="lg">
                                                                            <Grid container spacing="1">
                                                                                <Grid item sm="6">
                                                                                    <MainLeftUp>
                                                                                            <a style={{textDecoration:'none'}} href="http://huythanhicare.com">
                                                                                                    <FooterImg  src={HuyThanhIcare} alt="huythanhicare"/>
                                                                                            </a>     
                                                                                            <h2 style={{color:'#e67e22',fontSize:25}}>Soft && Technology</h2>
                                                                                            <h4 style={{color:'#888888',fontSize:20,marginTop:-10}}>KẾT NỐI CÙNG CHÚNG TÔI</h4>                                <ListIcon>                                  
                                        <IconFooter href="" style={{textDecoration:'none'}}>
                                                <IMG_ICON src={Facebook}/>
                                        </IconFooter>
                                        <IconFooter href="" style={{textDecoration:'none'}}>
                                           <IMG_ICON src={Youtube}/>
                                        </IconFooter>

                                        <IconFooter href="" style={{textDecoration:'none'}}>
                                            <IMG_ICON src={Titok}/>
                                        </IconFooter>   
                                        <IconFooter href="" style={{textDecoration:'none'}}>
                                            <IMG_ICON src={Instagram}/>
                                        </IconFooter>                                              
                                </ListIcon>
                                
                                                                         </MainLeftUp>
                                                                          </Grid>                                                                                
                                                                                   <LineFooter className='line'></LineFooter>

                                                                                <Grid item sm="6">
                                                                                    <MainRightUp>
                                                                                        <h2 style={{color:'white'}}>CONTACT FOR US</h2>
                                                                                        <h3  style={{color:`#e67e22`,fontSize:18}}>Zalo : 0775.568.194  - Support technique / kỹ thuật </h3>
                                                                                        <h3  style={{color:`#e67e22`,fontSize:18}}>Zalo : 0935.009.112  - HOTLINE </h3>
                                                                                        <h4 style={{color:'#888888',fontSize:20,marginTop:-10}}><a href='http://huythanhicare.com' style={{textDecoration:'none',color:'#888888'}}>Website : http://huythanhicare.com</a></h4>
                                                                                        <h4 style={{color:'#888888',fontSize:20,marginTop:-10}}>Company: VIỄN THÔNG TIN HỌC HUY THÀNH</h4>
                                                                                        <h4 style={{color:'#888888',fontSize:20,marginTop:-10}}>Address: 26/8 Nha Trang, Khánh Hoà</h4>
                                                                                        <h4 style={{color:'#888888',fontSize:20,marginTop:-10}}>Email: huythanhicare.coltd@gmail.com</h4>
                                                                                        <h4 style={{color:'#e67e22',fontSize:20,marginTop:-10}}>Online: {OnlineFake(Date.now())}</h4>
                                                                                        {/* <h4 style={{color:'#888888',fontSize:20,marginTop:-10}}>Cám ơn các bạn đã đồng hành và ủng hộ</h4> */}
                                                                                    </MainRightUp>
                                                                                    {/* {qrcodeLink!==null&&
                                                                                    <DivQrcodeFooter >
                                                                                            <ChangeTooltip title="Download">
                                                                                                    <DivQrcode>
                                                                                                        <a style={{textDecoration:'none'}} href={qrcodeLink} download><ImgQrcode src={qrcodeLink}/></a>             
                                                                                                    </DivQrcode>
                                                                                            </ChangeTooltip>        
                                                                                    </DivQrcodeFooter>                                                                                           
                                                                                    } */}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Container> 
                                            </MainFooterUp>
                                            <MainFooterDown id="main-footer_down">
                                                     <Container maxWidth="lg">
                                                        <Box display="flex">
                                                            <h2 style={{color:'#888888',fontSize:15}}>©2023&nbsp;</h2>
                                                            <h2 style={{color:'#e67e22',fontSize:15}}>HT-Icare COMPANY</h2>
                                                        </Box>  
                                                    </Container>                                                  
                                            </MainFooterDown>
                                         
                                </MainFooter>
        </Grid>
    );
};

export default Footer;