import React from 'react'

const index = () => {
  return (
    <div>
          nhan vien
    </div>
  )
}

export default index
