import {GET_INFO_USER_LOGIN,LIST_GOV,UPDATE_INFO_GOV_SUCCESS,UPDATE_PASS_SUCCESS,DISPATCH_LIST_INFO_USER_DETAIL,
  DISPATCH_ITEM_INFO_USER_DETAIL,DELETE_SUCCESS,DISPATCH_ADD_TEMPLETE_TO_STORE,DISPATCH_LIST_ITEM_TO_STORE_TEMPLETE,ADD_TEMPLETE_SUCCESS,LOGIN_FAIL_ACCOUNT_ONLY,
  ALERT_LOGIN_FAIL_ACCOUNT_ONLY,ADD_FORM_SIGN_REGIS_SUCCESS_AND_DISPATCH_TO_STORE,DISPATCH_ITEM_FORM_REGIS_TO_STORE
} from '../../contant/Gov/login'

export const GetInfoUserLogin = (user) =>{
  return {
    type:GET_INFO_USER_LOGIN,
    payload:{user}
  }
}

export const ListGov = (arr)=>{
  return{
    type:LIST_GOV,
    payload:{arr}
  }
}

export const UpdateInfoGovSuccess = ()=>{
  return{
    type:UPDATE_INFO_GOV_SUCCESS
  }
}
export const UpdatePassSuccess = ()=>{
  return{
    type:UPDATE_PASS_SUCCESS
  }
}

export const DispatchListInfoUserDetail = (arr)=>{
    return{
      type:DISPATCH_LIST_INFO_USER_DETAIL,
      payload:{arr}
    }
}
export const DispatchItemInfoUserDetail=(item)=>{
    return{
      type:DISPATCH_ITEM_INFO_USER_DETAIL,
      payload:{item}
    }
}

export const DeleteSuccess=()=>{
    return {
      type:DELETE_SUCCESS
    }
}
export const DispatchAddTempleteToStore=(item)=>{
  return{
    type:DISPATCH_ADD_TEMPLETE_TO_STORE,
    payload:{item}
  }
}
export const DispatchListItemToStoreTemplete=(item)=>{
  return{
    type:DISPATCH_LIST_ITEM_TO_STORE_TEMPLETE,
    payload:{item}
  }
}

export const AddTempleteSuccess = ()=>{
  return {
    type:ADD_TEMPLETE_SUCCESS
  }
}

export const LoginFailAccountOnly =()=>{
    return {
      type:LOGIN_FAIL_ACCOUNT_ONLY
    }
}

export const AlertLoginFailAccountOnly=()=>{
  return{
    type:ALERT_LOGIN_FAIL_ACCOUNT_ONLY
  }
}

export const AddFormSignRegisSuccessAndDispatchToStore=(item)=>{
    return{
      type:ADD_FORM_SIGN_REGIS_SUCCESS_AND_DISPATCH_TO_STORE,
      payload:{item}
    }
}

export const DispatchItemFormRegisToStore=(item)=>{
  return{
    type:DISPATCH_ITEM_FORM_REGIS_TO_STORE,
    payload:{item}
  }
}
