import styled,{keyframes} from "styled-components";
import {device} from '../device'
import { MainColor } from "./color";

export const gradient = keyframes`
    100% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    0% {
        background-position: 0% 0%;
    }
`

export const MainChooseUs=styled.div`
    width:100%;
    height:580px;
    background:#171616;
    margin:auto;
    padding-top:50px;
    padding-bottom:50px;
    &:hover{
        background: linear-gradient(315deg,#34495e 10%, rgba(0, 0, 0, 0.87) 38%,  #7f8c8d  68%,#16a085 85%, rgba(0, 0, 0, 0.87) 98%);
        animation: ${gradient} 15s ease infinite;
        background-size: 200% 200%;
        background-attachment: fixed;
    }
    @media  (max-width:768px){
        height:950px;
    }
    @media (min-width:769px) and (max-width:1024px){
        height:600px;
    }
`

export const MainOffer = styled.div`
    width:100%;
    height:600px;
    background:#171616;
    padding:25px;
    margin:auto;
    transition-delay:2s;
    &:hover{
        transition-delay:2s;
        background: linear-gradient(315deg,#34495e 10%, rgba(0, 0, 0, 0.87) 38%,  #7f8c8d  68%,#16a085 85%, rgba(0, 0, 0, 0.87) 98%);
        animation: ${gradient} 15s ease infinite;
        background-size: 200% 200%;
        background-attachment: fixed;
    }
    ${'' /* @media ${device.mobile}{
        height:600px;
    }
    @media (max-width:1024px){
        height:600px;
    } */}
`

export const DivOne =styled.div`
        width:90%;
        height:250px;
        display:block;
        margin:auto;
        padding-top:25px;
        @media (max-width:768px){
            height:280px;
            margin-bottom:-50px;
            margin-top:-80px;
        }
`

export const DivTwo =styled.div`
        width:90%;
        height:250px;
        display:block;
        margin:auto;
        padding-top:25px;
        @media ${device.mobile}{
            height:280px;          
        }
        @media (max-width:768px){
            margin-top:-10px;
        }
`

export const DivThree =styled.div`
        width:90%;
        height:250px;
        display:block;
        margin:auto;
        padding-top:25px;
        ${'' /* @media ${device.mobile}{
            height:280px;
            margin-top:-100px;
        } */}
        @media (max-width:768px){
            height:280px;
            margin-top:-100px;
        }
`

export const DivFour =styled.div`
        width:90%;
        height:250px;
        display:block;
        margin:auto;
        padding-top:25px;
        @media (max-width:768px){
            height:280px;
            margin-top:-100px;
        }   
        @media (min-width:728px)and(max-width:1024px){
            height:280px;
            margin-top:0;
        }
`

export const DivFive =styled.div`
        width:90%;
        height:250px;
        display:block;
        margin:auto;
        padding-top:25px;
        @media (max-width:768px){
            height:280px;
            margin-top:-100px;
        }
        @media (min-width:728px)and(max-width:1024px){
            height:280px;
            margin-top:-27px;
        }
`

export const TextOne =styled.div`
    color:${MainColor};
    font-size:35px;
    margin-bottom:20px;
    font-weight:bold;
    margin-left:5px;
    @media ${device.mobile}{
        text-align:center;
        font-size:15px;
        margin:auto;
    }
    @media (max-width:1024px){        
        font-size:20px;       
    }
`

export const TextOne_p =styled.div`
    color:#ecf0f1;
    font-size:18px;
    text-align:justify;
    @media ${device.mobile}{       
        font-size:12px;
    }
    @media (max-width:1024px){        
        font-size:15px;       
    }
`

export const TextOne__p =styled.div`
    color:#ecf0f1;
    font-size:25px;,
    text-align:justify;
    font-family:Roboto;
    font-style:italic;
    @media ${device.mobile}{       
        font-size:15px;
    }
    @media (max-width:1024px){        
        font-size:15px;       
    }
`

export const LineIn= styled.div`
        width:70%;
        height:3px;
        background:${MainColor};
        margin:auto;
        margin-top:15px;
        margin-bottom:15px;        
`