import {put,call, delay, takeLatest} from 'redux-saga/effects'
import {DISPATCH_VALUE_ADD_TO_SAGA,DISPATCH_VALUE_TO_DB,GET_LIST_TYPE_OF_SERVICE

} from '../../Contant/Admin/AddViewAppService'
import {DispatchValueAddToStore,DispatchAddSuccessToStore,DispatchListTypeOfServiceToStore} from '../../Actions/App_TypeOfService'
import {addTypeOfService,listTypeOfService} from '../../Api-services/ADMIN_APP_SERVICE/index'
import {LoadingStop,LoadingNow,OpenAlertSuccess} from '../../Actions/helper'
import {ALERT_TEXT_SUCCESS} from '../../Contant/helperText'

const SUCCESS = 'success'
const FAIL    = 'fail'

function* DispatchValueAddToSaga({payload}){
    yield put(DispatchValueAddToStore(payload.item))
}

function* DispatchValueToDb({payload}){
    yield put(LoadingNow())
    let res = yield call(addTypeOfService,payload.item)
    if(res.data.result===SUCCESS){
        yield put(DispatchAddSuccessToStore(res.data.data))
        yield put(LoadingStop())
        yield put(OpenAlertSuccess(ALERT_TEXT_SUCCESS))
    }else{
        return alert('Lỗi, kiểm tra lại')
    }    
}

function* GetListTypeOfService(){
    yield put(LoadingNow())
    let res = yield call(listTypeOfService)
    if(res.data.result===SUCCESS){
        yield put(DispatchListTypeOfServiceToStore(res.data.data))
        yield put(LoadingStop())
    }else{
        return alert('Lỗi, kiểm tra lại')
    }
}

function* ServiceSaga(){
 yield takeLatest(DISPATCH_VALUE_ADD_TO_SAGA,DispatchValueAddToSaga)
 yield takeLatest(DISPATCH_VALUE_TO_DB,DispatchValueToDb)
 yield takeLatest(GET_LIST_TYPE_OF_SERVICE,GetListTypeOfService)
}

export default ServiceSaga