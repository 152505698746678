import React from 'react';
import { 
    //ContentService,ContentServiceIn,ContentServiceUnder,
    ContentService_,
    //ContentWe,Four_,
    HeaderText, Item_, LineService, LineUnder, MainContent,
     //One_,
      ShowLineBg, TextExplain, TextHeadService, 
      //TextIn, TextIn_, TextWe, TextWeIn, Three_, Two_ 
    } from './style';
//import AccountBoxIcon from '@material-ui/icons/AccountBox';//nutrikid
import ReceiptIcon from '@material-ui/icons/Receipt'; //thiet ke
//import TouchAppIcon from '@material-ui/icons/TouchApp';
//import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
//import SyncAltIcon from '@material-ui/icons/SyncAlt';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SecurityIcon from '@material-ui/icons/Security';
import LineBG from '../../FnComponent/Line'
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import LanguageIcon from '@material-ui/icons/Language';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import VideocamIcon from '@material-ui/icons/Videocam';
import CodeIcon from '@material-ui/icons/Code';
import GitHubIcon from '@material-ui/icons/GitHub';
import { MainColor } from '../color';

const Index = React.memo(() => {
    return (
        <MainContent id="SERVICE">
                   {/* <ContentService>
                        <HeaderText>WITH KIDTOP</HeaderText>
                        <LineUnder/>
                        <TextIn>QUÝ KHÁCH HÀNG SẼ ĐƯỢC HỖ TRỢ ĐẮC LỰC TRONG VIỆC</TextIn>
                    </ContentService> */}

                    {/* <ContentServiceUnder>
                        <ContentServiceIn>
                                <One_>
                                    <AccountBoxIcon style={{fontSize:60,color:`${MainColor}`,textAlign:'center'}}/> <h3>Tính Nutrikids</h3>
                                </One_>
                                <One_>
                                    <TouchAppIcon style={{fontSize:60,color:`${MainColor}`,textAlign:'center'}}/> <h3>Kiểm Thực 3 Bước</h3>
                                </One_>
                                <One_>
                                    <SyncAltIcon style={{fontSize:60,color:`${MainColor}`,textAlign:'center'}}/> <h3>Quản Lý Thu Chi</h3>
                                </One_>
                                <One_>
                                    <PeopleAltIcon style={{fontSize:60,color:`${MainColor}`,textAlign:'center'}}/> <h3>Quản Trị Toàn Diện</h3>
                                </One_>                             
                        </ContentServiceIn>
                    </ContentServiceUnder>     */}

                    <ContentService_>
                        <HeaderText>CÁC DỊCH VỤ HỖ TRỢ</HeaderText>
                        <LineUnder/>
                        <LineService>
                                <Item_>
                                    <AddShoppingCartIcon style={{color:`${MainColor}`,fontSize:60}}/>
                                    <TextHeadService>DÙNG THỬ MIỄN PHÍ</TextHeadService>
                                    <TextExplain>Khách hàng có thể trải nghiệm phần mềm miễn phí 30 ngày. </TextExplain>
                                </Item_>
                                <Item_>
                                    <ReceiptIcon style={{color:`${MainColor}`,fontSize:60}}/>
                                    <TextHeadService>THIẾT KẾ PHẦN MỀM THEO YÊU CẦU</TextHeadService>
                                    <TextExplain>
                                        Tùy chỉnh theo quy trình, yêu cầu của khách hàng
                                    </TextExplain>
                                </Item_>
                                <Item_>
                                    <ThumbUpIcon style={{color:`${MainColor}`,fontSize:60}}/>
                                    <TextHeadService>ĐỒNG HÀNH LÂU DÀI VỚI ĐỐI TÁC</TextHeadService>
                                    <TextExplain>
                                        Luôn giúp đỡ khách hàng và cung cấp dịch vụ hậu mãi tốt nhất             
                                    </TextExplain>
                                </Item_>
                                <Item_>
                                    <FavoriteIcon style={{color:`${MainColor}`,fontSize:60}}/>
                                    <TextHeadService>CHĂM SÓC KHÁCH HÀNG</TextHeadService>
                                    <TextExplain>
                                        Đội ngũ nhân viên sẵn sàng hỗ trợ khách hàng khi gặp sự cố
                                    </TextExplain>
                                </Item_>
                                <Item_>
                                    <SupervisedUserCircleIcon style={{color:`${MainColor}`,fontSize:60}}/>
                                    <TextHeadService>HỖ TRỢ CÀI ĐẶT</TextHeadService>
                                    <TextExplain>
                                    Cài đặt nhanh chóng, hướng dẫn chi tiết, sử dụng dễ dàng 
                                   </TextExplain>
                                </Item_>
                                <Item_>
                                    <SecurityIcon style={{color:`${MainColor}`,fontSize:60}}/>
                                    <TextHeadService>BẢO MẬT THÔNG TIN                                     </TextHeadService>
                                    <TextExplain>
                                        Luôn tôn trọng và bảo đảm dữ liệu riêng tư cho khách hàng
                                    </TextExplain>
                                </Item_>
                        </LineService>
                    </ContentService_>         

                    {/* <ContentWe>
                        <TextWe>CHÚNG TÔI</TextWe>
                        <TextWeIn>ĐỘI NGŨ HOLDING TECHNOLOGY CỦA CỘNG ĐỒNG GIỚI ĐỊNH TUỆ</TextWeIn>
                        <TextIn_>Cam kết mang lại cho Khách Hàng giải pháp tối ưu và hiệu quả nhất trong việc tính Nutrikids, quản lý thu chi, tạo các mẫu kiểm thực 3 bước, giúp quý khách đạt được những yêu cầu mà mình mong muốn.</TextIn_>
                        <LineBG/>
                    </ContentWe>       */}

                    <ContentService_>
                        <HeaderText>CÁC DỊCH VỤ KHÁC</HeaderText>
                        <LineUnder/>
                        <LineService>
                                <Item_>
                                    <HomeWorkIcon style={{color:`${MainColor}`,fontSize:60}}/>
                                    <TextHeadService>THIẾT KẾ SERVER VÀ XÂY DỰNG MÁY CHỦ CHO DOANH NGHIỆP & TỔ CHỨC</TextHeadService>
                                </Item_>
                                <Item_>
                                    <LanguageIcon style={{color:`${MainColor}`,fontSize:60}}/>
                                    <TextHeadService>CUNG CẤP THIẾT BỊ VÀ LẮP ĐẶT HỆ THỐNG MẠNG INTERNET</TextHeadService>
                                   
                                </Item_>
                                <Item_>
                                    <ImportantDevicesIcon style={{color:`${MainColor}`,fontSize:60}}/>
                                    <TextHeadService>CUNG CẤP CÁC SẢN PHẨM CÔNG NGHỆ THÔNG TIN </TextHeadService>
                                   
                                </Item_>
                                <Item_>
                                    <VideocamIcon style={{color:`${MainColor}`,fontSize:60}}/>
                                    <TextHeadService>CUNG CẤP THIẾT BỊ VÀ LẮP ĐẶT HỆ THỐNG CAMERA GIÁM SÁT</TextHeadService>                                    
                                </Item_>
                                <Item_>
                                    <CodeIcon style={{color:`${MainColor}`,fontSize:60}}/>
                                    <TextHeadService>THIẾT KẾ PHẦN MỀM QUẢN LÝ CHO DN & TỔ CHỨC THEO YÊU CẦU</TextHeadService>                                   
                                </Item_>
                                <Item_>
                                    <GitHubIcon style={{color:`${MainColor}`,fontSize:60}}/>
                                    <TextHeadService>ĐÀO TẠO LẬP TRÌNH VIÊN VÀ KĨ THUẬT VIÊN CÔNG NGHỆ </TextHeadService>                                   
                                </Item_>
                        </LineService>
                        <ShowLineBg>
                            <LineBG></LineBG>
                       </ShowLineBg>
                    </ContentService_>  
        </MainContent>
    );
});

export default Index;