export const SIGNUP      = "SIGNUP"
export const SIGNUP_FAIL = "SIGNUP_FAIL"
export const SIGNUP_FAIL_CHECK_USERNAME = "SIGNUP_FAIL_CHECK_USERNAME"
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS"

export const LOGIN ="LOGIN"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGIN_FAIL_CHECK_USER_PASS ="LOGIN_FAIL_CHECK_USER_PASS"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const REDIRECT_WEB                = "/"
export const REDIRECT_SIGNUP_SUCCESS     = "/login"
//export const REDIRECT_LOGIN_SUCCESS  = "/admin/taskboard"
export const REDIRECT_LOGIN_SUCCESS      = "/admin"
export const REDIRECT_LOGIN_STAFF        = '/staff'
export const REDIRECT_LOGIN_PERSON       = '/person'
export const REDIRECT_LOGIN_CLASS_ACTIVE = '/class-active'

//dang nhap vao trang nhan vien tai khoan idgroup:88
export const REDIRECT_LOGIN_PERSON_STAFF = '/accountloginpersonstaff'

export const REDIRECT_LOGIN_GOV_SUCCESS  = "/gov/taskboard"
export const REDIRECT_SIGNUP_PAGE        = "/signup"
export const REDIRECT_LOGIN_PAGE         = "/login"
export const DISPATCH_LOGIN_PAGE_CREATE_ACCOUNT = "DISPATCH_LOGIN_PAGE_CREATE_ACCOUNT"

export const REDIRECT_MAIN_PAGE_GOV = '/active-soft-food'


export const REDIRECT_LOGIN_USER_CUS_NN_SUCCESS = "/user-cus-nn"
export const GET_LIST_FORM ="GET_LIST_FORM"

export const OPEN_FORM_CHECK_LOGIN  = "OPEN_FORM_CHECK_LOGIN"
export const CLOSE_FORM_CHECK_LOGIN = "CLOSE_FORM_CHECK_LOGIN"

export const DISPATCH_LIST_USER_STORE = "DISPATCH_LIST_USER_STORE"



