import {LOADING_NOW,LOADING_STOP,OPEN_ALERT_SUCCESS,CLOSE_ALERT_SUCCESS} from '../Contant/helper'

export const LoadingNow=()=>{
  return{
    type:LOADING_NOW
  }
}

export const LoadingStop=()=>{
  return{
    type:LOADING_STOP
  }
}

export const OpenAlertSuccess=(item)=>{
  return{
    type:OPEN_ALERT_SUCCESS,
    payload:{item}
  }
}

export const CloseAlertSuccess=(item)=>{
  return{
    type:CLOSE_ALERT_SUCCESS,
    payload:{item}
  }
}