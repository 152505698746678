import axiosServices from '../../../../../../../api-services/api'
import {API_ENDPOINT}from '../../../../../../../contant'

const urlAutoImport        = 'person/AutoImport'
const urlGetAutoImport     = 'person/GetAutoImport'
const urlAddCategory       = 'person/addCategory'
const urlGetCategory       = 'person/getCategory'
const urlAddMaterial       = 'person/addMaterial'
const urlUpdateMaterial    = 'person/updateMaterial'
const urlUpdateCategory    = 'person/updateCategory'
const urlUpdateMaterials   = 'person/updateMaterials'
const urlAddImportMaterial = 'person/addImportMaterial'
const urlGetImportMaterial = 'person/getImportMaterial'
const urlDeleteMaterial    = 'person/DeleteMaterial'
const urlDeleteCategory    = 'person/DeleteCategory'
const urlUpdateImportMaterial = 'person/UpdateImportMaterial'
const urlDeleteImportMaterial = 'person/DeleteImportMaterial'
const urlAddAutoImport        = 'person/AddAutoImport'
const urlGetListImportStaff   = 'person/GetListImportStaff'
const urlDdosAttack           = "person/DdosAttack"
const urlUpdateItemImportStaff = "person/UpdateItemImportStaff"

export const AutoImportApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAutoImport}`,item)
}

export const GetAutoImportApi=()=>{
  return axiosServices.get(`${API_ENDPOINT}/${urlGetAutoImport}`)
}

export const AddCategoryApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddCategory}`,item)
}

export const GetCategoryApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetCategory}`,item)
}

export const AddMaterialApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddMaterial}`,item)
}

export const UpdateMaterialApi=(item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateMaterial}`,item)
}

export const UpdateCategoryApi=(item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateCategory}`,item)
}

export const AddMaterialsApi=(item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateMaterials}`,item)
}

export const AddImportMaterialsApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddImportMaterial}`,item)
}

export const GetImportMaterialsApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetImportMaterial}`,item)
}

export const DeleteMaterialApi=(item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlDeleteMaterial}`,item)
}

export const DeleteCategoryApi=(item)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteCategory}`,item)
}

export const UpdateImportMaterialApi=(item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateImportMaterial}`,item)
}

export const DeleteImportMaterialApi=(item)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteImportMaterial}`,item)
}

export const AddAutoImportApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddAutoImport}`,item)
}

export const GetListImportStaffApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetListImportStaff}`,item)
}

export const DdosAttackSagaApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlDdosAttack}`,item)
}

export const UpdateItemImportStaffApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlUpdateItemImportStaff}`,item)
}