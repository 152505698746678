import { makeStyles } from '@material-ui/core/styles';
import styled ,{keyframes,css}from 'styled-components'

export const useStyles = makeStyles((theme)=>({
    root: {
        minWidth: 275,
        background:'#e67e22',
        // backgroundImage:'linear-gradient(0,#27ae60,#4cb2b6)',
        width: '100%',
        marginLeft:0,
        marginRight:10,
        marginBottom:10,
        marginTop:12,
        padding: 45,
        height: 461,
        overflow:'auto',
        clear:'both'
       },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
}))



// element.style {
// }
// <style>
// .makeStyles-root-24 {
//     width: 100%;
//     padding: 62px;
//     /* min-width: 275px; */
//     margin-top: 10px;
//     margin-left: 0;
//     margin-right: 10px;
//     margin-bottom: 10px;
//     background-image: linear-gradient(0,#27ae60,#4cb2b6);
//     height: 500px;
const  SlideIn = keyframes`
0%{
    opacity: 0;
}

 60%{
    opacity:0.5
}

100%{
    opacity:1;
}
`

const HideSlideIn=keyframes`
0%{
opacity:1;
scale:100%;
}
60%{
opacity:0.5;
scale:50%;
}
100%{
opacity:0;
scale:0%;
}    
`

export const ChucNangDiv = styled.div`        
        ${(props)=>props.variant===true?
        css`
        animation:${HideSlideIn} 2s 1 linear;     
        visibility: hidden;
        `:
        css`
        animation:${SlideIn} 2s 1 linear;
        visibility: visible;
        `
       }
`

export const ButtonStyleHide=styled.button`
display: inline-block;
margin-top:15px;
width: 100%;
height: 40px;
border-radius:6px;
background-color: #e74c3c;
color:${(props)=>props.variant==='outline'?'#fff':'#dcdde1'};
    background-color:${(props)=>props.variant==='outline'?
    '#e74c3c':'#c0392b'
    } ;
font-weight: bold;
text-align:center;
font-size: 15px;
cursor: pointer;
border: none;
transition: 0.2s all ease-in-out;
&:hover{
    color:${(props)=>props.variant!=='outline'?'#7f8c8d':'#dcdde1'};
        background-color:${(props)=>props.variant!=='outline'?
        '#e74c3c':'#c0392b'
         } ;
    }
`

export const ButtonStyle=styled.button`
display: inline-block;
//padding: 15px 32px;
margin-top:15px;
width: 100%;
height: 40px;
border-radius:6px;
background-color: #1e88e5;
color:${(props)=>props.variant==='outline'?'#fff':'#dcdde1'};
    background-color:${(props)=>props.variant==='outline'?
        '#1e88e5':'#0097e6'
    } ;
font-weight: bold;
text-align:center;
font-size: 15px;
cursor: pointer;
border: none;
transition: 0.2s all ease-in-out;
&:hover{
        color:${(props)=>props.variant!=='outline'?'#4caf50':'#dcdde1'};
        background-color:${(props)=>props.variant!=='outline'?
                '#1e88e5':'#0097e6'
         } ;
    }
`

