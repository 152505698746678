export const THUC_PHAM_TUOI_SONG_DONG_LANH                         = "Nhóm Thực Phẩm Tươi sống đông lạnh : Thịt, cá, rau củ ..."
export const THUC_PHAM_KHO_THUC_PHAM_BAO_GOI_SAN_PHU_GIA_THUC_PHAM = "Nhóm thực phẩm khô và thực phẩm bao gói sẵn, phụ gia thực phẩm"
export const MAU_BIEU_GHI_CHEP_KIEM_THUC_BA_BUOC          = "MẪU BIỂU GHI CHÉP KIỂM THỰC BA BƯỚC"
export const THEO_DOI_LUU_MAU_VA_HUY_MAU_THUC_AN          = "THEO DÕI LƯU MẪU VÀ HỦY MẪU THỨC ĂN"
export const BUOC_1_KIEM_TRA_TRUOC_KHI_CHE_BIEN_THUC_AN   = "Bước 1 : Kiểm tra trước khi chế biến thức ăn"
export const BUOC_2_KIEM_TRA_KHI_CHE_BIEN_THUC_AN         = "Bước 2 : Kiểm tra  khi chế biến thức ăn"
export const BUOC_3_KIEM_TRA_TRUOC_KHI_AN                 = "Bước 3 : Kiểm tra trước khi ăn"


export const ADD_KIEM_THUC = "ADD_KIEM_THUC"
export const DISPATCH_LIST_TYPE_OF_KIEM_THUC_TO_STORE = "DISPATCH_LIST_TYPE_OF_KIEM_THUC_TO_STORE"
export const UPDATE_LIST_TYPE_OF_KIEM_THUC            = "UPDATE_LIST_TYPE_OF_KIEM_THUC"

export const OPEN_FORM_UPDATE = "OPEN_FORM_UPDATE"
export const CLOSE_FORM_UPDATE = "CLOSE_FORM_UPDATE"

export const DISPATCH_ACTIVE_UPDATE_KIEM_THUC     = "DISPATCH_ACTIVE_UPDATE_KIEM_THUC"

export const DISPATCH_LIST_MATERIAL_DATE_TO_STORE = "DISPATCH_LIST_MATERIAL_DATE_TO_STORE"
export const ADD_KIEM_THUC_BA_BUOC                             = "ADD_KIEM_THUC_BA_BUOC"
export const SET_ITEM_ADD_KIEM_THUC_TO_NULL = "SET_ITEM_ADD_KIEM_THUC_TO_NULL"
export const DISPATCH_ITEM_KIEM_THUC_BA_BUOC_TO_STORE_IN_DATE  = "DISPATCH_ITEM_KIEM_THUC_BA_BUOC_TO_STORE_IN_DATE"
export const DISPATCH_DATE_TIME_KIEM_THUC = "DISPATCH_DATE_TIME_KIEM_THUC"

export const GET_LIST_KIEM_THUC_BA_BUOC_IN_MONTH = "GET_LIST_KIEM_THUC_BA_BUOC_IN_MONTH"
export const DISPATCH_LIST_KIEM_THUC_BA_BUOC     = "DISPATCH_LIST_KIEM_THUC_BA_BUOC"
export const UPDATE_KIEM_THUC_BA_BUOC            =  "UPDATE_KIEM_THUC_BA_BUOC"
export const UPDATE_KIEM_THUC_BA_BUOC_AGAIN      =  "UPDATE_KIEM_THUC_BA_BUOC_AGAIN"
export const DISPATCH_UPDATE_KIEM_THUC_BA_BUOC   =  "DISPATCH_UPDATE_KIEM_THUC_BA_BUOC"

export const ADD_INFO_KIEM_THUC_BA_BUOC              = "ADD_INFO_KIEM_THUC_BA_BUOC"
export const DISPATCH_INFO_KIEM_THU_BA_BUOC_TO_STORE = "DISPATCH_INFO_KIEM_THU_BA_BUOC_TO_STORE"
export const GET_INFO_KIEM_THUC_BA_BUOC              = "GET_INFO_KIEM_THUC_BA_BUOC"

export const ADD_CO_SO_SAN_XUAT = "ADD_CO_SO_SAN_XUAT"
export const DISPATCH_ITEM_CO_SO_SAN_XUAT_TO_STORE = "DISPATCH_ITEM_CO_SO_SAN_XUAT_TO_STORE"
export const UPDATE_ITEM_CO_SO_SAN_XUAT = "UPDATE_ITEM_CO_SO_SAN_XUAT"

export const DISPATCH_LIST_CLASS_NUMBER = "DISPATCH_LIST_CLASS_NUMBER"