import {managerDiff,managerSell,managerRepo,managerStaff} from '../../../../APP_ADMIN_PERSON/Contant'

export const FindValueOfAppActive=(FilterItemAppActive,value)=>{
        switch (value.name) {
          case managerStaff:
              return FilterItemAppActive[0].managerStaff
          case managerRepo:
              return FilterItemAppActive[0].managerRepo
          case managerSell:
              return FilterItemAppActive[0].managerSell
          case managerDiff:
              return FilterItemAppActive[0].managerDiff          
          default: return null
        }
}

export const FindItemOfAppActiveToSave=(item,value,AppActive)=>{
  switch (item.name) {
    case managerStaff:
        return {
           _id:AppActive[0]._id,
           userId:AppActive[0].userId,
           //name:AppActive[0].name,
           managerStaff:value,
           managerRepo:AppActive[0].managerRepo,
           managerSell:AppActive[0].managerSell,
           managerDiff:AppActive[0].managerDiff           
        }
    case managerRepo:
      return {
        _id:AppActive[0]._id,
        userId:AppActive[0].userId,
        //name:AppActive[0].name,
        managerStaff:AppActive[0].managerStaff,
        managerRepo:value,
        managerSell:AppActive[0].managerSell,
        managerDiff:AppActive[0].managerDiff           
     }
    case managerSell:
      return {
        _id:AppActive[0]._id,
        userId:AppActive[0].userId,
        //name:AppActive[0].name,
        managerStaff:AppActive[0].managerStaff,
        managerRepo:AppActive[0].managerRepo,
        managerSell:value,
        managerDiff:AppActive[0].managerDiff           
     }
    case managerDiff:
      return {
        _id:AppActive[0]._id,
        userId:AppActive[0].userId,
        managerStaff:AppActive[0].managerStaff,
        managerRepo:AppActive[0].managerRepo,
        managerSell:AppActive[0].managerSell,
        managerDiff:value           
     }
    default: return null
  }
}

