import React,{Fragment} from 'react'
import style from './style'
import {withStyles} from '@material-ui/core'
import {connect} from 'react-redux'
import {bindActionCreators,compose} from 'redux'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import * as actionsUserPage from '../../../../actions/pageUser'
import UserPageForm from '../../../../container/Admin/InfoUserPageForm/index'




class TaskForm extends React.Component{
  render() {
    let {handleOpenCloseModal,classes,open,title,name,valueId} = this.props              
    let {hideFormUser} = handleOpenCloseModal
    return (
      <Fragment>
         <Dialog open={open} onClose={hideFormUser}  aria-labelledby="form-dialog-title">
          <div className={classes.bgcolor}>            
             <div className={classes.textCv}> {title?title:'Thêm mới bảng'}  </div>
             <CloseIcon className={classes.icon} onClick={hideFormUser}/>
          </div>
          <Fragment>
            <UserPageForm
                name={name}
                valueId={valueId}
            />
           </Fragment>
        </Dialog> 
    </Fragment>                  
    )
  }
}

const mapStateToProps = state =>{
      return {
        //initialValues:state.task.taskEditting,
        //task:state.task.taskEditting ? state.task.taskEditting:'',
        // status:state.task.taskEditting.status ? state.task.taskEditting.status:''      
        open:state.userPage.showFormUser,
        close:state.userPage.showForm,
        listUserNhaNuoc:state.userPage.listUserGroupNhaNuoc
      }
}

const mapDispatchStateToProps = dispatch =>{
  return{
      handleOpenCloseModal: bindActionCreators(actionsUserPage,dispatch)
  }
}

const withConnect = connect(mapStateToProps,mapDispatchStateToProps)




export default compose(
  withStyles(style),
  withConnect,   
  )(TaskForm)