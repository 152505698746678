import {API_ENDPOINT} from '../contant/index'
import AxiosServices from './api'
//import qs from 'query-string'

const url = 'tasks'

export const getListTask = ()=>{/*eslint no-const-assign:*/  
  // let query= ''
  // if(Object.keys(params).length>0){
  //    query = `?${qs.stringify(params)}`
  // }
  return AxiosServices.get(`${API_ENDPOINT}/${url}`)
}

export const addTask = (data)=>{
  return AxiosServices.post(`${API_ENDPOINT}/${url}`,data)
}

export const editTask =(data,taskId)=>{
  return AxiosServices.put(`${API_ENDPOINT}/${url}/${taskId}`,data)
}
export const deleteTask = (taskId)=>{
  return AxiosServices.delete(`${API_ENDPOINT}/${url}/${taskId}`)
}
