import { updateArrById } from '../../../../helper/fnAllways'
import { toastError, toastSuccess } from '../../../../helper/toastHelper'
import {DISPATCH_CREATE_CAN_DO_TO_STORE,DISPATCH_ADD_HOC_SINH_CAN_DO_TO_STORE, DISPATCH_ID_CLASS_TO_STORE_CAN_DO, DISPATCH_LIST_HOC_SINH_CAN_DO_TO_STORE,
UPLOAD_FILE_CAN_DO_FAIL,DISPATCH_MONTH_YEAR_CAN_DO_TO_STORE,UPDATE_HOC_SINH_CAN_DO,DISPATCH_UPDATE_HOC_SINH_CAN_DO_AGAIN, DELETE_HOC_SINH_CAN_DO
} from '../contant'

const initialState ={
    TimeCanDo:null,
    IdClassCanDo:null,
    ListHocSinhByClass:[],
    TimeCanDo:null,
    ItemClass:null
}

const IndexReducer = (state=initialState,action)=>{
    switch (action.type) {
        case DELETE_HOC_SINH_CAN_DO:
            toastSuccess('Xoá Thành Công')
            return{
                ...state,
                ListHocSinhByClass:state.ListHocSinhByClass.filter(v=>v._id!==action.payload.item._id)
            }
        case DISPATCH_UPDATE_HOC_SINH_CAN_DO_AGAIN:
            toastSuccess('Cập Nhật Thành Công')
            return{              
                    ...state,
                    ListHocSinhByClass:updateArrById(state.ListHocSinhByClass,action.payload.item)                
            }
        case UPDATE_HOC_SINH_CAN_DO:
            // toastSuccess('Cập Nhật Thành Công')
            return{
                ...state,
                ListHocSinhByClass:updateArrById(state.ListHocSinhByClass,action.payload.item)
            }
        case UPLOAD_FILE_CAN_DO_FAIL:
            toastError('Lỗi, dữ liệu tháng khởi tạo đã có. Kiểm Tra Lại')
            return{
                ...state,
            }
            
        case DISPATCH_CREATE_CAN_DO_TO_STORE:
            return{
                ...state,
                ItemCanDo:action.payload.item
            }           
        case DISPATCH_MONTH_YEAR_CAN_DO_TO_STORE:
            return{
                ...state,
                TimeCanDo:action.payload.item
            }
        case DISPATCH_ID_CLASS_TO_STORE_CAN_DO:
            let cha = `${state.TimeCanDo.valueMonth.length}`===1?`0${state.TimeCanDo.valueMonth}/${state.TimeCanDo.valueYear}`:`${state.TimeCanDo.valueMonth}/${state.TimeCanDo.valueYear}`
            let findItemClass = state.ListHocSinhByClass.filter(v=>v.idClass===action.payload.item&&
                v.monthCanDo===cha
                )
            return{
                ...state,
                IdClassCanDo:action.payload.item,
                ItemClass:findItemClass
            }
        case DISPATCH_ADD_HOC_SINH_CAN_DO_TO_STORE:
            toastSuccess('Upload Thành Công')
            return{
                ...state,
                ListHocSinhByClass:state.ListHocSinhByClass.concat(action.payload.item)
            }
        case DISPATCH_LIST_HOC_SINH_CAN_DO_TO_STORE:
            return{
                ...state,
                ListHocSinhByClass:action.payload.item
            }
    
        default: return state
            
    }
}

export default IndexReducer