import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Button, DialogActions, TextField } from '@material-ui/core';

const color = '#2c3e50'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


export default function Index({open,handleClose}) {

    const [age,setAge]         = useState(0)
    const [Height,setHeight]   = useState(0)
    const [Weight,setWeight]   = useState(0)
    const [errHeight,setErrHeight] = useState(false)
    const [errWeight,setErrWeight] = useState(false)
    const [result,setResult]       = useState("")
    const [numberBMI,setNumberBMI] = useState(0)

    const ResultItem=()=>{
        if(Height===0||Height===null||Height===undefined){
            setErrHeight(true)
            setTimeout(()=>{
                setErrHeight(false)
            },3000)
            return
        }
        if(Weight===0||Weight===null||Weight===undefined){
            setErrWeight(true)
            setTimeout(()=>{
                setErrWeight(false)
            },3000)
            return
        }
        let item = ((+Weight) / (((+Height)/100) * ((+Height)/100))).toFixed(2)
        if(item<18.5){
            setResult('Gầy')
            setNumberBMI(item)
            setTimeout(()=>{
                setResult("")
                setNumberBMI(0)
            },5000)
        }
        if(item>18.5&&item<24.9){
            setResult('Bình Thường')
            setNumberBMI(item)
            setTimeout(()=>{
                setResult("")
                setNumberBMI(0)
            },5000)
        }
        if(item===25){
            setResult('Thừa Cân')
            setNumberBMI(item)
            setTimeout(()=>{
                setResult("")
                setNumberBMI(0)
            },5000)
        }
        if(item>25&&item<29.9){
            setResult('Tiền Béo Phì')
            setNumberBMI(item)
            setTimeout(()=>{
                setResult("")
                setNumberBMI(0)
            },5000)
        }
        if(item>30&&item<34.9){
            setResult('Béo Phì Độ 1')
            setNumberBMI(item)
            setTimeout(()=>{
                setResult("")
                setNumberBMI(0)
            },5000)
        }
        if(item>35&&item<39.9){
            setResult('Béo Phì Độ 2')
            setNumberBMI(item)
            setTimeout(()=>{
                setResult("")
                setNumberBMI(0)
            },5000)
        }
        if(item>=40){
            setResult('Béo Phì Độ 3')
            setNumberBMI(item)
            setTimeout(()=>{
                setResult("")
                setNumberBMI(0)
            },5000)
        }
    }

  return (
    <div>     
      <Dialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle style={{color:'green'}} id="customized-dialog-title" onClose={handleClose}>
          Tính BMI Dinh Dưỡng Học Đường
        </DialogTitle>
        <DialogContent dividers>  
            <div>
                  <TextField
                        size='small'
                        fullWidth
                        variant="filled"
                        label="Độ tuổi"
                        style={{margin:5}}
                    />
            </div>   
            <div>
                  <TextField
                  size='small'
                  fullWidth
                  variant="filled"
                  label="Chỉ Số Chiều Cao/m"
                  style={{margin:5}}
                  error={errHeight}
                  type="number"
                  onChange={e=>setHeight(e.target.value)}

              />
            </div>

            <div>
               <TextField
                size='small'
                fullWidth
                variant="filled"
                label="Chỉ Số Cân Nặng/Kg"
                style={{margin:5}}
                error={errWeight}
                type="number"
                onChange={e=>setWeight(e.target.value)}
            />
            </div>         
            
        </DialogContent>     
        <DialogActions>
          <Button autoFocus onClick={()=>ResultItem()} color="primary">
           Hiện Kết Quả
          </Button>
          {result!==""&&<Typography style={{color:'red'}}>BMI:{numberBMI}&nbsp;{result}</Typography>
           }
        </DialogActions>
      </Dialog>
    </div>
  );
}
