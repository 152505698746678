import {DISPATCH_LIST_MANAGER_ACCOUNT_ACTIVE_TO_STORE,DISPATCH_ADD_LIST_MANAGER_ACCOUNT_TO_STORE,
  DISPATCH_UPDATE_LIST_MANAGER_ACCOUNT,OPEN_FORM_ACTIVE_MANAGER_ACCOUNT,CLOSE_FORM_ACTIVE_MANAGER_ACCOUNT,
  DISPATCH_ACTIVE_ACCOUNT_SOCKET,DISPATCH_LIST_STUDEENT_BY_CLASS,DISPATCH_LIST_CLASS_ACCOUNT_APPROVAL_TO_STORE,
  DISPATCH_SEND_SOCKET_CHECK_FOOD_TO_MANAGER_ACCOUNT,DISPATCH_UPDATE_APPROVAL_CHECK_DAY_FOOD, UPDATE_APPROVAL_SUCCESS,DATE_INPUT,
  DISPATCH_ITEM_MANAGER_ACCOUNT_BY_USER_ID,DISPATCH_REQUEST_MANAGER_CLASS,OPEN_CHECK_DAY_FOOD_CLASS,CLOSE_CHECK_DAY_FOOD_CLASS,
  DISPATCH_LIST_CLASS_CHECK_DAY_FOOD_BY_DATE,
  OPEN_FORM_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE,CLOSE_FORM_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE,
  OPEN_ALERT_FORM_CHECK_DAY_FOOD,CLOSE_ALERT_FORM_CHECK_DAY_FOOD,DISPATCH_FIND_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE,
  ACCEPT_UPDATE_APPROVAL_FROM_CLASS,DISPATCH_GET_INFO_LIST_OF_USERID_TO_STORE,
  OPEN_PRINT_CHECK_DAY_FOOD,CLOSE_PRINT_CHECK_DAY_FOOD,OPEN_ALERT_LIST_CLASS_NOT_CHECK_DAY_FOOD,CLOSE_ALERT_LIST_CLASS_NOT_CHECK_DAY_FOOD,
  DISPATCH_LIST_CLASS_STUDENT_LEAVE_TEMPORATY_TO_STORE,OPEN_PRINT_ALL_CHECK_DAY_FOOD,CLOSE_PRINT_ALL_CHECK_DAY_FOOD,
  DISPATCH_LIST_CHECK_DAY_FOOD_BY_MONTH,
  DISPATCH_LIST_STU_SEND_MESS_TO_STORE,DISPATCH_LIST_STU_SEND_MESS_INTO_MANAGER_ACCOUNT,
  DISPATCH_ADD_STU_SEND_MESS_MANAGER_ACOUNT_TO_STORE,DISPATCH_ITEM_INFO_MESSAGE_AUTO_TO_STORE, UPDATE_ITEM_INFO_MESSAGE_AUTO_TO_STORE,
  OPEN_UPDATE_INFO_MESSAGE_AUTO,
  CLOSE_UPDATE_INFO_MESSAGE_AUTO,UPDATE_LIST_STU_SEND_MESS_MANAGET_ACCOUNT,
  STUDENT_INFO_PAYMENT_TO_MANAGER_ACCOUNT,
  UPDATE_ITEM_LIST_STU_SEND_MESS_MANAGER_ACCOUNT,UPDATE_MANAGER_ACCOUNT_CLASS, UPDATE_ITEM_LIST_STU_SEND_MESS_MANAGER_ACCOUNT_AGAIN, UPLOAD_LIST_STUDENT_SEND_SMS_ZALO,
  DISPATCH_ITEM_LIST_STUDENT_TO_UPLOAD_EXCEL,
  REMOVE_ITEM_LIST_STUDENT_TO_UPLOAD_EXCEL,DISPATCH_FIND_ITEM_CLASS_SEND_SMS_ZALO_PRV_MONTH,
  DISPATCH_ITEM_KHOAN_THU_TO_STORE,DISPATCH_ADD_KHOAN_THU_TO_STORE, DISPATCH_ITEM_STUDENT_REGIS_KHOAN_THU_TO_STORE,UPDATE_STUDENT_REGIS_KHOAN_THU, CHANGE_MONTH_QUAN_LY
} from '../contant/ManagerAccount'
import {OPEN_FORM_CREATE_ACCOUNT_CLASS,CLOSE_FORM_CREATE_ACCOUNT_CLASS,DISPATCH_ITEM_CREATE_ACCOUNT_TO_STORE,OPEN_FORM_VIEW_ACCOUNT_CLASS,CLOSE_FORM_VIEW_ACCOUNT_CLASS,UPDATE_ITEM_CREATE_ACCOUNT_NOT_SAVE,
DISPATCH_GET_LIST_ACTIVE_ACCOUNT_TO_STORE,
DISPATCH_ITEM_MANAGER_ACCOUNT_CLASS_ACTIVE_TO_STORE,OPEN_FORM_EMPTY_COUNT_EAT,CLOSE_FORM_EMPTY_COUNT_EAT,
DISPATCH_ITEM_MENU_WEEKLY_TO_STORE,DISPATCH_ID_CLASS_ACTIVE_TO_STORE,DISPATCH_ACTIVE_ALL_ACCOUNT_ALL_TO_STORE,
DISPATCH_ITEM_APPROVAL_CHECK_DAY_FOOD_TO_STORE,DISPATCH_ITEM_APPROVAL_CHECK_DAY_FOOD_TO_STORE_FROM_DB,
DISPATCH_UPDATE_APPROVAL_FROM_CLASS,OPEN_ALERT_LIST_STUDENT_NOT_REGIS,CLOSE_ALERT_LIST_STUDENT_NOT_REGIS,
OPEN_LIST_STUDENT_NOT_EAT,CLOSE_LIST_STUDENT_NOT_EAT,OPEN_UPDATE_APPROVAL_CHECK_DAY_FOOD,CLOSE_UPDATE_APPROVAL_CHECK_DAY_FOOD,
OPEN_COUNT_EAT_CHECK_DAY_FOOD,CLOSE_COUNT_EAT_CHECK_DAY_FOOD,DISPATCH_DATE_INPUT_TO_UPDATE_COUNT_EAT,
DISPATCH_CANCEL_UPDATE_COUNT_EAT,DISPATCH_CHANGE_UPDATE_CHECK_DAY_FOOD,OPEN_CHECK_DAY_FOOD_COUNT_EAT,CLOSE_CHECK_DAY_FOOD_COUNT_EAT,
DISPATCH_LIST_LEAVE_TEMPORATY_BY_CLASS_TO_STORE,DISPATCH_ITEM_STU,CLOSE_DISPATCH_ITEM_STU,
DISPATCH_ADD_STU_MESS_TO_STORE,UPDATE_ITEM_STU_SEND_MESS,ITEM_CLASS_TO_STORE
} from '../contant/Class'
import {updateArrById} from '../../helper/fnAllways'
import {CreateNewArrListActiveAccount,updateArrApproval,CheckExitManagerClass} from './helper'
import _ from 'lodash'
import {toastSuccess} from '../../helper/toastHelper'
import { showMM, showMMYY } from '../../helper/dateTime/handleDMY'
import { handleNewItemListStuMessSend, HanldeDupplicate } from './Fn'

export const initialState={
  DateInput:"",
  ListClassCheckDyFoodByDate:[],
  ListManagerActiveAccount:[],
  OpenCheckDayFood:false,
  SelectDate:'',
  OpenAlertCheckDayFood:false,
  ItemManagerAccountByUserId:"",
  ItemCheckAccount:false,
  OpenFormActiveManagerAccount:false,
  OpenFormCreateAccount:false,
  OpenFormViewAccount:false,
  ItemCreateAccount:false,
  ListCreateAccountNotSave:[],
  ListCreateAccountSave:[],
  OpenFormEmptyCountEat:false,
  ItemManagerActiveAccount:"",
  ResultListStudent:"",
  ValueOpen:0,
  ValueCountEat:"",
  ItemMenuWeekly:'',
  userIdCurrent:"",//id tk cua lop
  ListApprovalCheckDayFood:[],  
  ItemCheckDayFood:[],
  OpenAlertNotRegis:false,
  OpenListStuNotEat:false,
  OpenCheckDayFoodClass:false,
  OpenUpdateApprovalCheckDayFood:false,
  CountEat:0,
  SwitchUpdateOrAdd:0,
  OpenCheckDayFoodCountEat:false,
  ListInfoUser:{},
  OpenPrintCheckDayFood:false,
  ListClassNotCheckDayFood:{
    open:false,
    List:[]
  },
  ItemClassLeaveTemporaty:[],
  ListStudentLeaveTemporaty:[],
  ListCheckDayFoodByMonth:[],
  OpenPrintAllCheckDayFood:false,
  ListStuByClassToSendMess:[],
  ItemStu:null,
  ItemStuSendMess:null,
  ListStuSendMess:[],
  ItemListStuMessSend:null,
  ItemInfoMessageAuto:null,
  OpenUpdateInfoMessageAuto:false,
  ItemOpenUpdateInfoMessageAuto:null,
  ListStudentPayment:[],
  ItemCreateAccount:[],
  ItemStudentUploadExcel:null,
  ItemKhoanThu:null,
  ItemStudentRegisKhoanThu:null,
  ItemClass:null,
  ChangeMonth:+showMM(Date.mow)
}

const IndexReducer=(state=initialState,action)=>{
    switch (action.type) {      
      case CHANGE_MONTH_QUAN_LY:
        return{
          ...state,
          ChangeMonth:action.payload.item
        }
      case ITEM_CLASS_TO_STORE:
        return{
          ...state,
          ItemClass:action.payload.item
        }

      case UPDATE_STUDENT_REGIS_KHOAN_THU:
        return{
          ...state,
          ItemStudentRegisKhoanThu:action.payload.item
        }

      case DISPATCH_ITEM_STUDENT_REGIS_KHOAN_THU_TO_STORE:
        return{
          ...state,
          ItemStudentRegisKhoanThu:action.payload.item
        }      

      case DISPATCH_ADD_KHOAN_THU_TO_STORE:
        toastSuccess('Cập nhật Thành Công')
        return{
          ...state,
          ItemKhoanThu:action.payload.item
        }

      case DISPATCH_ITEM_KHOAN_THU_TO_STORE:
        return{
          ...state,
          ItemKhoanThu:action.payload.item
        }

      case DISPATCH_FIND_ITEM_CLASS_SEND_SMS_ZALO_PRV_MONTH:
        if(action.payload.item.length!==0){
          let resultItem = handleNewItemListStuMessSend(state.ItemListStuMessSend,action.payload.item)
              return{
              ...state,
              ItemListStuMessSend:resultItem
            }                
        }
        if(action.payload.item.length===0){
          return{
            ...state,
            ItemListStuMessSend:state.ItemListStuMessSend
          }
      }        
       
      case UPLOAD_LIST_STUDENT_SEND_SMS_ZALO:
        return{
          ...state,
          ItemListStuMessSend:action.payload.item,
          ListStuSendMess:updateArrById(state.ListStuSendMess,action.payload.item)
        }

      case DISPATCH_ITEM_LIST_STUDENT_TO_UPLOAD_EXCEL:
        return{
          ...state,
          ItemStudentUploadExcel:action.payload.item
        }

      case REMOVE_ITEM_LIST_STUDENT_TO_UPLOAD_EXCEL:
        return{
          ...state,
          ItemStudentUploadExcel:null
        }
        
      case UPDATE_ITEM_LIST_STU_SEND_MESS_MANAGER_ACCOUNT_AGAIN:
          toastSuccess('Cập nhật thành công!')
          return{
            ...state,
            ListStuSendMess:action.payload.item
          }

      case STUDENT_INFO_PAYMENT_TO_MANAGER_ACCOUNT:

        if(showMMYY(action.payload.item.DateInput)!==showMMYY(state.DateInput)){
         alert(`Tháng Báo cáo không phù hợp ${showMMYY(action.payload.item.DateInput)}`)          
         return{
             ...state,
         }
        }      
        if(showMMYY(action.payload.item.DateInput)===showMMYY(state.DateInput)){         
           let Result  = state.ListStudentPayment.concat(action.payload.item)
           let REsult = HanldeDupplicate(Result)
          return{
              ...state,
              ListStudentPayment:REsult        
          }
      }   
       
      case CLOSE_UPDATE_INFO_MESSAGE_AUTO:
        return{
          ...state,
          OpenUpdateInfoMessageAuto:false,
          ItemOpenUpdateInfoMessageAuto:null
        }
      case OPEN_UPDATE_INFO_MESSAGE_AUTO:
        return{
          ...state,
          OpenUpdateInfoMessageAuto:true,
          ItemOpenUpdateInfoMessageAuto:action.payload.item
        }
      case UPDATE_ITEM_INFO_MESSAGE_AUTO_TO_STORE:
        toastSuccess('Cập nhật thành công!')
        return{
          ...state,
          ItemInfoMessageAuto:action.payload.item
        }
      case DISPATCH_ITEM_INFO_MESSAGE_AUTO_TO_STORE:
        return{
          ...state,
          ItemInfoMessageAuto:action.payload.item
        }
      case UPDATE_LIST_STU_SEND_MESS_MANAGET_ACCOUNT:
          return{
            ...state,
            ListStuSendMess:action.payload.item
          }
      case DISPATCH_ADD_STU_SEND_MESS_MANAGER_ACOUNT_TO_STORE:
        return{
          ...state,
          ListStuSendMess:state.ListStuSendMess.concat(action.payload.item)
        }
      case DISPATCH_LIST_STU_SEND_MESS_INTO_MANAGER_ACCOUNT://manageraccount
        return{
          ...state,
          ItemListStuMessSend:action.payload.item
        }
      case DISPATCH_LIST_STU_SEND_MESS_TO_STORE://manageraccount
        return{
          ...state,
          ListStuSendMess:action.payload.item
        }
      case UPDATE_ITEM_STU_SEND_MESS://account
        toastSuccess('Cập nhật thành công!')
        return{
          ...state,
          ItemStuSendMess:action.payload.item,
        }
        case UPDATE_ITEM_LIST_STU_SEND_MESS_MANAGER_ACCOUNT:
          toastSuccess('Cập nhật thành công!')
          return{
            ...state,
            ListStuSendMess:updateArrById(state.ListStuSendMess,action.payload.item)
          }
      case DISPATCH_ADD_STU_MESS_TO_STORE:
          return{
            ...state,
            ItemStuSendMess:action.payload.item
          }
      
      case CLOSE_DISPATCH_ITEM_STU:
        return{
          ...state,
          ItemStu:null
        }
      case DISPATCH_ITEM_STU:
        return{
          ...state,
          ItemStu:action.payload.item
        }
      case DATE_INPUT:
        return{
          ...state,
          DateInput:action.payload.item
        }
      case DISPATCH_ID_CLASS_ACTIVE_TO_STORE:
        return{
          ...state,
          userIdCurrent:action.payload.item
        }
      case DISPATCH_LIST_MANAGER_ACCOUNT_ACTIVE_TO_STORE:        
       return{
         ...state,
         ListManagerActiveAccount:action.payload.item,
       }
       case DISPATCH_ADD_LIST_MANAGER_ACCOUNT_TO_STORE:
         return{
           ...state,
           ListManagerActiveAccount:state.ListManagerActiveAccount.concat(action.payload.item)
         }
      case DISPATCH_UPDATE_LIST_MANAGER_ACCOUNT:
        return{
          ...state,
          ListManagerActiveAccount:updateArrById(state.ListManagerActiveAccount,action.payload.item)
        }
      case DISPATCH_ACTIVE_ACCOUNT_SOCKET:
        return{
          ...state,
          ListManagerActiveAccount:updateArrById(state.ListManagerActiveAccount,action.payload.item)
        }
      case OPEN_FORM_ACTIVE_MANAGER_ACCOUNT:
        return{
          ...state,
          OpenFormActiveManagerAccount:true,
          ValueOpen:1
        }
      
      case CLOSE_FORM_ACTIVE_MANAGER_ACCOUNT:
        return{
          ...state,
          OpenFormActiveManagerAccount:false
        }   
      
      case OPEN_FORM_CREATE_ACCOUNT_CLASS:
        return{
          ...state,
          OpenFormCreateAccount:true,
          ItemCreateAccount:action.payload.item,
          ValueOpen:1

        }
      case CLOSE_FORM_CREATE_ACCOUNT_CLASS:
        return{
          ...state,
          OpenFormCreateAccount:false,
          ValueOpen:0
        }
      case OPEN_FORM_VIEW_ACCOUNT_CLASS:
        return{
          ...state,
          OpenFormViewAccount:true,
          ItemCreateAccount:action.payload.item,
          ValueOpen:2
        }
        case CLOSE_FORM_VIEW_ACCOUNT_CLASS:
          return{
            ...state,
            OpenFormViewAccount:false,
            ValueOpen:0
          }
      case DISPATCH_ITEM_CREATE_ACCOUNT_TO_STORE:
        return{
          ...state,
          ListCreateAccountNotSave:state.ListCreateAccountNotSave.concat(action.payload.item),
        }    
        case UPDATE_ITEM_CREATE_ACCOUNT_NOT_SAVE:         
          return{
            ...state,
            ListCreateAccountNotSave:updateArrById(state.ListCreateAccountNotSave,action.payload.item)
          }
          case UPDATE_MANAGER_ACCOUNT_CLASS:
            return{
              ...state,
              ListCreateAccountSave:CreateNewArrListActiveAccount(action.payload.item[0].listAccount),
              ItemCreateAccount:action.payload.item
            }

        case DISPATCH_ACTIVE_ALL_ACCOUNT_ALL_TO_STORE:
          return{
            ...state,
            ListCreateAccountSave:CreateNewArrListActiveAccount(action.payload.item[0].listAccount)
          }
        case DISPATCH_GET_LIST_ACTIVE_ACCOUNT_TO_STORE:
          return{
            ...state,
            ItemCreateAccount:action.payload.item,
            ListCreateAccountSave:CreateNewArrListActiveAccount(action.payload.item[0].listAccount)
          }
       case DISPATCH_ITEM_MANAGER_ACCOUNT_CLASS_ACTIVE_TO_STORE:
         return{
           ...state,
           ItemManagerActiveAccount:action.payload.item
         }
        case DISPATCH_LIST_STUDEENT_BY_CLASS:
          return{
            ...state,
            ResultListStudent:action.payload.item
          }
          
         case OPEN_FORM_EMPTY_COUNT_EAT:
           return{
             ...state,
             OpenFormEmptyCountEat:true
           }
        case CLOSE_FORM_EMPTY_COUNT_EAT:
          return{
            ...state,
            OpenFormEmptyCountEat:false
          }
        case DISPATCH_ITEM_MENU_WEEKLY_TO_STORE:
          return{
            ...state,
            ItemMenuWeekly:action.payload.item
          }
        case DISPATCH_ITEM_APPROVAL_CHECK_DAY_FOOD_TO_STORE:          
          let result3 = state.ListApprovalCheckDayFood===undefined?[]:state.ListApprovalCheckDayFood
          return{
            ...state,
            ListApprovalCheckDayFood:result3.concat(action.payload.item)            
          }
        case DISPATCH_ITEM_APPROVAL_CHECK_DAY_FOOD_TO_STORE_FROM_DB:
          return{
            ...state,
            ListApprovalCheckDayFood:state.ListApprovalCheckDayFood.concat(action.payload.item)
          }
        case DISPATCH_LIST_CLASS_ACCOUNT_APPROVAL_TO_STORE:
          return{
            ...state,
            ListApprovalCheckDayFood:action.payload.item
          }

        case DISPATCH_SEND_SOCKET_CHECK_FOOD_TO_MANAGER_ACCOUNT:
          //let filterNewArr = state.ListApprovalCheckDayFood.filter(v=>v.userClass!==action.payload.item.userClass)
          let newArr       = _.union(state.ListApprovalCheckDayFood,[action.payload.item])
          return{
            ...state,
            ListApprovalCheckDayFood:newArr
          }
          case ACCEPT_UPDATE_APPROVAL_FROM_CLASS:
            let result2 =  updateArrApproval(state.ListApprovalCheckDayFood,action.payload.item)
            return{
              ...state,
              ListApprovalCheckDayFood:result2===undefined?[]:result2
            }
        case DISPATCH_UPDATE_APPROVAL_CHECK_DAY_FOOD: 
          let result1 =  updateArrApproval(state.ListApprovalCheckDayFood,action.payload.item)
          return {
            ...state,
            ListApprovalCheckDayFood:result1===undefined?[]:result1
          }

        case UPDATE_APPROVAL_SUCCESS:
          toastSuccess('Đã phê duyệt')
          return{
            ...state
          }
        case DISPATCH_UPDATE_APPROVAL_FROM_CLASS:         
          let result =  updateArrApproval(state.ListApprovalCheckDayFood,action.payload.item)
          return{
            ...state,
            ListApprovalCheckDayFood:result===undefined?[]:result
          }
        case OPEN_ALERT_LIST_STUDENT_NOT_REGIS:
          return{
            ...state,
            OpenAlertNotRegis:true
          }
        case CLOSE_ALERT_LIST_STUDENT_NOT_REGIS:
          return{
            ...state,
            OpenAlertNotRegis:false
          }
        case OPEN_LIST_STUDENT_NOT_EAT:
          return{
            ...state,
            OpenListStuNotEat:true
          }
          case CLOSE_LIST_STUDENT_NOT_EAT:
            return{
              ...state,
              OpenListStuNotEat:false
            }
          case DISPATCH_ITEM_MANAGER_ACCOUNT_BY_USER_ID:
            return{
              ...state,
              ItemManagerAccountByUserId:action.payload.item
            }
          case DISPATCH_REQUEST_MANAGER_CLASS:
            return{
              ...state,
              ItemCheckAccount:CheckExitManagerClass(state.ItemManagerActiveAccount,state.ItemManagerAccountByUserId,action.payload.item)
            }
          case OPEN_CHECK_DAY_FOOD_CLASS:
            return{
              ...state,
              OpenCheckDayFoodClass:true
            }
            case CLOSE_CHECK_DAY_FOOD_CLASS:
              return{
                ...state,
                OpenCheckDayFoodClass:false
              }
            case DISPATCH_LIST_CLASS_CHECK_DAY_FOOD_BY_DATE:
              return{
                ...state,
                ListClassCheckDyFoodByDate:action.payload.item
              }
            case OPEN_FORM_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE:
              return{
                ...state,
                OpenCheckDayFood:true
              }
              case CLOSE_FORM_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE:
                return{
                  ...state,
                  OpenCheckDayFood:false
                }
              case OPEN_ALERT_FORM_CHECK_DAY_FOOD:
                return{
                  ...state,
                  OpenAlertCheckDayFood:true
                }
            case CLOSE_ALERT_FORM_CHECK_DAY_FOOD:
              return{
                ...state,
                OpenAlertCheckDayFood:false
              }
            case DISPATCH_FIND_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE:
              return{
                ...state,
                SelectDate:action.payload.item.dateNow
              }
            case OPEN_UPDATE_APPROVAL_CHECK_DAY_FOOD:
              return{
                ...state,
                OpenUpdateApprovalCheckDayFood:true
              }
            case CLOSE_UPDATE_APPROVAL_CHECK_DAY_FOOD:
              return{
                ...state,
                OpenUpdateApprovalCheckDayFood:false
              }
            case OPEN_COUNT_EAT_CHECK_DAY_FOOD:
              return{
                ...state,
                CountEat:1
              }
            case CLOSE_COUNT_EAT_CHECK_DAY_FOOD:
              return{
                ...state,
                CountEat:0
              }
            case DISPATCH_DATE_INPUT_TO_UPDATE_COUNT_EAT:
              return{
                ...state,
                DateInput:action.payload.item,
                CountEat:1,
                SwitchUpdateOrAdd:1
              }
            case DISPATCH_CANCEL_UPDATE_COUNT_EAT:
              return{
                ...state,
                CountEat:0,
                SwitchUpdateOrAdd:0
              }
            case DISPATCH_CHANGE_UPDATE_CHECK_DAY_FOOD:
              return{
                ...state,
                ListApprovalCheckDayFood:action.payload.item
              }
            case OPEN_CHECK_DAY_FOOD_COUNT_EAT:
              return{
                ...state,
                OpenCheckDayFoodCountEat:true
              }
            case CLOSE_CHECK_DAY_FOOD_COUNT_EAT:
              return{
                ...state,
                OpenCheckDayFoodCountEat:false
              }
            case DISPATCH_GET_INFO_LIST_OF_USERID_TO_STORE:
              return{
                ...state,
                ListInfoUser:action.payload.item
              }
            case OPEN_PRINT_CHECK_DAY_FOOD:
              return{
                ...state,
                OpenPrintCheckDayFood:true
              }
              case CLOSE_PRINT_CHECK_DAY_FOOD:
                return{
                  ...state,
                  OpenPrintCheckDayFood:false
                }
              case OPEN_ALERT_LIST_CLASS_NOT_CHECK_DAY_FOOD:
                return{
                  ...state,
                  ListClassNotCheckDayFood:{
                    open:true,
                    List:action.payload.item
                  }
                }
              case CLOSE_ALERT_LIST_CLASS_NOT_CHECK_DAY_FOOD:
                return{
                  ...state,
                  ListClassNotCheckDayFood:{
                    open:false,
                    List:[]
                  }
                }
              case DISPATCH_LIST_LEAVE_TEMPORATY_BY_CLASS_TO_STORE:
                return{
                  ...state,
                  ItemClassLeaveTemporaty:action.payload.item
                }
              case DISPATCH_LIST_CLASS_STUDENT_LEAVE_TEMPORATY_TO_STORE:
                return{
                  ...state,
                  ListStudentLeaveTemporaty:action.payload.item
                }           
                case DISPATCH_LIST_CHECK_DAY_FOOD_BY_MONTH:
                  return{
                    ...state,
                    ListCheckDayFoodByMonth:action.payload.item
                  }
              case OPEN_PRINT_ALL_CHECK_DAY_FOOD:
                return{
                  ...state,
                  OpenPrintAllCheckDayFood:true
                }
                case CLOSE_PRINT_ALL_CHECK_DAY_FOOD:
                  return{
                    ...state,
                    OpenPrintAllCheckDayFood:false
                  }
      default: return state
    }
}

export default IndexReducer