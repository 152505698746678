import {call, put, takeLatest} from 'redux-saga/effects'
import { loadingFail, loadingSuccess } from '../../../../actions/uiLoading'
//import { LoadingStop,LoadingNow } from '../../../../APP_ADMIN_PERSON/Actions/helper'
import { DispatchListPaymentKidtopToStore, DispatchThanhToanByUserToStore, EmailSendNppSuccess } from '../action/thanhToan'
import { GetThanhToanByUserApi,UpdateThanhToanByUserApi,SendEmailNppNameBuyApi, GetAddPaymentKidTopApi, UpdatePaymentKidTopApi } from '../api/thanhToan'
import {  GET_ADD_PAYMENT_KIDTOP, GET_THANH_TOAN_BY_USER ,UPDATE_PAYMENT_KIDTOP,UPDATE_THANH_TOAN_BY_USER,UPDATE_THANH_TOAN_BY_USER_GIA_HAN} from '../contant/ThanhToan'

const SUCCESS = 'success'
const FAIL    = 'fail'

function* ThanhToanByUserSaga({payload}){
    let res = yield call(GetThanhToanByUserApi,payload.item)
    if(res.data.result="success"){
        yield put(DispatchThanhToanByUserToStore(res.data.data))
    }    
}

function* UpdateThanhToanByUserSaga({payload}){
     yield call(UpdateThanhToanByUserApi,payload.item)
}

function* UpdateThanhToanByUserGiaHanSaga({payload}){
    let itemSendDB = {ItemId:payload.item.ItemId,ItemBuy:payload.item.itemBuy}
    yield call(UpdateThanhToanByUserApi,payload.item.result)
    yield put(loadingSuccess())
    let res = yield call(SendEmailNppNameBuyApi,itemSendDB)
    //console.log(res)
    if(res.data.result===SUCCESS){
        yield put(loadingFail())
        yield put(EmailSendNppSuccess(true))
    }
    if(res.data.result===FAIL){
        yield put(loadingFail())
        yield put(EmailSendNppSuccess(false))
    }
}

function* GetAddPaymentKidTopSaga({payload}){    
    let res = yield call(GetAddPaymentKidTopApi,payload.item)
    if(res.data.result===SUCCESS){
       yield put(DispatchListPaymentKidtopToStore(res.data.data))
    }
}

function* UpdatePaymentKidtopSaga({payload}){
    let res = yield call(UpdatePaymentKidTopApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(DispatchListPaymentKidtopToStore(res.data.data))
     }
}



function* Index(){
    yield takeLatest(UPDATE_PAYMENT_KIDTOP,UpdatePaymentKidtopSaga)
    yield takeLatest(GET_ADD_PAYMENT_KIDTOP,GetAddPaymentKidTopSaga)
    yield takeLatest(GET_THANH_TOAN_BY_USER,ThanhToanByUserSaga)
    yield takeLatest(UPDATE_THANH_TOAN_BY_USER,UpdateThanhToanByUserSaga)
    yield takeLatest(UPDATE_THANH_TOAN_BY_USER_GIA_HAN,UpdateThanhToanByUserGiaHanSaga)
}

export default Index