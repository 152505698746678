import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import format from "date-fns/format";
import viLocale from "date-fns/locale/vi";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import {Button,Dialog,DialogActions,DialogContent} from '@material-ui/core';
import {Alert} from '@material-ui/lab'
import {DISPATCH_FIND_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE} from '../../contant/ManagerAccount'

const localeMap = {  
  vi: viLocale
};

class ViLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "dd MMM yyyy", { locale: this.locale });
  }
}

const localeUtilsMap = {   
  vi: ViLocalizedUtils
};

const localeFormatMap = { 
  vi: "d MMM yyyy"
};

const localeCancelLabelMap = {  
  vi: "Hủy"
};

export default function Index({open,handleClose}) { 
  const dispatch = useDispatch()
  const [locale]                      = useState("vi");
  const [selectedDate, setDateChange] = useState(new Date()); 
  const ItemManagerActiveAccount      = useSelector(state=>state.ManagerAccount.ItemManagerActiveAccount)
  const showCheckDayFoodClass=()=>{
        let item = {
          userId:ItemManagerActiveAccount[0].userId,
          dateNow:selectedDate
        }
        dispatch({
          type:DISPATCH_FIND_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE,
          payload:{item}
        })
  }
  return (
     
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Alert severity="info">Xem Báo Ăn</Alert>
        <DialogContent>
        <MuiPickersUtilsProvider utils={localeUtilsMap[locale]} locale={localeMap[locale]}>
                                  <KeyboardDatePicker
                                    value={selectedDate}
                                    onChange={setDateChange}
                                    format={localeFormatMap[locale]} 
                                    cancelLabel={localeCancelLabelMap[locale]} 
                                    label="Chọn ngày xem báo ăn"                  
                                  >                                 
                                 </KeyboardDatePicker>
                 </MuiPickersUtilsProvider> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" size="small" variant="contained">
            Thoát
          </Button>        
          <Button onClick={()=>showCheckDayFoodClass()} size="small" variant="contained" color="primary">Xem</Button>
        </DialogActions>
      </Dialog>
  )
}
