import {fork,all} from 'redux-saga/effects'
import AppSaga from './app'
import TypeOfSaga from './typeOfService'

function* appServiceSaga(){
  yield all(
    [
        yield fork(AppSaga),
        yield fork(TypeOfSaga)
    ]
  )
}

export default appServiceSaga