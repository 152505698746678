import { ALERT_REGIS_ACCOUNT,CLOSE_ALERT,LOADING_START,LOADING_STOP, OPEN_VIDEO_YOUTUBE} from "../contant/GioiThieu";
let initialState={
    AlertGioiThieu:null,
    OpenAlert:false,
    ValueAlert:null,
    Loading:false,
    ValueItem:null,
    ValueOpenYoutube:null
}
const IndexReducer =(state=initialState,action)=>{
    switch (action.type) {
        case OPEN_VIDEO_YOUTUBE:
            return{
                ...state,
                ValueOpenYoutube:action.payload.item
            }
        case ALERT_REGIS_ACCOUNT:
            return{
                ...state,
                AlertGioiThieu:action.payload.item.alert,
                ValueAlert:action.payload.item.value,
                OpenAlert:action.payload.item.open,
                ValueItem:action.payload.item.valueItem
            }
        case CLOSE_ALERT:
            return{
                ...state,
                AlertGioiThieu:null,
                OpenAlert:false,
                ValueAlert:null,
                ValueItem:null
            }
        case LOADING_START:
            return{
                ...state,
                Loading:true
            }
        case LOADING_STOP:
            return{
                ...state,
                Loading:false
            }
    
        default: return state
    }
}

export default IndexReducer