import {call, put,takeEvery, takeLatest}from 'redux-saga/effects'
import { ADD_MEAL_NUTRIKID_FROM_LIBRARY_MEAL, GET_ALL_MEAL_NUTRIKID ,UPDATE_ITEM_MEAL_IN_LIBRARY, ADD_MEAL_MENU_NUTRIKID_WEEK, UPDATE_VALUE_MEAL_WEEK} from '../contant/LibraryMeal'
import {GetAllMealNutrikidApi} from '../api/library'
import { loadingFail, loadingSuccess } from '../../../../actions/uiLoading'
import {DispatchAllMealButrikidToStore,DispatchAddMealNutrikidToStoreLibrary, DispatchValueAddMealWeek} from '../action/library'
import { AddMealNutrikidApi, UpdateMealDateNutrikid } from '../api/nutrikid'
import { DispatchAddMealNutrikidToStore } from '../action/nutrikid'
import { HandleListMealStandard } from '../component/nutrikids/ThuVienThucDon/fn'

const SUCCESS = "success"

function* GetAllMealNutrikidSaga(){
    yield put(loadingSuccess())
    let res = yield call(GetAllMealNutrikidApi)
    if(res.data.result===SUCCESS){
        yield put(loadingFail())
        let listThuVien = HandleListMealStandard(res.data.data)
        //let listThuVien = res.data.data.filter(v=>v.typeOf===5) 
        yield put(DispatchAllMealButrikidToStore(listThuVien))
    }
}

function* AddMealNutrikidFromLibraryMealSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(AddMealNutrikidApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(loadingFail())
        yield put(DispatchAddMealNutrikidToStoreLibrary(res.data.data))        
        yield put(DispatchAddMealNutrikidToStore(res.data.data))
    }
}

function* AddMealMenuNutrikidWeekSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(AddMealNutrikidApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(loadingFail())
        // yield put(DispatchAddMealNutrikidToStoreLibrary(res.data.data))        
        yield put(DispatchAddMealNutrikidToStore(res.data.data))
        yield put(DispatchValueAddMealWeek(res.data.data))
    }
}

function* UpdateItemMealinLibrarySaga({payload}){
    yield call(UpdateMealDateNutrikid,payload.item)
}

function* UpdateValueMealWeekSaga({payload}){
   yield call(UpdateMealDateNutrikid,payload.item)  
}

function* LibraryMealSaga(){
        yield takeLatest(GET_ALL_MEAL_NUTRIKID,GetAllMealNutrikidSaga)
        yield takeEvery(ADD_MEAL_NUTRIKID_FROM_LIBRARY_MEAL,AddMealNutrikidFromLibraryMealSaga)
        yield takeLatest(UPDATE_ITEM_MEAL_IN_LIBRARY,UpdateItemMealinLibrarySaga)
        yield takeLatest(ADD_MEAL_MENU_NUTRIKID_WEEK,AddMealMenuNutrikidWeekSaga)
        yield takeLatest(UPDATE_VALUE_MEAL_WEEK,UpdateValueMealWeekSaga)
}

export default LibraryMealSaga