import { push } from 'connected-react-router'
import {put,call, takeLatest, delay}from 'redux-saga/effects'
import {loadingFail,loadingSuccess} from '../../../../actions/uiLoading'
import { DispatchInfoUserDetailWeb, DispatchResultWebAutoToStore, SendRegisStudentWebSuccess,SendRegisStudentWebSuccessFail } from '../action'
import { GetInfoUserDetailWebApi, GetLinkCheckDomainApi, SendRegisStudentWebApi } from '../api'
import { GET_INFO_USER_DETAIL_WEB, GET_LINK_CHECK_DOMAIN ,SEND_REGIS_STUDENT_WEB} from '../contant'

const SUCCESS ="success"

function* GetLinkCheckDomainSaga({payload}){    
    yield put(loadingSuccess()) 
   let res = yield call(GetLinkCheckDomainApi,payload.item)
   if(res.data.result===SUCCESS){
        yield put(loadingFail()) 
        yield put(DispatchResultWebAutoToStore(res.data.data))
   }
   if(res.data.result!==SUCCESS){
        yield put(loadingFail()) 
        alert('Tên Miền Chưa Đưọc Đăng Ký, vui lòng liên hệ quản trị!')   
        yield put(push('/'))
    }
}

function* GetInfoUserDetailWebSaga({payload}){
    let res = yield call(GetInfoUserDetailWebApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(DispatchInfoUserDetailWeb(res.data.data))   
    }
}

function* SendRegisStudentWebSaga({payload}){
    yield put(loadingSuccess()) 
    let res = yield call(SendRegisStudentWebApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(loadingFail())  

        if(res.data.data){
            yield put(SendRegisStudentWebSuccess())
            yield delay(2000)
            yield put(push(window.location.reload()))   
        }
        if(!res.data.data){
            yield put(SendRegisStudentWebSuccessFail())
        }

   
           
    }
}

function* IndexSaga(){
    yield takeLatest(GET_LINK_CHECK_DOMAIN,GetLinkCheckDomainSaga)
    yield takeLatest(GET_INFO_USER_DETAIL_WEB,GetInfoUserDetailWebSaga)
    yield takeLatest(SEND_REGIS_STUDENT_WEB,SendRegisStudentWebSaga)
}

export default IndexSaga