import React,{Suspense, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {FastForward} from '@material-ui/icons'
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Typography } from '@material-ui/core';
import {useStyles} from './styles'
import FormSelect from '../SelectCost/index'

const countEats = [
  {
    values: 0,
    label: 'Cấp Mầm Non',
  },
  {
    values: 1,
    label: 'Cấp Tiểu Học',
  },  
];


const Index=()=> {
  const classes = useStyles();
  const [countEat, setCountEat] = React.useState("");
  const [OpenNext,setOpenNext] = useState(false)
  const [OpenSelectCost,setSelectCost] = useState(false)

  const handleChange = (e) => {
       setCountEat(e.target.value);
       if(countEat!==e.target.value){
         setOpenNext(true)
       }
  };

  const handleOpenNext=()=>{
    setSelectCost(true)
  }
  const HandleCloseSelectCost=()=>{
    setSelectCost(false)
  }


  return (
  <Suspense fallback={()=>{}}>
    {OpenSelectCost&&(<FormSelect
        value={countEat}
        HandleCloseSelectCost={HandleCloseSelectCost}
    />)}

    {!OpenSelectCost&&(
         <form className={classes.root} noValidate autoComplete="off">
         <div>
           <TextField
             select
             label="Phân Loại Tính Ăn"
             value={countEat}
             onChange={handleChange}
             helperText="Chọn kiểu tính ăn phù hợp"
           >
             {countEats.map((option,i) => (
               <MenuItem key={i} value={option.values}>
                 {option.label}
               </MenuItem>
             ))}
           </TextField>        
         </div>
         
         {OpenNext===true?(
           <Typography component="div" className={classes.buttonNextCountEat}>         
             <Button color="primary"  variant="contained" size="small" onClick={()=>handleOpenNext()}>
               <FastForward/>
             </Button>         
           </Typography>
         ):""}     
     </form>      
    )}     
  </Suspense>
  );
}

export default React.memo(Index)
