import React,{Fragment} from 'react'
import {compose,bindActionCreators} from 'redux'
import cn from 'classnames'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/styles'
import style from './style'
import Header from './Header/index'
import Sidebar from './SideBar/index'
import * as uiActions from '../../actions/uiLoading'
import io from 'socket.io-client'
import * as LOGIN from '../../actions/Gov/login'
import {API_ENDPOINT} from '../../contant/index'
let socket = io(API_ENDPOINT);


class index extends React.Component{  
  UNSAFE_componentWillMount(){
    //this.setState.valueUser = this.props.loginUser
    socket.emit('loginUser',this.props.loginUser)        
    socket.emit('disconnect')
    // socket.on('loginUserFail',v=>{
    // let {Login} = this.props
    // let {LoginFailAccountOnly} = Login
    //   if(v.err==='fail'){
    //     socket.emit('logoutUser',v.id)     
    //     LoginFailAccountOnly()
    //   }
    // })
  }

 
  handleToggleSidebar = value => {
    const { uiActions } = this.props;
    const { showSidebar, hideSidebar } = uiActions;
    if (value === true) {
      showSidebar();
    } else {
      hideSidebar();
    }
  }

  render() {
    let {children,
     // name,
      showSidebar,classes,InfoGov} = this.props       
    return (
      <Fragment >        
           <Header name={InfoGov.workPlace} onToggleSidebar={this.handleToggleSidebar} open={showSidebar} 
           />
            <div className={classes.wrapper}>
          <Sidebar
            showSidebar={showSidebar}
            onToggleSidebar={this.handleToggleSidebar}
          />
          <div
            className={cn(classes.wrapperContent, {
              [classes.shiftLeft]: showSidebar === true
            })}
          >
            {children}
          </div>
        </div>        
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    showSidebar: state.uiLoading.showSidebar,    
    loginUser:state.users.loginUser,
    InfoGov:state.GovLogin.ListGov,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uiActions: bindActionCreators(uiActions, dispatch),
    Login: bindActionCreators(LOGIN,dispatch)
  };
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withStyles(style), 
  withConnect
  )(index)