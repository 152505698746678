import React, { useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {OPEN_BLOCK_APP_ACTIVE_MANAGER_REPO,CLOSE_BLOCK_APP_ACTIVE_MANAGER_REPO,
EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_REPO
}from '../../../Contant/Admin/AppTypeOfService'
import {DISPATCH_SELECT_REPO}from '../../../Contant/Person/cateRepo'

import {AlertDeActiveManagerRepo,BtnExit} from '../../../Contant/index'
import HtmlAction from '../../Fn'
import { Suspense } from 'react'

const HeaderMain = React.lazy(()=>import('./MainTab'))
const FormActive =React.lazy(()=>import('../../../Helper/FormOpen/index'))


const Index = () => {
  const dispatch = useDispatch()
  const ItemAppActive = useSelector(state=>state.AdminAddService.ItemAppActive[0].managerRepo)
  const OpenBlockAppActiveManagerRepo = useSelector(state=>state.AdminAddService.OpenBlockAppActiveManagerRepo)


  useEffect(()=>{
        dispatch({
          type:DISPATCH_SELECT_REPO
        })
        if(ItemAppActive===false){
            dispatch({
              type:OPEN_BLOCK_APP_ACTIVE_MANAGER_REPO
            })
        }
        if(ItemAppActive===true){
          dispatch({
          type:CLOSE_BLOCK_APP_ACTIVE_MANAGER_REPO
        })
      }      
  })
  const CloseFormActive=()=>{
        dispatch({
          type:CLOSE_BLOCK_APP_ACTIVE_MANAGER_REPO
        })
  }

  const handleBtnExit=()=>{
      dispatch({
        type:EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_REPO
      })
  }  


 
  return (
  <>
      {OpenBlockAppActiveManagerRepo&&
      <Suspense fallback={<>Loading...</>}>
              <FormActive
                open={OpenBlockAppActiveManagerRepo}
                handleClose={CloseFormActive}
                ComponentAction={<HtmlAction
                      alertText={AlertDeActiveManagerRepo}
                      fn={handleBtnExit}
                      btn={BtnExit}          
                />}
            />        
        </Suspense>   
      }
      
      <Suspense fallback={<>Loading...</>}>
            <HeaderMain/>
      </Suspense>    
  </>
  )
}

export default Index