import {fork,all} from 'redux-saga/effects'
import AdminSaga from './admin/index'
import GovSaga from './gov/index'
import AdminAppServiceSaga from '../APP_ADMIN_PERSON/Saga'
//import PersonSaga from '../APP_ADMIN_PERSON/Saga/person'
import ManagerAccountActiveSaga from '../ManagerAccountActiveGov/saga'
import WebHoldingSaga from '../container/Login/saga/holdingtech' 
import WebMamNonSaga from '../container/Login/WebMamNon/saga/root'
import GioiDinhTueSaga from '../container/Login/GioiDinhTue/component/saga'
import CrmSaga from './CRM' 
import ROOT_CONG_NGHE from '../container/Login/NotFoundPage/saga/CONG_NGHE/index'

function* rootSaga(){
  yield all(
    [
      yield fork(AdminSaga),     
      yield fork(GovSaga),
      yield fork(AdminAppServiceSaga),
      //yield fork(PersonSaga),
      yield fork(ManagerAccountActiveSaga),
      yield fork(WebHoldingSaga),
      yield fork(WebMamNonSaga),
      yield fork(GioiDinhTueSaga),
      yield fork(CrmSaga),
      yield fork(ROOT_CONG_NGHE)
    ]
  )
}

export default rootSaga