import styled,{css,keyframes} from 'styled-components'
import {device} from './device'

export const LI_header= styled.li`
        list-style:none;
        display:inline-block;
        &:hover .block{
            background-color:#95a5a6;
            transition: 0.5s all ease-in-out;
            cursor:'pointer';
        }
        &:hover .in-block{
        cursor:pointer;
        }

        @media ${device.mobile}{
            display:block;
        }
`