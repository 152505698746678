import React ,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Gov from '../TableUser/Gov/index'
import Person from '../TableUser/Personal/index'
import TuyenDungComponent from '../TableUser/TuyenDung'
import CrmComponent from '../TableUser/CRM'
import { GET_ADD_TUYEN_DUNG } from '../../container/Login/GioiDinhTue/component/contant';
import {useDispatch,useSelector} from 'react-redux'
import { GET_LIST_CRM } from '../../contant/CRM';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Index=React.memo(()=> {
  const dispatch = useDispatch()
  const classes  = useStyles();
  const [value, setValue] = React.useState(0);  

  useEffect(()=>{
    dispatch({type:GET_ADD_TUYEN_DUNG})
    dispatch({type:GET_LIST_CRM})
  },[])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Nhóm Tổ Chức" {...a11yProps(0)} />
          <Tab label="Nhóm Tư Nhân" {...a11yProps(1)} />
          <Tab label="Tuyển dụng" {...a11yProps(2)}/>
          <Tab label="CRM" {...a11yProps(3)}/>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Gov/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Person/>
      </TabPanel>     
      <TabPanel value={value} index={2}>      
        <TuyenDungComponent/>
      </TabPanel>    
      <TabPanel value={value} index={3}>      
        <CrmComponent/>
      </TabPanel>
    </div>
  );
})

export default Index
