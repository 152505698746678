import React,{Suspense, useState} from 'react';
import {Grid,TextField,FormHelperText, Button} from '@material-ui/core';
import {AccountCircle,CropFree,Person,Work,PersonOutline, Save} from '@material-ui/icons';
import {useStyles} from './styles'
import {useDispatch,useSelector} from 'react-redux'
import {ADD_TEMPLETE} from '../../../../../contant/Gov/login'

const Index=({handleClose})=> {
  const classes = useStyles();
  const dispatch = useDispatch()
  const userId = useSelector(state=>state.GovLogin.userId)
  const [masterOrigin,setMaster]   = useState('') //co quan chu quan   
  const [codeTxt,setCode]          = useState('') //ma qhns
  const [namePerson,setNamePerson] = useState('') //giay de nghi thanh toan: ho va ten
  const [detailJob,setDetailJob]   = useState('') //giay de nghi thanh toan : noi dung cong viec
  const [namePersonEx,setNamePersonEx] = useState('') //phieu xuat kho : ten nguoi nhan hang
  const [resonEx,setResonEx]       = useState('') //phieu xuat kho : ly do xuat kho
  const [codeTmp,setCodeTmp]       = useState('') //phieu xuat kho : mau so
  const [department,setDepartment] = useState('')//phieu xuat kho :phong ban
  const [err,setErr]               = useState('')
  const ShowErr=()=>{
    setErr('Lỗi, không được để trống!')
    setTimeout(()=>{
        setErr('')
    },3000)
  }

  const handleSave=()=>{
        let item={
            userId,
            masterOrigin,
            codeTxt,
            namePerson,
            detailJob,
            namePersonEx,
            resonEx,
            codeTmp,
            department
        }
        if(item.masterOrigin   ===""||
            item.codeTxt       ===""||
            item.namePerson    ===""||
            item.detailJob     ===""||
            item.namePersonEx  ===""||
            item.resonEx       ===""||
            item.codeTmp       ===""||
            item.department    ===""
        ){
          ShowErr()
          return true
        }
        dispatch({
          type:ADD_TEMPLETE,
          payload:{item}
        })
        handleClose()
  }
  return (
    <Suspense fallback={()=>{}}>
        <div className={classes.margin}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
              <AccountCircle />
            </Grid>
            <Grid item>
              <TextField id="input-with-icon-grid" 
                        onChange={e=>setMaster(e.target.value)}
                        defaultValue={""}
                        multiline
              />
              <FormHelperText id="filled-weight-helper-text">Cơ quan chủ quản</FormHelperText>
            </Grid>
          </Grid>
        </div>

        <div className={classes.margin}>        
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <CropFree />
              </Grid>
              <Grid item>
                <TextField id="input-with-icon-grid" 
                          onChange={e=>setCode(e.target.value)}
                          defaultValue={""}                      
                />
                <FormHelperText id="filled-weight-helper-text">Mã QHNS</FormHelperText>
              </Grid>
            </Grid>
        </div>

            <div className={classes.margin}>   
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <Person />
                  </Grid>
                  <Grid item>
                    <TextField id="input-with-icon-grid" 
                              label="Họ và tên"
                              onChange={e=>setNamePerson(e.target.value)}
                              defaultValue={""}              
                              multiline        
                    />
                    <FormHelperText id="filled-weight-helper-text">Giấy đề nghị thanh toán</FormHelperText>
                  </Grid>
                </Grid>
            </div>

           <div className={classes.margin}>   
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                  <Work />
                </Grid>
                <Grid item>
                  <TextField id="input-with-icon-grid" 
                            label="Nội dung công việc"
                            onChange={e=>setDetailJob(e.target.value)}
                            defaultValue={""}              
                            multiline        
                  />
                  <FormHelperText id="filled-weight-helper-text">Giấy đề nghị thanh toán</FormHelperText>
                </Grid>
              </Grid>
            </div>

              <div className={classes.margin}>   
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <PersonOutline/>
                  </Grid>
                  <Grid item>
                    <TextField id="input-with-icon-grid" 
                              label="Họ tên người nhận hàng"
                              onChange={e=>setNamePersonEx(e.target.value)}
                              defaultValue={""}              
                              multiline        
                    />
                    <FormHelperText id="filled-weight-helper-text">Phiếu xuất kho</FormHelperText>
                  </Grid>
                </Grid>
          </div>
          <div className={classes.margin}>   
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <PersonOutline/>
                  </Grid>
                  <Grid item>
                    <TextField id="input-with-icon-grid" 
                              label="Lý do xuất kho"
                              onChange={e=>setResonEx(e.target.value)}
                              defaultValue={""}              
                              multiline        
                    />
                    <FormHelperText id="filled-weight-helper-text">Phiếu xuất kho</FormHelperText>
                  </Grid>
                </Grid>
          </div>
          <div className={classes.margin}>   
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <CropFree/>
                  </Grid>
                  <Grid item>
                    <TextField id="input-with-icon-grid" 
                              label="Mẫu số"
                              onChange={e=>setCodeTmp(e.target.value)}
                              defaultValue={""}              
                              multiline                            />
                    <FormHelperText id="filled-weight-helper-text">Phiếu xuất kho</FormHelperText>
                  </Grid>
                </Grid>
          </div>
          <div className={classes.margin}>   
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <CropFree/>
                  </Grid>
                  <Grid item>
                    <TextField id="input-with-icon-grid" 
                              label="Bộ phận"
                              onChange={e=>setDepartment(e.target.value)}
                              defaultValue={""}              
                              multiline                        
                    />
                    <FormHelperText id="filled-weight-helper-text">Phiếu xuất kho</FormHelperText>
                  </Grid>
                </Grid>
          </div>      
          <p className={classes.textAlert}>{err}</p>
          <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                        <Button color="primary" variant="contained" onClick={()=>handleSave()}><Save/></Button>
                  </Grid>                 
           </Grid>
    </Suspense>
  )
}
export default React.memo(Index)