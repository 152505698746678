import React ,{useState} from 'react';
import { UL_Header_ ,Header,HeaderMobile,A_Link, UL_Header,   SearchHeader_, SearchIconClass,LI_header} from './style';
import LogoGDT from './huythanh.png'
import {Link} from 'react-scroll'
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
//import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import { OPEN_CREATE_QRCODE, OPEN_PROCESS_BMI, OPEN_TUYEN_DUNG,OPEN_CREATE_WEBSITE } from '../../../contant';
import {useDispatch} from 'react-redux'
import { OPEN_FORM_LOGIN_REGIS } from '../../../contant/ThietBiCongNghe';


const Navbar = () => {
    const dispatch           = useDispatch()    
    const [toogle,setToogle] = useState(false)

    const LoginResgis=()=>{
        dispatch({type:OPEN_FORM_LOGIN_REGIS})
    }

  
    return (
        <Header>
        {/* begin nav*/}
                <HeaderMobile>
                        <img style={{height:50,marginLeft:10,padding:5}} src={LogoGDT}/>
                </HeaderMobile>

                <UL_Header_ variant={toogle}>                
            
                    <LI_header  >
                        <A_Link className="block" onClick={()=>LoginResgis()}>
                                    <Link>THIẾT BỊ</Link>
                        </A_Link>                                   
                    </LI_header>               
                    
                    <LI_header >
                        <A_Link className="block">
                                    <Link 
                                    // onClick={()=>setToogle(!toogle)} to="SERVER" className="in-block" spy={true} smooth={true} offset={50} duration={1200} 
                                     >SERVER</Link>
                        </A_Link>                                   
                    </LI_header>

                    <LI_header >
                        <A_Link className="block">
                                <Link onClick={()=>setToogle(!toogle)} to="DAO_TAO" className="in-block" spy={true} smooth={true} offset={50} duration={1200} >ĐÀO TẠO</Link>
                        </A_Link>                                   
                    </LI_header>     

                    <LI_header    onClick={()=>LoginResgis()}>
                         <A_Link  className="block" onClick={()=>LoginResgis()}>
                            <Link onClick={()=>LoginResgis()}>ĐĂNG NHẬP/ĐĂNG KÝ</Link>
                        </A_Link>                                   
                    </LI_header>  
                   
                    {/* end nav */}
                </UL_Header_>
                
                <UL_Header >                  
                    <LI_header>
                        <A_Link className="block">
                                    <Link 
                                    //to="CONG_NGHE" className="in-block" spy={true} smooth={true} offset={50} duration={1100}  
                                    >THIẾT BỊ</Link>
                        </A_Link>                                    
                    </LI_header>                  

                    <LI_header>
                        <A_Link className="block">
                                    <Link 
                                    //to="SERVER" className="in-block" spy={true} smooth={true} offset={50} duration={1200}  
                                    >SERVER</Link>
                        </A_Link>                                  
                    </LI_header>

                    <LI_header >
                         <A_Link className="block">
                                <Link 
                                //to="DAO_TAO" className="in-block" spy={true} smooth={true} offset={50} duration={1200} 
                                >ĐÀO TẠO</Link>
                        </A_Link>                                  
                    </LI_header>

                    <LI_header  onClick={()=>LoginResgis()}>
                         <A_Link className="block" onClick={()=>LoginResgis()}>
                            <Link onClick={()=>LoginResgis()}>ĐĂNG NHẬP/ĐĂNG KÝ</Link>
                        </A_Link>                                   
                    </LI_header>                     

                 

                 
                    {/* end nav */}
                </UL_Header>               

            <SearchHeader_ onClick={()=>setToogle(!toogle)}>
                <SearchIconClass >
                    {toogle?<CloseIcon/>:<MenuIcon/>}                    
                </SearchIconClass>
            </SearchHeader_>
        {/* end search */}
       </Header>
    );
};

export default Navbar;
