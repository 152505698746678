import styled from "styled-components";
import { device } from "../../../../style/MainCss/device";


export const MainCustomer = styled.div`
        width:100%;
        height:500px;
        margin:auto;
        padding:50px;
        background:#171616;
        position:relative;
        top:0;
        left:0;
`

export const SliderCustomer = styled.div`
        width:80%;
        height:200px;
        position:absolute;
        z-index:3;
        top:15%;
        left:50%;
        transform:translateX(-50%) translateY(-50%); 

        ${'' /* animation:${CaroselImgLogo} ease 1.5s; */}
`




export const ItemCarosel = styled.div`
        margin:auto;
        width:90%;       
        height:100%;        
        @media ${device.mobile}{                
                
        }
        @media ${device.tablet}{                
             
        }
`

export const TextIn = styled.div`         
        ${'' /* position:absolute;
        top:50%;
        left:50%;
           */}
        background:white;
        z-index:7; 
        margin:20px;
        padding:20px;
        box-shadow : 2px 2px 2px 2px #bdc3c7;
        box-sizing:border-box;
        text-align:left;       
        font-size: 18px;
        color: #7f8c8d;
        font-weight:500;
        border-radius:3px;
        margin-top:100px;
        @media ${device.mobile}{                
                font-size: 14px;
        }
`

export const SpeechIcon = styled.div`     
      float:right;
      margin-right:10px;
      margin-top:-15px;
`

export const GropupIcon = styled.div`
        margin-left:0px;
        margin-top: -6px;
`

export const NameSchool = styled.p`
        font-size:15px;
        color:#34495e;
        margin-top: 35px;
        margin-left: 4px;
`

export const FooterIcon = styled.div`
        margin:auto;
        width:30%;
        height:200px;
        margin-top:400px;
`

export const ImgLogo = styled.img`
    height: 189px;
    width: 158px;
    margin-left: 88px;

`