import {fork,all} from 'redux-saga/effects'
import AppAndServiceSaga from './appService/index'
import PersonSaga from './person/index'

function* appServiceSaga(){
  yield all(
    [
        yield fork(AppAndServiceSaga),
        yield fork(PersonSaga),
    ]
  )
}

export default appServiceSaga