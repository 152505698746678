import * as TypeGov from '../../contant/Gov/softFood'
import {toastSuccess,toastError} from '../../helper/toastHelper'
import {DISPATCH_THANH_TOAN_BY_USER_TO_STORE,UPDATE_THANH_TOAN_BY_USER} from '../../ComponentGov/SoftFoodTabMain/Nutrikid/contant/ThanhToan'
import { updateArrById } from '../../helper/fnAllways'
let initialState = {
  openModalBox:false,
  findValue:{
    id:'',
    Value:null
  },
  findUserActiveStatus:{},
  ListUserPayment:[],
  ItemInfoUser:null,
}

const ActiveSoftFoodReducer = (state=initialState,action)=>{
    switch (action.type) {
      case TypeGov.DISPATCH_ITEM_INFO_BY_USER_ID_TO_STORE:
        return{
          ...state,
          ItemInfoUser:action.payload.item
        }
      case TypeGov.ACTIVE_SOFT_FOOD:           
         return{          
           ...state,
           openModalBox:true
         }    

         case TypeGov.ACTIVE_SOFT_FOOD_CLOSE_BOX:           
         return{          
           ...state,
           openModalBox:false
         }   

         case TypeGov.ACTIVE_SOFT_FOOD_FAIL:           
         return{          
           ...state           
         } 

         case TypeGov.ACTIVE_SOFT_FOOD_STATUS:
           let {item} = action.payload    
         if(item.activeSoftFood===false){
            toastError(`${item.workPlace}-Chương trình tạm khóa`)
         }else if(item.activeSoftFood===true) {           
            toastSuccess(`${item.workPlace} - Chương trình đã được kích hoạt`)
         }
                  
         return{          
           ...state,          
         }         

         case TypeGov.ACTIVE_SOFT_FOOD_USER_STATUS:
           let {user} = action.payload                
           return{
             ...state,
            findUserActiveStatus:user
           }        
           case UPDATE_THANH_TOAN_BY_USER:
            return{
              ...state,
              ListUserPayment:updateArrById(state.ListUserPayment,action.payload.item)
            }     
           case DISPATCH_THANH_TOAN_BY_USER_TO_STORE:
            return{
              ...state,
              ListUserPayment:[action.payload.item].concat(state.ListUserPayment)
            }
          case TypeGov.DISPATCH_GET_LIST_USER_THANH_TOAN_TO_STORE  :
            return {
              ...state,
                ListUserPayment:action.payload.item
            }
    
      default: return state
    }
}

export default ActiveSoftFoodReducer