import Axios from 'axios'
import uuid from 'uuid'

export const ToolUpdateRefreshToken =async(value) =>{
    try {
        let URL = `${value.urlRequestAccessToken}&refresh_token=${value.refresh_token}`                         
         
        let headers = {  
            'secret_key': `${value.secret_key}`,  
            'Content-Type': 'application/x-www-form-urlencoded',  
        };                      
        let response = await Axios(URL, {  
            method: 'post',  
            headers: headers  
        });     
        return response.data
    } catch (error) {
        return false
    }    
}

export const ToolSendNofiPhone=async(value)=>{
        try {
            let URL = 'https://business.openapi.zalo.me/message/template'
            let headers = {  
                'access_token': `${value.access_token}`,  
                'Content-Type': 'application/json',                  
            };   
            let data ={
                phone:'84775568194',
                template_id:'249389',
                template_data:{                    
                        date: '13/03/2023',
                        cost: 188000,
                        code_student: "188000",
                        name_student: "Pham minh tuan ",
                        tel: '84775568194',
                        },
            tracking_id:uuid()           
            }
            let response = await Axios(URL, {  
                method: 'post',  
                headers: headers,
                data
            });     
            //console.log(JSON.parse(response.config.data))
            return JSON.parse(response.config.data)
        } catch (error) {
            
        }
}

export const ToolSendNofiPhone249846=async(item,value)=>{
    try {
        let URL = 'https://business.openapi.zalo.me/message/template'
        let headers = {  
            'access_token': `${value.access_token}`,  
            'Content-Type': 'application/json',                  
        };   
        let data ={
            phone:item.phone,
            template_id:'249846',
            template_data:item.template_data,
            tracking_id:item.tracking_id
        }
        let response = await Axios(URL, {  
            method: 'post',  
            headers: headers,
            data
        });            
        return {result:JSON.parse(response.config.data),message:response.data}
    } catch (error) {
        
    }
}

