import React,{Fragment} from 'react'
import style from './style'
import {withStyles, Grid,DialogContent,DialogActions,Button} from '@material-ui/core'
import {reduxForm,Field} from 'redux-form'
import renderTextField from '../../../component/formHelper/TextField/index'
import {connect} from 'react-redux'
import {bindActionCreators,compose} from 'redux'
import renderSelectField from '../../../component/formHelper/Select/index'
import validate from './validate'
import * as actionsUserPage from '../../../actions/pageUser'
import * as actionsGov from '../../../actions/auth'



class UserPageForm extends React.Component{
  handleSubmitForm=(data)=>{    
      let{formInfoUser,Gov} = this.props
      let{addFormInfoUser}= formInfoUser
      let {
        //listGov,
        addItemListGov} = Gov
     //dispatch action show list nhan vien
     //listGov()
     addItemListGov(data)
      addFormInfoUser(data)
  }

  renderSelect=()=>{
        let {listUserNhaNuoc,name,valueId}=this.props      
        let xhtml =''
        if(name&&valueId){
           xhtml =(
            <Grid item md={12}>                
              <Field
                  margin="dense"            
                  label="Tên account của quý khách"
                  name="userId"
                  type="text"
                  component={renderSelectField}
                  fullWidth        
                >  
                <option/>           
                <option value={valueId}>{name}</option>
              </Field>                      
             </Grid>        
           )
        }
         if(!name&&!valueId)
        {                                  
        xhtml=  (
          <Grid item md={12}>                
              <Field
                  margin="dense"            
                  label="Tên account của quý khách"
                  name="userId"
                  type="text"
                  component={renderSelectField}
                  fullWidth            
                >       
                <option/>      
                {listUserNhaNuoc.map((item,index)=>{
                return (<option value={item._id} key={index}>{item.name}</option>)
                })}
                </Field>                      
          </Grid>   
          )     
     }
     return xhtml
    }
  
  
  // renderAccount = ()=>{    
  //     let {listUserNhaNuoc} = this.props           
  //        return (   
  //                 <Grid item md={12}>                
  //                   <Field
  //                       margin="dense"            
  //                       label="Tên account của quý khách"
  //                       name="userId"
  //                       type="text"
  //                       component={renderSelectField}
  //                       fullWidth             
  //                     >     
  //                       {this.renderSelect()} 
                       
  //                     </Field>                      
  //                 </Grid>        
  //           )
  //     }

  render() {
    let {handleOpenCloseModal,handleSubmit,invalid,submitting} = this.props           
    let {hideFormUser} = handleOpenCloseModal  

    return (
      <Fragment>
         <form onSubmit={handleSubmit(this.handleSubmitForm)} autoComplete="off">         
          <DialogContent>  
            <Grid container spacing={1}>             
                  {this.renderSelect()}                  
                  <Grid item md={6}>
                      <Field                                       
                        label="Email khách hàng"
                        margin="dense"              
                        component={renderTextField}
                        name="email" 
                        type="email"
                        fullWidth   
                       // value={}        
                      />   
                  </Grid>
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"      
                        label="Số điện thoại"
                        name="tel"
                        component={renderTextField}
                        type="text"
                      //  value=''
                        fullWidth             
                      />       
                  </Grid>
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"          
                        label="Mã số thuế"
                        name="taxCode"
                        component={renderTextField}
                        type="text"
                        //value=''
                        fullWidth             
                      />       
                  </Grid>
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"        
                        label="Địa chỉ"
                        name="address"
                        component={renderTextField}
                        type="text"
                      //  value=''
                        fullWidth             
                      />       
                  </Grid>
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"            
                        label="Nơi Làm việc"
                        name="workPlace"
                        component={renderTextField}
                        type="text"
                       // value=''
                        fullWidth             
                      />       
                  </Grid>
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"           
                        label="Tên đại diện/ Người Giao Hàng"
                        name="directorName"
                        component={renderTextField}
                        type="text"
                       // value=''
                        fullWidth             
                      />       
                  </Grid>                  
               </Grid>       
          </DialogContent>
          <DialogActions>           
            <Button onClick={hideFormUser} color="default" variant="contained" >
              Hủy
          </Button>
          <Button disabled={invalid||submitting}  color="primary" type="submit" variant="contained">
              Lưu lại
          </Button>
          </DialogActions>   
    </form>    
      </Fragment>                  
    )
  }
}

const mapStateToProps = state =>{
      return {
        //initialValues:state.task.taskEditting,
        //task:state.task.taskEditting ? state.task.taskEditting:'',
        // status:state.task.taskEditting.status ? state.task.taskEditting.status:''      
       // open:state.userPage.showFormUser,
        close:state.userPage.showForm,
        listUserNhaNuoc:state.userPage.listUserGroupNhaNuoc
      }
}

const mapDispatchStateToProps = dispatch =>{
  return{
      handleOpenCloseModal: bindActionCreators(actionsUserPage,dispatch),
      formInfoUser: bindActionCreators(actionsUserPage,dispatch),
      Gov:bindActionCreators(actionsGov,dispatch)
  }
}


const FORM_NAME = "FORM_MANAGER"

const withConnect = connect(mapStateToProps,mapDispatchStateToProps)

const withReduxForm = reduxForm({
  form:FORM_NAME,
  validate
})

export default compose(
  withStyles(style),
  withConnect, 
  withReduxForm
  )(UserPageForm)