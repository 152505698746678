import {LOADING_NOW,LOADING_STOP,CLOSE_ALERT_SUCCESS,OPEN_ALERT_SUCCESS,
OPEN_DUPPLICATE,CLOSE_DUPPLICATE
} from '../Contant/helper'

export const initalState={
  Loading:false,
  AlertOpen:false,
  DupplicateOpen:false,
  TextAlert:''
}

const HelperReducer = (state=initalState,action)=>{
  switch (action.type) {
    case LOADING_NOW:
      return{
        ...state,
        Loading:true
      }
    case LOADING_STOP:
      return{
        ...state,
        Loading:false
      }
    case OPEN_ALERT_SUCCESS:
      return{
        ...state,
        AlertOpen:true,
        TextAlert:action.payload.item
      }
    case CLOSE_ALERT_SUCCESS:
      return{
        ...state,
        AlertOpen:false,
        
      }
    case OPEN_DUPPLICATE:
      return{
        ...state,
        DupplicateOpen:true
      }
    case CLOSE_DUPPLICATE:
        return{
          ...state,
          DupplicateOpen:false
       }

    default: return state
  }
}

export default HelperReducer