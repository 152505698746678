import {put,call,select, takeLatest} from 'redux-saga/effects'
import {UPDATE_INFO_STAFF,DISPATCH_GET_PAYMENT_STAFF,GET_QRCODE_IN_ACCOUNT_LOGIN,CHAM_CONG, GET_CHAM_CONG,DELETE_BAO_NGHI_CHAM_CONG,UPDATE_CHAM_CONG_NUMBER_1,GET_LIST_SUPPLIES_MATERIAL_COMMODITY_BY_USER_ID,ADD_IMPORT_FROM_STAFF, EDIT_IMPORT_FROM_STAFF, DELETE_EDIT_IMPORT_STAFF, GET_LIST_IMPORT_BY_STAFF, GET_LIST_TASK_JOB_BY_STAFF, CONVERT_READY_TO_PROCESS_TASK_JOB,UPDATE_EDIT_DEPARTMENT, CONVERT_READY_TO_PROCESS_TASK_JOB_TO_SAGA, GET_LIST_AUTO_IMPORT, STAFF_SELECT_TASK_JOB, CONVERT_READY_TO_COMPLETE_TASK_JOB, DELETE_TASK_JOB_BY_STAFF, DISPATCH_UPDATE_ITEM_VALUE_PERSON, GET_LIST_TASK_JOB_STAFF_BY_MONTH, ADD_PAY_FOR_JOB_TASK, UPDATE_TASK_JOB_BY_STAFF, SAVE_ADD_MORE_DEPARTMENT,EDIT_PAY_FOR_JOB_TASK, ADD_TASK_JOB_BY_STAFF} from '../contant'
import {UpdateInfoStaffApi,GetPaymentStaffApi, AddImportFromStaffApi,EditImportFromStaffApi, DeleteEditImportFromStaffApi, GetListImportByStaffApi, GetListTaskJobByStaffApi, ConvertReadyToProcessTaskJobApi, UpdateEditDepartmentApi, GetListAutoImportApi, StaffSelectTaskJobApi, ConvertReadyToProcessTaskJobSaveDbApi, DeleteTaskJobByStaffApi, AddPayForJobTaskApi, UpdateTaskJobByStaffApi,} from '../api'
import {GetQrcodeApi} from '../../../Api-services/person/qrcode'
import {GetChamCongApi,AddChamCongStaffApi,UpdateItemNumberApi,GetManyItemApi,AddMoreDepartmentApi} from '../api'
import { OpenAlertSuccess } from '../../../Actions/helper'
import {DispatchGetPaymentStaffToStore,DispatchGetQrcodeToStore,ChamCongLoi,DispatchGetChamCongToStore, ChamCongSuccess,DispatchGetListManyToStore,DispatchAddImportFromStaffToStore, DispatchListImportByStaffToStore, DispatchListTaskJobUpdate, DispatchListAutoImportToStore, DispatchListTaskJobPesonalToStore, DispatchListTaskJobByStaffToStore, DispatchAddPayForJobTaskToStore, ConvertReadyToProcessTaskJob,  DispatchValueAddDepartmentUpdateToStore, DispatchAddTaskJobToStore, ValueProcessHandleEmpty} from '../action'
import {handleChamCongToSaveDB,DeleteBaoNghiChamCong} from './fn'
import {loadingFail,loadingSuccess} from '../../../../actions/uiLoading'
import { delay } from 'lodash'
import { AddTaskJobApi } from '../../App/QuanlyNhanVien/api'
import { showMMYY} from '../../../../helper/dateTime/handleDMY'
import { updateArrById } from '../../../../helper/fnAllways'
import { UpdateListTaskJobManagerStaff } from '../../App/QuanlyNhanVien/action'

const SUCCESS = 'success'
const ALERT_UPDATE_SUCCESS = "Cập Nhật Thành Công"

function* UpdateInfoStaffSaga({payload}){
  yield put(loadingSuccess())
  let res = yield call(UpdateInfoStaffApi,payload.item)  
  if(res.data.result===SUCCESS){
    yield put(loadingFail())
      yield put(OpenAlertSuccess(ALERT_UPDATE_SUCCESS))
  }
}

function* DispatchGetPaymentStaffSaga({payload}){
  yield put(loadingSuccess())
  let res = yield call(GetPaymentStaffApi,payload.item)
  if(res.data.result===SUCCESS){  
        yield put(loadingFail())
        yield put(DispatchGetPaymentStaffToStore(res.data.data))
     }
}

function* GetQrcodeInAccountSaga({payload}){
  yield put(loadingSuccess())
 let res = yield call(GetQrcodeApi,payload.item)
 if(res.data.result===SUCCESS){
  yield put(loadingFail())
  //console.log(res.data.data)
    yield put(DispatchGetQrcodeToStore(res.data.data))
 }
}

function* GetChamCongSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(GetChamCongApi,payload.item)
    if(res.data.result===SUCCESS){
          yield put(loadingFail())
          //console.log(res.data.data)
          yield put(DispatchGetChamCongToStore(res.data.data))
    }
}

function* ChamCongSaga({payload}){
  let ItemChamCong = yield select(state=>state.AccountLoginStaff.ItemChamCong)
  let ItemQrcode   = yield select(state=>state.AccountLoginStaff.ItemQrcode)
  let findIdQrcode = ItemQrcode.qrcodes.filter(v=>v.typeof===1)[0].id
  if(payload.item.scan!==""&&payload.item.scan!=="scan"&&payload.item.scan!==findIdQrcode){
       yield put(ChamCongLoi())
   }
    if(payload.item.scan===false&&payload.item.scan!==findIdQrcode){
        yield put(ChamCongLoi())
    }
    if(payload.item.scan!==""&&payload.item.scan!==false&&payload.item.scan===findIdQrcode){
          yield put(ChamCongSuccess(payload.item.typeOf))
          let result =  handleChamCongToSaveDB(ItemChamCong,payload.item)   
          if(result!==false){
            let res = yield call(AddChamCongStaffApi,result)             
             if(res.data.result===SUCCESS){
               yield put(DispatchGetChamCongToStore(res.data.data))
             }         
          }             
    }
    //bao nghi
    if(payload.item.scan==='scan'&&payload.item.scan!==findIdQrcode){
      yield put(ChamCongSuccess(payload.item.typeOf))
      let result =  handleChamCongToSaveDB(ItemChamCong,payload.item)   
      if(result!==false){
        let res = yield call(AddChamCongStaffApi,result)             
         if(res.data.result===SUCCESS){
           yield put(DispatchGetChamCongToStore(res.data.data))
         }         
      }             
  }


}

function* DeleteBaoNghiChamCongSaga({payload}){
  yield put(loadingSuccess())
  let ItemChamCong = yield select(state=>state.AccountLoginStaff.ItemChamCong)
  let result = DeleteBaoNghiChamCong(ItemChamCong,payload.item)
  let res = yield call(AddChamCongStaffApi,result)             
  if(res.data.result===SUCCESS){
    yield put(loadingFail())
    yield put(DispatchGetChamCongToStore(res.data.data))
  }    
}

function* UpdateChamCongNumberSaga({payload}){
  yield put(loadingSuccess())
  let res = yield call(UpdateItemNumberApi,payload.item)
  if(res.data.result===SUCCESS){
    yield put(loadingFail())
    yield put(DispatchGetChamCongToStore(res.data.data))
  }  
}

function* GetListSuppliesMaterialCommodityByUserIdSaga({payload}){
  yield put(loadingSuccess())
  let res = yield call(GetManyItemApi,payload.item)
  yield put(loadingFail())
  //console.log(res.data.data)
  yield put(DispatchGetListManyToStore(res.data.data))  
}

function* AddImportFromStaffSaga({payload}){
  yield put(loadingSuccess())
let res =  yield call(AddImportFromStaffApi,payload.item)
  if(res.data.result===SUCCESS){
      yield put(DispatchAddImportFromStaffToStore(res.data.data))
      yield put(loadingFail())
  }
}

function* EditImportStaffSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(EditImportFromStaffApi,payload.item)    
    if(res.data.result===SUCCESS){      
      yield put(loadingFail())
    }
}

function* DeleteEditImportStaffSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(DeleteEditImportFromStaffApi,payload.item)
    if(res.data.result===SUCCESS){      
      yield put(loadingFail())
    }
}

function* GetListImportByStaffSaga({payload}){
  yield put(loadingSuccess())
  let res = yield call(GetListImportByStaffApi,payload.item)
  if(res.data.result===SUCCESS){
    yield put(loadingFail())
    yield put(DispatchListImportByStaffToStore(res.data.data))
  }
}

function* GetListTaskJobByStaffSaga({payload}){  
  yield put(loadingSuccess())
  let Item={
    userId:payload.item.ItemInfoStaff.userId,
    idStaff:payload.item.ItemInfoStaff.idStaff
  }
  let res =  yield call(GetListTaskJobByStaffApi,Item)   
  if(res.data.result===SUCCESS){
    yield delay(3000)
    yield put(loadingFail())
    let Result = res.data.data.filter(v=>v!==null)
    //console.log(Result)
    yield put(DispatchListTaskJobByStaffToStore(Result))  
  }  
}

function* ConvertReadyToProcessTaskJobSaga({payload}){
    let ListTaskJob   = yield select(state=>state.ManagerStaff.ListTaskJob)   
    let update = updateArrById(ListTaskJob,payload.item)
    //cap nhat Reduce tai QuanLyNhanVien
    yield put(loadingSuccess())
    let res = yield call(ConvertReadyToProcessTaskJobApi,payload.item)
    if(res.data.result===SUCCESS){
      yield put(loadingFail()) 
      yield put(UpdateListTaskJobManagerStaff(update))     
    }
}

function* ConvertReadyToCompleteTaskJobSaga({payload}){
  let ListTaskJob   = yield select(state=>state.ManagerStaff.ListTaskJob)   
  let update = updateArrById(ListTaskJob,payload.item)
  yield put(loadingSuccess())
  let res = yield call(ConvertReadyToProcessTaskJobApi,payload.item)
  if(res.data.result===SUCCESS){
    yield put(loadingFail())   
    yield put(UpdateListTaskJobManagerStaff(update))       
  }
}

function* DispatchUpdateItemValuePersonSaga({payload}){
  let ListTaskJob      = yield select(state=>state.AccountLoginStaff.ListTaskJob)   
  yield put(loadingSuccess())
  let res = yield call(ConvertReadyToProcessTaskJobSaveDbApi,payload.item)  
  if(res.data.result===SUCCESS){
    //let ItemInfoStaff = yield select(state=>state.AccountLoginStaff.ItemInfoStaff)
    //let result = handleFilterByStaff(res.data.data,ItemInfoStaff)
    let updateArr = updateArrById(ListTaskJob,res.data.data) 
    yield put(DispatchListTaskJobPesonalToStore(updateArr))
    yield put(loadingFail())     
  }
}

function* ConvertReadyToProcessTaskJobToSagaSaga({payload}){
   let ListTaskJob      = yield select(state=>state.AccountLoginStaff.ListTaskJob)   
  yield put(loadingSuccess())
  let res = yield call(ConvertReadyToProcessTaskJobSaveDbApi,payload.item)  
 if(res.data.result===SUCCESS){
   yield put(loadingFail())  
   let updateArr = updateArrById(ListTaskJob,res.data.data) 
     yield put(DispatchListTaskJobUpdate(updateArr)) 
  }
}

function* UpdateEditDepartmentSaga({payload}){
  yield put(loadingSuccess())
  let res = yield call(UpdateEditDepartmentApi,payload.item)
  if(res.data.result===SUCCESS){
    yield put(loadingFail())
  }
}

function* GetListAutoImportSaga(){
  let res = yield(GetListAutoImportApi())
  if(res.data.result===SUCCESS){
    //console.log(res.data.data)
    yield put(DispatchListAutoImportToStore(res.data.data))
  }
}

function* StaffSelectTaskJobSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(StaffSelectTaskJobApi,payload.item)
    if(res.data.result===SUCCESS){
      yield put(loadingFail())
    }
}

function* DeleteTaskJobByStaffSaga({payload}){
  let ListTaskJob     = yield select(state=>state.ManagerStaff.ListTaskJob)  
  let filterByStaff  = ListTaskJob.filter(v=>v._id!==payload.item._id)
    yield put(loadingSuccess())
    let res = yield call(DeleteTaskJobByStaffApi,payload.item)
    if(res.data.result===SUCCESS){
      //cap nhat mang ListTaskJob ManagerStaff
      yield put(UpdateListTaskJobManagerStaff(filterByStaff)) 
      yield put(loadingFail())
    }
}

function* GetListTaskJobStaffJobStaffByMonthSaga({payload}){
  let ItemInfoStaff       = yield select(state=>state.AccountLoginStaff.ItemInfoStaff)
  let ListTaskJob         = yield select(state=>state.ManagerStaff.ListTaskJob)  
  let filterListTaskJob   = ListTaskJob.filter(v=>showMMYY(v.dateBegin)===showMMYY(payload.item.month)&&v!==undefined&&ItemInfoStaff.idStaff===v.Staff[0].idStaff)  
      yield put(DispatchListTaskJobByStaffToStore(filterListTaskJob))   
}

//cong tac phi
function* AddPayForJobTaskSaga({payload}){
  yield put(loadingSuccess())
  let res = yield call(AddPayForJobTaskApi,payload.item)
  if(res.data.result===SUCCESS){
    yield put(loadingFail())
    yield put(DispatchAddPayForJobTaskToStore(res.data.data))
  }
}

function* UpdateTaskJobByStaffSaga({payload}){
  yield put(loadingSuccess())
  let res = yield call(UpdateTaskJobByStaffApi,payload.item)
  if(res.data.result===SUCCESS){
    yield put(ConvertReadyToProcessTaskJob(payload.item))
    yield put(loadingFail())
  }
}

function* SaveAddMoreDepartmentSaga({payload}){
  yield put(loadingSuccess())
  let res = yield call(AddMoreDepartmentApi,payload.item)  
  if(res.data.result===SUCCESS){
    yield put(DispatchValueAddDepartmentUpdateToStore(res.data.data))
    yield put(loadingFail())
  }
}

function* EditPayForJobTaskSaga({payload}){
  yield put(loadingSuccess())
  let res = yield call(UpdateTaskJobByStaffApi,payload.item)
  if(res.data.result===SUCCESS){
    yield put(ConvertReadyToProcessTaskJob(payload.item))
    yield put(loadingFail())
  }
}

function* AddTaskJobByStaffSaga({payload}){
  let ListTaskJob     = yield select(state=>state.ManagerStaff.ListTaskJob)  
  //console.log(filterByStaff)
  yield put(loadingSuccess())  
  let res = yield call(AddTaskJobApi,payload.item)
  if(res.data.result===SUCCESS){
       yield put(loadingFail())
       let NewArr = ListTaskJob.concat(res.data.data)
       //let filterByStaff =NewArr.filter(v=>v.Staff[0].idStaff===payload.item.Staff[0].idStaff&&v!==undefined)
       yield put(DispatchAddTaskJobToStore(res.data.data))
       yield put(UpdateListTaskJobManagerStaff(NewArr))
  }
}

function* AccountLoginStaff(){
  yield takeLatest(UPDATE_INFO_STAFF,UpdateInfoStaffSaga)
  yield takeLatest(DISPATCH_GET_PAYMENT_STAFF,DispatchGetPaymentStaffSaga)
  yield takeLatest(GET_QRCODE_IN_ACCOUNT_LOGIN,GetQrcodeInAccountSaga)
  yield takeLatest(CHAM_CONG,ChamCongSaga)
  yield takeLatest(GET_CHAM_CONG,GetChamCongSaga)
  yield takeLatest(DELETE_BAO_NGHI_CHAM_CONG,DeleteBaoNghiChamCongSaga)
  yield takeLatest(UPDATE_CHAM_CONG_NUMBER_1,UpdateChamCongNumberSaga)
  yield takeLatest(GET_LIST_SUPPLIES_MATERIAL_COMMODITY_BY_USER_ID,GetListSuppliesMaterialCommodityByUserIdSaga)
  yield takeLatest(ADD_IMPORT_FROM_STAFF,AddImportFromStaffSaga)
  yield takeLatest(EDIT_IMPORT_FROM_STAFF,EditImportStaffSaga)
  yield takeLatest(DELETE_EDIT_IMPORT_STAFF,DeleteEditImportStaffSaga)
  yield takeLatest(GET_LIST_IMPORT_BY_STAFF,GetListImportByStaffSaga)
  yield takeLatest(GET_LIST_TASK_JOB_BY_STAFF,GetListTaskJobByStaffSaga)
  yield takeLatest(CONVERT_READY_TO_PROCESS_TASK_JOB,ConvertReadyToProcessTaskJobSaga)
  yield takeLatest(CONVERT_READY_TO_COMPLETE_TASK_JOB,ConvertReadyToCompleteTaskJobSaga)
  yield takeLatest(CONVERT_READY_TO_PROCESS_TASK_JOB_TO_SAGA,ConvertReadyToProcessTaskJobToSagaSaga)
  yield takeLatest(UPDATE_EDIT_DEPARTMENT,UpdateEditDepartmentSaga)  
  yield takeLatest(GET_LIST_AUTO_IMPORT,GetListAutoImportSaga)
  yield takeLatest(STAFF_SELECT_TASK_JOB,StaffSelectTaskJobSaga)
  yield takeLatest(DELETE_TASK_JOB_BY_STAFF,DeleteTaskJobByStaffSaga)
  yield takeLatest(DISPATCH_UPDATE_ITEM_VALUE_PERSON,DispatchUpdateItemValuePersonSaga)
  yield takeLatest(GET_LIST_TASK_JOB_STAFF_BY_MONTH,GetListTaskJobStaffJobStaffByMonthSaga)
  yield takeLatest(UPDATE_TASK_JOB_BY_STAFF,UpdateTaskJobByStaffSaga)
  //cong tac phi
  yield takeLatest(ADD_PAY_FOR_JOB_TASK,AddPayForJobTaskSaga)
  yield takeLatest(SAVE_ADD_MORE_DEPARTMENT,SaveAddMoreDepartmentSaga)
  yield takeLatest(EDIT_PAY_FOR_JOB_TASK,EditPayForJobTaskSaga)
  yield takeLatest(ADD_TASK_JOB_BY_STAFF,AddTaskJobByStaffSaga)
}

export default AccountLoginStaff