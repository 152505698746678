import React,{useState} from 'react';
import { handleNumberDot } from '../../../../../../../../../helper/cost/costStudent';
import LineComponent from '../../../FnComponent/LineInBG'
import { CenterContent, Content, ContentLeft, ContentRight, HeaderText, HeaderTextIn, HeaderTextInRight, HeaderTextRight, IMGLOGO, LeftContent, LineSpace, MainContent, MainTinhUuViet, Name_full3in1_Month, Price1, Price_full3in1_Month, RegisSoft, RightContent,  Text } from './style';
import KidTopLogo from '../../../FnComponent/HTICARE_T1.png'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import {Box} from '@material-ui/core'
import VideoComponent from '../Video'
import OpenRegisCompoent from './OpenRegis'
import {useSelector} from 'react-redux'
import TinhUuViet from '../../TinhUuViet'

const Index = React.memo(() => {
    const ListPriceSoft            = useSelector(state=>state.holdingTech.ListPriceSoft)
    const [openRegis,setOpenRegis] = useState(false)

    const RegisKidTop=()=>{
        setOpenRegis(true)
    }

    const HandleCloseRegis=()=>{
        setOpenRegis(false)
    }

    const HandleShowTimeOut=(value)=>{
            if(value<=31)return 'Tháng'
            if(value>31&&value<=93) return 'Quý'
            if(value>93)return 'Năm'
    }

    return (
        <div style={{background:'#171616'}}>
            {openRegis&&<OpenRegisCompoent
                handleClose={HandleCloseRegis}
                open={openRegis}
            />}
            <MainContent id="KIDTOP">
                <Content>
                    <ContentLeft>
                        <HeaderText>Phần Mềm Dinh Dưỡng KIDTOP</HeaderText>
                        <HeaderTextIn>Nền Tảng Quản Trị Nghiệp Vụ Mầm Non & Tiểu Học Toàn Diện</HeaderTextIn>
                        <LineSpace>
                            <LineComponent/>
                        </LineSpace>
                        {ListPriceSoft.map((v,i)=>{
                            return(
                                <Price1 key={i}>
                                    <Name_full3in1_Month>{v.nameSoft}</Name_full3in1_Month>
                                    <Price_full3in1_Month>{handleNumberDot(v.priceSoft)}/{HandleShowTimeOut(v.timeout)}</Price_full3in1_Month>
                                </Price1>
                            )
                        })}         
                    </ContentLeft>

                    <ContentRight>
                            <HeaderTextRight>Phần Mềm Dinh Dưỡng Chuyên Nghiệp</HeaderTextRight>
                            <HeaderTextInRight>KIDTOP</HeaderTextInRight>
                            <CenterContent>
                                <LeftContent>
                                    <IMGLOGO src={KidTopLogo} alt="phan-mem-nutrikids-nutrikid"/>
                                </LeftContent>
                                <RightContent>
                                    <Box display="flex" justifyContent="flex-start" style={{marginTop:30}}>
                                        <PlayCircleOutlineIcon style={{fontSize:30,color:'white'}}/> &nbsp; <Text>NUTRIKIDS</Text>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" style={{marginTop:20}}>
                                        <PlayCircleOutlineIcon style={{fontSize:30,color:'white'}}/> &nbsp; <Text>QUẢN LÝ THU CHI</Text>
                                    </Box>

                                    <Box display="flex" justifyContent="flex-start" style={{marginTop:15}}>
                                        <PlayCircleOutlineIcon style={{fontSize:30,color:'white'}}/> &nbsp; <Text>KIỂM THỰC BA BƯỚC</Text>
                                    </Box>      

                                    <Box display="flex" justifyContent="flex-start" style={{marginTop:15}}>
                                        <PlayCircleOutlineIcon style={{fontSize:30,color:'white'}}/> &nbsp; <Text>TẠO WEBSITE TỰ ĐỘNG</Text>
                                    </Box>      
                                    <Box display="flex" justifyContent="flex-start" style={{marginTop:15}}>
                                        <PlayCircleOutlineIcon style={{fontSize:30,color:'white'}}/> &nbsp; <Text>Cân Đo Tự Động</Text>
                                    </Box>      
                                    <Box display="flex" justifyContent="flex-start" style={{marginTop:15}}>
                                        <PlayCircleOutlineIcon style={{fontSize:30,color:'white'}}/> &nbsp; <Text>30 NGÀY DÙNG THỬ</Text>
                                    </Box>
                                </RightContent>
                            </CenterContent>       
                            <RegisSoft onClick={()=>RegisKidTop()}>ĐĂNG KÝ SỬ DỤNG</RegisSoft>                 
                    </ContentRight>               
                </Content>                       
            </MainContent>

             <MainTinhUuViet>
                <TinhUuViet/>
             </MainTinhUuViet>

             <VideoComponent/>             
        </div>       
    );
});

export default Index;