export const GET_ALL_MEAL_NUTRIKID =  "GET_ALL_MEAL_NUTRIKID"
export const DISPATCH_ALL_MEAL_NUTRIKID_TO_STORE = "DISPATCH_ALL_MEAL_NUTRIKID_TO_STORE"

export const OPEN_TAO_THUC_DON  = "OPEN_TAO_THUC_DON"
export const CLOSE_TAO_THUC_DON = "CLOSE_TAO_THUC_DON"
export const TAO_THUC_DON       = "TAO_THUC_DON"
export const ADD_MEAL_NUTRIKID_FROM_LIBRARY_MEAL         = "ADD_MEAL_NUTRIKID_FROM_LIBRARY_MEAL"
export const DISPATCH_ADD_MEAL_NUTRIKID_TO_STORE_LIBRARY = "DISPATCH_ADD_MEAL_NUTRIKID_TO_STORE_LIBRARY"
export const OPEN_MATERIAL_IN_MEAL  = "OPEN_MATERIAL_IN_MEAL"
export const CLOSE_MATERIAL_IN_MEAL = "CLOSE_MATERIAL_IN_MEAL"

export const ADD_ITEM_MEAL_IN_LIBRARY    = "ADD_ITEM_MEAL_IN_LIBRARY"
export const UPDATE_ITEM_MEAL_IN_LIBRARY = "UPDATE_ITEM_MEAL_IN_LIBRARY"

export const USER_ID = "USER_ID"
export const ADD_MEAL_MENU_NUTRIKID_WEEK   = "ADD_MEAL_MENU_NUTRIKID_WEEK" //tao thuc don tuan
export const DISPATCH_VALUE_ADD_MEAL_WEEK  = "DISPATCH_VALUE_ADD_MEAL_WEEK"
export const UPDATE_VALUE_MEAL_WEEK        = "UPDATE_VALUE__MEAL_WEEK"
export const RETURN_VALUE_MEAL_WEEK_NULL   = "RETURN_VALUE_MEAL_WEEK_NULL"

export const DISPATCH_ARR_MATERIAL_PROCESS ="DISPATCH_ARR_MATERIAL_PROCESS"