import {DISPATCH_ADD_MEAL_NUTRIKID_TO_STORE ,DISPATCH_GET_DATE_MEAL_NUTRIKID_TO_STORE,
DISPATCH_ADD_MATERIAL_DATE_NUTRIKID_TO_STORE,DISPATCH_LUY_KE_TO_STORE,DISPATCH_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM_TO_STORE,
DISPATCH_CREATE_GET_TACH_HOC_SINH,DISPATCH_UPDATE_CREATE_GET_TACH_HOC_SINH,
DISPATCH_GET_LIST_TACH_HOC_SINH,DISPATCH_CREATE_GET_BRANCH_DIEM_TRUONG,DISPATCH_ADD_BRANCH_DIEM_TRUONG,
UPLOAD_EXCEL_MATERIAL_NUTRIKID,UPLOAD_EXCEL_MATERIAL_NUTRIKID_NULL_TO_STORE
} from "../contant/nutrikid";


export const UploadExcelMaterialNutrikidNullToStore=item=>{
    return{
        type:UPLOAD_EXCEL_MATERIAL_NUTRIKID_NULL_TO_STORE,
        payload:{item}
    }
}

export const UploadExcelMaterialNutrikid=item=>{
    return{
        type:UPLOAD_EXCEL_MATERIAL_NUTRIKID,
        payload:{item}
    }
}

export const DispatchAddBranchDiemTruong =(item)=>{
    return{
        type:DISPATCH_ADD_BRANCH_DIEM_TRUONG,
        payload:{item}
    }
}

export const DispatchCreateGetBranchDiemTruong =(item)=>{
    return{
        type:DISPATCH_CREATE_GET_BRANCH_DIEM_TRUONG,
        payload:{item}
    }
}

export const DispatchGetListTachHocSinh=(item)=>{
    return{
        type:DISPATCH_GET_LIST_TACH_HOC_SINH,
        payload:{item}
    }
}

export const DispatchUpdateCreateGetTachHocSinh=(item)=>{
    return{
        type:DISPATCH_UPDATE_CREATE_GET_TACH_HOC_SINH,
        payload:{item}
    }
}

export const DispatchCreateGetTachHocSinh=(item)=>{
    return{
        type:DISPATCH_CREATE_GET_TACH_HOC_SINH,
        payload:{item}
    }
}

export const DispatchAddMealNutrikidToStore=(item)=>{
    return{
        type:DISPATCH_ADD_MEAL_NUTRIKID_TO_STORE,
        payload:{item}
    }
}

export const DispatchGetMealNutrikidToStore=(item)=>{
    return{
        type:DISPATCH_GET_DATE_MEAL_NUTRIKID_TO_STORE,
        payload:{item}
    }
}

export const DispatchAddMaterialDateNutrikidToStore=(item)=>{
    return{
        type:DISPATCH_ADD_MATERIAL_DATE_NUTRIKID_TO_STORE,
        payload:{item}
    }
}

export const DispatchLuyKeToStore=(item)=>{
    return{
        type:DISPATCH_LUY_KE_TO_STORE,
        payload:{item}
    }
}

export const DispatchItemPhanBoNguyenLieuThucPhamToStore=(item)=>{
    return{
        type:DISPATCH_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM_TO_STORE,
        payload:{item}
    }
}