import { Table, TableCell, TableHead, TableRow,TableBody } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from 'antd';
import React from 'react';
import{useSelector} from 'react-redux'

const ListOfTuyenDung = ({value}) => {
    const ItemTuyenDung            = useSelector(state=>state.MainWeb.ItemTuyenDung)  
    const FilterTuyenDung = ItemTuyenDung.ListTuyenDung.filter(v=>v.nameTuyenDung===value)
 
    return (
        <div>
            {!FilterTuyenDung.length&&
            <Alert variant="filled" severity="error">Không Có Dữ Liệu</Alert>            
            }
            {FilterTuyenDung.length&&
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>STT</TableCell>
                        {value!=='DOI_TAC'&&
                            <>
                                  <TableCell>avatar</TableCell>
                            </>                            
                        }

                        {value=='DOI_TAC'&&
                            <>
                                  <TableCell>Tên Đơn vị</TableCell>
                            </>                            
                        }
                        <TableCell>Tên Khách Hàng</TableCell>
                        <TableCell>Số Điện Thoại</TableCell>
                        <TableCell>Email</TableCell>
                        {value==='DOI_TAC'&&
                            <TableCell>Mã Số Thuế</TableCell>
                        }
                        <TableCell>Địa chỉ</TableCell>
                        <TableCell>Lời Nhắn</TableCell>
                        <TableCell>Xử lý</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {FilterTuyenDung.map((v,i)=>{
                    return(
                        <TableRow>
                        <TableCell>{i+1}</TableCell>
                        {value!=='DOI_TAC'&&
                                <>
                                    <TableCell>{v.avatar!==""&&<img src={v.avatar} style={{width:80,height:80,borderRadius:'50%'}}/>}</TableCell>
                                     <TableCell>{v.nameCus}</TableCell>
                                </>                                
                        }      

                        {value!=='DOI_TAC'&&
                                <>
                                     <TableCell>{v.nameCus}</TableCell>
                                </>                                
                        }                      

                        <TableCell>{v.fullNameCus}</TableCell>
                        <TableCell>{v.tel}</TableCell>
                        <TableCell>{v.email}</TableCell>
                        {value==='DOI_TAC'&&
                             <TableCell>{v.taxCode}</TableCell>
                        }
                        <TableCell>{v.address}</TableCell>
                        <TableCell>{v.message}</TableCell>
                        <TableCell>{!v.approve?<Button>Đang xử lý</Button>:
                                                <Button>Đã xử lý</Button>
                        }</TableCell>
                    </TableRow>
                    )
                })}
                    
                </TableBody>
            </Table>
            }
        </div>
    );
};

export default ListOfTuyenDung;