import axiosServices from '../../../../api-services/api'
import { API_ENDPOINT } from '../../../../contant'


const urlRegisAccountAppKidsTopApi  = 'RegisAccountAppKidsTop'
const urlDispatchOtpToDbApi         = 'DispatchOtpToDb'
const urlCreateAcountUserKidstopApi = "CreateAcountUserKidstop"
const urlGetAutoImportPhatDatApi    = "GetAutoImportPhatDat"
const urlGetAutoImportPhatDatAfterCreateApi    = "GetAutoImportPhatDatAfterCreate"
const urlGetDetailProductItemApi               = 'GetDetailProductItem'
const urlFindValueImgApi                       = 'FindValueImg'
export const RegisAccountAppKidsTopApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlRegisAccountAppKidsTopApi}`,item)
}

export const DispatchOtpToDbApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDispatchOtpToDbApi}`,item)
}

export const CreateAcountUserKidstopApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlCreateAcountUserKidstopApi}`,item)
}

export const GetAutoImportPhatDatApi=()=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetAutoImportPhatDatApi}`)
}

export const GetAutoImportPhatDatAfterCreateApi=()=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetAutoImportPhatDatAfterCreateApi}`)
}

export const GetDetailProductItemApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetDetailProductItemApi}`,item)
}

export const FindValueImgApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlFindValueImgApi}`,item)
}
