import React from 'react'

import MyWeb from '../container/Login/MyWeb/component'
import WebMamNon from '../container/Login/WebMamNon/component'
import AdminHomePage from '../container/Admin/AdminHomePage/index'
import SignupPage from '../container/Login/SignupPage/index'
import LoginPage from '../container/Login/LoginPage/index'
import VerifyAccount from '../container/Login/VerifyAccount/index'
import UserPage from  '../container/Admin/page/userPage/index'
import CusNNPage from '../container/Gov/CusNNHomePage/index'
import SoftFood from '../container/Gov/SoftFood/index'
import LopChamAn from '../container/ClassActive/index'
import Nutrikids from '../ComponentGov/SoftFoodTabMain/Nutrikid/component/index'
import NutrikidIcon from '@material-ui/icons/ChildCare'
import GroupIcon from '@material-ui/icons/Group'
import AppsIcon from '@material-ui/icons/Apps'
import PersonIcon from '@material-ui/icons/Person'
import FaceIcon from '@material-ui/icons/Face'
import StaffPage from '../container/Staff/index'
import PersonPage from '../container/Person/index'
import InputIcon from '@material-ui/icons/Input'
import AppAdmin from '../APP_ADMIN_PERSON/Admin/index'
import SchoolIcon from '@material-ui/icons/School'

import QuanLyKho from '../APP_ADMIN_PERSON/Person/App/QuanLyKho'
import QuanLyBanHang from '../APP_ADMIN_PERSON/Person/App/QuanLyBanHang'
import QuanLyNhanVien from '../APP_ADMIN_PERSON/Person/App/QuanlyNhanVien/MainTab'
// import LopChamAn from '../ManagerAccountActiveGov/component/ClassActive/index'

import TaiKhoanNhanVienDangNhap from '../container/Person/TaiKhoanNhanVienDangNhap'

export const ADMIN_ROUTER =[
  {
    path:'/admin',
    name:'Quản Trị',
    exact:true,
    icon:GroupIcon,
    component:AdminHomePage
  }, 
  {
    path:'/admin/user',
    name:'Quản Lý Thành Viên',
    icon:PersonIcon,
    component:UserPage
  },
  {
    path:'/admin/applist',
    name:'Phần Mềm Ứng Dụng',
    icon:AppsIcon,
    component:AppAdmin
  }
]

export const GOV_ROUTER = [
  {
    path:'/active-soft-food',
    name:'Phần mềm tính ăn',   
    icon:FaceIcon,
    component:SoftFood,
    exact:true,
  },
  {
    path:'/user-cus-nn',
    name:'Thông Tin Khách Hàng',    
    icon:GroupIcon,
    component:CusNNPage
  }  
]

export const CLASS_ACTIVE_ROUTER = [
  {
    path:'/class-active',
    name:'Chấm Ăn',
    icon:SchoolIcon,
    component:LopChamAn,
    exact:true
  },
  {
    path:'/nutrikid',
    name:'Nutrikid',
    icon:NutrikidIcon,
    component:Nutrikids
  }
]

export const STAFF_ROUTER =[
  {
    path:'/staff',
    name:'Nhân Viên',
    icon:PersonIcon,
    component:StaffPage,
    exact:true
  },
  {
    path:'/staff/detail',
    name:'chi tiet',
    icon:PersonIcon,
    component:StaffPage
  }
]

export const PERSON_ROUTER =[
  {
    path:'/person',
    name:'Trang thông tin',
    icon:PersonIcon,
    component:PersonPage,
    exact:true
  },
  {
    path:'/person/repo',
    name:'quản lý kho',
    icon:InputIcon,
    component:QuanLyKho
  },
  {
    path:'/person/staff',
    name:'quản lý nhân viên',
    icon:InputIcon,
    component:QuanLyNhanVien
  },
  {
    path:'/person/sell',
    name:'quản lý bán hàng',
    icon:InputIcon,
    component:QuanLyBanHang
  }
  // {
  //   path:'/person/diff',
  //   name:'quản lý khác',
  //   icon:InputIcon,
  //   component:QuanLyKhac
  // }
]

export const PERSON_STAFF_ROUTER =[
  {
    path:'/accountloginpersonstaff',
    name:'Nhân viên',
    icon:PersonIcon,
    component:TaiKhoanNhanVienDangNhap,
    exact:true
  },
]


export const ROUTES = [
  {
    path:'/login',
    name:'Dang nhap',
    component:LoginPage,
    exact:true,
  },
  {
    path:'/signup',
    name:'Dang ky',
    component:SignupPage
  },
  {
    path:'/verifyAccount',
    name:"khoi phuc tai khoan",
    component:VerifyAccount
  },
  {
    path:'/login/success/:id',
    name:"khoi phuc thanh cong",
    component:({match})=> <LoginPage match={match}/>   
  },
  {
    path:'/login/verifypayment/:id',
    name:"verifypayment",
    component:({match})=> <LoginPage match={match}/>   
  },
  {
    path:'/login/:value',
    name:"link die",
    component:({match})=> <LoginPage match={match}/>   
  }
]

export const WEB_MAM_NON =[
  {
    path:'/web-mamnon/:name',
    name:'truonghocmamnon',
    component:(({match})=><WebMamNon match={match}/>),
    exact:true,
  }
]

export const CREATE_MY_WEB =[
  {
    path:'/web/:name',
    name:'myweb',
    component:(({match})=><MyWeb match={match}/>),
    exact:true
  }
]