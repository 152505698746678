import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  marginBaoAn:{
    marginBottom:'5px'
  },
  selectAll:{
    cursor:'pointer'
  },
  SaveCountEat:{
    cursor:'pointer',
    color:'red'
  },
  numStu:{
    cursor:'pointer',
    marginLeft: '182px',
    marginTop: '-23px'
  },
  Point:{
    cursor:'pointer'
  } 
}));