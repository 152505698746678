import styled,{css,keyframes} from 'styled-components'
import {device} from './device'
export const SearchHeader = styled.div`
            float: right;
            margin-top: -60px;
            padding: 28px 29px;
            height: 60px;           
            &:hover {
                background-color:#c0392b;
                transition: 0.3s all ease-in-out;
                cursor:pointer;
            }     
            @media ${device.mobile}{
               display:none;
            }
            @media ${device.tablet}{
               display:none;
            }
`
export const SearchHeader_ = styled.div`
            padding: 28px 29px;
            height: 60px;   
            top: -20px;
            z-index: 10;
            right: -11px;
            position: absolute;        
            &:hover {
                ${'' /* background-color:black; */}
                transition: 0.3s all ease-in-out;
                cursor:pointer;
            }                 
            @media ${device.pc}{
               display:none;
            }
            @media ${device.tablet}{
               display:none;
            }
`

export const SearchIconClass =styled.i`
        color:#FFFFFF;        
`