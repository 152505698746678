import React ,{Suspense}from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
//import ThongTinComponent from '../Component_Thongtin'
//import CongViecComponent from '../Component_CongViec'
//import TongHop_Component from '../Component_TongHop'
import { useEffect } from 'react';
import { GET_CHAM_CONG, GET_LIST_AUTO_IMPORT, GET_LIST_SUPPLIES_MATERIAL_COMMODITY_BY_USER_ID, GET_LIST_TASK_JOB_STAFF_BY_MONTH, GET_QRCODE_IN_ACCOUNT_LOGIN } from '../contant';
import { GET_LIST_MANAGER_STAFF, GET_LIST_TASK_JOB } from '../../App/QuanlyNhanVien/contant';
import { GET_CATEGORY, GET_LIST_IMPORT_MATERIAL } from '../../App/QuanLyKho/MainTab/Categories/Contant';
import { GET_CUSTOMER } from '../../../Contant/Person/supplies';
import { useDispatch, useSelector } from 'react-redux';

const ThongTinComponent = React.lazy(()=>import('../Component_Thongtin'))
const CongViecComponent = React.lazy(()=>import('../Component_CongViec'))
const TongHop_Component = React.lazy(()=>import('../Component_TongHop'))

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`scrollable-auto-tabpanel-${index}`}
    aria-labelledby={`scrollable-auto-tab-${index}`}
    {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    //width: 500,
  },
}));

const Index=React.memo(()=> {
  const dispatch       = useDispatch()
  const ItemInfoStaff  = useSelector(state=>state.AccountLoginStaff.ItemInfoStaff)
  const classes        = useStyles();
  const theme          = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  useEffect(() => {      
    return()=>{
        if(ItemInfoStaff!==null){
            dispatch({
              type:GET_LIST_AUTO_IMPORT
            })
   
            dispatch({
            type:GET_LIST_MANAGER_STAFF,
            payload:{item:{userId:ItemInfoStaff.userId}}
           })
        
                dispatch({
                type:GET_QRCODE_IN_ACCOUNT_LOGIN,
                payload:{item:{userId:ItemInfoStaff.userId}}
               })
            
                 dispatch({
                  type:GET_CHAM_CONG,
                  payload:{item:{userId:ItemInfoStaff.userId,idStaff:ItemInfoStaff.idStaff}}
                })
         
                dispatch({
                type:GET_LIST_SUPPLIES_MATERIAL_COMMODITY_BY_USER_ID,
                payload:{item:{userId:ItemInfoStaff.userId,idStaff:ItemInfoStaff.idStaff}}
              })
            
                dispatch({
                  type:GET_CATEGORY,
                  payload:{item:{
                    userId:ItemInfoStaff.userId
                  }}
                })

                dispatch({
                  type:GET_CUSTOMER,
                  payload:{item:ItemInfoStaff}
                })
            
              dispatch({
                type:GET_LIST_TASK_JOB,
                payload:{item:ItemInfoStaff}
              })  

               dispatch({
              type:GET_LIST_TASK_JOB_STAFF_BY_MONTH,
              payload:{item:{
                userId:ItemInfoStaff.userId,
                idStaff:ItemInfoStaff.idStaff,
                month:Date.now()
              }}
           })

              dispatch({
                type:GET_LIST_IMPORT_MATERIAL,
                payload:{item:{
                  userId:ItemInfoStaff.userId
                }}
              })
          }     
    }        
  })

  return (
    <div className={classes.root}>      
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          //variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Thông tin" {...a11yProps(0)} />
          <Tab label="Công việc" {...a11yProps(1)} />
          <Tab label="Tổng hợp" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

        <TabPanel value={value} index={0} dir={theme.direction}>
          <Suspense fallback={<>Dang Nap Du Lieu</>}>
                        <ThongTinComponent/>
          </Suspense>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Suspense fallback={<>Dang Nap Du Lieu</>}>
             <CongViecComponent/>
          </Suspense>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
            <Suspense fallback={<>Dang Nap Du Lieu</>}>
              <TongHop_Component/>
          </Suspense>         
        </TabPanel>
    </div>
  );
})

export default Index
