import React from 'react'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {Tooltip} from '@material-ui/core'
import {Save,Cancel} from '@material-ui/icons'
import {CLOSE_ADD_TYPE_OF_SERVICE,DISPATCH_VALUE_TO_DB} from '../../../Contant/Admin/AddViewAppService'
import {OPEN_DUPPLICATE} from '../../../Contant/helper'
import {useStyles} from '../styles'
import {CheckDupplicateTypeOfService} from '../../../Helper/Fn/Array'

 const ActionButton=()=>{
  const ValueAddTypeOfService = useSelector(state=>state.AdminAddService.ValueAddTypeOfService)
  const ListTypeOfService     = useSelector(state=>state.AdminAddService.ListTypeOfService)
  const classes = useStyles()
  const dispatch= useDispatch()
  const CancelAddTypeOfService=()=>{
    dispatch({
      type:CLOSE_ADD_TYPE_OF_SERVICE
    })
  }
  const AddTypeOfService=()=>{
     let CheckDupplicate = CheckDupplicateTypeOfService(ValueAddTypeOfService,ListTypeOfService)
     //false co ton tai, true la khong ton tai
    if(CheckDupplicate){
      dispatch({
        type:OPEN_DUPPLICATE
      })
      return true      
    }
    dispatch({
      type:DISPATCH_VALUE_TO_DB,
      payload:{item:ValueAddTypeOfService}
    })
    CancelAddTypeOfService()
  }
     return(
       <>
          <Tooltip title="Thêm" className={classes.buttonTooltip} onClick={()=>AddTypeOfService()}>
            <Save color="primary"/>
          </Tooltip>
          <Tooltip title="Thoát" className={classes.buttonTooltip} onClick={()=>CancelAddTypeOfService()}>
            <Cancel color="secondary"/>
          </Tooltip>
       </>
     )
}

export default ActionButton
  

     



