import {fork,all} from 'redux-saga/effects'

import LoginPageCongNghe from './LoginPageQuanTri'
import InfoGroupQuanTri from './InfoGroupQuanTri'
import InfoGroupNhanVien from './InfoGroupNhanVien'
import InfoGroupKhachHang from './InfoGroupKhachHang'

function* IndexSaga(){
    yield all(
        [
            yield fork(LoginPageCongNghe),
            yield fork(InfoGroupQuanTri),
            yield fork(InfoGroupNhanVien),
            yield fork(InfoGroupKhachHang)
        ]
    )
}

export default IndexSaga