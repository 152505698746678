import {SHOW_MANAGER_GOV_SOCKET,HIDE_MANAGER_GOV_SOCKET,DISPATCH_ARR_GOV_UPDATE_SOCKET} from '../../contant/SocketGov/index'

let initialState ={
  OpenGovSocket:false,//dong mo quan ly gov
  ArrGovSocket:[] //mang cac account nhom gov
}

const GovSocketReducer=(state=initialState,action)=>{
    switch (action.type) {
      case SHOW_MANAGER_GOV_SOCKET:
       // console.log(action.payload.item)
        return{
          ...state,
          OpenGovSocket:true,
          ArrGovSocket:action.payload.item
        }    
        case HIDE_MANAGER_GOV_SOCKET:
          return{
            ...state,
            OpenGovSocket:false
          }
      case DISPATCH_ARR_GOV_UPDATE_SOCKET:
        return{
          ...state,
          ArrGovSocket:action.payload
        }
      default: return state
      }
}

export default GovSocketReducer