import React from 'react'
import {TextField} from '@material-ui/core'
import {SearchComponent} from '../../../Helper/Fn/Tool'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));
export const SearchHtml =(handleChange)=>{
  const classes = useStyles();  
  return SearchComponent.map((v,i)=>{
    return (
      <form className={classes.root} noValidate autoComplete="off" key={i}>
      <TextField            
            label={v.label}
            defaultValue=""
            onChange ={handleChange}
            id={v.id}
      />
      </form>
    )
  })
}

//export default SearchHtml