import * as TypePageUser  from '../contant/pageUser'


export const hideActionFormUser = ()=>{
  return {
    type:TypePageUser.HIDE_ACTION_FORM_USER
  }
}

export const showActionFormUser = ()=>{
  return{
      type:TypePageUser.SHOW_ACTION_FORM_USER,      
  }
}

export const showFormUser = ()=>{
  return{
      type:TypePageUser.SHOW_FORM_USER        
  }
}

export const donotAddForm =()=>{
  return{
    type:TypePageUser.DO_NOT_ADD_FORM
  }
}

export const getListGroupNhaNuoc = (data,dataInfoUser)=>{
  return{
    type:TypePageUser.GET_LIST_GROUP_NHA_NUOC,
    payload:{data,dataInfoUser}
  }
}

export const getListAllUser = (data)=>{
  return{
    type:TypePageUser.GET_LIST_ALL_USER,
    payload:{data}
  }
}

export const hideFormUser = ()=>{
  return {
    type:TypePageUser.HIDE_FORM_USER
  }
}

export const showComponentFormUser = (component)=>{
  return{
      type:TypePageUser.SHOW_COMPONENT_FORM_USER,
      payload:{component}
  }
}



export const addFormInfoUser = (data)=>{
 return{
   type:TypePageUser.ADD_FORM_INFO_USER,
   payload:{
     data
   }
 }
}

export const addFormDepartment = (data)=>{
  return{
    type:TypePageUser.ADD_FORM_DEPARTMENT,
    payload:{data}
  }
}

export const addFormDepartmentSuccess = ()=>{
  return{
    type:TypePageUser.ADD_FORM_DEPARTMENT_SUCCESS
  }
}

export const addFormDepartmentFail = ()=>{
  return{
    type:TypePageUser.ADD_FORM_DEPARTMENT_FAIL
  }
}

export const addFormInfoUserSuccess = ()=>{
  return{
    type:TypePageUser.ADD_FORM_INFO_USER_SUCCESS
  }
}

export const addFormInfoUserFail =()=>{
  return{
    type:TypePageUser.ADD_FORM_INFO_USER_FAIL
  }
}

export const beginInstallId = ()=>{
  return{
    type:TypePageUser.BEGIN_INSTALL_ID
  }
}

export const beginInstallForm = ()=>{
  return{
    type:TypePageUser.BEGIN_INSTALL_FORM   
  }
}
export const checkExitId = (id)=>{
  return{
    type:TypePageUser.CHECK_EXIT_ID,
    payload:{id}
  }
}

export const checkExitIdSuccess = (data)=>{
  return{
    type:TypePageUser.CHECK_EXIT_ID_SUCCESS,
    payload:{data}
  }
}

export const infoUser = (title,form,name)=>{
  return{
    type:TypePageUser.INFO_USER,
    payload:{title,form,name}
  }
}

export const checkExitIdFail =()=>{
  return{
    type:TypePageUser.CHECK_EXIT_ID_FAIL,
    }
}

export const getListAllInfoUser= (data)=>{
  return{
    type:TypePageUser.GET_LIST_ALL_INFO_USER,
    payload:{data}
  }
}
export const getAllListDepartment = (data)=>{ 
  return {
    type:TypePageUser.GET_LIST_DEPARTMENT,
    payload:{data}
  }
}

//Department Gov
export const showFormDepartmentGov = ()=>{
    return{
      type:TypePageUser.SHOW_FORM_DEPARTMENT_GOV
    }
}

export const showActionDepartmentGov = ()=>{
  return{
    type:TypePageUser.SHOW_ACTION_DEPARTMENT_GOV
  }
}

export const showComponentDepartmentGov = (component)=>{
  return{
    type:TypePageUser.SHOW_COMPONENT_DEPARTMENT_GOV,
    payload:{component}
  }
}

export const hideFormDepartmentGov = ()=>{
  return{
    type:TypePageUser.HIDE_FORM_DEPARTMENT_GOV
  }
}
export const hideFormDepartmentGovWhenSubmit = ()=>{
  return{
    type:TypePageUser.HIDE_FORM_DEPARTMENT_GOV_WHEN_SUBMIT
  }
}


