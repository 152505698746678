import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { DivOne, TextOne_p,TextOne, DivTwo, DivFour, DivFive, DivThree, TextOne__p,MainChooseUs } from './styleOffer';
import { makeStyles } from '@material-ui/core/styles';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
//import LineComponent from '../FnComponent/LineInBG'
import LineIn from './LineIn'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

const OfferSoft = React.memo(() => {
    const classes = useStyles();

    return (
        <MainChooseUs className={classes.root}>
            <Grid container style={{paddingTop:30}}>
                    <Grid item xs={12} sm={6} style={{marginTop:20}}>
                         <DivOne>
                            <TextOne>WE OFFER SOFT KIDTOP</TextOne>
                            <TextOne__p>Là sản phầm phù hợp với các cơ sở giáo dục mầm non và tiểu học hoặc các doanh nghiệp và đơn vị có nhà ăn tập thể. Chúng tôi hi vọng KidTop sẽ trở thành một công cụ hữu ích đối với quý khách hàng, đảm bảo tối ưu hóa việc quản lý và mang lại hiệu suất cao trong công việc.</TextOne__p>
                        </DivOne>         
                        <LineIn/>            
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <DivTwo>
                           <Box display="flex" justifyContent="flex-start">
                                <ViewComfyIcon style={{fontSize:30,color:'#e67e22'}}/> <TextOne>Quản lý dinh dưỡng</TextOne>
                           </Box>  
                            <TextOne_p>
                                Nhằm tiết kiệm thời gian và công sức cho khách hàng, chúng tôi đã thiết kế một giao diện đơn giản, dễ dùng, với nhiều tab chức năng hỗ trợ tính Nutrikids nhanh chóng.                   
                            </TextOne_p>
                        </DivTwo>
                        <DivThree>
                             <Box display="flex" justifyContent="flex-start">
                                <PeopleAltIcon style={{fontSize:30,color:'#e67e22'}}/> <TextOne>Quản lý học sinh</TextOne>
                            </Box>  
                            <TextOne_p>
                                Chúng tôi rất hân hạnh cung cấp cho quý khách hàng một giải pháp hoàn thiện để quản lý số buổi học cho các cháu .         
                            </TextOne_p>
                        </DivThree>
                    </Grid> 
                    <Grid item xs={12} sm={3}>
                         <DivFour>
                            <Box display="flex" justifyContent="flex-start">
                                <TouchAppIcon style={{fontSize:30,color:'#e67e22'}}/> <TextOne>Quản lý phiếu ăn</TextOne>                                    
                            </Box>  
                            <TextOne_p>
                                        Với tính năng quản lý phiếu ăn chính xác, đáp ứng yêu cầu tính phiếu ăn lũy kế nhanh chóng rõ ràng theo từng tháng,tránh sai sót trong quá trình kiểm soát.                 
                            </TextOne_p>
                        </DivFour>
                        <DivFive>
                            <Box display="flex" justifyContent="flex-start">
                                <SyncAltIcon style={{fontSize:30,color:'#e67e22'}}/><TextOne>Quản lý tài chính</TextOne>                                  
                            </Box>                           
                            <TextOne_p>
                               Phần mềm quản lý thu chi được thiết kế để phục vụ công tác quản lý tài chính trở nên tự động, chính xác và nhanh chóng hơn.        
                            </TextOne_p>
                        </DivFive>
                    </Grid>                        
                 {/* <LineComponent/> */}
            </Grid>         
        </MainChooseUs>
    );
});

export default OfferSoft;