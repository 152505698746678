import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import YouTube from 'react-youtube';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100',
    flexGrow: 1,
    marginTop:10,
    background:'#e67e22',
    // backgroundImage:'linear-gradient(0,#27ae60,#4cb2b6)',
    borderRadius:'2%'
  }, 
}));

const YouTuBe =()=>{
  const classes  = useStyles();
  const ValueItem = useSelector(state=>state.GioiThieuWeb.ValueOpenYoutube)

  const VideoDefault = "5Y9xMZWSpTU"
      const opts ={
              height: '400',
              width: '100%',
            }
      const _onReady=(event)=>{
            event.target.playVideo();
      }

      const RenderYouTuBe =()=>{
        if(ValueItem!==null){
          return(
            <div style={{marginTop:15}}>
                            <YouTube videoId={ValueItem} opts={opts} onReady={_onReady} />
            </div>
          )
        }
        if(ValueItem===null){
          return(
            <div style={{marginTop:15}}>
                            <YouTube videoId={VideoDefault} opts={opts} onReady={_onReady} />
            </div>
          )
        }
      }
           
          return  <>       
                    <div className={classes.root}> 
                      <Box display="flex" justifyContent="center" >
                          <Typography   style={{color:'#ecf0f1',fontFamily:'Tahoma',marginTop:5,fontWeight:'bold'}}>KidTop Phần Mềm Dinh Dưỡng</Typography>
                      </Box>
                      {/* <Box display="flex" justifyContent="center" >                                  
                            <Typography style={{color:'#ecf0f1',fontFamily:'Roboto',fontStyle:'italic',fontWeight:'bold',fontSize:12}}>
                                Miễn Phí Sử Dụng 30 Ngày      
                            </Typography>    
                        </Box>  
                        <Box display="flex" justifyContent="center" >                                  
                            <Typography style={{color:'#ecf0f1',fontFamily:'Roboto',fontStyle:'italic',fontWeight:'bold',fontSize:12}}>
                                Hoàn trả tiền mua sản phẩm trong 15 ngày sử dụng nếu không phù hợp!
                            </Typography>    
                        </Box>   */}
                      <div style={{margin:5,padding:5,overflow:'-moz-hidden-unscrollable'}}>
                        {RenderYouTuBe()}
                      </div>
                    </div>                    
                  </>    
}

export default YouTuBe