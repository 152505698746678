import React from 'react';
import {Dialog} from '@material-ui/core'


function OpenForm({open,handleClose,ComponentAction}) { 
  return (
   
    <Dialog onClose={handleClose} open={open}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    >
      {ComponentAction}
    </Dialog>
  );
}

export default OpenForm