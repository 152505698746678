import React,{Fragment} from 'react'
import style from './style'
import {withStyles, Grid,DialogContent,DialogActions,Button} from '@material-ui/core'
import {reduxForm,Field} from 'redux-form'
import renderTextField from '../../../component/formHelper/TextField/index'
import {connect} from 'react-redux'
import {bindActionCreators,compose} from 'redux'
import renderSelectField from '../../../component/formHelper/Select/index'
import validate from './validate'
import * as actionsUserPage from '../../../actions/pageUser'

class FormGOV extends React.Component{
  handleSubmitForm=(data)=>{    
     let{formDepartment} = this.props
     let{addFormDepartment,hideFormDepartmentGovWhenSubmit} = formDepartment     
     hideFormDepartmentGovWhenSubmit()
     addFormDepartment(data)
   }

  renderSelectAccount = ()=>{  
    let {GetData}  = this.props        
      let xhtml = ''   
      if(GetData){
        xhtml =(                          
          <Grid item md={6}>                
            <Field
                margin="dense"            
                label="Trực thuộc"
                name="infoUserId"
                type="text"
                component={renderSelectField}                                        
                fullWidth             
              >     
                    <option/>                           
                    {GetData.map((item,index)=>{
                      return (<option value={item.userId} key={index}>{item.workPlace}</option> )
                    })}                     
              </Field>                      
          </Grid>       
        )
                 }      
   return xhtml      
  } 

  render() {
    let {handleOpenCloseModal,handleSubmit,invalid,submitting} = this.props  
    let {hideFormDepartmentGov} = handleOpenCloseModal             
    return (
      <Fragment>
         <form onSubmit={handleSubmit(this.handleSubmitForm)} autoComplete="off">         
          <DialogContent>  
            <Grid container spacing={1}>             
                  {this.renderSelectAccount()}                
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"      
                        label="Tên Phòng Ban"
                        name="departmentName"
                        component={renderTextField}
                        type="text"                     
                        fullWidth             
                      />       
                  </Grid>
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"      
                        label="Tên đầy đủ"
                        name="humanName"
                        component={renderTextField}
                        type="text"                     
                        fullWidth             
                      />       
                  </Grid>
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"          
                        label="Số điên thoại"
                        name="tel"
                        component={renderTextField}
                        type="text"                      
                        fullWidth             
                      />       
                  </Grid>
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"          
                        label="Địa chỉ"
                        name="address"
                        component={renderTextField}
                        type="text"                      
                        fullWidth             
                      />       
                  </Grid>
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"        
                        label="Email"
                        name="email"
                        component={renderTextField}
                        type="text"                      
                        fullWidth             
                      />       
                  </Grid>                  
                  {/* <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"           
                        label="Ảnh đại diện"
                        name="avatar"
                        component={renderTextField}
                        type="text"
                        //value=''
                        fullWidth             
                      />       
                  </Grid>            
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"           
                        label="Chữ Ký"
                        name="signature"
                        component={renderTextField}
                        type="text"
                        // value=''
                        fullWidth             
                      />       
                  </Grid>                   */}
               </Grid>       
          </DialogContent>
          <DialogActions>           
            <Button onClick={hideFormDepartmentGov} color="default" variant="contained" >
              Hủy
          </Button>
          <Button disabled={invalid||submitting}  color="primary" type="submit" variant="contained">
              Lưu lại
          </Button>
          </DialogActions>   
        </form>    
      </Fragment>                  
    )
  }
}

const mapStateToProps = state =>{
      return {
       // initialValues:state.auth.GetData,
        //task:state.task.taskEditting ? state.task.taskEditting:'',
        // status:state.task.taskEditting.status ? state.task.taskEditting.status:''      
        // open:state.userPage.showFormUser,
        close:state.userPage.showForm,       
        GetData:state.auth.GetData                      
      }
}

const mapDispatchStateToProps = dispatch =>{
  return{
      handleOpenCloseModal: bindActionCreators(actionsUserPage,dispatch),
      formDepartment: bindActionCreators(actionsUserPage,dispatch)     
  }
}


const FORM_NAME = "FORM_MANAGER_GOV"

const withConnect = connect(mapStateToProps,mapDispatchStateToProps)

const withReduxForm = reduxForm({
  form:FORM_NAME,
  validate
})

export default compose(
  withStyles(style),
  withConnect, 
  withReduxForm
  )(FormGOV)