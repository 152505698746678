import React from 'react'
import {Grid,Paper,Typography,Button} from '@material-ui/core'
import {useDispatch} from 'react-redux'
//import {useStyles} from './styles'
import TableListGovComponent from './listTable'
import {HIDE_MANAGER_GOV_SOCKET} from '../../../contant/SocketGov'

const Index = () => {  
  //const classes = useStyles()
  const dispatch = useDispatch()
  const ReturnMain=()=>{
      dispatch({
        type:HIDE_MANAGER_GOV_SOCKET
      })
  }
    return (          
      <>    
          <Grid container spacing={2}>         
              <Grid item xs={6} sm={4}>                
                <Paper>
                  <Typography align="center">Nhóm Nhà Nước</Typography>
                  <TableListGovComponent/>
                </Paper>       
                <Button size="small" color="secondary" variant="outlined" onClick={()=>ReturnMain()} mt="1">Quay Lại</Button>         
              </Grid>
          </Grid>        
      </>
    )
}

export default Index