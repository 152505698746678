const style = (theme)=>({
  // root: {
  //   // height: 0,
  //   // transform: 'translateZ(0px)',
  //   // flexGrow: 1,
  //   //position: 'relative',
  //   bottom:0,
  //   right:0   
  // },
  speedDial: {    
    position:'fixed',
    zIndex:1000,
    //bottom: 0,
    right: 0,   
    top:0,
    fontSize:15
  }
 
})

export default style