export const OPEN_PROCESS_BMI  = "OPEN_PROCESS_BMI"
export const CLOSE_PROCESS_BMI  = "CLOSE_PROCESS_BMI"
export const OPEN_CREATE_WEBSITE  = "OPEN_CREATE_WEBSITE"
export const CLOSE_CREATE_WEBSITE = "CLOSE_CREATE_WEBSITE"

export const CREATE_NEW_WEBSITE = "CREATE_NEW_WEBSITE"


export const OPEN_CREATE_QRCODE  = "OPEN_CREATE_QRCODE"
export const CLOSE_CREATE_QRCODE = "CLOSE_CREATE_QRCODE"

export const OPEN_TUYEN_DUNG  = "OPEN_TUYEN_DUNG"
export const CLOSE_TUYEN_DUNG = "CLOSE_TUYEN_DUNG"

export const GET_ADD_TUYEN_DUNG = "GET_ADD_TUYEN_DUNG"
export const DISPATCH_ITEM_TUYEN_DUNG_TO_STORE = "DISPATCH_ITEM_TUYEN_DUNG_TO_STORE"
export const UPDATE_ITEM_TUYEN_DUNG = "UPDATE_ITEM_TUYEN_DUNG"
export const DISPATCH_UPDATE_TUYEN_DUNG_SUCCESS = "DISPATCH_UPDATE_TUYEN_DUNG_SUCCESS"