export const GET_LINK_CHECK_DOMAIN             = "GET_LINK_CHECK_DOMAIN"
export const DISPATCH_RESULT_WEB_AUTO_TO_STORE = "DISPATCH_RESULT_WEB_AUTO_TO_STORE"
export const GET_INFO_USER_DETAIL_WEB          = "GET_INFO_USER_DETAIL_WEB"
export const DISPATCH_INFO_USER_DETAIL_WEB     = "DISPATCH_INFO_USER_DETAIL_WEB"

export const SEND_REGIS_STUDENT_WEB                 = "SEND_REGIS_STUDENT_WEB"
export const SEND_REGIS_STUDENT_WEB_SUCCESS         = "SEND_REGIS_STUDENT_WEB_SUCCESS"
export const SEND_REGIS_STUDENT_WEB_SUCCESS_FAIL    = "SEND_REGIS_STUDENT_WEB_SUCCESS_FAIL"

export const DISPATCH_REGIS_LOPHOC_MAM_NON         = "DISPATCH_REGIS_LOPHOC_MAM_NON"

export const OPEN_VIEW_IMAGE_SLIDE                  = "OPEN_VIEW_IMAGE_SLIDE"
export const CLOSE_VIEW_IMAGE_SLIDE                 = "OPEN_VIEW_IMAGE_SLIDE"