import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { GropupIcon, ItemCarosel,NameSchool,SpeechIcon, TextIn } from "./style";
import ChatIcon from '@material-ui/icons/Chat';
import StarIcon from '@material-ui/icons/Star';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { Box } from "@material-ui/core";
import { ListIdeaCustomer } from "./fn";


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(7),
     height: theme.spacing(7),
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
      large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
  }));

const Index=React.memo(({})=>{   
    const classes = useStyles();
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 1024 },
          items: 2,
          slidesToSlies:2
        },
        desktop: {
          breakpoint: { max: 1024, min: 800 },
          items: 2,
          slidesToSlies:3
        },
        tablet: {
          breakpoint: { max: 800, min: 464 },
          items: 1,
          slidesToSlies:1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlies:1
        }   
    }    
    return(
        <> 
                            <Carousel                       
                                        responsive={responsive}
                                        infinite={true}
                                        autoPlaySpeed={7000}   
                                        autoPlay={true}  
                            >                                
                                {/* <ContentItem> */}
                                {ListIdeaCustomer.map((v,i)=>{
                                    return(
                                        <ItemCarosel key={i}>
                                        <TextIn>                                       
                                            <StarIcon style={{color:'#f39c12'}}/>&nbsp;                                  
                                            <StarIcon style={{color:'#f39c12'}}/>&nbsp;
                                            <StarIcon style={{color:'#f39c12'}}/>&nbsp;
                                            <StarIcon style={{color:'#f39c12'}}/>&nbsp;
                                            <StarIcon style={{color:'#f39c12'}}/>&nbsp;                                         
                                                {v.content}
                                        </TextIn>   
                                            <SpeechIcon>
                                                <ChatIcon style={{color:'#7f8c8d',fontSize:40}}/>
                                            </SpeechIcon>     
                                            <GropupIcon>
                                                    <Box display="flex" justifyContent="flex-start">
                                                            <Avatar style={{fontSize:40}} src={v.name} className={classes.orange}>{v.name}</Avatar>            
                                                            <NameSchool>{v.nameSchool}</NameSchool>
                                                    </Box>
                                            </GropupIcon> 
                                     </ItemCarosel> 
                                    )
                                })}
                                        

                                     
                              
                                  
                                
                                                   
                                </Carousel>
            
        </>       
    )
})

export default Index