import {fork,all} from 'redux-saga/effects'
import ActiveAccount from './activeAccount'

function* IndexSaga(){
  yield all(
    [
        yield fork(ActiveAccount),
    ]
  )
}

export default IndexSaga