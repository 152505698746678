import { toastSuccess } from "../../../../../helper/toastHelper";
import { CLOSE_PROCESS_BMI, OPEN_PROCESS_BMI,OPEN_CREATE_QRCODE,CLOSE_CREATE_QRCODE, OPEN_TUYEN_DUNG, CLOSE_TUYEN_DUNG, DISPATCH_ITEM_TUYEN_DUNG_TO_STORE, DISPATCH_UPDATE_TUYEN_DUNG_SUCCESS, OPEN_CREATE_WEBSITE, CLOSE_CREATE_WEBSITE } from "../contant";
export const initialState={
    OpenProcessBMI:false,
    OpenQrcode:false,
    OpenCreateWebsite:false,
    OpenTuyenDung:false,
    ValueTuyenDung:null,
    ItemTuyenDung:null
}

const IndexReducer=(state=initialState,action)=>{

    switch (action.type) {
        case DISPATCH_UPDATE_TUYEN_DUNG_SUCCESS:
            toastSuccess('Gửi Tin Thành Công, Cám Ơn Bạn, Chúng Tôi Sẽ Liên Hệ Với Bạn')
            return{
                ...state,
                ItemTuyenDung:action.payload.item
            }
         
        case DISPATCH_ITEM_TUYEN_DUNG_TO_STORE:
            return{
                ...state,
                ItemTuyenDung:action.payload.item
            }

        case OPEN_TUYEN_DUNG:
            return{
                ...state,
                OpenTuyenDung:true,
                ValueTuyenDung:action.payload.item
            }

        case CLOSE_TUYEN_DUNG:
            return{
                ...state,
                OpenTuyenDung:false,
                ValueTuyenDung:null
            }

        case OPEN_CREATE_QRCODE:
            return{
                ...state,
                OpenQrcode:true
            } 

        case CLOSE_CREATE_QRCODE:
            return{
                ...state,
                OpenQrcode:false
            }

        case OPEN_PROCESS_BMI:
            return{
                ...state,
                OpenProcessBMI:true
            }

        case CLOSE_PROCESS_BMI:
            return{
                ...state,
                OpenProcessBMI:false
            }
        case OPEN_CREATE_WEBSITE:
            return {
                ...state,
                OpenCreateWebsite:true
            }
        case CLOSE_CREATE_WEBSITE:
            return {
                ...state,
                OpenCreateWebsite:false
            }

        default: return state
    }
}

export default IndexReducer