import { DeleteItemById, updateArrById } from "../../../../helper/fnAllways";
import { toastSuccess } from "../../../../helper/toastHelper";
import { DISPATCH_ITEM_APP_WEB_TO_STORE ,DISPATCH_LIST_REGIS_STUDENT_WEB,DISPATCH_LIST_GIOI_THIEU_WEB_TO_STORE, UPDATE_GIOI_THIEU_WEB, UPDATE_SUCCESS, UPDATE_ITEM_REGIS_STUDENT_WEB, DELETE_ITEM_REGIS_STUDENT_WEB} from "../Contant";

let initialState={
    ItemInfoWeb:null,
    ListRegisStudent:[],
    ItemGioiThieuWeb:null
}

const IndexReducer = (state=initialState,action)=>{
    switch (action.type) {
        case UPDATE_ITEM_REGIS_STUDENT_WEB:
            return{
                ...state,
                ListRegisStudent:updateArrById(state.ListRegisStudent,action.payload.item)
            }
        case DELETE_ITEM_REGIS_STUDENT_WEB:
            return{
                ...state,
                ListRegisStudent:DeleteItemById(state.ListRegisStudent,action.payload.item)
            }
        case UPDATE_SUCCESS:
            toastSuccess('Xoá Thành Công')
            return{
                ...state,
            }
        case UPDATE_GIOI_THIEU_WEB:
            toastSuccess('Cập nhật thành công')
            return{
                ...state,
                //ItemGioiThieuWeb:action.payload.item
            }
        case DISPATCH_LIST_GIOI_THIEU_WEB_TO_STORE:
            return {
                ...state,
                ItemGioiThieuWeb:action.payload.item
            }
        case DISPATCH_LIST_REGIS_STUDENT_WEB:
            return {
                ...state,
                ListRegisStudent:action.payload.item.reverse()
            }  
        case DISPATCH_ITEM_APP_WEB_TO_STORE:
            toastSuccess('Xử Lý Thành Công')
            return {
                ...state,
                ItemInfoWeb:action.payload.item
            }                       
        default: return state            
    }
}

export default IndexReducer