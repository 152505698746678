import React from 'react'
import {ShowDateName} from '../ClassActive/helper'
import {Button,Typography}from '@material-ui/core'
import {useSelector} from 'react-redux'

const Index =()=>{
  const DateInput  = useSelector(state=>state.ManagerAccount.DateInput)
  const ResultDate                = ShowDateName(DateInput)

  return(
    <Button variant="text" color="primary" size="small">
        <Typography>{ResultDate.dateName},&nbsp;{ResultDate.date}</Typography>
    </Button>
  )
}

export default Index