import React from 'react';
import YouTuBe from '../../../../../../../NotFoundPage/component/GioiThieu/Youtube';
import {ContentVideo,HuongDanContent,VideoContent} from './style';
import ChucNangPM from '../../../../../../../NotFoundPage/component/GioiThieu/CHUC_NANG_PM';

const Index = () => {
    return (
       
            <ContentVideo id="VIDEO">
                    <VideoContent>
                        <YouTuBe/>
                    </VideoContent>
                    <HuongDanContent>
                        <ChucNangPM/>
                    </HuongDanContent>
            </ContentVideo>
        
    );
};

export default Index;