import {SplitSpaceString} from './String'
//kiểm tra trung lăp
//every tất ca các đôi tuọng đuọc so sánh phải giống nhau =>true
//some 1 giá trị so sánh không tồn tại =>false
// regex: \s khoang trang
export const CheckDupplicateTypeOfService=(value,arr)=>{    
    return arr.some(v=>{
            let arrStringValue = SplitSpaceString(value.nameTypeOfService)
            let arrStringArr   = SplitSpaceString(v.nameTypeOfService)
            return HandleArrToArrFind(arrStringArr,arrStringValue)
    })
}
 const HandleArrToArrFind=(arrStringArr,arrStringValue)=>{        
        return arrStringArr.every(v=>arrStringValue.some(i=>i===v))       
}

export const FilterArrSearchByFullName =(arr,key)=>{
        return arr.filter(v=>v.fullName.toLocaleLowerCase().indexOf(key)!==-1)
}
