import {put,call,delay, takeLatest} from 'redux-saga/effects'
import {push} from 'connected-react-router'
import { DISPATCH_OTP_TO_DB, LOGIN_APP_KIDSTOP, REGIS_ACCOUNT_APP_KIDSTOP, GET_AUTO_IMPORT_PHAT_DAT, GET_AUTO_IMPORT_PHAT_DAT_AFTER_CREATE } from '../contant/GioiThieu'
import {DISPATCH_FIND_GET_VALUE_IMG, DISPATCH_VALUE_DETAIL_PRODUCT_TO_DB_HANDLE, OPEN_FORM_DETAIL_ITEM_PRODUCT} from '../contant/ThietBiCongNghe'
import { CreateAcountUserKidstopApi, DispatchOtpToDbApi, RegisAccountAppKidsTopApi,GetAutoImportPhatDatApi,GetAutoImportPhatDatAfterCreateApi,GetDetailProductItemApi, FindValueImgApi } from '../api/GioiThieu'
import { AlertResetAcountAction,LoadingStart,LoadingStop,DispatchMaterialCateAllPhatDatToStore,DispatchImgDetailProductToStore, DispatchFindValueImgToStore, UpdateItemDetailProduct } from '../action'
import { REDIRECT_SIGNUP_SUCCESS } from '../../../../contant/auth'
import {HandleArrPhatDat, handlePriceName, handlePriceSell} from './fn'
import { expiredMonth30Day } from '../../../../utils/DayTime'


const FAIL    = 'fail'
const SUCCESS = 'success'

function* RegisAccountAppKidsTopSaga({payload}){
    yield put(LoadingStart())
    let res = yield call(RegisAccountAppKidsTopApi,payload.item)
    if(res.data.result==='fail'){
        let item ={
            alert:res.data.message,         
            open:true,
            value:FAIL,
            valueItem:null
        }
        yield put(AlertResetAcountAction(item))
        yield put(LoadingStop())
    }
    if(res.data.result==='success'){
        let item ={
            alert:res.data.message,
            open:true,
            value:SUCCESS,
            valueItem:null
        }
        yield put(AlertResetAcountAction(item))
        yield put(LoadingStop())
    }
}

function* DispatchOtpToDbSaga({payload}){
    yield put(LoadingStart())
    let res = yield call(DispatchOtpToDbApi,payload.item)
    if(res.data.result===FAIL){
        let item ={
            alert:res.data.message,
            open:true,
            value:FAIL,
            valueItem:null
        }
        yield put(LoadingStop())
        yield put(AlertResetAcountAction(item))
    }
    if(res.data.result===SUCCESS){
        let item = {
            alert:res.data.message,
            open:true,
            value:SUCCESS,
            valueItem:res.data.data
        }
        let newValueDB=[item.valueItem.value].map(v=>{
            return{
                ...v,
                expired:expiredMonth30Day()
            }
        })[0]
        yield put(LoadingStop())
        yield put(AlertResetAcountAction(item))
        yield put(LoadingStart())
        //tao tai khoan
        let res2 =   yield call(CreateAcountUserKidstopApi,newValueDB)
        if(res2.data.result===SUCCESS){
            let item ={
                alert:res2.data.message,
                open:true,
                value:SUCCESS,
                valueItem:null
            }
            yield put(LoadingStop())
            yield put(AlertResetAcountAction(item))
            yield delay(3000)
            window.location.reload()            
        }
        if(res2.data.result===FAIL){
            let item2 ={
                alert:res2.data.message,
                open:true,
                value:FAIL,
                valueItem:null
            }
            yield put(LoadingStop())
            yield put(AlertResetAcountAction(item2))
        }
    }
}

function* GetAutoImportPhatDatSaga(){
      yield call(GetAutoImportPhatDatApi)
    //   yield put(loadingSuccess())
    //   yield delay(15000)
    //   yield put(loadingFail())
}

function* LoginAppKidstopSaga({}){
    yield put(push(REDIRECT_SIGNUP_SUCCESS))
}

function* GetAutoImportPhatDatAfterCreateSaga(){
    let res = yield call(GetAutoImportPhatDatAfterCreateApi)
    if(res.data.result===SUCCESS){
            let handleData = HandleArrPhatDat(res.data.data)     
            yield put(DispatchMaterialCateAllPhatDatToStore(handleData))
    }
}

function* DispatchValueDetailProductToDbHandleSaga({payload}){   
    yield delay(200)
    yield put(LoadingStart())
    let res = yield call(GetDetailProductItemApi,{item:payload.item.link})
    if(res.data.result===SUCCESS){
        let result={
            data:res.data.data,
            itemName:payload.item
        }
        yield put(DispatchImgDetailProductToStore(result))
        yield put(LoadingStop())
    }
}

function* DispatchFindGetValueImgSaga({payload}){
    let res = yield call(FindValueImgApi,payload.item)
    if(res.data.result===SUCCESS){
            yield put(DispatchFindValueImgToStore(res.data.data))
    }
}

function* OpenFormDetailItemProductSaga({payload}){
    yield put(LoadingStart())
    let res = yield call(GetDetailProductItemApi,{item:payload.item})

    if(res.data.result===SUCCESS){   
        let Result = [res.data.data].map(v=>{
            return{
                ...v,
              priceOri:handlePriceName(v.priceName),
              priceSell:handlePriceSell(v.priceName)
            }
        })[0]
        yield put(UpdateItemDetailProduct(Result))
        yield put(LoadingStop())
    }
}

function* Index(){
        yield takeLatest(REGIS_ACCOUNT_APP_KIDSTOP,RegisAccountAppKidsTopSaga)
        yield takeLatest(DISPATCH_OTP_TO_DB,DispatchOtpToDbSaga)
        yield takeLatest(LOGIN_APP_KIDSTOP,LoginAppKidstopSaga)
        yield takeLatest(GET_AUTO_IMPORT_PHAT_DAT,GetAutoImportPhatDatSaga)
        yield takeLatest(GET_AUTO_IMPORT_PHAT_DAT_AFTER_CREATE,GetAutoImportPhatDatAfterCreateSaga)
        yield takeLatest(DISPATCH_VALUE_DETAIL_PRODUCT_TO_DB_HANDLE,DispatchValueDetailProductToDbHandleSaga)
        yield takeLatest(DISPATCH_FIND_GET_VALUE_IMG,DispatchFindGetValueImgSaga)
        yield takeLatest(OPEN_FORM_DETAIL_ITEM_PRODUCT,OpenFormDetailItemProductSaga)
}

export default Index
