import axiosServices from '../../../../api-services/api'
import { API_ENDPOINT } from '../../../../contant'

const urlCreateWebAutoInfo      = 'CreateWebAutoInfo'
const urlGetWebAutoInfo         = 'GetWebAutoInfo'
const urlUpdateWebAutoInfo      = 'UpdateWebAutoInfo'
const urlGetListRegisStudentWeb = "GetListRegisStudentWeb"
const urlGetListGioiThieuWeb    = "GetListGioiThieuWeb"
const urlUpdateGioiThieuWeb     = "UpdateGioiThieuWeb"
const urlUpdateItemRegisStudentWeb = "UpdateItemRegisStudentWeb"
const urlDeleteItemRegisStudentWeb = "DeleteItemRegisStudentWeb"

export const DeleteItemRegisStudentWebApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteItemRegisStudentWeb}`,item)
}

export const UpdateItemRegisStudentWebApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateItemRegisStudentWeb}`,item)
}

export const UpdateGioiThieuWebApi =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateGioiThieuWeb}`,item)
}

export const GetListRegisStudentWebApi =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetListRegisStudentWeb}`,item)
}

export const CreateWebAutoInfoApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlCreateWebAutoInfo}`,item)
}

export const GetWebAutoInfoApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetWebAutoInfo}`,item)
}

export const UpdateWebAutoInfoApi =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateWebAutoInfo}`,item)
}

export const GetListGioiThieuWebApi =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetListGioiThieuWeb}`,item)
}

