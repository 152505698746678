import React ,{useEffect,useState}from 'react';
import {Dialog,DialogActions,DialogContent,Tooltip} from '@material-ui/core'
import {Print} from '@material-ui/icons'
import {Alert} from '@material-ui/lab'
import {showDDMMYY,showMMYY} from '../../../../helper/dateTime/handleDMY'
import {useSelector,useDispatch} from 'react-redux'
import TableList from './tableList'
import {useStyles}from './styles'
import {OPEN_PRINT_CHECK_DAY_FOOD,CLOSE_PRINT_CHECK_DAY_FOOD,GET_LIST_CLASS_STUDENT_LEAVE_TEMPORATY,OPEN_PRINT_ALL_CHECK_DAY_FOOD,CLOSE_PRINT_ALL_CHECK_DAY_FOOD}from '../../../contant/ManagerAccount'
import PrintCheckDayFoodForm from './Print'
import {HandleNewResultCheckDayFood,AllResultCheckDayFood} from './fn'
import PrintAllCheckDayFood from './PrintAll'

export default function Index({open,handleClose}) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const ItemManagerActiveAccount       = useSelector(state=>state.ManagerAccount.ItemManagerActiveAccount)

  const ListClassCheckDyFoodByDate     = useSelector(state=>state.ManagerAccount.ListClassCheckDyFoodByDate)
  const OpenPrintCheckDayFood          = useSelector(state=>state.ManagerAccount.OpenPrintCheckDayFood)  
  const ListStudentLeaveTemporaty      = useSelector(state=>state.ManagerAccount.ListStudentLeaveTemporaty)  
  const ListCheckDayFoodByMonth        = useSelector(state=>state.ManagerAccount.ListCheckDayFoodByMonth)  
  const OpenPrintAllCheckDayFood       = useSelector(state=>state.ManagerAccount.OpenPrintAllCheckDayFood)  


  const SelectDate                     = useSelector(state=>state.ManagerAccount.SelectDate)
  const Result                         = HandleNewResultCheckDayFood(ListClassCheckDyFoodByDate,ListStudentLeaveTemporaty,SelectDate)
  const ResultAll                      = AllResultCheckDayFood(ListCheckDayFoodByMonth,ListStudentLeaveTemporaty)

  const PrintCheckDayFood =()=>{
        dispatch({
          type:OPEN_PRINT_CHECK_DAY_FOOD
        })
  }
  const ClosePrintCheckDayFood=()=>{
    dispatch({
      type:CLOSE_PRINT_CHECK_DAY_FOOD
    })
  }

  const OPENPrintAllCheckDayFood=()=>{
      dispatch({
        type:OPEN_PRINT_ALL_CHECK_DAY_FOOD
      })
  }

  const ClosePrintAllCheckDayFood=()=>{
      dispatch({
        type:CLOSE_PRINT_ALL_CHECK_DAY_FOOD
      })
  }

  useEffect(() => {
        dispatch({
            type:GET_LIST_CLASS_STUDENT_LEAVE_TEMPORATY,
            payload:{item:{
              userId:ItemManagerActiveAccount[0].userId,
              dateNow:SelectDate
            }}
        })
  },[SelectDate])


  return (    
    <>
      {OpenPrintCheckDayFood&&<PrintCheckDayFoodForm
            open={OpenPrintCheckDayFood}
            handleClose={ClosePrintCheckDayFood}            
      />}

      {OpenPrintAllCheckDayFood&&<PrintAllCheckDayFood
            open={OpenPrintAllCheckDayFood}
            handleClose={ClosePrintAllCheckDayFood}
            list={ResultAll}
      />}

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Alert severity="info">Báo Ăn Các Lớp {showDDMMYY(ListClassCheckDyFoodByDate[0].date)}
              <Tooltip title="In Báo Ăn"><Print color="primary" className={classes.PrintCountEat} onClick={()=>PrintCheckDayFood()}/></Tooltip>
              <Tooltip title={`In tháng ${showMMYY(SelectDate)}`}><Print color="secondary" className={classes.PrintAll} onClick={()=>OPENPrintAllCheckDayFood()}/></Tooltip>
        </Alert>
        <DialogContent>    
           
              <TableList
                  list={Result}
              />
        </DialogContent>
        <DialogActions>
         
        </DialogActions>
      </Dialog>
    </>
  );
}
