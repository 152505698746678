import React from 'react';
import {Dialog,DialogContent} from '@material-ui/core';
import {useStyles} from './styles'
import styled from 'styled-components';
import {A4} from 'paper-print-react'
import PrintCheckDayFood from './PrintCheckDay'
const StyledDialog = styled(Dialog)`
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  max-width:1300px;
}
`

const PrintPaymentList =React.memo(({open,handleClose,listStu}) => {
 const classes = useStyles()
  return (
    <>    
      <StyledDialog open={open} onClose={handleClose}>        
        <DialogContent>                
                <A4 landscape>
                   <PrintCheckDayFood
                   listStu={listStu}
                   />
                </A4>                   
        </DialogContent>       
      </StyledDialog>
    </>
  );
})

export default PrintPaymentList