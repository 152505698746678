export const REGIS_ACCOUNT_APP_KIDSTOP = "REGIS_ACCOUNT_APP_KIDSTOP"
export const ALERT_REGIS_ACCOUNT       = "ALERT_REGIS_ACCOUNT"
export const CLOSE_ALERT               = "CLOSE_ALERT"
export const LOADING_START             = "LOADING_START"
export const LOADING_STOP              = "LOADING_STOP"
export const DISPATCH_OTP_TO_DB        = "DISPATCH_OTP_TO_DB"

export const WAIT_ALERT_TIME           = "WAIT_ALERT_TIME"
export const  REGIS_ACCOUNT_APP_KIDSTOP_SUCCESS = "Đăng Ký Thành Công, Truy Cập Email Của Bạn!"


export const LOGIN_APP_KIDSTOP        = "LOGIN_APP_KIDSTOP"
export const GET_AUTO_IMPORT_PHAT_DAT = "GET_AUTO_IMPORT_PHAT_DAT"
export const GET_AUTO_IMPORT_PHAT_DAT_AFTER_CREATE = "GET_AUTO_IMPORT_PHAT_DAT_AFTER_CREATE"

export const OPEN_VIDEO_YOUTUBE = "OPEN_VIDEO_YOUTUBE"