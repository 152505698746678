
  import {OPEN_FORM_ADD_CATEGORY,CLOSE_FORM_ADD_CATEGORY,
    CLOSE_FORM_ADD_MATERIAL,OPEN_FORM_ADD_MATERIAL,DISPATCH_ITEM_ADD_CATEGORY_TO_STORE,
  DISPATCH_LIST_CATEGORY_TO_STORE,OPEN_LIST_CATEGORY_MATERIAL,CLOSE_LIST_CATEGORY_MATERIAL,
      DISPATCH_VALUE_CATEGORY_TO_STORE,
      UPDATE_MATERIAL_MANAGER_REPO,UDPATE_CATEGORY_MANAGER_REPO,OPEN_UPLOAD_EXCEL,CLOSE_UPLOAD_EXCEL,
    OPEN_FORM_IMPORT_MATERIAL,CLOSE_FORM_IMPORT_MATERIAL,DISPATCH_ITEM_IMPORT_MATERIAL_TO_STORE,
    DISPATCH_GET_LIST_IMPORT_MATERIAL_TO_STORE,OPEN_FORM_DELETE_MATERIAL,CLOSE_FORM_DELETE_MATERIAL,
    DELETE_MATERIAL_MAMAGER_REPO,STATUS_DELETE_FALSE, DELETE_CATEGORY_MATERIAL_REPO,
    DISPATCH_ALL_MATERIALS,MANAGER_REPO,MANAGER_SUPPLIES, OPEN_FORM_UPDATE_IMPORT_MATERIAL, CLOSE_FORM_UPDATE_IMPORT_MATERIAL,
    OPEN_FORM_DELETE_IMPORT_MATERIAL,CLOSE_FORM_DELETE_IMPORT_MATERIAL,OPEN_FORM_AUTO_IMPORT,CLOSE_FORM_AUTO_IMPORT,OPEN_FORM_APPROVE_IMPORT,CLOSE_FORM_APPROVE_IMPORT,
    UPDATE_IMPORT_MATERIAL,DELETE_IMPORT_MATERIAL,DISPATCH_GET_LIST_IMPORT_STAFF_TO_STORE,OPEN_VIEW_IMAGE_IMPORT_STAFF,CLOSE_VIEW_IMAGE_IMPORT_STAFF, UPDATE_ITEM_IMPORT_STAFF} from '../Contant'
    import { toastSuccess} from '../../../../../../../helper/toastHelper'
    import {updateArrById} from '../../../../../../../helper/fnAllways'
    import {OPEN_ALERT_SUCCESS,CLOSE_ALERT_SUCCESS} from '../../../../../../Contant/helper'
    import { sortArrAsc } from '../../../../../../../helper/tableList/tableList'
    import {handleUpdateImportMaterial,handleDeleteImportMaterial} from './fn'
  export const initialState={
    OpenFormAddCategory:false,
    OpenFormAddMaterial:false,
    OpenUploadExcel:false,
    OpenFormImportMaterial:false,
    OpenFormDeleteMaterial:false,   
    OpenManagerRepo:false, 
    OpenManagerSupplies:false,
    OpenFormUpdateImportMaterial:false,
    OpenFormDeleteImportMaterial:false,
    OpenFormAutoImport:false,
    NameCategory:"",
    ListCategories:[],    
    ActiveListCategories:false,
    ListImportStaff:[],
    ActiveListImportStaff:false,
    OpenListCategoriesMaterial:false,
    statusDelete:false,
    ItemDeleteMaterial:{},
    ItemCategory:[],
    ListImportMaterial:[],
    ActiveListImportMaterial:false,
    ListAllMaterials:[],
    ItemSupplies:{},
    ItemMaterialToUpdate:{},
    ItemMaterialToDelete:{},
    OpenFormApproveImport:false,
    OpenViewImageImportStaff:false,
    ImgImportStaff:"",
    ItemApprove:false
  }

  
  const CategoriesManagerRepoReducer=(state=initialState,action)=>{
    switch (action.type) {
      //category
      case OPEN_FORM_ADD_CATEGORY:
        return{
          ...state,
          OpenFormAddCategory:true
        }
      case CLOSE_FORM_ADD_CATEGORY:
        return{
          ...state,
          OpenFormAddCategory:false
        }
          //material
      case OPEN_FORM_ADD_MATERIAL:
          return{
            ...state,
            OpenFormAddMaterial:true
          }
      case CLOSE_FORM_ADD_MATERIAL:
          return{
            ...state,
            OpenFormAddMaterial:false
         }
      case DISPATCH_ITEM_ADD_CATEGORY_TO_STORE:
        toastSuccess('Tạo Thành Công !')
        return{
          ...state,
          ListCategories:sortArrAsc(state.ListCategories.concat(action.payload.item))
        }
      case DISPATCH_LIST_CATEGORY_TO_STORE:
        return{
          ...state,
          ActiveListCategories:true,
          ListCategories:sortArrAsc(action.payload.item),
          ItemCategory:state.ItemCategory.length!==0?action.payload.item.filter(v=>v._id===state.ItemCategory[0]._id):[]
        }
      case OPEN_ALERT_SUCCESS:
       toastSuccess('Lưu thành công')
       return{
         ...state
       }
       case CLOSE_ALERT_SUCCESS:
        //toastError('Lỗi, kiểm tra lại')
        return{
          ...state
        }

      case OPEN_LIST_CATEGORY_MATERIAL:
        return{
          ...state,
          OpenListCategoriesMaterial:true
        }
      case CLOSE_LIST_CATEGORY_MATERIAL:
         return{
           ...state,
            OpenListCategoriesMaterial:false
          }
      case DISPATCH_VALUE_CATEGORY_TO_STORE:          
        return{
          ...state,
          ItemCategory:action.payload.item._id!==undefined?state.ListCategories.filter(v=>v._id===action.payload.item._id):[]
        }
      case UPDATE_MATERIAL_MANAGER_REPO:
        return{
          ...state,
          ItemCategory:action.payload.item
        }
      case UDPATE_CATEGORY_MANAGER_REPO:
        return{
          ...state,
          ItemCategory:action.payload.item,
          ListCategories:updateArrById(state.ListCategories,action.payload.item[0])
        }
      case OPEN_UPLOAD_EXCEL:
        return{
          ...state,
          OpenUploadExcel:true,
          NameCategory:action.payload.item
        }
      case CLOSE_UPLOAD_EXCEL:
        return{
          ...state,
          OpenUploadExcel:false,
          NameCategory:""
        }

      //import
      case OPEN_FORM_IMPORT_MATERIAL:
        return{
          ...state,
          OpenFormImportMaterial:true
        }

      case CLOSE_FORM_IMPORT_MATERIAL:
        return{
          ...state,
          OpenFormImportMaterial:false
        }

      case DISPATCH_ITEM_IMPORT_MATERIAL_TO_STORE:
        return{
          ...state,
          ListImportMaterial:(state.ListImportMaterial.concat(action.payload.item)).reverse()
        }
      case DISPATCH_GET_LIST_IMPORT_MATERIAL_TO_STORE:
        return{
          ...state,
          ListImportMaterial:action.payload.item,
          ActiveListImportMaterial:true
        }

      case OPEN_FORM_DELETE_MATERIAL:
        return{
          ...state,
          OpenFormDeleteMaterial:true,
          ItemDeleteMaterial:action.payload.item
        }
      case CLOSE_FORM_DELETE_MATERIAL:
          return{
            ...state,
            OpenFormDeleteMaterial:false
          }
        case DELETE_MATERIAL_MAMAGER_REPO:          
           return{
            ...state,
            ItemCategory:action.payload.item,
            statusDelete:true,
            ListCategories:updateArrById(state.ListCategories,action.payload.item[0])
          }
        case STATUS_DELETE_FALSE:
          return{
            ...state,
            statusDelete:false
          }
        case DELETE_CATEGORY_MATERIAL_REPO:
          return{
            ...state,
            ListCategories:state.ListCategories.filter(v=>v._id!==action.payload.item._id)
          }
        case DISPATCH_ALL_MATERIALS:
          return{
            ...state,
            ListAllMaterials:action.payload.item
          }
        case DISPATCH_GET_LIST_IMPORT_STAFF_TO_STORE:
          return{
            ...state,
            ActiveListImportStaff:true,
            ListImportStaff:action.payload.item
          }
        case UPDATE_ITEM_IMPORT_STAFF:
          toastSuccess('Phê duyệt thành công')
          return{
            ...state,
            ListImportStaff:updateArrById(state.ListImportStaff,action.payload.item)
          }

        case MANAGER_REPO:
          return{
            ...state,
            OpenManagerRepo:!state.OpenManagerRepo,
            OpenFormApproveImport:false
          }
        case MANAGER_SUPPLIES:
          return{
            ...state,
            OpenManagerSupplies:true
          }
        case OPEN_FORM_UPDATE_IMPORT_MATERIAL:
          return{
            ...state,
            OpenFormUpdateImportMaterial:true,
            ItemMaterialToUpdate:action.payload.item
          }
        case CLOSE_FORM_UPDATE_IMPORT_MATERIAL:
          return{
            ...state,
            OpenFormUpdateImportMaterial:false, 
            ItemMaterialToUpdate:{}       
            }
        case UPDATE_IMPORT_MATERIAL:           
         let New = handleUpdateImportMaterial(action.payload.item,state.ListAllMaterials,action.payload.ListSupplies)
          return{
            ...state,
            ListAllMaterials:New
          }
        case OPEN_FORM_DELETE_IMPORT_MATERIAL:
          return{
            ...state,
            OpenFormDeleteImportMaterial:true,
            ItemMaterialToDelete:action.payload.item
          }
        case CLOSE_FORM_DELETE_IMPORT_MATERIAL:
          return{
            ...state,
            OpenFormDeleteImportMaterial:false
          }
        case DELETE_IMPORT_MATERIAL:
          let NEW =  handleDeleteImportMaterial(state.ListAllMaterials,action.payload.item)
          return{
            ...state,
            ListAllMaterials:NEW
          }
        case OPEN_FORM_AUTO_IMPORT:
          return{
            ...state,
            OpenFormAutoImport:true
          }
          case CLOSE_FORM_AUTO_IMPORT:
              return{
                ...state,
                OpenFormAutoImport:false
              }
          case OPEN_FORM_APPROVE_IMPORT:
            return{
              ...state,
              OpenFormApproveImport:true,
              ItemApprove:action.payload.item
            }
          case CLOSE_FORM_APPROVE_IMPORT:
            return{
              ...state,
              OpenFormApproveImport:false
            }
          case OPEN_VIEW_IMAGE_IMPORT_STAFF:
            return{
              ...state,
              OpenViewImageImportStaff:true,
              ImgImportStaff:action.payload.item
            }
          case CLOSE_VIEW_IMAGE_IMPORT_STAFF:
            return{
              ...state,
              OpenViewImageImportStaff:false,
              ImgImportStaff:""
            }
              
            
        
     
      default: return state
    }
  }
  
  export default CategoriesManagerRepoReducer