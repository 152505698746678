import React from 'react';
import { ImgLine, LineInBG ,SpaceInBG} from './style';
import imgIcon from './icon.png'

const Line = () => {
    return (
           <SpaceInBG>
                        <LineInBG>
                        </LineInBG>                
                        <ImgLine  src={imgIcon} alt="nutrkids-nutrikid"/>
           </SpaceInBG>        
    );
};

export default Line;