import React from 'react';
import {Dialog,Box,Typography,TableContainer,Table,TableHead,TableRow,TableCell,TableBody,Paper, Grid
} from '@material-ui/core'
import {useSelector} from 'react-redux'
import {useStyles}from './styles'
import {A5}from 'paper-print-react'
import {changeUpperCase}from '../../../../helper/fnAllways'
import {showDD,showMM,showYYYY} from '../../../../helper/dateTime/handleDMY'
import {ShowDateName} from '../../ClassActive/helper'


export default function Index({open,handleClose}) {
  const classes = useStyles()
  const ListInfoUser                 = useSelector(state=>state.ManagerAccount.ListInfoUser)  //list
  const list                         = useSelector(state=>state.ManagerAccount.ListClassCheckDyFoodByDate)
  const DateInput                    = useSelector(state=>state.ManagerAccount.SelectDate)
  let{infoUser,templeteUser}         = ListInfoUser

  const SumListNotEat= (list.map(v=>v.numCheckDay)).reduce((x,y)=>x+y,0)
  const ShowList=()=>{
      return list.map((v,i)=>{
        return <TableRow key={i} border="1"> 
                    <TableCell align="center">{i+1}</TableCell>
                    <TableCell align="center">{changeUpperCase(v.nameClass)}</TableCell>
                    <TableCell align="center">{v.numCheckDay}</TableCell> 
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
               </TableRow>
      })
  }

  return (       
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">  
       <A5 landscape>       
            <Box display="flex" p={1} className={classes.head1}>
                <Box p={1} flexGrow={1}>                  
                    <Typography>{templeteUser.masterOrigin.toUpperCase()}</Typography>  
                    <Typography component="h6">{changeUpperCase(infoUser.workPlace)}</Typography>
                </Box>                           
            </Box>  
            <Box display="flex" justifyContent="center">       
               <Box p={1}>
                   <Typography className={classes.head3Repo} component="h3">BÁO ĂN CÁC LỚP</Typography>                 
               </Box>               
            </Box>  

             <Box display="flex" justifyContent="center">       
               <Box p={1}>
                   <Typography className={classes.date}>{ShowDateName(DateInput).dateName},&nbsp;ngày&nbsp;{showDD(DateInput)}&nbsp;tháng&nbsp;{showMM(DateInput)}&nbsp;năm&nbsp;{showYYYY(DateInput)}</Typography>                            
               </Box>               
             </Box>       

             <TableContainer component={Paper} border="1">    
             <Table className={classes.table} stickyHeader aria-label="sticky table" size="small" border="1" >
                <TableHead border="1">
                  <TableRow >
                    <TableCell align="center">Stt</TableCell>                                    
                    <TableCell align="center">Lớp</TableCell>                  
                    <TableCell align="center">Sỉ Số báo ăn</TableCell>
                    <TableCell align="center" border="1">Ký Tên</TableCell>
                    <TableCell align="center" border="1">Ghi chú</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody border="1">       
                        {ShowList()}
                        <TableRow border="1">
                          <TableCell align="center" colSpan="2" >Tổng số</TableCell>
                          <TableCell align="center">{SumListNotEat}</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

              <Grid container >
                  <Grid item xs={4}>
                  <Box p="1" display="flex" justifyContent="center" className={classes.footerOne}>
                           <Typography component="h4">Cấp Dưỡng</Typography>
                      </Box>
                      {/* <Box p="1" display="flex" justifyContent="center" className={classes.footerSecond}>
                          <Typography>{changeUpperCase(infoUser.directorName)}</Typography>
                      </Box> */}
                  </Grid>
                  <Grid item xs={4}>
                  <Box p="1" display="flex" justifyContent="center" className={classes.footerOne}>
                           <Typography component="h4">Kế toán</Typography>
                      </Box>
                  </Grid>
                 <Grid item xs={4}>
                      <Box p="1" display="flex" justifyContent="center" className={classes.footerOne}>
                           <Typography component="h4">HIỆU TRƯỞNG</Typography>
                      </Box>
                      <Box p="1" display="flex" justifyContent="center" className={classes.footerSecond}>
                          <Typography>{changeUpperCase(infoUser.directorName)}</Typography>
                      </Box>
                </Grid>
              </Grid>               
         </A5>   
      </Dialog>
   
  );
}
