import {call,put,select, takeEvery, takeLatest} from 'redux-saga/effects'
import {DISPATCH_ADD_LIST_MANAGER_ACCOUNT_TO_DB,DISPATCH_UPDATE_LIST_MANAGER_ACCOUNT,REDIRECT_CLOSE_FORM_ACTIVE_MANAGER_ACCOUNT,
        DISPATCH_LIST_CLASS_ACCOUNT_APPROVAL_BY_USER_ID_DATE,
        DISPATCH_UPDATE_APPROVAL_CHECK_DAY_FOOD,
        DISPATCH_REQUEST_MANAGER_CLASS,DISPATCH_FIND_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE, DISPATCH_ADD_CHECK_DAY_FOOD_FROM_MANAGER, DISPATCH_GET_INFO_LIST_OF_USERID,GET_LIST_CLASS_STUDENT_LEAVE_TEMPORATY, GET_LIST_STU_SEND_MESS, ADD_ITEM_STU_SEND_MESS_MANAGER_ACOUNT, GET_INFO_MESSAGE_AUTO, UPDATE_ITEM_INFO_MESSAGE_AUTO_TO_STORE, 
        UPDATE_ITEM_LIST_STU_SEND_MESS_MANAGER_ACCOUNT,UPDATE_MANAGER_ACCOUNT_CLASS, UPDATE_ITEM_LIST_STU_SEND_MESS_MANAGER_ACCOUNT_AGAIN, UPDATE_ITEM_LIST_STU_SEND_MESS_MANAGER_ACCOUNT_AGAIN_ARRAY, UPLOAD_LIST_STUDENT_SEND_SMS_ZALO, FIND_ITEM_CLASS_SEND_SMS_ZALO_PRV_MONTH,
        ADD_KHOAN_THU,
        GET_KHOAN_THU,ADD_UPDATE_STUDENT_REGIS_KHOAN_THU, GET_STUDENT_REGIS_KHOAN_THU, UPDATE_STUDENT_REGIS_KHOAN_THU,
        GET_ALL_LIST_STUDENT_KHOAN_THU
} from '../contant/ManagerAccount'
import {REDIRECT_WHEN_NOT_CREATE_ID_YEAR_SCHOOL,DISPATCH_ACTIVE_ALL_ACCOUNT,DISPATCH_GET_LIST_ACTIVE_ACCOUNT,
FIND_MENU_WEEKLY_DB_BY_USERID_DATE_NOW,DISPATCH_MANAGER_ACCOUNT_RECICVE_REQUEST,ADD_APPROVAL_CHECK_DAY_FOOD,DISPATCH_LOGOUT_USER_CLASS,
DISPATCH_UPDATE_APPROVAL_FROM_CLASS,DISPATCH_CHANGE_UPDATE_CHECK_DAY_FOOD, GET_STUDENT_LEAVE_TEMPORATY_BY_CLASS, ADD_ITEM_STU_SEND_MESS, UPDATE_ITEM_STU_SEND_MESS, GET_ITEM_STU_SEND_MESS,
} from '../contant/Class'
import {AddListManagerActiveAccount,UpdateManagerActiveAccount,ActiveAllAccount,GetActiveAllAccountById,FindMenuWeeklyByUserAndDate,AddApprovalCheckDayFood,
      ListApprovalByUserIdDate,ListApprovalUpdate,FindCheckDayFoodByUserIdAndDate,
      ChangeUpdateCheckDayFood,AddOrUpdateCheckDayFood,GetInfoListOfUser,GetStudentLeaveTemporatyByClass,GetListClassStudentLeaveTemporaty, AddStuSendMessApi, UpdateStuSendMessApi, GetListStuSendMessApi, GetItemStuSendMessApi, GetInfoMessageAutoApi, UpdateInfoMessageAutoApi, UpdateManagerAccountClassApi, FindItemClassSendSmsZaloPrvMonthApi, AddKhoanThuApi, GetKhoanThuApi, AddUpdateStudentRegisKhoanThuApi, GetStudentRegisKhoanThuApi, UpdateStudentRegisKhoanThuApi, GetAllListStudentKhoanThuApi
} from '../api-services'
import * as uiLoading from '../../actions/uiLoading'
import {DispatchAddListManagerAccountToStore,CloseFormActiveManagerAccount,DispatchActiveAllAccountAllToStore,DispatchGetListActiveAccountToStore,DispatchItemMenuWeeklyToStore,DispatchItemApprovalCheckDayFoodToStore, DispatchListClassAccountApprovalToStore,DispatchSendSocketCheckFoodToManagerAccount,UpdateApprovalSuccess,
  DispatchManagerAccountRecicveRequest,DispatchListClassCheckDayFoodByDate,
  OpenAlertCheckDayFood,OpenFormCheckDayFoodByUseIdAndDate,
  CloseAlertCheckDayFood,CloseFormCheckDayFoodByUseIdAndDate,AcceptUpdateApprovalFromClass,
  DispatchGetInfoListOfUserIdToStore,OpenAlertListClassNotCheckDayFood,DispatchListLeaveTemporatyByClassToStore,
  DispatchListClassStudentLeaveTemporatyToStore,DispatchListCheckDayFoodByMonth,  DispatchAddStuSendMessToStore, DispatchListStuSendMessToStore, DispatchAddStuSendMessManagerAcountToStore, DispatchItemInfoMessageAutoToStore, UploadListStudentSendSmsZalo, DispatchFindItemClassSendSmsZaloPrvMonth, DispatchItemKhoanThuToStore, DispatchAddKhoanThuToStore, DispatchItemStudentRegisKhoanThuToStore,
}from '../actions'
import {REDIRECT_LOGIN_USER_CUS_NN_SUCCESS} from '../../contant/auth'
import {CreateMenuWeekly,handleFindListClassCheckDayFood,FindClassNotChechDayFood,ResultAllMonthCheckDayFood} from './helper'
import {push} from 'connected-react-router'
import {addDateMeal} from '../../api-services/weeklyFood/dateMeal'
import _ from 'lodash'
import { showMMYY, showYYMMDD } from '../../helper/dateTime/handleDMY'
import { ThoiGianTruocMotThang } from '../../utils/DayTime'

const SUCCESS = 'success'

function* DispatchAddListManagerAccountToDbSaga({payload}){
  yield put(uiLoading.loadingSuccess())    
    let res = yield call(AddListManagerActiveAccount,payload.item)
    if(res.data.result===SUCCESS){
        if(res.data.data[0]!==null){
          yield put(uiLoading.loadingFail())
          yield put(DispatchAddListManagerAccountToStore(res.data.data))
        }        
          yield put(uiLoading.loadingFail())        
    }else{
      alert('Lỗi, liên hệ với quản trị')
    }
}

function* DispatchUpdateListManagerAccountSaga({payload}){
  yield put(uiLoading.loadingSuccess())    
  let res = yield call(UpdateManagerActiveAccount,payload.item)
  if(res.data.result===SUCCESS){
    yield put(uiLoading.loadingFail())
  }else{
    alert('Lỗi, liên hệ với quản trị')
  }
}

function* DispatchActiveAllAccountSaga({payload}){
  yield put(uiLoading.loadingSuccess())    
  let user = yield select(state=>state.users.loginUser)
  let newArr = payload.arr.map(v=>{
    let Item ={
      userAccount:v.name+'_'+user.name.toLowerCase().trim(),
      role:v.role,
      email:v.email,
      nameClass:v.nameClass,
      idClass:v.idClass,
      password:v.password,
      active:true,
      userId:payload.arr[0].userId,
    }
    return Item
  })
  let resultDB ={
    userId:payload.arr[0].userId,
    workPlace:payload.arr[0].workPlace.toUpperCase(),
    listAccount:newArr
  }
    let res = yield call(ActiveAllAccount,resultDB)
    if(res.data.result===SUCCESS){
      yield put(uiLoading.loadingFail())
      yield put(DispatchActiveAllAccountAllToStore(res.data.data))
    }else{
      yield put(uiLoading.loadingFail())
      alert('Lỗi, kiểm tra lại Mã:EMAC099')
    }
}

function* RedirectCloseFormActiveManagerAccountSaga(){
    yield put(CloseFormActiveManagerAccount())
    yield put(push(REDIRECT_LOGIN_USER_CUS_NN_SUCCESS))
}

function* RedirectWhenNotCreateIdYearSchoolSaga(){
  yield put(push(REDIRECT_LOGIN_USER_CUS_NN_SUCCESS))
}

function* DispatchGetListActiveAccountSaga(){
  yield put(uiLoading.loadingSuccess())   
  let userId = yield select(state=>state.GovLogin.userId)
  let id = {id:userId}
  let res = yield call(GetActiveAllAccountById,id)
  if(res.data.result===SUCCESS){
    yield put(uiLoading.loadingFail())
    //console.log(res.data.data)
    yield put(DispatchGetListActiveAccountToStore(res.data.data))
  }else{
    yield put(uiLoading.loadingFail())
    alert('Lỗi, kiểm tra lại Mã:GEMAC099')
  }
}

function* FindMenuWeeklyDbByUserIdDateNowSaga({payload}){
    yield put(uiLoading.loadingSuccess())
     let res= yield call(FindMenuWeeklyByUserAndDate,payload.item)
     if(res.data.result===SUCCESS&&res.data.data.length===0){
      let itemAccount = yield select(state=>state.ManagerAccount.ItemManagerActiveAccount)
      let DateInput   = yield select(state=>state.ManagerAccount.DateInput)

      //api tao 1 ban ghi createMenu
      let res2 =yield call(addDateMeal,CreateMenuWeekly(_.first(itemAccount),DateInput))
      yield put(DispatchItemMenuWeeklyToStore(res2.data.data))
      yield put(uiLoading.loadingFail())
   }
  if(res.data.result===SUCCESS&&res.data.data.length!==0){
    yield put(DispatchItemMenuWeeklyToStore(res.data.data))
    yield put(uiLoading.loadingFail())
  }
}

function* AddApprovalCheckDayFoodSaga({payload}){
    yield put(uiLoading.loadingSuccess())   
    let res=yield call(AddApprovalCheckDayFood,payload.item)
    if(res.data.result===SUCCESS){
      yield put(DispatchItemApprovalCheckDayFoodToStore(res.data.data))
      yield put(uiLoading.loadingFail())
    }else{
      alert('Loi')
    }
}

function* DispatchListClassAccountApprovalByUserIdDateSaga({payload}){
  yield put(uiLoading.loadingSuccess())   
  let res = yield call(ListApprovalByUserIdDate,payload.item)
  if(res.data.result===SUCCESS){
    yield put(DispatchListClassAccountApprovalToStore(res.data.data))
    yield put(uiLoading.loadingFail())
  }else{
    alert('Loi')
  }
}

function* DispatchManagerAccountRecicveRequestSaga({payload}){
   yield put(DispatchSendSocketCheckFoodToManagerAccount(payload.item))
}

function* DispatchUpdateApprovalCheckDayFoodSaga({payload}){
       yield call(ListApprovalUpdate,payload.item)       
}

function* DispatchLogoutUserClassSaga(){
    yield put(window.location.reload())
}

function* DispatchRequestManagerClassSaga() {
    let itemRequest = yield select(state=>state.ManagerAccount.ItemCheckAccount)
    if(itemRequest!==null){
       yield put(DispatchManagerAccountRecicveRequest(itemRequest))
    }
}

function* DispatchFindCheckDayFoodByUserIdAndDateSaga({payload}){
    let res = yield call(FindCheckDayFoodByUserIdAndDate,payload.item)
    if(res.data.result===SUCCESS){
      let {findByDate,findByMonth} = res.data.data      
      let listClass = yield select(state=>state.Student.ListClass)
      let ListOriginAllStudentNow = yield select(state=>state.Student.ListOriginAllStudentNow)
      let ListRegisSevenDay       = yield select(state=>state.Student.ListRegisSevenDay)
      let filterListRegis         = ListRegisSevenDay.filter(v=>showMMYY(v.dateMonth)===showMMYY(payload.item.dateNow)) 
      let findResult = handleFindListClassCheckDayFood(listClass,ListOriginAllStudentNow,filterListRegis,findByDate,payload.item.dateNow)       
      let findClass = FindClassNotChechDayFood(listClass,findResult)      
      let checkFilter = findResult.some(v=>v===undefined)    
      let ResultCheckDayFood=ResultAllMonthCheckDayFood(findByMonth,listClass,ListOriginAllStudentNow,ListRegisSevenDay)
      yield put(DispatchListCheckDayFoodByMonth(ResultCheckDayFood))  
      if((findResult.length===0)){
          yield put(OpenAlertCheckDayFood())
          yield put(CloseFormCheckDayFoodByUseIdAndDate())
      }
      if(findClass.length!==0&&findResult.length!==0){
        yield put(OpenAlertListClassNotCheckDayFood(findClass))
        yield put(CloseFormCheckDayFoodByUseIdAndDate())
      }
      if(findClass.length===0&&findResult.length!==0&&checkFilter===false){     
        yield put(DispatchListClassCheckDayFoodByDate(findResult))
        yield put(OpenFormCheckDayFoodByUseIdAndDate())
        yield put(CloseAlertCheckDayFood())
      }
    }else{
      alert('Loi GET CHECK DAY FOOD BY DATE _ LIST APPROVAL')
    }
}

function* DispatchUpdateApprovalFromClassSaga({payload}){
    yield put(AcceptUpdateApprovalFromClass(payload.item))
}

function* DispatchAddCheckDayFoodFromManagerSaga({payload}){
    yield put(uiLoading.loadingSuccess()) 
    //kiem tra ton tai cua item trong db check day food, neu co thi cap nhat, khong co thi them
     let res = yield call(AddOrUpdateCheckDayFood,payload.item)
    if(res.data.result===SUCCESS){
      yield put(uiLoading.loadingFail())
      yield put(UpdateApprovalSuccess())
    }else{
      alert('Lỗi, mã Lỗi "DispatchAddCheckDayFoodFromManagerSaga"')
    }
}

function* DispatchChangeUpdateCheckDayFoodSaga({payload}){
  yield put(uiLoading.loadingSuccess())   
  let res = yield call(ChangeUpdateCheckDayFood,payload.item[0])
  if(res.data.result===SUCCESS){
    yield put(uiLoading.loadingFail())
  }else{
    alert('Lỗi, mã Lỗi "DispatchChangeUpdateCheckDayFoodSaga"')
  }
}

function* DispatchGetInfoListOfUserIdSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(GetInfoListOfUser,payload.item)
    if(res.data.result===SUCCESS){
      yield put(uiLoading.loadingFail())
      yield put(DispatchGetInfoListOfUserIdToStore(res.data.data))
    }else{
      alert('Lỗi, mã Lỗi "DispatchGetInfoListOfUserIdSaga"')
    }
}

function* GetStudentLeaveTemporatyByClassSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res= yield call(GetStudentLeaveTemporatyByClass,payload.item)
    if(res.data.result===SUCCESS){
      yield put(uiLoading.loadingFail())
      yield put(DispatchListLeaveTemporatyByClassToStore(res.data.data))
    }else{
      alert('Lỗi, mã Lỗi "GetStudentLeaveTemporatyByClassSaga"')
    }
}

function* GetListClassStudentLeaveTemporatySaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(GetListClassStudentLeaveTemporaty,payload.item)
  if(res.data.result===SUCCESS){
    yield put(uiLoading.loadingFail())
    yield put(DispatchListClassStudentLeaveTemporatyToStore(res.data.data))
  }else{
    alert('Lỗi, "GetListClassStudentLeaveTemporatySaga"')
  }
}

function* AddItemStuSendMessSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(AddStuSendMessApi,payload.item)
    if(res.data.result===SUCCESS){
      yield put(uiLoading.loadingFail())
          yield put(DispatchAddStuSendMessToStore(res.data.data))
    }
}

function* GetItemStuSendMessSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(GetItemStuSendMessApi,payload.item)
  if(res.data.result===SUCCESS){
    yield put(uiLoading.loadingFail())
        yield put(DispatchAddStuSendMessToStore(res.data.data))
  }
}

function* AddItemStuSendMessManagerAcountSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(AddStuSendMessApi,payload.item)
  if(res.data.result===SUCCESS){
    yield put(uiLoading.loadingFail())
        yield put(DispatchAddStuSendMessManagerAcountToStore(res.data.data))
  }
}

function* UpdateItemStuSendMessSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(UpdateStuSendMessApi,payload.item)
  if(res.data.result===SUCCESS){
    yield put(uiLoading.loadingFail())    
  }
}

function* GetListStuSendMessSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(GetListStuSendMessApi,payload.item)  
  if(res.data.result===SUCCESS){
    yield put(DispatchListStuSendMessToStore(res.data.data))
    //console.log(res.data.data)
    yield put(uiLoading.loadingFail())
  }
}

function* GetInfoMessageAutoSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(GetInfoMessageAutoApi,payload.item)
  if(res.data.result===SUCCESS){
    yield put(DispatchItemInfoMessageAutoToStore(res.data.data))
    yield put(uiLoading.loadingFail())
  }
}

function* UpdateItemInfoMessageAutoToStoreSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(UpdateInfoMessageAutoApi,payload.item)
  if(res.data.result===SUCCESS){    
    yield put(uiLoading.loadingFail())
  }
}

function* UpdateItemListStuSendMessManagerAccountSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(UpdateStuSendMessApi,payload.item)
    if(res.data.result===SUCCESS){    
      yield put(uiLoading.loadingFail())
    }
}

function* UpdateItemListStuSendMessManagerAccountAgainArraySaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(UpdateStuSendMessApi,payload.item)
  if(res.data.result===SUCCESS){    
    yield put(uiLoading.loadingFail())
  }
}

function* UpdateManagerAccountClassSaga({payload}){
     yield put(uiLoading.loadingSuccess())
     let res = yield call(UpdateManagerAccountClassApi,payload.item[0])
     if(res.data.result===SUCCESS){    
      yield put(uiLoading.loadingFail())
    }
}

function* UploadListStudentSendSmsZaloSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(UpdateStuSendMessApi,payload.item)
  if(res.data.result===SUCCESS){
    yield put(uiLoading.loadingFail())    
  }
}

function* FindItemClassSendSmsZaloPrvMonthSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let newItem = {idClass:payload.item.idClass,dateNow:ThoiGianTruocMotThang(showYYMMDD(payload.item.dateNow))}
  let res = yield call(FindItemClassSendSmsZaloPrvMonthApi,newItem)
  if(res.data.result===SUCCESS){
    yield put(DispatchFindItemClassSendSmsZaloPrvMonth(res.data.data))
    yield put(uiLoading.loadingFail())    
  }
}

//khoanthu
function* AddKhoanThuSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(AddKhoanThuApi,payload.item)
    if(res.data.result===SUCCESS){
      yield put(DispatchAddKhoanThuToStore(res.data.data))
      yield put(uiLoading.loadingFail())    
    }
}

function* GetKhoanThuSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  //console.log(payload)
  let res = yield call (GetKhoanThuApi,payload.item)
  //console.log(res)
  if(res.data.result===SUCCESS){
    yield put(DispatchItemKhoanThuToStore(res.data.data))
    yield put(uiLoading.loadingFail())    
  }
}

function* AddUpdateStudentRegisKhoanThuSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(AddUpdateStudentRegisKhoanThuApi,payload.item)
  if(res.data.result===SUCCESS){
    yield put(DispatchItemStudentRegisKhoanThuToStore(res.data.data))
    yield put(uiLoading.loadingFail())    
  }
}

function* GetStudentRegisKhoanThuSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(GetStudentRegisKhoanThuApi,payload.item)
  //console.log(res)
  if(res.data.result===SUCCESS){
    yield put(DispatchItemStudentRegisKhoanThuToStore(res.data.data))
    yield put(uiLoading.loadingFail())    
  }
}

function* UpdateStudentRegisKhoanThuSaga({payload}){
   yield put(uiLoading.loadingSuccess())
  let res =  yield call(UpdateStudentRegisKhoanThuApi,payload.item)
    if(res.data.result===SUCCESS){
      yield put(DispatchItemStudentRegisKhoanThuToStore(res.data.data))
      yield put(uiLoading.loadingFail())    
    }
}

function* GetAllListStudentKhoanThuSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(GetAllListStudentKhoanThuApi,payload.item)
  if(res.data.result===SUCCESS){
    let {itemRegis,khoanThu,listMess} = res.data.data   
    if(itemRegis.length!==0){
      yield put(DispatchItemStudentRegisKhoanThuToStore(itemRegis[0]))
    }
    if(itemRegis.length===0){
      yield put(DispatchItemStudentRegisKhoanThuToStore([]))
    }
    if(khoanThu.length!==0){
      yield put(DispatchItemKhoanThuToStore(khoanThu[0]))
    }
    if(khoanThu.length===0){
      yield put(DispatchItemKhoanThuToStore([]))
    }
      yield put(DispatchItemInfoMessageAutoToStore(listMess))
      yield put(uiLoading.loadingFail())    
  }

}

function* IndexSaga(){
  yield takeLatest(GET_ALL_LIST_STUDENT_KHOAN_THU,GetAllListStudentKhoanThuSaga)
  yield takeLatest(UPDATE_STUDENT_REGIS_KHOAN_THU,UpdateStudentRegisKhoanThuSaga)
  yield takeLatest(GET_STUDENT_REGIS_KHOAN_THU,GetStudentRegisKhoanThuSaga)
  yield takeLatest(ADD_UPDATE_STUDENT_REGIS_KHOAN_THU,AddUpdateStudentRegisKhoanThuSaga)
  yield takeLatest(GET_KHOAN_THU,GetKhoanThuSaga)
  yield takeLatest(ADD_KHOAN_THU,AddKhoanThuSaga)
  yield takeLatest(DISPATCH_ADD_LIST_MANAGER_ACCOUNT_TO_DB,DispatchAddListManagerAccountToDbSaga)
  yield takeLatest(DISPATCH_UPDATE_LIST_MANAGER_ACCOUNT,DispatchUpdateListManagerAccountSaga) 
  yield takeLatest(REDIRECT_CLOSE_FORM_ACTIVE_MANAGER_ACCOUNT,RedirectCloseFormActiveManagerAccountSaga)
  yield takeLatest(REDIRECT_WHEN_NOT_CREATE_ID_YEAR_SCHOOL,RedirectWhenNotCreateIdYearSchoolSaga)   
  yield takeLatest(DISPATCH_ACTIVE_ALL_ACCOUNT,DispatchActiveAllAccountSaga)
  yield takeLatest(DISPATCH_GET_LIST_ACTIVE_ACCOUNT,DispatchGetListActiveAccountSaga)
  yield takeLatest(FIND_MENU_WEEKLY_DB_BY_USERID_DATE_NOW,FindMenuWeeklyDbByUserIdDateNowSaga)
  yield takeLatest(DISPATCH_UPDATE_APPROVAL_FROM_CLASS,DispatchUpdateApprovalFromClassSaga)
  yield takeLatest(DISPATCH_MANAGER_ACCOUNT_RECICVE_REQUEST,DispatchManagerAccountRecicveRequestSaga)
  yield takeLatest(DISPATCH_LIST_CLASS_ACCOUNT_APPROVAL_BY_USER_ID_DATE,DispatchListClassAccountApprovalByUserIdDateSaga)
  yield takeLatest(ADD_APPROVAL_CHECK_DAY_FOOD,AddApprovalCheckDayFoodSaga)
  yield takeLatest(DISPATCH_UPDATE_APPROVAL_CHECK_DAY_FOOD,DispatchUpdateApprovalCheckDayFoodSaga)
  yield takeLatest(DISPATCH_LOGOUT_USER_CLASS,DispatchLogoutUserClassSaga)
  yield takeLatest(DISPATCH_REQUEST_MANAGER_CLASS,DispatchRequestManagerClassSaga)
  yield takeLatest(DISPATCH_FIND_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE,DispatchFindCheckDayFoodByUserIdAndDateSaga)
  yield takeLatest(DISPATCH_ADD_CHECK_DAY_FOOD_FROM_MANAGER,DispatchAddCheckDayFoodFromManagerSaga)
  yield takeLatest(DISPATCH_CHANGE_UPDATE_CHECK_DAY_FOOD,DispatchChangeUpdateCheckDayFoodSaga)
  yield takeLatest(DISPATCH_GET_INFO_LIST_OF_USERID,DispatchGetInfoListOfUserIdSaga)
  yield takeLatest(GET_STUDENT_LEAVE_TEMPORATY_BY_CLASS,GetStudentLeaveTemporatyByClassSaga)
  yield takeLatest(GET_LIST_CLASS_STUDENT_LEAVE_TEMPORATY,GetListClassStudentLeaveTemporatySaga)
  yield takeLatest(ADD_ITEM_STU_SEND_MESS,AddItemStuSendMessSaga)
  yield takeLatest(UPDATE_ITEM_STU_SEND_MESS,UpdateItemStuSendMessSaga)
  yield takeLatest(GET_LIST_STU_SEND_MESS,GetListStuSendMessSaga)
  yield takeLatest(ADD_ITEM_STU_SEND_MESS_MANAGER_ACOUNT,AddItemStuSendMessManagerAcountSaga)
  yield takeLatest(GET_ITEM_STU_SEND_MESS,GetItemStuSendMessSaga)
  yield takeLatest(GET_INFO_MESSAGE_AUTO,GetInfoMessageAutoSaga)
  yield takeLatest(UPDATE_ITEM_INFO_MESSAGE_AUTO_TO_STORE,UpdateItemInfoMessageAutoToStoreSaga)
  yield takeLatest(UPDATE_ITEM_LIST_STU_SEND_MESS_MANAGER_ACCOUNT,UpdateItemListStuSendMessManagerAccountSaga)
  yield takeLatest(UPDATE_MANAGER_ACCOUNT_CLASS,UpdateManagerAccountClassSaga)
  yield takeLatest(UPDATE_ITEM_LIST_STU_SEND_MESS_MANAGER_ACCOUNT_AGAIN_ARRAY,UpdateItemListStuSendMessManagerAccountAgainArraySaga)
  yield takeLatest(UPLOAD_LIST_STUDENT_SEND_SMS_ZALO,UploadListStudentSendSmsZaloSaga)
  yield takeLatest(FIND_ITEM_CLASS_SEND_SMS_ZALO_PRV_MONTH,FindItemClassSendSmsZaloPrvMonthSaga)
}

export default IndexSaga