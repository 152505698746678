import {ADD_COST_STUDENT_SUCCESS,ADD_COST_STUDENT,ADD_COST_STUDENT_FAIL,EDIT_COST_STUDENT,
    LIST_COST_STUDENT,
    GET_LIST,
    DELETE_COST_STUDENT,NAME_COST_STUDENT
} from '../../../contant/Gov/cost'
import {toastSuccess} from '../../../helper/toastHelper'
let initialState = {
    listCostStudent:[],
    listCost:[],
    nameCost:'',
    ListCostEatDailyMeal:[]
}

const CostStudentReducer = (state=initialState,action)=>{   
    switch (action.type) {
        case ADD_COST_STUDENT:           
            return{
                ...state                
            }
        case ADD_COST_STUDENT_SUCCESS:    
        toastSuccess('Thêm mới thành công')         
            return{
                ...state,
                listCostStudent:(state.listCostStudent).concat(action.payload.item)                
            }    
            case ADD_COST_STUDENT_FAIL:    
            toastSuccess('Lỗi, kiểm tả lại')        
                return{
                    ...state                
                }  

            case  GET_LIST:
                return{
                    ...state,                    
                } 
                
            case LIST_COST_STUDENT:                
                return{
                    ...state,
                    listCostStudent:action.payload.arr,
                    PriceMeal: (action.payload.arr).filter(v=>v.typeOf===1),    //tien an     
                    MonthTuition: (action.payload.arr).filter(v=>v.typeOf===2),       //hoc phi hang ngay  
                    SummerService:    (action.payload.arr).filter(v=>v.typeOf===3),  //phuc vu he         
                    BreakfastPrice:  (action.payload.arr).filter(v=>v.typeOf===4),  //tien an sang     
                    InFras:(action.payload.arr).filter(v=>v.typeOf===5),       //co so vat chat   
                    Clean: (action.payload.arr).filter(v=>v.typeOf===6),       //ve sinh chat dot    
                    NumEat: (action.payload.arr).filter(v=>v.typeOf===7),    // so phieu an trong thang          
                    BoardingService: (action.payload.arr).filter(v=>v.typeOf===8),    // phuc vu ban tru  
             }       
                
            case EDIT_COST_STUDENT:
                toastSuccess('Cập nhật thành công')
                let {item}=action.payload
                let {listCostStudent}=state
                let index = listCostStudent.findIndex(value=>value._id===item.id)
                if(index!==-1){
                    let newList = [
                        ...listCostStudent.slice(0,index),
                        item,
                        ...listCostStudent.slice(index+1)
                    ]
                     return{
                    ...state,
                    listCostStudent:newList
                 }
                }
                return {...state}

            case DELETE_COST_STUDENT:
                toastSuccess('Xóa thành công')
                let Item =action.payload.item
                return{
                    ...state,
                    listCostStudent:state.listCostStudent.filter(value=>value._id!==Item._id)
                }
            case NAME_COST_STUDENT:                
                return {
                    ...state,
                    nameCost:action.payload.Cost
                }

               
        default:return state           
    }
}

export default CostStudentReducer