const style = theme => ({
  buttonAlign: {
    display: "flex",
    alignItem: "center",
    justifyContent: "flex-end"
  },
  bgcolor:{
        background:theme.colorPink.primary,
        justifyContent:"space-between",
        display:"flex",
        alignItem:"center",
        padding:2
  },
  textCv:{   
    width:500,
    fontSize:19,
    padding:5,
    textTransform:"capitalize"
  },
  icon:{
    cursor:"pointer",
    fontSize:25
  },
  select:{
    display:"flex",
    flexWarp:"wrap",
    width:200
  },
  modal:{
    top:'50%',
    left:'50%',
    transform:'translate(-50%,-50%)',
    position:'absolute',
    with:500,
    border:'2px solid #000',
    boxShadow:theme.shadows[5],
    padding:theme.spacing(2,4,4),
    oulitne:'none'
  },
  
});

export default style;
