import axiosServices from '../../../api-services/api'
import {API_ENDPOINT}   from '../../../contant/index'

const urlUpdateQrcode = 'person/UpdateQrcode'
const urlGetQrcode = 'person/GetQrcode'
const urlUpdateItemTimeChamCong = 'person/UpdateItemTimeChamCong'

export const UpdateQrcodeApi =(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlUpdateQrcode}`,item)
}

export const GetQrcodeApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetQrcode}`,item)
}

export const UpdateItemTimeChamCongApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlUpdateItemTimeChamCong}`,item)
}