export const ADD_SUPPLIES = "ADD_SUPPLIES"
export const EDIT_SUPPLIES = "EDIT_SUPPLIES"

export const ADD_SUPPLIES_SUCCESS_AND_DISPATCH_TO_STORE = "ADD_SUPPLIES_SUCCESS_AND_DISPATCH_TO_STORE"
export const DISH_PATCH_GET_LIST_SUPPLIES_BY_USER_ID_TO_STORE = "DISH_PATCH_GET_LIST_SUPPLIES_BY_USER_ID_TO_STORE"


export const ADD_SUPPLIES_SUCCESS_NOTIFICATION = "ADD_SUPPLIES_SUCCESS_NOTIFICATION"
export const CLOSE_SUPPLIES_SUCCESS_NOTIFICATION="CLOSE_SUPPLIES_SUCCESS_NOTIFICATION"

export const OPEN_EDIT_SUPPLIES_NOTIFICATION ="OPEN_EDIT_SUPPLIES_NOTIFICATION"
export const CLOSE_EDIT_SUPPLIES_NOTIFICATION ="OPEN_EDIT_SUPPLIES_NOTIFICATION"


export const OPEN_EDIT_SUPPLIES = "OPEN_EDIT_SUPPLIES"
export const CLOSE_EDIT_SUPPLIES ="CLOSE_EDIT_SUPPLIES" 
export const OPEN_ADD_SUPPLIES = "OPEN_ADD_SUPPLIES"
export const CLOSE_ADD_SUPPLIES = "CLOSE_ADD_SUPPLIES"
export const GET_LIST_SUPPLIES  = "GET_LIST_SUPPLIES"

export const ADD_CUSTOMER                    = "ADD_CUSTOMER"
export const DISPATCH_ADD_CUSTOMER_TO_STORE  = "DISPATCH_ADD_CUSTOMER_TO_STORE"
export const GET_CUSTOMER                    = "GET_CUSTOMER"
export const DISPATCH_GET_CUSTOMER           = "DISPATCH_GET_CUSTOMER"
export const DISPATCH_LIST_CUSTOMER_TO_STORE = "DISPATCH_LIST_CUSTOMER_TO_STORE"

export const OPEN_EDIT_CUSTOMER  = "OPEN_EDIT_CUSTOMER"
export const CLOSE_EDIT_CUSTOMER = "CLOSE_EDIT_CUSTOMER"
export const EDIT_CUSTOMER       = "EDIT_CUSTOMER"

export const OPEN_ADD_BRANCH         = "OPEN_ADD_BRANCH"
export const CLOSE_ADD_BRANCH        = "CLOSE_ADD_BRANCH"
export const UPDATE_CUSTOMER_BRANCH  = "UPDATE_CUSTOMER_BRANCH"
export const OPEN_UPDATE_BRANCH      = "OPEN_UPDATE_BRANCH"
export const CLOSE_UPDATE_BRANCH     = "CLOSE_UPDATE_BRANCH"
export const UPDATE_ITEM_BRANCH      = "UPDATE_ITEM_BRANCH"

export const OPEN_ADD_DEPARTMENT     = "OPEN_ADD_DEPARTMENT"
export const CLOSE_ADD_DEPARTMENT    = "CLOSE_ADD_DEPARTMENT"
export const UPDATE_ITEM_DEPARMENT  = "UPDATE_ITEM_DEPARMENT"

export const OPEN_ADD_PERSON_IN_DEPARMENT  = "OPEN_ADD_PERSON_IN_DEPARMENT"
export const CLOSE_ADD_PERSON_IN_DEPARMENT = "CLOSE_ADD_PERSON_IN_DEPARMENT"

export const UPDATE_PERSON_IN_DEPARMENT  = "UPDATE_PERSON_IN_DEPARMENT"

export const UPDATE_ITEM_DEPARMENT_ACTION =  "UPDATE_ITEM_DEPARMENT_ACTION"

export const EDIT_CUSTOMER_ACTION = "EDIT_CUSTOMER_ACTION"
