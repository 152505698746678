import {OPEN_ADD_TYPE_OF_SERVICE,CLOSE_ADD_TYPE_OF_SERVICE,DISPATCH_VALUE_ADD_TO_STORE,
        DISPATCH_ADD_SUCCESS_TO_STORE_TYPE_OF_SERVICE,DISPATCH_LIST_TYPE_OF_SERVICE_TO_STORE,
        OPEN_VIEW_TYPE_OF_SERVICE,CLOSE_VIEW_TYPE_OF_SERVICE        
} from '../Contant/Admin/AddViewAppService'
import {DISPATCH_ITEM_ADD_APP_ACTIVE_STORE,DISPATCH_LIST_APP_ACTIVE_TO_STORE,DISPATCH_UPDATE_ITEM_APP_ACTIVE,DISPATCH_APP_ACTIVE_BY_USER_WHEN_LOGIN,
OPEN_BLOCK_APP_ACTIVE_MANAGER_REPO,CLOSE_BLOCK_APP_ACTIVE_MANAGER_REPO,OPEN_SELECT_APP_ACTIVE,CLOSE_SELECT_APP_ACTIVE,
OPEN_BLOCK_APP_ACTIVE_MANAGER_STAFF,CLOSE_BLOCK_APP_ACTIVE_MANAGER_STAFF,OPEN_BLOCK_APP_ACTIVE_MANAGER_SELL,CLOSE_BLOCK_APP_ACTIVE_MANAGER_SELL
} from '../Contant/Admin/AppTypeOfService'
import {updateArrById} from './fn'

export const initialState={
    OpenAddTypeOfService:false,
    ValueAddTypeOfService:{},
    OpenViewTypeOfService:false,
    ListTypeOfService:[],    
    ListAppActive:[],
    ItemAppActive:[],//gui 1 item cua person trong db appactive len
    OpenBlockAppActiveManagerRepo:false,
    OpenBlockAppActiveManagerStaff:false,
    OpenBlockAppActiveManagerSell:false,
    OpenAppActive:false
}

const AdminAddService =(state=initialState,action)=>{
    switch (action.type) {
      //type of service
      case OPEN_ADD_TYPE_OF_SERVICE:
          return{
            ...state,
            OpenAddTypeOfService:true
          }
      case CLOSE_ADD_TYPE_OF_SERVICE:
        return {
          ...state,
          OpenAddTypeOfService:false
        }
      case DISPATCH_VALUE_ADD_TO_STORE:
        return{
          ...state,
          ValueAddTypeOfService:action.payload.item
        }
      case DISPATCH_ADD_SUCCESS_TO_STORE_TYPE_OF_SERVICE:
        return{
          ...state,
          ListTypeOfService:state.ListTypeOfService.concat(action.payload.item)
        }
      case DISPATCH_LIST_TYPE_OF_SERVICE_TO_STORE:
        return{
          ...state,
          ListTypeOfService:action.payload.item
        }
      case OPEN_VIEW_TYPE_OF_SERVICE:
        return{
          ...state,
          OpenViewTypeOfService:true
        }
      case CLOSE_VIEW_TYPE_OF_SERVICE:
          return{
            ...state,
            OpenViewTypeOfService:false
          }
     
      //app active
      case DISPATCH_ITEM_ADD_APP_ACTIVE_STORE:
        return{
          ...state,
          ListAppActive:state.ListAppActive.concat(action.payload.item)
        }
      case DISPATCH_LIST_APP_ACTIVE_TO_STORE:
        return{
          ...state,
          ListAppActive:action.payload.item
        }
      case DISPATCH_UPDATE_ITEM_APP_ACTIVE:
        return{
          ...state,
          ListAppActive:updateArrById(state.ListAppActive,action.payload.item)
        }
      case DISPATCH_APP_ACTIVE_BY_USER_WHEN_LOGIN:
        return{
          ...state,
          ItemAppActive:action.payload.item
        }
      case OPEN_BLOCK_APP_ACTIVE_MANAGER_REPO:
        return{
          ...state,
          OpenBlockAppActiveManagerRepo:true
        }
      case CLOSE_BLOCK_APP_ACTIVE_MANAGER_REPO:
        return{
          ...state,
          OpenBlockAppActiveManagerRepo:false
        }
        case OPEN_SELECT_APP_ACTIVE:
          return{
            ...state,
            OpenAppActive:true
          }
        case CLOSE_SELECT_APP_ACTIVE:
          return{
            ...state,
            OpenAppActive:false
          }
        case OPEN_BLOCK_APP_ACTIVE_MANAGER_STAFF:
          return{
            ...state,
            OpenBlockAppActiveManagerStaff:true
          }
        case CLOSE_BLOCK_APP_ACTIVE_MANAGER_STAFF:
          return{
            ...state,
            OpenBlockAppActiveManagerStaff:false
          }
        case OPEN_BLOCK_APP_ACTIVE_MANAGER_SELL:
          return{
            ...state,
            OpenBlockAppActiveManagerSell:true
          }
        case CLOSE_BLOCK_APP_ACTIVE_MANAGER_SELL:
          return{
            ...state,
            OpenBlockAppActiveManagerSell:false
          }

      default: return state        
    }
  
}

export default AdminAddService