import axiosServices from '../../../../api-services/api'
import { API_ENDPOINT } from '../../../../contant'

const urlCreateCanDoSucKhoe = 'CreateCanDoSucKhoe'
const urlUpdateCanDoSucKhoe = 'UpdateCanDoSucKhoe'

const urlAddUploadExcelHocSinhCanDo = 'AddUploadExcelHocSinhCanDo'
const urlGetListHocSinhCanDo = 'GetListHocSinhCanDo'
const urlUpdateHocSinhCanDo  = 'UpdateHocSinhCanDo'
const urlUpdateHocSinhCanDoAgain  = 'UpdateHocSinhCanDoAgain'
const urlDeleteHocSinhCanDo  = 'DeleteHocSinhCanDo'

export const DeleteHocSinhCanDoApi= (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteHocSinhCanDo}`,item)
}

export const UpdateHocSinhCanDoAgainApi= (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateHocSinhCanDoAgain}`,item)
}

export const UpdateHocSinhCanDoApi= (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateHocSinhCanDo}`,item)
}

export const GetListHocSinhCanDoApi= (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetListHocSinhCanDo}`,item)
}

export const CreateCanDoSucKhoeApi = ()=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlCreateCanDoSucKhoe}`)
}

export const UpdateCanDoSucKhoeApi =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateCanDoSucKhoe}`,item)
}

export const AddUploadExcelHocSinhCanDoApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddUploadExcelHocSinhCanDo}`,item)
}

