import {useSelector}from 'react-redux'
import {HandleAllArrListStudentNow,ArrNumDate} from '../../../../helper/countEat'
import {showMMYY,showYYYY, showMM,handleMM,showDateName,showDateShortName,numberDate} from '../../../../helper/dateTime/handleDMY'    
import {CheckDayFoodCoutEatForClassAccout} from './fn'
import {changeUpperCase}from '../../../../helper/fnAllways'
import {ArrStudentSortByName,sortByNameLast} from '../../../../helper/tableList/tableList'
import {handleNumberDot}from '../../../../helper/cost/costStudent'
import {useStyles}from './styles'
import {Table,TableCell,TableBody,TableContainer,TableHead,TableRow,Paper,Box,Typography}from '@material-ui/core'
import React,{Fragment} from 'react'
import styled from 'styled-components'


const Index = ({listStu}) => {  
  const   listSeven                  = listStu.ListStudentByMonthRegisSevenDay
  const   classes                    = useStyles()
  const ListInfoUser                 = useSelector(state=>state.ManagerAccount.ListInfoUser)  //list
  const DateInput                    = useSelector(state=>state.ManagerAccount.DateInput)  //list
  //const ItemManagerActiveAccount     = useSelector(state=>state.ManagerAccount.ItemManagerActiveAccount)  //list
  const ItemClassLeaveTemporaty      = useSelector(state=>state.ManagerAccount.ItemClassLeaveTemporaty) 
  let {itemStudentNow,itemCheckDayFood}   = ListInfoUser
  let FilterListStudent              = HandleAllArrListStudentNow([itemStudentNow])
  const FindResult                   = CheckDayFoodCoutEatForClassAccout(DateInput,FilterListStudent,itemCheckDayFood,listSeven,ItemClassLeaveTemporaty)


  const StyledTableRow = styled(TableRow)`
.MuiTableCell-sizeSmall{
  padding: 0px 0px 0px 0px;
}
  `
  const TitleHeader = ()=>{
    return(
      <div className={classes.header}>
            {/* <Box marginLeft={1}>
            <Typography>{templeteUser.masterOrigin.toUpperCase()}</Typography>                                    
            </Box>
            <Box marginLeft={1}>
              <Typography>{changeUpperCase(infoUser.workPlace)}</Typography>  
            </Box>                           */}
            <Box display="flex" justifyContent="center" >                    
            <Box p={1} >              
                   <Typography> Bảng Chấm Ăn... {showMMYY(DateInput)} {changeUpperCase(listStu.nameClass)}</Typography>
                   <Typography component="span" className={classes.fontChuthich}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chú thích: (x : đi học) ; (1:nghỉ học) ; (-: Nghỉ học tạm thời)</Typography>               
                </Box>                               
            </Box>          
        </div>
    )
  }
  // const TitleFooter =()=>{
  //   return(
  //     <>
  //       <Box display="flex" justifyContent="flex-end">                    
  //           <Box marginTop={2} >
  //               <Typography> Nha trang, ngày ... tháng {showMM(DateInput)} năm {showYYYY(DateInput)}</Typography>
  //           </Box>                               
  //         </Box>
  //         <Box display="flex" justifyContent="center" >
  //               <Box marginLeft={0}>
  //                 <Typography >Kế toán</Typography>
  //             </Box>             
  //             <Box marginLeft={40}>
  //               <Typography>Hiệu Trưởng</Typography>
  //             </Box>
  //       </Box>
  //     </>
  //   )
  // }
  const ShowHtmlNumberDay=(DateInput)=>{
    let num = numberDate(showMM(DateInput),showYYYY(DateInput))
    let ArrNum = ArrNumDate(num)
        return ArrNum.map((i,v)=>{
         return(
         <TableCell align="center" key={v} className={classes.num}>{i}</TableCell> 
         )
       })
   }
   const ShowHtmlDateName=(DateInput)=>{
    let num = numberDate(showMM(DateInput),showYYYY(DateInput))
    let ArrNum = ArrNumDate(num)
     return ArrNum.map((i,v)=>{
         let DD = handleMM(i)
         let YYYY = showYYYY(DateInput)
         let MM   = showMM(DateInput)
         let FindDate = showDateName(`${YYYY}/${MM}/${DD}`)
      return(
      <TableCell align="center" key={v} className={classes.num} >{changeUpperCase(showDateShortName(FindDate))}</TableCell> 
      )
    })
   }
   const ShowTableRow=(result)=>{
    return sortByNameLast(ArrStudentSortByName(result)).map((v,i)=>{
      return(
        <StyledTableRow key={i} hover={true}>

         <TableCell align="center" className={classes.num}>{i+1}</TableCell> 
         <TableCell align="left" className={classes.nameClass}>{changeUpperCase(v.name)}</TableCell>           
        {ShowDateInTableRow(v.arrSum)}
      </StyledTableRow>         
      )
    })
}
const ShowHtml =(v)=>{
  if(v===0)return ""
  else if(v===true)return 1
  else if(v===false) return "x"
  else if(v==='TMP') return "-"
  else return v
}
const ShowDateInTableRow=(arr)=>{ 
return arr.map((v,i)=>{
   return(
     <Fragment key={i}>   
        <TableCell align="center">{ShowHtml(v)}</TableCell>
     </Fragment>
   ) 
  })
}

const ShowHtmlSumTotal=(arr)=>{
return arr.map((v,i)=>{
  return(
  <TableCell key={i} align="center">{v===0?(<></>):handleNumberDot(v)}</TableCell>
  )
})
}
  return (
      <div>
      {TitleHeader()}
          <TableContainer component={Paper} className={classes.printPaymentList}>
            <Table className={classes.table} stickyHeader
            size="small" aria-label="a dense table" border="1" 
            id="table-react"
            >
              <TableHead border="1">
                <StyledTableRow border="1"> 
                  <TableCell rowSpan={2} align="center"  >Stt</TableCell>
                  <TableCell align="center" rowSpan={2}  >Họ tên học sinh</TableCell>                
                    {ShowHtmlNumberDay(DateInput)}
                  <TableCell align="center" rowSpan={2} className={classes.num}>Cộng</TableCell>
              </StyledTableRow>
              <StyledTableRow border="1">                          
                {ShowHtmlDateName(DateInput)}
              </StyledTableRow>         
              </TableHead>
              <TableBody>    
                  {ShowTableRow(FindResult.sumTotalStudentEat)}
              <StyledTableRow>                   
                  <TableCell colSpan={2} align="center" className={classes.num}>Cộng</TableCell>                
                  {ShowHtmlSumTotal(FindResult.findSumCheckDayFoodInDay)}
              </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>    
          {/* {OpenCheckDayFoodInMonth?TitleFooter():(<></>)}    */}
          {/* {TitleFooter()}         */}
          
    </div>
  )
}


export default React.memo(Index)