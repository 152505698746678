import * as TypeLoading from '../contant/uiLoading'

export const loadingSuccess = ()=>{
    return {
      type:TypeLoading.LOADING_SUCCESS
    }
}

export const loadingFail = ()=>{
    return {
      type:TypeLoading.LOADING_FAIL
    }
}

export const showSidebar = ()=>{
  return{
    type:TypeLoading.SHOW_SIDEBAR
  }
}

export const hideSidebar = ()=>{
  return {
    type:TypeLoading.HIDE_SIDEBAR
  }
}