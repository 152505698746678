import {put,takeEvery,call,select,delay, takeLatest} from 'redux-saga/effects'
import {ListGov,UpdateInfoGovSuccess,UpdatePassSuccess,DispatchItemInfoUserDetail,DispatchListInfoUserDetail,DeleteSuccess,
    DispatchAddTempleteToStore,DispatchListItemToStoreTemplete,AddTempleteSuccess,AlertLoginFailAccountOnly,
    AddFormSignRegisSuccessAndDispatchToStore,DispatchItemFormRegisToStore
} from '../../../actions/Gov/login'
import { DISPATCH_EDIT_ITEM_GOV,UPDATE_PASS_GOV,ADD_INFO_USER_DETAIL,LIST_INFO_USER_DETAIL,EDIT_ITEM_INFO_USER_DETAIL,
    EDIT_ARR_INFO_USER_DETAIL,DISPATCH_DELETE_ITEM_INFO_USER_DETAIL,ADD_TEMPLETE,LIST_BY_USERID_TEMPLETE, EDIT_ITEM_TEMPLETE,
    LOGIN_FAIL_ACCOUNT_ONLY,ADD_FORM_SIGN_REGIS, GET_FORM_SIGN_REGIS_BY_USERID,UPDATE_FORM_SIGN_REGIS
} from '../../../contant/Gov/login'
import {listByUserIdGov,updateInfoUserByUserId, addInfoUserDetail, ListByUserIdInfoUserDetail, EditItemInfoUserDetail,
  EditArrInfoUserDetail,DeleteInfoUserDetail, AddTemplete,ListTemplete,UpdateTemplete,
  AddFormSignRegis,GetFormSignRegisByUserId,UpdateFormSignRegisByUserId
} from '../../../api-services/user'
import {updatePass} from '../../../api-services/auth'
import * as uiLoading from '../../../actions/uiLoading'
import {REDIRECT_LOGIN_PAGE} from '../../../contant/auth'
import {push} from 'connected-react-router'

function* EditItemGovSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(updateInfoUserByUserId,payload.item)   
    if(res.data.result==='success'){
         let userId = yield select(state=>state.GovLogin.userId)
         let id = {id:userId}  
         let res2 = yield call(listByUserIdGov,id) 
         yield put(UpdateInfoGovSuccess())       
         yield put(ListGov(res2.data.data))
         yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* UpdatePassGovSaga({payload}){
  yield put(uiLoading.loadingSuccess())
    let res = yield call(updatePass,payload.item)
    if(res.data.result==='success'){
      yield put(UpdatePassSuccess())
      yield delay(5000)
      yield put(push(REDIRECT_LOGIN_PAGE))
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* AddInfoUserDetailSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(addInfoUserDetail,payload.item)
    if(res.data.result==="success"){        
        yield put(DispatchItemInfoUserDetail(res.data.data))
        yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* ListInfoUserDetailSaga(){
    yield put(uiLoading.loadingSuccess())
    let userId = yield select(state=>state.GovLogin.userId)
    let id = {id:userId}  
    let res = yield call(ListByUserIdInfoUserDetail,id)
    if(res.data.result==='success'){
      yield put(DispatchListInfoUserDetail(res.data.data))
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* EditItemInfoUserDetailSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(EditItemInfoUserDetail,payload.itemEdit)
    if(res.data.result==='success'){     
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* EditArrInfoDetailSaga({payload}){
    yield put(uiLoading.loadingSuccess)
    let res = yield call(EditArrInfoUserDetail,payload.newArr)
    if(res.data.result==='success'){     
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* DeleteInfoUserDetailSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(DeleteInfoUserDetail,payload.item)
    if(res.data.result==='success'){     
      yield put(DeleteSuccess())
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* AddTempleteSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(AddTemplete,payload.item)
  if(res.data.result==='success'){   
    yield put(DispatchAddTempleteToStore(res.data.data))  
    yield put(AddTempleteSuccess())
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
}

function* ListTempleteSaga(){
    yield put(uiLoading.loadingSuccess())
    let userId = yield select(state=>state.GovLogin.userId)
    let id = {id:userId}  
    let res = yield call(ListTemplete,id)
    if(res.data.result==='success'){   
      yield put(DispatchListItemToStoreTemplete(res.data.data))  
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}
function* EditItemSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(UpdateTemplete,payload.Item)
    if(res.data.result==='success'){   
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* LoginFailAccountOnlySaga(){
  //yield delay(4000)
  yield put(push(REDIRECT_LOGIN_PAGE))
  yield put(AlertLoginFailAccountOnly())
}

function* AddFormSignRegisSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(AddFormSignRegis,payload.item)
    if(res.data.result==='success'){   
      yield put(uiLoading.loadingFail())
      yield put(AddFormSignRegisSuccessAndDispatchToStore(res.data.data))
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

// function* GetFormSignRegisByUserIdSaga({payload}){     
//     //if(payload.item.userId!==""){
//       console.log(payload.item)
//        yield put(uiLoading.loadingSuccess())
//        let res = yield call(GetFormSignRegisByUserId,payload.item)
//        if(res.data.result==='success'){   
//         yield put(uiLoading.loadingFail())
//         yield put(DispatchItemFormRegisToStore(res.data.data))
//       }else{
//         alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
//       }
//    // }
// }

function* UpdateFormSignRegisSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(UpdateFormSignRegisByUserId,payload.item)
    if(res.data.result==='success'){   
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* InfoCusSaga(){
    yield takeLatest(DISPATCH_EDIT_ITEM_GOV,EditItemGovSaga)
    yield takeLatest(UPDATE_PASS_GOV,UpdatePassGovSaga)
    yield takeLatest(ADD_INFO_USER_DETAIL,AddInfoUserDetailSaga)
    yield takeLatest(LIST_INFO_USER_DETAIL,ListInfoUserDetailSaga)
    yield takeLatest(EDIT_ITEM_INFO_USER_DETAIL,EditItemInfoUserDetailSaga) 
    yield takeLatest(EDIT_ARR_INFO_USER_DETAIL,EditArrInfoDetailSaga)  
    yield takeLatest(DISPATCH_DELETE_ITEM_INFO_USER_DETAIL,DeleteInfoUserDetailSaga) 
    yield takeLatest(ADD_TEMPLETE,AddTempleteSaga)
    yield takeLatest(LIST_BY_USERID_TEMPLETE,ListTempleteSaga)
    yield takeLatest(EDIT_ITEM_TEMPLETE,EditItemSaga)
    yield takeLatest(LOGIN_FAIL_ACCOUNT_ONLY,LoginFailAccountOnlySaga)  
    yield takeLatest(ADD_FORM_SIGN_REGIS,AddFormSignRegisSaga)
    //yield takeLatest(GET_FORM_SIGN_REGIS_BY_USERID,GetFormSignRegisByUserIdSaga)
    yield takeLatest(UPDATE_FORM_SIGN_REGIS,UpdateFormSignRegisSaga)
}

export default InfoCusSaga