import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  loading:{
    position:"fixed",
    left:0,
    right:0,
    top:0,
    bottom:0,
    zIndex:1000,
    background:"rgba(0,0,0,0.4)"
  },
    loadingStart:{
    position:"fixed",
    left: '50%',
		top: '50%',
  }
}));

export default function CircularIndeterminate() {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <CircularProgress className={classes.loadingStart} color="secondary"/>
    </div>
  );
}
