import React,{useState} from 'react';
import {useStyles} from './styles'
import {Paper,Table,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow,Tooltip,TextField} from '@material-ui/core';
import {Edit,Cancel,Delete,Save} from '@material-ui/icons'
import {ColViewTypeOfService,CreateDataColViewTypeOfService} from '../../Helper/App_Service'
import {useSelector} from 'react-redux'
import {UpperCase} from '../../../Helper/Fn/String'
import {SearchHtml} from '../../../Helper/Fn/Search'
import {DeleteLastString} from '../../../Helper/Fn/String'
import {AddTypeOfServiceInput} from '../../Helper/App_Service'

const columns = ColViewTypeOfService

export default function StickyHeadTable() {
  const classes                       = useStyles();
  const [page, setPage]               = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const ListTypeOfService             = useSelector(state=>state.AdminAddService.ListTypeOfService)
  const [ResultSearch,setResultSearch]= useState(ListTypeOfService)
  const [ValueSearch,setValueSearch]  = useState('')
  const [EditAction,setEditAction]    = useState(false)
  const [valueFind,setValueFind]      = useState('')
  const EditItem=(v)=>{    
    let FilterItem = ResultSearch.filter(vv=>vv.nameTypeOfService===v.nameTypeOfService)
    setResultSearch(FilterItem)
    setEditAction(true)
    setValueFind(FilterItem[0].nameTypeOfService)
  }
  const DeleteItem=(v)=>{
    console.log(v)
  }
  const ActionComponent =(value)=>{
    return (
      <>
        <Tooltip title="Sửa" onClick={()=>EditItem(value)} className={classes.buttonTooltip}>
            {EditAction?<Cancel color="primary"/>: <Edit color="primary"/>}           
        </Tooltip>
        <Tooltip title="Xóa" onClick={()=>DeleteItem(value)}  className={classes.buttonTooltip}>
          {EditAction?<Save  color="secondary"/>:<Delete color="secondary"/>}          
        </Tooltip>
      </>
    )
  }

  const TableInCelRowHtml=(value)=>{
   return(
    AddTypeOfServiceInput.map((v,i)=>{
      return (
        <TextField
          key={i}
          name={v.name}
          type={v.type}
          defaultValue={value}
        />
      )
    })
   )
  }

  const rows = ResultSearch.map((v,i)=>{  
     return CreateDataColViewTypeOfService(UpperCase(v.nameTypeOfService),ActionComponent(v,i))
  })  

  const TableBodyHtml =()=>{
      let Search = rows.filter(row=>row.name.toLocaleLowerCase().indexOf(ValueSearch.toLocaleLowerCase())!==-1)
      return  Search.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
            {columns.map((column,i) => {
              let value = row[column.id];              
              return (                
                 <TableCell key={i}>                   
                      {value}
                 </TableCell>          
              );
            })}
          </TableRow>
        );
      })      
  }

  const TableBodyEditHtml=(arr)=>{
      return arr.map((v,i)=>{
        return  <TableRow hover role="checkbox" tabIndex={-1} key={i}>                      
                        <TableCell>
                            {TableInCelRowHtml(v.nameTypeOfService)}
                        </TableCell>
                          <TableCell>
                             {ActionComponent(v)}
                          </TableCell>
                </TableRow>
      })         
    }  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange=(e)=>{
    setValueSearch(DeleteLastString(e.target.value))        
  }

  return (
    <Paper className={classes.root}>
     {SearchHtml(handleChange)}
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column,i) => (                      
                 <TableCell
                  key={i}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                   </TableCell>               
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
                  {!EditAction?TableBodyHtml():TableBodyEditHtml(ResultSearch)}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}