import styled from "styled-components";
import { device } from "../../../container/Login/GioiDinhTue/component/style/MainCss/device";

export const MainDiv = styled.div`
    margin:auto;
    margin-top:30px;
    width:95%;
    height:900px;
    overflow:auto;
`

export const ContentDiv = styled.div`
    margin:auto;
    margin-top:20px;
    display:flex;
    flex-wrap:wrap;
    width:95%;
    @media (max-width:768px){
        display:block;
        margin:auto;
    }
`
export const ItemDiv = styled.div`       
    display:flex;
    width:calc(100% / 4);
    justify-content:space-around;
    @media (max-width:768px){
       display:block;
       width:100%;
    }    
`
export const TextName =styled.p`
    min-width:150px;
    color:#2980b9;
    padding:0 15px;
`

