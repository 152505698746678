export const SHOW_ACTION_FORM_USER = "SHOW_ACTION_FORM_USER"
export const SHOW_FORM_USER = "SHOW_FORM_USER"
export const GET_LIST_GROUP_NHA_NUOC = "GET_LIST_GROUP_NHA_NUOC"
export const GET_LIST_ALL_USER = "GET_LIST_ALL_USER"
export const SHOW_COMPONENT_FORM_USER = "SHOW_COMPONENT_FORM_USER"
export const HIDE_ACTION_FORM_USER = "HIDE_ACTION_FORM_USER"
export const HIDE_FORM_USER = "HIDE_FORM_USER"
export const DO_NOT_ADD_FORM = "DO_NOT_ADD_FORM"
export const ADD_FORM_INFO_USER = "ADD_FORM_INFO_USER"
export const ADD_FORM_INFO_USER_SUCCESS = "ADD_FORM_INFO_USER_SUCCESS"
export const ADD_FORM_INFO_USER_FAIL = "ADD_FORM_INFO_USER_FAIL"


export const ADD_FORM_DEPARTMENT = "ADD_FORM_DEPARTMENT"
export const ADD_FORM_DEPARTMENT_SUCCESS = "ADD_FORM_DEPARTMENT_SUCCESS"
export const ADD_FORM_DEPARTMENT_FAIL = "ADD_FORM_DEPARTMENT_FAIL"

export const ADD_USER_ACCOUNT = "ADD_USER_ACCOUNT"
export const INFO_USER_TITLE = "INFO_USER_TITLE"
export const INFO_USER_FORM = "INFO_USER_FORM"
export const INFO_USER_NAME = "INFO_USER_NAME"

export const INFO_USER = "INFO_USER"
export const GET_LIST_INFOUSER_BEGIN_INITIAL="GET_LIST_INFOUSER_BEGIN_INITIAL"
export const BEGIN_INITIAL_GET_LIST_DEPARTMENT_GOV = "BEGIN_INITIAL_GET_LIST_DEPARTMENT_GOV"
export const BEGIN_INSTALL_FORM = "BEGIN_INSTALL_FORM"
export const BEGIN_INSTALL_ID = "BEGIN_INSTALL_ID"
export const CHECK_EXIT_ID = "CHECK_EXIT_ID"
export const CHECK_EXIT_ID_SUCCESS = "CHECK_EXIT_ID_SUCCESS"
export const CHECK_EXIT_ID_FAIL = "CHECK_EXIT_ID_SUCCESS"
export const GET_LIST_ALL_INFO_USER = "GET_LIST_ALL_INFO_USER"
export const GET_LIST_DEPARTMENT = "GET_ALL_LIST_DEPARTMENT"
export const GET_LIST_FORM = "GET_LIST_FORM"

export const FIND_ARRAY_INFO_GOV = "FIND_ARRAY_INFO_GOV"

export const SHOW_FORM_DEPARTMENT_GOV ="SHOW_FORM_DEPARTMENT_GOV"
export const SHOW_ACTION_DEPARTMENT_GOV ="SHOW_ACTION_DEPARTMENT_GOV"
export const SHOW_COMPONENT_DEPARTMENT_GOV ="SHOW_COMPONENT_DEPARTMENT_GOV"
export const HIDE_FORM_DEPARTMENT_GOV ="HIDE_FORM_DEPARTMENT_GOV"
export const HIDE_FORM_DEPARTMENT_GOV_WHEN_SUBMIT ="HIDE_FORM_DEPARTMENT_GOV_WHEN_SUBMIT"
