import _ from 'lodash'

export const SumTongCongThuKH =(arr)=>{
    let result = arr.map(v=>{
         return v.VatTu.map(vv=>vv.priceCus*vv.quantity)
    })
    return (_.flatten(result).map(v=>v)).reduce((x,y)=>x+y,0)
 }
export const HandleFilterAddDetailProduct=(List,item)=>{    
            let result = List.map(v=>{
            let checkItem = item.VatTu.findIndex(vv=>vv.idVatTu===v._id)
            if(checkItem===-1)return v
        })   
        return result.filter(v=>v!==undefined)   
}
export const HandleListDetailProduct=(item,List)=>{
    let result = item.VatTu.map(v=>{       
                return List.filter(vv=>vv._id===v.idVatTu)
          })
    return _.flatten(result)
}

export const STATUS =[
    {value:1,label:'Đã Hoàn Thành'},
    {value:0,label:'Đang Xử Lý'}
]

export const STATUS_ =[
    {value:2,label:'Tất Cả'},
    {value:1,label:'Đã Hoàn Thành'},
    {value:0,label:'Đang Xử Lý'}
]

export const TYPE_DETAIL_PRODUCT =[
    {value:0,label:'Xuất Kho'},
    {value:1,label:'Ứng tiền mua hàng'},
    {value:2,label:'Khoán Công'}
]

export const TypeDetailProduct=(type)=>{
    if(type===0)return 'Xuất Kho'
    if(type===1)return 'Ứng tiền mua hàng'
    if(type===2)return 'Khoán Công'
}

export const FilterNameDetailProduct=(id,List)=>{
    return List.filter(vv=>vv._id===id)[0]
}
export const FilterNameDetailProduct_=(id,List)=>{
    return List.filter(vv=>vv._id===id)[0].nameDetailProduct
}

export const HandleIdProduct=(list)=>{
    return 0
}