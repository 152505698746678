import { DISPATCH_ITEM_INFO_GROUP_TO_STORE ,DISPATCH_ADD_BUSSINES_QUAN_TRI,
ADD_UPDATE_QUAN_TRI_FAIL,ADD_UPDATE_QUAN_TRI_SUCCESS,ADD_ITEM_SUPPIES_QUAN_TRI_SUCCESS,
ADD_ITEM_CUS_QUAN_TRI_SUCCESS,ADD_ITEM_STAFF_QUAN_TRI_SUCCESS,ADD_ITEM_DOI_TAC_QUAN_TRI_SUCCESS
,ADD_PARTMENT_QUAN_TRI_SUCCESS,ADD_CATE_QUAN_TRI_SUCCESS,ADD_PRODUCT_QUAN_TRI_SUCCESS,
DISPATCH_ITEM_CATE_QUAN_TRI_TO_STORE_AGAIN,DISPATCH_INFO_CUS_LOGIN_LIST_SUPPLIES_STORE, DISPATCH_CREATE_INFO_CUS_STORE,
DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_CUS_LOGIN,
DISPATCH_ACTIVE_ACCOUNT_INFO_CUS_LOGIN,
DISPATCH_CREATE_INFO_LIST_CUS_STORE,
DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_LIST_CUS_LOGIN,
DISPATCH_ACTIVE_ACCOUNT_INFO_LIST_CUS_LOGIN,
DISPATCH_INFO_CUS_LOGIN_LIST_CUS_STORE,
DISPATCH_INFO_CUS_LOGIN_LIST_STAFF_STORE,
DISPATCH_CREATE_INFO_LIST_STAFF_STORE,
DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_LIST_STAFF_LOGIN,
DISPATCH_ACTIVE_ACCOUNT_INFO_LIST_STAFF_LOGIN,
DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_LIST_DOI_TAC_LOGIN,
DISPATCH_ACTIVE_ACCOUNT_INFO_LIST_DOI_TAC_LOGIN,
DISPATCH_CREATE_INFO_LIST_DOI_TAC_STORE,
ADD_JOB_QUAN_TRI_SUCCESS,
DISPATCH_ADD_DETAIL_PRODUCT_QUAN_TRI_STORE,
DISPATCH_GET_DETAIL_PRODUCT_QUAN_TRI,DISPATCH_GET_ID_LOGIN_INFO_CUS, DISPATCH_LIST_JOB_QUAN_TRI_BY_CUS,
DISPATCH_SEARCH_BAO_GIA_CUS_JOB_STAFF,CREATE_BAO_GIA_CUS_JOB_STAFF_SUCCESS,
GET_EDIT_ADD_ITEM_LIST_PRODUCT_CUS_JOB_STAFF,GET_NEW_UPDATE_LOGIN_ACCOUNT_QUAN_TRI,
DISPATCH_UPDATE_ACCOUNT_INFO_QUAN_TRI,DISPATCH_GIAM_DINH_NGHIEM_THU_TO_STORE
} from "../contant/LOGIN_PAGE/OpenNavBarQuanTri";

export const DispatchGiamDinhNghiemThuToStore=(item)=>{
    return{
        type:DISPATCH_GIAM_DINH_NGHIEM_THU_TO_STORE,
        payload:{item}
    }
}

export const DispatchUpdateAccountInfoQuanTri=item=>{
    return{
        type:DISPATCH_UPDATE_ACCOUNT_INFO_QUAN_TRI,
        payload:{item}
    }
}

export const GetNewUpdateLoginAccountQuanTri=item=>{
    return{
        type:GET_NEW_UPDATE_LOGIN_ACCOUNT_QUAN_TRI,
        payload:{item}
    }
}

export const GetEditAddItemListProductCusJobStaff=(item)=>{
    return{
        type:GET_EDIT_ADD_ITEM_LIST_PRODUCT_CUS_JOB_STAFF,
        payload:{item}
    }
}

export const CreateBaoGiaCusJobStaffSuccess=()=>{
    return{
        type:CREATE_BAO_GIA_CUS_JOB_STAFF_SUCCESS        
    }
}

export const DispatchSearchBaoGiaCusJobStaff=(item)=>{
        return{
            type:DISPATCH_SEARCH_BAO_GIA_CUS_JOB_STAFF,
            payload:{item}
        }
}

export const DispatchListJobQuanTriByCus = (item)=>{
    return{
        type:DISPATCH_LIST_JOB_QUAN_TRI_BY_CUS,
        payload:{item}
    }
}

export const DispatchGetIdLoginInfoCus=(item)=>{
    return{
        type:DISPATCH_GET_ID_LOGIN_INFO_CUS,
        payload:{item}
    }
}
export const DispatchGetDetailProductQuanTriStore=(item)=>{
    return{
        type:DISPATCH_GET_DETAIL_PRODUCT_QUAN_TRI,
        payload:{item}
    }
}
export const DispatchAddDetailProductQuanTriStore=(item)=>{
    return{
        type:DISPATCH_ADD_DETAIL_PRODUCT_QUAN_TRI_STORE,
        payload:{item}
    }
}
export const DispatchCreateInfoListDoiTacStore=(item)=>{
    return{
        type:DISPATCH_CREATE_INFO_LIST_DOI_TAC_STORE,
        payload:{item}
    }
}

export const DispatchActiveAccountInfoListDoiTacLogin=(item)=>{
    return {
        type:DISPATCH_ACTIVE_ACCOUNT_INFO_LIST_DOI_TAC_LOGIN,
        payload:{item}
    }
}

export const DispatchUpdateActiveAccountInfoListDoiTacLogin=(item)=>{
    return{
        type:DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_LIST_DOI_TAC_LOGIN,
        payload:{item}
    }
}
export const DispatchInfoCusLoginListDoiTacStore=(item)=>{
        return{
            type:DISPATCH_INFO_CUS_LOGIN_LIST_CUS_STORE,
            payload:{item}
        }
}

export const DispatchActiveAccountInfoListStaffLogin=(item)=>{
    return{
        type:DISPATCH_ACTIVE_ACCOUNT_INFO_LIST_STAFF_LOGIN,
        payload:{item}
    }
}

export const DispatchUpdateActiveAccountInfoListStaffLogin=(item)=>{
    return{
        type:DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_LIST_STAFF_LOGIN,
        payload:{item}
    }
}

export const DispatchCreateInfoListStaffStore=(item)=>{
    return{
        type:DISPATCH_CREATE_INFO_LIST_STAFF_STORE,
        payload:{item}
    }
}
export const DispatchInfoCusLoginListStaffStore=(item)=>{
    return{
        type:DISPATCH_INFO_CUS_LOGIN_LIST_STAFF_STORE,
        payload:{item}
    }
}
//STAFF
export const DispatchInfoCusLoginListCusStore=(item)=>{
    return{
        type:DISPATCH_INFO_CUS_LOGIN_LIST_CUS_STORE,
        payload:{item}
    }
}

export const DispatchActiveAccountInfoListCusLogin=(item)=>{
    return {
        type:DISPATCH_ACTIVE_ACCOUNT_INFO_LIST_CUS_LOGIN,
        payload:{item}
    }
}

export const DispatchUpdateActiveAccountInfoListCusLogin =(item)=>{
    return{
        type:DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_LIST_CUS_LOGIN,
        payload:{item}
    }
}

export const DispatchCreateInfoListCusStore = (item)=>{
    return{
        type:DISPATCH_CREATE_INFO_LIST_CUS_STORE,
        payload:{item}
    }
}

export const DispatchActiveAccountInfoCusLogin =(item)=>{
    return{
        type:DISPATCH_ACTIVE_ACCOUNT_INFO_CUS_LOGIN,
        payload:{item}
    }
}

export const DispatchUpdateActiveAccountInfoCusLogin =(item)=>{
    return{
        type:DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_CUS_LOGIN,
        payload:{item}
    }
}



export const DispatchCreateInfoCusStore = (item)=>{
    return{
        type:DISPATCH_CREATE_INFO_CUS_STORE,
        payload:{item}
    }
}

export const DispatchInfoCusLoginListSuppliesStore =(item)=>{
    return{
        type:DISPATCH_INFO_CUS_LOGIN_LIST_SUPPLIES_STORE,
        payload:{item}
    }
}

export const DispatchItemCateQuanTriToStoreAgain=(item)=>{
    return{
        type:DISPATCH_ITEM_CATE_QUAN_TRI_TO_STORE_AGAIN,
        payload:{item}
    }
}
export const AddProductQuanTriSuccess=(item)=>{
    return{
        type:ADD_PRODUCT_QUAN_TRI_SUCCESS,
        payload:{item}
    }
}

export const AddCateQuanTriSuccess=(item)=>{
    return{
        type:ADD_CATE_QUAN_TRI_SUCCESS,
        payload:{item}
    }
}

export const AddPartmentQuanTriSuccess=(item)=>{
    return{
        type:ADD_PARTMENT_QUAN_TRI_SUCCESS,
        payload:{item}
    }
}
export const AddJobQuanTriSuccess=(item)=>{
    return{
        type:ADD_JOB_QUAN_TRI_SUCCESS,
        payload:{item}
    }
}

export const AddItemDoiTacQuanTriSuccess=(item)=>{
    return{
        type:ADD_ITEM_DOI_TAC_QUAN_TRI_SUCCESS,
        payload:{item}
    }
}


export const AddItemStaffQuanTriSuccess=(item)=>{
    return{
        type:ADD_ITEM_STAFF_QUAN_TRI_SUCCESS,
        payload:{item}
    }
}

export const AddItemCusQuanTriSuccess=(item)=>{
    return{
        type:ADD_ITEM_CUS_QUAN_TRI_SUCCESS,
        payload:{item}
    }
}

export const AddItemSuppliesQuanTriSuccess=(item)=>{    
    return{
        type:ADD_ITEM_SUPPIES_QUAN_TRI_SUCCESS,
        payload:{item}
    }
}

export const AddUpdateQuanTriFail = ()=>{
    return{
        type:ADD_UPDATE_QUAN_TRI_FAIL
    }
}

export const AddUpdateQuanTriSuccess = ()=>{
    return{
        type:ADD_UPDATE_QUAN_TRI_SUCCESS
    }
}

export const DispatchItemInfoGroupToStore =(item)=>{
    return{
        type:DISPATCH_ITEM_INFO_GROUP_TO_STORE,
        payload:{item}
    }
}

export const DispatchAddBussinesQuanTri=(item)=>{
    return{
        type:DISPATCH_ADD_BUSSINES_QUAN_TRI,
        payload:{item}
    }
}