import {put,takeLatest,call,select, takeEvery,} from 'redux-saga/effects'
import {ADD_MATERIAL, LIST_ALL_MATERIAL,ADD_TYPE_MATERIAL,LIST_ALL_TYPE_MATERIAL,
      EDIT_MATERIAL,EDIT_TYPE_MATERIAL,DISPATCH_ITEM_DELETE_MATERIAL,DISPATCH_ITEM_DELETE_TYPE_MATERIAL
       } from '../../../contant/Gov/cost'
import {WhenListAllYearSchool_0} from '../../../actions/Gov/student/student'
import {AddMaterial,ListAllMaterial,AddTypeMaterial,ListAllTypeMaterial, EditMaterial,
        EditTypeMaterial,AddMaterialMealDate,DeleteMaterialMealDate,EditMaterialMealDate,ListAllMaterialMealDate,
        AddSumLessMore,ListAllSumLessMore, EditSumLessMore,DeleteMaterial,DeleteTypeMaterial, DeleteManyMaterialMealDate, UpdatePhanBoNguyenLieuTinhAnApi
        }  from '../../../api-services/cost/material'
import {// listAllMaterial,
   listAllCategoryMaterial,AddMaterialStore,
   ListAllCategoryTypeMaterial,AddTypeMaterialStore,dispatchAddMaterialMealDateToStore,dispatchListAllMaterialToStore,dispatchAddSumLessMoreToStore,dispatchAddItemSuppliesToStore,
   dispatchListSuppliesStore,dispatchAddRepositoryToStore,dispatchListRepositoryToStore,
   dispatchAddImportToStore,dispatchListTypeMaterialToStore,dispatchItemAddBalanceToStore,
   dispatchListBalanceToStore,
   DispatchItemPhanBoNguyenLieuNutriTinhAn,
   } from '../../../actions/Gov/cost/material'
import {dispatchListAllSumLessMoreToStore} from '../../../actions/Gov/cost/material'
import * as uiLoading from '../../../actions/uiLoading'
import {ADD_ITEM_MATERIAL_MEAL_DATE,LIST_ALL_MATERIAL_MEAL_DATE,EDIT_MATERIAL_MEAL_DATE,
DELETE_ITEM_MATERIAL_MEAL_DATE,ADD_SUM_LESS_MORE,LIST_SUM_LESS_MORE,
DISPATCH_SUM_LESS_MORE_TO_DB,//edit SUM LESS MORE
SAVE_ADD_SUPPLIES,LIST_SUPPLIES_STORE,DISPATCH_EDIT_SUPPLIES_TO_DB,DISPATCH_ADD_REPOSITORY,
LIST_REPOSITORY_TO_STORE,
ALLOW_DELETE_REPOSITORY,DISPATCH_ITEM_EDIT_SAVE,ADD_IMPORT_TYPE_MATERIAL_TO_DB,LIST_IMPORT_TYPE_MATERIAL,
EDIT_IMPORT_REPO,ADD_BALANCE,LIST_BALANCE, DISPATCH_EDIT_BALANCE,DELETE_ITEM_IMPORT_TYPE_MATERIAL,GET_ALL_DB_YEARSHOOL_REPOSITORIES, DELETE_MANY_ITEM_MATERIAL_MEAL_DATE
} from '../../../contant/Gov/countEat/dispatch'
import {AddSupplies,EditSupplies,ListSupplies} from '../../../api-services/cost/supplies'
import {AddImportTypeMaterial,ListImportTypeMaterial,EditImportTypeMaterial,DeleteImportTypeMaterial,AddBalance,ListBalance,UpdateBalance,DeleteAndAddImportTypeMaterial

} from '../../../api-services/cost/importTypeMaterial'
import {AddRepository,EditRepository,ListRepository,DeleteRepository, GetAllYearSchoolRepository} from '../../../api-services/cost/repository'
import {AddPhanBoNguyenLieuNutriTinhAnApi} from '../../../api-services/cost/material'
import _, { take } from 'lodash'
import {HandleArrRepositoryYearSchool} from './fn'
import {ADD_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN,UPDATE_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN} from '../../../contant/Gov/countEat/dispatch'


function* AddMaterialSaga({payload}){  //them danh muc nguyen lieu
  yield put(uiLoading.loadingSuccess())  
  let {item}=payload  
  let res = yield call(AddMaterial,item) 
  
  if(res.data.result==='success'){
     yield put(AddMaterialStore(res.data.data))
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
  yield put(uiLoading.loadingFail()) 
}

function* listAllMaterialSaga(){//danh sach nguyen lieu
  yield put(uiLoading.loadingSuccess()) 
  let res =yield call(ListAllMaterial)


  if(res.data.result==='success'){
    yield put(listAllCategoryMaterial(res.data.data)) 
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
  yield put(uiLoading.loadingFail())
   
}

function* AddTypeMaterialSaga({payload}){ //them ten cac thuc pham
  yield put(uiLoading.loadingSuccess())  
  let {itemResult} = payload 
  let res = yield call(AddTypeMaterial,itemResult) 
  
  if(res.data.result==='success'){
     yield put(AddTypeMaterialStore(res.data.data))
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
  yield put(uiLoading.loadingFail())
}

function* ListAllTypeMaterialSaga(){//danh sach thuc pham
    yield put(uiLoading.loadingSuccess())
    let res = yield call(ListAllTypeMaterial) 
    
    if(res.data.result==='success'){
      yield put(ListAllCategoryTypeMaterial(res.data.data))
   }else{
     alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
   }
   yield put(uiLoading.loadingFail())  
}

function* EditMaterialSaga({payload}){
  yield put(uiLoading.loadingSuccess()) 
  let res = yield call(EditMaterial,payload.ITEM)   
 
  if(res.data.result==='success'){
      yield put(uiLoading.loadingFail())  
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
}
function* EditTypeMaterialSaga({payload}){
  yield put(uiLoading.loadingSuccess())   
  let res = yield call(EditTypeMaterial,payload.ItemValue)  
  
  if(res.data.result==='success'){
      yield put(uiLoading.loadingFail())  
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
}

function* AddItemMaterialMealDateSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(AddMaterialMealDate,payload.Item)   
  if(res.data.result==='success'){
    yield put(dispatchAddMaterialMealDateToStore(res.data.data))
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
}

function* ListAllMaterialMealDateSaga(){
  yield put(uiLoading.loadingSuccess())   
  let userId = yield select(state=>state.GovLogin.userId)
  let id = {id:userId}  
  let res = yield call(ListAllMaterialMealDate,id)
 
  if(res.data.result==='success'){
    yield put(dispatchListAllMaterialToStore(res.data.data))
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
}

function* EditMaterialMealDateSaga({payload}){
    yield put(uiLoading.loadingSuccess())    
    let res = yield call(EditMaterialMealDate,payload.value)  
    if(res.data.result==='success'){     
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* DeleteItemMaterialMealDateSaga({payload}){
  yield put(uiLoading.loadingSuccess())
    let res = yield call(DeleteMaterialMealDate,payload.item)        
    if(res.data.result==='success'){     
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}


 function* DeleteManyItemMaterialMealDateSaga({payload}){
  yield put(uiLoading.loadingSuccess())
    let res = yield call(DeleteManyMaterialMealDate,payload.item)        
    if(res.data.result==='success'){     
      yield put(uiLoading.loadingFail())
    }
 }


function* AddSumLessMoreSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(AddSumLessMore,payload.itemLessMore)   
    
    if(res.data.result==='success'){     
      yield put(dispatchAddSumLessMoreToStore(res.data.data))
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}
function* ListSumLessMoreSaga(){
  yield put(uiLoading.loadingSuccess())
  let userId = yield select(state=>state.GovLogin.userId)
  let id = {id:userId}  
  let res = yield call(ListAllSumLessMore,id)     
  
    if(res.data.result==='success'){     
      yield put(dispatchListAllSumLessMoreToStore(res.data.data))
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* EditSumLessMoreSaga({payload}){
  yield put(uiLoading.loadingSuccess())  
  let res = yield call(EditSumLessMore,payload.ItemToDB)
  
  if(res.data.result==='success'){         
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
}

function* AddSupplierSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(AddSupplies,payload.item)    
    if(res.data.result==='success'){    
      yield put(dispatchAddItemSuppliesToStore(res.data.data))
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* ListSuppliesStoreSaga(){
  yield put(uiLoading.loadingSuccess())
  let userId = yield select(state=>state.GovLogin.userId)
  let id = {id:userId}  
  let res = yield call(ListSupplies,id)  
    if(res.data.result==='success'){     
      yield put(dispatchListSuppliesStore(res.data.data))
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* EditSuppliesSaga({payload}){
  yield put(uiLoading.loadingSuccess())  
  let res = yield call(EditSupplies,payload.item)    
    if(res.data.result==='success'){         
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* AddRepositorySaga({payload}){
  yield put(uiLoading.loadingSuccess())  
  let res = yield call(AddRepository,payload.Item)    
    if(res.data.result==='success'){         
      yield put(dispatchAddRepositoryToStore(res.data.data))
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* ListRepositorySaga(){
  yield put(uiLoading.loadingSuccess())
  let userId = yield select(state=>state.GovLogin.userId)
  let id = {id:userId}  
  let res = yield call(ListRepository,id)  
    if(res.data.result==='success'){     
      yield put(dispatchListRepositoryToStore(res.data.data))
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* DeleteRepositorySaga({payload}){
  yield put(uiLoading.loadingSuccess())  
  let res = yield call(DeleteRepository,payload.item) 
    if(res.data.result==='success'){  
        yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* UpdateRepositorySaga({payload}){
  yield put(uiLoading.loadingSuccess())    
  let res = yield call(EditRepository,payload.Item) 
   if(res.data.result==='success'){  
       yield put(uiLoading.loadingFail())
   }else{
     alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
   }
}

function* addImportSaga({payload}){
  yield put(uiLoading.loadingSuccess())    
  let res = yield call(AddImportTypeMaterial,payload.itemAdd) 
   if(res.data.result==='success'){  
       yield put(dispatchAddImportToStore(res.data.data))
       yield put(uiLoading.loadingFail())
   }else{
     alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
   }
}

function* listImportSaga(){
  yield put(uiLoading.loadingSuccess())
  let userId = yield select(state=>state.GovLogin.userId)
  let id = {id:userId}  
  let res = yield call(ListImportTypeMaterial,id)  
    if(res.data.result==='success'){     
      yield put(dispatchListTypeMaterialToStore(res.data.data))
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* EditImportSaga({payload}){
  yield put(uiLoading.loadingSuccess())    
  let res = yield call(EditImportTypeMaterial,payload.item) 
   if(res.data.result==='success'){  
       yield put(uiLoading.loadingFail())
   }else{
     alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
   }
}

function* AddBalanceSaga({payload}){
  yield put(uiLoading.loadingSuccess())    
  let res = yield call(AddBalance,payload.item) 
   if(res.data.result==='success'){  
       yield put(dispatchItemAddBalanceToStore(res.data.data))
       yield put(uiLoading.loadingFail())
   }else{
     alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
   }
}

function* ListBalanceSaga(){
  yield put(uiLoading.loadingSuccess())    
  let userId = yield select(state=>state.GovLogin.userId)
  let id = {id:userId}  
  let res = yield call(ListBalance,id) 
   if(res.data.result==='success'){  
       yield put(dispatchListBalanceToStore(res.data.data))
       yield put(uiLoading.loadingFail())
   }else{
     alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
   }
}

function* EditBalanceSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(UpdateBalance,payload.item)
   if(res.data.result==='success'){  
       yield put(uiLoading.loadingFail())
   }else{
     alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
   }
}

function* DeleteImportTypeMaterialSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(DeleteImportTypeMaterial,payload.item)
  if(res.data.result==='success'){
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
}

function* DeleteItemMaterialSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(DeleteMaterial,payload.item)
  if(res.data.result==='success'){
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
}

function* DeleteItemTypeMaterialSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(DeleteTypeMaterial,payload.item)
  if(res.data.result==='success'){
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
}

function* GetAllYearSchoolRepositorySaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let userId ={id:payload.item}
  let res = yield call(GetAllYearSchoolRepository,userId)
  if(res.data.result==='success'){
    yield put(uiLoading.loadingFail())

    let {getAllImportTypeMaterial,getAllYearSchool}  = res.data.data
      if(getAllYearSchool.length===0){
        yield put(WhenListAllYearSchool_0())        
        //bat thong bao chon nam hoc 
      }
      if(getAllYearSchool.length===1){
          //xoa mang va them mang
          let item={
            arrDelete : getAllImportTypeMaterial,
            arrAdd : HandleArrRepositoryYearSchool(getAllImportTypeMaterial,_.first(getAllYearSchool)._id)
           }
          yield call(DeleteAndAddImportTypeMaterial,item) 
      }
    //xu ly mang
  }
}

function* AddPhanBoNguyenLieuNutriTinhAnSaga({payload}){
  let res = yield call(AddPhanBoNguyenLieuNutriTinhAnApi,payload.item)
    if(res.data.result==='success'){
        yield put(DispatchItemPhanBoNguyenLieuNutriTinhAn(res.data.data))
    }
  }

function* UpdatePhanBoNguyenLieuTinhAnSaga({payload}){
  let res = yield call(UpdatePhanBoNguyenLieuTinhAnApi,payload.item[0])
  
}

function* costMaterialSaga(){
  yield takeLatest(LIST_ALL_MATERIAL,listAllMaterialSaga)
  yield takeLatest(LIST_ALL_TYPE_MATERIAL,ListAllTypeMaterialSaga)  
  yield takeLatest(ADD_MATERIAL,AddMaterialSaga)
  yield takeLatest(ADD_TYPE_MATERIAL,AddTypeMaterialSaga)
  yield takeLatest(EDIT_MATERIAL,EditMaterialSaga)
  yield takeLatest(EDIT_TYPE_MATERIAL,EditTypeMaterialSaga)
  yield takeEvery(ADD_ITEM_MATERIAL_MEAL_DATE,AddItemMaterialMealDateSaga)
  yield takeLatest(LIST_ALL_MATERIAL_MEAL_DATE,ListAllMaterialMealDateSaga)
  yield takeLatest(EDIT_MATERIAL_MEAL_DATE,EditMaterialMealDateSaga)
  yield takeLatest(DELETE_ITEM_MATERIAL_MEAL_DATE,DeleteItemMaterialMealDateSaga)
  yield takeLatest(DELETE_MANY_ITEM_MATERIAL_MEAL_DATE,DeleteManyItemMaterialMealDateSaga)
  yield takeLatest(ADD_SUM_LESS_MORE,AddSumLessMoreSaga)
  yield takeLatest(LIST_SUM_LESS_MORE,ListSumLessMoreSaga)
  yield takeLatest(DISPATCH_SUM_LESS_MORE_TO_DB,EditSumLessMoreSaga)
  yield takeLatest(SAVE_ADD_SUPPLIES,AddSupplierSaga)
  yield takeLatest(LIST_SUPPLIES_STORE,ListSuppliesStoreSaga)
  yield takeLatest(DISPATCH_EDIT_SUPPLIES_TO_DB,EditSuppliesSaga)
  yield takeLatest(DISPATCH_ADD_REPOSITORY,AddRepositorySaga)
  yield takeLatest(LIST_REPOSITORY_TO_STORE,ListRepositorySaga)
  yield takeLatest(ALLOW_DELETE_REPOSITORY,DeleteRepositorySaga)
  yield takeLatest(DISPATCH_ITEM_EDIT_SAVE,UpdateRepositorySaga)
  yield takeLatest(ADD_IMPORT_TYPE_MATERIAL_TO_DB,addImportSaga)
  yield takeLatest(LIST_IMPORT_TYPE_MATERIAL,listImportSaga)
  yield takeLatest(EDIT_IMPORT_REPO,EditImportSaga)
  yield takeLatest(ADD_BALANCE,AddBalanceSaga)
  yield takeLatest(LIST_BALANCE,ListBalanceSaga)
  yield takeLatest(DISPATCH_EDIT_BALANCE,EditBalanceSaga)
  yield takeLatest(DELETE_ITEM_IMPORT_TYPE_MATERIAL,DeleteImportTypeMaterialSaga)
  yield takeLatest(DISPATCH_ITEM_DELETE_MATERIAL,DeleteItemMaterialSaga)
  yield takeLatest(DISPATCH_ITEM_DELETE_TYPE_MATERIAL,DeleteItemTypeMaterialSaga)
  yield takeLatest(GET_ALL_DB_YEARSHOOL_REPOSITORIES,GetAllYearSchoolRepositorySaga)
  yield takeLatest(ADD_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN,AddPhanBoNguyenLieuNutriTinhAnSaga)
  yield takeLatest(UPDATE_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN,UpdatePhanBoNguyenLieuTinhAnSaga)

}

export default costMaterialSaga