import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Button, DialogActions, TextField, Tooltip } from '@material-ui/core';
import { GenerateQrcodeIMG } from '../../../../../WebMamNon/component/footer/fn';
import { makeStyles } from '@material-ui/core/styles';

const color = '#2c3e50'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
}))

function ChangeTooltip(props) {
    const classes = useStylesBootstrap();    
    return <Tooltip arrow classes={classes} {...props} />;
}

export default function Index({open,handleClose}) {

    const [qrcode,setQrcode]              = useState(null)
    const [errQrcode,setErrQrcode]        = useState(false)  
    const [resultQrcode,setResultQrcode]  = useState("")

    const ResultItem=()=>{
       if(qrcode===null||qrcode===""||qrcode===undefined){
        setErrQrcode(true)
        setTimeout(()=>{
            setQrcode(false)
        },3000)
        return
       }   

       const fetchData = async()=>{
            let item = await GenerateQrcodeIMG(qrcode)
            setResultQrcode(item)
       }
       fetchData()
       .catch(console.error)

    }

    useEffect(()=>{
        if(resultQrcode!==""){
            setTimeout(()=>{
                setResultQrcode("")
            },5000)
        }
    },[resultQrcode])

  return (
    <div>     
      <Dialog aria-labelledby="customized-dialog-title" open={open} >
        <DialogTitle style={{color:'green'}} id="customized-dialog-title" onClose={handleClose}>
          Tạo mã Qrcode
        </DialogTitle>
        <DialogContent dividers>     
        <div>
             <TextField
                size='small'
                fullWidth
                variant="filled"
                label="Nhập chuỗi ký tự"
                style={{margin:5}}
                onChange={e=>setQrcode(e.target.value)}
                error={errQrcode}
            />        
        </div>

        {resultQrcode!==""&&
            <div>
                <ChangeTooltip title="Download">
                                <a href={resultQrcode} style={{textDecoration:'none'}} download>
                                        <img src={resultQrcode} style={{height:80}}/>
                                </a> 
                </ChangeTooltip>               
            </div>
        }
           
        </DialogContent>     
        <DialogActions>
          <Button autoFocus onClick={()=>ResultItem()} color="primary">
           Tạo mã 
          </Button>          
        </DialogActions>
      </Dialog>
    </div>
  );
}
