import {call, put, takeLatest} from 'redux-saga/effects'
import {UpdateQrcodeApi, GetQrcodeApi,UpdateItemTimeChamCongApi} from '../../Api-services/person/qrcode'
import {GET_QRCODE,UPDATE_ITEM_QRCODE,UPDATE_ITEM_TIME_CHAM_CONG} from '../../Contant/Person/qrcode'
import { DispatchGetQrcodeToStore,UpdateSuccess} from '../../Actions/qrcode'
const SUCCESS = 'success'



function* UpdateItemQrcodeSaga({payload}){
    let res = yield call(UpdateQrcodeApi,payload.item)
    if(res.data.result===SUCCESS){
      yield put(DispatchGetQrcodeToStore(res.data.data))
    }
  }

function* GetQrcodeSaga({payload}){
 let res = yield call(GetQrcodeApi,payload.item)
  if(res.data.result===SUCCESS){
    yield put(DispatchGetQrcodeToStore(res.data.data))
  }
}

function* UpdateItemTimeChamCongSaga({payload}){
      let res = yield call(UpdateItemTimeChamCongApi,payload.item.updateListQrcode)
      if(res.data.result===SUCCESS){
        yield put(DispatchGetQrcodeToStore(res.data.data))
        yield put(UpdateSuccess())
      }
}

function* Qrcode(){
  yield takeLatest(GET_QRCODE,GetQrcodeSaga)
  yield takeLatest(UPDATE_ITEM_QRCODE,UpdateItemQrcodeSaga)
  yield takeLatest(UPDATE_ITEM_TIME_CHAM_CONG,UpdateItemTimeChamCongSaga)
}


export default Qrcode