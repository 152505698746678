import _ from 'lodash'
import {sortArrAsc} from '../tableList/tableList'
export const FilterDupplicateItem=(arr)=>{
  return   [...new Set(arr.map(v=>v.name))]
}
export const HandleShowPrice=(item,arr)=>{ 
    return arr.filter(v=>v.name===item)
}
export const CheckItemToInsertInfoUserDetail =(arr,item)=>{
   let result=["done","edit","add"] 
   let find = arr.filter(v=>(v.name===item.name&&v.userId===item.userId))
   if(!find.length){
     return result[2]
   }
   if(find){
      if(find[0].price!==item.price||find[0].typeOfCountEat!==item.typeOfCountEat){
        return result[1]
      }
      if(find[0].price===item.price&&find[0].typeOfCountEat===item.typeOfCountEat){
        return result[0]
      }
   }
}

export const handleEditInfoUserDetail=(arr,item)=>{
  return arr.filter(v=>v.name===item.name)
}

const FilterIndexOf=(arr)=>{
    return arr.filter((item,index)=>arr.indexOf(item)===index)
}

export const FilterSelectCost =(arrCost,arrInfoDetail,item)=>{
    let nameArrCost = arrCost.map(v=>v.name)
    let nameArrDetail = arrInfoDetail.map(v=>v.name)
    let newArr = _.difference(nameArrCost,nameArrDetail)
    newArr.push(item.name)
    let ResultArr=[]
    arrCost.map(i=>{
      newArr.map(v=>{
        if(v===i.name){
            ResultArr.push(i)
        }
      })
    })
    return sortArrAsc(FilterIndexOf(ResultArr))   
}

export const FilterSelectCostNotItem =(arrCost,arrInfoDetail)=>{

    let nameArrCost = arrCost.map(v=>v.name)
    let nameArrDetail = arrInfoDetail.map(v=>v.name)
    let newArr = _.difference(nameArrCost,nameArrDetail)
    let ResultArr=[]
    arrCost.map(i=>{
      newArr.map(v=>{
        if(v===i.name){
            ResultArr.push(i)
        }
      })
    })
    return sortArrAsc(FilterIndexOf(ResultArr))   
}
