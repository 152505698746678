import * as cost from '../../contant/Gov/cost'

export const handleSwitch=(value)=>{
  switch (value) {
    case cost.DAILY_MEAL:    
    return 1
     case cost.DAILY_TUITION: 
     return 2
    case cost.SUMMER_TUITION:    
    return 3
    case cost.BREAKFAST_FEE:     
    return 4
    case cost.INFRASTRUCTURE:       
    return 5  
    case cost.CLEAN_FUEL:         
    return 6  
    case cost.NUM_OF_MEAL_MONTH:
    return 7             
    case cost.SERVICE_BOARDING:
      return 8
    case cost.SERVICE_SEVEN_DAY:
      return 9
    case cost.LEARN_LANGUAGUE:
      return 10
    case cost.NUM_OF_MEAL_7:
      return 11    
            
    default: return null  
  }
}

export const handleWhichCost=(arr,nameCost)=>{
    let valueCost = handleSwitch(nameCost)
    return arr.filter(item=>item.typeOf===valueCost)
}

export const handleNumberDot=(number)=>{
  //console.log(number)
  if(number===null||number===undefined||number==="")return 0
if(number!==null&&number!==undefined&&number!==""){
   return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
 } 
}
