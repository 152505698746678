import styled ,{keyframes}from 'styled-components'


export const gradient = keyframes`
        0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
`

export const wave = keyframes`
       2% {
        transform: translateX(1);
    }

    25% {
        transform: translateX(-25%);
    }

    50% {
        transform: translateX(-50%);
    }

    75% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(1);
    }
`

export const MainDiv = styled.div`
    background-image:linear-gradient(0,#f1c40f,#c0392b);
    padding: 40px ;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
        
         margin: auto;
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        overflow: auto;
        background: linear-gradient(315deg, rgba(101,0,94,1) 3%, rgba(60,132,206,1) 38%, rgba(48,238,226,1) 68%, rgba(255,25,25,1) 98%);
        animation: ${gradient} 15s ease infinite;
        background-size: 400% 400%;
        background-attachment: fixed;
`


export const Wave = styled.div`
        background: rgb(255 255 255 / 25%);
        border-radius: 1000% 1000% 0 0;
        position: fixed;
        width: 200%;
        height: 12em;
        animation: ${wave} 10s -3s linear infinite;
        transform: translate3d(0, 0, 0);
        opacity: 0.8;
        bottom: 0;
        left: 0;
        z-index: 10;
`

export const Wave1 = styled.div`
         background: rgb(255 255 255 / 25%);
        border-radius: 1000% 1000% 0 0;
        position: fixed;
        width: 200%;
        height: 12em;
        animation: ${wave} 10s -3s linear infinite;
        transform: translate3d(0, 0, 0);

         bottom: -1.25em;
         animation: ${wave} 18s linear reverse infinite;
         opacity: 0.8;
        
        left: 0;
        z-index: 11;
`



export const Wave2 = styled.div`
       background: rgb(255 255 255 / 25%);
        border-radius: 1000% 1000% 0 0;
        position: fixed;
        width: 200%;
        height: 12em;
        animation: wave 10s -3s linear infinite;
        transform: translate3d(0, 0, 0);

        bottom: -2.5em;
        animation: ${wave} 20s -1s reverse infinite;
        opacity: 0.9;
        
        left: 0;
        z-index: 11;
`

export const textclip = keyframes`
   from {
    background-position: 200% center;
  }
`
export const SeiAward = styled.div`
            text-transform: uppercase;
            background-image: linear-gradient(
                -225deg,
                #231557 0%,
                #44107a 29%,
                #ff1361 67%,
                #fff800 100%
            );
            background-size: auto auto;
            background-clip: border-box;
            background-size: 200% auto;
            ${'' /* color: #fff; */}
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: ${textclip} 3s linear infinite;
            display: inline-block;
            font-size: 14px;            
`