import {call,put,takeLatest,takeEvery} from 'redux-saga/effects'
import {push} from 'connected-react-router'
import _get from 'lodash/get'
import {GetListDateMealByUserId}  from '../../actions/Gov/weeklyMeal/dateInput'
import * as TypeAuth from '../../contant/auth'
import {ALERT_RESET_ACCOUNT, ALERT_VERIFY_PAYMENT_ACCOUNT} from '../../contant/users'
import * as uiLoading from '../../actions/uiLoading'
import {getListAllUser} from '../../actions/pageUser'
import {getListFrom} from '../../actions/auth'
import {getUser,getInfoUser,getListDepartment,AlertResetAccount,GetFormSignRegisByUserId, UpdateTypeOf_0_PaymentApi} from '../../api-services/user'
import {GetInfoStaffApi,GetPaymentStaffApi} from '../../APP_ADMIN_PERSON/Person/TaiKhoanNhanVienDangNhap/api'
import {getListDateMealByUserId} from '../../api-services/weeklyFood/dateMeal'
import {DispatchGetInfoStaffToStore,DispatchGetPaymentStaffToStore} from '../../APP_ADMIN_PERSON/Person/TaiKhoanNhanVienDangNhap/action'
import {signup,login, GetInfoUserVerifyPaymentApi} from '../../api-services/auth'
import {ListAllUser} from '../../api-services/user'
import * as AuthAction from '../../actions/auth'
import * as UsersAction from '../../actions/users'
import * as GovAction from '../../actions/Gov/login'
import {listByUserIdGov} from '../../api-services/user'
import {ListGov} from '../../actions/Gov/login'
import {AUTHORIZATION_KEY} from '../../contant/index'
//import axiosService from '../../api-services/api'
import _ from 'lodash'
import {AddAppActive, ListAppActive,GetAppActiveByUserId}from '../../APP_ADMIN_PERSON/Api-services/ADMIN_APP_SERVICE/index'
import {DispatchItemAddAppActiveStore,DispatchListAppActiveToStore,DispatchAppActiveByUserWhenLogin}from '../../APP_ADMIN_PERSON/Actions/App_TypeOfService'
import {AddManagerActiveAccount,ListManagerActiveAccount,FindUserName,ListApprovalCheckDayFood,ItemManagerAccountByUserId
} from '../../ManagerAccountActiveGov/api-services/index'
import {DispatchListManagerAccountActiveToStore,DispatchItemManagerAccountClassActiveToStore,DispatchIdClassActiveToStore,DispatchItemApprovalCheckDayFoodToStoreFromDb,DateInput,DispatchItemManagerAccountByUserId} from '../../ManagerAccountActiveGov/actions'
import {findUserName,filterUserAccount} from './helper'
import {ListAllClassByUserId,ListStudentNow,ListStudentRegisSevenDay} from '../../api-services/student/student'
import {dispatchListClassToStore,DispatchAllListStudentNow,DispatchListRegisSevenToStore} from '../../actions/Gov/student/student'

const USERS_LOGIN = "UsersLogin"


function* proccessSignup({payload}){   
    let {name,password,idGroup} = payload  
    yield put(uiLoading.loadingSuccess())    
         try{                               
                let res = yield call(signup,{name,password,idGroup})                   
                let {data} = res                  
                if(data.result==='success'){
                  if(data.resultData.idGroup===4){
                    let item ={
                    userId:data.resultData._id,
                    name:data.resultData.name,
                    managerRepo:false,
                    managerStaff:false,
                    managerSell:false,
                    managerDiff:false
                  }
                   let res_2 = yield call(AddAppActive,item)                   
                   yield put(DispatchItemAddAppActiveStore(res_2.data.data))
                  }
                  if(data.resultData.idGroup===5){
                    let item={
                      userId:data.resultData._id,
                      managerAccountActive:false,
                      listAccount:[]
                    }
                    yield call(AddManagerActiveAccount,item)
                  }
                  
                  yield put(AuthAction.signupSuccess(data.message))
                  yield put(push(TypeAuth.REDIRECT_SIGNUP_SUCCESS))       
                }else{
                  yield put(AuthAction.signupFail(data.message))
                }                            
                                            
                } catch (error) {
                  let details = _get(error,'response.data.detail',{})
                  yield put(AuthAction.signupFail(details))
                }finally{
                  yield put(uiLoading.loadingFail())
                }       
    }

  function* proccessLogin({payload}){     
      //lay so lieu tren localstorga
          //et getArrUserLogin = JSON.parse(localStorage.getItem('UsersLogin'))
          let{name,password} = payload  
          let getdata = {name,password}  
          yield put(uiLoading.loadingSuccess())       
          try{              
              let res    = yield call(login,getdata)  
              let {data} = res                
                           if(data.result==='success'){
                              let sendDataToProps = {name:data.data.name,idGroup:data.data.idGroup,idUser:data.data._id}
                              yield put(UsersAction.saveUserLoginInfo(sendDataToProps))                      
                              let {token}=data.data
                              //axiosService.setHeader('PMT',`${token}`)
                              localStorage.setItem(AUTHORIZATION_KEY,token)
                              localStorage.setItem(USERS_LOGIN,JSON.stringify(sendDataToProps))
                              let listUser= yield call(ListAllUser)   //danh sach user acount     
                                                 const res        = yield call(getUser)   //danh sach nhom idGroup ===5 nhom Gov       
                                                 const res_2      = yield call(getInfoUser) //danh sach bang infoUser
                                                 const res_3      = yield call(getListDepartment)  //danh sach trong bang department    
                                                 let listInfo     = res_2.data.data
                                                 let userLogin    = data.data._id                                                 
                                                 let findGovLogin = _.find(listInfo,function(user){
                                                  return user.userId===userLogin
                                                 })
                          
                              if(data.data.idGroup===1){               
                                yield put(AuthAction.loginSuccess(data.message))                 
                                yield put(push(TypeAuth.REDIRECT_LOGIN_SUCCESS))   
                                //danh sach account active
                                let res  = yield call(ListAppActive)
                                //danh sach manager account
                                let res2 = yield call(ListManagerActiveAccount)
                                yield put(DispatchListManagerAccountActiveToStore(res2.data.data))
                                yield put(DispatchListAppActiveToStore(res.data.data))
                              }

                              //nhom nha nuoc
                              else if(data.data.idGroup===5){         
                               // yield put(AuthAction.loginSuccess(data.message))                        
                                yield put(push(TypeAuth.REDIRECT_LOGIN_USER_CUS_NN_SUCCESS)) 
                                yield put(GovAction.GetInfoUserLogin(findGovLogin))
                                yield put(DateInput(Date.now()))
                                let item={userId:findGovLogin.userId}
                                let data ={id:findGovLogin.userId}                                 
                                let infoCus = yield call(listByUserIdGov,data) 
                                let res2 = yield call(ListManagerActiveAccount)
                                let res3 = yield call(GetFormSignRegisByUserId,item)
                                yield put(GovAction.DispatchItemFormRegisToStore(res3.data.data))
                                yield put(DispatchListManagerAccountActiveToStore(res2.data.data))
                                yield put(ListGov(infoCus.data.data))                               
                                let getByUserId = yield call(getListDateMealByUserId,data)
                                yield put(GetListDateMealByUserId(getByUserId.data.data))    
                              }
                              //nhom nha nuoc
                              else if(data.data.idGroup===2){
                                yield put(push(TypeAuth.REDIRECT_LOGIN_STAFF))
                              }
                              else if(data.data.idGroup===4){        
                               yield put(AuthAction.loginSuccess(data.message))                        
                               yield put(push(TypeAuth.REDIRECT_LOGIN_PERSON))
                               let id={id:data.data._id}                               
                               let res = yield call(GetAppActiveByUserId,id)
                               yield put(DispatchAppActiveByUserWhenLogin(res.data.data))                               
                               }  
                              //doanh nghiep tu nha to chuc
                               else if(data.data.idGroup===88){                                
                                yield put(AuthAction.loginSuccess(data.message))
                                yield put(push(TypeAuth.REDIRECT_LOGIN_PERSON_STAFF))
                                let idStaff ={
                                  idStaff:data.data._id
                                }
                                let res88 = yield call(GetInfoStaffApi,idStaff)
                                yield put(DispatchGetInfoStaffToStore(res88.data.data))
                                let res99 = yield call(GetPaymentStaffApi,idStaff)
                                yield put(DispatchGetPaymentStaffToStore(res99.data.data))
                               }

                               else if(data.data.idGroup===8){
                                  //yield put(CloseAlertSuccess(ACCOUNT_IS_DEACTIVE))
                                  alert('Tài khoản chưa được kích hoạt')
                               }
                               //doanh nghiep tu nha to chuc
                              else if(data.data.idGroup===7){      
                                yield put(AuthAction.loginSuccess(data.message))
                                yield put(push(TypeAuth.REDIRECT_LOGIN_CLASS_ACTIVE))
                                yield put(DispatchIdClassActiveToStore(data.data._id))
                                yield put(DateInput(Date.now()))
                               let name ={
                                 name:findUserName(data.data.name).trim()
                               }
                               let itemListCheckDayFood = {
                                 userClass:data.data._id,
                                 dateApproval:Date.now()
                               }
                               //lay danh sach phe duyet cham an
                               let resListApproval = yield call(ListApprovalCheckDayFood,itemListCheckDayFood)
                              if(resListApproval.data.result==='success'){
                                  yield put(DispatchItemApprovalCheckDayFoodToStoreFromDb(resListApproval.data.data))
                              }
                               // tim managerAccountActive dua vao userName
                               let res = yield call(FindUserName,name)
                               let item =_.first(res.data.data)
                               let id={id:item.userId}    

                              //  let addMenuWeeklies={
                              //     userId:id.id,
                              //     date:Date.now()
                              //  }    
                               
                              //  yield call(CheckToAddMenuWeelies,addMenuWeeklies)                               
                               
                                let findItemAccount= filterUserAccount(item.listAccount,data.data.name)
                                yield put(DispatchItemManagerAccountClassActiveToStore(findItemAccount))
                                     
                                //viet them menu week lies

                                let res2=yield call(ListAllClassByUserId,id)
                                yield put(dispatchListClassToStore(res2.data.data))

                                let res3 = yield call(ListStudentNow,id)    
                                yield put(DispatchAllListStudentNow(res3.data.data))

                                let res4 = yield call(ListStudentRegisSevenDay,id)
                                yield put(DispatchListRegisSevenToStore(res4.data.data))  

                                let res5 = yield call(ItemManagerAccountByUserId,id)
                                yield put(DispatchItemManagerAccountByUserId(res5.data.data[0]))

                              }
                              yield put(getListAllUser(listUser.data.data))
                              yield put(getListFrom(res.data.data,res_2.data.data,res_3.data.data,listUser.data.data))                      
                          }else{
                            yield put(AuthAction.loginFail(data.message))
                          }                            
          }catch(error){
            const err = _get(error, '', {});
            yield put(AuthAction.loginFail(err));
          }finally{
            yield put(uiLoading.loadingFail())
          }
    }

  function* LoginPageCreateAccountSaga(){
    yield put(push(TypeAuth.REDIRECT_SIGNUP_PAGE))
  }

  function* AlertResetAccountSaga({payload}){
    yield put(uiLoading.loadingSuccess())   
    let res = yield call(AlertResetAccount,payload.id)
    if(res.data.result==="success"){
        yield put(UsersAction.AlertResetAccountSuccess(res.data.data))  
        yield put(uiLoading.loadingFail())  
    }else{
      alert('Liên hệ với quản trị nếu đợi quá lâu')
    }
  }    

  function* AlertVerifyPaymentAccountSaga({payload}){
    //alert(payload.id)
    let splitId = payload.id.id.split('&&&')
    let res = yield call(GetInfoUserVerifyPaymentApi,splitId)
    if(res.data.result==="success"){
      yield put(UsersAction.AlertVerifyPaymentAccountToStore(res.data.data))  
      yield call(UpdateTypeOf_0_PaymentApi,splitId)
      yield put(uiLoading.loadingFail())  
      }
    if(res.data.result==='fail'){
      yield put(UsersAction.AlertVerifyPaymentAccountToStore(false))
      yield put(uiLoading.loadingFail())  
    }    
  }

function* authSaga(){
  yield takeLatest(TypeAuth.SIGNUP, proccessSignup)
  yield takeLatest(TypeAuth.LOGIN,proccessLogin)
  yield takeLatest(TypeAuth.DISPATCH_LOGIN_PAGE_CREATE_ACCOUNT,LoginPageCreateAccountSaga)
  yield takeLatest(ALERT_RESET_ACCOUNT,AlertResetAccountSaga)
  yield takeLatest(ALERT_VERIFY_PAYMENT_ACCOUNT,AlertVerifyPaymentAccountSaga)
}

export default authSaga