import React, { useState } from 'react';

const Index = React.memo((props) => {
    const [linkWeb,setLinkWeb] = useState(window.location.href)

    return (
        <div>
                vat ly tri lieu     
        </div>
    );
});

export default Index;