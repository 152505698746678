import { DISPATCH_GET_LIST_CRM_TO_STORE,DISPATCH_ADD_CREATE_NEW_CRM_TO_STORE,DELETE_ITEM_CRM,DELETE_CRM_SUCCESS,UPDATE_ITEM_CRM_SUCCESS } from "../contant/CRM";

export const UpdateItemCrmSuccess =(item)=>{
    return{
        type:UPDATE_ITEM_CRM_SUCCESS,
        payload:{item}
    }
}

export const DeleteItemCrm=(item)=>{
    return {
        type:DELETE_ITEM_CRM,
        payload:{item}
    }
}

export const DispatchAddCreateNewCrmToStore = (item)=>{
    return{
        type:DISPATCH_ADD_CREATE_NEW_CRM_TO_STORE,
        payload:{item}
    }
}

export const DispatchGetListCrmToStore =(item)=>{
    return{
        type:DISPATCH_GET_LIST_CRM_TO_STORE,
        payload:{item}
    }
}

export const DeleteCrmSuccess = (item)=>{
    return{
        type:DELETE_CRM_SUCCESS,
        payload:{item}
    }
}