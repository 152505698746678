//xoa ky tu cuoi cung trong chuoi
export const DeleteLastString =(value)=>{
    let result = (value+'\b').trim()
    return result.substring(0,result.length-1).trim().toLocaleLowerCase()
}

//cat chuoi thanh khoang trang, cắt bỏ các dấu ',' neu co
export const SplitSpaceString=(value)=>{
    return value.split(/[\s,]+/g)
}

export const UpperCase=(str)=>{
    return str.charAt(0).toUpperCase()+str.slice(1)
}

export const CheckViewOrEditComponentInfoStaff=(ItemInfoStaff)=>{
  if(ItemInfoStaff===undefined) return false
  if(ItemInfoStaff.infoStaff.avatarImg!==undefined&&ItemInfoStaff.infoStaff.sex!==undefined&&
      ItemInfoStaff.infoStaff.birth!==undefined&&ItemInfoStaff.infoStaff.passport!==undefined&&
      ItemInfoStaff.infoStaff.passportImgFont!==undefined&&ItemInfoStaff.infoStaff.passportImgBack!==undefined&&
      ItemInfoStaff.infoStaff.startJob!==undefined&&ItemInfoStaff.infoStaff.levelSchool!==undefined&&
      ItemInfoStaff.infoStaff.email!==undefined&&ItemInfoStaff.infoStaff.phone!==undefined&&
      ItemInfoStaff.infoStaff.address!==undefined&&ItemInfoStaff.infoStaff.numberBank!==undefined
    )
     return true
     else{
       return false
     }      
}
