import axiosServices from '../api'
import {API_ENDPOINT} from '../../contant/index'

//block
const urlAddBlock              = 'block'
const urlListBlockByUserId     = 'block/userId'
const urlUpdateBlock           = 'block/userId'
const urlDeleteBlockClass      = 'block/deleteBlock'
//block

//class
const urlAddClass              = 'class'
const urlListClassByUserId     = 'class/userId'
const urlUpdateClass           = 'class/userId'
const urlDeleteClass           = 'class/deleteClass'
//class

//student
const urlAddStudent            = 'student'
const urlAddStudentAfter       = 'student/addStudentAfter'
const urlListStudentByUserId   = 'student/userId'
const urlUpdateStudent         = 'student/userId'
const urlDeleteStudent         = 'student/userId'
const urlAddManyStudent        = 'student/many/userId'

//student now
const urlAddStudentNow             = 'studentNow'
const urlListStudentNow            = 'studentNow/list/userId'
const urlDeleteItemStudentNow      = 'studentNow/userId'
const urlUpdateListStudentNow      = 'studentNow/updateListStudentNow'
const urlDeleteListStudentNow      = 'studentNow/deleteListStudentNow'
const urlDeleteStudentInStudentNow = 'studentNow/DeleteStudentInStudentNow'

//Student Leave temporaty 
const urlAddStudentLeaveTemporaty    = 'studentLeaveTemporaty/add'
const urlGetStudentLeaveTemporaty    = 'studentLeaveTemporaty/getStudentLeaveTemporaty'
const urlUpdateStudentLeaveTemporaty = 'studentLeaveTemporaty/updateStudentLeaveTemporaty'
const urlGetStudentLeaveTemporatyNotByDateNow = 'studentLeaveTemporaty/getStudentLeaveTemporatyNotByDateNow'

//yearSchool
const urlAddYearSchool         = 'student/yearSchool'
const urlListAllYearSchool     = 'student/yearSchool/userId'
const urlUpdateYearSchool      = 'student/yearSchool/userId'
//student
//check day food
const urlListAllDateMeal       = 'student/listAllDateMeal/userId'
const urlAddCheckDateFood      = 'student/addCheckDateFood/userId'
const urlDeleteCheckDateFood   = 'student/deleteCheckDateFood/userId'


const urlListAllCheckDateFood  = 'student/listAllCheckDateFood/userId'
const urlEditCheckDateFood     = 'student/editCheckDateFood/userId'
const urlAddStudentNotEat      = 'student/addStudentNotEat/userId'
const urlDeleteStudentNotEat   = 'student/deleteStudentNotEat/userId'

const UrlAddManyCheckDateFood  = 'student/AddManyCheckDateFood/userId'

//check day food
//regis seven day
const urlAddStudentRegisSevenDay    = 'student/regis/userId'
const urlDeleteRegisSevenDay    =     'student/regis/userId'

const urlUpdateStudentRegisSevenDay = 'student/regis/userId/update'
const urlListStudentRegisSevenDay   = 'student/regis/userId/list'
//regis seven day

//tranfer leftover
const urlAddTransferLeftOver     = 'student/transferLeftOver/Add'
const urlGetTransferLeftOver     = 'student/transferLeftOver/List'
const urlDeleteTransferLeftOver  = 'student/transferLeftOver/Delete'
//tranfer leftover

//buffet
const urlAddBuffet               = 'countEat/addBuffet'
const urlListBuffet              = 'countEat/listBuffet'
const urlUpdateBuffet            = 'countEat/updateBuffet'
const urlRemoveBuffet            = 'countEat/removeBuffet'
//buffet

//print receipts               
const urlAddReceipts                               = "countEat/addPrintReceipts"
const urlGetReceiptsByUserClassYearSchool          = "countEat/GetReceiptsByUserClassYearSchool"
const urlUpdateReceipts                            = "countEat/UpdateReceipts"

const urlAddUpdateDailyTui                         = "countEat/AddUpdateDailyTui"
const urlUpdateUpdateDailyTui                      = "countEat/UpdateUpdateDailyTui"
const urlGetUpdateDailyTui                         = "countEat/GetUpdateDailyTui"
const urlGetItemUserInfoByUserId = 'countEat/GetItemUserInfoByUserId'

const urlManagerDeleteItemStudentInfo = 'countEat/ManagerDeleteItemStudentInfo'
const urlGetListDeleteItemStudentInfo = 'countEat/GetListDeleteItemStudentInfo'


export const GetListDeleteItemStudentInfoApi = item=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetListDeleteItemStudentInfo}`,item)
}

export const ManagerDeleteItemStudentInfoApi = item=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlManagerDeleteItemStudentInfo}`,item)
}

export const DeleteClassApi =(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlDeleteClass}`,item)
}

export const GetItemUserInfoByUserIdApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetItemUserInfoByUserId}`,item)
}

//block
export const AddBlock = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddBlock}`,item)
}

export const ListAllBlockByUserId = (id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListBlockByUserId}`,id)
}

export const UpdateBlock = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateBlock}`,item)
}

export const DeleteBlockClassApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlDeleteBlockClass}`,item)
}

// export const DeleteBlock =(item)=>{
//   return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteCostStudent}`,item)
// }

//block

//class
export const AddClass = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddClass}`,item)
}

export const ListAllClassByUserId = (id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListClassByUserId}`,id)
}

export const UpdateClass = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateClass}`,item)
}
//class

//student
export const AddStudent = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddStudent}`,item)
}

export const AddStudentAfter = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddStudentAfter}`,item)
}

export const ListAllStudentByUserId = (id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListStudentByUserId}`,id)
}

export const UpdateStudent = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateStudent}`,item)
}
export const DeleteStudent = (item)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteStudent}`,item)
}
export const AddManyStudent = (arr)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddManyStudent}`,arr)
}
//student now 
export const AddStudentNow=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddStudentNow}`,item)
}
export const ListStudentNow=(userId)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListStudentNow}`,userId)
}
  export const DeleteStudentNow=(item)=>{
    return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteItemStudentNow}`,item)
  }

  export const DeleteStudentInStudentNowApi=(item)=>{
      return axiosServices.post(`${API_ENDPOINT}/${urlDeleteStudentInStudentNow}`,item)
  }
  //yearSchool
  export const ListAllYearSchool = (id)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlListAllYearSchool}`,id)
  }
  export const AddYearSchool = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddYearSchool}`,item)
  }
  export const UpdateYearSchool = (item)=>{
    return axiosServices.put(`${API_ENDPOINT}/${urlUpdateYearSchool}`,item)
  }
  //yearSchool
//student
//check day food
export const listAllDateMeal = (id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListAllDateMeal}`,id)
}
export const addCheckDateFood = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddCheckDateFood}`,item)
}
export const deleteCheckDateFood = (item)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteCheckDateFood}`,item)
}
export const listAllCheckDateFood = (id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListAllCheckDateFood}`,id)
}
export const editCheckDateFood = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlEditCheckDateFood}`,item)
}
export const addStudentNotEat =  (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlAddStudentNotEat}`,item)
}

export const deleteStudentNotEat = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlDeleteStudentNotEat}`,item)
}

//check day food

//regis seven day
export const AddStudentRegisSevenDay=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddStudentRegisSevenDay}`,item)
}
export const DeleteRegisSevenDay=(item)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteRegisSevenDay}`,item)
}
export const UpdateStudentRegisSevenDay=(item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateStudentRegisSevenDay}`,item)
}
export const ListStudentRegisSevenDay=(id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListStudentRegisSevenDay}`,id)
}
//regis seven day

//tranfer leftover ket chuyen phieu an thua
export const AddTransferLeftOver =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddTransferLeftOver}`,item)
}
export const GetTransferLeftOver=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetTransferLeftOver}`,item)
}

export const DeleteTransferLeftOver=(item)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteTransferLeftOver}`,item)
}

export const AddBuffet = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddBuffet}`,item)
}
export const ListBuffet = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListBuffet}`,item)
}
export const UpdateBuffet=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateBuffet}`,item)
}

export const RemoveBuffet=(item)=>{
    return axiosServices.delete(`${API_ENDPOINT}/${urlRemoveBuffet}`,item)
}

export const UpdateListStudentNow=(item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateListStudentNow}`,item)
}

export const DeleteListStudentNow=(item)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteListStudentNow}`,item)
}

export const AddStudentLeaveTemporaty =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddStudentLeaveTemporaty}`,item)
}

export const GetStudentLeaveTemporaty =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetStudentLeaveTemporaty}`,item)
}

export const UpdateStudentLeaveTemporaty=(item)=>{
    return axiosServices.put(`${API_ENDPOINT}/${urlUpdateStudentLeaveTemporaty}`,item)
}

export const GetStudentLeaveTemporatyNotByDateNow=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetStudentLeaveTemporatyNotByDateNow}`,item)
}

// Print Receipts
export const AddReceipts=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddReceipts}`,item)
}

export const GetReceiptsByUserClassYearSchool=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetReceiptsByUserClassYearSchool}`,item)
}

export const UpdateReceipts=(item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateReceipts}`,item)
}

export const AddUpdateDailyTuiApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddUpdateDailyTui}`,item)
}

export const UpdateUpdateDailyTuiApi=(item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateUpdateDailyTui}`,item)
}

export const GetListUpdateDailyTuiApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetUpdateDailyTui}`,item)
}

export const AddManyCheckDateFoodApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlAddManyCheckDateFood}`,item)
}

