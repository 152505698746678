import {fork,all} from 'redux-saga/effects'
import CreateWeb from './CreateWeb'


function* IndexSaga(){
    yield all(
        [
            yield fork(CreateWeb)
        ]
    )
}

export default IndexSaga