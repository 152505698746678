import {OPEN_SELECT_REPO,CLOSE_SELECT_REPO,ADD_SUPPLIES_SUCCESS_NOTIFICATION} from '../Contant/Person/cateRepo'
import {ADD_SUPPLIES_SUCCESS_AND_DISPATCH_TO_STORE,DISH_PATCH_GET_LIST_SUPPLIES_BY_USER_ID_TO_STORE,OPEN_EDIT_SUPPLIES_NOTIFICATION,
DISPATCH_ADD_CUSTOMER_TO_STORE,DISPATCH_LIST_CUSTOMER_TO_STORE,
EDIT_CUSTOMER_ACTION,UPDATE_ITEM_DEPARMENT_ACTION
} from '../Contant/Person/supplies'
export const OpenSelectRepo=()=>{
  return{
    type:OPEN_SELECT_REPO
  }
}

export const CloseSelectRepo=()=>{
  return{
    type:CLOSE_SELECT_REPO
  }
}
export const AddSuppliesSuccessAndDispatchToStore=(item)=>{
  return {
    type:ADD_SUPPLIES_SUCCESS_AND_DISPATCH_TO_STORE,
    payload:{item}
  }  
}

export const DispatchGetListSuppliesByUserIdToStore=(item)=>{
  return{
    type:DISH_PATCH_GET_LIST_SUPPLIES_BY_USER_ID_TO_STORE,
    payload:{item}
  }
}

export const AddSuppliesSuccessNotification=()=>{
  return {
    type:ADD_SUPPLIES_SUCCESS_NOTIFICATION    
  }
}

export const OpenEditSuppliseNotification=()=>{
  return {
    type:OPEN_EDIT_SUPPLIES_NOTIFICATION
  }
}

export const DispatchAddCustomerToStore=(item)=>{
  return{
    type:DISPATCH_ADD_CUSTOMER_TO_STORE,
    payload:{item}
  }
}

export const DispatchListCustomerToStore=(item)=>{
  return{
    type:DISPATCH_LIST_CUSTOMER_TO_STORE,
    payload:{item}
  }
}

export const EditCustomerAction =(item)=>{
  return{
    type:EDIT_CUSTOMER_ACTION,
    payload:{item}
  }
}

export const UpdateItemDepartmentAction=(item)=>{
    return{
      type:UPDATE_ITEM_DEPARMENT_ACTION,
      payload:{item}
    }
}