import React,{useState,useEffect} from 'react';
import {Grid,TextField,FormHelperText, Button,InputLabel} from '@material-ui/core';
import {AccountCircle,CropFree,Person,Work,PersonOutline, Save,TransferWithinAStation,Group} from '@material-ui/icons';
import {useStyles} from './styles'
import {useDispatch,useSelector} from 'react-redux'
import {changeUpperCase} from '../../../../../helper/fnAllways/index'
import {EDIT_VIEW_TEMPLETE_FALSE,EDIT_VIEW_TEMPLETE_TRUE, EDIT_ITEM_TEMPLETE} from '../../../../../contant/Gov/login'

const Index=()=> {
  const ItemTemplete = useSelector(state=>state.GovLogin.ItemTemplete)
  const [item,setItem] = useState(ItemTemplete.length>0?ItemTemplete[0]:"")
  useEffect(() => {
      setItem(ItemTemplete.length>0?ItemTemplete[0]:"")
  },[ItemTemplete.length>0?ItemTemplete[0]:""])
  const classes = useStyles();
  const dispatch = useDispatch()
  const EditTemplete = useSelector(state=>state.GovLogin.EditTemplete)
  const [masterOrigin,setMaster] = useState(item.masterOrigin) //co quan chu quan   
  const [codeTxt,setCode]       = useState(item.codeTxt) //ma qhns
  const [namePerson,setNamePerson] = useState(item.namePerson) //giay de nghi thanh toan: ho va ten
  const [detailJob,setDetailJob] = useState(item.detailJob) //giay de nghi thanh toan : noi dung cong viec
  const [namePersonEx,setNamePersonEx] = useState(item.namePersonEx) //phieu xuat kho : ten nguoi nhan hang
  const [resonEx,setResonEx]     = useState(item.resonEx) //phieu xuat kho : ly do xuat kho
  const [codeTmp,setCodeTmp]   = useState(item.codeTmp) //phieu xuat kho : mau so
  const [department,setDepartment] = useState(item.department)//phieu xuat kho :phong ban
  const [err,setErr] = useState('')

  const handleUpdate=()=>{
    dispatch({
      type:EDIT_VIEW_TEMPLETE_TRUE
    })
  }
  const handleSaveUpdate=()=>{
      let Item={
        userId:item.userId,
        _id:item._id,
        masterOrigin,
        codeTxt,
        namePerson,
        detailJob,
        namePersonEx,
        resonEx,
        codeTmp,
        department
      }
      if(Item.masterOrigin===item.masterOrigin&&
          Item.codeTxt    === item.codeTxt&&
          Item.namePerson === item.namePerson&&
          Item.detailJob === item.detailJob&&
          Item.namePersonEx === item.namePersonEx&&
          Item.resonEx   === item.resonEx&&
          Item.codeTmp  === item.codeTmp&&
          Item.department === item.department
        ){         
          return true        
        }        
    dispatch({
      type:EDIT_ITEM_TEMPLETE,
      payload:{Item}
    })
    dispatch({
      type:EDIT_VIEW_TEMPLETE_FALSE
    })
  }
  const handleCancel=()=>{
    dispatch({
      type:EDIT_VIEW_TEMPLETE_FALSE
    })
  }
  return (
    <>
      <div className={classes.margin}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
              <AccountCircle />
            </Grid>
            <Grid item>
              {EditTemplete===true?(
                <TextField                 
                        defaultValue={item.masterOrigin}
                        multiline
                        onChange={e=>setMaster(e.target.value)}
              />
              ):(
               <TextField                 
                        value={item.masterOrigin}
                        multiline
              />
              )}
             
              <FormHelperText id="filled-weight-helper-text">Cơ quan chủ quản</FormHelperText>
            </Grid>
          </Grid>
        </div>

        <div className={classes.margin}>        
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <CropFree />
              </Grid>
              <Grid item>
                {EditTemplete===true?(                  
                          <TextField  
                          onChange={e=>setCode(e.target.value)}
                          defaultValue={item.codeTxt}                      
                />                  
                ):(                 
                   <TextField  
                          value={item.codeTxt}                      
                />                
                )}
               
                <FormHelperText id="filled-weight-helper-text">Mã QHNS</FormHelperText>
              </Grid>
            </Grid>
        </div>

            <div className={classes.margin}>   
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <Person />
                  </Grid>
                  <Grid item>
                    {EditTemplete===true?(
                    <>
                         <InputLabel htmlFor="input-with-icon-adornment">Họ và tên</InputLabel>
                         <TextField id="input-with-icon-grid" 
                                   //label="Họ và tên"
                                   onChange={e=>setNamePerson(e.target.value)}
                                   defaultValue={changeUpperCase(item.namePerson)}              
                                   multiline                                   
                         />
                      </>
                    ):(
                       <TextField id="input-with-icon-grid" 
                              label="Họ và tên"
                              value={changeUpperCase(item.namePerson)}              
                              multiline                                   
                    />
                    )}
                   
                    <FormHelperText id="filled-weight-helper-text">Giấy đề nghị thanh toán</FormHelperText>
                  </Grid>
                </Grid>
            </div>

           <div className={classes.margin}>   
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                  <Work />
                </Grid>
                <Grid item>
                  {EditTemplete===true?(
                    <>
                        <InputLabel htmlFor="input-with-icon-adornment">Nội dung công việc</InputLabel>
                      <TextField id="input-with-icon-grid" 
                          
                            onChange={e=>setDetailJob(e.target.value)}
                            defaultValue={changeUpperCase(item.detailJob)}              
                            multiline        
                  />
                 </>
                  ):(
                    <TextField id="input-with-icon-grid" 
                              label="Nội dung công việc"
                              value={changeUpperCase(item.detailJob)}              
                              multiline        
                    />
                  )}
                  
                  <FormHelperText id="filled-weight-helper-text">Giấy đề nghị thanh toán</FormHelperText>
                </Grid>
              </Grid>
            </div>

          <div className={classes.margin}>   
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <PersonOutline/>
                  </Grid>
                  <Grid item>
                        {EditTemplete===true?(
                          <>
                              <InputLabel htmlFor="input-with-icon-adornment">Họ tên người nhận hàng</InputLabel>
                              <TextField id="input-with-icon-grid" 
                              onChange={e=>setNamePersonEx(e.target.value)}
                              defaultValue={changeUpperCase(item.namePersonEx)}              
                              multiline        
                          />
                      </>
                        ):(
                              <TextField id="input-with-icon-grid" 
                              label="Họ tên người nhận hàng"
                              value={changeUpperCase(item.namePersonEx)}              
                              multiline        
                        />
                        )}                    
                    <FormHelperText id="filled-weight-helper-text">Phiếu xuất kho</FormHelperText>
                  </Grid>
                </Grid>
          </div>
          <div className={classes.margin}>   
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <TransferWithinAStation/>
                  </Grid>
                  <Grid item>
                    {EditTemplete===true?(
                    <>
                        <InputLabel htmlFor="input-with-icon-adornment">Lý do xuất kho</InputLabel>
                        <TextField id="input-with-icon-grid" 
                        onChange={e=>setResonEx(e.target.value)}
                        defaultValue={changeUpperCase(item.resonEx)}              
                        multiline       
                      />
                    </>
                    ):(
                      <TextField id="input-with-icon-grid" 
                              label="Lý do xuất kho"
                              value={changeUpperCase(item.resonEx)}              
                              multiline        
                    />
                    )}
                    
                    <FormHelperText id="filled-weight-helper-text">Phiếu xuất kho</FormHelperText>
                  </Grid>
                </Grid>
          </div>
          <div className={classes.margin}>   
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <CropFree/>
                  </Grid>
                  <Grid item>
                    {EditTemplete===true?(
                      <>
                               <InputLabel htmlFor="input-with-icon-adornment">Mẫu số</InputLabel>
                               <TextField id="input-with-icon-grid" 
                               onChange={e=>setCodeTmp(e.target.value)}
                               defaultValue={item.codeTmp}              
                               multiline                            />
                    </>
                    ):(
                             <TextField id="input-with-icon-grid" 
                             label="Mẫu số"
                             value={item.codeTmp}              
                             multiline                            />
                    )}
                   
                    <FormHelperText id="filled-weight-helper-text">Phiếu xuất kho</FormHelperText>
                  </Grid>
                </Grid>
          </div>
          <div className={classes.margin}>   
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <Group/>
                  </Grid>
                  <Grid item>
                    {EditTemplete===true?(
                        <>
                              <InputLabel htmlFor="input-with-icon-adornment">Bộ phận</InputLabel>
                                  <TextField id="input-with-icon-grid" 
                                  onChange={e=>setDepartment(e.target.value)}
                                  defaultValue={changeUpperCase(item.department)}              
                                  multiline                        
                        />
                     </>
                    ):(
                       <TextField id="input-with-icon-grid" 
                              label="Bộ phận"
                              value={changeUpperCase(item.department)}              
                              multiline                        
                    />
                    )}
                   
                    <FormHelperText id="filled-weight-helper-text">Phiếu xuất kho</FormHelperText>
                  </Grid>
                </Grid>
          </div>      
          <p className={classes.textAlert}>{err}</p>
          <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    {EditTemplete===true?(
                          <>
                                      <Button color="secondary" variant="contained" onClick={()=>handleSaveUpdate()}>Lưu Thay Đổi</Button> &nbsp;
                                      <Button color="primary" variant="contained" onClick={()=>handleCancel()}>Thoát</Button>
                        </>

                    ):(
                                      <Button color="primary" variant="contained" onClick={()=>handleUpdate()}>Chỉnh sửa</Button>
                    )}
                  </Grid>                 
           </Grid>
      </>
  );
}
export default React.memo(Index)