import {call, put,select,takeLatest}from 'redux-saga/effects'
import { loadingSuccess,loadingFail } from '../../../../actions/uiLoading'
import { AddLuyKeNutrikidApi, AddMaterialDateNutrikidApi,  AddMealNutrikidApi,DeleteMealDateNutrikidApi,GetMealDateNutrikidApi, GetPhanBoNguyenLieuThucPhamApi, UpdateLuyKeNutrikidApi, UpdateMealDateNutrikid, UpdateItemPhanBoNguyenLieuThucPhamApi,UpdateItemPhanBoNguyenLieuThucPhamTinhAnApi, TachHocSinhApi, UpdateTachHocSinhApi, GetListTachHocSinhApi, GetCreateBranchDiemTruongApi, AddBranchDiemTruongApi, UpdateAddBranchDiemTruongApi, DeleteBranchDiemTruongApi, DeleteListTachHocSinhApi, ResetCreateGetTachHocSinhApi} from '../api/nutrikid'
import { ADD_MEAL_NUTRIKID, GET_ITEM_DATE_MEAL_NUTRIKID,DISPATCH_UPDATE_DATE_MEAL_NUTRIKID, DELETE_DATE_MEAL_NUTRIKID,ADD_MATERIAL_DATE_NUTRIKID,UPDATE_MASS_IN_MATERIAL_DATE_NUTRIKID, UPDATE_PRICE_LOSE_IN_MATERIAL_DATE_NUTRIKID, UPDATE_PRICE_IN_MATERIAL_DATE_NUTRIKID, UPDATE_REPO_MATERIAL_DATE_NUTRIKID,
DELETE_REPO_MATERIAL_DATE_NUTRIKID,
ADD_LUY_KE,
UPDATE_LUY_KE,
DONG_BO_THUC_DON_TUAN_MEAL,
GET_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM,UPDATE_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM,
UPDATE_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM_TINH_AN,
CREATE_GET_TACH_HOC_SINH,
UPDATE_CREATE_GET_TACH_HOC_SINH,
GET_LIST_TACH_HOC_SINH,
CREATE_GET_BRANCH_DIEM_TRUONG,
ADD_BRANCH_DIEM_TRUONG,
UPDATE_ADD_BRANCH_DIEM_TRUONG,
//DELETE_BRANCH_DIEM_TRUONG,
DELETE_LIST_TACH_HOC_SINH,
UPLOAD_EXCEL_MATERIAL_NUTRIKID,
RESET_CREATE_GET_TACH_HOC_SINH
} from '../contant/nutrikid'
import {DispatchAddMaterialDateNutrikidToStore, DispatchAddMealNutrikidToStore,DispatchGetMealNutrikidToStore, DispatchLuyKeToStore,DispatchItemPhanBoNguyenLieuThucPhamToStore, DispatchCreateGetTachHocSinh, DispatchUpdateCreateGetTachHocSinh, DispatchGetListTachHocSinh, DispatchCreateGetBranchDiemTruong, DispatchAddBranchDiemTruong, UploadExcelMaterialNutrikidNullToStore} from '../action/nutrikid'
import { updateDateMeal } from '../../../../api-services/weeklyFood/dateMeal'

const SUCCESS = 'success'
//const FAIL    = 'fail'


function* AddMealNutrikidSaga({payload}){
    //yield put(loadingSuccess())
    let res = yield call(AddMealNutrikidApi,payload.item)
    if(res.data.result===SUCCESS){
       // yield put(loadingFail())
        yield put(DispatchAddMealNutrikidToStore(res.data.data))
     }
}

function* GetItemDateMealNutrikidSaga({payload}){
    //yield put(loadingSuccess())
    let res = yield call(GetMealDateNutrikidApi,payload.item.Item)
    if(res.data.result===SUCCESS){
       // yield put(loadingFail())
        yield put(DispatchGetMealNutrikidToStore(res.data.data))
     }
}

function* DispatchUpdateMealDateNutrikidSaga({payload}){
   // yield put(loadingSuccess())
     let res = yield call(UpdateMealDateNutrikid,payload.item)
    if(res.data.result===SUCCESS){
      //  yield put(loadingFail())
     }         
}

function* DeleteDateMealNutrikidSaga({payload}){
  //  yield put(loadingSuccess())
    let res = yield call(DeleteMealDateNutrikidApi,payload.item)
    if(res.data.result===SUCCESS){
    //    yield put(loadingFail())
     }   
}

function* AddMaterialNutrikidSaga({payload}){
   // yield put(loadingSuccess())
    let res= yield call(AddMaterialDateNutrikidApi,payload.item)
    if(res.data.result===SUCCESS){
    //    yield put(loadingFail())
        yield put(DispatchAddMaterialDateNutrikidToStore(res.data.data))
     }   
}

function* UpdateMassInMaterialDateNutrikidSaga({payload}){    
     yield call(UpdateMealDateNutrikid,payload.item)    
}
function* UpdatePriceLoseInMaterialDateNutrikidSaga({payload}){    
yield call(UpdateMealDateNutrikid,payload.item)
}
function* UpdatePriceInMaterialDateNutrikidSaga({payload}){   
     yield call(UpdateMealDateNutrikid,payload.item)

 }
function* UpdateRepoMaterialDateNutrikidSaga({payload}){    
     yield call(UpdateMealDateNutrikid,payload.item)
     
}

function* DeleteRepoMaterialDateNutrikidSaga({payload}){
    yield call(UpdateMealDateNutrikid,payload.item)   
}

function* AddLuyKeSaga({payload}){
    let res = yield call(AddLuyKeNutrikidApi,payload.item)
    if(res.data.result===SUCCESS){
      //  yield put(loadingFail())
        yield put(DispatchLuyKeToStore(res.data.data))
     }   
}

function* UpdateLuyKeSaga({payload}){
  //  yield put(loadingSuccess())
    let res = yield call(UpdateLuyKeNutrikidApi,payload.item)
    if(res.data.result===SUCCESS){
    //    yield put(loadingFail())
    }   
}

function* DongBoThucDonMealSaga({payload}){
  //  yield put(loadingSuccess())
  let res = yield call(updateDateMeal,payload.item) 
  if(res.data.result==='success'){
   // yield put(loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }  
}
function* GetItemPhanBoNguyenLieuThucPhamSaga({payload}){
    let res = yield call(GetPhanBoNguyenLieuThucPhamApi,payload.item)
    if(res.data.result==='success'){
        yield put(DispatchItemPhanBoNguyenLieuThucPhamToStore(res.data.data))
      }else{
        alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
      }  
}

function* UpdateItemPhanBoNguyenLieuThucPhamSaga({payload}){
        yield call(UpdateItemPhanBoNguyenLieuThucPhamApi,payload.item)
        //console.log(res)
}

function* UpdateItemPhanBoNguyenLieuThucPhamTinhAnSaga({payload}){
    yield call(UpdateItemPhanBoNguyenLieuThucPhamTinhAnApi,payload.item)
}

function* CreateGetTachHocSinhSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(TachHocSinhApi,payload.item)
    if(res.data.result==='success'){
        yield put(DispatchCreateGetTachHocSinh(res.data.data))
        yield put(loadingFail())
      }  
}

function* UpdateCreateGetTachHocSinhSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(UpdateTachHocSinhApi,payload.item)
    if(res.data.result==='success'){
        //yield put(DispatchUpdateCreateGetTachHocSinh(res.data.data))
        yield put(loadingFail())
      }  
}

function* GetListTachHocSinhSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(GetListTachHocSinhApi,payload.item)
    if(res.data.result==='success'){
        yield put(DispatchGetListTachHocSinh(res.data.data))
        yield put(loadingFail())
    } 
}

function* CreateGetBranchDiemTruongSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(GetCreateBranchDiemTruongApi,payload.item)
    if(res.data.result==='success'){
        yield put(DispatchCreateGetBranchDiemTruong(res.data.data))
        yield put(loadingFail())
    }    
}

function* AddBranchDiemTruongSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(AddBranchDiemTruongApi,payload.item)
    if(res.data.result==='success'){
        yield put(DispatchAddBranchDiemTruong(res.data.data))
        yield put(loadingFail())
    }    
}

function* UpdateAddBranchDiemTruongSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(UpdateAddBranchDiemTruongApi,payload.item)
    if(res.data.result==='success'){
        yield put(loadingFail())
    }    
}
function* DeleteListTachHocSinhSaga({payload}) {
    yield put(loadingSuccess())
    let res = yield call(DeleteListTachHocSinhApi,payload.item)
    if(res.data.result==='success'){
        yield put(loadingFail())
    }    
}

function* UploadExcelMaterialNutrikidSaga({payload}){
    let {ResultMaterial} = payload.item
    let ItemTableMeal    = yield select(state=>state.ProcessNutrikid.ItemTableMeal)
    let item = [ItemTableMeal].map(v=>{
            return{
                ...v,
                materials:ResultMaterial
            }
    })[0]
     let res= yield call(AddMaterialDateNutrikidApi,item)
     if(res.data.result===SUCCESS){
        yield put(loadingFail())
        yield put(DispatchAddMaterialDateNutrikidToStore(res.data.data))
     }    
}

function* ResetCreateGetTachHocSinhSaga({payload}){
    yield put(loadingSuccess())
  let res =  yield call(ResetCreateGetTachHocSinhApi,payload.item)
    if(res.data.result==='success'){
        yield put(loadingFail())
    }    
}

function* NutrikidSaga(){       
    
    yield takeLatest(RESET_CREATE_GET_TACH_HOC_SINH,ResetCreateGetTachHocSinhSaga)
        yield takeLatest(UPLOAD_EXCEL_MATERIAL_NUTRIKID,UploadExcelMaterialNutrikidSaga)        
        yield takeLatest(DELETE_LIST_TACH_HOC_SINH,DeleteListTachHocSinhSaga)        
        yield takeLatest(UPDATE_ADD_BRANCH_DIEM_TRUONG,UpdateAddBranchDiemTruongSaga)
        yield takeLatest(ADD_BRANCH_DIEM_TRUONG,AddBranchDiemTruongSaga)
        yield takeLatest(CREATE_GET_BRANCH_DIEM_TRUONG,CreateGetBranchDiemTruongSaga)
        yield takeLatest(GET_LIST_TACH_HOC_SINH,GetListTachHocSinhSaga)
        yield takeLatest(UPDATE_CREATE_GET_TACH_HOC_SINH,UpdateCreateGetTachHocSinhSaga)
        yield takeLatest(CREATE_GET_TACH_HOC_SINH,CreateGetTachHocSinhSaga)
        yield takeLatest(ADD_MEAL_NUTRIKID,AddMealNutrikidSaga)
        yield takeLatest(GET_ITEM_DATE_MEAL_NUTRIKID,GetItemDateMealNutrikidSaga)
        yield takeLatest(DISPATCH_UPDATE_DATE_MEAL_NUTRIKID,DispatchUpdateMealDateNutrikidSaga)
        yield takeLatest(DELETE_DATE_MEAL_NUTRIKID,DeleteDateMealNutrikidSaga)
        yield takeLatest(ADD_MATERIAL_DATE_NUTRIKID,AddMaterialNutrikidSaga)
        yield takeLatest(UPDATE_MASS_IN_MATERIAL_DATE_NUTRIKID,UpdateMassInMaterialDateNutrikidSaga)
        yield takeLatest(UPDATE_PRICE_LOSE_IN_MATERIAL_DATE_NUTRIKID,UpdatePriceLoseInMaterialDateNutrikidSaga)
        yield takeLatest(UPDATE_PRICE_IN_MATERIAL_DATE_NUTRIKID,UpdatePriceInMaterialDateNutrikidSaga)
        yield takeLatest(UPDATE_REPO_MATERIAL_DATE_NUTRIKID,UpdateRepoMaterialDateNutrikidSaga)
        yield takeLatest(DELETE_REPO_MATERIAL_DATE_NUTRIKID,DeleteRepoMaterialDateNutrikidSaga)
        yield takeLatest(ADD_LUY_KE,AddLuyKeSaga)
        yield takeLatest(UPDATE_LUY_KE,UpdateLuyKeSaga)
        yield takeLatest(DONG_BO_THUC_DON_TUAN_MEAL,DongBoThucDonMealSaga)
        yield takeLatest(GET_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM,GetItemPhanBoNguyenLieuThucPhamSaga)
        yield takeLatest(UPDATE_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM,UpdateItemPhanBoNguyenLieuThucPhamSaga)
        yield takeLatest(UPDATE_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM_TINH_AN,UpdateItemPhanBoNguyenLieuThucPhamTinhAnSaga)
}

export default NutrikidSaga