import {call,put,takeLatest} from 'redux-saga/effects'
import { LoadingComponentFalse, LoadingComponentOpen } from '../../action/Loading'
import { CreateNewAccountQuanTriFail, CreateNewAccountQuanTriSuccess,CloseFormAfterCreateSuccess, GetAllAccountToStoreQuanTri,  LoginPageQuanTriFail,  LoginPageQuanTriSuccess, UpdateAccountLoginQuanTriSuccess, UpdateAccountLoginQuanTriFail } from '../../action/LOGIN_PAGE'
import { CreateNewAccountQuanTriApi,CreateAccountQuanTriApi, LoginToDatabaseCongNgheApi, UpdateAccountLoginQuanTriApi } from '../../api/ROOT_CONG_NGHE/LoginPageQuanTri'
import { CREATE_ACCOUNT_QUAN_TRI, CREATE_NEW_ACCOUNT_QUAN_TRI, UPDATE_ACCOUNT_LOGIN_QUAN_TRI } from '../../contant/LOGIN_PAGE/QuanTri'
import { DISPATCH_LOGIN_TO_DATABASE_CONG_NGHE } from '../../contant/ThietBiCongNghe'

const FAIL    = 'fail'
const SUCCESS = 'success'

function* CreateNewAccountQuanTriSaga({payload}){
    yield put(LoadingComponentOpen())
    let res = yield call(CreateNewAccountQuanTriApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(CreateNewAccountQuanTriSuccess(res.data.data))
        yield put(LoadingComponentFalse())
        yield put(CloseFormAfterCreateSuccess())
    }
    if(res.data.result===FAIL){
        yield put(CreateNewAccountQuanTriFail(res.data.data))
        yield put(LoadingComponentFalse())
    }
}

function* CreateAccountQuanTriSaga({}){
    yield put(LoadingComponentOpen())
    let res = yield call(CreateAccountQuanTriApi)
    if(res.data.result===SUCCESS){
        yield put(GetAllAccountToStoreQuanTri(res.data.data))
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* DispatchLoginToDatabaseCongNgheSaga({payload}){
    yield put(LoadingComponentOpen())
    let res = yield call(LoginToDatabaseCongNgheApi,payload.item)
    if(res.data.result===SUCCESS){  
        yield put(LoginPageQuanTriSuccess(res.data.data))
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoginPageQuanTriFail(res.data.data))
        yield put(LoadingComponentFalse())        
    }
}

function* UpdateAccountLoginQuanTriSaga({payload}){
    yield put(LoadingComponentOpen())
    let res = yield call(UpdateAccountLoginQuanTriApi,payload.item)
    if(res.data.result===SUCCESS){  
        yield put(UpdateAccountLoginQuanTriSuccess(res.data.data))
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(UpdateAccountLoginQuanTriFail(res.data.data))
        yield put(LoadingComponentFalse())        
    }
}

function* IndexSaga(){
    yield takeLatest(CREATE_NEW_ACCOUNT_QUAN_TRI,CreateNewAccountQuanTriSaga)
    yield takeLatest(CREATE_ACCOUNT_QUAN_TRI,CreateAccountQuanTriSaga)
    yield takeLatest(DISPATCH_LOGIN_TO_DATABASE_CONG_NGHE,DispatchLoginToDatabaseCongNgheSaga)
    yield takeLatest(UPDATE_ACCOUNT_LOGIN_QUAN_TRI,UpdateAccountLoginQuanTriSaga)
}

export default IndexSaga