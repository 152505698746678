import React, { Component } from 'react';
import { connect } from 'react-redux';
import {compose} from 'redux'
import {Route,Redirect} from 'react-router-dom'
import DashBoardGov from '../../ComponentGov/Dashboard/index'
//import axiosServices from '../../api-services/api'
import {AUTHORIZATION_KEY} from '../../contant/index'



class index extends Component {
  UNSAFE_componentWillMount(){
      // const token = localStorage.getItem(AUTHORIZATION_KEY)
      // if(token){
      //   axiosServices.setHeader('Authorization',`PMT ${token}`)
      // }
      window.addEventListener('beforeunload',(e)=>{
        e.preventDefault()
        e.returnValue=""
     })
  }

  render() {  
    const {component : YourComponent,...remainProps} = this.props 
    let {loginUser} = this.props
    const token = localStorage.getItem(AUTHORIZATION_KEY)   
   
    return (
        <Route
            {...remainProps}
            render={routeProps=>{                       
                return (token&&loginUser.idGroup===2) ? (
                    <DashBoardGov {...remainProps}>
                      <YourComponent {...routeProps}/>
                    </DashBoardGov>
                ):(
                  <Redirect to="/login"/>
                )
            }}
        />
    );
  }
}

const mapStateToProps = state =>{
  return{
      loginUser: state.users.loginUser
  }
}

const mapDispatchStateToProps =  null

const withConnect = connect(
  mapStateToProps,
  mapDispatchStateToProps
)

export default compose(
  withConnect
)(index);