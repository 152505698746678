import React from 'react'
import TextField from '@material-ui/core/TextField'
import {useStyles} from './style'
import SearchIcon from '@material-ui/icons/Search';

const Find = ({searchFn,openFind})=>{
    const classes = useStyles()       
    const handleSearch = (e)=>{                
          searchFn(e.target.value)
    }
    return (
          <form noValidate="off" open={openFind} className={classes.background}>
            <div>             
            <TextField                 
                  label={<SearchIcon/>}
                  type="search"                  
                  margin="normal"
                  name="search"
                  className={classes.searchHeader}
                  onChange={handleSearch}
                  autoComplete="off"
            />            
            </div>            
          </form>
    )  
}

export default Find