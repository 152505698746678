import {DISPATCH_EXIST_NAME_LOGIN_TO_STORE,DISPATCH_ADD_STAFF_TO_STORE,DISPATCH_GET_LIST_INFO_STAFF,DISPATCH_ADD_PAYMENT_STAFF_TO_STORE,
DISPATCH_GET_LIST_PAYMENT_STAFF_TO_STORE,DISPATCH_ITEM_EDIT_ACCOUNT_STAFF_TO_STORE,DISPATCH_ADD_LOCATION_TO_STORE,DISPATCH_GET_LIST_LOCATION,
DISPATCH_GET_CHAM_CONG_BY_USERID_TO_STORE,DISPATCH_ADD_TASK_JOB_STORE,DISPATCH_TASK_JOBS_TO_STORE,UPDATE_TASK_JOB_PAYMENT,LIST_PRICE_PAYMENT,UPDATE_LIST_PRICE_PAYMENT,
UPDATE_LIST_TASK_JOB_MANGER_STAFF
} from '../contant'
export const DispatchExistNameLoginToStore=(item)=>{
  return{
    type:DISPATCH_EXIST_NAME_LOGIN_TO_STORE,
    payload:{item}
  }
}
export const DispatchAddStaffToStore=(item)=>{
    return {
      type:DISPATCH_ADD_STAFF_TO_STORE,
      payload:{item}
    }
}

export const DispatchGetListInfoStaff=(item)=>{
  return{
    type:DISPATCH_GET_LIST_INFO_STAFF,
    payload:{item}
  }
}

export const DispatchAddPaymentStaffToStore=(item)=>{
    return{
      type:DISPATCH_ADD_PAYMENT_STAFF_TO_STORE,
      payload:{item}
    }
}

export const DispatchGetListPaymentStaffToStore=(item)=>{
    return{
      type:DISPATCH_GET_LIST_PAYMENT_STAFF_TO_STORE,
      payload:{item}
    }
}

export const DispatchItemEditAccountStaffToStore=(item)=>{
  return{
    type:DISPATCH_ITEM_EDIT_ACCOUNT_STAFF_TO_STORE,
    payload:{item}
  }
}

export const DispatchAddLocationToStore=(item)=>{
  return{
    type:DISPATCH_ADD_LOCATION_TO_STORE,
    payload:{item}
  }
}

export const DispatchGetListLocation=(item)=>{
  return{
    type:DISPATCH_GET_LIST_LOCATION,
    payload:{item}
  }
}

export const DispatchGetChamCongByUserIdToStore=(item)=>{
  return{
    type:DISPATCH_GET_CHAM_CONG_BY_USERID_TO_STORE,
    payload:{item}
  }
}

export const DispatchAddTaskJobToStore=(item)=>{
  return {
    type:DISPATCH_ADD_TASK_JOB_STORE,
    payload:{item}
  }
}

export const DispatchTaskJobsToStore=(item)=>{
    return{
      type:DISPATCH_TASK_JOBS_TO_STORE,
      payload:{item}
    }
}

export const UpdateTaskJobPayment=(item)=>{
  return{
    type:UPDATE_TASK_JOB_PAYMENT,
    payload:{item}
  }
}

export const ListPricePayment=(item)=>{
  return{
    type:LIST_PRICE_PAYMENT,
    payload:{item}
  }
}

export const UpdateListPricePayment=(item)=>{
  return{
    type:UPDATE_LIST_PRICE_PAYMENT,
    payload:{item}
  }
}

export const UpdateListTaskJobManagerStaff=(item)=>{
  return{
    type:UPDATE_LIST_TASK_JOB_MANGER_STAFF,
    payload:{item}
  }
}