import {UPDATE_JOB_STAFF_NHAN_VIEN_STORE, DISPATCH_GET_INFO_CUS_NHAN_VIEN_TO_STORE,DISPATCH_ADD_JOB_STAFF_NHAN_VIEN_STORE ,DISPATCH_SEARCHING_GET_JOB_STAFF_NHAN_VIEN} from "../contant/LOGIN_PAGE/OpenNavBarNhanVien";

export const UpdateJobStaffNhanVienStore=(item)=>{
    return{
        type:UPDATE_JOB_STAFF_NHAN_VIEN_STORE,
        payload:{item}
    }
}
export const DispatchGetSearchingJobStaffNhanVien=(item)=>{
    return{
        type:DISPATCH_SEARCHING_GET_JOB_STAFF_NHAN_VIEN,
        payload:{item}
    }
}
export const DispatchAddJobStaffNhanVienStore=(item)=>{
    return{
        type:DISPATCH_ADD_JOB_STAFF_NHAN_VIEN_STORE,
        payload:{item}
    }
}
export const DispatchGetInfoCusNhanVienToStore=(item)=>{
    return{
        type:DISPATCH_GET_INFO_CUS_NHAN_VIEN_TO_STORE,
        payload:{item}
    }
}

