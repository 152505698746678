import io from 'socket.io-client'
import {API_ENDPOINT} from '../../../../contant'
let socket
socket = io(API_ENDPOINT)
export const ActiveAccount = ()=>{
         socket.on('ActiveAccountUserId',data=>{
             console.log(data)
         })         
     
         return ()  =>{
          socket.emit('disconnect')
          socket.off()
      }       
}
//socket doi phe duyet tu tai khoan ke toan
export const RequestAcceptManagerAccount =(data)=>{
    socket.emit('RequestAcceptManagerAccount',data)
    
    
    return ()=>{
        socket.emit('disconnect')
        socket.off()
    }
}

export const RequestApprovalClass=(data)=>{
    socket.emit('RequestApprovalClass',data)    
    
    return ()=>{
        socket.emit('disconnect')
        socket.off()
    }
}

export const RequestApprovalClassFromManagerClass=(data)=>{
    socket.emit('RequestApprovalClassFromManagerClass',data)    
    
    return ()=>{
        socket.emit('disconnect')
        socket.off()
    }
}

export const RequestManagerClassToManagerAccount=(data)=>{
            socket.emit('RequestManagerClassToManagerAccount',data)
            return ()=>{
                socket.emit('disconnect')
                socket.off()
            }

}

//note : managerAccount: tk ke toan
            // mamangerClass : tk hieu pho, cap duong