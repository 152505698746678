import {fork,all}     from 'redux-saga/effects'
import activeFoodSaga from './activeFood'
import dateInputSaga  from './weekyFood/dateInput'
import costStudent    from './cost/costStudent'
import costMaterial   from './cost/material'
import studentSaga    from './student/student'
import InfoCusSaga    from './infoCus/infoCus'
import RegisSevenSaga from './student/regisSevenDay'
import TransferSaga   from './transfer'
import NutrikidsSaga  from '../../ComponentGov/SoftFoodTabMain/Nutrikid/saga'
import CanDoSucKhoeSaga from '../../ComponentGov/SoftFoodTabMain/CanDoSucKhoe/saga'

// Router Webb
import  GioiThieuSaga from '../../container/Login/NotFoundPage/saga/GioiThieu'
import  ZaloAppSaga    from '../../container/Login/NotFoundPage/saga/zalo'

function* indexGovSaga(){
  yield all(
    [
      yield fork(activeFoodSaga),     
      yield fork(dateInputSaga),
      yield fork(costStudent),
      yield fork(costMaterial),
      yield fork(studentSaga),
      yield fork(InfoCusSaga),
      yield fork(RegisSevenSaga),
      yield fork(TransferSaga),
      yield fork(NutrikidsSaga),
      yield fork(CanDoSucKhoeSaga),

      //RouterWeb Saga
      yield fork(GioiThieuSaga),
      yield fork(ZaloAppSaga)
    ]
  )
}

export default indexGovSaga