import axiosServices from './api'
import {API_ENDPOINT} from '../contant/index'

const urlGetUser = 'getUserByIdGroupNhaNuoc'
const urlInfoUser = 'info-user' 
const urlDeleteArrayByIdInfoUser = 'info-user'
const urlPutUserId = 'info-user'
const urlListByUserIdGov = 'info-user/userId'
const urlGetListAllUser = 'getListAllUser'
const urlGetListDepartment = 'department'
const urlDeleteArrayUserIdInDepartmentStaff = 'department'
const urlUpdateArrayUserIdInDepartmentStaff = 'department'
const urlActiveSoftFood = 'info-user/soft-active-food'

//FormSignRegis ten ca chuc danh
const urlAddFormSignRegis     = 'info-user/addFormSignRegis'
const urlGetFormSignRegis     = 'info-user/getFormSignRegis'
const urlUpdateFormSignRegis  = 'info-user/updateFormSignRegis'



//infoUserDetail
const urlAddInfoUserDetail        = "info-user-detail"
const urlListByUserInfoUserDetail = "info-user-detail/userId"
const urlEditItemInfoUserDetail   = "info-user-detail/editItem"
const urlEditArrInfoUserDetail   = "info-user-detail/editArr"
const urlDeleteInfoUserDetail   = "info-user-detail/delete"
//infoUserDeatail
//templete
const urlAddTemplete          = "templete"
const urlListTemplete         = "templete/userId"
const urlUpdateTemplete       = "templete/userId"

//templete
//reset pass
const urlResetPassword        = "user/resetPass/userId"
const urlResetAccount         =  "user/resetAccount"
const urlAlertResetAccount    =  "user/resetAccount/alertSuccess"
const urlUpdateTypeOf_0_Payment = 'updateTypeOf0Payment'
export const ActiveSoftFood = (userId)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlActiveSoftFood}`,userId)
}

export const getUser = ()=>{
  return axiosServices.get(`${API_ENDPOINT}/${urlGetUser}`)
}

export const addInfoUser = (data)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlInfoUser}`,data)
}

export const getInfoUser = ()=>{
  return axiosServices.get(`${API_ENDPOINT}/${urlInfoUser}`)
}

export const updateInfoUserByUserId = (data)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlPutUserId}`,data)
}

export const deleteByArrayIdInfoUser =(data)=>{
    return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteArrayByIdInfoUser}`,data)
}

export const ListAllUser = ()=>{
  return axiosServices.get(`${API_ENDPOINT}/${urlGetListAllUser}`)
}

export const listDepartment = (data)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetListDepartment}`,data)
}
export const deleteArrayUserIdInDepartmentStaff =(data)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteArrayUserIdInDepartmentStaff}`,data)
}
export const updateArrayUserIdInDepartmentStaff =(data)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateArrayUserIdInDepartmentStaff}`,data)
}

export const getListDepartment = ()=>{
  return axiosServices.get(`${API_ENDPOINT}/${urlGetListDepartment}`)
}

export const listByUserIdGov =(id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListByUserIdGov}`,id)
}

export const addInfoUserDetail = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddInfoUserDetail}`,item)
}

export const ListByUserIdInfoUserDetail=(id)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlListByUserInfoUserDetail}`,id)
}

export const EditItemInfoUserDetail = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlEditItemInfoUserDetail}`,item)
}
export const EditArrInfoUserDetail =(arr)=>{
    return axiosServices.put(`${API_ENDPOINT}/${urlEditArrInfoUserDetail}`,arr)
}
export const DeleteInfoUserDetail =(item)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteInfoUserDetail}`,item)
}

export const AddTemplete =(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddTemplete}`,item)
}
export const ListTemplete =(id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListTemplete}`,id)
}
export const UpdateTemplete =(item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateTemplete}`,item)
}

export const ResetPassword = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlResetPassword}`,item)
}
export const ResetAccount=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlResetAccount}`,item)
}
export const AlertResetAccount=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAlertResetAccount}`,item)
}

export const AddFormSignRegis=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddFormSignRegis}`,item)
}

export const GetFormSignRegisByUserId=(item)=>{
   return axiosServices.post(`${API_ENDPOINT}/${urlGetFormSignRegis}`,item)
}

export const UpdateFormSignRegisByUserId=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlUpdateFormSignRegis}`,item)
}

export const UpdateTypeOf_0_PaymentApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlUpdateTypeOf_0_Payment}`,item)
}