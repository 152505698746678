import React, { Suspense, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import NavBarComponent from './component/CONG_NGHE/NAV_BAR'
import TextSlideComponent from './component/CONG_NGHE/TEXT_SLIDE'

import FormLoginComponent from './component/CONG_NGHE/FORMLOGIN_REGIS/Login'
import { CLOSE_FORM_LOGIN_REGIS } from './contant/ThietBiCongNghe';
 import LoadingComponent     from  './component/Loading'
import AlertSuccessComponent from './component/Alert/AlertSuccess'
import AlertFailComponent from './component/Alert/AlertFail'
import { CLOSE_NEW_ACCOUNT_QUANTRI_FAIL, CLOSE_NEW_ACCOUNT_QUANTRI_SUCCESS, CREATE_ACCOUNT_QUAN_TRI } from './contant/LOGIN_PAGE/QuanTri';

const ThietBiCongNgheComponent = React.lazy(()=>import('./component/CONG_NGHE/CONTENT/THIET_BI')) 
const  LoginPageComponent      = React.lazy(()=>import('./component/LOGIN_PAGE')) 

const ThietBiCongNghe=({})=> {
  const dispatch                         = useDispatch()
  const OpenFormLoginRegis               = useSelector(state=>state.ThietBiCongNghe.OpenFormLoginRegis)
  const ShowComponentQuanTri             = useSelector(state=>state.LoginPageQuanTri.ShowComponentQuanTri)

  const Loading       = useSelector(state=>state.LoginPageQuanTri.Loading)
  const AlertSuccess  = useSelector(state=>state.LoginPageQuanTri.AlertSuccess)
  const AlertFail     = useSelector(state=>state.LoginPageQuanTri.AlertFail)
  const TextAlert     = useSelector(state=>state.LoginPageQuanTri.TextAlert)

  useState(()=>{
    //tao tai khoan sieu quan tri va lay ds account
    dispatch({type:CREATE_ACCOUNT_QUAN_TRI})
},[])
  return (
    <>
    {Loading&&
         <LoadingComponent open={Loading}
                           text={TextAlert}

         />
    }

    {AlertFail&&
      <AlertFailComponent open={AlertFail}
                          text={TextAlert}
                          handleClose={()=>{
                            dispatch({type:CLOSE_NEW_ACCOUNT_QUANTRI_FAIL})
                          }}
      />
    }

    {AlertSuccess&&
      <AlertSuccessComponent  open={AlertSuccess}
                              text={TextAlert}
                              handleClose={()=>{
                                dispatch({type:CLOSE_NEW_ACCOUNT_QUANTRI_SUCCESS})
                              }}
      />
    }

      {OpenFormLoginRegis&&<FormLoginComponent
        open={OpenFormLoginRegis}
        handleClose={()=>dispatch({type:CLOSE_FORM_LOGIN_REGIS})}
      />}
      <NavBarComponent/>        
      <TextSlideComponent/>
     
      {!ShowComponentQuanTri&&
        <Suspense fallback={<></>}>
                <ThietBiCongNgheComponent/>
        </Suspense>
      }      
      {ShowComponentQuanTri&&
        <Suspense fallback={<></>}>
                <LoginPageComponent/>
        </Suspense>
      }
    </>
  );
}

export default ThietBiCongNghe;