import {call, put,select, takeLatest} from 'redux-saga/effects'
import { DispatchAddHocSinhCanDoToStore, DispatchCreateCanDoToStore, DispatchListHocSinhCanDoToStore, DispatchUpdateHocSinhCanDoAgain, UploadFileCanDoFail } from '../action'
import { AddUploadExcelHocSinhCanDoApi, CreateCanDoSucKhoeApi, DeleteHocSinhCanDoApi, GetListHocSinhCanDoApi, UpdateCanDoSucKhoeApi, UpdateHocSinhCanDoAgainApi, UpdateHocSinhCanDoApi } from '../api'
import { ADD_UPLOAD_FILE_HOC_SINH_CAN_DO, CREATE_CAN_DO_SUC_KHOE, CREATE_HOC_SINH_CAN_DO, DELETE_HOC_SINH_CAN_DO, GET_LIST_HOC_SINH_CAN_DO, UPDATE_CAN_DO_SUC_KHOE, UPDATE_HOC_SINH_CAN_DO, UPDATE_HOC_SINH_CAN_DO_AGAIN } from '../contant'
import { HandleFilterListHocSinhCanDo, HandleNewArrCanDo } from './fn'

function* CreateCanDoSucKhoeSaga({}){
    let res = yield call(CreateCanDoSucKhoeApi)
    if(res.data.result==='success'){    
        yield put(DispatchCreateCanDoToStore(res.data.data))
    }
}

function* UpdateCanDoSucKhoeSaga({payload}){
    let res = yield call(UpdateCanDoSucKhoeApi,payload.item)
    if(res.data.result==='success'){    
        yield put(DispatchCreateCanDoToStore(res.data.data))
    }
}

function* AddUploadFileHocSinhCanDoSaga({payload}){
    let userId = yield select(state=>state.GovLogin.userId)
    let TimeCanDo = yield select(state=>state.CanDoSucKhoe.TimeCanDo)
    let newArr    = HandleNewArrCanDo(payload.item,userId,TimeCanDo)
    let res = yield call(AddUploadExcelHocSinhCanDoApi,newArr)
    if(res.data.result==='success'){    
        yield put(DispatchAddHocSinhCanDoToStore(res.data.data))
    }
    if(res.data.result==='fail'){
        yield put(UploadFileCanDoFail())
    }
}    

function* CreateHocSinhCanDoSaga({payload}){
    let res = yield call(AddUploadExcelHocSinhCanDoApi,payload.item)
    if(res.data.result==='success'){    
        yield put(DispatchAddHocSinhCanDoToStore(res.data.data))
    }
}

function* GetListHocSinhCanDoSaga({payload}){
    let res = yield call(GetListHocSinhCanDoApi,payload.item)
    if(res.data.result==='success'){    
        let result = HandleFilterListHocSinhCanDo(res.data.data,payload.item)
        yield put(DispatchListHocSinhCanDoToStore(result))
    }   
}

function* UpdateHocSinhCanDoSaga({payload}){
     yield call(UpdateHocSinhCanDoApi,payload.item)
}

function* UpdateHocSinhCanDoAgainSaga({payload}){
    let res = yield call(UpdateHocSinhCanDoAgainApi,payload.item)
    if(res.data.result==='success'){    
        yield put(DispatchUpdateHocSinhCanDoAgain(res.data.data))
    }   
}

function* DeleteHocSinhCanDoSaga({payload}){
     yield call(DeleteHocSinhCanDoApi,payload.item)
}

function* IndexSaga(){
    yield takeLatest(CREATE_CAN_DO_SUC_KHOE,CreateCanDoSucKhoeSaga)
    yield takeLatest(UPDATE_CAN_DO_SUC_KHOE,UpdateCanDoSucKhoeSaga)
    yield takeLatest(ADD_UPLOAD_FILE_HOC_SINH_CAN_DO,AddUploadFileHocSinhCanDoSaga)
    yield takeLatest(GET_LIST_HOC_SINH_CAN_DO,GetListHocSinhCanDoSaga)
    yield takeLatest(UPDATE_HOC_SINH_CAN_DO,UpdateHocSinhCanDoSaga)
    yield takeLatest(UPDATE_HOC_SINH_CAN_DO_AGAIN,UpdateHocSinhCanDoAgainSaga)
    yield takeLatest(DELETE_HOC_SINH_CAN_DO,DeleteHocSinhCanDoSaga)
    yield takeLatest(CREATE_HOC_SINH_CAN_DO,CreateHocSinhCanDoSaga)

}

export default IndexSaga