import styled,{keyframes} from "styled-components";
import {device} from '../../../device'
import { MainColor } from "../../color";

export const gradient = keyframes`
    100% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    0% {
        background-position: 0% 0%;
    }
`

export const MainContent = styled.div`
        width:100%;
        height:800px;
        background:#171616;
        margin:auto;
        padding:50px;        
        &:hover{
            transition-delay:2s;
            background: linear-gradient(315deg,#34495e 10%, rgba(0, 0, 0, 0.87) 38%,  #7f8c8d  68%,#16a085 85%, rgba(0, 0, 0, 0.87) 98%);
            animation: ${gradient} 15s ease infinite;
            background-size: 200% 200%;
            background-attachment: fixed;
        }
        @media ${device.mobile}{
        padding:0;  
        height:650px; 
        margin-bottom:300px; 
        }
`

export const MainTinhUuViet = styled.div`
            background:#171616;  
            padding-top:20px;
            padding-bottom:50px;      
            &:hover{
            transition-delay:2s;
            background: linear-gradient(315deg,#34495e 10%, rgba(0, 0, 0, 0.87) 38%,  #7f8c8d  68%,#16a085 85%, rgba(0, 0, 0, 0.87) 98%);
            animation: ${gradient} 15s ease infinite;
            background-size: 200% 200%;
            background-attachment: fixed;
          }
`

export const Content = styled.div`
    width:100%;
    background:#171616;
    height:100%;
    display:flex;
    margin:auto;
    @media ${device.mobile}{
        display:block;       
    }
`

export const ContentLeft = styled.div`
    width:55%;
    background:#171616;
    height:100%;
    @media (max-width:768px){
        display:block;
        width:100%;
        height:64%;
    }
    @media (min-width:768px)and(max-width:1024px){       
        height:50%;
    }
`

export const ContentRight = styled.div`
    width:45%;
    background:${MainColor};
    ${'' /* background:linear-gradient(0,#4cb2b6,#27ae60); */}
    ${'' /* background:#4cb2b6; */}
    height:100%;
    @media ${device.mobile}{
        display:block;
        width:100%;
        height:85%;
    }
`

export const HeaderText = styled.h2`
        color:${MainColor};
        font-size:30px;
        font-weight:bold;
        text-align:center;
        @media (max-width:768px){
            font-size:25px;
            margin-top:-1px;
        }
      
        @media (min-width:769px) and(max-width:1024px){
            font-size:15px;
            margin-top:68px;
        }
`

export const HeaderTextIn = styled.h4`
        color:#95a5a6;
        font-size:25px;
        font-weight:bold;
        text-align:center;
        margin-top:-30px;
        padding:20px;
        @media (max-width:768px){
            font-size:15px;
        }
        @media (min-width:769px) and(max-width:1024px){
            font-size:15px;
        }
`

export const HeaderTextRight = styled.h2`
        color:white;
        font-size:25px;
        font-weight:bold;
        text-align:center;
        @media ${device.mobile}{
                  margin-top:58px;
                  padding-top:30px;
                  font-size:20px;
          }
          @media (max-width:768px){
            font-size:20px;
          }
          @media (max-width:1024px){
            font-size:20px;
            margin-top:68px;
          }
`

export const HeaderTextInRight = styled.h4`
        color:white;
        font-size:30px;
        font-weight:bold;
        text-align:center;
        margin-top:-30px;
        padding:20px;
        @media (max-width:768px){
            font-size:18px;
          }
        @media (max-width:1024px){
            font-size:18px;
          }
`

export const RegisSoft = styled.h2`
    width:80%;
    height:60px;
    background:linear-gradient(45deg,#f1c40f,#e67e22);
    margin:auto;
    padding:13px;
    color:white;
    margin-top:20px;
    text-align:center;
    border-radius:3px;
    cursor:pointer;
    margin-top:0px;
    @media (max-width:768px){
         margin-top:-13px;
    }   
`

export const Price1 =styled.div`
    margin:auto;
    display:flex;
    width:80%;
    height:40px;
    margin-bottom:15px;

`
export const Name_full3in1_Month = styled.h2`
    width:70%;
    height:100%;
    background:${MainColor};
    font-size:18px;
    color:white;
    padding:6px;
    @media ${device.mobile}{
        font-size:14px;
        width:60%;
    }
    @media (max-width:768px){
            font-size:15px;           
    }
    @media (max-width:1024px){
            font-size:15px;           
    }
`

export const Price_full3in1_Month = styled.h2`
    width:30%;
    height:100%;
    background:#7f8c8d;
    font-size:18px;
    color:white;
    text-align:right;
    padding:6px;
    @media ${device.mobile}{
        font-size:14px;
        width:40%;
    }
    @media (max-width:1024px){
            font-size:15px;
            width:40%;
    }
`
export const LineSpace= styled.div`
        @media ${device.mobile}{
            margin-top:98px;
        }
`
// export const SelectPrice = styled.h2`
//     width:20%;
//     height:100%;
//     background:#e67e22;
//     font-size:18px;
//     color:white;
//     text-align:center;
//     font-size:18px;
//     padding:6px;
//     font-weight:bolder;
//     cursor:pointer;
//     &:hover .SelectPriceIn{
//             background:#d35400;
//             transition: 0.5s all ease-in-out;            
//       }
// `

export const CenterContent = styled.div`
    width: 80%;
    height:50%;
    display:flex;   
    ${'' /* border:1px solid #e67e22; */}
    margin:auto;
    @media ${device.mobile}{
             width:100%;
            }
`

export const LeftContent = styled.div`
    width:40%;
    height:100%;
    ${'' /* background:#4cb2b6; */}   
`

export const IMGLOGO = styled.img`
        height:170px;
        margin-top:15%;
        margin-left:25%;
        @media ${device.mobile}{
            height:120px;
        }   
        @media (max-width:768px){
            margin-left: 68%;
            margin-top: 0%;
        }
        @media (min-width:768px) and (max-width:1024px){
            margin-top: 38%;
            margin-left: -19%;
        }
`

export const RightContent = styled.div`
        width:60%;
        height:100%;    
        @media (max-width:768px){
            padding-left: 13%;
            margin-top: -12%;
        }
        @media (min-width:769px) and (max-width:1024px){
            margin-top: 14px;
            margin-left: 35px;
        }      
`

export const Text = styled.h2`
        color:white;
        font-size:16px;
        margin-top:-2px;
        text-align:center;
        @media ${device.mobile}{
            font-size:13px;
            margin-top:5px;
        }
        @media (max-width:1024px){
            font-size:12px;
            margin-top:5px;
        }
`