const validate = values =>{
  const errors ={}
  const {departmentName,humanName,tel,address,email,userId,infoUserId,avatar,signature} = values
  //email
  if(!userId){
    errors.userId = "Không được để trống"
  } 
  if(!infoUserId){
    errors.infoUserId = "Không được để trống"
  } 
  if(!email){
    errors.email = "Email không được để trống"
  }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
    errors.email = "Email không hợp lệ"
  }  
  //tel
  if(!tel){
    errors.tel="Số điện thoại không được để trống"
  }else if( tel.trim().length<10 || tel.trim().length>13){
    errors.tel="Số điện thoại không tồn tại"
  }
  //taxCode ma so thue
  if(!humanName){
    errors.humanName="không được để trống"
  }else if(humanName.trim().length<10 || humanName.trim().length>100){
    errors.humanName="ít nhất 10 đến 100 ký tự"
  }
  //address
  if(!address){
    errors.address="Địa chỉ không được để trống"
  }else if(address.trim().length<10 || address.trim().length>100){
    errors.address="ít nhất 10 đến 100 ký tự"
  }
  //workPlace
  if(!departmentName){
    errors.departmentName="Nơi làm việc không được để trống"
  }else if(departmentName.trim().length<10 || departmentName.trim().length>100){
    errors.departmentName="Có ít nhất 10 đến 100 ký tự"
  }
  //directorName
  if(!signature){
    errors.signature="không được để trống"
  }

  if(!avatar){
    errors.avatar="không được để trống"
  }

  if(!userId){
    errors.userId="không được để trống"
  }

  return errors  
}

export default validate


