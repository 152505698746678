import React,{Suspense} from 'react';
import {    
    MainCss,//chua toan bo web
 } from '../style/MainCss/navbar';
import LogoGDT from '../assest/img/gdt.jpg'

import {Helmet} from 'react-helmet-async';


import { 
     ContentUs,
      IMGCenter, LogoImg, 
     } from '../style/MainCss/content_us';
import { MainContent } from '../style/MainCss/maincontent';

import HeaderComponent from './component/Header';

const SliderComponent = React.lazy(()=>import('./component/SliderHeader'))
const MemberCompoent  = React.lazy(()=>import('./component/Member'))
const FooterComponent = React.lazy(()=>import('./component/footer'))
const LoadingLogo     = React.lazy(()=>import('./component/loadingLogo'))
const CeoLeaderComponent  = React.lazy(()=>import('./component/CeoLeader'))
const GioiDinhTueCompoent = React.lazy(()=>import('./component/GIO_DINH_TUE_TON_CHI')) 
const AboutUsComponent = React.lazy(()=>import('./component/AboutUs'))

const Index = () => {
    return (
        <div>
                <Helmet>
                    <title>VIỄN THÔNG TIN HỌC HUY THÀNH</title>
                    <meta
                        name="description"
                        content="VIỄN THÔNG TIN HỌC HUY THÀNH"
                        charSet="utf-8"
                    />
                    <link rel="canonical" href="http://huythanhicare.com/cong-dong-gioi-dinh-tue"

                    />
                </Helmet>
                    <MainCss style={{overFlow:'hidden'}}>        
                            
                                            <HeaderComponent/>
                                {/* slider */}
                                                <Suspense fallback={()=>{}}>
                                                    <SliderComponent/>
                                                </Suspense>

                                                <MainContent  className='MainContent'>   
                                                {/* top20 */}
                                                        
                                                            <Suspense fallback={()=>{}}>
                                                                <LoadingLogo />
                                                            </Suspense>

                                                            <Suspense fallback={()=>{}}>
                                                                <AboutUsComponent/>
                                                            </Suspense>                                       

                                                            <Suspense fallback={()=>{}} >
                                                                <LoadingLogo />
                                                            </Suspense>                            

                                                            <Suspense fallback={()=>{}}>
                                                                <GioiDinhTueCompoent/>
                                                            </Suspense>                         

                                                            <Suspense fallback={()=>{}}>
                                                                <LoadingLogo />
                                                            </Suspense>                           

                                                            {/* menber */}
                                                            <Suspense fallback={()=>{}}>
                                                                    <MemberCompoent/>
                                                            </Suspense>

                                                            <Suspense fallback={()=>{}}>
                                                                <LoadingLogo/>
                                                            </Suspense>

                                                            <Suspense fallback={()=>{}}>
                                                                <CeoLeaderComponent/>
                                                            </Suspense>           

                                                            
                                                            <ContentUs id="VE_CHUNG_TOI">                            
                                                                        <LogoImg >
                                                                            <IMGCenter src={LogoGDT} alt="cong-dong-gioi-dinh-tue"/>
                                                                        </LogoImg>                            
                                                            </ContentUs>
                                                </MainContent>  
                                    
                                    
                                        {/* footer */}
                                                    <Suspense fallback={()=>{}}>
                                                        <FooterComponent/>
                                                    </Suspense>
                                        {/* footer */}
                                    
                            </MainCss>
        </div>
      
    );
};

export default Index;