import React, {  useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Button, DialogActions, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DomainInput, EmailLogin,FooterContent,NameLogin, PassLogin, TelLogin, TextLogin } from './style';
import { ValidateEmailAddress,ValidTel } from '../../../../../../../helper/fnAllways';
import { CREATE_NEW_WEBSITE } from '../../../contant';
import {useDispatch} from 'react-redux'

const color = '#2c3e50'
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
}))

function ChangeTooltip(props) {
    const classes = useStylesBootstrap();    
    return <Tooltip arrow classes={classes} {...props} />;
}

export default function Index({open,handleClose}) {
    const dispatch                 = useDispatch()
    const [nameLogin,setNameLogin] = useState("")
    const [passLogin,setPassLogin] = useState("")
    const [email,setEmail]         = useState("")
    const [tel,setTel]             = useState("")
    const [domain,setDomain]       = useState("")

    const [errName,setErrName] = useState(false)
    const [errPass,setErrPass] = useState(false)

    const [errEmail,setErrEmail] = useState(false)
    const [errTel,setErrTel]     = useState(false)
    const [errTextEmail,setErrTestEmail] = useState(false)
    const [errTextTel,setErrTextTel]     = useState(false)

    const [errDomain,setErrDomain]  = useState(false)


    const CreateWeb=()=>{
        if(nameLogin===""||nameLogin===null){
            setErrName(true)
            setTimeout(()=>{
                setErrName(false)
            },3000)
            return
        }
        if(passLogin===""||passLogin===null){
            setErrPass(true)
            setTimeout(()=>{
                setErrPass(false)
            },3000)
            return
        }
        if(email===""||email===null){
            setErrEmail(true)
            setTimeout(()=>{
                setErrEmail(false)
            },3000)
            return
        }
        if(!ValidateEmailAddress(email)){
            setErrTestEmail('Email Không Đúng Định Dạng, Vui Lòng Thử Lại')
            setErrEmail(true)
            setTimeout(()=>{
                setErrTestEmail("")
                setErrEmail(false)
            },3000)
            return
        }       

        if(tel===""||tel===null){
            setErrTel(true)
            setTimeout(()=>{
                setErrTel(false)
            },3000)
            return
        }

        if(!ValidTel(tel)){
            setErrTextTel('Số Điện Thoại Không Đúng Định Dạng, Vui Lòng Thử Lại')
            setErrTel(true)
            setTimeout(()=>{
                setErrTextTel("")
                setErrTel(false)
            },3000)
            return
        }
        
        if(domain===""||domain===null){
            setErrDomain(true)
            setTimeout(()=>{
                setErrDomain(false)
            },3000)
            return
        }
       
        let item={
            nameLogin,passLogin,email,tel,domain
        }
        dispatch({
            type:CREATE_NEW_WEBSITE,
            payload:{item}
        })
    }

  return (
    <div>     
      <Dialog fullWidth aria-labelledby="customized-dialog-title" open={open} >
        <DialogTitle style={{color:'green'}} id="customized-dialog-title" onClose={handleClose}>
          Tạo Website, vui lòng điền đầy đủ thông tin
          <p style={{fontSize:12,fontStyle:'italic',color:'black'}}>Chúng Tôi Sẽ Giúp Bạn Tạo Website nhanh nhất</p>
        </DialogTitle>
        <DialogContent dividers>     
                <NameLogin>
                    <TextLogin>Tên đăng nhập:&nbsp;</TextLogin>
                    <TextField 
                    error={errName}
                    onChange={e=>setNameLogin(e.target.value)}
                    type='text' variant="outlined" fullWidth size='small'
                    />
                </NameLogin>
                <PassLogin>
                    <TextLogin>Mật Khẩu:&nbsp;</TextLogin>
                    <TextField 
                    error={errPass}
                    onChange={e=>setPassLogin(e.target.value)}
                    type='password' variant="outlined" fullWidth size='small'/>
                </PassLogin>
                <EmailLogin>
                    <TextLogin>Email:&nbsp;</TextLogin>
                    <TextField
                      error={errEmail}
                      helperText={errTextEmail}
                      onChange={e=>setEmail(e.target.value)}
                     type='text' variant="outlined" fullWidth size='small'/>
                </EmailLogin>
                <TelLogin>
                    <TextLogin>Tel/SDT:&nbsp;</TextLogin>
                    <TextField
                     error={errTel}
                     helperText={errTextTel}
                     onChange={e=>setTel(e.target.value)}
                     type='text' variant="outlined" fullWidth size='small'/>
                 </TelLogin>
                <DomainInput>
                    <TextLogin>Tên Miền:&nbsp;</TextLogin>
                    <TextField
                     error={errDomain}
                     onChange={e=>setDomain(e.target.value)}
                     type='text' variant="outlined" fullWidth size='small'/>
                </DomainInput>
        </DialogContent>     
        <DialogActions>
        <FooterContent>
            <div>
                 
            </div>
            <div>
                <Button autoFocus onClick={()=>CreateWeb()} color="primary">
                  Khởi Tạo
                </Button>
            </div>        
        </FooterContent>            
        </DialogActions>
      </Dialog>
    </div>
  );
}
