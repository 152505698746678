import React, {useEffect} from 'react'
import LopChamAn from '../../ManagerAccountActiveGov/component/ClassActive'
import {useSelector,useDispatch}from 'react-redux'
import {handleFindClass}from './helper'
import {DISPATCH_LIST_STUDEENT_BY_CLASS,DISPATCH_GET_INFO_LIST_OF_USERID}from '../../ManagerAccountActiveGov/contant/ManagerAccount'

const Index = () => {
         const dispatch = useDispatch()
         const DateInput                = useSelector(state=>state.ManagerAccount.DateInput) 
         const ItemManagerActiveAccount = useSelector(state=>state.ManagerAccount.ItemManagerActiveAccount) 
         const ListClass                = useSelector(state=>state.Student.ListClass)
         const ListOriginAllStudentNow  = useSelector(state=>state.Student.ListOriginAllStudentNow)  
         const ListRegisSevenDay         = useSelector(state=>state.Student.ListRegisSevenDay)  
         const result                    = handleFindClass(DateInput,ItemManagerActiveAccount,ListClass,ListOriginAllStudentNow,ListRegisSevenDay)
        useEffect(() => {
                if(result===false){
                        alert('Tài Khoản Bị Vô Hiệu')
                        return window.location.reload()
                }
               if(result!==undefined){
                        dispatch({
                                type:DISPATCH_LIST_STUDEENT_BY_CLASS,
                                payload:{item:result}
                        })
                        let item={
                                idClass:ItemManagerActiveAccount[0].idClass,
                                userId:ItemManagerActiveAccount[0].userId,
                                dateNow:DateInput
                        }
                        //lay thong tin templete, infouser, check day food,studentNow
                        dispatch({
                                type:DISPATCH_GET_INFO_LIST_OF_USERID,
                                payload:{item}
                        })
                        return ()=>{}
               }
        }, [result])
        return (         
                <>
                      {result===false?<></>:<LopChamAn/>}              
                </> 
                      
       )
}

export default Index