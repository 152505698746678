import React from 'react';
import {Alert,AlertTitle}from '@material-ui/lab'
import {MenuItem,Dialog} from '@material-ui/core'
import {TyepOfApp} from '../../../../APP_ADMIN_PERSON/Contant'
import SwitchApp from './SwitchActive/index'
import {useSelector} from 'react-redux'
import {FindValueOfAppActive} from '../Fn'

export default function SimpleMenu({row,handleClose,open}) { 
  const ListAppActive = useSelector(state=>state.AdminAddService.ListAppActive)
  const FilterItemAppActive = ListAppActive.filter(v=>v.userId===row.userId) 
 
  const APP_LIST_HTML =()=>{
    return(
      TyepOfApp.map((v,i)=>{   
        let ValueOfAppActive= FindValueOfAppActive(FilterItemAppActive,v)  
      return <MenuItem key={i} value={v.type}>
                <SwitchApp
                    Value={v}   
                    ValueActive={ValueOfAppActive}         
                    AppActive ={FilterItemAppActive}                                                                                                                                                                                                                                                                         
                />
                {v.name.toUpperCase()}      
            </MenuItem>
      })
    )
  }

  return (
    <Dialog onClose={handleClose} open={open}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >      
   
    <Alert severity="info">
          <AlertTitle>{row.humanName.toUpperCase()}</AlertTitle>          
    </Alert>
          {APP_LIST_HTML()}
    </Dialog>   
  );
}
