import React, {  useEffect,Suspense } from 'react';
import {useDispatch,useSelector} from 'react-redux'
import { GET_LIST_MATERIAL_NUTRIKID } from '../contant/material';
import {GET_ALL_MEAL_NUTRIKID} from '../contant/LibraryMeal';
import { Typography } from '@material-ui/core';
import { GET_ADD_PAYMENT_KIDTOP } from '../contant/ThanhToan';
import { itemList } from '../../CountEat/PaymenExpire/fn';

const Nutrikids      = React.lazy(()=>import('./nutrikids'))

const Index =React.memo(() => {
    const ItemLogin            = useSelector(state => state.GovLogin.GovLogin)
    const dispatch             = useDispatch()    
    
    useEffect(()=>{       
           dispatch({type:GET_LIST_MATERIAL_NUTRIKID})     
           dispatch({type:GET_ALL_MEAL_NUTRIKID})   
           dispatch({
            type:GET_ADD_PAYMENT_KIDTOP,
            payload:{item:{
                userId:ItemLogin.userId,
                listSoftPrice:itemList
            }}
           })
    },[])


    const WaitComponent =()=>{
        return(
            <Typography style={{color:'#2980b9',fontSize:'15',fontFamily:'Roboto',fontStyle:'italic'}}>Đang Nạp Dữ Liệu ...</Typography>
        )
    }

    return (
        <> 
            <Suspense fallback={()=>{}}>
                    <Suspense fallback={WaitComponent()}>
                            <Nutrikids/>            
                    </Suspense>
            </Suspense>    
        </>         
    );
});

export default Index;