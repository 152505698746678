import {DISPATCH_GET_INFO_STAFF_TO_STORE,UPDATE_INFO_STAFF,OPEN_UPDATE_INFO_STAFF_AGAIN,CLOSE_UPDATE_INFO_STAFF_AGAIN,
DISPATCH_GET_PAYMENT_STAFF_TO_STORE,DISPATCH_GET_QRCODE_TO_STORE,CHAM_CONG_LOI,DISPATCH_GET_CHAM_CONG_TO_STORE, CHAM_CONG_SUCCESS,
DISPATCH_GET_LIST_MANY_ITEM_TO_STORE,OPEN_ADD_IMPORT_REPO,CLOSE_ADD_IMPORT_REPO, DISPATCH_ADD_IMPORT_FROM_STAFF_TO_STORE,OPEN_EDIT_IMPORT_FROM_STAFF,CLOSE_EDIT_IMPORT_FROM_STAFF, EDIT_IMPORT_FROM_STAFF,
DELETE_EDIT_IMPORT_STAFF,DISPATCH_LIST_IMPORT_BY_STAFF_TO_STORE,
DISPATCH_LIST_TASK_JOB_BY_STAFF_TO_STORE,
UPDATE_EDIT_DEPARTMENT,DISPATCH_VALUE_PROCESS_TO_STORE, DISPATCH_LIST_TASK_JOB_UPDATE, CONVERT_READY_TO_PROCESS_TASK_JOB, DISPATCH_LIST_AUTO_IMPORT_TO_STORE, STAFF_SELECT_TASK_JOB,
OPEN_ADD_TASK_JOB,CLOSE_ADD_TASK_JOB, CONVERT_READY_TO_COMPLETE_TASK_JOB,DELETE_TASK_JOB_BY_STAFF, DISPATCH_ITEM_VALUE_PESONAL_TO_STORE,
DISPATCH_UPDATE_ITEM_VALUE_PERSON,
DISPATCH_LIST_TASK_JOB_PESONAL_TO_STORE,VALUE_PROCESS_TO_HANDLE, NAME_STATUS, FIND_BY_CUSTOMER,
//cong tac phi 
OPEN_ADD_PAY_FOR_JOB_TASK,CLOSE_ADD_PAY_FOR_JOB_TASK, DISPATCH_ADD_PAY_FOR_JOB_TASK_TO_STORE, DISPATCH_FIND_BY_MONTH_PAY_JOB_STAFF,OPEN_ADD_MORE_DEPARTMENT,CLOSE_ADD_MORE_DEPARTMENT,  DISPATCH_VALUE_ADD_DEPARTMENT_UPDATE_TO_STORE,OPEN_EDIT_PAY_FOR_JOB_TASK,CLOSE_EDIT_PAY_FOR_JOB_TASK,
TYPE_PAYMENT_SELECT_PAY_TASK_JOB,
//ADD_TASK_JOB_BY_STAFF,
 DISPATCH_ADD_STAFF_JOB_TO_STORE,
 DISPATCH_MONTH_SELECT_TO_STORE,
 DISPATCH_FIND_NAME_TASK_TO_STORE,
 DISPATCH_FIND_BY_ID,OPEN_SHOW_IMG,CLOSE_SHOW_IMG, 
 //VALUE_PROCESS_TO_HANDLE_EMPTY,
 OPEN_TRA_CUU,CLOSE_TRA_CUU

} from '../contant'
import {toastError,toastSuccess} from '../../../../helper/toastHelper'
import { AllArrMaterial } from '../../App/QuanLyKho/MainTab/Categories/Component/Fn'
import { DeleteItemById, updateArrById } from '../../../../helper/fnAllways'
import { STATUS_COMPLETE, STATUS_PROCESS, STATUS_READY } from '../../App/QuanlyNhanVien/component/Fn'
import { FilterByCustomer,  handleShowCustomer, PAYMENT_STAFF_GET_MATERIAL } from '../Component_CongViec/Fn'
import { HandleSelectDepartment } from '../Component_CongViec/AddMoreDepartment/fn'
import {NewNameTask } from '../Component_CongViec/Fn'
import { showMMYY } from '../../../../helper/dateTime/handleDMY'

export const initialState={
    ItemInfoStaff:null,//thong tin nhan vien
    ItemPaymentStaff:null,//luong nhan vien
    ListSupplies:[],
    ListCategories:[],
    ListCommodities:[],
    ListCustomers:[],
    ListMaterials:[],
    ListRepo:[],
    ListStaffJob:[],
    OpenUpdateInfoAgain:false,
    ItemQrcode:{},
    ActiveItemQrCode:false,
    ItemChamCong:{},
    ActiveItemChamCong:false,
    OpenAddImport:false,
    OpenEditImport:false,
    ItemEditImport:"",
    ActiveListMany:false,
    ListTaskJob:[],
    ListTaskJobReady:[],
    ListTaskJobProcess:[],
    ListTaskJobComplete:[],
    ListAutoImport:[],
    ActiveListAutoImport:false,
    value:"",
    valueProcess:"",
    OpenAddTaskJob:false,
    ValuePersonal:"",//ca nhan
    ValueProcessHandle:"",
    NameStatus:"",
    FindCustomer:"",
    ValueSwitchLockIcon:false,
    OpenAddPayForJobTask:false,//cong tac phi
    OpenAddMoreDepartment:false,
    ItemDepartment:"",
    ListPayTaskJob:[],//danh sach cong tac phi
    ItemCustomer:"",
    ArrDepartment:[],
    MonthPayJobStaff:"",
    OpenEditPayForJob:false,
    ValueEditPayForJob:"",
    //tong hop
    TypePayment:PAYMENT_STAFF_GET_MATERIAL,
    ValueCustomer:"",
    MonthSelect:showMMYY(Date.now()),
    NameTask:"",
    OpenShowImg:false,
    ValueShowImg:"",
    OpenTraCuu:false,
    ValueTraCuu:null
}

const Index=(state=initialState,action)=>{
  switch (action.type) {
    case DISPATCH_GET_INFO_STAFF_TO_STORE:
      return{
        ...state,
        ItemInfoStaff:action.payload.item
      }      
    case UPDATE_INFO_STAFF:
      return{
        ...state,
        ItemInfoStaff:action.payload.item
      }
    case OPEN_UPDATE_INFO_STAFF_AGAIN:
      return{
        ...state,
        OpenUpdateInfoAgain:true
      }
      case CLOSE_UPDATE_INFO_STAFF_AGAIN:
        return{
          ...state,
          OpenUpdateInfoAgain:false
        }
      case DISPATCH_GET_PAYMENT_STAFF_TO_STORE:
        return{
          ...state,
          ItemPaymentStaff:action.payload.item
        }
      case DISPATCH_GET_QRCODE_TO_STORE:
        return{
          ...state,
          ActiveItemQrCode:true,
          ItemQrcode:action.payload.item
        }
      case CHAM_CONG_LOI:
        toastError("Mã QRCODE Không phù hợp, kiểm tra lại!")
        return{
          ...state
        }   
        case CHAM_CONG_SUCCESS:
          toastSuccess(`${action.payload.item} thành công`)
          return{
            ...state
          }        
      case DISPATCH_GET_CHAM_CONG_TO_STORE:
        return{
          ...state,
          ActiveItemChamCong:true,
          ItemChamCong:action.payload.item
        }
      case DISPATCH_GET_LIST_MANY_ITEM_TO_STORE:
        return{
          ...state,
          ActiveListMany:true,
          ListCategories:action.payload.item.ListCategories,
          ListSupplies:action.payload.item.ListSupplies,
          ListCommodities:action.payload.item.ListCommodities,
          ListCustomers:action.payload.item.ListCustomers,
          ListRepo:action.payload.item.ListRepo,
          ListStaffJob:action.payload.item.ListStaffJob,
          ListMaterials:AllArrMaterial(action.payload.item.ListCategories)
        }
      //cap nhat mang tai day
      case DISPATCH_ADD_IMPORT_FROM_STAFF_TO_STORE:
        toastSuccess('Lưu dữ liệu thành công')
        return{
          ...state,
          ListStaffJob:[action.payload.item].concat(state.ListStaffJob)
        }
      case EDIT_IMPORT_FROM_STAFF:
        toastSuccess('Cập nhật thành công')
        return{
          ...state,
          ListStaffJob:updateArrById(state.ListStaffJob,action.payload.item)
        }
      case DELETE_EDIT_IMPORT_STAFF:
        toastSuccess('Xóa thành công')
        return{
          ...state,
          ListStaffJob:DeleteItemById(state.ListStaffJob,action.payload.item)
        }
      case DISPATCH_LIST_IMPORT_BY_STAFF_TO_STORE:
        return{
          ...state,
          ListStaffJob:action.payload.item
        }
    
        case OPEN_ADD_IMPORT_REPO:
          return{
            ...state,
            OpenAddImport:true
          }
          case CLOSE_ADD_IMPORT_REPO:
            return{
              ...state,
              OpenAddImport:false
            }
        case OPEN_EDIT_IMPORT_FROM_STAFF:
          return{
            ...state,
            OpenEditImport:true,
            ItemEditImport:action.payload.item
          }
          case CLOSE_EDIT_IMPORT_FROM_STAFF:
            return{
              ...state,
              OpenEditImport:false,
              ItemEditImport:""
            }  
          case DISPATCH_ADD_STAFF_JOB_TO_STORE:
            let arrNew = state.ListTaskJob.concat(action.payload.item)
            return{
              ...state,
              ListTaskJob:arrNew,
              ListTaskJobReady:arrNew.filter(v=>v.status===STATUS_READY&&v!==undefined),
              ListTaskJobProcess:arrNew.filter(v=>v.status===STATUS_PROCESS&&v!==undefined),
              ListTaskJobComplete:arrNew.filter(v=>v.status===STATUS_COMPLETE&&v!==undefined),
            }
          case DISPATCH_LIST_TASK_JOB_BY_STAFF_TO_STORE:      
          if(state.ValueCustomer!==""){
              let newList = state.ListTaskJob.filter(v=>v.taskJob[0].idCustomer===state.ValueCustomer)
              return{
                ...state,
                ListTaskJob:newList,
                ListTaskJobReady:newList.filter(v=>v.status===STATUS_READY),
                ListTaskJobProcess:newList.filter(v=>v.status===STATUS_PROCESS),
                ListTaskJobComplete:newList.filter(v=>v.status===STATUS_COMPLETE),
             }
          }
          if(state.ValueCustomer===""){
            return{
              ...state,
              ListTaskJob:action.payload.item,
              ListTaskJobReady:(action.payload.item).filter(v=>v.status===STATUS_READY),
              ListTaskJobProcess:(action.payload.item).filter(v=>v.status===STATUS_PROCESS),
              ListTaskJobComplete:(action.payload.item).filter(v=>v.status===STATUS_COMPLETE),
           }
          }
            
         case DISPATCH_VALUE_PROCESS_TO_STORE:
           return{
             ...state,
             value:action.payload.item.value,
             valueProcess:action.payload.item.valueProcess
           }
          case DISPATCH_LIST_TASK_JOB_UPDATE:
             toastSuccess('Cập nhật thành công')
             let findListTaskJobProcess = (action.payload.item).filter(v=>v.status===STATUS_PROCESS)
             let findValue              = findListTaskJobProcess.filter(v=>v._id===state.valueProcess._id)[0]
             let ItemCustomer           = handleShowCustomer(findValue,state.ListCustomers)
             let Value                  = ItemCustomer.arrDepartment.filter(v=>v._id===state.value._id)[0]          
             return{
               ...state,
               ListTaskJob:action.payload.item,
               ListTaskJobReady:(action.payload.item).filter(v=>v.status===STATUS_READY),
               ListTaskJobProcess:findListTaskJobProcess,
               ListTaskJobComplete:(action.payload.item).filter(v=>v.status===STATUS_COMPLETE),
               value:Value,
               valueProcess:findValue,
               ValueProcessHandle:action.payload.item.filter(v=>v._id===state.ValueProcessHandle._id)[0]
             }
          case CONVERT_READY_TO_PROCESS_TASK_JOB:
            let listTask = updateArrById(state.ListTaskJob,action.payload.item)
            return{
              ...state,
              ListTaskJob:listTask,
              ListTaskJobReady:listTask.filter(v=>v.status===STATUS_READY),
              ListTaskJobProcess:listTask.filter(v=>v.status===STATUS_PROCESS),
              ListTaskJobComplete:listTask.filter(v=>v.status===STATUS_COMPLETE),
            }
          
        case CONVERT_READY_TO_COMPLETE_TASK_JOB:
          let list = updateArrById(state.ListTaskJob,action.payload.item)
          return{
            ...state,
            ListTaskJob:list,
            ListTaskJobReady:list.filter(v=>v.status===STATUS_READY),
            ListTaskJobProcess:list.filter(v=>v.status===STATUS_PROCESS).reverse(),
            ListTaskJobComplete:list.filter(v=>v.status===STATUS_COMPLETE),
          }
          case STAFF_SELECT_TASK_JOB:
              let ListTask = updateArrById(state.ListTaskJob,action.payload.item)
              return{
                ...state,
                ListTaskJob:ListTask,
                ListTaskJobReady:ListTask.filter(v=>v.status===STATUS_READY),
                ListTaskJobProcess:ListTask.filter(v=>v.status===STATUS_PROCESS),
                ListTaskJobComplete:ListTask.filter(v=>v.status===STATUS_COMPLETE),
              }
        case DELETE_TASK_JOB_BY_STAFF:
            let newArrAfterDelete = DeleteItemById(state.ListTaskJob,action.payload.item)
            return{
              ...state,
              ListTaskJob:newArrAfterDelete,
              ListTaskJobReady:newArrAfterDelete.filter(v=>v.status===STATUS_READY),
              ListTaskJobProcess:newArrAfterDelete.filter(v=>v.status===STATUS_PROCESS),
              ListTaskJobComplete:newArrAfterDelete.filter(v=>v.status===STATUS_COMPLETE),
            }
        case UPDATE_EDIT_DEPARTMENT:
          return{
            ...state,
            ListCustomers:updateArrById(state.ListCustomers,action.payload.item)
          }
        case DISPATCH_LIST_AUTO_IMPORT_TO_STORE:
          return{
            ...state,
            ActiveListAutoImport:true,
            ListAutoImport:action.payload.item
          }
        case OPEN_ADD_TASK_JOB:
          return{
            ...state,
            OpenAddTaskJob:true
          }
        case CLOSE_ADD_TASK_JOB:
          return{
            ...state,
            OpenAddTaskJob:false
          }
        case DISPATCH_ITEM_VALUE_PESONAL_TO_STORE:
          return{
            ...state,
            ValuePersonal:action.payload.item
          }
        case DISPATCH_UPDATE_ITEM_VALUE_PERSON:
          return{
            ...state,
            ValuePersonal:action.payload.item
          }
        case DISPATCH_LIST_TASK_JOB_PESONAL_TO_STORE:
          let FindListTaskJobProcess = (action.payload.item).filter(v=>v.status===STATUS_PROCESS)
          let findValuePersonal      =  FindListTaskJobProcess.filter(v=>v._id===state.ValuePersonal._id)[0]
          return{
            ...state,
            ListTaskJob:action.payload.item,
            ListTaskJobReady:(action.payload.item).filter(v=>v.status===STATUS_READY),
            ListTaskJobProcess:FindListTaskJobProcess,
            ListTaskJobComplete:(action.payload.item).filter(v=>v.status===STATUS_COMPLETE),
            ValuePersonal:findValuePersonal
          }
        case VALUE_PROCESS_TO_HANDLE:
          return{
            ...state,
            ValueProcessHandle:action.payload.item
          }
        case NAME_STATUS:
          return{
            ...state,
            NameStatus:action.payload.item
          }
        case FIND_BY_CUSTOMER:             
             let FilterListTaskJob = FilterByCustomer(state.ListTaskJob,action.payload.item)             
             //console.log(FilterListTaskJob)
             return{
               ...state,
               ListTaskJob:FilterListTaskJob,
               ListTaskJobReady:FilterListTaskJob.filter(v=>v.status===STATUS_READY),
               ListTaskJobProcess:FilterListTaskJob.filter(v=>v.status===STATUS_PROCESS),
               ListTaskJobComplete:FilterListTaskJob.filter(v=>v.status===STATUS_COMPLETE),
               ValueSwitchLockIcon:true,
               ValueCustomer:action.payload.item
         }      
        case OPEN_ADD_PAY_FOR_JOB_TASK:
          return{
            ...state,
            OpenAddPayForJobTask:true
          }
       case CLOSE_ADD_PAY_FOR_JOB_TASK:
            return{
              ...state,
              OpenAddPayForJobTask:false
        }
      case DISPATCH_ADD_PAY_FOR_JOB_TASK_TO_STORE:
        toastSuccess('Khởi tạo thành công')
        return{
          ...state,
          ListTaskJob:[action.payload.item].concat(state.ListTaskJob)
        }
      case DISPATCH_FIND_BY_MONTH_PAY_JOB_STAFF:
          return{
            ...state,
            MonthPayJobStaff:action.payload.item
          }
      case OPEN_ADD_MORE_DEPARTMENT:
        let findDepartment = state.ListCustomers.filter(v=>v._id===action.payload.item.taskJob[0].idCustomer)[0]
        let arrDepartment = HandleSelectDepartment(action.payload.item,findDepartment.infoCustumer.deparment)
        return{
          ...state,
          OpenAddMoreDepartment:true,
          ItemDepartment:action.payload.item,
          ArrDepartment:arrDepartment
        }
    case CLOSE_ADD_MORE_DEPARTMENT:
      return{
        ...state,
        OpenAddMoreDepartment:false,
        ItemDepartment:'',
        ArrDepartment:[]
      }
    case DISPATCH_VALUE_ADD_DEPARTMENT_UPDATE_TO_STORE:
      let List = updateArrById(state.ListTaskJob,action.payload.item)
      return{
        ...state,
        ValueProcessHandle:action.payload.item,
        ListTaskJob:List,
        ListTaskJobReady:List.filter(v=>v.status===STATUS_READY),
        ListTaskJobProcess:List.filter(v=>v.status===STATUS_PROCESS),
        ListTaskJobComplete:List.filter(v=>v.status===STATUS_COMPLETE),
      }
      case DISPATCH_FIND_NAME_TASK_TO_STORE:  
          let newArrNameTask =  state.ListTaskJob.filter(v=>NewNameTask(v.nameTask)===action.payload.item)
          console.log(newArrNameTask)
          return{
            ...state,
            ListTaskJob:newArrNameTask,
            ListTaskJobReady:newArrNameTask.filter(v=>v.status===STATUS_READY),
            ListTaskJobProcess:newArrNameTask.filter(v=>v.status===STATUS_PROCESS),
            ListTaskJobComplete:newArrNameTask.filter(v=>v.status===STATUS_COMPLETE),
          }       
      case DISPATCH_FIND_BY_ID:
        return{
          ...state,
          ListTaskJob:[action.payload.item],
          ListTaskJobReady:[action.payload.item].filter(v=>v.status===STATUS_READY),
          ListTaskJobProcess:[action.payload.item].filter(v=>v.status===STATUS_PROCESS),
          ListTaskJobComplete:[action.payload.item].filter(v=>v.status===STATUS_COMPLETE),  
      }       
       
      //cong tac phi
    case OPEN_EDIT_PAY_FOR_JOB_TASK:
        return{
          ...state,
          OpenEditPayForJob:true,
          ValueEditPayForJob:action.payload.item
        }
     case CLOSE_EDIT_PAY_FOR_JOB_TASK:
        return{
          ...state,
          OpenEditPayForJob:false,
          ValueEditPayForJob:""
        }
      case TYPE_PAYMENT_SELECT_PAY_TASK_JOB:
          return{
            ...state,
            TypePayment:action.payload.item
          }
      case DISPATCH_MONTH_SELECT_TO_STORE:
        return{
          ...state,
          MonthSelect:action.payload.item
        }

      case OPEN_SHOW_IMG:
        return{
          ...state,
          OpenShowImg:true,
          ValueShowImg:action.payload.item
        }
      case CLOSE_SHOW_IMG:
        return{
          ...state,
          OpenShowImg:false,
          ValueShowImg:""
        }   
      // case VALUE_PROCESS_TO_HANDLE_EMPTY:
      //   return{
      //     ...state,
      //     ValueProcessHandle:null
      //   }
        case OPEN_TRA_CUU:
          return{
            ...state,
            OpenTraCuu:true,
            ValueTraCuu:action.payload.item
          }
        case CLOSE_TRA_CUU:
          return{
            ...state,
            OpenTraCuu:false,
            ValueTraCuu:null
          }
        
    
    default: return state     
  }
}

export default Index