import React, { Component,Fragment } from 'react';
import {withStyles} from '@material-ui/core'
import {compose,bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import style from './style'
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit'
import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TaskFind from '../../../../component/taskFind/index'
import AddInfoForm from '../../../../component/Dashboard/Layout/AddInfoUserForm/index'
import AddAccount from '../../../../component/Dashboard/Layout/AddAccount/index'
import DepartmentFormGov from '../../../../component/Dashboard/Layout/AddDepartmentGov'
import * as Actions from '../../../../actions/pageUser'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SwipePageUser from '../../../../component/SwipePageUser/index'

const actions = [
  { icon: <SearchIcon />, name: 'Tìm kiếm', component:<TaskFind/>,number:1  },
  { icon: <PersonAddIcon />, name: 'Thêm thông tin user',component:<AddInfoForm/>,number:2 },
  { icon: <AccountBalanceIcon />, name: 'Thêm phòng ban',component:<DepartmentFormGov/>,number:3 },
  { icon: <AccountCircleIcon />, name: 'Tạo mới tài khoản user',component:<AddAccount/>,number:4 },  
]

class index extends Component {
  openForm=(number)=>{  
    const findNumber = number -1     
    let {ActionUserPage}  = this.props
    let {showActionFormUser,showComponentFormUser,showFormUser,
      showComponentDepartmentGov,showActionDepartmentGov,showFormDepartmentGov     
    } = ActionUserPage  

    actions.map((item,index)=>{          
           if(item.number===3 && findNumber===index){                            
              showFormDepartmentGov()            
              showActionDepartmentGov()
              showComponentDepartmentGov(<DepartmentFormGov />)                   
             }
             else if(item.number===4 &&findNumber===index){              
              showFormUser()
              showActionFormUser()
              showComponentFormUser(<AddAccount />)                   
             }
            return true            
          })     
    }   
  

  speedDial=()=>{     
     let {classes,setOpen,ValueComponent,ActionUserPage,componentDepartmentGov} = this.props
     let {hideActionFormUser,showActionFormUser,
          hideFormDepartmentGov,
          showActionDepartmentGov
    } = ActionUserPage    
    let xhtml = ''
        xhtml =(
            <Fragment>        
              <span className="fixedSpeedDial">                       
              {ValueComponent?ValueComponent:componentDepartmentGov}  
              <div className={classes.root}>   
              <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                className={classes.speedDial}        
                icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                onClose={hideActionFormUser?hideActionFormUser:hideFormDepartmentGov}
                onOpen={showActionFormUser?showActionFormUser:showActionDepartmentGov}
                open={setOpen}  
                      
              >
                {actions.map(action => (
                  <SpeedDialAction            
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}            
                    onClick={()=>this.openForm(action.number)}                      
                  />
                ))}
              </SpeedDial>
            </div>
            </span>
            </Fragment>
        )
    return xhtml
  } 

  render() {    
    return (
      <Fragment>
        <SwipePageUser/>
        {/* {this.speedDial()} */}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return{
    ValueComponent:state.userPage.ValueComponent,
    componentDepartmentGov:state.userPage.componentDepartmentGov,
    setOpen:state.userPage.showForm,
    GetData:state.auth.GetData
  }
}

const mapDispatchStateToProps = (dispatch) =>{
  return{
    ActionUserPage:bindActionCreators(Actions,dispatch)   
  }
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchStateToProps
)

export default compose(
 withStyles(style),
  withConnect
)(index);