import {DISPATCH_ALL_MEAL_NUTRIKID_TO_STORE,OPEN_TAO_THUC_DON,CLOSE_TAO_THUC_DON,
DISPATCH_ADD_MEAL_NUTRIKID_TO_STORE_LIBRARY,OPEN_MATERIAL_IN_MEAL,CLOSE_MATERIAL_IN_MEAL,ADD_ITEM_MEAL_IN_LIBRARY,
UPDATE_ITEM_MEAL_IN_LIBRARY,USER_ID,DISPATCH_VALUE_ADD_MEAL_WEEK,RETURN_VALUE_MEAL_WEEK_NULL,
DISPATCH_ARR_MATERIAL_PROCESS
} from '../contant/LibraryMeal'
import { toastSuccess } from "../../../../helper/toastHelper"
import { updateArrById } from '../../../../helper/fnAllways'

export const  initialState={
        AllMeal:[],
        OpenTaoThucDon:false,
        ItemMeal:null,
        OpenMaterailInMeal:false,
        ItemMaterial:null,
        UserId:null,
        ValueMealWeek:null,
        KeySelect:null,
        ListMaterialProcess:[]

}

const Index =(state=initialState,action)=>{
    switch (action.type) {
            case DISPATCH_ARR_MATERIAL_PROCESS:
                return{
                    ...state,
                    ListMaterialProcess:action.payload.item
                }
            case RETURN_VALUE_MEAL_WEEK_NULL:
                return{
                    ...state,
                    ValueMealWeek:null
                }
            case DISPATCH_VALUE_ADD_MEAL_WEEK:
                return{
                    ...state,
                    ValueMealWeek:action.payload.item
                }
            case USER_ID:
                return{
                    ...state,
                    UserId:action.payload.item
                }
       
        case DISPATCH_ALL_MEAL_NUTRIKID_TO_STORE:
            return{
                ...state,
                AllMeal:action.payload.item
            }           
        case OPEN_TAO_THUC_DON:
            return{
                ...state,
                OpenTaoThucDon:true,
                ItemMeal:action.payload.item,   
                KeySelect:action.payload.item.i             
            }
        case CLOSE_TAO_THUC_DON:
            return{
                ...state,
                OpenTaoThucDon:false,
                ItemMeal:null,
                //KeySelect:null
            }
        case DISPATCH_ADD_MEAL_NUTRIKID_TO_STORE_LIBRARY:
            toastSuccess('Thêm mới thành công')
            return{
                ...state,
                //AllMeal:state.AllMeal.concat(action.payload.item)
            }
        case OPEN_MATERIAL_IN_MEAL:
            return{
                ...state,
                OpenMaterailInMeal:true,
                ItemMaterial:action.payload.item.v,
                KeySelect:action.payload.item.i
            }
        case CLOSE_MATERIAL_IN_MEAL:
                return{
                    ...state,
                    OpenMaterailInMeal:false,
                    ItemMaterial:null
                }
        case ADD_ITEM_MEAL_IN_LIBRARY:
            toastSuccess('Đã thêm vào thư viện')
            return{
                ...state,
                AllMeal:state.AllMeal.concat(action.payload.item)
            }
        case UPDATE_ITEM_MEAL_IN_LIBRARY:            
            return{
                ...state,
                AllMeal:updateArrById(state.AllMeal,action.payload.item)
            }
        default: return state
    }
}

export default Index