export const DISPATCH_TIEUHOC_OR_MAMNON = "DISPATCH_TIEUHOC_OR_MAMNON"

export const GET_LIST_MATERIAL_NUTRIKID              ="GET_LIST_MATERIAL_NUTRIKID"
export const DISPATCH_LIST_MATERIAL_TO_STORE         = "DISPATCH_LIST_MATERIAL_TO_STORE"

export const DISPATCH_ITEM_CATE_MATERIAL_NUTRIKID_TO_STORE    = "DISPATCH_ITEM_CATE_MATERIAL_NUTRIKID_TO_STORE"

export const ADD_MATERIAL_NUTRIKID                   = "ADD_MATERIAL_NUTRIKID" //them ten nhom thuc pham//tao nhom thuc pham
export const DISPATCH_ADD_MATERIAL_MUTRIKID_TO_STORE = "DISPATCH_ADD_MATERIAL_MUTRIKID_TO_STORE" //nhom thuc pham
export const OPEN_ADD_MATERI_NUTRIKID  = "OPEN_ADD_MATERI_NUTRIKID"
export const CLOSE_ADD_MATERI_NUTRIKID = "CLOSE_ADD_MATERI_NUTRIKID"

export const ADD_MATERI_NUTRIKID       = "ADD_MATERI_NUTRIKID" //them thuc pham
export const DISPATCH_ADD_MATERI_NUTRIKID_TO_STORE = "DISPATCH_ADD_MATERI_NUTRIKID_TO_STORE"

export const UPDATE_MATERIAL_NUTRIKID          = "UPDATE_MATERIAL_NUTRIKID"
export const DISPATCH_UPDATE_MATERIAL_NUTRIKID = "DISPATCH_UPDATE_MATERIAL_NUTRIKID"
export const OPEN_UPDATE_MATERIAL_NUTRIKID = "OPEN_UPDATE_MATERIAL_NUTRIKID"
export const CLOSE_UPDATE_MATERIAL_NUTRIKID = "CLOSE_UPDATE_MATERIAL_NUTRIKID"

export const OPEN_IMPORT_EXCEL_NUTRIKID = "OPEN_IMPORT_EXEL_NUTRIKID"
export const CLOSE_IMPORT_EXCEL_NUTRIKID = "CLOSE_IMPORT_EXEL_NUTRIKID"

export const UPLOAD_IMPORT_LIST_MATERIAL_NUTRIKID = "UPLOAD_IMPORT_LIST_MATERIAL_NUTRIKID"


