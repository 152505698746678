const styles = theme => ({
  background: {
    backgroundImage:'linear-gradient(0,#f1c40f,#c0392b)',
    padding: `40px ${theme.spacing}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 0 auto',
    flexDirection: 'column',
    minHeight: '100vh',
    textAlign: 'center'
  },
  wrapper: {
    flex: 'none',
    maxWidth: '400px',
    width: '100%',
    margin: '0 auto'
  },
  inputSelectStatus:{
    width:"100%"
}
});
export default styles;
