import { API_ENDPOINT } from '../contant'
import axiosServices from './api'

const urlCreateNewCrm = "CreateNewCrm"
const urlGetListCrm   = "GetListCrm"
const urlDeleteCrm    = 'DeleteCrm'
const urlUpdateCrm    = 'UpdateCrm'

export const CreateNewCrmApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlCreateNewCrm}`,item)
}

export const GetListCrmApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetListCrm}`,item)
}

export const DeleteItemCrmApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteCrm}`,item)
}

export const UpdateCrmApi =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateCrm}`,item)
}