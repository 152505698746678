import { toastSuccess } from "../../../../helper/toastHelper"
import { DISPATCH_LIST_MATERIAL_TO_STORE,DISPATCH_ADD_MATERIAL_MUTRIKID_TO_STORE,
OPEN_ADD_MATERI_NUTRIKID,CLOSE_ADD_MATERI_NUTRIKID,DISPATCH_ITEM_CATE_MATERIAL_NUTRIKID_TO_STORE,
DISPATCH_ADD_MATERI_NUTRIKID_TO_STORE,OPEN_IMPORT_EXCEL_NUTRIKID,CLOSE_IMPORT_EXCEL_NUTRIKID,
OPEN_UPDATE_MATERIAL_NUTRIKID,CLOSE_UPDATE_MATERIAL_NUTRIKID, DISPATCH_UPDATE_MATERIAL_NUTRIKID,
DISPATCH_TIEUHOC_OR_MAMNON
} from "../contant/material"
import {updateArrById} from '../../../../helper/fnAllways'
import { ConvertListAllMaterials } from "./fn"
const initialState={
    ListMaterialNutrikid:[],
    ListAllMaterialNutrikids:[],
    OpenAddMateriNutrikid:false,
    ItemCateMaterialNutrikid:"",
    OpenImportExcelNutrikid:false,
    OpenUpdateMaterialNutrikid:false,
    ItemUpdateMaterialNutrikid:"",
    MamNonOrTieuHoc:true//mamnon:true, tieu hoc:false
}

const Index =(state=initialState,action)=>{
    switch (action.type) {
        case DISPATCH_LIST_MATERIAL_TO_STORE:
            return{
                ...state,
                ListMaterialNutrikid:action.payload.item,
                ListAllMaterialNutrikids:ConvertListAllMaterials(action.payload.item)         
            }
        case DISPATCH_ADD_MATERIAL_MUTRIKID_TO_STORE:
            toastSuccess('Thêm mới thành công')         
            return{
                ...state,
                ListMaterialNutrikid:(state.ListMaterialNutrikid).concat(action.payload.item),
                ListAllMaterialNutrikids:ConvertListAllMaterials((state.ListMaterialNutrikid).concat(action.payload.item))         

            }
        case OPEN_ADD_MATERI_NUTRIKID:
            return{
                ...state,
                OpenAddMateriNutrikid:true
            }
        case CLOSE_ADD_MATERI_NUTRIKID:
            return{
                ...state,
                OpenAddMateriNutrikid:false
            }      
        case DISPATCH_ITEM_CATE_MATERIAL_NUTRIKID_TO_STORE:
            return{
                ...state,
                ItemCateMaterialNutrikid:action.payload.item
            }     
        case DISPATCH_ADD_MATERI_NUTRIKID_TO_STORE:
            toastSuccess('Thêm mới thành công')   
            return{
                ...state,
                ItemCateMaterialNutrikid:action.payload.item,
                ListMaterialNutrikid:updateArrById(state.ListMaterialNutrikid,action.payload.item),
                ListAllMaterialNutrikids:ConvertListAllMaterials(updateArrById(state.ListMaterialNutrikid,action.payload.item))
            }
        case OPEN_IMPORT_EXCEL_NUTRIKID:
            return{
                ...state,
                OpenImportExcelNutrikid:true
            }
        case CLOSE_IMPORT_EXCEL_NUTRIKID:
            return{
                ...state,
                OpenImportExcelNutrikid:false
            }    
        case OPEN_UPDATE_MATERIAL_NUTRIKID:
            return{
                ...state,
                OpenUpdateMaterialNutrikid:true,
                ItemUpdateMaterialNutrikid:action.payload.item
            }
        case CLOSE_UPDATE_MATERIAL_NUTRIKID:
            return{
                ...state,
                OpenUpdateMaterialNutrikid:false,
                ItemUpdateMaterialNutrikid:""
            }
        case DISPATCH_UPDATE_MATERIAL_NUTRIKID:
            toastSuccess('cập nhật thành công')   
            return{
                ...state,
                ListMaterialNutrikid:updateArrById(state.ListMaterialNutrikid,action.payload.item),
                ListAllMaterialNutrikids:ConvertListAllMaterials(updateArrById(state.ListMaterialNutrikid,action.payload.item)),
                ItemCateMaterialNutrikid:action.payload.item
            }
        case DISPATCH_TIEUHOC_OR_MAMNON:
            return{
                ...state,
                MamNonOrTieuHoc:action.payload.item.checkMamNon
            }

        default: return state
    }
}

export default Index