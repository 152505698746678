import {combineReducers} from 'redux'
import taskReducer from './task'
import uiLoadingReducer from './uiLoading'
import formTaskReducer from './formTask'
import authReducer from './auth'
import usersReducer from './users'
import userPageReducer from './pageUser'
import GovLoginReducer from './Gov/login'
import ActiveSoftFoodReducer from './Gov/softFood'
import DateinputReducer from './Gov/weeklyMeal/dateInput'
import CostStudentReducer from './Gov/cost/costStudent'
import MaterialReducer from './Gov/cost/material'
import StudentReducer from './Gov/studentGroup/student'
import CountEatReducer from './Gov/countEat/countEat'
import AlertReducer from './Gov/Alert'
import GovSocketReducer from './socketGov/index'
import HelperReducer from '../APP_ADMIN_PERSON/Reducer/helper'
//person
import AdminAddServiceReducer from '../APP_ADMIN_PERSON/Reducer/adminAPP_SERVICE'
import CreateRepoReducer from '../APP_ADMIN_PERSON/Reducer/createRepo'
import CateRepoReducer from '../APP_ADMIN_PERSON/Reducer/cateRepo'
//import MaterialsManagerRepoReducer from '../APP_ADMIN_PERSON/Person/App/QuanLyKho/MainTab/Material/Reducer'
import CategoriesManagerRepoReducer from '../APP_ADMIN_PERSON/Person/App/QuanLyKho/MainTab/Categories/Reducer'
import ImportExportManagerRepoReducer from '../APP_ADMIN_PERSON/Person/App/QuanLyKho/MainTab/ImportExport/reducer'

//staff quan ly nhan vien
import ManagerStaffReducer from '../APP_ADMIN_PERSON/Person/App/QuanlyNhanVien/reducer'
import AccountLoginStaffReducer from '../APP_ADMIN_PERSON/Person/TaiKhoanNhanVienDangNhap/reducer'
import QrcodeReducer from '../APP_ADMIN_PERSON/Reducer/qrcode'
import {reducer as formReducer} from 'redux-form'
import {connectRouter} from 'connected-react-router'
//managerAccountActive 
import ManagerAccountActiveGovReducer from '../ManagerAccountActiveGov/reducers/activeAccount'

//nutrikid
import MaterialNutrikidReducer from '../ComponentGov/SoftFoodTabMain/Nutrikid/reduce/material'
import MealNutrikidReducer     from '../ComponentGov/SoftFoodTabMain/Nutrikid/reduce/meal'
import TableAgeNutrikidReducer     from '../ComponentGov/SoftFoodTabMain/Nutrikid/reduce/tableAge'
import LibraryMealNutrikidReducer from '../ComponentGov/SoftFoodTabMain/Nutrikid/reduce/libraryMeal'
import ProcessNutrikidReducer     from '../ComponentGov/SoftFoodTabMain/Nutrikid/reduce/nutrikid'
import KiemThucReducer  from '../ComponentGov/SoftFoodTabMain/Nutrikid/reduce/kiemThuc'
import ThanhToanReducer from '../ComponentGov/SoftFoodTabMain/Nutrikid/reduce/thanhToan'
import CanDoSucKhoeReducer from '../ComponentGov/SoftFoodTabMain/CanDoSucKhoe/reduces'
//web Page Home

import GioiThieuWeReducer from '../container/Login/NotFoundPage/reducer/GioiThieu'
import StateCssReducer from '../container/Login/NotFoundPage/reducer/StateCss'
import ThietBiCongNgheReducer from '../container/Login/NotFoundPage/reducer/ThietBiCongNghe'
import ContactMainWebReducer from '../container/Login/NotFoundPage/reducer/Contact'
import ZaloAccessTokenReducer from '../container/Login/NotFoundPage/reducer/Zalo'
import HoldingTechReducer from '../container/Login/reducer/holdingtech'
//createWebMamnon
import WebAutoMamNonReducer from '../ComponentGov/SoftFoodTabMain/WebSiteMamNon/Reducer'
import RenderWebAutoMamNonReducer from '../container/Login/WebMamNon/reducer'
//MainWeb
import MainWebReducer from '../container/Login/GioiDinhTue/component/reducer'
import CrmReducer from './CRM'
//cong nghe
import LoginPageQuanTriReducer from '../container/Login/NotFoundPage/reducer/LOGIN_PAGE/QuanTri'
//sieu quan tri
import MenuBarSieuQuanTriReducer from '../container/Login/NotFoundPage/reducer/LOGIN_PAGE/OpenNavBarSieuQuanTri'
import MenuBarQuanTriReducer from '../container/Login/NotFoundPage/reducer/LOGIN_PAGE/OpenNavBarQuanTri'
import MenuBarNhanVienReducer from '../container/Login/NotFoundPage/reducer/LOGIN_PAGE/OpenNavBarNhanVien'
import MenuBarKhachHangReducer from '../container/Login/NotFoundPage/reducer/LOGIN_PAGE/OpenNavBarKhachHang'


const rootReducers = history => combineReducers({
      // Define reducers
      //admin
      MainWeb:MainWebReducer,
      RenderWebAutoMamNon:RenderWebAutoMamNonReducer,
      WebAutoMamNon:WebAutoMamNonReducer,
      holdingTech:HoldingTechReducer,
      task:taskReducer,
      uiLoading:uiLoadingReducer,
      formTask:formTaskReducer,
      auth:authReducer,
      form:formReducer,
      users:usersReducer,
      userPage:userPageReducer,     
      AdminAddService:AdminAddServiceReducer,
      CreateRepo:CreateRepoReducer,
      CateRepo:CateRepoReducer,
      Helper:HelperReducer,
      //admin
      //gov
      GovLogin:GovLoginReducer,
      ActiveSoftFood:ActiveSoftFoodReducer,
      DateInput:DateinputReducer,     
      //gov
      //cost 
       CostStudent:CostStudentReducer,
       Material:MaterialReducer,
       //student
       Student:StudentReducer,
       CountEat:CountEatReducer,
       Alert:AlertReducer,
       GovSocket:GovSocketReducer,
       //managerAccountActiveGov 
       ManagerAccount:ManagerAccountActiveGovReducer,
       ManagerStaff:ManagerStaffReducer,
       AccountLoginStaff:AccountLoginStaffReducer,
       Qrcode:QrcodeReducer,
       //MaterialsManagerRepo:MaterialsManagerRepoReducer,
       ImportExportManagerRepo:ImportExportManagerRepoReducer,
       CategoriesManagerRepo:CategoriesManagerRepoReducer,
       //nutrikid
       MaterialNutrikid:MaterialNutrikidReducer,
       MealNutrikid:MealNutrikidReducer,
       TableAgeNutrikid:TableAgeNutrikidReducer,
       LibraryMealNutrikid:LibraryMealNutrikidReducer,
       ProcessNutrikid:ProcessNutrikidReducer,
       KiemThucNutrikid:KiemThucReducer,
       ThanhToan:ThanhToanReducer,
       CanDoSucKhoe:CanDoSucKhoeReducer,
       //home
       //web
       GioiThieuWeb:GioiThieuWeReducer,
       StateCss:StateCssReducer,
       ThietBiCongNghe:ThietBiCongNgheReducer,
       ContactMainWeb:ContactMainWebReducer,
       ZaloAccessToken:ZaloAccessTokenReducer,
       //HomeRouter:WebHomeReducer,
       //COng nghe
       LoginPageQuanTri:LoginPageQuanTriReducer,
       //sieu quan tri meubar
       MenuBarSieuQuanTri:MenuBarSieuQuanTriReducer,
       MenuBarQuanTri:MenuBarQuanTriReducer,
       MenuBarNhanVien:MenuBarNhanVienReducer,
       MenuBarKhachHang:MenuBarKhachHangReducer,
       Crm:CrmReducer,
       router: connectRouter(history)
})

export default rootReducers