import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import EditStaff from '../FormPerson/index'
import {useStyles} from './style'


 const StaffFormEdit =({openEdit,editForm,handleCloseEdit,handleAlertColorEdit})=>{
    const classes = useStyles()    
    
    return (
       <>
          <Dialog open={openEdit} onClose={handleCloseEdit} aria-labelledby="form-dialog-title">
            <div className={classes.bgcolor}>            
            <div className={classes.textCv}> Sửa thông tin - {editForm.humanName}</div>
              <CloseIcon className={classes.icon} onClick={handleCloseEdit}/>
            </div>            
                  <EditStaff editForm={editForm} handleCloseEdit={handleCloseEdit} handleAlertColorEdit={handleAlertColorEdit}                
                  />           
          </Dialog> 
      </>  
    )
  }

  export default StaffFormEdit