import {  makeStyles } from '@material-ui/core/styles';
import {red,blue} from '@material-ui/core/colors'

export const useStyles =makeStyles(theme=>({
    colorText:{
      color:red[500]
    },
    dateTime:{
      marginTop:'-30px',
      color:blue[700],
      fontWeight:700
    }
}))