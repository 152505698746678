import {DISPATCH_LIST_PRICE_SOFT_TO_STORE} from '../contant/holdingtech'

export const initialState={
    ListPriceSoft:[]
}

const IndexReducer =(state=initialState,action)=>{
    switch (action.type) {
        case DISPATCH_LIST_PRICE_SOFT_TO_STORE:
            return{
                ...state,
                ListPriceSoft:action.payload.item.listSoftPrice
            }          
    
        default: return state
    }
}

export default IndexReducer