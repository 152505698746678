import {call,put,takeLatest} from 'redux-saga/effects'
import { DispatchAddJobStaffNhanVienStore, DispatchGetInfoCusNhanVienToStore, DispatchGetSearchingJobStaffNhanVien, UpdateJobStaffNhanVienStore } from '../../action/INFO_GROUP_NHAN_VIEN'
import { LoadingComponentFalse, LoadingComponentOpen } from '../../action/Loading'
import { AddJobStaffNhanVienApi, AddJobStaffNhanVienVatTuDbApi, ApproveJobStaffFalseApi, ApproveJobStaffTrueApi, DeleteJobStaffNhanVienVatTuDbApi, GetInfoCusNhanVienApi, SearchJobStaffNhanVienApi, SearchJobStaffNhanVien__Api, UpdateJobStaffNhanVienApi, UpdateJobStaffNhanVienVatTuDbApi } from '../../api/ROOT_CONG_NGHE/InfoGroupNhanVien'
import { UpdateJobStaffCongLaoDongApi } from '../../api/ROOT_CONG_NGHE/InfoGroupQuanTri'
import { ADD_JOB_STAFF_NHAN_VIEN, ADD_JOB_STAFF_NHAN_VIEN_VAT_TU, APPROVE_JOB_STAFF_FALSE, APPROVE_JOB_STAFF_TRUE, DELETE_JOB_STAFF_NHAN_VIEN, DELETE_JOB_STAFF_NHAN_VIEN_VAT_TU_DB, GET_INFO_CUS_NHAN_VIEN, SEARCHING_GET_JOB_STAFF_NHAN_VIEN, SEARCHING_GET_JOB_STAFF_NHAN_VIEN__, UPDATE_JOB_STAFF_CONG_LAO_DONG, UPDATE_JOB_STAFF_NHAN_VIEN, UPDATE_JOB_STAFF_NHAN_VIEN_VAT_TU_DB } from '../../contant/LOGIN_PAGE/OpenNavBarNhanVien'

const FAIL    = 'fail'
const SUCCESS = 'success'

function* GetInfoCusNhanVienSaga({payload}){
    yield put(LoadingComponentOpen())
    let res = yield call(GetInfoCusNhanVienApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(DispatchGetInfoCusNhanVienToStore(res.data.data))
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* AddJobStaffNhanVienSaga({payload}){
    yield put(LoadingComponentOpen())
    let res = yield call(AddJobStaffNhanVienApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(DispatchAddJobStaffNhanVienStore(res.data.data))
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* SearchGetJobStaffNhanVienSaga({payload}){
    yield put(LoadingComponentOpen())
    let res = yield call(SearchJobStaffNhanVienApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(DispatchGetSearchingJobStaffNhanVien(res.data.data))
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* SearchGetJobStaffNhanVien__Saga({payload}){
    yield put(LoadingComponentOpen())
    let res = yield call(SearchJobStaffNhanVien__Api,payload.item)
    if(res.data.result===SUCCESS){
        yield put(DispatchGetSearchingJobStaffNhanVien(res.data.data))
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* UpdateJobStaffNhanVienSaga({payload}){
    yield put(LoadingComponentOpen())
    let res = yield call(UpdateJobStaffNhanVienApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(UpdateJobStaffNhanVienStore(payload.item))
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* DeleteJobStaffNhanVienSaga({payload}){
    yield put(LoadingComponentOpen())
    let res = yield call(UpdateJobStaffNhanVienApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* UpdateJobStaffNhanVienVatTuDbSaga({payload}){
    yield put(LoadingComponentOpen())
    let res =  yield call(UpdateJobStaffNhanVienVatTuDbApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* DeleteJobStaffNhanVienVatTuDbSaga({payload}){
    yield put(LoadingComponentOpen())
    let res =  yield call(DeleteJobStaffNhanVienVatTuDbApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* AddJobStaffNhanVienVatTuDbSaga({payload}){
    yield put(LoadingComponentOpen())
    let res =  yield call(AddJobStaffNhanVienVatTuDbApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* ApproveJobStaffTrueSaga({payload}){
    yield put(LoadingComponentOpen())
    let newItem ={_id:payload.item._id,approve:payload.item.approve}
    let res = yield call(ApproveJobStaffTrueApi,newItem)
    if(res.data.result===SUCCESS){
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* ApproveJobStaffFalseSaga({payload}){
    yield put(LoadingComponentOpen())
    let newItem ={_id:payload.item._id,approve:payload.item.approve}
    let res = yield call(ApproveJobStaffFalseApi,newItem)
    if(res.data.result===SUCCESS){
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* UpdateJobStaffCongLaoDongSaga({payload}){
    yield put(LoadingComponentOpen())
    let newItem ={_id:payload.item._id,CongLaoDong:payload.item.CongLaoDong}
    let res = yield call(UpdateJobStaffCongLaoDongApi,newItem)
    if(res.data.result===SUCCESS){
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* IndexSaga(){    
    yield takeLatest(UPDATE_JOB_STAFF_CONG_LAO_DONG,UpdateJobStaffCongLaoDongSaga)
    yield takeLatest(APPROVE_JOB_STAFF_TRUE,ApproveJobStaffTrueSaga)
    yield takeLatest(APPROVE_JOB_STAFF_FALSE,ApproveJobStaffFalseSaga)

    yield takeLatest(GET_INFO_CUS_NHAN_VIEN,GetInfoCusNhanVienSaga)
    yield takeLatest(ADD_JOB_STAFF_NHAN_VIEN,AddJobStaffNhanVienSaga)
    yield takeLatest(SEARCHING_GET_JOB_STAFF_NHAN_VIEN,SearchGetJobStaffNhanVienSaga)
    yield takeLatest(SEARCHING_GET_JOB_STAFF_NHAN_VIEN__,SearchGetJobStaffNhanVien__Saga)
    yield takeLatest(UPDATE_JOB_STAFF_NHAN_VIEN,UpdateJobStaffNhanVienSaga)
    yield takeLatest(DELETE_JOB_STAFF_NHAN_VIEN,DeleteJobStaffNhanVienSaga)
    yield takeLatest(UPDATE_JOB_STAFF_NHAN_VIEN_VAT_TU_DB,UpdateJobStaffNhanVienVatTuDbSaga)
    yield takeLatest(DELETE_JOB_STAFF_NHAN_VIEN_VAT_TU_DB,DeleteJobStaffNhanVienVatTuDbSaga)
    yield takeLatest(ADD_JOB_STAFF_NHAN_VIEN_VAT_TU,AddJobStaffNhanVienVatTuDbSaga)    
}   

export default IndexSaga