import {CLOSE_ALERT_YEAR,OPEN_ALERT_YEAR,
  OPEN_INFO_USER_DETAIL,CLOSE_INFO_USER_DETAIL,OPEN_ALERT_TEMPLETE,CLOSE_ALERT_TEMPLETE,
  OPEN_STUDENT_EAT_SEVEN_DAY,CLOSE_STUDENT_EAT_SEVEN_DAY,DISPATCH_ARR_STUDENT_EAT_SEVEN_DAY,
  ALERT_CLOSE_INFO_COST,ALERT_OPEN_INFO_COST
} from '../../contant/Gov/Alert'

let initialState={
  OpenAlertYear:false,
  OpenInfoUserDetail:false,
  OpenAlertTemplete:false,
  OpenStudentEatSevenDay:false,
  ItemClass:"",
  AlertOpenInfoCost:false
}

const AlertReducer =(state=initialState,action)=>{
  switch (action.type) {
    case OPEN_ALERT_YEAR:
      return{
        ...state,
        OpenAlertYear:true
      }
    case CLOSE_ALERT_YEAR:
      return{
        ...state,
        OpenAlertYear:false
      }      
    case OPEN_INFO_USER_DETAIL:
      return{
        ...state,
        OpenInfoUserDetail:true
      }
    case CLOSE_INFO_USER_DETAIL:
      return{
        ...state,
        OpenInfoUserDetail:false
      }
    case OPEN_ALERT_TEMPLETE:
      return{
        ...state,
        OpenAlertTemplete:true
      }
    case CLOSE_ALERT_TEMPLETE:
      return{
        ...state,
        OpenAlertTemplete:false
      }
    case OPEN_STUDENT_EAT_SEVEN_DAY:
      return{
        ...state,
        OpenStudentEatSevenDay:true
      }
    case CLOSE_STUDENT_EAT_SEVEN_DAY:
      return{
        ...state,
        OpenStudentEatSevenDay:false
      }
    case DISPATCH_ARR_STUDENT_EAT_SEVEN_DAY:
      return{
        ...state,
        ItemClass:action.payload.item.map(v=>v)
      }
  case ALERT_OPEN_INFO_COST:
    return{
      ...state,
      AlertOpenInfoCost:true
    }
  case ALERT_CLOSE_INFO_COST:
    return{
      ...state,
      AlertOpenInfoCost:false
    }
    default: return state
      
  }
}

export default AlertReducer