import axiosServices from '../../../../api-services/api'
import {API_ENDPOINT} from  '../../../../contant/index'


const urlGetThanhToanByUser        = 'getThanhToanByUser'
const urlUpdateThanhToanByUser     = 'updateThanhToanByUser'
const urlSendEmailNppNameBuy       = 'sendEmailNppNameBuy'
const urlGetAddPaymentKidTop       = 'GetAddPaymentKidTop'
const urlUpdatePaymentKidTop       = 'UpdatePaymentKidTop'

export const UpdatePaymentKidTopApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdatePaymentKidTop}`,item)
}

export const GetAddPaymentKidTopApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetAddPaymentKidTop}`,item)
}

export const GetThanhToanByUserApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetThanhToanByUser}`,item)
}

export const UpdateThanhToanByUserApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateThanhToanByUser}`,item)
}

export const SendEmailNppNameBuyApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlSendEmailNppNameBuy}`,item)
}