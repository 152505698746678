import {call, put, takeLatest} from 'redux-saga/effects'
import { DispatchAccessTokenToStore } from '../action'
import { CreateZaloAppAccessTokenApi, GetAccessTokenApi, UpdateAccessTokenApi } from '../api/ZaloApp'
import { CREATE_APP_ZALO_ACCESS_TOKEN, GET_ACCESS_TOKEN,UPDATE_APP_ZALO_ACCESS_TOKEN } from '../contant/Zalo'

function* CreateAppZaloAccessTokenSaga({payload}){
    let res = yield call(CreateZaloAppAccessTokenApi,payload.item)
    if(res.data.result==='success'){
        yield put(DispatchAccessTokenToStore(res.data.data))
    }
}

function* GetAccessTokenSaga({}){
        let res =  yield call(GetAccessTokenApi)
        if(res.data.result){
            yield put(DispatchAccessTokenToStore(res.data.data))
        }
}

function* UpdateAppZaloAccessTokenSaga({payload}){
        let res = yield call(UpdateAccessTokenApi,payload.item)
        if(res.data.result==='success'){
            alert('Cập nhật thành công!')
        }
        if(res.data.result!=='success'){
            alert('Lỗi, Kiểm Tra Lại!')
        }
        
}

function* Index(){
    yield takeLatest(CREATE_APP_ZALO_ACCESS_TOKEN,CreateAppZaloAccessTokenSaga)
    yield takeLatest(GET_ACCESS_TOKEN,GetAccessTokenSaga)
    yield takeLatest(UPDATE_APP_ZALO_ACCESS_TOKEN,UpdateAppZaloAccessTokenSaga)
}

export default Index