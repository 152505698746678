import {call,put, takeLatest} from 'redux-saga/effects'
import {DISPATCH_VALUE_ADD_CREATE_REPO,DISPATCH_VALUE_ADD_CREATE_REPO_TO_DB, LIST_ALL_CREATE_REPO,UPDATE_CREATE_REPO_TO_DB, UPDATE_VIEW_INFO_REPO
} from '../../Contant/Person/createRepo'
import {GET_LIST_SUPPLIES} from '../../Contant/Person/cateRepo'
import {DispatchValueAddCreateRepoToStore}from '../../Actions/createRepo'
import {AddCreateRepoApi,ListAllCreateRepoApi,updateCreateRepoApi,AddSuppliesApi,EditSuppliesApi, AddCustomerApi,GetCustomerApi, EditCustomerApi, UpdateViewInfoRepoApi, GetByIdCustomerApi} from '../../Api-services/person/createRepo'
import {
   //LoadingNow,LoadingStop,
    OpenAlertSuccess,CloseAlertSuccess}from '../../Actions/helper'
import { ALERT_TEXT_SUCCESS } from '../../Contant/helperText'
import {ADD_SUPPLIES,EDIT_SUPPLIES,ADD_CUSTOMER,GET_CUSTOMER,EDIT_CUSTOMER} from '../../Contant/Person/supplies'
import {AddSuppliesSuccessAndDispatchToStore, DispatchAddCustomerToStore, DispatchListCustomerToStore, EditCustomerAction, UpdateItemDepartmentAction} from '../../Actions/cateRepo'
import {DispatchAddSuccessCreateRepoToStore,DispatchListAllCreateRepoToStore} from '../../Actions/createRepo'
import {GetListSuppliesByUserIdRePo} from '../../Api-services/person/createRepo'
import {DispatchGetListSuppliesByUserIdToStore,AddSuppliesSuccessNotification,OpenEditSuppliseNotification} from '../../Actions/cateRepo'
import { loadingSuccess as LoadingNow, loadingFail as LoadingStop } from '../../../actions/uiLoading'

const SUCCESS = 'success'
const FAIL    = 'fail'


function* DispatchValueAddCreateRepoSaga({payload}){
    yield put(DispatchValueAddCreateRepoToStore(payload))
}

function* DispatchValueAddCreateRepoToDbSaga({payload}){
    yield put(LoadingNow())
   let res = yield call(AddCreateRepoApi,payload.item)
   if(res.data.result=SUCCESS){
        yield put(DispatchAddSuccessCreateRepoToStore(res.data.data))
        yield put(LoadingStop())
        yield put(OpenAlertSuccess(ALERT_TEXT_SUCCESS))
   }else{
        return alert('Mã Lỗi, Lưu Dữ liệu Không thành công-CreateRepo DB')
   }  
}

function* ListAllCreateRepoSaga({payload}){
    let id={
        id:payload.item[0].userId
    }
   let res = yield call(ListAllCreateRepoApi,id)
   if(res.data.result===SUCCESS){
       yield put(DispatchListAllCreateRepoToStore(res.data.data))
   }else{
    return alert('Mã Lỗi, Lấy Dữ liệu Không thành công-ListAllCreate DB')
   }
}

function* UpdateCreateRepoToDbSaga({payload}){
    yield put(LoadingNow())
    let res  = yield call(updateCreateRepoApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(LoadingStop())
    }else{
        return alert('Mã lỗi, cập nhật lỗi, UpdateCreateRepo')
    }
}

function* AddSuppliesSaga({payload}){
    yield put(LoadingNow())
    let res = yield call(AddSuppliesApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(LoadingStop())
        yield put(AddSuppliesSuccessAndDispatchToStore(res.data.data))
        yield put(AddSuppliesSuccessNotification())
    }else{
        return alert('Mã lỗi, cập nhật lỗi, UpdateCreateRepo')
    }
}
function* EditSuppliesSaga({payload}){
    yield put(LoadingNow())
    let res =yield call(EditSuppliesApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(LoadingStop())
        yield put(OpenEditSuppliseNotification())
    }
}


function* GetListSuppliesCateRepoSaga({payload}){
    yield put(LoadingNow())
    let res = yield call(GetListSuppliesByUserIdRePo,payload.item)
    if(res.data.result===SUCCESS){
        yield put(LoadingStop())
        yield put(DispatchGetListSuppliesByUserIdToStore(res.data.data))
    }
}

function* AddCustomerSaga({payload}){   
    let res = yield call(AddCustomerApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(DispatchAddCustomerToStore(res.data.data))
        yield put(OpenAlertSuccess('Thêm thành công'))
    }else{
        yield put(CloseAlertSuccess('Lỗi, cập nhật thành công'))
    }
}

function* GetCustomerSaga({payload}){   
    let res = yield call(GetCustomerApi,payload.item)
    if(res.data.result===SUCCESS){        
        //console.log(res.data.data)
        yield put(DispatchListCustomerToStore(res.data.data))
    }
}

function* EditCustomerSaga({payload}){
    yield put(LoadingNow())   
    let res  = yield call(EditCustomerApi,payload.item)
    let res1 = yield call(GetByIdCustomerApi,payload.item)
    let res2 = yield call(GetCustomerApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(EditCustomerAction(res2.data.data))
        yield put(UpdateItemDepartmentAction(res1.data.data))
        yield put(OpenAlertSuccess('Cập Nhật Thành công'))
        yield put(LoadingStop())
    }else{
        yield put(CloseAlertSuccess('Lỗi, kiểm tra lại'))
    }
}

function* UpdateViewInfoRepoSaga({payload}){
    yield put(LoadingNow())
    let res= yield call(UpdateViewInfoRepoApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(LoadingStop())
    }
}

function* CreateRepo (){
    yield takeLatest(DISPATCH_VALUE_ADD_CREATE_REPO,DispatchValueAddCreateRepoSaga)
    yield takeLatest(DISPATCH_VALUE_ADD_CREATE_REPO_TO_DB,DispatchValueAddCreateRepoToDbSaga)
    yield takeLatest(LIST_ALL_CREATE_REPO,ListAllCreateRepoSaga)
    yield takeLatest(UPDATE_CREATE_REPO_TO_DB,UpdateCreateRepoToDbSaga)
    yield takeLatest(ADD_SUPPLIES,AddSuppliesSaga)
    yield takeLatest(EDIT_SUPPLIES,EditSuppliesSaga)
    yield takeLatest(GET_LIST_SUPPLIES,GetListSuppliesCateRepoSaga)
    yield takeLatest(ADD_CUSTOMER,AddCustomerSaga)
    yield takeLatest(GET_CUSTOMER,GetCustomerSaga)
    yield takeLatest(EDIT_CUSTOMER,EditCustomerSaga)
    yield takeLatest(UPDATE_VIEW_INFO_REPO,UpdateViewInfoRepoSaga)
}

export default CreateRepo