import React from 'react';
import { MainCustomer, SliderCustomer, } from './style';
import CaroselCustomer from './ListCarosel'
// import TinhUuViet from '../TinhUuViet'

const Index = React.memo(() => {
    
    return (
        <MainCustomer id="KHACH_HANG">       
                                             
                     <SliderCustomer>
                         <CaroselCustomer/>
                    </SliderCustomer>

                    {/* <FooterIcon>
                        <ImgLogo src={LogoKidTop} alt="cong-dong-gioi-dinh-tue"/>
                    </FooterIcon> */}
                    {/* <TinhUuViet/> */}

        </MainCustomer>
                   
    );
});

export default Index;