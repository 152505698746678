import styled,{css,keyframes} from 'styled-components'

export const MainTextSlide = styled.div`
        margin:auto;
        width:100%;
        height:120px;
        position:fixed;
        top:0;
        left:0;
        z-index:0;
        background:white;
`

export const ImgLogo = styled.img`  
    width:70px;
    height:80px;
    object-fit:contain;
    position:absolute;
    z-index:1;
    top:45px;
    left:calc(50% - 35px);
`