import { Typography } from '@material-ui/core'
import React,{useEffect,useState} from 'react'

const formatDate=(date)=>{
  if(!date) return ""
  const hours     = `0${date.getHours()}`.slice(-2)
  const minutes   = `0${date.getMinutes()}`.slice(-2)
  const seconds   = `0${date.getSeconds()}`.slice(-2)
  return `${hours}:${minutes}:${seconds}`
}

export default function Index() {
  const [timeString,setTimeString] = useState("")
  useEffect(() => {
      const TimeInterval =   setInterval(() => {
          const now = new Date()
          const newTimeString = formatDate(now)
          setTimeString(newTimeString)          
        }, 1000)
       return()=>{
         clearInterval(TimeInterval)
       } 
  }, [])
  return (
    <>
      <Typography style={{color:'red'}}>
        {timeString}
      </Typography>
    </>
  )
}
