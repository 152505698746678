export const UpdateByIdStudent=(itemList,item)=>{ 
   return [itemList].map(v=>{
        return {
                ...v,
                listStudent:updateArr(v.listStudent,item)
        }     
    })[0]
}

const updateArr=(arr,value)=>{
    let index = arr.findIndex(item=>item.idStudent===value.idStudent)
    if(index!==-1){
      let newArr =[
        ...arr.slice(0,index),
        value,
        ...arr.slice(index+1)
      ]   
      return newArr
    }else{
      return null
    }  
}

export const UpdateByIdStudentEmail=(itemList,item,valueEmail)=>{
      let newItem = [item].map(v=>{
        return{
          ...v,
          sendEmail:valueEmail
        }
      })[0]
      return UpdateByIdStudent(itemList,newItem)
}

export const UpdateByIdStudentTel=(itemList,item,valueTel)=>{
  let newItem = [item].map(v=>{
    return{
      ...v,
      sendSms:valueTel
    }
  })[0]
  return UpdateByIdStudent(itemList,newItem)
}

export const FindValueEmail=(value)=>{
    if(value.sendEmail===true)  return false
    if(value.sendEmail===false) return true
}

export const FindValueTel=(value)=>{
  if(value.sendSms===true)  return false
  if(value.sendSms===false) return true
}