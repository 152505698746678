import React,{useEffect,useState,createContext} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {SHOW_MANAGER_GOV_SOCKET} from '../../../contant/SocketGov/index'
import io from 'socket.io-client'
import {API_ENDPOINT} from '../../../contant/index'
import {Grid,Paper} from '@material-ui/core'
import {GROUP_USER} from '../../../contant/index'
import {useStyles} from './style'
import GovSocketComponent from '../../AdminSocket/GovSocket/index'
let  socket ;

export const GovMain = createContext()

function Index() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(state=>state.users.loginUser)
  const showGovSocket = useSelector(state=>state.GovSocket.OpenGovSocket)
  const [arrUserGov,setArrUserGov] = useState([])
  
  useEffect(() => {  
      socket = io(API_ENDPOINT);
      let valueUser ={
      user,
      id:socket.id
    }
    //admin dang nhap, gui data cho server
    socket.emit('loginAdmin',valueUser)   
    //su kien khi , va truoc do gov da login , gui data cho admin  
    socket.on('govToAdmin',data=>{
      setArrUserGov(data)
    })  
    //khi gov mat ket noi, gov gui data cho admin
    socket.on('userGovLoginDis',data=>{
      setArrUserGov(data)
    })  
    //khi gov dang nhap sau khi admin da dang nhap
    socket.on('userGovLogin',data=>{
      setArrUserGov(data)
    })    
   
  return ()  =>{
      socket.emit('disconnect')
      socket.off()
  }      
  },[API_ENDPOINT,arrUserGov,user])
  
  const showValue=(value)=>{
    //nha nuoc 5
      if(value===5){
        //code cho nha nuoc
        dispatch({
          type:SHOW_MANAGER_GOV_SOCKET,
           payload:{item:arrUserGov}
        })
      }
  }
  const Html =()=>{
    return(
      <Grid container spacing={2}>        
        {GROUP_USER.map((v,i)=>{
          return (    
             <Grid item xs={4} sm={3} key={i}>
                 <Paper className={classes.paper} onClick={()=>showValue(v.value)}>{v.name.toLocaleUpperCase()}</Paper>
             </Grid>             
          )
        })}              
      </Grid>   
    )
  }
  return (
    <> 
    {!showGovSocket&&Html()}               
    {/* gov */}
         {showGovSocket&&
         <GovMain.Provider value={arrUserGov}>
              <GovSocketComponent/>
         </GovMain.Provider>         
         }       
    {/* gob */}
    </>
  )
}

export default Index
