import { OPEN_MENU_BAR_SIEU_QUAN_TRI,CLOSE_MENU_BAR_SIEU_QUAN_TRI } from "../../contant/LOGIN_PAGE/OpenNavBarSieuQuanTri"

let initialState={
    OpenMenuBarSieuQuanTri:false
}

const CREATE_SUCCESS = "Khởi Tạo Thành Công"
const UPDATE_SUCCESS = "Cập Nhật Thành Công"
const UPDATE_FAIL    = "Cập Nhật Lỗi"

const IndexReducer =(state=initialState,action)=>{
            switch (action.type) {
              
                case OPEN_MENU_BAR_SIEU_QUAN_TRI:
                    return{
                        ...state,
                        OpenMenuBarSieuQuanTri:!state.OpenMenuBarSieuQuanTri
                    }

                case CLOSE_MENU_BAR_SIEU_QUAN_TRI:
                        return{
                            ...state,
                            OpenMenuBarSieuQuanTri:!state.OpenMenuBarSieuQuanTri
                        }

           
                default:   return state;
            }
}

export default IndexReducer