const style = theme => ({
  buttonAlign: {
    display: "flex",
    alignItem: "center",
    justifyContent: "flex-end"
  },
  bgcolor:{
        background:theme.colorPink.primary,
        justifyContent:"space-between",
        display:"flex",
        alignItem:"center",
        padding:2
  },
  textCv:{   
    fontSize:19,
    padding:5,
    textTransform:"capitalize"
  },
  icon:{
    cursor:"pointer",
    fontSize:25
  }
});

export default style;
