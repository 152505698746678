import React from 'react'
import {convertDDMMYY, showDateName,nameWeek,numWeek,showYYMMDD} from '../dateTime/handleDMY'
import {Typography} from '@material-ui/core'
import {changeUpperCase} from '../fnAllways'

export const UpdateItem =(list,valueItem)=>{
  //console.log(list)
  if(list!==undefined&&list!==null){
    let index = list.findIndex(item=>item.date===valueItem.date)   
        if(index!==-1){
            let newList = [
              ...list.slice(0,index),
              valueItem,
              ...list.slice(index+1)
            ]
            return {list:newList}
        }else{
          return []
        }
  }
     
}

export const UpdateItemListAllUser =(arr,value)=>{ 
 let index = arr.findIndex(item=>showYYMMDD(item.dateMenu)===value.dateMenu)
 if(index!==-1){
  let newList = [
    ...arr.slice(0,index),
    value,
    ...arr.slice(index+1)
  ]
  return {arr:newList}
}else{
return []
}
}



export const UpdateItemAfterEdit =(list,valueItem)=>{
      let a = convertDDMMYY(valueItem.dateMenu)
      let dateName = showDateName(valueItem.dateMenu)
      let nameweek = nameWeek(valueItem.dateMenu)
      let numweek = numWeek(valueItem.dateMenu)      
         //console.log(valueItem.dateMenu)
      let newObject={
        dateName, 
        numWeek:numweek,
        nameWeek:nameweek,
        date:a,
        listFoodPri:valueItem.listFoodPri,
        listFoodSe:valueItem.listFoodSe,
        listFoodPri2: valueItem.listFoodPri2,
        listFoodSe2: valueItem.listFoodSe2,
        listFoodPri3: valueItem.listFoodPri3,
        listFoodSe3: valueItem.listFoodSe3,
        userId:valueItem.userId,
        _id:valueItem._id,
        dateMenu:valueItem.dateMenu          
      }
      let valueObject ={
        listFoodPri:valueItem.listFoodPri,
        listFoodSe:valueItem.listFoodSe,
        listFoodPri2: valueItem.listFoodPri2,
        listFoodSe2: valueItem.listFoodSe2,
        listFoodPri3: valueItem.listFoodPri3,
        listFoodSe3: valueItem.listFoodSe3,
        userId:valueItem.userId,
        _id:valueItem._id,
        dateMenu:valueItem.dateMenu          
      }
      let value = UpdateItem(list,newObject)  
      return {value,valueObject}
}

export const handleStringCharacter=(str)=>{
      if(str!==undefined&&str!==null){
        let newStr=str.split(/[.,]/)       
        return newStr.map((v,i)=>{
          return(       
              <Typography key={i}>{changeUpperCase(v)}</Typography>
          )
        })
      }else{
        return ""
      }  
    
}


