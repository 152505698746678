import { showMMYY } from "../../../../helper/dateTime/handleDMY"
import { DeleteItemById, updateArrById,  } from "../../../../helper/fnAllways"
import { STATUS_PROCESS,STATUS_COMPLETE,STATUS_READY } from "../../App/QuanlyNhanVien/component/Fn"
import { FindNameTask } from "./AddTaskJob/fn"
import _ from 'lodash'

const NUMBER_SHOW_ID = 6
const NUMBER_SHOW_ID_MEAL = 8
export const  TECHNICAL_STAFF =[
  {value:1,name:'thay thế'},//sua chua khong bao hanh
  {value:2,name:'bảo trì'},//sua chua co bao hanh
  {value:3,name:'bán mới'}//ban moi 1 thiet bi hoa 1 khôi linh kien
]

export const WARANTY =[
  {name:"1 tháng"},
  {name:'3 tháng'},
  {name:'6 tháng'},
  {name:'9 tháng'},
  {name:'12 tháng'},
  {name:'18 tháng'},
  {name:'24 tháng'},
  {name:'36 tháng'},
  {name:'test'}  
]



export const TYPE_OF_NAME_MATERIAL_THAY_THE = "thay thế(sửa chữa)" //linh kien nhỏ, sủa a
export const TYPE_OF_NAME_MATERIAL_BAO_TRI  = "bảo trì"
export const TYPE_OF_NAME_MATERIAL_BAN_MOI  = "bán mới" //theo bộ nguyên đai nguyên kiện

export const TYPE_OF_IMPORT_REPO   = "nhập kho"
export const TYPE_OF_SERVICE       = "dịch vụ"
export const PAYMENT_STAFF         = "Nhân viên tạm ứng tiền thanh toán" //cong ty nơ tien nhanvien
export const PAYMENT_MASTER        = "Công ty đã thanh toán"
export const PAYMENT_MASTER_NOT    = "Công ty chưa thanh toán"
//payment chi tiet phan loai vat tu nhan vien lay hang di thi cong
export const PAYMENT_CUSTOMER_NOT  = "Chưa thanh toán"
export const PAYMENT_CUSTOMER_DONE = "Đã thanh toán-nộp công ty"
export const PAYMENT_CUSTOMER_DONE_NOT_SEND_COMPANY = "Đã thanh toán-chưa nộp công ty"
export const GHI_CONG_NO = "Ghi công nợ"
export const PAYMENT_STAFF_BUY_MATERIAL_IMPORT = "ứng tiền mua vật tư"
export const PAYMENT_STAFF_GET_MATERIAL        = "hàng xuất kho"
export const PAYMENT_STAFF_BUY_MATERIAL_IMPORT_IN = "ứng tiền mua vật tư nhập vào kho"
export const PAYMENT_STAFF_TASK_JOB  = "công lắp đặt"
export const INFO_BEGIN              ="Thông tin ban đầu"
export const INFO_PROCESS            ="Thông tin đã xử lý"
export const PAYMENT_MONEY           ="Doanh Thu"

//payment chi tiet phan loai vat tu nhan vien lay hang di thi cong

export const PAYMENT_MASTER_AND_IMPORT = "Hàng đã có trong kho"
export const PAYMENT_EMPTY         = ""
export const PAY_TASK_JOB          = "công tác phí"
export const NOT_APPROVE           = "chưa phê duyệt"
export const ACCEPT_APPROVE        = "chấp nhận phê duyệt"
export const DONOT_ACCEPT_APPROVE  = "không được phê duyệt"

export const PAYMENT_STATUS =[PAYMENT_STAFF,PAYMENT_MASTER,PAYMENT_MASTER_NOT,PAYMENT_MASTER_AND_IMPORT]
export const SearchImportFromStaff=(arr,search)=>{
  return arr.filter(v=>v.dateTime.indexOf(search)!==-1||
                      v.nameRepo.indexOf(search)!==-1 ||
                      v.nameSupplies.indexOf(search)!==-1||
                      v.approveStatus.indexOf(search)!==-1||
                      v.payment.indexOf(search)!==-1||
                      v.nameStaff.toLowerCase().indexOf(search)!==-1
  )
}
export const handleShowCustomer=(item,arrCustomer)=>{
  //if(item!==null){
    if(item.taskJob.length!==0&&item.taskJob[0]!==null){
     let arrDepartmentNotName = item.taskJob
     let arrDepartmentInName  = arrCustomer.filter(v=>v._id===item.taskJob[0].idCustomer)
     if(arrDepartmentInName.length!==0){
        let arrDepartment = handleArrResultDepartment(arrDepartmentNotName,arrDepartmentInName[0].infoCustumer.deparment)
        return {Customer:arrDepartmentInName,arrDepartment}
     }     
    }   
  //}  
}

const handleArrResultDepartment=(arrNotName,arrInName)=>{    
      let result= arrNotName.map(v=>{
        let index = arrInName.findIndex(vv=>vv._id===v.idDepartmentCustomer)
        if(index!==-1){
          return{
            ...v,
            name:arrInName[index].name,
            address:arrInName[index].address,
            personInDepart:arrInName[index].personInDepart
          }
        }
      })      
      return result.filter(v=>v!==undefined)
  }


export const UpdateEditDepartment=(ListCustomers,valueEdit,addressEdit,nameEdit)=>{
  let findItemCustomer = ListCustomers.filter(v=>v._id===valueEdit.idCustomer)[0]
  let result = handleUpdateItem(findItemCustomer,valueEdit,addressEdit,nameEdit)
  return [findItemCustomer].map(v=>{
    return{
      ...v,
      infoCustumer:{
        ...v.infoCustumer,
        deparment:result
      }
    }
  })[0]
}

const handleUpdateItem=(findItemCustomer,valueEdit,addressEdit,nameEdit)=>{
  let {deparment} = findItemCustomer.infoCustumer
  let index = deparment.findIndex(v=>v._id===valueEdit.idDepartmentCustomer)
  if(index!==-1){
    let newItem= {      
      personInDepart:deparment[index].personInDepart,
      _id:valueEdit.idDepartmentCustomer,
      address:addressEdit,
      name:nameEdit
    }
    return[
      ...deparment.slice(0,index),
      newItem,
      ...deparment.slice(index+1)
    ]
  }
}

export const FindPersonInDepart =(arr,idDepartment)=>{
    return arr.filter(v=>v.idDepartmentCustomer===idDepartment)[0].personInDepart
}


export const CreateNewItemTaskJob=(value,result,OpenEdit,OpenDelete)=>{
  if(result._id===undefined&&OpenEdit===false&&OpenDelete===false) { //them
    return  [value].map(v=>{
      return{
        id:v.id,
        _id:v._id,
        idCustomer:v.idCustomer,
        idDepartmentCustomer:v.idDepartmentCustomer,
        materials:v.materials,
        typeOf:STATUS_PROCESS,
        status:STATUS_PROCESS,          
        infoDetailBefore:v.infoDetailBefore.concat(result),
        infoDetailAfter:v.infoDetailAfter,
        infoDetailForCustomerAfter:v.infoDetailForCustomerAfter
      }
    })[0]
  }
  if(result._id!==undefined&&OpenEdit===true&&OpenDelete===false){    
    return  [value].map(v=>{
      return{
        id:v.id,
        _id:v._id,
        idCustomer:v.idCustomer,
        idDepartmentCustomer:v.idDepartmentCustomer,
        materials:v.materials,
        typeOf:STATUS_PROCESS,
        status:STATUS_PROCESS,          
        infoDetailBefore:updateArrById(v.infoDetailBefore,result),
        infoDetailAfter:v.infoDetailAfter,
        infoDetailForCustomerAfter:v.infoDetailForCustomerAfter
      }
    })[0]
  }    
  if(result._id!==undefined&&OpenEdit===false&&OpenDelete===true){    
    return  [value].map(v=>{
      let resultDelete = DeleteItemById(v.infoDetailBefore,result)===null?[]:DeleteItemById(v.infoDetailBefore,result)
      return{
        id:v.id,
        _id:v._id,
        idCustomer:v.idCustomer,
        idDepartmentCustomer:v.idDepartmentCustomer,
        //idPersonInDepart:valueSelect._id,
        materials:v.materials,
        typeOf:STATUS_PROCESS,
        status:STATUS_PROCESS,          
        infoDetailBefore:resultDelete,
        infoDetailAfter:v.infoDetailAfter,
        infoDetailForCustomerAfter:v.infoDetailForCustomerAfter
      }
    })[0]
  }    
}

export const CreateNewResultTaskJob =(valueProcess,ResultItem)=>{ 
    return valueProcess.map(v=>{
      return{
        ...v,
        taskJob:updateArrById(v.taskJob,ResultItem)
      }
    })[0]
}

export const CreateNewItemTaskJobAfter=(value,result,OpenEdit,OpenDelete)=>{
  if(result._id===undefined&&OpenEdit===false&&OpenDelete===false) { //them
    return  [value].map(v=>{
      return{
        id:v.id,
        _id:v._id,
        idCustomer:v.idCustomer,
        idDepartmentCustomer:v.idDepartmentCustomer,
        //idPersonInDepart:valueSelect._id,
        materials:v.materials,
        typeOf:STATUS_COMPLETE,
        status:STATUS_COMPLETE,          
        infoDetailBefore:v.infoDetailBefore,
        infoDetailAfter:v.infoDetailAfter.concat(result),
        infoDetailForCustomerAfter:v.infoDetailForCustomerAfter
      }
    })[0]
  }
  if(result._id!==undefined&&OpenEdit===true&&OpenDelete===false){
    return  [value].map(v=>{
      return{
        id:v.id,
        _id:v._id,
        idCustomer:v.idCustomer,
        idDepartmentCustomer:v.idDepartmentCustomer,
        materials:v.materials,
        typeOf:STATUS_COMPLETE,
        status:STATUS_COMPLETE,          
        infoDetailAfter:updateArrById(v.infoDetailAfter,result),
        infoDetailBefore:v.infoDetailBefore,
        infoDetailForCustomerAfter:v.infoDetailForCustomerAfter
      }
    })[0]
  }    
  if(result._id!==undefined&&OpenEdit===false&&OpenDelete===true){    
    return  [value].map(v=>{
      let resultDelete =  DeleteItemById(v.infoDetailAfter,result)
      let arrInfoDetailAfter = resultDelete===null?[]:resultDelete
      let Status =arrInfoDetailAfter.length!==0?STATUS_COMPLETE:STATUS_PROCESS
       return{
        id:v.id,
        _id:v._id,
        idCustomer:v.idCustomer,
        idDepartmentCustomer:v.idDepartmentCustomer,
        materials:v.materials,
        typeOf:Status,
        status:Status,          
        infoDetailAfter:arrInfoDetailAfter,
        infoDetailBefore:v.infoDetailBefore,
        infoDetailForCustomerAfter:v.infoDetailForCustomerAfter
      }
    })[0]
  }    
}

export const CreateNewResultTaskJobAfter =(valueProcess,ResultItem)=>{ 
    return valueProcess.map(v=>{
      return{
        ...v,
        taskJob:updateArrById(v.taskJob,ResultItem)
      }
    })[0]
}

export const CreateNewItemTaskJobMaterial=(value,result,OpenEdit,OpenDelete)=>{
  if(result._id===undefined&&OpenEdit===false&&OpenDelete===false) { //them
    return  [value].map(v=>{
      return{
        id:v.id,
        _id:v._id,
        idCustomer:v.idCustomer,
        idDepartmentCustomer:v.idDepartmentCustomer,
        materials:v.materials.concat(result),
        typeOf:v.typeOf,
        status:v.status,          
        infoDetailBefore:v.infoDetailBefore,
        infoDetailAfter:v.infoDetailAfter,
        infoDetailForCustomerAfter:v.infoDetailForCustomerAfter
      }
    })[0]
  }
  if(result._id!==undefined&&OpenEdit===true&&OpenDelete===false){
    return  [value].map(v=>{
      return{
        id:v.id,
        _id:v._id,
        idCustomer:v.idCustomer,
        idDepartmentCustomer:v.idDepartmentCustomer,
        materials:updateArrById(v.materials,result),
        typeOf:v.typeOf,
        status:v.status,          
        infoDetailAfter:v.infoDetailAfter,
        infoDetailBefore:v.infoDetailBefore,
        infoDetailForCustomerAfter:v.infoDetailForCustomerAfter
      }
    })[0]
  }    
  if(result._id!==undefined&&OpenEdit===false&&OpenDelete===true){
    return  [value].map(v=>{      
      return{
        id:v.id,
        _id:v._id,
        idCustomer:v.idCustomer,
        idDepartmentCustomer:v.idDepartmentCustomer,
        materials:DeleteItemById(v.materials,result)===null?[]:DeleteItemById(v.materials,result),
        typeOf:v.typeOf,
        status:v.status,          
        infoDetailAfter:v.infoDetailAfter,
        infoDetailBefore:v.infoDetailBefore,
        infoDetailForCustomerAfter:v.infoDetailForCustomerAfter
      }
    })[0]
  }    
}

export const CreateNewResultTaskJobMaterial =(valueProcess,ResultItem)=>{ 
    return valueProcess.map(v=>{
      return{
        ...v,
        taskJob:updateArrById(v.taskJob,ResultItem)
      }
    })[0]
}

export const FindItemAutoImport=(arr,item)=>{ 
    return arr.filter(v=>v.nameMaterial===item.name)[0]
}

export const FindNameMaterial=(arr,item)=>{
  let result = arr.filter(v=>v._id===item.idNameMaterial)
  return result.length===0?"":result[0].name
}

export const FindNewItemTaskJob =(itemStaff,item,id,check)=>{  
    if(check===true){
      let findTaskJob = item.taskJob.filter(v=>v._id===id)
      let newFindTaskJob = findTaskJob.map(v=>{
        return {
          ...v,
          idStaffIn:""
        }
      })[0]
      let newTaskJob = updateArrById(item.taskJob,newFindTaskJob)    
      return [item].map(v=>{
        return{
          ...v,
          taskJob:newTaskJob
        }
      })[0]
    }
    if(check===false){
      let findTaskJob = item.taskJob.filter(v=>v._id===id)
      let newFindTaskJob = findTaskJob.map(v=>{
        return {
          ...v,
          idStaffIn:itemStaff.idStaff
        }
      })[0]
      let newTaskJob = updateArrById(item.taskJob,newFindTaskJob)    
      return [item].map(v=>{
        return{
          ...v,
          taskJob:newTaskJob
        }
      })[0]
    }  
}

export const NewArrCustomer=(arr,arrTask)=>{ 
          let filterArrTask = arrTask.filter(v=>v.taskJob.length!==0&&v.taskJob[0]!==null)
         
          let newArr        = arr.map(v=>{
          let index         = filterArrTask.findIndex(vv=>vv.taskJob[0].idCustomer===v._id)
          if(index!==-1){
              return{...v}
          }    
          })
      let resultArr = newArr.filter(v=>v!==undefined)
      return resultArr.map(v=>{
        return{
          _id:v._id,
          typeOf:v.typeOf,
          name:v.infoCustumer.name,
          phone:v.infoCustumer.phone,
          address:v.infoCustumer.addressPrimary
        }
      })
}

export const NewArrCustomer_=(arr)=>{   
    return arr.map(v=>{
    return{
      _id:v._id,
      typeOf:v.typeOf,
      name:v.infoCustumer.name,
      phone:v.infoCustumer.phone,
      address:v.infoCustumer.addressPrimary
      }
    })
}

export const handleFilterByStaff=(arr,item)=>{
  let result = arr.map(v=>{
    let find = v.Staff.filter(vv=>vv.idStaff===item.idStaff)
    if(find.length!==0) return v
  })
  return result.filter(v=>v!==undefined)
}

export const FilterByCustomer=(arr,item)=>{    
  //console.log(arr)
  //console.log(item)
  let filterNotNull = arr.filter(v=>v.taskJob[0]!==null)
  //let filterArr5 = filterNotNull.filter(v=>v.typeOf===5)
        let result =  filterNotNull.map(v=>{
        let Filter = v.taskJob.some(vv=>vv.idCustomer===item)
        if(Filter===true){
          return v
        }
      })
       return result.filter(v=>v!==undefined)
}

const NewArrStatus =(typeOf)=>{
    if(typeOf===0){
      return NOT_APPROVE
    }
    if(typeOf===1){
      return ACCEPT_APPROVE
    }
    if(typeOf===2){
      return DONOT_ACCEPT_APPROVE
    }
}

export const handleFilterPayTaskJob=(arr)=>{
  return  arr.map(v=>{
    if(v.taskJob[0]!==null){
       return{
        ...v,
          nameStatus:NewArrStatus(v.typeOf),
          price:v.taskJob[0].materials.length===0?0:v.taskJob[0].materials[0].price,
          img:v.taskJob[0].materials.length===0?"":v.taskJob[0].materials[0].img
        }
    }    
   }) 
}

export const handleFilterPayTaskJobByMonth=(arr,month,PAY)=>{
    let filterByPay = arr.filter(v=>v.status===PAY)
    let result      = filterByPay.filter(v=>showMMYY(v.dateBegin)===month)
    return result.map(v=>{
      return{
        ...v,
        nameStatus:NewArrStatus(v.typeOf),
        price:v.taskJob[0].materials[0].price,
        img:v.taskJob[0].materials[0].img
      }
    })
}

export const handleFilterPayTaskJobByMonth_=(arr,PAY)=>{
  let filterByPay = arr.filter(v=>v.status===PAY)
  //let result      = filterByPay.filter(v=>showMMYY(v.dateBegin)===month)
  return filterByPay.map(v=>{
    return{
      ...v,
      nameStatus:NewArrStatus(v.typeOf),
      price:v.taskJob[0].materials[0].price,
      img:v.taskJob[0].materials[0].img
    }
  })
}

export const FindArrJob=(arr,name,ARR_SELECT)=>{  
  if(name!==undefined){
    let findName = name.split('-')   
    let filter   = ARR_SELECT.filter(v=>v.name.toLowerCase().trim()===findName[0].toLowerCase().trim())
    if(filter.length===0){       
        return false
    }
    if(filter.length!==0){
      return FindNameTask(arr,filter[0].name)   
    }
  }    
}

export const CreateNewUpdateProcess=(item,value)=>{
  return [item].map(v=>{
    return{
      ...v,
      nameTask:value
    }
  })[0]
}

export const FilterDepartmentAfterComplete=(arr,item)=>{
  let fitlerCustomer = arr.filter(v=>v._id===item.taskJob[0].idCustomer)
  let arrDepartment = fitlerCustomer[0].infoCustumer.deparment
  let arrNewDepartment = arrDepartment.map(v=>v._id)
  let arrTaskJob       = item.taskJob.map(v=>v.idDepartmentCustomer)
  let diff = _.difference(arrTaskJob,arrNewDepartment)
  return diff
}

export const NewNameTask=(v)=>{
  return v.split('-')[1]
}

export const HandleShowId=(id)=>{
      let findLength = id.length
      let findStart = (findLength) - NUMBER_SHOW_ID
      return id.slice(findStart,findLength).toUpperCase()
}


export const HandleShowIdMeal=(id)=>{
  if(id!==""&&id!==null&&id!==undefined){
     let findLength = id.length
     let findStart = (findLength) - NUMBER_SHOW_ID_MEAL
     return id.slice(findStart,findLength).toUpperCase()
  } 
}


