import {OPEN_SELECT_DATE_TO_COUNT_EAT,CLOSE_SELECT_DATE_TO_COUNT_EAT,
      OPEN_FORM_SELECT_PRICE,DISPATCH_SELECT_PRICE,CLOSE_FORM_SELECT_PRICE,DISPATCH_LIST_ALL_MATERIAL_TO_STORE,DISPATCH_ADD_MATERIAL_MEAL_DATE_TO_STORE,
      EDIT_MATERIAL_MEAL_DATE,DELETE_ITEM_MATERIAL_MEAL_DATE,DELETE_MANY_ITEM_MATERIAL_MEAL_DATE,
      DISPATCH_SUM_BLOCK_CLASS_LIST,DISPATCH_SUM_MATERIAL_MEAL_DATE_LIST,
      DISPATCH_LIST_SUM_LESS_MORE_TO_STORE,DISPATCH_ADD_SUM_LESS_MORE_TO_STORE,
      DISPATCH_VALUE_SUM_LESS_MORE,
      DISPATCH_SUM_LESS_MORE_TO_DB,CONDITION_PRINT_FALSE,CONDITION_PRINT_TRUE,
      GENERAL_FALSE,GENERAL_TRUE,CLASS_FALSE,CLASS_TRUE,DISPATCH_ID_CLASS,
      OPEN_PAYMENT_LIST,CLOSE_PAYMENT_LIST,
      DISPATCH_SELECT_PRICE_MODAL_OPEN,DISPATCH_SELECT_PRICE_MODAL_CLOSE,DISPATCH_ITEMS_PRICE_TO_STORE,
      OPEN_ALERT_FORM,CLOSE_ALERT_FORM,MATERIAL_TRUE,MATERIAL_FALSE,
     CLOSE_EXPORT_EXCEL,CURRENT_DATE,TOTAL_MATERIAL_FALSE,TOTAL_MATERIAL_TRUE,
      OPEN_PRINT_PAYMENT_LIST,CLOSE_PRINT_PAYMENT_LIST, OPEN_EXPORT_EXCEL,
      OPEN_PRINT_TOTAL_MATERIAL,CLOSE_PRINT_TOTAL_MATERIAL,
      CLOSE_ADD_SUPPLIES,OPEN_ADD_SUPPLIES,DISPATCH_ITEM_ADD_SUPPLIES_TO_STORE,
      DISPATCH_LIST_SUPPLIES_STORE,DISPATCH_EDIT_SUPPLIES_TO_DB,TOTAL_SUPPLIES_TRUE, TOTAL_SUPPLIES_FALSE,
      PRINT_MATERIAL_TRUE,PRINT_MATERIAL_FALSE,PRINT_SUPPLIES_FALSE,PRINT_SUPPLIES_TRUE,
      OPEN_ADD_REPOSITORY,CLOSE_ADD_REPOSITORY,SHOW_MENU_PRINT_CHECK_EAT_COUNT,HIDE_MENU_PRINT_CHECK_EAT_COUNT,
      OPEN_CREATE_EXPORT_REPO,CLOSE_CREATE_EXPORT_REPO,DISPATCH_ITEM_EXPORT_REPO,OPEN_PRINT_EXPORT_REPO,CLOSE_PRINT_EXPORT_REPO,
      OPEN_CREATE_PAYMENT_ORDER,CLOSE_CREATE_PAYMENT_ORDER,OPEN_PRINT_PAYMENT_ORDER,CLOSE_PRINT_PAYMENT_ORDER, DISPATCH_ITEM_PAYMENT_ORDER,
      DISPATCH_ARR_TO_RESULT_REPOSITORY,DISPATCH_OPEN_FORM_UPDATE_REPO,DISPATCH_CLOSE_FORM_UPDATE_REPO,
      SHOW_PAYMENT_TOTAL,CLOSE_PAYMENT_TOTAL,
      OPEN_PRINT_PAYMENT_LIST_TOTAL,CLOSE_PRINT_PAYMENT_LIST_TOTAL,
      OPEN_TOTAL_COUNT_EAT,CLOSE_TOTAL_COUNT_EAT,
      OPEN_PRINT_TOTAL_COUNT_EAT,CLOSE_PRINT_TOTAL_COUNT_EAT,
      OPEN_TOTAL_STUDENT_NOT_EAT,CLOSE_TOTAL_STUDENT_NOT_EAT,
      OPEN_PRINT_TOTAL_STUDENT_NOT_EAT,CLOSE_PRINT_TOTAL_STUDENT_NOT_EAT,OPEN_EXPORT_EXCEL_PAYMENT_TOTAL,
      OPEN_EXPORT_EXCEL_TOTAL_COUNT_EAT,OPEN_EXPORT_EXCEL_TOTAL_MATERIAL,OPEN_EXPORT_EXCEL_IMPORT_REPO,
      OPEN_EXPORT_EXCEL_TOTAL_MATERIAL_SUPPLIES,OPEN_EXPORT_EXCEL_TOTAL_STUDENT_NOT_EAT,
      OPEN_EXPORT_EXCEL_MATERIAL,TYPE_MATERIAL_FALSE,TYPE_MATERIAL_TRUE,
      PRINT_TYPE_MATERIAL_FALSE,PRINT_TYPE_MATERIAL_TRUE,OPEN_EXPORT_EXCEL_TYPE_MATERIAL,CLOSE_EXPORT_EXCEL_TYPE_MATERIAL,
      RESULT_TYPE_MATERIAL_SUPPLIES,DISPATCH_NAME_TYPE_MATERIAL_SUPPLIES,
      OPEN_LIST_CHECK_DAY_FOOD_IN_MONTH,CLOSE_LIST_CHECK_DAY_FOOD_IN_MONTH,
      DISPATCH_SET_VALUE_CLASS_OR_STUDENT,
      OPEN_PRINT_TOTAL_CHECK_DAY_FOOD,CLOSE_PRINT_TOTAL_CHECK_DAY_FOOD,
      OPEN_EXPORT_EXCEL_CHECK_DY_FOOD_IN_MONTH,OPEN_FORM_TRANSFER_LEFT_OVER,CLOSE_FORM_TRANSFER_LEFT_OVER,DISPATCH_VALUES_TRANSFER_LEFT_OVER,
      UPLOAD_TRANSFER_LEFT_OVER,DISPATCH_ADD_TRANSFER_LEFT_OVER_TO_STORE,DISPATCH_LIST_TRANSFER_TO_STORE,DELETE_TRANSFER_LEFT_OVERS_BY_MONTH_CLASS,
      DELETE_TRANSFER_LEFT_SUCCESS,OPEN_FORM_DELETE_TRANSFER_LEFT_OVER,CLOSE_FORM_DELETE_TRANSFER_LEFT_OVER,
      OPEN_FORM_TRANSFER_LEFT_OVER_TH,CLOSE_FORM_TRANSFER_LEFT_OVER_TH,DISPATCH_VALUES_TRANSFER_LEFT_OVER_TH,UPLOAD_TRANSFER_LEFT_OVER_TH, DISPATCH_ADD_TRANSFER_LEFT_OVER_TO_STORE_TH, DISPATCH_LIST_TRANSFER_TO_STORE_TH, DELETE_TRANSFER_LEFT_SUCCESS_TH, OPEN_FORM_DELETE_TRANSFER_LEFT_OVER_TH, CLOSE_FORM_DELETE_TRANSFER_LEFT_OVER_TH, DELETE_TRANSFER_LEFT_OVERS_BY_MONTH_CLASS_TH,
      OPEN_FORM_BUFFET,CLOSE_FORM_BUFFET,ADD_BUFFET_TO_STORE,LIST_BUFFET_TO_STORE,OPEN_UPDATE_BUFFET,CLOSE_UPDATE_BUFFET,UPDATE_BUFFET_DB,
      REMOVE_BUFFET,OPEN_FORM_PRINT_RECEIPTS,CLOSE_FORM_PRINT_RECEIPTS,DISPATCH_ITEM_LIST_STUDENT_BY_CLASS_YEARSCHOOL,
      DISPATCH_ADD_RECEIPTS_TO_STORE,DISPATCH_GET_RECEIPTS_TO_STORE,OPEN_FORM_UPDATE_PRINT_RECEIPTS,CLOSE_FORM_UPDATE_PRINT_RECEIPTS,UPDATE_RECEIPTS,
      OPEN_DISPATCH_VALUE_PRINT_RECEIPTS_SELECT_ITEM,CLOSE_DISPATCH_VALUE_PRINT_RECEIPTS_SELECT_ITEM,
      OPEN_DISPATCH_VALUE_PRINT_RECEIPTS_ALL_SELECT_ITEM,CLOSE_DISPATCH_VALUE_PRINT_RECEIPTS_ALL_SELECT_ITEM,
      OPEN_FORM_UPDATE_DAILY_TUI,CLOSE_FORM_UPDATE_DAILY_TUI,DISPATCH_ADD_UPDATE_DAILY_TUI_TO_STORE,
      UPDATE_ITEM_UPDATE_DAILY_TUI,DISPATCH_LIST_UPDATE_DAILY_TUI, DISPATCH_ADD_MANY_MATERIAL_MEAL_DATE_TO_STORE
} from '../../../contant/Gov/countEat/dispatch'
import {updateArrById,DeleteItemById} from '../../../helper/fnAllways/index'
import {sortArrAsc} from '../../../helper/tableList/tableList'
import {ListPriceMaterialByDate} from '../../../helper/dateTime/handleDMY'
import {ManageRepositoryResult} from '../../../helper/countEat/index'
import {toastSuccess} from '../../../helper/toastHelper'
import _ from 'lodash'
import { DeleteManyItemById } from './fn'
let initialState = {
  OpenSelectDate:false,
  FormDateInput:false, //false formDateinput, true form select
  PriceMeal:null,
  ListMaterialMealDate:[],
  SumMealDateList:0, //tien di cho
  SumMealDate:0, //tien an hang ngay,
  SumLessMore:0, //ket qua thua hany thieu khi di cho SumMealDate - SumMealDateList
  ListSumLessMore:[],
  valueSumLessMore:0,//tien an con thua hay thieu ngay hom truoc
  valueSumDB:0,
  valueBuffet:[],//so phieu an buffet
  ItemAddSumLessMore:null,
  ConditionPrint:false,
  General:false, //nut tong hop hien thi, dong
  Class:false, //hien thi danh sach cac lop hoc
  ValueClassOrStudent:0,//0 class,1 student
  Material:false,
  TypeMaterial:false,
  TotalMaterial:false,
  IdClass:null,
  OpenPaymentList:false,
  OpenSelectPriceModal:false,
  ItemsPrice:null,//cac loai phi
  OpenAlertForm:false,  
  PriceValue:"",
  OpenPrintPaymentList:false,
  OpenExportExcel:false,
  CurrentDate:"",// moc thoi gain hien tai
  ListMaterialMealDateByDate:[], //danh sach gia nguyen lieu theo moc thoi gian hien tai
  OpenPrintTotalMaterial:false,
  OpenAddSupplies:false,
  OpenPrintTotalCheckDayFood:false,
  ListSupplies:[],
  TotalSupplies:false, //tong hop nguyen lieu theo nha cung cap
  PrintMaterial:false,
  PrintSupplies:false,
  OpenAddRepository:false,
  ShowMenuPrintCheckEatCount:false,
  OpenCreateExportRepo:false,
  ItemExportRepo:"",
  ItemPaymentOrder:"",
  OpenPrintExportRepo:false,
  OpenCreatePaymentOrder:false,
  OpenPrintPaymentOrder:false,
  ManageRepository:"",
  OpenFormRepoManagerItem:false,
  OpenFormUpdateRepoBalance:false,
  ItemUpdateRepoImport:"",
  ShowPaymentTotal:false,
  OpenPrintPaymentListTotal:false,
  OpenTotalCountEat:false,
  OpenPrintTotalCountEat:false,
  OpenTotalStudentNotEat:false,
  OpenPrintTotalStudentNotEat:false,
  OpenExportExcelPaymentTotal:false,
  OpenExportExcelTotalCountEat:false,
  OpenExportExcelTotalMaterial:false,
  OpenExportExcelImportRepo:false,
  OpenExportExcelTotalMaterialSupplies:false,
  OpenExportExcelStudentNotEat:false,
  OpenExportExcelMaterial:false,
  PrintTypeMaterial:false,
  OpenExportExcelTypeMaterial:false,
  ResultTypeMaterialSupplies:[],
  ListTypeMaterialSupplies:[],
  OpenCheckDayFoodInMonth:false,
  OpenExportExcelCheckDayFoodInMonth:false,
  OpenTransferLeftOver:false, // form mam non
  OpenFormDeleteTransferLeftOver:false, //form xoa mam non
  OpenTransferLeftOverTh:false, // form tieu hoc
  ValuesTransferLeftOver:'',
  ValuesUploadTransferOver:'',
  ValuesTransferLeftOverTh:'',
  ValuesUploadTransferLeftOverTh:'',
  OpenFormDeleteTransferLeftOverTh:false,
  ItemTransferByClass:[], //danh sach ket chuyen cua 1 lop,
  ListTransferByAllClass:[], //danh sach ket chuyen cua tat ca ca lop
  OpenFormBuffet:false,
  OpenUpdateBuffet:false,
  OpenFormPrintReceipts:false,
  OpenFormUpdatePrintReceipts:false,
  ListStudentByClassYearSchool:[],
  ListStudentPrintReceipts:[],
  //ListStudentPrintReceiptsByYearSchoolByMonth:[]
  ValuePrintReceiptsSelect:{
    Value:false,
    Item:{}
  },
  ValuePrintReceiptsAllSelect:{
    Value:false,
    Item:{}
  },
  OpenFormUpdateDailyTui:false,
  ListUpdateDailyTui:[],  
}

const CountEatReducer = (state=initialState,action)=>{
  switch(action.type){
    case OPEN_SELECT_DATE_TO_COUNT_EAT:
      return{
        ...state,
        OpenSelectDate:true
      }
    case CLOSE_SELECT_DATE_TO_COUNT_EAT:
        return{
          ...state,
          OpenSelectDate:false
        }
    case OPEN_FORM_SELECT_PRICE:
        return{
          ...state,
          FormDateInput:true
        }
     case CLOSE_FORM_SELECT_PRICE:
          return{
            ...state,
            FormDateInput:false
         }
    case DISPATCH_SELECT_PRICE:
        return{
          ...state,
          PriceMeal:action.payload.valueInput
        }
    case DISPATCH_LIST_ALL_MATERIAL_TO_STORE:
      return{
        ...state,
        ListMaterialMealDate:action.payload.arr
      }
    case DISPATCH_ADD_MATERIAL_MEAL_DATE_TO_STORE:
      return{
        ...state,
        ListMaterialMealDate:state.ListMaterialMealDate.concat(action.payload.item)
      }
      case DISPATCH_ADD_MANY_MATERIAL_MEAL_DATE_TO_STORE:
        toastSuccess('Tạo danh sách nguyên liệu đi chợ thành công!')
        return{
          ...state,
          ListMaterialMealDate:state.ListMaterialMealDate.concat(action.payload.item)
        }
      case EDIT_MATERIAL_MEAL_DATE:
        return{
          ...state,
          ListMaterialMealDate:updateArrById(state.ListMaterialMealDate,action.payload.value)
        }
        case DELETE_ITEM_MATERIAL_MEAL_DATE:
          return{
            ...state,
            ListMaterialMealDate:DeleteItemById(state.ListMaterialMealDate,action.payload.item)
          }
          case DELETE_MANY_ITEM_MATERIAL_MEAL_DATE:
            toastSuccess('Danh sách đã được xóa')
            return{
              ...state,
              ListMaterialMealDate:DeleteManyItemById(state.ListMaterialMealDate,action.payload.item)
            }
        case DISPATCH_SUM_MATERIAL_MEAL_DATE_LIST:         
          return{
            ...state,
            SumMealDateList:action.payload.Sum //di cho chi thu te
          }
        case DISPATCH_SUM_BLOCK_CLASS_LIST:     
        let {valueBuffet,Sum,price}      = action.payload
          return{
            ...state,
            SumMealDate:valueBuffet!==undefined?(Sum)*(price)*(valueBuffet.value+1)+state.valueSumLessMore:(Sum)*(price)+state.valueSumLessMore,
            SumLessMore:(Sum)*(price)+state.SumMealDateList//ngay hien tai
          }
        case ADD_BUFFET_TO_STORE:
          toastSuccess('Khởi Tạo Thành Công !')
          return{
            ...state,
            valueBuffet:[action.payload.item]
          }
        case LIST_BUFFET_TO_STORE:
          return{
            ...state,
            valueBuffet:action.payload.item
          }
        case UPDATE_BUFFET_DB:
          toastSuccess('Cập nhật thành công')
          return{
            ...state,
            valueBuffet:[action.payload.item]
          }
        case REMOVE_BUFFET:
            toastSuccess('Đã xóa!')
          return{
            ...state,
            valueBuffet:[]
          }
        case DISPATCH_LIST_SUM_LESS_MORE_TO_STORE:
          return{
            ...state,
            ListSumLessMore:sortArrAsc(action.payload.arr) //phai xap xep tang dan theo thoi gian
          }
        case DISPATCH_ADD_SUM_LESS_MORE_TO_STORE:
          return{
            ...state,
            ListSumLessMore:sortArrAsc((state.ListSumLessMore).concat(action.payload.item)),
            ItemAddSumLessMore:action.payload.item
          }
        case DISPATCH_VALUE_SUM_LESS_MORE:
          let value = action.payload.item
          let Value = (value===undefined?0:value.sumLessMore)
          return{
            ...state,
            valueSumLessMore:Value
          }
          case DISPATCH_SUM_LESS_MORE_TO_DB:
            return{
              ...state,
              valueSumDB:action.payload.ItemToDB,
              ListSumLessMore:sortArrAsc(updateArrById(state.ListSumLessMore,action.payload.ItemToDB))
            }
          case CONDITION_PRINT_TRUE:
            return{
              ...state,
              ConditionPrint:true
            }
          case CONDITION_PRINT_FALSE:
              return{
                ...state,
                ConditionPrint:false
              }
          case GENERAL_TRUE:
            return{
              ...state,
              General:true
            }
          case GENERAL_FALSE:
            return{
              ...state,
              General:false
            }
          case CLASS_TRUE:
            return{
              ...state,
              Class:true
            }
          case CLASS_FALSE:
            return {
              ...state,
              Class:false
            }
          case DISPATCH_SET_VALUE_CLASS_OR_STUDENT:
            return{
              ...state,
              ValueClassOrStudent:action.payload.item
            }
            case MATERIAL_TRUE:
              return{
                ...state,
                Material:true
              }
            case MATERIAL_FALSE:
              return {
                ...state,
                Material:false
              }
          case DISPATCH_ID_CLASS:
            return{
              ...state,
              IdClass:action.payload.item
            }
          case OPEN_PAYMENT_LIST:
            return{
              ...state,
              OpenPaymentList:true
            }
          case CLOSE_PAYMENT_LIST:
            return{
              ...state,
              OpenPaymentList:false
            }
          case DISPATCH_SELECT_PRICE_MODAL_CLOSE:
            return{
              ...state,
              OpenSelectPriceModal:false
            }
          case DISPATCH_SELECT_PRICE_MODAL_OPEN:
            return{
              ...state,
              OpenSelectPriceModal:true
            }
          case DISPATCH_ITEMS_PRICE_TO_STORE:
            return{
              ...state,
              ItemsPrice:action.payload.item
            }
          case OPEN_ALERT_FORM:
            return{
              ...state,
              OpenAlertForm:true
            }
          case CLOSE_ALERT_FORM:
            return {
              ...state,
              OpenAlertForm:false
            }
        
        case OPEN_PRINT_PAYMENT_LIST:
          return{
            ...state,
            OpenPrintPaymentList:true
          }
          case CLOSE_PRINT_PAYMENT_LIST:
            return{
              ...state,
              OpenPrintPaymentList:false
            }
          case OPEN_EXPORT_EXCEL:
            return{
              ...state,
              OpenExportExcel:!state.OpenExportExcel
            }
            case CLOSE_EXPORT_EXCEL:
              return{
                ...state,
                OpenExportExcel:false
              }
          case CURRENT_DATE:          
            return{
              ...state,
              CurrentDate:action.payload.CurrentDate,
              ListMaterialMealDateByDate:sortArrAsc(ListPriceMaterialByDate(state.ListMaterialMealDate,action.payload.CurrentDate))
            }
          case TOTAL_MATERIAL_TRUE:
            return{
              ...state,
              TotalMaterial:true
            }
          case TOTAL_MATERIAL_FALSE:
            return{
              ...state,
              TotalMaterial:false
            }
          case OPEN_PRINT_TOTAL_MATERIAL:
            return{
              ...state,
              OpenPrintTotalMaterial:true
            }
          case CLOSE_PRINT_TOTAL_MATERIAL:
            return{
              ...state,
              OpenPrintTotalMaterial:false
            }
          case OPEN_ADD_SUPPLIES:
            return{
              ...state,
              OpenAddSupplies:true
            }
          case CLOSE_ADD_SUPPLIES:
            return{
              ...state,
              OpenAddSupplies:false
            }
          case DISPATCH_ITEM_ADD_SUPPLIES_TO_STORE:
            return{
              ...state,
              ListSupplies:state.ListSupplies.concat(action.payload.data)
            }
          case DISPATCH_LIST_SUPPLIES_STORE:
            return{
              ...state,
              ListSupplies:action.payload.data
            }
          case DISPATCH_EDIT_SUPPLIES_TO_DB:
            return{
              ...state,
              ListSupplies:updateArrById(state.ListSupplies,action.payload.item)
            }   
          case TOTAL_SUPPLIES_TRUE:
            return{
              ...state,
              TotalSupplies:true
            }
          case TOTAL_SUPPLIES_FALSE:
            return{
              ...state,
              TotalSupplies:false
            }
            case PRINT_MATERIAL_FALSE:
              return{
                ...state,
                PrintMaterial:false
              }
            case PRINT_MATERIAL_TRUE:
              return{
                ...state,
                PrintMaterial:true
              }
          case PRINT_SUPPLIES_TRUE:
            return{
              ...state,
              PrintSupplies:true
            }
        case PRINT_SUPPLIES_FALSE:
          return{
            ...state,
            PrintSupplies:false
          }
        case CLOSE_ADD_REPOSITORY:
          return{
            ...state,
            OpenAddRepository:false
          }
        case OPEN_ADD_REPOSITORY:
          return{
            ...state,
            OpenAddRepository:true
          }
        case SHOW_MENU_PRINT_CHECK_EAT_COUNT:
          return{
            ...state,
            ShowMenuPrintCheckEatCount:true
          }
        case HIDE_MENU_PRINT_CHECK_EAT_COUNT:
          return{
            ...state,
            ShowMenuPrintCheckEatCount:false
          }
        case OPEN_CREATE_EXPORT_REPO:
          return{
            ...state,
            OpenCreateExportRepo:true
          }
        case CLOSE_CREATE_EXPORT_REPO:
          return{
            ...state,
            OpenCreateExportRepo:false
          }
        case DISPATCH_ITEM_EXPORT_REPO:
          return{
            ...state,
            ItemExportRepo:action.payload.item
          }
        case OPEN_PRINT_EXPORT_REPO:
          return{
            ...state,
            OpenPrintExportRepo:true
          }
        case CLOSE_PRINT_EXPORT_REPO:
          return{
            ...state,
            OpenPrintExportRepo:false
          }
        case OPEN_CREATE_PAYMENT_ORDER:
          return{
            ...state,
            OpenCreatePaymentOrder:true
          }
        case CLOSE_CREATE_PAYMENT_ORDER:
          return{
            ...state,
            OpenCreatePaymentOrder:false
          }
      case OPEN_PRINT_PAYMENT_ORDER:
        return{
          ...state,
          OpenPrintPaymentOrder:true
        }
      case CLOSE_PRINT_PAYMENT_ORDER:
        return{
          ...state,
          OpenPrintPaymentOrder:false
        }
      case DISPATCH_ITEM_PAYMENT_ORDER:
        return{
          ...state,
          ItemPaymentOrder:action.payload.item
        }
      case DISPATCH_ARR_TO_RESULT_REPOSITORY:
        let {ListRepo,ListImport,DateInput,ItemRepo,ListBalance} = action.payload.ArrReduce
        let {ListMaterialMealDate} = state        
        return{
          ...state,
          ManageRepository:ManageRepositoryResult(ListRepo,ListImport,DateInput,ItemRepo,ListMaterialMealDate,ListBalance)
        }     

      case DISPATCH_CLOSE_FORM_UPDATE_REPO:
        return{
          ...state,
          OpenFormUpdateRepoBalance:false
        }
      case DISPATCH_OPEN_FORM_UPDATE_REPO:
        return{
          ...state,
          OpenFormUpdateRepoBalance:true,
          ItemUpdateRepoImport:action.payload.result
        }
      case SHOW_PAYMENT_TOTAL: //tong hop thu tien
        return{
          ...state,
          ShowPaymentTotal:true
        }
      case CLOSE_PAYMENT_TOTAL:
        return{
          ...state,
          ShowPaymentTotal:false
        }
        case OPEN_PRINT_PAYMENT_LIST_TOTAL://in tong hop thu tien
          return{
            ...state,
            OpenPrintPaymentListTotal:true
          }
        case CLOSE_PRINT_PAYMENT_LIST_TOTAL:
          return{
            ...state,
            OpenPrintPaymentListTotal:false
          }

        case OPEN_TOTAL_COUNT_EAT: //bao an
          return{
            ...state,
            OpenTotalCountEat:true
          }
        case CLOSE_TOTAL_COUNT_EAT:
          return{
            ...state,
            OpenTotalCountEat:false
          }
        case OPEN_PRINT_TOTAL_COUNT_EAT: //in bao an
          return{
            ...state,
            OpenPrintTotalCountEat:true
          }
        case CLOSE_PRINT_TOTAL_COUNT_EAT:
          return{
            ...state,
            OpenPrintTotalCountEat:false
          }
        case OPEN_TOTAL_STUDENT_NOT_EAT:
          return{
            ...state,
            OpenTotalStudentNotEat:true
          }
        case CLOSE_TOTAL_STUDENT_NOT_EAT:
          return{
            ...state,
            OpenTotalStudentNotEat:false
          }
        case OPEN_PRINT_TOTAL_STUDENT_NOT_EAT:
          return{
            ...state,
            OpenPrintTotalStudentNotEat:true
          }
        case CLOSE_PRINT_TOTAL_STUDENT_NOT_EAT:
          return{
            ...state,
            OpenPrintTotalStudentNotEat:false
          }
        case OPEN_EXPORT_EXCEL_PAYMENT_TOTAL:
          return{
            ...state,
            OpenExportExcelPaymentTotal:!state.OpenExportExcelPaymentTotal
          }
        case OPEN_EXPORT_EXCEL_TOTAL_COUNT_EAT:
          return{
            ...state,
            OpenExportExcelTotalCountEat:!state.OpenExportExcelTotalCountEat
          }
        case OPEN_EXPORT_EXCEL_TOTAL_MATERIAL:
          return{
            ...state,
              OpenExportExcelTotalMaterial:!state.OpenExportExcelTotalMaterial
          }
        case OPEN_EXPORT_EXCEL_IMPORT_REPO:
          return{
            ...state,
            OpenExportExcelImportRepo:!state.OpenExportExcelImportRepo
          }
        case OPEN_EXPORT_EXCEL_TOTAL_MATERIAL_SUPPLIES:
          return{
            ...state,
            OpenExportExcelTotalMaterialSupplies:!state.OpenExportExcelTotalMaterialSupplies
          }
        case OPEN_EXPORT_EXCEL_TOTAL_STUDENT_NOT_EAT:
          return{
            ...state,
            OpenExportExcelStudentNotEat:!state.OpenExportExcelStudentNotEat
          }
        case OPEN_EXPORT_EXCEL_MATERIAL:
          return{
            ...state,
            OpenExportExcelMaterial:!state.OpenExportExcelMaterial
          }
        case TYPE_MATERIAL_TRUE:
          return{
            ...state,
            TypeMaterial:true
          }
        case TYPE_MATERIAL_FALSE:
          return{
            ...state,
            TypeMaterial:false
          }        
        case PRINT_TYPE_MATERIAL_FALSE:
          return{
            ...state,
            PrintTypeMaterial:false
          }
        case PRINT_TYPE_MATERIAL_TRUE:
          return{
            ...state,
            PrintTypeMaterial:true
          }
        case OPEN_EXPORT_EXCEL_TYPE_MATERIAL:
          return{
            ...state,
            OpenExportExcelTypeMaterial:true
          }
          case CLOSE_EXPORT_EXCEL_TYPE_MATERIAL:
          return{
            ...state,
            OpenExportExcelTypeMaterial:false
          }
         case RESULT_TYPE_MATERIAL_SUPPLIES:
           return{
             ...state,
             ResultTypeMaterialSupplies:action.payload.result,
             ListTypeMaterialSupplies:action.payload.result
           }
           case DISPATCH_NAME_TYPE_MATERIAL_SUPPLIES:            
             return{
               ...state,
               ResultTypeMaterialSupplies:state.ListTypeMaterialSupplies.filter(v=>v.name===action.payload.item)
             }
          case OPEN_LIST_CHECK_DAY_FOOD_IN_MONTH:
            return{
              ...state,
              OpenCheckDayFoodInMonth:true
            }
          case CLOSE_LIST_CHECK_DAY_FOOD_IN_MONTH:
            return{
              ...state,
              OpenCheckDayFoodInMonth:false
            }
          case OPEN_PRINT_TOTAL_CHECK_DAY_FOOD:
            return{
              ...state,
              OpenPrintTotalCheckDayFood:true
            }
          case CLOSE_PRINT_TOTAL_CHECK_DAY_FOOD:
            return{
              ...state,
              OpenPrintTotalCheckDayFood:false
            }       
          case OPEN_EXPORT_EXCEL_CHECK_DY_FOOD_IN_MONTH:
            return{
              ...state,
              OpenExportExcelCheckDayFoodInMonth:!state.OpenExportExcelCheckDayFoodInMonth
            }
          // case CLOSE_EXPORT_EXCEL_CHECK_DY_FOOD_IN_MONTH:
          //   return{
          //     ...state,
          //     OpenExportExcelCheckDayFoodInMonth:false
          //   }
          case OPEN_FORM_TRANSFER_LEFT_OVER:
            return{
              ...state,
              OpenTransferLeftOver:true
            }
          case CLOSE_FORM_TRANSFER_LEFT_OVER:
            return{
              ...state,
              OpenTransferLeftOver:false
            }
            case OPEN_FORM_TRANSFER_LEFT_OVER_TH:
            return{
              ...state,
              OpenTransferLeftOverTh:true
            }
          case CLOSE_FORM_TRANSFER_LEFT_OVER_TH:
            return{
              ...state,
              OpenTransferLeftOverTh:false
            }
          case DISPATCH_VALUES_TRANSFER_LEFT_OVER:
            return{
              ...state,
              ValuesTransferLeftOver:action.payload.item
            }
            case DISPATCH_VALUES_TRANSFER_LEFT_OVER_TH:
              return{
                ...state,
                ValuesTransferLeftOverTh:action.payload.item
              }
          case UPLOAD_TRANSFER_LEFT_OVER:
            return{
              ...state,
              ValuesUploadTransferLeftOver:action.payload.item
            }
            case UPLOAD_TRANSFER_LEFT_OVER_TH:
              return{
                ...state,
                ValuesUploadTransferLeftOverTh:action.payload.item
              }
          case DISPATCH_ADD_TRANSFER_LEFT_OVER_TO_STORE:
            toastSuccess('Kết chuyển phiếu ăn thừa thành công')            
            return{
              ...state,
              ItemTransferByClass:action.payload.item,
              ListTransferByAllClass:state.ListTransferByAllClass.concat(action.payload.item)
            }
            case DISPATCH_ADD_TRANSFER_LEFT_OVER_TO_STORE_TH:
              toastSuccess('Kết chuyển phiếu ăn thừa thành công')            
              return{
                ...state,
                ItemTransferByClass:action.payload.item,
                ListTransferByAllClass:state.ListTransferByAllClass.concat(action.payload.item)
              }
          case DISPATCH_LIST_TRANSFER_TO_STORE:
            return{
              ...state,
              ListTransferByAllClass:action.payload.item
            }
            case DISPATCH_LIST_TRANSFER_TO_STORE_TH:
              return{
                ...state,
                ListTransferByAllClass:action.payload.item
              }
          case DELETE_TRANSFER_LEFT_OVERS_BY_MONTH_CLASS:
            return{
              ...state,
              ListTransferByAllClass:_.difference(state.ListTransferByAllClass,action.payload.item)
            }
            case DELETE_TRANSFER_LEFT_OVERS_BY_MONTH_CLASS_TH:
              return{
                ...state,
                ListTransferByAllClass:_.difference(state.ListTransferByAllClass,action.payload.item)
              }
          case DELETE_TRANSFER_LEFT_SUCCESS:
            toastSuccess('Xóa Thành Công!')       
            return{
              ...state
            }
            case DELETE_TRANSFER_LEFT_SUCCESS_TH:
              toastSuccess('Xóa Thành Công!')       
              return{
                ...state
              }
          case OPEN_FORM_DELETE_TRANSFER_LEFT_OVER:
            return{
              ...state,
              OpenFormDeleteTransferLeftOver:true
            }
            case OPEN_FORM_DELETE_TRANSFER_LEFT_OVER_TH:
              return{
                ...state,
                OpenFormDeleteTransferLeftOverTh:true
              }
          case CLOSE_FORM_DELETE_TRANSFER_LEFT_OVER:
            return{
              ...state,
              OpenFormDeleteTransferLeftOver:false
            }
            case CLOSE_FORM_DELETE_TRANSFER_LEFT_OVER_TH:
              return{
                ...state,
                OpenFormDeleteTransferLeftOverTh:false
              }
           case OPEN_FORM_BUFFET:
             return{
               ...state,
               OpenFormBuffet:true
             }
          case CLOSE_FORM_BUFFET:
            return{
              ...state,
              OpenFormBuffet:false
            }
          case OPEN_UPDATE_BUFFET:
            return{
              ...state,
              OpenUpdateBuffet:true
            }
          case CLOSE_UPDATE_BUFFET:
            return{
              ...state,
              OpenUpdateBuffet:false
            }
          case OPEN_FORM_PRINT_RECEIPTS:
            return{
              ...state,
              OpenFormPrintReceipts:true
            }
          case CLOSE_FORM_PRINT_RECEIPTS:
            return{
              ...state,
              OpenFormPrintReceipts:false
            }
          case DISPATCH_ITEM_LIST_STUDENT_BY_CLASS_YEARSCHOOL:
            return{
              ...state,
              ListStudentByClassYearSchool:action.payload.item
            }
          case DISPATCH_ADD_RECEIPTS_TO_STORE:
            toastSuccess('Thêm Thành Công!')
            return{
              ...state,
              ListStudentPrintReceipts:state.ListStudentPrintReceipts.concat(action.payload.item),
              //ListStudentPrintReceiptsByYearSchoolByMonth:state.ListStudentPrintReceipts.filter()
            }
          case DISPATCH_GET_RECEIPTS_TO_STORE:
            return{
              ...state,
              ListStudentPrintReceipts:action.payload.item
            }
          case OPEN_FORM_UPDATE_PRINT_RECEIPTS:
            return{
              ...state,
              OpenFormUpdatePrintReceipts:true
            }
          case CLOSE_FORM_UPDATE_PRINT_RECEIPTS:
            return{
              ...state,
              OpenFormUpdatePrintReceipts:false
            }
          case UPDATE_RECEIPTS:
            toastSuccess('Cập nhật thành công!')
            return{
              ...state,
              ListStudentPrintReceipts:updateArrById(state.ListStudentPrintReceipts,action.payload.item)
            }
          case OPEN_DISPATCH_VALUE_PRINT_RECEIPTS_SELECT_ITEM:
            return{
              ...state,
              ValuePrintReceiptsSelect:{
                Value:true,
                Item:action.payload.item
              }
            }
            case CLOSE_DISPATCH_VALUE_PRINT_RECEIPTS_SELECT_ITEM:
              return{
                ...state,
                ValuePrintReceiptsSelect:{
                  Value:false,
                  Item:{}
                }
              }
            case OPEN_DISPATCH_VALUE_PRINT_RECEIPTS_ALL_SELECT_ITEM:
              return{
                ...state,
                ValuePrintReceiptsAllSelect:{
                  Value:true,
                  Item:action.payload.item
                }
              }
              case CLOSE_DISPATCH_VALUE_PRINT_RECEIPTS_ALL_SELECT_ITEM:
                return{
                  ...state,
                  ValuePrintReceiptsAllSelect:{
                    Value:false,
                    Item:[]
                  }
                }            
              case OPEN_FORM_UPDATE_DAILY_TUI:
                return{
                  ...state,
                  OpenFormUpdateDailyTui:true
                }
            case CLOSE_FORM_UPDATE_DAILY_TUI:
              return{
                ...state,
                OpenFormUpdateDailyTui:false
              }
            case DISPATCH_ADD_UPDATE_DAILY_TUI_TO_STORE:
              toastSuccess('Lưu dữ liệu thành công')
              return{
                ...state,
                ListUpdateDailyTui:state.ListUpdateDailyTui.concat(action.payload.item)
              }
            case DISPATCH_LIST_UPDATE_DAILY_TUI:
              return{
                ...state,
                ListUpdateDailyTui:action.payload.item
              }
            case UPDATE_ITEM_UPDATE_DAILY_TUI:
              return{
                ...state,
                ListUpdateDailyTui:updateArrById(state.ListUpdateDailyTui,action.payload.item)
              }
      default: return state
  } 
}

export default CountEatReducer