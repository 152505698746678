export const GET_INFO_USER_LOGIN    = 'GET_INFO_USER_LOGIN'
export const LIST_GOV               = 'LIST_GOV'
export const OPEN_DETAIL_INFO_GOV   = "OPEN_DETAIL_INFO_GOV"
export const CLOSE_DETAIL_INFO_GOV  = "CLOSE_DETAIL_INFO_GOV"
export const DISPATCH_EDIT_ITEM_GOV = "DISPATCH_EDIT_ITEM_GOV"
export const UPDATE_INFO_GOV_SUCCESS = "UPDATE_INFO_GOV_SUCCESS"
export const UPDATE_PASS_SUCCESS     = "UPDATE_PASS_SUCCESS"
export const UPDATE_PASS_GOV         = "UPDATE_PASS_GOV"

export const OPEN_SETTING_ADVANCE    = "OPEN_SETTING_ADVANCE"
export const CLOSE_SETTING_ADVANCE   = "CLOSE_SETTING_ADVANCE"
export const ADD_INFO_USER_DETAIL    = "ADD_INFO_USER_DETAIL"
export const LIST_INFO_USER_DETAIL   = "LIST_INFO_USER_DETAIL"
export const DISPATCH_ITEM_INFO_USER_DETAIL = "DISPATCH_ITEM_INFO_USER_DETAIL"
export const DISPATCH_LIST_INFO_USER_DETAIL = "DISPATCH_LIST_INFO_USER_DETAIL"
export const EDIT_ITEM_INFO_USER_DETAIL = "EDIT_ITEM_INFO_USER_DETAIL"
export const EDIT_ARR_INFO_USER_DETAIL = "EDIT_ARR_INFO_USER_DETAIL"

export const DISPATCH_DELETE_ITEM_INFO_USER_DETAIL = "DISPATCH_DELETE_ITEM_INFO_USER_DETAIL"
export const DISPATCH_EDIT_ITEM_INFO_USER_DETAIL   = "DISPATCH_EDIT_ITEM_INFO_USER_DETAIL"
export const DISPATCH_OPEN_DELETE_ITEM_INFO_USER_DETAIL ="DISPATCH_OPEN_DELETE_ITEM_INFO_USER_DETAIL"
export const DISPATCH_CLOSE_DELETE_ITEM_INFO_USER_DETAIL ="DISPATCH_CLOSE_DELETE_ITEM_INFO_USER_DETAIL"
export const DELETE_SUCCESS = "DELETE_SUCCESS"

export const OPEN_TEMPLETE                  = "OPEN_TEMPLETE"
export const CLOSE_TEMPLETE                 = "CLOSE_TEMPLETE"
export const ADD_TEMPLETE                   = "ADD_TEMPLETE"
export const DISPATCH_ADD_TEMPLETE_TO_STORE = "DISPATCH_ADD_TEMPLETE_TO_STORE"
export const LIST_BY_USERID_TEMPLETE        = "LIST_BY_USERID_TEMPLETE"
export const DISPATCH_LIST_ITEM_TO_STORE_TEMPLETE    = "DISPATCH_LIST_ITEM_TO_STORE_TEMPLETE"
export const SHOW_LIST_VIEW_TEMPLETE        = "SHOW_LIST_VIEW_TEMPLETE"
export const CLOSE_SHOW_LIST_VIEW_TEMPLETE  = "CLOSE_SHOW_LIST_VIEW_TEMPLETE"
export const ADD_TEMPLETE_SUCCESS           = "ADD_TEMPLETE_SUCCESS"
export const EDIT_VIEW_TEMPLETE_TRUE        = "EDIT_VIEW_TEMPLETE_TRUE"
export const EDIT_VIEW_TEMPLETE_FALSE       = "EDIT_VIEW_TEMPLETE_FALSE"
export const EDIT_ITEM_TEMPLETE             = "EDIT_ITEM_TEMPLETE"

export const LOGIN_FAIL_ACCOUNT_ONLY             = "LOGIN_FAIL_ACCOUNT_ONLY"
export const ALERT_LOGIN_FAIL_ACCOUNT_ONLY       = "ALERT_LOGIN_FAIL_ACCOUNT_ONLY"

export const OPEN_FORM_SIGN_REGIS           = "OPEN_FORM_SIGN_REGIS"
export const CLOSE_FORM_SIGN_REGIS          = "CLOSE_FORM_SIGN_REGIS"

export const ADD_FORM_SIGN_REGIS                                  = "ADD_FORM_SIGN_REGIS"
export const ADD_FORM_SIGN_REGIS_SUCCESS_AND_DISPATCH_TO_STORE    = "ADD_FORM_SIGN_REGIS_SUCCESS_AND_DISPATCH_TO_STORE"
export const GET_FORM_SIGN_REGIS_BY_USERID                        = "GET_FORM_SIGN_REGIS_BY_USERID"
export const DISPATCH_ITEM_FORM_REGIS_TO_STORE                    = "DISPATCH_ITEM_FORM_REGIS_TO_STORE"

export const OPEN_UPDATE_FORM_SIGN_REGIS           = "OPEN_UPDATE_FORM_SIGN_REGIS"
export const CLOSE_UPDATE_FORM_SIGN_REGIS          = "CLOSE_UPDATE_FORM_SIGN_REGIS"

export const UPDATE_FORM_SIGN_REGIS                                  = "UPDATE_FORM_SIGN_REGIS"
export const UPDATE_FORM_SIGN_REGIS_SUCCESS                          = "UPDATE_FORM_SIGN_REGIS_SUCCESS"



export const ADD_ITEM_LIST_SOFT_PRICE          = "ADD_ITEM_LIST_SOFT_PRICE"
export const DISPATCH_LIST_SOFT_PRICE_TO_STORE    = "DISPATCH_LIST_SOFT_PRICE_TO_STORE"
export const UPDATE_ITEM_LIST_SOFT_PRICE      = "UPDATE_ITEM_LIST_SOFT_PRICE"


