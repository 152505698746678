export const TASK_LOADING_SUCCESS = 'TASK_LOADING_SUCCESS'
export const TASK_LOADING_FALING = 'TASK_LOADING_FAILNG'
export const TASK_LIST            = 'TASK_LIST'

export const FILTER_TASK = "FILTER_TASK"
export const FILTER_TASK_SUCCESS = "FILTER_TASK_SUCCESS"

export const ADD_TASK ="ADD_TASK"
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS"
export const ADD_TASK_FAIL = "ADD_TASK_FAIL"

export const TASK_EDITTING = "TASK_EDITTING"

export const UPDATE_TASK = "UPDATE_TASK"
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS"
export const UPDATE_TASK_FAIL = "UPDATE_TASK_FAIL"

export const DELETE_TASK ="DELETE_TASK"
export const DELETE_TASK_SUCCESS= "DELETE_TASK_SUCCESS"
export const DELETE_TASK_FAIL ="DELETE_TASK_FAIL"