import {ADD_MATERIAL,LIST_ALL_MATERIAL,
  LIST_ALL_CATEGORY_MATERIAL,
   ADD_MATERIAL_STORE,
    LIST_ALL_CATEGORY_TYPE_MATERIAL, 
    ADD_TYPE_MATERIAL_STORE,
    EDIT_MATERIAL,
    EDIT_TYPE_MATERIAL,
  LIST_ALL_TYPE_MATERIAL,
  LIST_TYPE_MATERIAL_BY_GROUP,
 DISPATCH_ITEM_CATE_MATERIAL_TO_STORE
} from '../../../contant/Gov/cost'
import {DISPATCH_PRICES_MATERIAL_BY_DATE,DISPACTH_NAME_CATE_MATERIAL,
MATERIAL_LIST_VIEW_OPEN,
CLOSE_EDIT_SUPPLIES_COUNT_EAT,DISPACTH_NAME_SUPPLIES,SUPPLIES_LIST_VIEW_OPEN,
LIST_TYPE_MATERIAL_NEW_ARR,DISPATCH_ADD_REPOSITORY_TO_STORE,DISPATCH_LIST_REPOSITORY_TO_STORE,
OPEN_FORM_ALLOW_DELETE,CLOSE_FORM_ALLOW_DELETE,ALLOW_DELETE_REPOSITORY,OPEN_FORM_EDIT_REPOSITORY,CLOSE_FORM_EDIT_REPOSITORY,
DISPATCH_ITEM_EDIT_REPOSITORY,REPO_LIST_VIEW_OPEN,
DISPATCH_ITEM_EDIT_SAVE,DISPATCH_NAME_MANAGE_REPO,MANAGE_REPO_CLOSE,MANAGE_REPO_OPEN,
PRINT_MANAGE_REPO_FALSE,PRINT_MANAGE_REPO_TRUE,OPEN_IMPORT_REPO,CLOSE_IMPORT_REPO,DISPATCH_LIST_TYPE_MATERIAL_TO_STORE,
DISPATCH_ADD_IMPORT_TO_STORE,EDIT_IMPORT_REPO,DISPATCH_ITEM_ADD_BALANCE_TO_STORE,DISPATCH_LIST_BALANCE_TO_STORE,
DISPATCH_EDIT_BALANCE,DISPATCH_EVENT_AFTER_ADD_BALANCE,DELETE_ITEM_IMPORT_TYPE_MATERIAL,
DISPACTH_NAME_TYPE_MATERIAL,TYPE_MATERIAL_LIST_VIEW_OPEN,TYPE_MATERIAL_LIST_VIEW_CLOSE,DISPATCH_NAME_TYPE_MATERIAL_SUPPLIES, OPEN_EDIT_SUPPLIES_COUNT_EAT,
UPDATE_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN
} from '../../../contant/Gov/countEat/dispatch'
import {OPEN_ALERT_FORM_ACTION_MATERAIL,CLOSE_ALERT_FORM_ACTION_MATERAIL,OPEN_ALERT_DELETE_CATE_MATERIAL,CLOSE_ALERT_DELETE_CATE_MATERIAL,
      OPEN_ALERT_DO_NOT_DELETE,CLOSE_ALERT_DO_NOT_DELETE,OPEN_ALLOW_DELETE,CLOSE_ALLOW_DELETE,
      DISPATCH_ITEM_DELETE_MATERIAL,DISPATCH_ITEM_DELETE_TYPE_MATERIAL,
      OPEN_DELETE_TYPE_MATERIAL,CLOSE_DELETE_TYPE_MATERIAL
} from '../../../contant/Gov/cost'
  import {TITLE_ADD_MATERIAL_SUCCESS} from '../../../contant/alert'
  import {toastSuccess} from '../../../helper/toastHelper'
  import {findListMaterialById,updateArrById,FilterIdTypeMaterial
  } from '../../../helper/fnAllways/index'
  import {sortArrAsc,sortArrDsc} from '../../../helper/tableList/tableList'
  import {ResultListMaterialByDate, showDDMMYY,showYYYY,showMM,numberDate} from '../../../helper/dateTime/handleDMY'
  import {handleSumPriceTypeMaterial} from '../../../helper/countEat/index'
  import {FindItemBalanceToEdit,DeleteItemById} from '../../../helper/fnAllways/index'
  import {DISPATCH_ITEM_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN} from '../../../contant/Gov/countEat/dispatch'

let initialState ={
  listMaterial:[], 
  ListTypeMaterial:[],
  itemValueMaterial:'',
  itemValueTypeMaterial:'',
  ListTypeMaterialByGroup:[],
  openListMaterial:false,
  itemMaterial:'',
  ListMaterialByItem:[],  
  ListPriceMaterialByDate:[],
  ResultListPrices:"",
  ResultListPricesSum:"",
  NameCate:'',
  ListArrPriceByName:{
    ListArr:[],
    dateTime:"",
    NameMaterial:"",
    NameTypeMaterial:"",
    SumItemTypeMaterial:"",
    SumQuantityTypeMaterial:""
  },
  MaterialListViewOpen:false,
  TypeMaterialListViewOpen:false,
  numberDate:"",
  OpenEditSupplies:true,
  SuppliesListViewOpen:false,
  NameSupplies:"",  
  NameTypeMaterialSupplies:"",
  ItemTypeMaterialSupplies:"",
  ItemSupplies:"",
  FilterNameSupplies:"",
  NewArrListTypeMaterial:[],
  NameTypeMaterial:[],
  ListRepository:[],
  OpenFormAlertDelete:false,
  OpenFormEditRepository:false,
  ItemEdit:"",
  ItemManageRepo:"",
  ManageRepoOpen:false,
  RepoListView:false,
  PrintManageRepo:false,
  OpenImportRepo:false,
  ItemImportRepo:"",
  ListImportTypeMaterial:[],
  ListImportByIdTypeMaterial:[],
  ItemBalance:"",
  ListBalance:[],
  ListBalaneByIdTypeMaterial:"",
  ItemBalanceSumless:"",
  OpenAlertFormActionMaterial:false,
  OpenAlertDeleteCateMaterial:false,
  OpenAlertDoNotDelete:false,
  OpenAllowDelete:false,
  OpenDeleteTypeMaterial:false,
  ItemNutriTinhAn:[]
}

const MaterialReducer = (state=initialState,action)=>{
  switch (action.type) {
    case ADD_MATERIAL:
          return{
            ...state,            
          }
    case LIST_ALL_MATERIAL:      
      return {
        ...state
      }  
    case LIST_ALL_TYPE_MATERIAL:
      return{
        ...state
      }  

    case DISPATCH_ITEM_CATE_MATERIAL_TO_STORE:
        return{
          ...state,
          itemMaterial:action.payload.row,     
          ListMaterialByItem:sortArrAsc(findListMaterialById(state.ListTypeMaterial,action.payload.row))
        }     
      
    case ADD_MATERIAL_STORE:
      toastSuccess(TITLE_ADD_MATERIAL_SUCCESS)
      return{
        ...state,
        listMaterial:(state.listMaterial).concat(action.payload.item),
        itemValueMaterial:action.payload.item
      }

    case ADD_TYPE_MATERIAL_STORE:     
      return{
        ...state,
        ListTypeMaterial:(state.ListTypeMaterial).concat(action.payload.item),
        ListMaterialByItem:(state.ListMaterialByItem).concat(action.payload.item),
        itemValueTypeMaterial:action.payload.item,
      }
    case DISPATCH_ITEM_DELETE_TYPE_MATERIAL:
      return{
        ...state,
        ListTypeMaterial:sortArrAsc(DeleteItemById(state.ListTypeMaterial,action.payload.item)),
        ListMaterialByItem:sortArrAsc(DeleteItemById(state.ListMaterialByItem,action.payload.item))
      }
     
    case LIST_ALL_CATEGORY_MATERIAL:  
    //xap xep thu tu tang dan    
      return{
        ...state,
        listMaterial:sortArrAsc(action.payload.arr)
      }

    case LIST_ALL_CATEGORY_TYPE_MATERIAL:
      //xap xep tang dan      
      return{
        ...state,
        ListTypeMaterial:sortArrDsc(action.payload.arr)
      }

    case LIST_TYPE_MATERIAL_BY_GROUP:
      return{
        ...state,
        ListTypeMaterialByGroup:action.payload.arrNew
      }

    case EDIT_MATERIAL://upadte material     
    return {...state,
      listMaterial:sortArrAsc(updateArrById(state.listMaterial,action.payload.ITEM))
    }
    case DISPATCH_ITEM_DELETE_MATERIAL:
      return{
        ...state,
        listMaterial:sortArrAsc(DeleteItemById(state.listMaterial,action.payload.item))
      }

    case EDIT_TYPE_MATERIAL:  
      return {...state,
            ListMaterialByItem:sortArrAsc(updateArrById(state.ListMaterialByItem,action.payload.ItemValue)),
            ListTypeMaterial  :sortArrAsc(updateArrById(state.ListTypeMaterial,action.payload.ItemValue))
      }  

    case DISPATCH_PRICES_MATERIAL_BY_DATE:
      let result = ResultListMaterialByDate(action.payload.ListPrices,state.ListTypeMaterial,state.listMaterial)
      let result2 = ResultListMaterialByDate(action.payload.ListPrices,state.ListTypeMaterial,state.listMaterial)
      let date = action.payload.ListPrices.length===0?"":action.payload.ListPrices[0].dateMeal
      let numberDateOfMonth = numberDate(showMM(date),showYYYY(date))      
      let {cate} = result2
      let newValue = "Tổng Cộng"
      cate.push(newValue)      
      return{
        ...state,
        ResultListPrices:result,
        numberDate:numberDateOfMonth,
        ResultListPricesSum:result2
      }
    case DISPACTH_NAME_CATE_MATERIAL:
      let Name = action.payload.NameCate.toLowerCase()
      let arrPriceByName = state.ResultListPrices.arrPrice.filter(v=>v.nameMaterial===Name)
      return{
        ...state,
         ListArrPriceByName:{
         ListArr:handleSumPriceTypeMaterial(arrPriceByName),
         dateBegin:showDDMMYY(arrPriceByName[0].dateMeal),
         dateEnd:showDDMMYY(arrPriceByName[arrPriceByName.length-1]),
         NameMaterial:Name
        }       
      }
      case DISPACTH_NAME_TYPE_MATERIAL:
        let {typeMaterial} = state.ResultListPrices
        let NameTypeMaterial=action.payload.NameTypeMaterial.toLowerCase()
        let FilterNameTypeMaterial = typeMaterial.filter(v=>v.name===NameTypeMaterial)
        return{
          ...state,          
          ListArrPriceByName:{                         
             NameTypeMaterial:NameTypeMaterial,
             ListArr:FilterNameTypeMaterial,
             dateBegin:showDDMMYY(FilterNameTypeMaterial[0].dateMeal),
             dateEnd:showDDMMYY(FilterNameTypeMaterial[FilterNameTypeMaterial.length-1]),
             SumItemTypeMaterial:(FilterNameTypeMaterial.map(v=>v.quantity*v.price)).reduce((x,y)=>x+y,0),
             SumQuantityTypeMaterial:(FilterNameTypeMaterial.map(v=>v.quantity)).reduce((x,y)=>x+y,0)     
       }
    }
    case MATERIAL_LIST_VIEW_OPEN:
      return{
        ...state,
        MaterialListViewOpen:!state.MaterialListViewOpen        
      }
    case TYPE_MATERIAL_LIST_VIEW_OPEN:
      return{
        ...state,
        TypeMaterialListViewOpen:true
      }
      case TYPE_MATERIAL_LIST_VIEW_CLOSE:
      return{
        ...state,
        TypeMaterialListViewOpen:false
      }
      case SUPPLIES_LIST_VIEW_OPEN:
        return{
          ...state,
          SuppliesListViewOpen:!state.SuppliesListViewOpen
        }
      case DISPACTH_NAME_SUPPLIES:       
        let item =(action.payload.nameSupplies)         
        return{
          ...state,
          NameSupplies:item.nameSupplies,
          ItemSupplies:item,
          
        }
        case DISPATCH_NAME_TYPE_MATERIAL_SUPPLIES:       
        //let ITEM =(action.payload.Item)         
        return{
          ...state,
          //NameTypeMaterialSupplies:Item.nameTypeMaterialSupplies,
          ItemTypeMaterialSupplies:action.payload.item
        }
    case OPEN_EDIT_SUPPLIES_COUNT_EAT:
      return{
        ...state,
        OpenEditSupplies:true
      }
    case CLOSE_EDIT_SUPPLIES_COUNT_EAT:
      return{
        ...state,
        OpenEditSupplies:false
      }   
    case LIST_TYPE_MATERIAL_NEW_ARR:
      return{
        ...state,
        NewArrListTypeMaterial:state.ListTypeMaterial,
        NameTypeMaterial:state.ListTypeMaterial.map(v=>v.name)
      }
   case DISPATCH_ADD_REPOSITORY_TO_STORE:
     return{
       ...state,
       ListRepository:sortArrAsc(state.ListRepository.concat(action.payload.item))
     }
    case DISPATCH_LIST_REPOSITORY_TO_STORE:
      return{
        ...state,
        ListRepository:sortArrAsc(action.payload.item)
      }
    case OPEN_FORM_ALLOW_DELETE:
      return{
        ...state,
        OpenFormAlertDelete:true
      }
    case CLOSE_FORM_ALLOW_DELETE:
      return{
        ...state,
        OpenFormAlertDelete:false
      }
      case ALLOW_DELETE_REPOSITORY:
        return{
          ...state,
          ListRepository:sortArrAsc(state.ListRepository.filter(v=>v._id!==action.payload.item._id)),          
        }
      case OPEN_FORM_EDIT_REPOSITORY:
        return{
          ...state,
          OpenFormEditRepository:true
        }
      case CLOSE_FORM_EDIT_REPOSITORY:
        return{
          ...state,
          OpenFormEditRepository:false
        }
        case DISPATCH_ITEM_EDIT_REPOSITORY:
          return{
            ...state,
            ItemEdit:action.payload.item
          }
        case DISPATCH_ITEM_EDIT_SAVE:
          return{
            ...state,
           ListRepository:sortArrAsc(updateArrById(state.ListRepository,action.payload.Item))
          }
        case DISPATCH_NAME_MANAGE_REPO:
          return{
            ...state,
            ItemManageRepo:action.payload.Item
          }
        case MANAGE_REPO_OPEN:
          return{
            ...state,
            ManageRepoOpen:true
          }
        case MANAGE_REPO_CLOSE:
          return{
            ...state,
            ManageRepoOpen:false
          }
        case REPO_LIST_VIEW_OPEN:
          return{
            ...state,
            RepoListView:!state.RepoListView
          }
        case PRINT_MANAGE_REPO_TRUE:
          return{
            ...state,
            PrintManageRepo:true
          }
        case PRINT_MANAGE_REPO_FALSE:
          return{
            ...state,
            PrintManageRepo:false
          }
        case OPEN_IMPORT_REPO:
          return{
            ...state,
            OpenImportRepo:true,
            ItemImportRepo:action.payload.item,
            ListImportByIdTypeMaterial:FilterIdTypeMaterial(state.ListImportTypeMaterial,action.payload.item)
          }
        case CLOSE_IMPORT_REPO:
          return{
            ...state,
            OpenImportRepo:false
          }          
        case DISPATCH_LIST_TYPE_MATERIAL_TO_STORE:
          return{
            ...state,
            ListImportTypeMaterial:action.payload.item,
            ListImportByIdTypeMaterial:FilterIdTypeMaterial(action.payload.item,state.ItemImportRepo)
          }
        case DISPATCH_ADD_IMPORT_TO_STORE:
          let ArrList = (state.ListImportTypeMaterial.concat(action.payload.item)).reverse()
          return{
            ...state,
            ListImportTypeMaterial: ArrList,
            ListImportByIdTypeMaterial: FilterIdTypeMaterial(ArrList,state.ItemImportRepo)            
          }        
          case DELETE_ITEM_IMPORT_TYPE_MATERIAL:
            return{
              ...state,
              ListImportTypeMaterial:state.ListImportTypeMaterial.filter(v=>v._id!==action.payload.item._id),
              ListImportByIdTypeMaterial:state.ListImportByIdTypeMaterial.filter(v=>v._id!==action.payload.item._id)
            }
        case EDIT_IMPORT_REPO:
          let ResultArr = updateArrById(state.ListImportTypeMaterial,action.payload.item)
          return{
            ...state,
            ListImportTypeMaterial:updateArrById(state.ListImportTypeMaterial,action.payload.item),
            ListImportByIdTypeMaterial: FilterIdTypeMaterial(ResultArr,action.payload.item)            
          }

        case DISPATCH_ITEM_ADD_BALANCE_TO_STORE:
          let ARR_ = state.ListBalance.concat(action.payload.item)
          return{
            ...state,
            ItemBalance:action.payload.item,
            ListBalance:ARR_            
          }
        case DISPATCH_LIST_BALANCE_TO_STORE:
          return{
            ...state,
            ListBalance:action.payload.item
          }
        case DISPATCH_EDIT_BALANCE:
          return{
            ...state,
            ListBalance:updateArrById(state.ListBalance,action.payload.item)
          }
        case DISPATCH_EVENT_AFTER_ADD_BALANCE:
          let {userId,DateInput,Item} = action.payload          
          let List = state.ListBalance
          let FindValue = FindItemBalanceToEdit(List,userId,Item,DateInput)
          return{
            ...state,
            ItemBalanceSumless:FindValue
          }
        case OPEN_ALERT_FORM_ACTION_MATERAIL:
          return{
            ...state,
            OpenAlertFormActionMaterial:true
          }
        case CLOSE_ALERT_FORM_ACTION_MATERAIL:
          return{
            ...state,
            OpenAlertFormActionMaterial:false
          }
        case OPEN_ALERT_DELETE_CATE_MATERIAL:
          return{
            ...state,
            OpenAlertDeleteCateMaterial:true
          }
        case CLOSE_ALERT_DELETE_CATE_MATERIAL:
          return{
            ...state,
            OpenAlertDeleteCateMaterial:false
          }
        case OPEN_ALERT_DO_NOT_DELETE:
          return{
            ...state,
            OpenAlertDoNotDelete:true
          }
        case CLOSE_ALERT_DO_NOT_DELETE:
          return{
            ...state,
            OpenAlertDoNotDelete:false
          }
        case OPEN_ALLOW_DELETE:
          return{
            ...state,
            OpenAllowDelete:true
          }
        case CLOSE_ALLOW_DELETE:
          return{
            ...state,
            OpenAllowDelete:false
          }
        case OPEN_DELETE_TYPE_MATERIAL:
          return{
            ...state,
            OpenDeleteTypeMaterial:true
          }
        case CLOSE_DELETE_TYPE_MATERIAL:
          return{
            ...state,
            OpenDeleteTypeMaterial:false
          }
        case DISPATCH_ITEM_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN:
          return{
            ...state,
            ItemNutriTinhAn:action.payload.item
          }
        case UPDATE_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN:
          toastSuccess('Cập nhật thành công')
          return{
            ...state,
            ItemNutriTinhAn:action.payload.item
          }
    default: return state      
  }
}

export default MaterialReducer