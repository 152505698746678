import React from 'react';
import {Dialog,DialogContent,TableContainer,TableHead,TableCell,TableRow,TableBody,Paper,Table} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import {useStyles} from './styles'
import {changeUpperCase} from '../../../../helper/fnAllways'
import {useSelector} from 'react-redux'
import {showDDMMYY} from '../../../../helper/dateTime/handleDMY'

export default function Index({open,handleClose,value,name}) {
  const classes = useStyles()
  const SelectDate = useSelector(state => state.ManagerAccount.SelectDate)
  const showList=()=>{
    return value.map((v,i)=>{
      return <TableRow key={i}>
                <TableCell align="center">{i+1}</TableCell>
                <TableCell align="left">{changeUpperCase(v)}</TableCell>               
      </TableRow>
    })
  }
  return (    
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Alert severity="info">Danh sách học sinh báo vắng {showDDMMYY(SelectDate)} Lớp {changeUpperCase(name)}</Alert>
        <DialogContent>          
        <TableContainer component={Paper}>    
            <Table className={classes.table} stickyHeader aria-label="sticky table" size="small" >
              <TableHead>
                <TableRow>
                <TableCell align="center">Stt</TableCell>                                    
                 <TableCell align="left">Tên Học Sinh</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>       
                      {showList()}
              </TableBody>
            </Table>
      </TableContainer>
        </DialogContent>
       
      </Dialog>
  );
}
