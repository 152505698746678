import {call,put,takeLatest} from 'redux-saga/effects'
import { CreateNewWebsiteApi } from '../api'
import { CREATE_NEW_WEBSITE } from '../contant'

function* CreateNewWebsiteSaga({payload}){
    let res = yield call(CreateNewWebsiteApi,payload.item)
}


function* IndexSaga(){
    yield takeLatest(CREATE_NEW_WEBSITE,CreateNewWebsiteSaga)

}

export default IndexSaga