import React,{Suspense} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
//import WeeklyFood  from '../../../ComponentGov/SoftFoodTabMain/WeeklyFood/index'
//import Cost from '../../../ComponentGov/SoftFoodTabMain/Cost'
//import Student from '../../../ComponentGov/SoftFoodTabMain/StudentGroup'
//import CountEat from '../../../ComponentGov/SoftFoodTabMain/CountEat'
//import Nutrikid from '../../../ComponentGov/SoftFoodTabMain/Nutrikid/component'
//import ManagerAccount from '../../../ManagerAccountActiveGov/component/ManagerAccount'
//import PaymentAccount from '../../../ComponentGov/SoftFoodTabMain/Nutrikid/PayMent'
import {MainDiv} from './styles'

const WeeklyFood         = React.lazy(()=>import('../../../ComponentGov/SoftFoodTabMain/WeeklyFood/index'))
const Cost               = React.lazy(()=>import('../../../ComponentGov/SoftFoodTabMain/Cost'))
const Student            = React.lazy(()=>import('../../../ComponentGov/SoftFoodTabMain/StudentGroup'))
const CountEat           = React.lazy(()=>import('../../../ComponentGov/SoftFoodTabMain/CountEat'))
const Nutrikid           = React.lazy(()=>import('../../../ComponentGov/SoftFoodTabMain/Nutrikid/component'))
const ManagerAccount     = React.lazy(()=>import('../../../ManagerAccountActiveGov/component/ManagerAccount'))
const PaymentAccount     = React.lazy(()=>import('../../../ComponentGov/SoftFoodTabMain/Nutrikid/PayMent'))
const CreateWebSite      = React.lazy(()=>import('../../../ComponentGov/SoftFoodTabMain/WebSiteMamNon'))
const CanDoSucKhoe       = React.lazy(()=>import('../../../ComponentGov/SoftFoodTabMain/CanDoSucKhoe/Component'))

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const ScrollableTabsButtonAuto=React.memo(()=> {
  //const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainDiv >
          <AppBar position="static" color="default" style={{zIndex:3}}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Cài Đặt" {...a11yProps(0)} />
              <Tab label="Thực đơn tuần" {...a11yProps(1)} />
              <Tab label="Học sinh" {...a11yProps(2)} />
              <Tab label="Thu Chi" {...a11yProps(3)} />
              <Tab label="Quản trị" {...a11yProps(4)}/>
              <Tab label="Tính Khẩu Phần" {...a11yProps(5)}/>
              <Tab label="Cân Đo" {...a11yProps(6)}/>
              <Tab label="WebSite" {...a11yProps(7)}/>          
              <Tab label="Thanh Toán"{...a11yProps(8)}/>          
            </Tabs>
          </AppBar>     
           
          <TabPanel value={value} index={0}>
              <Suspense fallback={<></>}>
                  <Cost/>
              </Suspense>    
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Suspense fallback={<></>}>
                <WeeklyFood/>
            </Suspense>      
          </TabPanel>

          <TabPanel value={value} index={2}>
            <Suspense fallback={<></>}>
              <Student/>
            </Suspense>           
          </TabPanel>

          <TabPanel value={value} index={3}>
          <Suspense fallback={<></>}>
              <CountEat/>
            </Suspense>               
          </TabPanel>

          <TabPanel value={value} index={4}>
              <Suspense fallback={<></>}>
                  <ManagerAccount/>
              </Suspense>           
          </TabPanel>   

          <TabPanel value={value} index={5}>
              <Suspense fallback={<></>}>
                <Nutrikid/>
              </Suspense>              
          </TabPanel>   

          <TabPanel value={value} index={6}>
              <Suspense fallback={<></>}>
                <CanDoSucKhoe/>
              </Suspense>              
          </TabPanel>   

          <TabPanel value={value} index={7}>
              <Suspense fallback={<></>}>
                <CreateWebSite/>
              </Suspense>              
          </TabPanel>   

          <TabPanel value={value} index={8}>
            <Suspense fallback={<></>}>
                <PaymentAccount/>
            </Suspense>              
          </TabPanel>   
    </MainDiv>
  );
})
export default  ScrollableTabsButtonAuto