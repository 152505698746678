import moment         from "moment"
import { showYYMMDD } from "../../helper/dateTime/handleDMY"

const NumberDayPrv = 25
const OneMonth     = 30
const LOCK         = "LOCK"
const UNLOCK       = "UNLOCK"

export const TimePrvOneMonth=(item,number)=>{
        return showYYMMDD(moment(item).subtract(number,'days'))          
}

export const ThoiGianTruocMotThang=(item)=>{
          return showYYMMDD(moment(item).subtract(NumberDayPrv,'days'))          
}

export const expiredMonth30Day=()=>{    
        let dateCurrent =showYYMMDD(Date.now())
        return showYYMMDD(moment(dateCurrent).add(OneMonth,'days'))
}

export const ActiveSoftFoodPayment=(numberDay)=>{
        let dateCurrent =showYYMMDD(Date.now())
        return showYYMMDD(moment(dateCurrent).add(numberDay,'days'))
}

export const DateUntil = (dateExpire)=>{
        let expireDate         = new Date(dateExpire)
        let timeDiffInMillisec = Math.abs(expireDate - new Date());
        let remainingDays      = Math.ceil(timeDiffInMillisec / (1000 * 3600 * 24))
        return remainingDays
      }

export const CompareDayToLockApp=(inputDate,currentDay)=>{
       if(inputDate!==null){       
        let INPUT_DATE  = new Date(inputDate)
        let CURRENT_DAY = new Date(currentDay)       
        //truong hop mo khoa
        if(INPUT_DATE>=CURRENT_DAY){
               return UNLOCK
        }
        if(INPUT_DATE<CURRENT_DAY){
               return LOCK
        }
       }
        return null
}