
export const updateArrById = (arr,value)=>{  
  let index = arr.findIndex(item=>item._id===value._id)
  if(index!==-1){
    let newArr =[
      ...arr.slice(0,index),
      value,
      ...arr.slice(index+1)
    ]   
    return newArr
  }else{
    return null
  }  
}