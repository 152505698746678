export const OPEN_TAI_KHOAN_SIEU_QUAN_TRI  = "OPEN_TAI_KHOAN_SIEU_QUAN_TRI"
export const CLOSE_TAI_KHOAN_SIEU_QUAN_TRI = "CLOSE_TAI_KHOAN_SIEU_QUAN_TRI"

export const CREATE_NEW_ACCOUNT_QUAN_TRI = "CREATE_NEW_ACCOUNT_QUAN_TRI"

export const CREATE_NEW_ACCOUNT_QUANTRI_SUCCESS = "CREATE_NEW_ACCOUNT_QUANTRI_SUCCESS"
export const CREATE_NEW_ACCOUNT_QUANTRI_FAIL    = "CREATE_NEW_ACCOUNT_QUANTRI_FAIL"

export const LOADING_COMPONENT_OPEN  = "LOADING_COMPONENT_OPEN"
export const LOADING_COMPONENT_CLOSE = "LOADING_COMPONENT_CLOSE"

export const CLOSE_NEW_ACCOUNT_QUANTRI_SUCCESS = "CLOSE_NEW_ACCOUNT_QUANTRI_SUCCESS"
export const CLOSE_NEW_ACCOUNT_QUANTRI_FAIL    = "CLOSE_NEW_ACCOUNT_QUANTRI_FAIL"


export const CLOSE_FORM_AFTER_CREATE_SUCCESS = "CLOSE_FORM_AFTER_CREATE_SUCCESS"
export const OPEN_FORM_BEFORE_CREATE_SUCCESS = "OPEN_FORM_BEFORE_CREATE_SUCCESS"

export const CREATE_ACCOUNT_QUAN_TRI           = "CREATE_ACCOUNT_QUAN_TRI"//tao tai khoan sieu quan tri va lay ds account
export const GET_ALL_ACCOUNT_TO_STORE_QUAN_TRI = "GET_ALL_ACCOUNT_TO_STORE_QUAN_TRI"

export const LOGIN_PAGE_QUAN_TRI_SUCCESS ="LOGIN_PAGE_QUAN_TRI_SUCCESS"
export const LOGIN_PAGE_QUAN_TRI_FAIL    ="LOGIN_PAGE_QUAN_TRI_FAIL"


export const LOGOUT_SYSTEM ="LOGOUT_SYSTEM"

//update

export const UPDATE_ACCOUNT_LOGIN_QUAN_TRI = "UPDATE_ACCOUNT_LOGIN_QUAN_TRI"
export const UPDATE_ACCOUNT_LOGIN_QUAN_TRI_SUCCESS = "UPDATE_ACCOUNT_LOGIN_QUAN_TRI_SUCCESS"
export const UPDATE_ACCOUNT_LOGIN_QUAN_TRI_FAIL    = "UPDATE_ACCOUNT_LOGIN_QUAN_TRI_FAIL"
