import styled,{keyframes} from "styled-components";
import { device } from "../../../../style/MainCss/device";
import { MainColor } from "../color";

export const gradient = keyframes`
    100% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    0% {
        background-position: 0% 0%;
    }
`

export const MainUuViet = styled.div`
        width:80%;
        height:600px;
        margin:auto;
        background:#171616;           
        @media (max-width:768px){
            height:965px;
        }
`

export const HeadingText = styled.h2`
        text-align:center;
        color:${MainColor};
        font-size:40px;
        @media ${device.mobile}{
            font-size:20px;
        }
`

export const ContentList = styled.div`
        width:100%;
        height:400px;
        margin:auto;
        margin-top:30px;
        padding:20px;
        @media ${device.mobile}{
            margin-top:0px;
            padding:0px;
        }
`

export const ItemList = styled.div`
    width:50%;
    height:60px;
    border-radius:15px;
    background:${MainColor};
    margin:10px;
    @media ${device.mobile}{
        width:100%;
        display:block;
        height:50px;
        border-radius:5px;
        margin:10px;
    }    
`

export const Text = styled.p`
    font-size:15px;
    color:white;
    text-transform:uppercase;
    padding-left:5px;
    font-weight:600;
    margin-left:10px;
    @media (max-width:768px){
        font-size:12px;
        display:block;
        margin-left: 5px;
        padding-left: 8px;
        padding-top: 11px;
    }
    @media (min-width:769px) and (max-width:1024px){
        font-size:15px;              
    }
`

export const BoxFlex = styled.div`
    display:flex;
    justify-content:center;
    @media ${device.mobile}{
        width:100%;
        display:block;
    }
`