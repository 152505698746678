import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { NameLogin, PassLogin, TextLogin } from './style';
import { TextField } from '@material-ui/core';
import{useDispatch} from 'react-redux'
import { DISPATCH_LOGIN_TO_DATABASE_CONG_NGHE } from '../../../contant/ThietBiCongNghe';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Index({open,handleClose}) {
  const dispatch                 = useDispatch()
  const [nameLogin,setNameLogin] = useState("")
  const [passLogin,setPassLogin] = useState("")

  const [errName,setErrName] = useState(false)
  const [errPass,setErrPass] = useState(false)

  const Login = ()=>{
        if(nameLogin===""||nameLogin===null){
        setErrName(true)
        setTimeout(()=>{
            setErrName(false)
        },3000)
        return
    }
    if(passLogin===""||passLogin===null){
        setErrPass(true)
        setTimeout(()=>{
            setErrPass(false)
        },3000)
        return
    }
    let item = {
      nameLogin,
      passLogin
    }
    handleClose()
    dispatch({type:DISPATCH_LOGIN_TO_DATABASE_CONG_NGHE,payload:{item}})    
    return
  }
  
  return (
    <div>      
      <Dialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        ĐĂNG NHẬP HỆ THỐNG
        </DialogTitle>
        <DialogContent dividers>          
                <NameLogin>
                    <TextLogin>Tên đăng nhập:&nbsp;</TextLogin>
                    <TextField
                    error={errName}
                    onChange={e=>setNameLogin(e.target.value)}
                    type='text' variant="outlined" fullWidth size='small'
                    />
                </NameLogin>
                <PassLogin>
                    <TextLogin>Mật Khẩu:&nbsp;</TextLogin>
                    <TextField 
                    error={errPass}
                    onChange={e=>setPassLogin(e.target.value)}
                    type='password' variant="outlined" fullWidth size='small'/>
                </PassLogin>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={()=>Login()} color="primary">
            Đăng Nhập
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
