import {DISPATCH_GET_QRCODE_TO_STORE,UPDATE_SUCCESS,UPDATE_FAIL} from '../Contant/Person/qrcode'

export const DispatchGetQrcodeToStore =(item)=>{
    return{
      type:DISPATCH_GET_QRCODE_TO_STORE,
      payload:{item}
    }
}

export const UpdateSuccess=()=>{
  return{
    type:UPDATE_SUCCESS
  }
}

export const UpdateFail=()=>{
  return{
    type:UPDATE_FAIL
  }
}