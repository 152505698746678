import React,{useState,useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Button,Badge,Grid,Paper}from '@material-ui/core'
import {showMMYY} from '../../../helper/dateTime/handleDMY'
import {Replay} from '@material-ui/icons'
import {useStyles} from './styles'
import io from 'socket.io-client'
import {DISPATCH_LIST_CLASS_ACCOUNT_APPROVAL_BY_USER_ID_DATE,OPEN_CHECK_DAY_FOOD_CLASS,CLOSE_CHECK_DAY_FOOD_CLASS, CLOSE_ALERT_FORM_CHECK_DAY_FOOD,CLOSE_FORM_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE,CLOSE_ALERT_LIST_CLASS_NOT_CHECK_DAY_FOOD}from '../../contant/ManagerAccount'
import {DISPATCH_REQUEST_MANAGER_CLASS, 
}from '../../contant/ManagerAccount'
import {API_ENDPOINT} from '../../../contant'
import {useDispatch}from 'react-redux'
import ListApproval from './listApproval'
import FormInputDate from './formInputDate'
import AlertForm from '../../../helper/componentFn/alerFormCancel'
import AlertList from './alerFormCancel'
import ListCheckDay from './PrintCheckDayFood'

let socket
socket = io(API_ENDPOINT)

function Index(){
    const classes  = useStyles()
    const dispatch = useDispatch() 
    const OpenAlertCheckDayFood    = useSelector(state=>state.ManagerAccount.OpenAlertCheckDayFood)
    const OpenCheckDayFood         = useSelector(state=>state.ManagerAccount.OpenCheckDayFood)
    const OpenCheckDayFoodClass    = useSelector(state=>state.ManagerAccount.OpenCheckDayFoodClass)
    const DateInput                = useSelector(state=>state.ManagerAccount.DateInput)   

    const ItemManagerActiveAccount = useSelector(state=>state.ManagerAccount.ItemManagerActiveAccount)
    const ListApprovalCheckDayFood = useSelector(state=>state.ManagerAccount.ListApprovalCheckDayFood)
    const ListFalse                = ListApprovalCheckDayFood.filter(v=>v.status===false&&showMMYY(v.dateApproval)===showMMYY(DateInput))
    const [ShowApproval,setShowApproval] = useState(false)
    const ListClassNotCheckDayFood       = useSelector(state=>state.ManagerAccount.ListClassNotCheckDayFood) 
    
    const HandleApproval =()=>{      
      setShowApproval(!ShowApproval)
    } 
    useEffect(() => {
        if(ItemManagerActiveAccount!==undefined&&ItemManagerActiveAccount.length!==0){
          let item ={
            userId:ItemManagerActiveAccount[0].userId,
            dateNow:DateInput
          }
          dispatch({
            type:DISPATCH_LIST_CLASS_ACCOUNT_APPROVAL_BY_USER_ID_DATE,
            payload:{item}
          })         
        }
         socket.on('ManagerAccountRecicveRequest',data=>{
          dispatch({
            type:DISPATCH_REQUEST_MANAGER_CLASS,
            payload:{item:data.data}
          })          
        })
    }, [ItemManagerActiveAccount])

    const ReloadList=()=>{
      let item={
        userId:ItemManagerActiveAccount[0].userId,
        dateNow:DateInput
      }
      dispatch({
        type:DISPATCH_LIST_CLASS_ACCOUNT_APPROVAL_BY_USER_ID_DATE,
        payload:{item}
      })
    }

    const CheckDayFoodClass=()=>{
       dispatch({
         type:OPEN_CHECK_DAY_FOOD_CLASS
       })
    }
    const CloseCheckDayFoodClass=()=>{
      dispatch({
        type:CLOSE_CHECK_DAY_FOOD_CLASS
      })
    }

    //close Alert form 
    const CloseAlertForm =()=>{
      dispatch({
        type:CLOSE_ALERT_FORM_CHECK_DAY_FOOD
      })
    }
    //close Liost Check Day 
    const CloseListCheckDayFood=()=>{
      dispatch({
        type:CLOSE_FORM_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE
      })
    }

    // close List Class Check Day Food
    const CloseListClassCheckDayFood=()=>{
      dispatch({
        type:CLOSE_ALERT_LIST_CLASS_NOT_CHECK_DAY_FOOD
      })
    }  

    

    return(
      <>
        {/* close Alert Form */}
            {OpenAlertCheckDayFood&&<AlertForm
                  open={OpenAlertCheckDayFood}
                  handleClose={CloseAlertForm}
                  value={`Không có số liệu`}
            />}
        {/* close Alert Form */}
        {/* alert class not check day food */}
              {(ListClassNotCheckDayFood.open)&&<AlertList
                  open={ListClassNotCheckDayFood.open}
                  handleClose={CloseListClassCheckDayFood}
                  value={'Danh sách các lớp chưa chấm ăn'}
                  arrList={ListClassNotCheckDayFood.List}
              />}
        {/* alert class not check day food */}

        {/* list Check Day Food */}
              {OpenCheckDayFood&&<ListCheckDay
                  open={OpenCheckDayFood}
                  handleClose={CloseListCheckDayFood}
              />}
        {/* list Check Day Food */}
        
        {OpenCheckDayFoodClass&&<FormInputDate
              open={OpenCheckDayFoodClass}
              handleClose={CloseCheckDayFoodClass}
        />}
        {/* {ListFalse.length!==0&&<>         */}
          <Badge badgeContent={ListFalse.length} pb="2" color="primary">
            <Button variant="contained" color="secondary"size="small" onClick={()=>HandleApproval()}>Phê duyệt chấm ăn</Button> 
          </Badge>                  
        {/* </>}    */}
        &nbsp;&nbsp; <Button variant="contained" color="primary" size="small" onClick={()=>CheckDayFoodClass()}>Báo ăn các lớp</Button> &nbsp; &nbsp; <Replay color="secondary" className={classes.Point} onClick={()=>ReloadList()}/>      
          <Grid container spacing={2}>
            <Grid item xs ={12} sm={12}>
             <Paper className={classes.paper}>
               <ListApproval list={ListFalse}/> 
             </Paper>
           </Grid>
         </Grid>          
      </>
    )
}

export default React.memo(Index)