import { call, takeEvery,put, takeLatest } from 'redux-saga/effects'
import { DeleteCrmSuccess, DeleteItemCrm, DispatchAddCreateNewCrmToStore,DispatchGetListCrmToStore, UpdateItemCrmSuccess } from '../../actions/CRM'
import { CreateNewCrmApi, DeleteItemCrmApi, GetListCrmApi, UpdateCrmApi } from '../../api-services/CRM'
import { DISPATCH_CREATE_NEW_CRM, GET_LIST_CRM ,DELETE_ITEM_CRM, UPDATE_ITEM_CRM} from '../../contant/CRM'
import { UPDATE_ITEM_CREATE_ACCOUNT_NOT_SAVE } from '../../ManagerAccountActiveGov/contant/Class'

function* DispatchCreateNewCrmSaga({payload}){  
   let res = yield call(CreateNewCrmApi,payload.item)
   if(res.data.result==="success"){
       yield put(DispatchAddCreateNewCrmToStore(res.data.data))
   }
}

function* GetListCrmSaga(){
    let res = yield call(GetListCrmApi)
    if(res.data.result==="success"){
        yield put(DispatchGetListCrmToStore(res.data.data))
    }
}

function* DeleteItemCrmSaga({payload}){
    let res =  yield call(DeleteItemCrmApi,payload.item)
    if(res.data.result==="success"){
        yield put(DeleteCrmSuccess(payload.item))
    }
}

function* UpdateItemCrmSaga({payload}){
        let res = yield call(UpdateCrmApi,payload.item)
        console.log(res)
        if(res.data.result==="success"){
            yield put(UpdateItemCrmSuccess(payload.item))
        }
}

function* IndexSaga(){
    yield takeLatest(DISPATCH_CREATE_NEW_CRM,DispatchCreateNewCrmSaga)
    yield takeLatest(GET_LIST_CRM,GetListCrmSaga)
    yield takeLatest(DELETE_ITEM_CRM,DeleteItemCrmSaga)
    yield takeLatest(UPDATE_ITEM_CRM,UpdateItemCrmSaga)
}

export default IndexSaga