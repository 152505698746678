import React,{useState,useEffect} from 'react';
import {TableCell,TableRow, Checkbox, Button, Typography} from '@material-ui/core'
import {changeUpperCase} from '../../../helper/fnAllways'
import AlertForm from '../../../helper/componentFn/alertForm'
import { CLOSE_FORM_EMPTY_COUNT_EAT,OPEN_FORM_EMPTY_COUNT_EAT,ADD_APPROVAL_CHECK_DAY_FOOD, DISPATCH_CANCEL_UPDATE_COUNT_EAT,DISPATCH_CHANGE_UPDATE_CHECK_DAY_FOOD } from '../../contant/Class'
import {useSelector,useDispatch}from 'react-redux'
import {FindItemToCountEat,ItemApprovalCountEat,FindListStudentSaturday} from './helper'
import {RequestAcceptManagerAccount} from '../../component/ManagerAccount/socket/activeAccount'
import {ShowDateName,CheckStudentLeaveTemporaty}from '../ClassActive/helper'
import {OPEN_ALERT_LIST_STUDENT_NOT_REGIS,
  DISPATCH_LOGOUT_USER_CLASS}from '../../contant/Class'
import OpenFormAlert from '../../../helper/componentFn/alerFormSubmit'
import {showMMYY} from '../../../helper/dateTime/handleDMY'
import _ from 'lodash'

const Index=React.memo(({ListStudentNow,ExitSaveCountEat,selectAll,ListSeven,Tmp})=>{

  const Saturday                        = "Thứ bảy"
  const CountEat                        = useSelector(state => state.ManagerAccount.CountEat)
  const dispatch                        = useDispatch()
  const ListApprovalCheckDayFood        = useSelector(state=>state.ManagerAccount.ListApprovalCheckDayFood)
  const DateInput                       = useSelector(state=>state.ManagerAccount.DateInput)
  const SwitchUpdateOrAdd               = useSelector(state=>state.ManagerAccount.SwitchUpdateOrAdd)
  const InputDate                       = ShowDateName(DateInput).dateName
  const OpenAlertNotRegis               = useSelector(state=>state.ManagerAccount.OpenAlertNotRegis)
  const userIdCurrent                   = useSelector(state=>state.ManagerAccount.userIdCurrent)
  const OpenFormEmptyCountEat           = useSelector(state=>state.ManagerAccount.OpenFormEmptyCountEat)
  const ItemMenuWeekly                  = useSelector(state=>state.ManagerAccount.ItemMenuWeekly[0])
  const ResultListStudent               = useSelector(state=>state.ManagerAccount.ResultListStudent.ListStudentByMonth)
  const ResultItemToCountEat            = FindItemToCountEat(ResultListStudent,ItemMenuWeekly,DateInput)
  const isSelected = (id) => selected.indexOf(id) !== -1
  const [selected,setSelected]     = useState([])  
  const [valueRow,setValueRow]     = useState([])  
  //viet socket io
   useEffect(() => {
      if(selectAll===1){setSelected(ListStudentNow.map(v=>v.idStudent))}
      if(selectAll===0){setSelected([])}
  }, [selectAll])

   const handleSubmit=()=>{
      dispatch({
        type:DISPATCH_LOGOUT_USER_CLASS
      })
  }

    const CloseFormEmptyCountEat=()=>{
    dispatch({
        type:CLOSE_FORM_EMPTY_COUNT_EAT
    })
  }

  const handleSaveCountEat=()=>{
    if(_.last(ListStudentNow)===undefined){
      dispatch({
        type:OPEN_ALERT_LIST_STUDENT_NOT_REGIS
      })      
   }
    //kiem tra rong
    if(selected.length===0){
        dispatch({
          type:OPEN_FORM_EMPTY_COUNT_EAT
        })
    }
    if(selected.length!==0){
      let Item={
        ...ResultItemToCountEat,
        nameStudentsNotEat:selected
      }
      let item = ItemApprovalCountEat(Item,userIdCurrent)
      if(item.userId===undefined||item.idClass===undefined||item.idYearSchool===undefined||item.dateApproval===undefined){
        alert('Phiên Làm việc hết thời gian, vui lòng đăng nhập lại!')
        window.location.reload()        
      }
      dispatch({
        type:ADD_APPROVAL_CHECK_DAY_FOOD,
        payload:{item}
      })
      //socket phê duyệt
      RequestAcceptManagerAccount(item)
      ExitSaveCountEat()
    }
  }
  
  const SubmitWhenArrEmpty=()=>{   
        //gửi phê duyệt socket

       // let item = ItemApprovalCountEat(ResultItemToCountEat,userIdCurrent)
        let Item={
          ...ResultItemToCountEat,
          nameStudentsNotEat:selected
        }
      let item = ItemApprovalCountEat(Item,userIdCurrent)
      if(item.userId===undefined||item.idClass===undefined||item.idYearSchool===undefined||item.dateApproval===undefined){
        alert('Phiên Làm việc thời gian, vui lòng đăng nhập lại!')
        window.location.reload()        
      }
        dispatch({
          type:ADD_APPROVAL_CHECK_DAY_FOOD,
          payload:{item}
        })
        //socket phe duyet
        RequestAcceptManagerAccount(item)
        CloseFormEmptyCountEat()
        ExitSaveCountEat()  
  }
  const handleClick = (event, _id,Row) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = []
    let row = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
      row         = row.concat(valueRow, Row);

    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      row         = row.concat(valueRow.slice(1));

    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      row         = row.concat(valueRow.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      row = row.concat(
        valueRow.slice(0, selectedIndex),
        valueRow.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    setValueRow(row)
 }; 
  const ShowListStudent = ()=>{
    if(ListStudentNow!==undefined){
      return ListStudentNow.map((row,i)=>{
        let ItemTmpClass = Tmp.length!==0?Tmp[0].students:[]     
        const CheckExistStudent = CheckStudentLeaveTemporaty(ItemTmpClass,row)
        const isItemSelected  = isSelected(row.idStudent)       
        const labelId = `enhanced-table-checkbox-${i}`
      return(
        <TableRow key={i}>
          <TableCell>{i+1}</TableCell>
          <TableCell>{changeUpperCase(row.fullName)}{CheckExistStudent&&<Typography component="span" color="secondary">&nbsp;&nbsp;- - - (nghỉ tạm thời)</Typography>}</TableCell>
          {CountEat===1?<TableCell> 
                <Checkbox                            
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}          
                    onClick={(event) => handleClick(event, row.idStudent,row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}                            
                    selected={isItemSelected} 
                    disabled={CheckExistStudent}
                /></TableCell>:<></>}
        </TableRow>
      )
     })
    }
    return <></>
  }

  const ShowListStudentSeven=()=>{
      let findSeven = FindListStudentSaturday(ListStudentNow,ListSeven)
      if(findSeven!==undefined){
        return findSeven.map((row,i)=>{
          const isItemSelected  = isSelected(row.idStudent)       
          const labelId = `enhanced-table-checkbox-${i}`
        return(
          <TableRow key={i}>
            <TableCell>{i+1}</TableCell>
            <TableCell>{changeUpperCase(row.fullName)}</TableCell>
            {CountEat===1?<TableCell> 
                  <Checkbox                            
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}          
                      onClick={(event) => handleClick(event, row.idStudent,row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}                            
                      selected={isItemSelected} 
                  /></TableCell>:<></>}
          </TableRow>
        )
       })
      }
      return <></>
  }

  const CancelUpdateCountEat=()=>{
      dispatch({
        type:DISPATCH_CANCEL_UPDATE_COUNT_EAT
      })
      setSelected([])
  }
  const UpdateCheckDayFood=()=>{
      let item=[{
        ...ListApprovalCheckDayFood[0],
        checkDayFood:{
          ...ListApprovalCheckDayFood[0].checkDayFood,
          nameStudentsNotEat:selected
        },
        messageClass:'Đợi Phê Duyệt',
        messageManager:'',
        status:false,
        type:0
      }]
      //cap nhat lại co so du lieu
      dispatch({
        type:DISPATCH_CHANGE_UPDATE_CHECK_DAY_FOOD,
        payload:{item}
      })
      dispatch({
        type:DISPATCH_CANCEL_UPDATE_COUNT_EAT
      })
      RequestAcceptManagerAccount(item[0])      
  }
  const CancelCoutEat=()=>{
    ExitSaveCountEat()
    setSelected([])
  }
  return ( 
    <>    
        <tr>
          <td>
           {OpenFormEmptyCountEat&&<AlertForm
              open={OpenFormEmptyCountEat}
              handleClose={CloseFormEmptyCountEat}
              handleSubmit={SubmitWhenArrEmpty}
              value="Không có học sinh vắng, bạn có chắc"
          />}
            {OpenAlertNotRegis&&<OpenFormAlert
              open={OpenAlertNotRegis}
              value={`Danh sách học sinh tháng ${showMMYY(DateInput)} chưa khởi tạo, vui lòng liên hệ với bộ phận có liên quan...`}
              handleSubmit={handleSubmit}
          />}         
          </td>
        </tr>
          <>
            {CountEat===1?<TableRow><TableCell align="right" colSpan="3">

            {SwitchUpdateOrAdd===0? <><Button size="small" color="primary" variant="outlined" onClick={()=>handleSaveCountEat()}>Lưu Chấm Ăn</Button> &nbsp;<Button color="secondary" variant="outlined"
                    onClick={()=>CancelCoutEat()} size="small"
            >Hủy</Button> </>:
                                    <>
                                        <Button size="small" color="primary" variant="outlined" onClick={()=>UpdateCheckDayFood()}>Cập nhật chấm ăn</Button>&nbsp;&nbsp;
                                        <Button size="small" color="secondary" variant="outlined" onClick={()=>CancelUpdateCountEat()}>Hủy Cập nhật</Button>
                                    </>
            }                       
            </TableCell></TableRow>:<></>} 
            {InputDate!==Saturday?ShowListStudent():ShowListStudentSeven()}
          </>
    </>
  );

})

export default Index
