import {  makeStyles } from '@material-ui/core/styles';

export const useStyles =makeStyles(theme=>({ 
    printPaymentList:{
       marginLeft:'10px',
       marginRight:'10px'
   },   
   header:{
       marginTop:'-3px',
       fontSize:'20px',
       fontWeight:400
   },
   header2:{
       marginTop:'-5px',
       marginBottom:'5px'
   },
   sizeSmall:{
       fontSize:"13px",
       width:'35px'
   },
   fullName:{
       width:"90px"
   },
   PriceHead:{
       width:"50px"
   },
   SecondHead:{
       width:"30px"
   },
   num:{
       width:"10px"
   },
   nameClass:{
    width: '105px',
    fontSize: '11px'
   },
   borderNone:{
    borderStyle:"none",
    backgroundColor: '#fafafa00'
},
borderNoneTitle2:{
 borderStyle:"none",
 backgroundColor: '#fafafa00',
 marginTop:'-10px'
},
title2:{
 marginTop:"-12px",
 fontSize:"16px"
},
title1:{
 marginTop:"-12px",
 fontSize:"13px"
},
fontChuthich:{
    fontSize:'12px',
    marginTop:'-5px'
},
iconFab:{
    marginTop:'-10xp'
},
iconPrint:{
    margin:'auto',
    fontSize:'30px'
},
fullName:{
    width: '105px',
    fontSize: '10px'
   },
}))


