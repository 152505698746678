export const OPEN_ADD_TYPE_OF_SERVICE    =  "OPEN_ADD_TYPE_OF_SERVICE"
export const CLOSE_ADD_TYPE_OF_SERVICE   =  "CLOSE_ADD_TYPE_OF_SERVICE"
export const ADD_TYPE_OF_SERVICE         =  "ADD_TYPE_OF_SERVICE"
export const DISPATCH_VALUE_ADD_TO_SAGA  =  "DISPATCH_VALUE_ADD_TO_SAGA"
export const DISPATCH_VALUE_ADD_TO_STORE =  "DISPATCH_VALUE_ADD_TO_STORE"
export const DISPATCH_VALUE_TO_DB        = "DISPATCH_VALUE_TO_DB" 
export const DISPATCH_ADD_SUCCESS_TO_STORE_TYPE_OF_SERVICE = "DISPATCH_ADD_SUCCESS_TO_STORE_TYPE_OF_SERVICE"
export const DISPATCH_LIST_TYPE_OF_SERVICE_TO_STORE = "DISPATCH_LIST_TYPE_OF_SERVICE_TO_STORE"
export const GET_LIST_TYPE_OF_SERVICE    = "GET_LIST_TYPE_OF_SERVICE"
export const LIST_TYPE_OF_SERVICE   =  "LIST_TYPE_OF_SERVICE"
export const EDIT_TYPE_OF_SERVICE   =  "EDIT_TYPE_OF_SERVICE"
export const DELETE_TYPE_OF_SERVICE =  "DELETE_TYPE_OF_SERVICE"

export const OPEN_VIEW_TYPE_OF_SERVICE  = "OPEN_VIEW_TYPE_OF_SERVICE"
export const CLOSE_VIEW_TYPE_OF_SERVICE = "CLOSE_VIEW_TYPE_OF_SERVICE"


export const ADD_APP              = "ADD_APP"
export const LIST_APP             = "LIST_APP"
export const EDIT_APP             = "EDIT_APP"
export const DELETE_APP           = "DELETE_APP"