import React from 'react';
import { ContentUs,LogoImg,IMGCenter } from '../../style/MainCss/content_us';
import LogoGDT from '../../assest/img/huythanh.png'

const loading = () => {
    return (
        <ContentUs>                            
                <LogoImg >
                    <IMGCenter src={LogoGDT} alt="cong-dong-gioi-dinh-tue"/>
                </LogoImg>                            
        </ContentUs>
    );
};

export default loading;