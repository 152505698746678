import {put,takeEvery,call,select, takeLatest} from 'redux-saga/effects'
import {ADD_COST_STUDENT, GET_LIST,DELETE_COST_STUDENT,EDIT_COST_STUDENT} from '../../../contant/Gov/cost'
import {AddCostStudent,UpdateCostStudent,ListAllByUserId,DeleteCostStudent} from '../../../api-services/cost/costStudent'
import {loadingFail,loadingSuccess} from '../../../actions/uiLoading'
import {ListCostStudent,AddCostStudentFail,AddCostStudentSuccess
} from '../../../actions/Gov/cost/costStudent'
import { GET_ITEM_USER_INFO_BY_USER_ID } from '../../../contant/Gov/softFood'
import { GetItemUserInfoByUserIdApi } from '../../../api-services/student/student'
import { DispatchItemInfoByUserIdToStore } from '../../../actions/Gov/countEat'


function* addCostStudentSaga({payload}){
  yield put(loadingSuccess())
   let res = yield call(AddCostStudent,payload.itemAdd)   
   let item = res.data.data    
   if(res.data.result==='success'){
     yield put(AddCostStudentSuccess(item))       
   }else{
     yield put(AddCostStudentFail())
   }
   yield put(loadingFail())
}

function* listCostStudentSaga(){
    let userId = yield select(state=>state.GovLogin.userId)
    let id={id:userId}
    let res = yield call(ListAllByUserId,id)
    yield put(ListCostStudent(res.data.data))
}

function* editCostStudentSaga({payload}){
    //yield delay(100)    
    yield call(UpdateCostStudent,payload.item)    
}
function* deleteCostStudentSaga({payload}){
   // yield delay(100)
    yield call(DeleteCostStudent,payload.item)   
}

function* GetItemUserInfoByUserIdSaga({payload}){
  let res = yield call(GetItemUserInfoByUserIdApi,payload)
  if(res.data.result==='success'){
      yield put(DispatchItemInfoByUserIdToStore(res.data.data))
  }
}



function* costStudentSaga(){
  yield takeLatest(ADD_COST_STUDENT,addCostStudentSaga)
  yield takeLatest(GET_LIST,listCostStudentSaga)
  yield takeLatest(EDIT_COST_STUDENT,editCostStudentSaga)
  yield takeLatest(DELETE_COST_STUDENT,deleteCostStudentSaga)
  yield takeLatest(GET_ITEM_USER_INFO_BY_USER_ID,GetItemUserInfoByUserIdSaga)
  
}

export default costStudentSaga