import {  showYYYY } from "../../../../helper/dateTime/handleDMY"

export const HandleNewArrCanDo=(arr,userId,TimeCanDo)=>{
    let cha =`${TimeCanDo.valueMonth}`.length    
    return {
        monthCanDo:cha===1?`0${TimeCanDo.valueMonth}/${TimeCanDo.valueYear}`:`${TimeCanDo.valueMonth}/${TimeCanDo.valueYear}`,
        idClass:arr[0].idClass,
        listHocSinh:arr,
        userId,
        
    }
}

export const HandleFilterListHocSinhCanDo=(arr,item)=>{
   let prvYear =  +item.monthCanDo -1 
   let nextYear = +item.monthCanDo + 1
    return arr.filter(v=>showYYYY(`01/${v.monthCanDo}`)===item.monthCanDo
    ||showYYYY(`01/${v.monthCanDo}`)===`${prvYear}`
    ||showYYYY(`01/${v.monthCanDo}`)===`${nextYear}`
    )    

}