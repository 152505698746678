export const DISPATCH_ITEM_ADD_APP_ACTIVE_STORE = "DISPATCH_ITEM_ADD_APP_ACTIVE_STORE"
export const DISPATCH_LIST_APP_ACTIVE_TO_STORE  = "DISPATCH_LIST_APP_ACTIVE_TO_STORE"
export const DISPATCH_UPDATE_ITEM_APP_ACTIVE    = "DISPATCH_UPDATE_ITEM_APP_ACTIVE"

export const GET_APP_ACTIVE_BY_USERID               = "GET_APP_ACTIVE_BY_USERID" 
export const DISPATCH_APP_ACTIVE_BY_USER_WHEN_LOGIN = "DISPATCH_APP_ACTIVE_BY_USER_WHEN_LOGIN"

export const OPEN_BLOCK_APP_ACTIVE_MANAGER_REPO  = "OPEN_BLOCK_APP_ACTIVE_MANAGER_REPO"
export const CLOSE_BLOCK_APP_ACTIVE_MANAGER_REPO = "CLOSE_BLOCK_APP_ACTIVE_MANAGER_REPO"

export const OPEN_SELECT_APP_ACTIVE = "OPEN_SELECT_APP_ACTIVE"
export const CLOSE_SELECT_APP_ACTIVE = "CLOSE_SELECT_APP_ACTIVE"
export const EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_REPO = "EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_REPO"

export const OPEN_BLOCK_APP_ACTIVE_MANAGER_STAFF    = "OPEN_BLOCK_APP_ACTIVE_MANAGER_STAFF"
export const CLOSE_BLOCK_APP_ACTIVE_MANAGER_STAFF   = "CLOSE_BLOCK_APP_ACTIVE_MANAGER_STAFF"
export const EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_STAFF = "EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_STAFF"


export const OPEN_BLOCK_APP_ACTIVE_MANAGER_SELL    = "OPEN_BLOCK_APP_ACTIVE_MANAGER_SELL"
export const CLOSE_BLOCK_APP_ACTIVE_MANAGER_SELL   = "CLOSE_BLOCK_APP_ACTIVE_MANAGER_SELL"
export const EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_SELL = "EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_SELL"


export const OPEN_BLOCK_APP_ACTIVE_MANAGER_DIFF    = "OPEN_BLOCK_APP_ACTIVE_MANAGER_DIFF"
export const CLOSE_BLOCK_APP_ACTIVE_MANAGER_DIFF   = "CLOSE_BLOCK_APP_ACTIVE_MANAGER_DIFF"
export const EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_DIFF = "EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_DIFF"

