import React from 'react'
import {useSelector} from 'react-redux'
import ModalBox from '../ModalBox/index'
import MainTab from '../MainTab/index'
import {Helmet} from 'react-helmet-async';


const Index =React.memo(() => {
  const GovLogin = useSelector(state=>state.GovLogin.GovLogin)
  let {activeSoftFood} = GovLogin  
  return (
   <>
      <Helmet>
                    <title>Phần Mềm Dinh Dưỡng KidTop</title>
                    <meta
                        name="description"
                        content="Phần Mềm Dinh Dưỡng"
                        keywords="Phần Mềm Dinh Dưỡng KidTop"
                        charSet="utf-8"
                    />
                    <link rel="canonical" href="http://huythanhicare.com"/>
       </Helmet>
     {activeSoftFood===false &&(<ModalBox/>)}    
     <MainTab/>     
   </>
  )
})

export default Index