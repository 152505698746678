export const ListIdeaCustomer= [
    {
        nameSchool:'SG Montessori',
        name:'SGM',
        content:`Mức độ tính toán và kiểm tra sai xót trong nhà ăn rất nhanh chóng.
            Chúng tôi phải cần nhiều người để xử lý nhà ăn, nhưng nay chỉ cần 1-2 người có thể làm nhanh chóng, ai cũng có thể làm được.
            các yêu cầu khi kiểm tra sổ sách báo cáo đầy đủ. Mọi thứ đã đơn giản và nhanh chóng khi sử dụng phần mềm này!
        `,
        color:'purple'
    },
    {
        nameSchool:'BAY',
        name:'MNB',
        content:`
            Có phần mềm này, trường chúng tôi tự tin hơn trong quá trình kiểm tra sổ sách báo cáo, mọi thứ
            đã trở nên đơn giản hơn! Đội ngũ hỗ trợ phần mềm làm việc rất nhiệt tình. Cá nhân tôi nghĩ các trường học nên sử dụng thử phần mềm, có rất nhiều tính năng mà các phần mềm ngoài thị trường không có.
        `,
        color:'orange'
    },
    {
        nameSchool:'KinderWorld HN',
        name:'KWH',
        content:`
            Phần tính nutrikid và kiểm thực ba bước bây giờ đã đơn giản và không phải mất thời gian và công sức nhiều hơn trước.
            Tốc độ phần mềm sử lý nhanh, kiểm soát được nhà ăn! 
        `,
        color:'purple'
    },
    {
        nameSchool:'Blue Sky SG',
        name:'BS',
        content:`
            Tôi không nghĩ rằng tính nutrikid có thể đơn giản đến như vậy! Nhanh chóng, tìm ra lỗi và xử lý lỗi rất nhanh.
            Ra hồ sơ kiểm thực tự động. Điều này giúp tôi chủ động trong công việc nhiều hơn!
        `,
        color:'orange'
    },
    {
        nameSchool:'HN LAETA',
        name:'MNL',
        content:`Phần mềm có nhiều ưu điểm hơn so với các phần mềm hiện có trên thị trường.
                Ra hồ sơ nhanh chóng. Không cần chuyên môn vẫn có thể sử dụng được.
        `,
        color:'green'
    },
    {
        nameSchool:'HN ELEPHANTA',
        name:'HE',
        content:`Phần mềm này có sử dụng công nghệ mới, nên thấy làm việc nhẹ và đơn giản hơn các phần mềm trước đó.
        Người không có chuyên môn vẫn có thể sử dụng.
        `,
        color:'blue'
    },
]