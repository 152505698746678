import axiosServices from '../api'
import {API_ENDPOINT} from '../../contant/index'

const urlAddMaterial         = 'costMaterial/material'
const urlListAllMaterial     = 'costMaterial/material'
const urlAddTypeMaterial     = 'costMaterial/typeMaterial'
const urlListAllTypeMaterial = 'costMaterial/typeMaterial'
const urlEditMaterial        = 'costMaterial/material'
const urlDeleteMaterial      = 'costMaterial/material'
const urlEditTypeMaterial    =  'costMaterial/typeMaterial'
const urlDeleteTypeMaterial  =  'costMaterial/typeMaterial'

const urlAddMaterialMealDate      = 'costMaterial/materialMealDate'
const urlAddManyMaterialMealDate  = 'costMaterial/materialMealDateMany'

const urlAllAddMaterialMealDate   = 'costMaterial/materialMealDateList'
const urlEditMaterialMealDate     = 'costMaterial/materialMealDate'
const urlDeleteMaterialMealDate   = 'costMaterial/materialMealDate'
const urlDeleteManyMaterialMealDate = 'costMaterial/materialMealDateMany'

const urlAddSumLessMore           = 'costMaterial/materialMealDateSumLessMore'
const urlAllSumLessMore           = 'costMaterial/materialMealDateListSumLessMore'
const urlEditSumLessMore          = 'costMaterial/materialMealDateSumLessMore'
const urlAddPhanBoNguyenLieuNutriTinhAn="AddPhanBoNguyenLieuNutriTinhAn"
const urlUpdatePhanBoNguyenLieuTinhAn  = "UpdatePhanBoNguyenLieuTinhAn"

export const AddMaterial = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddMaterial}`,item)
}
export const ListAllMaterial = ()=>{
  return axiosServices.get(`${API_ENDPOINT}/${urlListAllMaterial}`)
}
export const AddTypeMaterial = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddTypeMaterial}`,item)    
}
export const ListAllTypeMaterial=()=>{
  return axiosServices.get(`${API_ENDPOINT}/${urlListAllTypeMaterial}`)
}
export const EditMaterial = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlEditMaterial}`,item)
}
export const DeleteMaterial = (item)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteMaterial}`,item)
}
export const EditTypeMaterial = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlEditTypeMaterial}`,item)
}

export const DeleteTypeMaterial = (item)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteTypeMaterial}`,item)
}
//materialMealDate

export const AddMaterialMealDate = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddMaterialMealDate}`,item)
}

export const AddManyMaterialMealDateApi = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddManyMaterialMealDate}`,item)
}
export const ListAllMaterialMealDate = (id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAllAddMaterialMealDate}`,id)
}
export const EditMaterialMealDate = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlEditMaterialMealDate}`,item)
}
export const DeleteMaterialMealDate = (item)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteMaterialMealDate}`,item)
}
export const DeleteManyMaterialMealDate = (item)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteManyMaterialMealDate}`,item)
}
//SUM LESS MORE


export const AddSumLessMore = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddSumLessMore}`,item)
}
export const ListAllSumLessMore = (id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAllSumLessMore}`,id)
}
export const EditSumLessMore = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlEditSumLessMore}`,item)
}

export const AddPhanBoNguyenLieuNutriTinhAnApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddPhanBoNguyenLieuNutriTinhAn}`,item)
}

export const UpdatePhanBoNguyenLieuTinhAnApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlUpdatePhanBoNguyenLieuTinhAn}`,item)
}