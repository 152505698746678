import * as TypeForm from '../contant/taskForm'

export const showFormTask =()=>{
  return{
    type:TypeForm.SHOW_FORM_TASK
  }
}

export const hideFormTask = ()=>{
  return{
    type:TypeForm.HIDE_FORM_TASK
  }
}

export const changeFormTitle = (title)=>{
  return {
    type:TypeForm.CHANGE_FORM_TITLE,
    payload: title    
  }
}

export const changeFormContent = (component) =>{
  return {
    type:TypeForm.CHANGE_FORM_CONTENT,
    payload: component    
  }
}