import { CLOSE_FORM_LOGIN_REGIS, OPEN_FORM_LOGIN_REGIS } from '../contant/ThietBiCongNghe';

let initialState={
   OpenFormLoginRegis:false
}

const IndexReducer =(state=initialState,action)=>{
            switch (action.type) {
                case OPEN_FORM_LOGIN_REGIS:
                    return{
                        OpenFormLoginRegis:true
                    }
                case CLOSE_FORM_LOGIN_REGIS:
                    return{
                        OpenFormLoginRegis:false
                    }                
                default: return state;
            }
}

export default IndexReducer