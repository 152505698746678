import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useDispatch,useSelector} from 'react-redux'
import DoiTacComponent from './DoiTac'
import ThiTruongComponent from './ThiTruong'
import ChamSocComponent from './ChamSoc'
import CtvComponent from './Ctv'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({open,handleClose}) {
    const ValueTuyenDung   = useSelector(state=>state.MainWeb.ValueTuyenDung)
    const [value,setValue] = useState(null)

    useEffect(()=>{
        if(ValueTuyenDung.value==="DOI_TAC"){
            setValue('Tìm Kiếm Đối Tác')
        }
        if(ValueTuyenDung.value==="THI_TRUONG"){
            setValue('Phát Triển Thị Trường')
        }
        if(ValueTuyenDung.value==="CHAM_SOC"){
            setValue('Tư Vấn Chăm Sóc Khách Hàng')
        }
        if(ValueTuyenDung.value==="CTV"){
            setValue('Làm Cộng Tác Viên')
        }
    },[ValueTuyenDung])   

  return (
    <div>     
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle style={{color:'green'}} id="customized-dialog-title" >
            Tuyển Dụng - {value!==null&&value}
        </DialogTitle>
        {value==='Tìm Kiếm Đối Tác'&&<DoiTacComponent/>}
        {value==='Phát Triển Thị Trường'&&<ThiTruongComponent/>}
        {value==='Tư Vấn Chăm Sóc Khách Hàng'&&<ChamSocComponent/>}
        {value==='Làm Cộng Tác Viên'&&<CtvComponent/>}              
      </Dialog>
    </div>
  );
}
