import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { LightBox } from 'react-lightbox-pack';
import "react-lightbox-pack/dist/index.css";
import Sei1 from './ImgSeiAward/Cup1.jpg'
import Sei2 from './ImgSeiAward/Cup2.jpg'
import Sei3 from './ImgSeiAward/Cup3.png'
import Sei4 from './ImgSeiAward/Cup4.png'
import { VinhDanhTitle, VinhDanhTitleMobile } from './styleRibbon';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);



export default function CustomizedDialogs({open,handleClose}) {

    const [toggle, setToggle] =  useState(false);
	const [sIndex, setSIndex] =  useState(0);

    const lightBoxHandler  = (state, sIndex) => {
            setToggle(state);
            setSIndex(sIndex);
    };

    const images= [
        {image:Sei4},
        {image:Sei2},
        {image:Sei3},
        {image:Sei1},
      ] 
    

  return (
    <div>     
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>        
         <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <VinhDanhTitle style={{color:'green'}}>
           ĐẠT DANH HIỆU SÁNG KIẾN GIÁO DỤC THÔNG MINH 2023
        </VinhDanhTitle> 
        <VinhDanhTitleMobile style={{color:'green'}}>
            SEI Awards Edutech 2023    
        </VinhDanhTitleMobile>  
        </DialogTitle>
        <DialogContent>
        {images.map((item, index) => (
			<>
				<img
					key={index}
					src={item.image}
					//alt={item.title}
					style={{ maxHeight:  "50vh", maxWidth:  "50vw" }}
					onClick={() => {
					lightBoxHandler(true, index);
					}}
				/>
			</>
			))}			
			<LightBox
				state={toggle}
                event={lightBoxHandler}
                data={images}
                imageWidth="60vw"
                imageHeight="70vh"
                thumbnailHeight={50}
                thumbnailWidth={50}
                setImageIndex={setSIndex}
                imageIndex={sIndex}
			/>    
        </DialogContent>        
      </Dialog>
    </div>
  );
}
