import React, { Suspense } from 'react';
import {Grid,DialogContent,Dialog,Box} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import {useSelector} from 'react-redux'
import styled from 'styled-components';
import SelectCountEat from './SelectCountEat/index'
import TableList from '../SettingAdvance/TableEdit/Cost/index'


const StyledDialog = styled(Dialog)`
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  max-width:600px;
}
`
const StyledDialogContent = styled(DialogContent)`
  .MuiDialogContent-root { 
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 15px 65px;
  } 
`
const Index =React.memo(({open,handleClose})=> {  
  const ShowEdit = useSelector(state=>state.GovLogin.ShowEdit)
  const ShowSettingAdvance =()=>{
    return(
      <>
          <Box textAlign="center">    
                 <Alert variant="filled" severity="info">Cài đặt nâng cao </Alert>        
             </Box>      
             <>       
                      <StyledDialogContent>                   
                            <Grid container> 
                                {ShowEdit===true?(
                                   <TableList/>
                                ):( 
                                <Grid item md={12}>
                                             <SelectCountEat/>
                                  </Grid>  
                                  )}                             
                                                                                                               
                             </Grid>                     
                      </StyledDialogContent>
              </>     
      </>
    )
  }
  return (    
      <Suspense fallback={()=>{}}>  
        <StyledDialog open={open} onClose={handleClose}>                    
                    {ShowSettingAdvance()}
        </StyledDialog>
      </Suspense>
  );
})

export default Index