export const DeleteManyItemById=(arr,item)=>{
    let result = arr.map(v=>{
        let index = item.findIndex(vv=>vv._id===v._id)
        if(index<0){
            return{
                ...v
            }
        }
        if(index>=0){
            return   undefined
            
        }
    })
   return result.filter(v=>v!==undefined)
   
}