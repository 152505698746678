export const GET_INFO_CUS_NHAN_VIEN = "GET_INFO_CUS_NHAN_VIEN"
export const DISPATCH_GET_INFO_CUS_NHAN_VIEN_TO_STORE = "DISPATCH_GET_INFO_CUS_NHAN_VIEN_TO_STORE"

export const ADD_JOB_STAFF_NHAN_VIEN = "ADD_JOB_STAFF_NHAN_VIEN"
export const DISPATCH_ADD_JOB_STAFF_NHAN_VIEN_STORE = "DISPATCH_ADD_JOB_STAFF_NHAN_VIEN_STORE"

export const SEARCHING_GET_JOB_STAFF_NHAN_VIEN = "SEARCHING_GET_JOB_STAFF_NHAN_VIEN"
export const DISPATCH_SEARCHING_GET_JOB_STAFF_NHAN_VIEN = "DISPATCH_SEARCHING_GET_JOB_STAFF_NHAN_VIEN"

export const UPDATE_JOB_STAFF_NHAN_VIEN = "UPDATE_JOB_STAFF_NHAN_VIEN"
export const UPDATE_JOB_STAFF_NHAN_VIEN_STORE = "UPDATE_JOB_STAFF_NHAN_VIEN_STORE"
export const DELETE_JOB_STAFF_NHAN_VIEN = "DELETE_JOB_STAFF_NHAN_VIEN"

export const DISPATCH_JOB_STAFF_NHAN_VIEN_VAT_TU = "DISPATCH_JOB_STAFF_NHAN_VIEN_VAT_TU"
export const UPDATE_JOB_STAFF_NHAN_VIEN_VAT_TU   = "UPDATE_JOB_STAFF_NHAN_VIEN_VAT_TU"
export const UPDATE_JOB_STAFF_NHAN_VIEN_VAT_TU_DB  = "UPDATE_JOB_STAFF_NHAN_VIEN_VAT_TU_DB"
export const DELETE_JOB_STAFF_NHAN_VIEN_VAT_TU  = "DELETE_JOB_STAFF_NHAN_VIEN_VAT_TU"
export const DELETE_JOB_STAFF_NHAN_VIEN_VAT_TU_DB  = "DELETE_JOB_STAFF_NHAN_VIEN_VAT_TU_DB"

export const ADD_JOB_STAFF_NHAN_VIEN_VAT_TU   = "ADD_JOB_STAFF_NHAN_VIEN_VAT_TU"
export const SEARCHING_GET_JOB_STAFF_NHAN_VIEN__ = "SEARCHING_GET_JOB_STAFF_NHAN_VIEN__"
export const APPROVE_JOB_STAFF_TRUE  = "APPROVE_JOB_STAFF_TRUE"
export const APPROVE_JOB_STAFF_FALSE = "APPROVE_JOB_STAFF_FALSE"
export const UPDATE_JOB_STAFF_CONG_LAO_DONG = "UPDATE_JOB_STAFF_CONG_LAO_DONG"