import axiosServices from '../api'
import {API_ENDPOINT} from '../../contant/index'

const urlAddCostStudent = 'costStudent'
const urlListAllByUserId = 'costStudent/userId'
const urlUpdateCostStudent = 'costStudent'
const urlDeleteCostStudent = 'costStudent'


export const AddCostStudent = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddCostStudent}`,item)
}

export const ListAllByUserId = (id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListAllByUserId}`,id)
}

export const UpdateCostStudent = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateCostStudent}`,item)
}

export const DeleteCostStudent =(item)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteCostStudent}`,item)
}