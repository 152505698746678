import React from 'react'
import Person from './Person'
import {compose} from 'redux'


const Index =()=>{   
  return (
    <>
       <Person/>
   </>   
  )
}

export default compose(
)(Index)