import React from 'react';
import { BoxFlex, ContentList, HeadingText, ItemList, MainUuViet,Text } from './style';
import Line from '../../FnComponent/Line';

const Index = React.memo(() => {
    return (
        <MainUuViet>
            <HeadingText>
               TÍNH NĂNG ƯU VIỆT
            </HeadingText>
            <Line/>
            <ContentList>
                <BoxFlex >
                    <ItemList>
                        <Text>
                            Hơn 1000 Thực Đơn Mẫu Nutrikid
                        </Text>
                    </ItemList>
                    <ItemList>
                         <Text>
                         Tính Nutrikid đơn giản, bất kỳ ai cũng có thể làm.
                         </Text>   
                    </ItemList> 
                </BoxFlex>
                <BoxFlex >
                    <ItemList>
                         <Text>
                              Tạo sổ báo cáo nutrikid, kiểm thực tự động, sổ thu chi...
                         </Text>   
                    </ItemList>
                    <ItemList>
                         <Text>
                            Tạo nhiều tài khoản cùng truy cập để báo ăn ...(báo ăn điện tử)
                         </Text>   
                    </ItemList> 
                </BoxFlex>
                <BoxFlex >
                    <ItemList>
                         <Text>   
                            Lưu trữ dữ liệu không giới hạn 36 tháng.
                         </Text>  
                    </ItemList>
                    <ItemList>
                         <Text>
                            Kiểm soát thu chi online - tuỳ chỉnh các khoản thu chi - kết xuất file...
                         </Text>  
                    </ItemList> 
                </BoxFlex>
                <BoxFlex >
                    <ItemList>
                          <Text>
                                Quản lý luỹ kế phiếu ăn, luỹ kế nhập xuất kho trong năm học...
                         </Text> 
                    </ItemList>
                    <ItemList>
                        <Text>
                               Tạo website tự động cho trường của bạn
                         </Text>  
                    </ItemList>                    
                </BoxFlex>       

                <BoxFlex >                    
                    <ItemList>
                        <Text>
                               Quản lý cân đo, tạo sổ cân đo tự động
                         </Text>  
                    </ItemList> 
                    <ItemList>
                         <Text>
                               Phần mềm đạt danh hiệu "ẢNH HƯỞNG GIÁO DỤC CỦA NĂM 2023"
                         </Text>  
                    </ItemList> 
                </BoxFlex>              
            </ContentList>
        </MainUuViet>
    );
});

export default Index;