import {  makeStyles } from '@material-ui/core/styles';

export const useStyles =makeStyles(theme=>({ 
    InfoCus:{
      background:"#ecf0f1"
    },
    textHead:{
      color:"#3498db"
    },
    root: {
      maxWidth: 450,
      background:"#ecf0f1",
      marginLeft:"3px",
      marginRight:"5px"
    },
    itemActive:{
      paddingLeft:"-10px"
    },
    alertText:{
      color:'red'
    }
}))