import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DANG_KY_PM from '../../../../../../../NotFoundPage/component/GioiThieu/DANG_KY_PM';

const Index =React.memo(({open,handleClose})=> {
  return (
    <div>   
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DANG_KY_PM/>       
        <DialogActions>
            <Button variant='contained' onClick={()=>handleClose()} color="secondary">
                Thoát
            </Button>          
        </DialogActions>
      </Dialog>
    </div>
  );
})

export default Index
