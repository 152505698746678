import * as TypeGov from  '../../../contant/Gov/softFood'
import {DISPATCH_LIST_ALL_DATE_MEAL,DISPATCH_ADD_DATE_MEAL_TO_STORE} from '../../../contant/Gov/student/dispatch'

export const GetDateInput = (DateInput)=>{
  return {
    type:TypeGov.GET_DATE_INPUT,
    payload:{DateInput}
  }
}

export const ListDateMeal = (list)=>{
  return {
    type:TypeGov.LIST_DATE_MEAL,
    payload:{list}
  }
}
export const addDateMealDbSuccess = ()=>{
  return {
    type:TypeGov.ADD_DATE_MEAL_DB_SUCCESS      
  }
}
export const addDateMealDbFail =()=>{
  return {
    type:TypeGov.ADD_DATE_MEAL_DB_FAIL   
  }
}

export const GetListDateMealByUserId=(data)=>{
    return {
      type:TypeGov.LIST_DATE_MEAL_BY_USERID,
      payload:{data}      
    }
}
export const listDateMealMonthTable =(value)=>{
  return{
    type:TypeGov.LIST_DATE_MEAL_MONTH_TABLE,
    payload:{value}
  }
}

export const insertDateMealDb = (value,data)=>{
  return{
    type:TypeGov.INSERT_DATE_MEAL_DB,
    payload:{value,data}
  }
}

export const addDateMealDB = data=>{
  return{
    type:TypeGov.ADD_DATE_MEAL_DB,
    payload:{data}
  }
}
export const dispatchListAllDateMeal = (arr)=>{
  return{
    type:DISPATCH_LIST_ALL_DATE_MEAL,
    payload:{arr}
  }
}
export const dispatchAddDateMealToStore =(item)=>{
  return{
    type:DISPATCH_ADD_DATE_MEAL_TO_STORE,
    payload:{item}
  }
}



