import React from 'react'
import {Select,FormHelperText,FormControl,InputLabel} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import style from './style'

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return null
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
}

const renderSelectField = ({
  classes,
  input,
  label,  
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl error={touched && error} className={classes.inputSelectStatus}>
    <InputLabel htmlFor="input-select">{label}</InputLabel>
    <Select
      native
      {...input}
      {...custom}      
      inputProps={{
        name: 'input',
        id: 'input-select'
      }}
    >
      {children}
    </Select>
    {renderFromHelper({ touched, error })}
  </FormControl>
)

export default withStyles(style)(renderSelectField)