import React from 'react';
import {Dialog,DialogActions,DialogContent,Table,TableHead,TableCell,TableRow,TableContainer,Paper,TableBody} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import {useStyles} from './styles'
import {changeUpperCase} from '../../../helper/fnAllways'

const Index=React.memo(({open,handleClose,listStu})=>{  
  const classes = useStyles()   
  const showList=()=>{
    if(listStu!==undefined){
      return listStu.map((v,i)=>{
      return(
        <TableRow key={i}>
            <TableCell>{i+1}</TableCell>
            <TableCell>{changeUpperCase(v.fullName)}</TableCell>
        </TableRow>
      )
    })
    }    
    return <></>
  }
  return (
    <div>   
      <Dialog open={open} onClose={handleClose} >
        <Alert severity="success">Báo Vắng</Alert>
        <DialogContent> 
        <TableContainer component={Paper}>    
            <Table className={classes.table} stickyHeader aria-label="sticky table" size="small" >
              <TableHead>
                <TableRow>
                <TableCell align="left">Stt</TableCell>                                    
                  <TableCell align="left">Tên Học Sinh </TableCell>                  
                </TableRow>
              </TableHead>
              <TableBody>       
                      {showList()}
              </TableBody>
            </Table>
        </TableContainer>
        </DialogContent>
        <DialogActions>        
        </DialogActions>
      </Dialog>
    </div>
  );
})

export default Index
