import * as  TypeUsers from '../contant/users'
import {toastSuccess,toastError} from '../helper/toastHelper'
var initialState = {
  userList:[],
  loginUser:{},
  OpenResetPass:false,  
}

const userReducer = (state= initialState,action)=>{
  switch (action.type) {
        case TypeUsers.FETCH_LIST_USERS:
            return{
              ...state,
              userList:[]
            }

        case  TypeUsers.SHOW_LIST_USERS:
          const {data} = action.payload             
          return {
            ...state,
            userList:data
          }

        case TypeUsers.SAVE_USER_LOGIN_INFO:
          let {user} = action.payload
          return{
              ...state,
              loginUser: user           
          }
        case TypeUsers.RESET_PASSWORD_ADMIN_SUCCESS:
          toastSuccess("Reset Mật khẩu thành công")
          return{...state}
        case TypeUsers.OPEN_RESET_PASSWORD_ADMIN:
          return{
            ...state,
            OpenResetPass:true
          }
        case TypeUsers.CLOSE_RESET_PASSWORD_ADMIN:
          return{
            ...state,
            OpenResetPass:false
          }
            
          case TypeUsers.OPEN_RESET_PASSWORD_ADMIN:
            return{
              ...state,
              OpenResetPass:false,              
            }
          case TypeUsers.ACCOUNT_IS_NOT_DEFINE:
            toastError("Kiểm tra lại tài khoản, email. Không Tồn tại")
            return{
              ...state
            }
          case TypeUsers.RESET_ACCOUNT_SUCCESS:
            toastSuccess("Tài khoản đã được khôi phục, Vui lòng truy cập email để kích hoạt tài khoản!")
            return{
              ...state
            }
          case TypeUsers.ALERT_RESET_ACCOUNT_SUCCESS:
            toastSuccess(`Tài khoản "${action.payload.name}" đã được khôi phục thành công`)
            return{
              ...state
            }
            case TypeUsers.ALERT_RESET_ACCOUNT_FAIL:
              toastError(`Link truy cập đã hết hạn, vui lòng kiểm tra lại`)
              return{
                ...state
              }
            case TypeUsers.ALERT_VERIFY_PAYMENT_ACCOUNT_TO_STORE:
              if(action.payload.item===false){
                  toastError('Quá trình xác minh này đã được tạo, không thể xác minh!')
                  return{
                    ...state
                  }
              }
              if(action.payload.item!==false){
                let {workPlace} = action.payload.item
                toastSuccess(`Tài Khoản Khách Hàng : ${workPlace} đã được gia hạn thành công`)
                alert(`${workPlace} Thông báo gia hạn thành công`)
                return{
                  ...state
                }
              }
              
              
    default:return state    
  }
}

export default userReducer
