import {LIST_ALL_MATERIAL,LIST_ALL_CATEGORY_MATERIAL,
   ADD_MATERIAL_STORE,LIST_ALL_CATEGORY_TYPE_MATERIAL,
  ADD_TYPE_MATERIAL_STORE,ADD_TYPE_MATERIAL_SUCCESS  
  } from '../../../contant/Gov/cost'
  import {DISPATCH_LIST_ALL_MATERIAL_TO_STORE,DISPATCH_ADD_MATERIAL_MEAL_DATE_TO_STORE,
  DISPATCH_LIST_SUM_LESS_MORE_TO_STORE,DISPATCH_ADD_SUM_LESS_MORE_TO_STORE,DISPATCH_ITEM_ADD_SUPPLIES_TO_STORE,
  DISPATCH_LIST_SUPPLIES_STORE,DISPATCH_ADD_REPOSITORY_TO_STORE,DISPATCH_LIST_REPOSITORY_TO_STORE,
  DISPATCH_ADD_IMPORT_TO_STORE,DISPATCH_LIST_TYPE_MATERIAL_TO_STORE,DISPATCH_ITEM_ADD_BALANCE_TO_STORE,
  DISPATCH_LIST_BALANCE_TO_STORE,
  DISPATCH_ADD_MANY_MATERIAL_MEAL_DATE_TO_STORE,DISPATCH_ITEM_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN
  } from '../../../contant/Gov/countEat/dispatch'


export const listAllMaterial = (arr)=>{
  return {
    type:LIST_ALL_MATERIAL,
    payload:{arr}
  }
}

export const addTypeMaterialSuccess = ()=>{
  return {
    type:ADD_TYPE_MATERIAL_SUCCESS    
  }
}



export const listAllCategoryMaterial = (arr)=>{
  return{
    type:LIST_ALL_CATEGORY_MATERIAL,
    payload:{arr}
  }
}
export const AddMaterialStore = (item)=>{
  return{
    type:ADD_MATERIAL_STORE,
    payload:{item}
  }
}
export const ListAllCategoryTypeMaterial = (arr)=>{
  return{
    type:LIST_ALL_CATEGORY_TYPE_MATERIAL,
    payload:{arr}
  }
}
export const AddTypeMaterialStore = (item)=>{
  return{
    type:ADD_TYPE_MATERIAL_STORE,
    payload:{item}
  }
}

export const dispatchListAllMaterialToStore =(arr)=>{
  return{
    type:DISPATCH_LIST_ALL_MATERIAL_TO_STORE,
    payload:{arr}
  }
}

export const dispatchAddMaterialMealDateToStore=(item)=>{
  return{
    type:DISPATCH_ADD_MATERIAL_MEAL_DATE_TO_STORE,
    payload:{item}
  }
}

export const dispatchAddManyMaterialMealDateToStore=(item)=>{
  return{
    type:DISPATCH_ADD_MANY_MATERIAL_MEAL_DATE_TO_STORE,
    payload:{item}
  }
}

export const dispatchListAllSumLessMoreToStore = (arr)=>{  
  return{
    type:DISPATCH_LIST_SUM_LESS_MORE_TO_STORE,
    payload:{arr}
  }
}

export const dispatchAddSumLessMoreToStore = (item)=>{
  return{
    type:DISPATCH_ADD_SUM_LESS_MORE_TO_STORE,
    payload:{item}
  }
}

export const dispatchAddItemSuppliesToStore = (data)=>{
  return{
    type:DISPATCH_ITEM_ADD_SUPPLIES_TO_STORE,
    payload:{data}
  }
}

export const dispatchListSuppliesStore = (data) =>{
  return{
    type:DISPATCH_LIST_SUPPLIES_STORE,
    payload:{data}
  }
}

export const dispatchAddRepositoryToStore = (item)=>{
  return{
    type:DISPATCH_ADD_REPOSITORY_TO_STORE,
    payload:{item}
  }
}
export const dispatchListRepositoryToStore = (item)=>{
  return{
    type:DISPATCH_LIST_REPOSITORY_TO_STORE,
    payload:{item}
  }
}
export const dispatchAddImportToStore = (item)=>{
  return{
    type:DISPATCH_ADD_IMPORT_TO_STORE,
    payload:{item}
  }
}
export const dispatchListTypeMaterialToStore=(item)=>{
  return{
    type:DISPATCH_LIST_TYPE_MATERIAL_TO_STORE,
    payload:{item}
  }
}

export const dispatchItemAddBalanceToStore =(item)=>{
    return{
      type:DISPATCH_ITEM_ADD_BALANCE_TO_STORE,
      payload:{item}
    }
}
export const dispatchListBalanceToStore =(item)=>{
    return{
      type:DISPATCH_LIST_BALANCE_TO_STORE,
      payload:{item}
    }
}

export const DispatchItemPhanBoNguyenLieuNutriTinhAn=(item)=>{
  return{
    type:DISPATCH_ITEM_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN,
    payload:{item}
  }
}