import {take,takeLatest,takeEvery,put,call} from 'redux-saga/effects'
import * as TypeVip from '../../contant/table/Vip'
import {deleteArrayUserIdInDepartmentStaff,updateArrayUserIdInDepartmentStaff} from '../../api-services/user'
import * as uiLoading from '../../actions/uiLoading'
import * as actionVip from '../../actions/auth'
import {STATUS_CODE} from '../../contant/index'

function* listVipSaga({payload}) {  
    while(true){
         yield take(TypeVip.LIST_VIP)                 
    }   
}

function* deleteVipArraySaga({payload}){ 
  
     yield put(uiLoading.loadingSuccess())    
     //yield delay(300)
     const value = payload.allId.map(item=>item.userId)     
     let res = yield call(deleteArrayUserIdInDepartmentStaff,value)
     
      if(res.status===STATUS_CODE.SUCCESS){
        yield put(actionVip.deleteVipArraySuccess())     
      }else {
        yield put(actionVip.deleteVipArrayFail())
      }
      yield put(uiLoading.loadingFail())
}

function* editVipSaga({payload}){  
    yield put(uiLoading.loadingSuccess())
    //yield delay(150)
    let res = yield call(updateArrayUserIdInDepartmentStaff,payload.newData)      
   //console.log(res)
    if(res.data.result==='success'){
        yield put(actionVip.editVipSuccess())
    }else{
      yield put(actionVip.editVipFail())
    }
    yield put(uiLoading.loadingFail())  
  }

function* VipSaga(){
    yield takeLatest(TypeVip.ADD_ITEM_LIST_VIP, listVipSaga)
    yield takeLatest(TypeVip.DELETE_VIP_ARRAY,deleteVipArraySaga)    
    yield takeLatest(TypeVip.EDIT_VIP,editVipSaga)
}

export default VipSaga