export const ACTIVE_SOFT_FOOD             = "ACTIVE_SOFT_FOOD"
export const ACTIVE_SOFT_FOOD_CLOSE_BOX   = "ACTIVE_SOFT_FOOD_CLOSE_BOX"
export const ACTIVE_SOFT_FOOD_FAIL        = "ACTIVE_SOFT_FOOD_FAIL"
export const ACTIVE_SOFT_FOOD_STATUS      =  "ACTIVE_SOFT_FOOD_STATUS"
export const ACTIVE_SOFT_FOOD_USER_STATUS = "ACTIVE_SOFT_FOOD_USER_STATUS"
export const GET_LIST_USER_THANH_TOAN     = "GET_LIST_USER_THANH_TOAN"
export const DISPATCH_GET_LIST_USER_THANH_TOAN_TO_STORE = "DISPATCH_GET_LIST_USER_THANH_TOAN_TO_STORE"
//lay gia tri ngay de input vao form
export const GET_DATE_INPUT = "GET_DATE_INPUT"
export const GET_MEAL_INPUT = "GET_MEAL_INPUT"
export const ADD_DATE_MEAL_DB = "ADD_DATE_MEAL_DB"
export const UPDATE_DATE_MEAL_DB = "UPDATE_DATE_MEAL_DB"
export const UPDATE_DATE_MEAL_DB_SUCCESS = "UPDATE_DATE_MEAL_DB_SUCCESS"
export const ADD_DATE_MEAL_DB_SUCCESS = "ADD_DATE_MEAL_DB_SUCCESS"
export const ADD_DATE_MEAL_DB_FAIL = "ADD_DATE_MEAL_DB_FAIL"
export const INSERT_DATE_MEAL_DB = "INSERT_DATE_MEAL_DB"
export const SHOW_TABLE_LIST = "SHOW_TABLE_LIST"
export const INSERT_DATE = "INSERT_DATE"

export const SHOW_PRINT_COMPONENT_CLASS ="SHOW_PRINT_COMPONENT_CLASS"

export const LIST_WEEK_MEAL = "LIST_WEEK_MEAL"
export const SHOW_PRINT = "SHOW_PRINT"
export const LIST_WEEK = "LIST_WEEK"

export const LIST_DATE_MEAL ="LIST_DATE_MEAL"
export const LIST_DATE_MEAL_BY_USERID ="LIST_DATE_MEAL_BY_USERID"
export const LIST_DATE_MEAL_MONTH_TABLE = "LIST_DATE_MEAL_MONTH_TABLE"
export const LIST_DATE_MONTH_YEAR ="LIST_DATE_MONTH_YEAR"
export const BEGIN_LIST_DATE_MONTH_YEAR ="BEGIN_LIST_DATE_MONTH_YEAR"

export const YEAR = [2020, 2021, 2022, 2023,2024,2025,2026,2027]
export const MONTH = [1,2,3,4,5,6,7,8,9,10,11,12]





export const CHECK_WEEK_FOOD_USER_LOGIN  = "CHECK_WEEK_FOOD_USER_LOGIN"



export const GET_ITEM_USER_INFO_BY_USER_ID = "GET_ITEM_USER_INOF_BY_USER_ID"
export const DISPATCH_ITEM_INFO_BY_USER_ID_TO_STORE  = "DISPATCH_ITEM_INFO_BY_USER_ID_TO_STORE"
export const BLOCK_APP_PAYMENT = "BLOCK_APP_PAYMENT"
export const UPDATE_DATE_MEAL_DB_DIFF="UPDATE_DATE_MEAL_DB_DIFF"


export const MANAGER_DELETE_ITEM_STUDENT_INFO  = "MANAGER_DELETE_ITEM_STUDENT_INFO"
export const DISPATCH_ADD_DELETE_ITEM_STUDENT_INFO = "DISPATCH_ADD_DELETE_ITEM_STUDENT_INFO"
export const GET_LIST_DELETE_ITEM_STUDENT_INFO = "GET_LIST_DELETE_ITEM_STUDENT_INFO"

