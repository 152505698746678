
export const STATUS = [
  {
    label:"READY",
    status:0
  },
  {
    label:"INPROGESS",
    status:1
  },
  {
    label:"COMPLETE",
    status:2
  }
]

export const image_message_type = ['image/png','image/jpg','image/jpeg']
export const image_message_limit_size = 12582912 // 12MB

//export const API_ENDPOINT    = "http://localhost:3000"
export const API_ENDPOINT = "http://data.huythanhicare.com:4444"
//export const API_ENDPOINT = "http://data.huythanhicare.com:5000"
//export const API_ENDPOINT = "http://app.huythanhicare.com:4000"

export const API_GOOGLE_MAP_KEY ="AIzaSyDX0Lech529sxB7oUyK4CaFDet5vDCeveU"

export const AUTHORIZATION_KEY ="PMT"
export const STATUS_CODE = {
  SUCCESS:200,
  CREATED:201,
  UPDATED:202
}

export const PASSWORD_LOGIN = "KH@nhhoa123"
export const PASSWORD_AUTO_IMPORT ="KH@nhhoa123"
export const GOOGLE_APT_FONTEND ="AIzaSyARgL6HhBBQr6pl20MMS-0k81lA-XFIGmg"
export const KEY_PASS = "Nhatrang123"

export const PRIMARY_SCHOOL = "PRIMARY_SCHOOL" //tieu hoc
export const PRESCHOOL      = "PRESCHOOL" //mam non


export const URL_GET_LOCATION_BY_ID = "https://geolocation-db.com/json/2c7ba9a0-fcc9-11eb-80e0-2d180bc7db04"
export const OBJECT_IP_INFO ={TOKEN_IP_INFO:"e6307718a2a688",IP_INFO:'171.227.166.25',ASN:'AS7552'}  
export const IPSTACK='http://api.ipstack.com/171.227.166.25?access_key=f10782b01f86ae5275bb1868ffb5f5be'
//pmt.huythanh default pas

export const GROUP_USER =[
  {
    value:1,
    name:"Quản Trị"
  },
  {
    value:2,
    name:"Nhân Viên",
  },
  {
    value:3,
    name:"Khách Vip"
  },
  {
    value:4,
    name:"Khách Tư Nhân"
  },
  {
    value:5,
    name:"Nhà nước"
  },
  {
    value:6,
    name:"Đối tác"
  }
]

export const APP_LIST =[
  {
    value:10,
    name:'Quản Lý Kho',
  },
  {value:11,
   name:'Quản Lý Nhân Viên'
  },
  {
    value:12,
    name:'Quản Lý Khách Hàng'
  }
]

export const TYPE_OF_SEVICES =[
  {
    value:0,
    name:'Sữa Chữa, Mua Bán'
  },
  {
    value:1,
    name:'Dịch vụ Ăn Uống'
  }
]
export const NOTIFICATION_ZALO_HOC_PHI = "NOP_HOC_PHI"