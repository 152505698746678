import axiosServices from '../../../../api-services/api'
import { API_ENDPOINT } from '../../../../contant'

const urlGetLinkCheckDomain      = 'GetLinkCheckDomain'
const urlGetInfoUserDetailWeb    = "GetInfoUserDetailWeb"
const urlSendRegisStudentWeb     = "SendRegisStudentWeb"

export const GetLinkCheckDomainApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetLinkCheckDomain}`,item)
}

export const GetInfoUserDetailWebApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetInfoUserDetailWeb}`,item)
}

export const SendRegisStudentWebApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlSendRegisStudentWeb}`,item)
}

