import axiosServices from './api'
import {API_ENDPOINT} from '../contant/index'

const url ='users'
const urlUpdatePass = 'users/login/updatePass'
//const urlFindUserName = 'managerAccount/FindUserName'
//let srcIp = "http://www.geoplugin.net/json.gp"

const urlVerifyPayment           = 'user/verifyPayment'
const urlGetListUserPayment      = 'user/GetListPayment'
const urlAddItemListSoftPrice    = 'AddItemListPrice'
const urlUpdateItemListSoftPrice = 'UpdateItemListSoftPrice'

export const UpdateItemListSoftPriceApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlUpdateItemListSoftPrice}`,item)
}
export const AddItemListSoftPriceApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddItemListSoftPrice}`,item)
}


export const signup = data =>{
  return axiosServices.post(`${API_ENDPOINT}/${url}/register`,data)
}

export const login = data =>{
  return axiosServices.post(`${API_ENDPOINT}/${url}/login`,data)
}

export const getListUsers = ()=>{
  return axiosServices.get(`${API_ENDPOINT}/${url}`)
}

export const updatePass = (data)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdatePass}`,data)
}

export const GetInfoUserVerifyPaymentApi=(item)=>{
      return axiosServices.post(`${API_ENDPOINT}/${urlVerifyPayment}`,item)
}

export const GetListUserPaymentApi=()=>{
  return axiosServices.get(`${API_ENDPOINT}/${urlGetListUserPayment}`)
}


