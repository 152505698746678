import {take,takeLatest,takeEvery,delay,put,call} from 'redux-saga/effects'
import * as TypePerson from '../../contant/table/Person'
import {deleteArrayUserIdInDepartmentStaff,updateArrayUserIdInDepartmentStaff} from '../../api-services/user'
import * as uiLoading from '../../actions/uiLoading'
import * as actionPerson from '../../actions/auth'
import {STATUS_CODE} from '../../contant/index'

function* listPersonSaga() {  
    while(true){
         yield take(TypePerson.LIST_PERSON)                 
    }   
}

function* deletePersonArraySaga({payload}){ 
   // console.log(payload)
   // console.log(payload.allId)  
     yield put(uiLoading.loadingSuccess())    
     yield delay(300)
     const value = payload.allId.map(item=>item.userId)     
     let res = yield call(deleteArrayUserIdInDepartmentStaff,value)
     //console.log(res)
    if(res.status===STATUS_CODE.SUCCESS){
      yield put(actionPerson.deletePersonArraySuccess())     
    }else {
      yield put(actionPerson.deletePersonArrayFail())
    }
    yield put(uiLoading.loadingFail())
}

function* editPersonSaga({payload}){  
    yield put(uiLoading.loadingSuccess())
    yield delay(150)
    let res = yield call(updateArrayUserIdInDepartmentStaff,payload.newData)      
    if(res.data.result==='success'){
        yield put(actionPerson.editPersonSuccess())
    }else{
      yield put(actionPerson.editPersonFail())
    }
    yield put(uiLoading.loadingFail())
  
  }

function* PersonSaga() {
    yield takeLatest(TypePerson.ADD_ITEM_LIST_PERSON, listPersonSaga)
    yield takeLatest(TypePerson.DELETE_PERSON_ARRAY,deletePersonArraySaga)    
    yield takeLatest(TypePerson.EDIT_PERSON,editPersonSaga)
}

export default PersonSaga