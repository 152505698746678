export const NOTIFI_EDIT_SUCCESS = "Cap nhat thanh cong"
export const NOTIFI_ADD_SUCCEESS = "Them Thanh cong"
export const NOTIFI_DELETE_SUCCESS = "XOA THANH CONG"
export const NOTIFI_FAIL = "Co loi xay ra, kiem tra lai"
export const NOTIFI_LOADING_SUCCESS="LOADING THANH CONG"
export const SIGNUP_SUCCESS = "Đăng ký tài khoản thành công"
export const SIGNUP_FAIL ="Đăng ký tài khoản thất bại"
export const SIGNUP_FAIL_CHECK_USERNAME ="Tên đăng nhập này đã được đăng ký, quý khách chọn tên khác"
export const LOGIN_FAIL_CHECK_USER_PASS = "Vui lòng kiểm tra lại tên đăng nhập, hoặc mật khẩu"
export const LOGIN_SUCCESS = "ĐĂNG NHẬP THÀNH CÔNG"
export const LOGIN_FAIL = "ĐĂNG NHẬP THẤT BẠI"
