import styled,{keyframes} from 'styled-components'
import { device } from "../../../../style/MainCss/device";
import { MainColor } from "../color";


export const gradient = keyframes`
    100% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    0% {
        background-position: 0% 0%;
    }
`

export const MainContent = styled.div`
        padding:20px;
        padding-bottom:40px;
        weight:100%;
        background:#171616; 
        &:hover{
            background: linear-gradient(315deg,#34495e 10%, rgba(0, 0, 0, 0.87) 38%,  #7f8c8d  68%,#16a085 85%, rgba(0, 0, 0, 0.87) 98%);
            animation: ${gradient} 15s ease infinite;
            background-size: 200% 200%;
            background-attachment: fixed;
        }  
`



export const ContentService = styled.div`
        width:100%;
        height:80px;
        margin:auto;
        background:#fafafa;
`

export const ContentServiceIn = styled.div`
        width:80%;
        height:130px;
        margin:auto;        
        display:flex;
        padding:25px;
        @media ${device.mobile}{
                display:block;               
                margin:auto;
        }
`

export const ContentServiceUnder = styled.div`
        width:100%;
        height:130px;
        margin:auto;
        background:#e9eced;
        display:flex;
        margin-top:45px;
        @media ${device.mobile}{
                display:block;                
                background:#e9eced;
                margin-top: 43px;
                height: 400px;
        }
`


export const HeaderText = styled.h1`
    color:#888888;
    font-size:35px;
    text-align:center;
    font-weight:700;
    @media ${device.mobile}{
        font-size:25px;
    }
`

export const LineUnder = styled.div`
    width:40%;
    height:2px;
    background:${MainColor};
    margin:auto;
    margin-top:-15px;    
`

export const TextIn = styled.h4`
     color:${MainColor};
     font-size:25px;
     text-align:center;
     margin-top:15px;
     @media ${device.mobile}{
        font-size:18px;
    }
`

export const One_ = styled.div`
    width:24%;
    height:100%;
    margin:auto;
    color:${MainColor};
    display:flex;
    padding-top:28px;
    padding:2px;
    transition:0.5s;
    @media ${device.mobile}{
        font-size:18px;
        width:100%;
        background:#e9eced;        
    }
    &:hover {
        transition:0.5s;
        color:#e88a20;
    }
`

export const ContentService_ = styled.div`
        width:80%;        
        margin:auto;
        background:#171616;
        display:block;
        padding:20;
`



export const LineService = styled.div`
    max-width:1200px;    
    margin:0 auto;
    margin-top:40px;
    @media ${device.mobile}{
        margin-top:20px;
        margin-bottom:-30px;
    }
    @media (min-width:768px) and (max-width:1024px){
        margin-left:20%;
    }
`

export const Item_ =styled.div`
    width:30%;
    height:150px;
    margin:auto;
    color:#2c3e50;
    text-align:center;
    display:inline-block;
    margin:20px;
    @media ${device.mobile}{
        margin:0;
        margin-top:20px;
        width:100%;
    }
    @media (min-width:768px) and (max-width:1024px){
    }
`

export const TextHeadService= styled.h3`
        font-size:19px;
        margin-top:10px;
        color:#7f8c8d;
        transition:0.5s;
        &:hover {
        transition:0.5s;
        padding-right:5px;
        }        
`

export const TextExplain = styled.p`
        font-size:15px;
        width:70%;
        margin-top:-15px;
        display: inherit;
        text-align: center;
        transition:0.5s;
        &:hover {
        transition:0.5s;
        color:#e88a20;
    }
`

//we are

export const ContentWe = styled.div`
        width:100%;        
        margin:auto;
        background:#e9eced;
        padding:15px;
        margin-top:15px;
        @media ${device.mobile}{
            margin-top:38px;
        }
`
export const TextWe =styled.h1`
        color:${MainColor};
        font-size:40px;
        text-align:center;
        margin:15px;
        @media ${device.mobile}{
            font-size:32px;
        }
`

export const TextWeIn =styled.h1`
        color:${MainColor};
        font-size:30px;
        text-align:center;
        margin:10px;
        @media ${device.mobile}{
            font-size:22px;
        }
`

export const TextIn_ = styled.p`
        width:40%;
        margin:auto;
        text-align:center;
        display:flex;
        color:#7f8c8d;
        height:60px;
        font-size:18px;
        margin-top:20px;        
        margin-bottom:40px;
        @media ${device.mobile}{
            width:100%;
            margin-bottom:98px;
        }
        @media (min-width:768px) and (max-width:1024px){
                margin-bottom:132px;
        }
        
`

export const ShowLineBg = styled.div`
        @media ${device.mobile}{
                display:content;
                margin-top:48px;
        }
        @media ${device.pc}{
                display:none;
        }

`