import {CLOSE_SELECT_REPO,OPEN_SELECT_REPO,DISPATCH_SELECT_CREATE_REPO_TO_STORE,
OPEN_ADD_SUPPLIES,CLOSE_ADD_SUPPLIES,ADD_SUPPLIES_SUCCESS_NOTIFICATION,CLOSE_SUPPLIES_SUCCESS_NOTIFICATION,
OPEN_ADD_CUSTOMER,CLOSE_ADD_CUSTOMER
,OPEN_EDIT_SUPPLIES,CLOSE_EDIT_SUPPLIES}from '../Contant/Person/cateRepo'
import {updateArrById}from './fn'
import {ADD_SUPPLIES_SUCCESS_AND_DISPATCH_TO_STORE,DISH_PATCH_GET_LIST_SUPPLIES_BY_USER_ID_TO_STORE
,EDIT_SUPPLIES,OPEN_EDIT_SUPPLIES_NOTIFICATION,CLOSE_EDIT_SUPPLIES_NOTIFICATION,
DISPATCH_ADD_CUSTOMER_TO_STORE,DISPATCH_LIST_CUSTOMER_TO_STORE,OPEN_EDIT_CUSTOMER,CLOSE_EDIT_CUSTOMER,OPEN_ADD_BRANCH,CLOSE_ADD_BRANCH,OPEN_UPDATE_BRANCH,CLOSE_UPDATE_BRANCH,
OPEN_ADD_DEPARTMENT,CLOSE_ADD_DEPARTMENT,  UPDATE_ITEM_BRANCH,OPEN_ADD_PERSON_IN_DEPARMENT,CLOSE_ADD_PERSON_IN_DEPARMENT, UPDATE_PERSON_IN_DEPARMENT,
EDIT_CUSTOMER_ACTION,UPDATE_ITEM_DEPARMENT_ACTION
} from '../Contant/Person/supplies'

import {toastSuccess} from "../../helper/toastHelper"

export const initialState={
  OpenSelectRepo:false,
  ValueSelectCreateRepo:null,
  OpenAddSupplies:false,
  OpenAddCustomer:false,
  OpenEditSupplies:false,
  ListSuppliesOfCateRepo:[],
  ActiveListSuppliesOfCateRepo:false,
  OpenFormAddSuppliesNoti:false,
  OpenFormEditSuppliesNoti:false,
  GetDataToEditFormSupplies:{},//truyen du lieu data qua form edit supplies
  ListCustomer:[],
  ActiveListCustomer:false,
  OpenEditCustomer:false,
  ItemEditCustumer:{},
  OpenAddBranch:false,
  OpenUpdateBranch:false,
  OpenAddDepartment:false,
  OpenAddPersonInDeparment:false,
  ItemAddPersonInDeparment:{},
  ItemBranch:{},
  ItemDeparment:{},
  ItemRow:{}
}

const CateRepoReducer=(state=initialState,action)=>{
  switch (action.type) {
    case OPEN_SELECT_REPO:      
        return{
          ...state,
          OpenSelectRepo:true
        }
    case CLOSE_SELECT_REPO:
      return{
        ...state,
        OpenSelectRepo:false
      }
    case DISPATCH_SELECT_CREATE_REPO_TO_STORE:
      return{
        ...state,
        ValueSelectCreateRepo:action.payload.item
      }  
    case OPEN_ADD_SUPPLIES:
      return{
        ...state,
        OpenAddSupplies:true
      }
    case CLOSE_ADD_SUPPLIES:
      return{
        ...state,
        OpenAddSupplies:false
      }
    case ADD_SUPPLIES_SUCCESS_AND_DISPATCH_TO_STORE:      
      return{
        ...state,
        ListSuppliesOfCateRepo:state.ListSuppliesOfCateRepo.concat(action.payload.item)
      }
  
    case DISH_PATCH_GET_LIST_SUPPLIES_BY_USER_ID_TO_STORE:
      return{
        ...state,
        ActiveListSuppliesOfCateRepo:true,
        ListSuppliesOfCateRepo:action.payload.item
      }
    case ADD_SUPPLIES_SUCCESS_NOTIFICATION:
      return{
        ...state,
        OpenFormAddSuppliesNoti:true
      }  
      case CLOSE_SUPPLIES_SUCCESS_NOTIFICATION:
      return{
        ...state,
        OpenFormAddSuppliesNoti:false
      }
      case OPEN_EDIT_SUPPLIES:
        return{
          ...state,
          OpenEditSupplies:true,
          GetDataToEditFormSupplies:action.payload.item
        }
      case CLOSE_EDIT_SUPPLIES:
        return{
          ...state,
          OpenEditSupplies:false
        }  
      case EDIT_SUPPLIES:
        toastSuccess("Cập nhập thành công")
        return{
          ...state,
          ListSuppliesOfCateRepo:updateArrById(state.ListSuppliesOfCateRepo,action.payload.item)
        }
      case OPEN_EDIT_SUPPLIES_NOTIFICATION:
        return{
          ...state,
          OpenFormEditSuppliesNoti:true
        }
        case CLOSE_EDIT_SUPPLIES_NOTIFICATION:
            return{
              ...state,
              OpenFormEditSuppliesNoti:false
            }
       case OPEN_ADD_CUSTOMER:
         return{
           ...state,
           OpenAddCustomer:true
         }
        case CLOSE_ADD_CUSTOMER:
          return {
            ...state,
            OpenAddCustomer:false
          }
        case DISPATCH_ADD_CUSTOMER_TO_STORE:
          return{
            ...state,
            ListCustomer:state.ListCustomer.concat(action.payload.item)
          }
      case DISPATCH_LIST_CUSTOMER_TO_STORE:
        return{
          ...state,
          ActiveListCustomer:true,
          ListCustomer:action.payload.item
        }
      case OPEN_EDIT_CUSTOMER:
        return{
          ...state,
          OpenEditCustomer:true,
          ItemEditCustumer:action.payload.item
        }
      case CLOSE_EDIT_CUSTOMER:
        return{
          ...state,
          OpenEditCustomer:false
        }
        case EDIT_CUSTOMER_ACTION:
          return{
            ...state,
              ListCustomer:action.payload.item
          }
      // case EDIT_CUSTOMER:
      //   return{
      //     ...state,
      //     ListCustomer:updateArrById(state.ListCustomer,action.payload.item)
      //   }
      case OPEN_ADD_BRANCH:
        return{
          ...state,
          OpenAddBranch:true,
          ItemBranch:action.payload.item
        }
        case CLOSE_ADD_BRANCH:
          return{
            ...state,
            OpenAddBranch:false,
            ItemBranch:{}
          }
      case OPEN_UPDATE_BRANCH:
        return{
          ...state,
          OpenUpdateBranch:true,
          ItemBranch:action.payload.item
        }
        case UPDATE_ITEM_BRANCH:
          return{
            ...state,
            ItemBranch:action.payload.item
          }
        case CLOSE_UPDATE_BRANCH:
          return{
            ...state,
            OpenUpdateBranch:false
          }
      case OPEN_ADD_DEPARTMENT:
        return{
          ...state,
          OpenAddDepartment:true,
          ItemDeparment:action.payload.item
        }
      case CLOSE_ADD_DEPARTMENT:
        return{
          ...state,
          OpenAddDepartment:false,
          ItemDeparment:{}
        }
        case UPDATE_ITEM_DEPARMENT_ACTION:
          return{
            ...state,
            ItemDeparment:action.payload.item
          }
      // case UPDATE_ITEM_DEPARMENT:
      //   return{
      //     ...state,
      //     ItemDeparment:action.payload.item
      //   }
      case OPEN_ADD_PERSON_IN_DEPARMENT:
        return{
          ...state,
          OpenAddPersonInDeparment:true,
          ItemDeparment:action.payload.item.itemDeparment,
          ItemRow:action.payload.item.row
        }
      case CLOSE_ADD_PERSON_IN_DEPARMENT:
        return{
          ...state,
          OpenAddPersonInDeparment:false
        }
        case UPDATE_PERSON_IN_DEPARMENT:
          return{
            ...state,
            ItemDeparment:action.payload.item.itemDeparment,
            ItemRow:action.payload.item.row
          }
    default: return state
  }
}

export default CateRepoReducer