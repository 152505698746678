export const HandleArrRepositoryYearSchool=(arrImportTypeMaterial,idYearSchool)=>{ 
  return arrImportTypeMaterial.map(v=>{
    return{
      userId:v.userId,
      idTypeMaterial:v.idTypeMaterial,
      dateImport:v.dateImport,
      quantity:v.quantity,
      price:v.price,
      idYearSchool
    }
  })
}