import {DISPATCH_VALUE_ADD_CREATE_REPO_TO_STORE,DISPATCH_ADD_SUCCESS_CREATE_REPO_TO_STORE,
LIST_ALL_CREATE_REPO,DISPATCH_LIST_ALL_CREATE_REPO_TO_STORE
} from '../Contant/Person/createRepo'
export const DispatchValueAddCreateRepoToStore=(item)=>{
    return{
      type:DISPATCH_VALUE_ADD_CREATE_REPO_TO_STORE,
      payload:item
    }    
}

export const DispatchAddSuccessCreateRepoToStore=(item)=>{
  return{
    type:DISPATCH_ADD_SUCCESS_CREATE_REPO_TO_STORE,
    payload:{item}
  }
}
export const ListAllCreateRepo=(id)=>{
  return {
      type:LIST_ALL_CREATE_REPO,
      payload:id
  }
}

export const DispatchListAllCreateRepoToStore=(item)=>{
    return{
      type:DISPATCH_LIST_ALL_CREATE_REPO_TO_STORE,
      payload:{item}
    }
}
