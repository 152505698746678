export const DISPATCH_DATE_TIME            = "DISPATCH_DATE_TIME"
export const DISPATCH_ITEM_SELECT_MEAL_AGE = "DISPATCH_ITEM_SELECT_MEAL_AGE"
export const DISPATCH_INPUT_NUMBER_EAT     = "DISPATCH_INPUT_NUMBER_EAT"
export const DISPATCH_INPUT_MONEY_EAT     = "DISPATCH_INPUT_MONEY_EAT"

export const OPEN_TONG_LUONG_DUONG_CHAT    = "OPEN_TONG_LUONG_DUONG_CHAT"
export const CLOSE_TONG_LUONG_DUONG_CHAT   = "CLOSE_TONG_LUONG_DUONG_CHAT"

export const ADD_MEAL_NUTRIKID             = "ADD_MEAL_NUTRIKID" //tao thuc don
export const OPEN_ADD_MEAL_NUTRIKID        = "OPEN_ADD_MEAL_NUTRIKID"
export const CLOSE_ADD_MEAL_NUTRIKID       = "CLOSE_ADD_MEAL_NUTRIKID"
export const DISPATCH_ADD_MEAL_NUTRIKID_TO_STORE = "DISPATCH_ADD_MEAL_NUTRIKID_TO_STORE"


export const GET_ITEM_DATE_MEAL_NUTRIKID   = "GET_ITEM_DATE_MEAL_NUTRIKID"
export const DISPATCH_GET_DATE_MEAL_NUTRIKID_TO_STORE = "DISPATCH_GET_DATE_MEAL_NUTRIKID_TO_STORE"

export const DISPATCH_UPDATE_DATE_MEAL_NUTRIKID = "DISPATCH_UPDATE_DATE_MEAL_NUTRIKID"
export const DELETE_DATE_MEAL_NUTRIKID      = "DELETE_DATE_MEAL_NUTRIKID"
export const OPEN_TABLE_MEAL_NUTRIKID       = "OPEN_TABLE_MEAL_NUTRIKID"
export const CLOSE_TABLE_MEAL_NUTRIKID      = "CLOSE_TABLE_MEAL_NUTRIKID"

export const DISPATCH_ITEM_ADD_MATERIAL_NUTRIKID          = "DISPATCH_ITEM_ADD_MATERIAL_NUTRIKID"
export const ADD_MATERIAL_DATE_NUTRIKID                   = "ADD_MATERIAL_DATE_NUTRIKID"
export const DISPATCH_ADD_MATERIAL_DATE_NUTRIKID_TO_STORE = "DISPATCH_ADD_MATERIAL_DATE_NUTRIKID_TO_STORE"

//update mass
export const UPDATE_MASS_IN_MATERIAL_DATE_NUTRIKID        = "UPDATE_MASS_IN_MATERIAL_DATE_NUTRIKID"
export const UPDATE_PRICE_LOSE_IN_MATERIAL_DATE_NUTRIKID  = "UPDATE_PRICE_LOSE_IN_MATERIAL_DATE_NUTRIKID"
export const UPDATE_PRICE_IN_MATERIAL_DATE_NUTRIKID       = "UPDATE_PRICE_IN_MATERIAL_DATE_NUTRIKID"
export const UPDATE_REPO_MATERIAL_DATE_NUTRIKID           = "UPDATE_REPO_MATERIAL_DATE_NUTRIKID"
export const DELETE_REPO_MATERIAL_DATE_NUTRIKID           = "DELETE_REPO_MATERIAL_DATE_NUTRIKID"

export const DISPATCH_KET_QUA_DUONG_CHAT                  = "DISPATCH_KET_QUA_DUONG_CHAT"

export const OPEN_RESULT_MEAL_NUTRIKID                    = "OPEN_RESULT_MEAL_NUTRIKID"
export const CLOSE_RESULT_MEAL_NUTRIKID                   = "CLOSE_RESULT_MEAL_NUTRIKID"
export const VALUE_SELECT_RESULT_MEAL_NUTRIKID            = "VALUE_SELECT_RESULT_MEAL_NUTRIKID"

export const ADD_ITEM_MEAL_NUTRIKID                       = "ADD_ITEM_MEAL_NUTRIKID"
export const OPEN_ADD_ITEM_MEAL_NUTRIKID                  = "OPEN_ADD_ITEM_MEAL_NUTRIKID"
export const CLOSE_ADD_ITEM_MEAL_NUTRIKID                 = "CLOSE_ADD_ITEM_MEAL_NUTRIKID"

export const UPDATE_ITEM_MEAL_NUTRIKID                    = "UPDATE_ITEM_MEAL_NUTRIKID"
export const OPEN_UPDATE_ITEM_MEAL_NUTRIKID               = "OPEN_UPDATE_ITEM_MEAL_NUTRIKID"
export const CLOSE_UPDATE_ITEM_MEAL_NUTRIKID              = "CLOSE_UPDATE_ITEM_MEAL_NUTRIKID"

export const OPEN_PRINT_MEAL_NUTRIKID                     = "OPEN_PRINT_MEAL_NUTRIKID" 
export const CLOSE_PRINT_MEAL_NUTRIKID                    = "CLOSE_PRINT_MEAL_NUTRIKID" 

export const OPEN_TONG_HOP_CHO_NGAY                       = "OPEN_TONG_HOP_CHO_NGAY"
export const CLOSE_TONG_HOP_CHO_NGAY                      = "CLOSE_TONG_HOP_CHO_NGAY"

//LuyKe
export const ADD_LUY_KE                = "ADD_LUY_KE"
export const OPEN_ADD_LUY_KE           = "OPEN_ADD_LUY_KE"
export const CLOSE_ADD_LUY_KE          = "CLOSE_ADD_LUY_KE"
export const DISPATCH_LUY_KE_TO_STORE  = "DISPATCH_LUY_KE_TO_STORE"

export const UPDATE_LUY_KE                = "UPDATE_LUY_KE"
export const OPEN_UPDATE_LUY_KE           = "OPEN_UPDATE_LUY_KE"
export const CLOSE_UPDATE_LUY_KE          = "CLOSE_UPDATE_LUY_KE"

export const DISPATCH_LUY_KE_DAU_KY      = "DISPATCH_LUY_KE_DAU_KY"

//update NumberEat

export const UPDATE_NUMBER_EAT_MEAL_NUTRIKID = "UPDATE_NUMBER_EAT_MEAL_NUTRIKID"
export const UPDATE_MEAL_EAT_MEAL_NUTRIKID   = "UPDATE_MEAL_EAT_MEAL_NUTRIKID"
//tong hop duong chat
export const OPEN_TONG_HOP_DUONG_CHAT            = "OPEN_TONG_HOP_DUONG_CHAT"
export const CLOSE_TONG_HOP_DUONG_CHAT           = "CLOSE_TONG_HOP_DUONG_CHAT"
export const DISPATCH_SELECT_TONG_HOP_DUONG_CHAT = "DISPATCH_SELECT_TONG_HOP_DUONG_CHAT"
export const CLOSE_SELECT_TONG_HOP_DUONG_CHAT    = "CLOSE_SELECT_TONG_HOP_DUONG_CHAT"

export const DONG_BO_THUC_DON_TUAN_MEAL  = "DONG_BO_THUC_DON_TUAN_MEAL"

export const OPEN_SO_GIAO_NHAN_THUC_PHAM = "OPEN_SO_GIAO_NHAN_THUC_PHAM"
export const CLOSE_SO_GIAO_NHAN_THUC_PHAM = "CLOSE_SO_GIAO_NHAN_THUC_PHAM"

export const OPEN_PHAN_BO_NGUYEN_LIEU_NCC  = "OPEN_PHAN_BO_NGUYEN_LIEU_NCC"
export const CLOSE_PHAN_BO_NGUYEN_LIEU_NCC = "CLOSE_PHAN_BO_NGUYEN_LIEU_NCC"

export const GET_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM = "GET_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM"

export const DISPATCH_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM_TO_STORE = "DISPATCH_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM_TO_STORE"
export const UPDATE_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM            = "UPDATE_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM"

export const UPDATE_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM_TINH_AN    = "UPDATE_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM_TINH_AN"

export const CREATE_GET_TACH_HOC_SINH = "CREATE_GET_TACH_HOC_SINH"
export const DISPATCH_CREATE_GET_TACH_HOC_SINH = "DISPATCH_CREATE_GET_TACH_HOC_SINH"
export const UPDATE_CREATE_GET_TACH_HOC_SINH = "UPDATE_CREATE_GET_TACH_HOC_SINH"
export const DISPATCH_UPDATE_CREATE_GET_TACH_HOC_SINH = "DISPATCH_UPDATE_CREATE_GET_TACH_HOC_SINH"
export const SHOW_PANEL_LEFT_NUTRIKID ="SHOW_PANEL_LEFT_NUTRIKID"
export const HIDE_PANEL_LEFT_NUTRIKID ="HIDE_PANEL_LEFT_NUTRIKID"

export const GET_LIST_TACH_HOC_SINH         = "GET_LIST_TACH_HOC_SINH"
export const DISPATCH_GET_LIST_TACH_HOC_SINH = "DISPATCH_GET_LIST_TACH_HOC_SINH"

export const CREATE_GET_BRANCH_DIEM_TRUONG = "CREATE_GET_BRANCH_DIEM_TRUONG"
export const DISPATCH_CREATE_GET_BRANCH_DIEM_TRUONG = "DISPATCH_CREATE_GET_BRANCH_DIEM_TRUONG"
export const ADD_BRANCH_DIEM_TRUONG = "ADD_BRANCH_DIEM_TRUONG"
export const DISPATCH_ADD_BRANCH_DIEM_TRUONG = "DISPATCH_ADD_BRANCH_DIEM_TRUONG"
export const UPDATE_ADD_BRANCH_DIEM_TRUONG = "UPDATE_ADD_BRANCH_DIEM_TRUONG"
export const DELETE_BRANCH_DIEM_TRUONG = "DELETE_BRANCH_DIEM_TRUONG"
export const DISPATCH_BRANCH_DIEM_TRUONG_TO_STORE = "DISPATCH_BRANCH_DIEM_TRUONG_TO_STORE"
export const DISPATCH_VALUE_BRANCH_DIEM_TRUONG_TO_STORE = "DISPATCH_VALUE_BRANCH_DIEM_TRUONG_TO_STORE"
export const DISPATCH_VALUE_0_BRANCH_DIEM_TRUONG_TO_STORE = "DISPATCH_VALUE_0_BRANCH_DIEM_TRUONG_TO_STORE"
export const DISPATCH_KEY_DIEM_TRUONG_TO_STORE = "DISPATCH_KEY_DIEM_TRUONG_TO_STORE"

export const DISPATCH_TACH_DIEM_TO_STORE= "DISPATCH_TACH_DIEM_TO_STORE"

export const DELETE_LIST_TACH_HOC_SINH = "DELETE_LIST_TACH_HOC_SINH"

export const UPLOAD_EXCEL_MATERIAL_NUTRIKID = "UPLOAD_EXCEL_MATERIAL_NUTRIKID"
export const UPLOAD_EXCEL_MATERIAL_NUTRIKID_NULL_TO_STORE = "UPLOAD_EXCEL_MATERIAL_NUTRIKID_NULL_TO_STORE"
export const UPLOAD_EXCEL_MATERIAL_NUTRIKID_NULL_TO_STORE_CLOSE_FORM = "UPLOAD_EXCEL_MATERIAL_NUTRIKID_NULL_TO_STORE_CLOSE_FORM"

export const RESET_CREATE_GET_TACH_HOC_SINH = "RESET_CREATE_GET_TACH_HOC_SINH"