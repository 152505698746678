import { API_ENDPOINT } from "../../../../../contant";
import axiosServices from "../../../../../api-services/api";

const urlGetInfoCusNhanVien     = "GetInfoCusNhanVien"
const urlAddJobStaffNhanVien    = "AddJobStaffNhanVien"
const urlSearchJobStaffNhanVien = "SearchJobStaffNhanVien"
const urlSearchJobStaffNhanVien__="SearchJobStaffNhanVien__"
const urlUpdateJobStaffNhanVien = "UpdateJobStaffNhanVien"
const urlDeleteJobStaffNhanVien = "DeleteJobStaffNhanVien"
const urlUpdateJobStaffNhanVienVatTuDb = 'UpdateJobStaffNhanVienVatTuDb'
const urlDeleteJobStaffNhanVienVatTuDb = 'DeleteJobStaffNhanVienVatTuDb'
const urlAddJobStaffNhanVienVatTuDb    =  'AddJobStaffNhanVienVatTuDb'
const urlApproveJobStaffTrue = 'ApproveJobStaffTrue'
const urlApproveJobStaffFalse = 'ApproveJobStaffFalse'

export const ApproveJobStaffFalseApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlApproveJobStaffFalse}`,item)
}
export const ApproveJobStaffTrueApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlApproveJobStaffTrue}`,item)
}
export const AddJobStaffNhanVienVatTuDbApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddJobStaffNhanVienVatTuDb}`,item)
}
export const DeleteJobStaffNhanVienVatTuDbApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteJobStaffNhanVienVatTuDb}`,item)
}
export const UpdateJobStaffNhanVienVatTuDbApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateJobStaffNhanVienVatTuDb}`,item)
}
export const DeleteJobStaffNhanVienApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteJobStaffNhanVien}`,item)
}

export const UpdateJobStaffNhanVienApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateJobStaffNhanVien}`,item)
}

export const GetInfoCusNhanVienApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetInfoCusNhanVien}`,item)
}

export const AddJobStaffNhanVienApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddJobStaffNhanVien}`,item)
}

export const SearchJobStaffNhanVienApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlSearchJobStaffNhanVien}`,item)
}

export const SearchJobStaffNhanVien__Api=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlSearchJobStaffNhanVien__}`,item)
}

