import styled,{} from 'styled-components'
import { LINE_TEXT_PX,MARGIN_TOP_IN_LINE } from './contant'
import LeaderCeo from '../../assest/ceo/leaderceo.jpg'
import {device} from './device'
import { MainColor } from '../../main/component/color'

export const CONTENT_TEAM = styled.div`
            width:100%;     
            height:800px;     
            top:0;
            left:0;  
            margin-top:20px;
            padding-top:15px;
            background:url(${LeaderCeo}) bottom center / cover no-repeat;
            position:relative;
            z-index:1;
            @media ${device.mobile}{
                 height:2800px;
                 margin:auto;
                 padding:auto;
        }
`

export const TEAM_HEAD_SHADOW = styled.div`
        position:absolute;
        z-index:2;
        background:rgba(0,0,0,0.8);
        width :100%;
        height:100%;
        left :0;
        top:0
`
export const TEAM_SEO_LEADER=styled.div`
        width:100%;
        height:100%;
        position:absolute;
        z-index:3;
        top:0;
        left:0;       
        margin:auto;
`


export const TEAM_HEAD_TEXT = styled.h2`
        text-align:center;
        font-size:30px;
        font-weight:bolder;
        letter-spacing:3px;
        color:white;
        margin-top:calc(2 * ${MARGIN_TOP_IN_LINE}px);    
`

export const LineTeamHead = styled.div`
        width:${LINE_TEXT_PX}px;
        height:3px;
        background:${MainColor};
        margin-left:calc(50% - ${LINE_TEXT_PX}px + ${LINE_TEXT_PX/2}px);
        margin-top:${MARGIN_TOP_IN_LINE}px;        
`

export const TEAM_LIST = styled.div`
        display:flex;       
        width:90%;
        align-content:flex-end;
        flex-warp:wrap;   
        margin:auto;
        margin-top:41px;
        @media ${device.mobile}{
                display:block;
                
        }
`

export const TEAM_ITEM = styled.div`
        ${'' /* float:left; */}
        width:25%;
        height:250px;
        margin:5px 5px;
        padding:5px 5px;
        border:none;
        border-radius:3px;
        box-shadow:5px 5px 5px 5px;
        background:${MainColor};
        @media ${device.mobile}{
                width: 90%;                
                margin: auto;
                margin-bottom: 5px;
        }
        @media ${device.tablet}{
                width:50%;
        }
`
export const TEAM_ITEM_IMG=styled.img`   
        width:80px;
        height:80px;        
        border-radius:50%;
`

export const TEAM_TEXT_P  = styled.p`
        font-size: 12px;
        color: white;
        text-align:justify;
        display:inline-block;
`

export const TEAM_NAME = styled.p`
        font-size:14px;
        color:black;
        font-style:italic;
        text-align:right;
        margin-top:-15px;
`

export const WAIT_PARTNER = styled.div`
        display:flex;
        margin:auto;
        margin-top:20px;
        width:80%;
        @media ${device.mobile}{
                display:block;                
        }
`

export const TEXT_PARTNER = styled.div`
        margin:auto;
        font-size:35px;
        color:${MainColor};
        text-align:center;
        margin-top:30px;
       
`

export const ITEM_PARTNER = styled.div`    
        height:180px;
        width:190px;
        ${'' /* box-sizing:content-box; */}
        margin:5px 5px;
        padding:26px 26px;
        background:white;
        box-shadow:5px 5px 5px 5px;
        border-radius:10px;
        margin:auto;
        @media ${device.mobile}{
                margin:10px;        
        }
        
`

export const ITEM_IMG_PARTNER = styled.img`
        height:70px;
        margin:auto;
        @media ${device.mobile}{
                padding: 12px 33px;
        }
`

export const NAME_PARTNER =styled.h4`
        text-align:center;
        color:black;
        font-size: 17px;
        margin-top: 3px;
`

export const TextIn = styled.h2`
        text-align:center;
        color:#09a3ad;
        font-size:25px;
`

export const ListIcon = styled.div`
        display:flex;

`

// export const IconFace = styled.div`
//         width:40px;
//         height:40px;
//         background:${MainColor};
//         border-radius:50%;
//         margin:auto;
// `

export const IMG_ICON = styled.img`
                height:35px;
                border-radius:50%;
                background:${MainColor};
                padding:8px;
                margin:5px;
                cursor:pointer;
                 transition:0.5s;
                &:hover {
                        background:#e74c3c;
                        transition:0.5s;
                }
`

export const IMG_ICON_YOU = styled.img`
                height:70px;
                border-radius:50%;
                background:${MainColor};
                padding:8px;
                margin:5px;
                cursor:pointer;
               
`