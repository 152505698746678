import styled ,{keyframes}from "styled-components";
//import { MainColor } from "../../../main/component/color";
import {device} from '../device'
import { ColorBackGround } from "./color";

export const firework = keyframes`
  0% { 
    transform: translate(-50%, 60vh);
    width: 0.5vmin;
    opacity: 1;
  }
  50% { 
    width: 0.5vmin;
    opacity: 1;
  }
  100% { 
    width: 45vmin; 
    opacity: 0; 
  }
`

export const textclip = keyframes`
   from {
    background-position: 200% center;
  }
`
export const rotate= keyframes`
	100% {
		transform: rotate(1turn);
	}
`

export const  opacityChange = keyframes `
	50% {
		opacity:1;
	}
	100% {
		opacity: 0.5;
	}
`
export const Banner =styled.div`            
                position: absolute;
                left: 50%;
                top:280px;
                display: block;
                margin: 100px -200px;
                width: 400px;
                height: 53px;
                border: 1px solid #e67e22;
                font: normal 30px/60px 'Rye';
                text-align: center;
                color: #e67e22;
                background: #e67e22;
                border-radius: 4px;
                box-shadow: 0 0 30px rgba(0,0,0,.15) inset,
                                    0 6px 10px rgba(0,0,0,.15);


                

            &:before{
                 content: '';
                 position: absolute;
                 z-index: -1;
                 left: -66px;
                 top: 16px;
                 display: block;
                 width: 35px;
                 height: 0px;
                 border: 25px solid  #e67e22;
                 border-right: 20px solid  #e67e22;
                 border-bottom-color:  #e67e22;
                 border-left-color: transparent;
                 transform: rotate(-5deg);
                 box-sizing: content-box;
            }
            &:after{
                content: '';
                position: absolute;
                z-index: -1;
                left: -66px;
                top: 16px;
                display: block;
                width: 35px;
                height: 0px;
                border: 25px solid  #e67e22;
                border-right: 20px solid  #e67e22;
                border-bottom-color:  #e67e22;
                border-left-color: transparent;
                transform: rotate(-5deg);
                box-sizing: content-box;
            }
            &:after{
                left: auto;
                right: -70px;
                border-left: 20px solid  #e67e22;
                border-right: 30px solid transparent;
                transform: rotate(5deg);
                box-sizing: content-box;
            }


            text-transform: uppercase;
            background-image: linear-gradient(
                -225deg,
                #231557 0%,
                #44107a 29%,
                #ff1361 67%,
                #fff800 100%
            );
            background-size: auto auto;
            background-clip: border-box;
            background-size: 200% auto;
            ${'' /* color: #fff; */}
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: ${textclip} 3s linear infinite;
            display: inline-block;
            font-size: 23px;
            @media (max-width:768px){
                display:none
            }
`

export const BannerMobile =styled.div`
    @media (max-width:768px)   {
        display:none;
        margin-top:67px;
        margin-left:50px;
        width:350px;
        height:100px;
        align-item:center;
        padding:20px;
        text-transform: uppercase;
        background-image: linear-gradient(
                -225deg,
                #231557 0%,
                #44107a 29%,
                #ff1361 67%,
                #fff800 100%
            );
            background-size: auto auto;
            background-clip: border-box;
            background-size: 200% auto;
            ${'' /* color: #fff; */}
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: ${textclip} 3s linear infinite;
            display: inline-block;
            font-size: 23px;

    }
    @media (min-width:769px){
        display:none;
    }
`

export const VinhDanhTitle = styled.div`
    @media (max-width:768px){
        display:none;
    }
    @media (min-width:769px){
        text-transform: uppercase;
            background-image: linear-gradient(
                -225deg,
                #231557 0%,
                #44107a 29%,
                #ff1361 67%,
                #fff800 100%
            );
            background-size: auto auto;
            background-clip: border-box;
            background-size: 200% auto;
            ${'' /* color: #fff; */}
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: ${textclip} 3s linear infinite;
            display: inline-block;
            font-size: 20px;
    }        
`

export const VinhDanhTitleMobile = styled.div`
        @media (max-width:768px){
            text-transform: uppercase;
            background-image: linear-gradient(
                -225deg,
                #231557 0%,
                #44107a 29%,
                #ff1361 67%,
                #fff800 100%
            );
            background-size: auto auto;
            background-clip: border-box;
            background-size: 200% auto;
            ${'' /* color: #fff; */}
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: ${textclip} 3s linear infinite;
            display: inline-block;
            font-size: 20px;
        }
        @media (min-width:769px){
            display:none;
        }
         
`

export const MainRibon = styled.div`     
    margin:auto;   
    margin-top:-111px;
    z-index: 100;
    @media (max-width:768px){
        right: 30px;
        width: 400px;
        height: 339px;
        margin-top:0px;
        margin-left:-36px;
    }
`   

export const MainAbout = styled.div`
        background:${ColorBackGround};
        padding:35px;
        ${'' /* padding-bottom:100px; */}
`

export const DivImg = styled.img`
    object-fit:contain;
    top:0;
    left:50px;
    position:absolute;
    z-index:100;
    width:300px;
    height:150px;   
    scale:0.9;
    @media (max-width:768px){
        left:22px;
    }
    @media ${device.tablet}{
        
    }
`

export const MainPic = styled.div`
    right:-150px;
    width:400px;
    height:400px;
    margin:auto;
    position:relative;
    z-index:99;
    @media (max-width:768px){
        right: 30px;
        width: 400px;
        height: 100px;
        margin-top:-39px;
        right:0px;
    }
    @media (min-width:769px)and(max-width:1024px){
        right:-50px;
    }
`

export const CupDiv = styled.div`
    margin:auto;
    width:200px;
    height:150px;
    position:relative;
    z-index:100;
    top:120px;
    border-radius: 10px;
    overflow: hidden;
    padding: 2rem;
    @media (max-width:768px){
        left:-31px;
        top:127px;
    }
    &:before{
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background-color: #399953;
		background-repeat: no-repeat;
		background-size: 50% 50%, 50% 50%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5);
		animation: ${rotate} 4s linear infinite;
	}
	
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 6px;
		top: 6px;
		width: calc(100% - 12px);
		height: calc(100% - 12px);
		background: white;
		border-radius: 5px;
		animation: ${opacityChange} 3s infinite alternate;
	}

`

export const CupImg = styled.img`
    position:absolute;
    z-index:101;
    width:100%;
    height:100%;
    object-fit: contain;
    top:0;
    left:0;
    scale:0.9;   
`


export const SeiAward = styled.div`
            text-transform: uppercase;
            background-image: linear-gradient(
                -225deg,
                #231557 0%,
                #44107a 29%,
                #ff1361 67%,
                #fff800 100%
            );
            background-size: auto auto;
            background-clip: border-box;
            background-size: 200% auto;
            ${'' /* color: #fff; */}
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: ${textclip} 3s linear infinite;
            display: inline-block;
            font-size: 10px;            
`

export const PhaoHoa = styled.div`      
  z-index:999999999;
  --top: 60vh;
  content: "";
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background:
    /* random backgrounds */
    radial-gradient(circle, #ff0 0.2vmin,    #009432 0) 50% 00%,
    radial-gradient(circle, #FFC312 0.3vmin, #0652DD 0) 00% 50%,
    radial-gradient(circle,#C4E538 0.5vmin, #9980FA 0) 50% 99%,
    radial-gradient(circle, #12CBC4 0.2vmin, #9980FA 0) 99% 50%,
    radial-gradient(circle, #FDA7DF 0.3vmin, #833471 0) 80% 90%,
    radial-gradient(circle, #ED4C67 0.5vmin, #EA2027 0) 95% 90%,
    radial-gradient(circle, #F79F1F 0.5vmin, #009432 0) 10% 60%,
    radial-gradient(circle, #A3CB38 0.2vmin, #1B1464 0) 31% 80%,
    radial-gradient(circle, #1289A7 0.3vmin, #5758BB 0) 80% 10%,
    radial-gradient(circle, #D980FA 0.2vmin, #6F1E51 0) 90% 23%,
    radial-gradient(circle, #B53471 0.3vmin,#FDA7DF 0) 45% 20%,
    radial-gradient(circle, #EE5A24 0.5vmin, #B53471 0) 13% 24%
    ;
  background-size: 0.5vmin 0.5vmin;
  background-repeat: no-repeat;
  animation: ${firework} 2s linear infinite;

  &:before{
    --top: 60vh;
  content: "";
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background:
    /* random backgrounds */
    radial-gradient(circle, #ff0 0.2vmin,    #009432 0) 50% 00%,
    radial-gradient(circle, #FFC312 0.3vmin, #0652DD 0) 00% 50%,
    radial-gradient(circle,#C4E538 0.5vmin, #9980FA 0) 50% 99%,
    radial-gradient(circle, #12CBC4 0.2vmin, #9980FA 0) 99% 50%,
    radial-gradient(circle, #FDA7DF 0.3vmin, #833471 0) 80% 90%,
    radial-gradient(circle, #ED4C67 0.5vmin, #EA2027 0) 95% 90%,
    radial-gradient(circle, #F79F1F 0.5vmin, #009432 0) 10% 60%,
    radial-gradient(circle, #A3CB38 0.2vmin, #1B1464 0) 31% 80%,
    radial-gradient(circle, #1289A7 0.3vmin, #5758BB 0) 80% 10%,
    radial-gradient(circle, #D980FA 0.2vmin, #6F1E51 0) 90% 23%,
    radial-gradient(circle, #B53471 0.3vmin,#FDA7DF 0) 45% 20%,
    radial-gradient(circle, #EE5A24 0.5vmin, #B53471 0) 13% 24%
    ;
  background-size: 0.5vmin 0.5vmin;
  background-repeat: no-repeat;
  animation: ${firework} 4s ease-in-out infinite;
   transform: translate(-50%, -50%) rotate(25deg) !important; 
  }
  &:after{
    --top: 60vh;
  content: "";
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background:
    /* random backgrounds */
    radial-gradient(circle, #ff0 0.2vmin,    #009432 0) 50% 00%,
    radial-gradient(circle, #FFC312 0.3vmin, #0652DD 0) 00% 50%,
    radial-gradient(circle,#C4E538 0.5vmin, #9980FA 0) 50% 99%,
    radial-gradient(circle, #12CBC4 0.2vmin, #9980FA 0) 99% 50%,
    radial-gradient(circle, #FDA7DF 0.3vmin, #833471 0) 80% 90%,
    radial-gradient(circle, #ED4C67 0.5vmin, #EA2027 0) 95% 90%,
    radial-gradient(circle, #F79F1F 0.5vmin, #009432 0) 10% 60%,
    radial-gradient(circle, #A3CB38 0.2vmin, #1B1464 0) 31% 80%,
    radial-gradient(circle, #1289A7 0.3vmin, #5758BB 0) 80% 10%,
    radial-gradient(circle, #D980FA 0.2vmin, #6F1E51 0) 90% 23%,
    radial-gradient(circle, #B53471 0.3vmin,#FDA7DF 0) 45% 20%,
    radial-gradient(circle, #EE5A24 0.5vmin, #B53471 0) 13% 24%
    ;
  background-size: 0.5vmin 0.5vmin;
  background-repeat: no-repeat;
  animation: ${firework} 6s ease-in infinite;
    transform: translate(-50%, -50%) rotate(-37deg) !important;
  }
`

export const PhaoHoa2 = styled.div`      
 z-index:999999999;
  --top: 60vh;
  content: "";
  position: absolute;
  top: 30%;
  left: 25%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background:
    /* random backgrounds */
    radial-gradient(circle, #ff0 0.2vmin,    #009432 0) 50% 00%,
    radial-gradient(circle, #FFC312 0.3vmin, #0652DD 0) 00% 50%,
    radial-gradient(circle,#C4E538 0.5vmin, #9980FA 0) 50% 99%,
    radial-gradient(circle, #12CBC4 0.2vmin, #9980FA 0) 99% 50%,
    radial-gradient(circle, #FDA7DF 0.3vmin, #833471 0) 80% 90%,
    radial-gradient(circle, #ED4C67 0.5vmin, #EA2027 0) 95% 90%,
    radial-gradient(circle, #F79F1F 0.5vmin, #009432 0) 10% 60%,
    radial-gradient(circle, #A3CB38 0.2vmin, #1B1464 0) 31% 80%,
    radial-gradient(circle, #1289A7 0.3vmin, #5758BB 0) 80% 10%,
    radial-gradient(circle, #D980FA 0.2vmin, #6F1E51 0) 90% 23%,
    radial-gradient(circle, #B53471 0.3vmin,#FDA7DF 0) 45% 20%,
    radial-gradient(circle, #EE5A24 0.5vmin, #B53471 0) 13% 24%
    ;
  background-size: 0.5vmin 0.5vmin;
  background-repeat: no-repeat;
  animation: ${firework} 2s linear infinite;

  &:before{
    --top: 60vh;
  content: "";
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background:
    /* random backgrounds */
    radial-gradient(circle, #ff0 0.2vmin,    #009432 0) 50% 00%,
    radial-gradient(circle, #FFC312 0.3vmin, #0652DD 0) 00% 50%,
    radial-gradient(circle,#C4E538 0.5vmin, #9980FA 0) 50% 99%,
    radial-gradient(circle, #12CBC4 0.2vmin, #9980FA 0) 99% 50%,
    radial-gradient(circle, #FDA7DF 0.3vmin, #833471 0) 80% 90%,
    radial-gradient(circle, #ED4C67 0.5vmin, #EA2027 0) 95% 90%,
    radial-gradient(circle, #F79F1F 0.5vmin, #009432 0) 10% 60%,
    radial-gradient(circle, #A3CB38 0.2vmin, #1B1464 0) 31% 80%,
    radial-gradient(circle, #1289A7 0.3vmin, #5758BB 0) 80% 10%,
    radial-gradient(circle, #D980FA 0.2vmin, #6F1E51 0) 90% 23%,
    radial-gradient(circle, #B53471 0.3vmin,#FDA7DF 0) 45% 20%,
    radial-gradient(circle, #EE5A24 0.5vmin, #B53471 0) 13% 24%
    ;
  background-size: 0.5vmin 0.5vmin;
  background-repeat: no-repeat;
  animation: ${firework} 4s infinite;
   transform: translate(-50%, -50%) rotate(25deg) !important; 
  }
  &:after{
    --top: 60vh;
  content: "";
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background:
    /* random backgrounds */
    radial-gradient(circle, #ff0 0.2vmin,    #009432 0) 50% 00%,
    radial-gradient(circle, #FFC312 0.3vmin, #0652DD 0) 00% 50%,
    radial-gradient(circle,#C4E538 0.5vmin, #9980FA 0) 50% 99%,
    radial-gradient(circle, #12CBC4 0.2vmin, #9980FA 0) 99% 50%,
    radial-gradient(circle, #FDA7DF 0.3vmin, #833471 0) 80% 90%,
    radial-gradient(circle, #ED4C67 0.5vmin, #EA2027 0) 95% 90%,
    radial-gradient(circle, #F79F1F 0.5vmin, #009432 0) 10% 60%,
    radial-gradient(circle, #A3CB38 0.2vmin, #1B1464 0) 31% 80%,
    radial-gradient(circle, #1289A7 0.3vmin, #5758BB 0) 80% 10%,
    radial-gradient(circle, #D980FA 0.2vmin, #6F1E51 0) 90% 23%,
    radial-gradient(circle, #B53471 0.3vmin,#FDA7DF 0) 45% 20%,
    radial-gradient(circle, #EE5A24 0.5vmin, #B53471 0) 13% 24%
    ;
  background-size: 0.5vmin 0.5vmin;
  background-repeat: no-repeat;
  animation: ${firework} 6s ease infinite;
    transform: translate(-50%, -50%) rotate(-37deg) !important;
  }
`

export const PhaoHoa3 = styled.div`      
 z-index:999999999;
  --top: 60vh;
  content: "";
  position: absolute;
  top: 30%;
  left: 75%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background:
    /* random backgrounds */
    radial-gradient(circle, #ff0 0.2vmin,    #009432 0) 50% 00%,
    radial-gradient(circle, #FFC312 0.3vmin, #0652DD 0) 00% 50%,
    radial-gradient(circle,#C4E538 0.5vmin, #9980FA 0) 50% 99%,
    radial-gradient(circle, #12CBC4 0.2vmin, #9980FA 0) 99% 50%,
    radial-gradient(circle, #FDA7DF 0.3vmin, #833471 0) 80% 90%,
    radial-gradient(circle, #ED4C67 0.5vmin, #EA2027 0) 95% 90%,
    radial-gradient(circle, #F79F1F 0.5vmin, #009432 0) 10% 60%,
    radial-gradient(circle, #A3CB38 0.2vmin, #1B1464 0) 31% 80%,
    radial-gradient(circle, #1289A7 0.3vmin, #5758BB 0) 80% 10%,
    radial-gradient(circle, #D980FA 0.2vmin, #6F1E51 0) 90% 23%,
    radial-gradient(circle, #B53471 0.3vmin,#FDA7DF 0) 45% 20%,
    radial-gradient(circle, #EE5A24 0.5vmin, #B53471 0) 13% 24%
    ;
  background-size: 0.5vmin 0.5vmin;
  background-repeat: no-repeat;
  animation: ${firework} 3s linear infinite;

  &:before{
    --top: 60vh;
  content: "";
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background:
    /* random backgrounds */
    radial-gradient(circle, #ff0 0.2vmin,    #009432 0) 50% 00%,
    radial-gradient(circle, #FFC312 0.3vmin, #0652DD 0) 00% 50%,
    radial-gradient(circle,#C4E538 0.5vmin, #9980FA 0) 50% 99%,
    radial-gradient(circle, #12CBC4 0.2vmin, #9980FA 0) 99% 50%,
    radial-gradient(circle, #FDA7DF 0.3vmin, #833471 0) 80% 90%,
    radial-gradient(circle, #ED4C67 0.5vmin, #EA2027 0) 95% 90%,
    radial-gradient(circle, #F79F1F 0.5vmin, #009432 0) 10% 60%,
    radial-gradient(circle, #A3CB38 0.2vmin, #1B1464 0) 31% 80%,
    radial-gradient(circle, #1289A7 0.3vmin, #5758BB 0) 80% 10%,
    radial-gradient(circle, #D980FA 0.2vmin, #6F1E51 0) 90% 23%,
    radial-gradient(circle, #B53471 0.3vmin,#FDA7DF 0) 45% 20%,
    radial-gradient(circle, #EE5A24 0.5vmin, #B53471 0) 13% 24%
    ;
  background-size: 0.5vmin 0.5vmin;
  background-repeat: no-repeat;
  animation: ${firework} 5s ease-in-out infinite;
   transform: translate(-50%, -50%) rotate(25deg) !important; 
  }
  &:after{
    --top: 60vh;
  content: "";
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background:
    /* random backgrounds */
    radial-gradient(circle, #ff0 0.2vmin,    #009432 0) 50% 00%,
    radial-gradient(circle, #FFC312 0.3vmin, #0652DD 0) 00% 50%,
    radial-gradient(circle,#C4E538 0.5vmin, #9980FA 0) 50% 99%,
    radial-gradient(circle, #12CBC4 0.2vmin, #9980FA 0) 99% 50%,
    radial-gradient(circle, #FDA7DF 0.3vmin, #833471 0) 80% 90%,
    radial-gradient(circle, #ED4C67 0.5vmin, #EA2027 0) 95% 90%,
    radial-gradient(circle, #F79F1F 0.5vmin, #009432 0) 10% 60%,
    radial-gradient(circle, #A3CB38 0.2vmin, #1B1464 0) 31% 80%,
    radial-gradient(circle, #1289A7 0.3vmin, #5758BB 0) 80% 10%,
    radial-gradient(circle, #D980FA 0.2vmin, #6F1E51 0) 90% 23%,
    radial-gradient(circle, #B53471 0.3vmin,#FDA7DF 0) 45% 20%,
    radial-gradient(circle, #EE5A24 0.5vmin, #B53471 0) 13% 24%
    ;
  background-size: 0.5vmin 0.5vmin;
  background-repeat: no-repeat;
  animation: ${firework} 7s ease infinite;
    transform: translate(-50%, -50%) rotate(-37deg) !important;
  }
`

export const IMG_ASEAN= styled.img`
    width:100px;
    height:100px;
    box-shadow:1px 1px 1px 2px #e67e22;
    border-radius:10px;
`
export const IMG_CUP_ASEAN = styled.img`
    height:131px;
    position:absolute;
    left:-22px;
    top:-3px;
`
export const DivNumLeft = styled.div`
    width:70px;
    height:70px;
    position:relative;
    right:22px;
    @media (max-width:768px){
        left:55px;
        top:231px;
    } 
`
export const NumLeft=styled.span`
      position:absolute;
      color:#e67e22;
      font-weight:500;
      font-size:20px;
      left: 8px;
    top: 20px;

`
export const NumLeftCha = styled.span`
      position:absolute;
      color:#e67e22;
      font-weight:500;
      font-size:20px;
      left: 21px;
      top: 45px;
`
export const DivNumRight = styled.div`
    width:70px;
    height:70px;
    position:relative;
    @media (max-width:768px){
        left:99px;
        top:231px;
    } 
`
export const NumRight=styled.span`
      position:absolute;
      color:#e67e22;
      font-weight:500;
      font-size:20px;
      left: 8px;
      top: 20px;
`
export const NumRightCha = styled.span`
      position:absolute;
      color:#e67e22;
      font-weight:500;
      font-size:20px;
      left: 11px;
      top: 45px;
`

export const DivCup = styled.div`
    width:100px;
    height:100px;
    position:relative;
    left:-12px;
    @media (max-width:768px){
        left:77px;
        top:231px;
    }    
`

