import {LIST_MANAGER_ACCOUNT_ACTIVE,DISPATCH_LIST_MANAGER_ACCOUNT_ACTIVE_TO_STORE,DISPATCH_ADD_LIST_MANAGER_ACCOUNT_TO_STORE,
    CLOSE_FORM_ACTIVE_MANAGER_ACCOUNT,DISPATCH_LIST_CLASS_ACCOUNT_APPROVAL_TO_STORE,
    DISPATCH_SEND_SOCKET_CHECK_FOOD_TO_MANAGER_ACCOUNT,
    UPDATE_APPROVAL_SUCCESS,DATE_INPUT,DISPATCH_ITEM_MANAGER_ACCOUNT_BY_USER_ID,DISPATCH_LIST_CLASS_CHECK_DAY_FOOD_BY_DATE,
    OPEN_FORM_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE,CLOSE_FORM_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE,
    OPEN_ALERT_FORM_CHECK_DAY_FOOD,CLOSE_ALERT_FORM_CHECK_DAY_FOOD,ACCEPT_UPDATE_APPROVAL_FROM_CLASS,
    DISPATCH_GET_INFO_LIST_OF_USERID_TO_STORE,OPEN_ALERT_LIST_CLASS_NOT_CHECK_DAY_FOOD,DISPATCH_LIST_CLASS_STUDENT_LEAVE_TEMPORATY_TO_STORE,
    DISPATCH_LIST_CHECK_DAY_FOOD_BY_MONTH,DISPATCH_LIST_STU_SEND_MESS_TO_STORE,
    DISPATCH_ADD_STU_SEND_MESS_MANAGER_ACOUNT_TO_STORE,DISPATCH_ITEM_INFO_MESSAGE_AUTO_TO_STORE,
    UPLOAD_LIST_STUDENT_SEND_SMS_ZALO,DISPATCH_FIND_ITEM_CLASS_SEND_SMS_ZALO_PRV_MONTH,DISPATCH_ITEM_KHOAN_THU_TO_STORE,
    DISPATCH_ADD_KHOAN_THU_TO_STORE,DISPATCH_ITEM_STUDENT_REGIS_KHOAN_THU_TO_STORE
} from '../contant/ManagerAccount'
import {DISPATCH_ACTIVE_ALL_ACCOUNT_ALL_TO_STORE,DISPATCH_GET_LIST_ACTIVE_ACCOUNT_TO_STORE,DISPATCH_ITEM_MANAGER_ACCOUNT_CLASS_ACTIVE_TO_STORE,
OPEN_FORM_EMPTY_COUNT_EAT,CLOSE_FORM_EMPTY_COUNT_EAT,DISPATCH_ITEM_MENU_WEEKLY_TO_STORE,DISPATCH_ID_CLASS_ACTIVE_TO_STORE,
DISPATCH_ITEM_APPROVAL_CHECK_DAY_FOOD_TO_STORE,DISPATCH_ITEM_APPROVAL_CHECK_DAY_FOOD_TO_STORE_FROM_DB,
DISPATCH_MANAGER_ACCOUNT_RECICVE_REQUEST,
DISPATCH_LIST_LEAVE_TEMPORATY_BY_CLASS_TO_STORE,DISPATCH_ADD_STU_MESS_TO_STORE
}from '../contant/Class'

export const DispatchItemStudentRegisKhoanThuToStore=(item)=>{
  return{
    type:DISPATCH_ITEM_STUDENT_REGIS_KHOAN_THU_TO_STORE,
    payload:{item}
  }
}

export const DispatchAddKhoanThuToStore=(item)=>{
  return{
    type:DISPATCH_ADD_KHOAN_THU_TO_STORE,
    payload:{item}
  }
}

export const DispatchItemKhoanThuToStore=(item)=>{
    return{
      type:DISPATCH_ITEM_KHOAN_THU_TO_STORE,
      payload:{item}
    }
}

export const DispatchFindItemClassSendSmsZaloPrvMonth=(item)=>{
    return{
      type:DISPATCH_FIND_ITEM_CLASS_SEND_SMS_ZALO_PRV_MONTH,
      payload:{item}
    }
}

export const UploadListStudentSendSmsZalo=(item)=>{
  return{
    type:UPLOAD_LIST_STUDENT_SEND_SMS_ZALO,
    payload:{item}
  }
}

export const DateInput=(item)=>{
    return{
      type:DATE_INPUT,
      payload:{item}
    }
}

export const DispatchIdClassActiveToStore=(item)=>{
    return{
      type:DISPATCH_ID_CLASS_ACTIVE_TO_STORE,
      payload:{item}
    }
}

export const ListManagerAccountActive = (item) => {
    return{
      type:LIST_MANAGER_ACCOUNT_ACTIVE,
      payload:{item}
    }
}

export const DispatchListManagerAccountActiveToStore=(item)=>{
  return{
    type:DISPATCH_LIST_MANAGER_ACCOUNT_ACTIVE_TO_STORE,
    payload:{item}
  }
}

export const DispatchAddListManagerAccountToStore=(item)=>{
    return{
      type:DISPATCH_ADD_LIST_MANAGER_ACCOUNT_TO_STORE,
      payload:{item}
    }
}

export const CloseFormActiveManagerAccount=()=>{
    return{
      type:CLOSE_FORM_ACTIVE_MANAGER_ACCOUNT
    }
}

export const DispatchActiveAllAccountAllToStore =(item)=>{
  return{
    type:DISPATCH_ACTIVE_ALL_ACCOUNT_ALL_TO_STORE,
    payload:{item}
  }
}

export const DispatchGetListActiveAccountToStore=(item)=>{
    return{
      type:DISPATCH_GET_LIST_ACTIVE_ACCOUNT_TO_STORE,
      payload:{item}
    }
}
export const DispatchItemManagerAccountClassActiveToStore =(item)=>{
    return{
      type:DISPATCH_ITEM_MANAGER_ACCOUNT_CLASS_ACTIVE_TO_STORE,
      payload:{item}
    }
}

export const OpenFormEmptyCountEat=()=>{
    return {
      type:OPEN_FORM_EMPTY_COUNT_EAT
    }
}
export const CloseFormEmptyCountEat=()=>{
  return{
    type:CLOSE_FORM_EMPTY_COUNT_EAT
  }
}
export const DispatchItemMenuWeeklyToStore =(item)=>{
    return{
      type:DISPATCH_ITEM_MENU_WEEKLY_TO_STORE,
      payload:{item}
    }
}
export const DispatchItemApprovalCheckDayFoodToStore=(item)=>{
    return{
        type:DISPATCH_ITEM_APPROVAL_CHECK_DAY_FOOD_TO_STORE,
        payload:{item}
    }
}
export const DispatchItemApprovalCheckDayFoodToStoreFromDb=(item)=>{
    return{
      type:DISPATCH_ITEM_APPROVAL_CHECK_DAY_FOOD_TO_STORE_FROM_DB,
      payload:{item}
    }
}
export const DispatchListClassAccountApprovalToStore=(item)=>{
    return{
      type:DISPATCH_LIST_CLASS_ACCOUNT_APPROVAL_TO_STORE,
      payload:{item}
    }
}
export const DispatchSendSocketCheckFoodToManagerAccount=(item)=>{
  return{
    type:DISPATCH_SEND_SOCKET_CHECK_FOOD_TO_MANAGER_ACCOUNT,
    payload:{item}
  }
}
export const UpdateApprovalSuccess=()=>{
  return{
    type:UPDATE_APPROVAL_SUCCESS
  }
}

export const DispatchItemManagerAccountByUserId=(item)=>{
  return{
    type:DISPATCH_ITEM_MANAGER_ACCOUNT_BY_USER_ID,
    payload:{item}
  }
}

export const DispatchManagerAccountRecicveRequest=(item)=>{
    return{
      type:DISPATCH_MANAGER_ACCOUNT_RECICVE_REQUEST,
      payload:{item}
    }
}
export const DispatchListClassCheckDayFoodByDate=(item)=>{
    return{
      type:DISPATCH_LIST_CLASS_CHECK_DAY_FOOD_BY_DATE,
      payload:{item}
    }
}
export const OpenAlertCheckDayFood=()=>{
  return{
    type:OPEN_ALERT_FORM_CHECK_DAY_FOOD
  }
}
export const CloseAlertCheckDayFood=()=>{
  return{
    type:CLOSE_ALERT_FORM_CHECK_DAY_FOOD
  }
}

export const OpenFormCheckDayFoodByUseIdAndDate=()=>{
  return {
    type:OPEN_FORM_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE
  }
}
export const CloseFormCheckDayFoodByUseIdAndDate=()=>{
  return {
    type:CLOSE_FORM_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE
  }
}

export const AcceptUpdateApprovalFromClass =(item)=>{
  return{
    type:ACCEPT_UPDATE_APPROVAL_FROM_CLASS,
    payload:{item}
  }
}

export const DispatchGetInfoListOfUserIdToStore =(item)=>{
    return{
      type:DISPATCH_GET_INFO_LIST_OF_USERID_TO_STORE,
      payload:{item}
    }
}

export const OpenAlertListClassNotCheckDayFood =(item)=>{
    return{
      type:OPEN_ALERT_LIST_CLASS_NOT_CHECK_DAY_FOOD,
      payload:{item}
    }
}

export const DispatchListLeaveTemporatyByClassToStore=(item)=>{
  return{
    type:DISPATCH_LIST_LEAVE_TEMPORATY_BY_CLASS_TO_STORE,
    payload:{item}
  }
}
export const DispatchListClassStudentLeaveTemporatyToStore=(item)=>{
  return{
    type:DISPATCH_LIST_CLASS_STUDENT_LEAVE_TEMPORATY_TO_STORE,
    payload:{item}
  }
}

export const DispatchListCheckDayFoodByMonth=(item)=>{
  return{
    type:DISPATCH_LIST_CHECK_DAY_FOOD_BY_MONTH,
    payload:{item}
  }
}

export const DispatchAddStuSendMessToStore=(item)=>{
  return{
    type:DISPATCH_ADD_STU_MESS_TO_STORE,
    payload:{item}
  }
}

export const DispatchListStuSendMessToStore=(item)=>{
  return{
    type:DISPATCH_LIST_STU_SEND_MESS_TO_STORE,
    payload:{item}
  }
}

export const DispatchAddStuSendMessManagerAcountToStore=(item)=>{
  return{
    type:DISPATCH_ADD_STU_SEND_MESS_MANAGER_ACOUNT_TO_STORE,
    payload:{item}
  }
}

export const DispatchItemInfoMessageAutoToStore=(item)=>{
  return{
    type:DISPATCH_ITEM_INFO_MESSAGE_AUTO_TO_STORE,
    payload:{item}
  }
}

