import { showDateName, showDDMM } from "../../helper/dateTime/handleDMY"
const THUBAY= 'thứ bảy'
const CHUNHAT = 'chủ nhật'
export const OnlineFake=(date)=>{
    let numDate = `${date}`.length
    let num1 = `${date}`.charAt(4)
    let num2 = `${date}`.charAt(7)
    let num3 = `${date}`.charAt(numDate-1)
    let num4 =  `${date}`.charAt(numDate-2)
    let result = `${num1}${num2}${num3}${num4}`   
    let dateIn = showDateName(date)
    if(dateIn ===THUBAY||dateIn===CHUNHAT){
        return `${num2}${num4}${num1}`   
    }
    if(showDDMM(date)==='30/04'||showDDMM(date)==='01/05'||showDDMM(date)==='02/09'){
        return `${num2}${num4}`
    }
     return Number(result)-(1999/9).toFixed(0)
}