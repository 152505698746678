import {ADD_BLOCK_TO_STORE,LIST_TABLE_BLOCK,DISPATCH_ADD_CLASS_TO_STORE
  ,DISPATCH_LIST_CLASS_TO_STORE,ADD_CLASS_SUCCESS,
  LIST_ALL_STUDENT,DISPATCH_LIST_ALL_STUDENT_TO_STORE,
  DISPATCH_ADD_STUDENT_TO_STORE,UPLOAD_FILE_EXCEL,CLOSE_FORM_IMPORT_EXCEL, 
    UPDATE_ARR_AFTER_UPLOAD_EXCEL,DISPATCH_LIST_YEAR_SCHOOL,OPEN_FORM_ADD_YEAR_SCHOOL,
  DISPATCH_ADD_YEAR_SCHOOL_TO_STORE,DISPATCH_LIST_YEAR_SCHOOL_TO_STORE,ADD_YEAR_SCHOOL_SUCCESS,
  OPEN_FORM_SELECT,DISPATCH_ADD_CHECK_DATE_FOOD,DISPATCH_LIST_CHECK_DATE_FOOD,UPDATE_CHECK_DATE_FOOD_SUCCESS,
  SEND_IDS_TO_DB_ADD_EMPTY_NOT_EAT,DISPATCH_ARRAY_STUDENT_NOT_EAT,DISPATCH_ADD_STUDENT_REGIS_SEVEN_DAY_TO_STORE,
  DISPATCH_LIST_REGIS_SEVEN_TO_STORE,UPDATE_REGIS_SEVEN_DAY_SUCCESS,DISPATCH_ADD_LIST_STUDENT_NOW_TO_STORE,
  DISPATCH_ALL_LIST_STUDENT_NOW,DISPATCH_STUDENT_STUDENT_NOW_TO_STORE,DELETE_ITEM_STUDENT_NOW_SUCCESS,
  UPDATE_ALERT_SUCCESS_LOGOUT,DISPATCH_STUDENT_LEAVE_TEMPORATY_TO_STORE, ADD_STUDENT_LEAVE_TEMPORATY_TO_STORE,
  UPDATE_STUDENT_LEAVE_TEMPORATY_AGAIN,GET_STUDENT_LEAVE_TEMPORATY_NOT_BY_DATE_NOW_TO_STORE, DISPATCH_ADD_MANY_CHECK_DATE_FOOD,
  
  
} from '../../../contant/Gov/student/dispatch'
import {DISPATCH_ADD_TRANSFER_LEFT_OVER_TO_STORE,CLOSE_FORM_TRANSFER_LEFT_OVER,DISPATCH_LIST_TRANSFER_TO_STORE,DELETE_TRANSFER_LEFT_SUCCESS, DISPATCH_ADD_TRANSFER_LEFT_OVER_TO_STORE_TH, CLOSE_FORM_TRANSFER_LEFT_OVER_TH, DISPATCH_LIST_TRANSFER_TO_STORE_TH, DELETE_TRANSFER_LEFT_SUCCESS_TH,
   ADD_BUFFET_TO_STORE,LIST_BUFFET_TO_STORE,WHEN_LIST_ALL_YEAR_SCHOOL_0,WHEN_LIST_ALL_YEAR_SCHOOL_1,WHEN_LIST_ALL_YEAR_SCHOOL_MORE_1,
   DISPATCH_ADD_UPDATE_DAILY_TUI_TO_STORE,DISPATCH_LIST_UPDATE_DAILY_TUI   
  } from '../../../contant/Gov/countEat/dispatch'
import { DISPATCH_ADD_DELETE_ITEM_STUDENT_INFO } from '../../../contant/Gov/softFood'

export const DispatchAddDeleteItemStudentInfo=(item)=>{
  return{
    type:DISPATCH_ADD_DELETE_ITEM_STUDENT_INFO,
    payload:{item}
  }
}

export const addBlockToStore = (item)=>{
  return{
    type:ADD_BLOCK_TO_STORE,
    payload:{item}
  }
}

export const ListTableBlock = (arr)=>{
  return{
    type:LIST_TABLE_BLOCK,
    payload:{arr}
  }
}

export const dispatchAddClassToStore = (arr)=>{  
  return {
    type:DISPATCH_ADD_CLASS_TO_STORE,
    payload:{arr}
  }
}
export const dispatchListClassToStore =(arr)=>{
  return{
    type:DISPATCH_LIST_CLASS_TO_STORE,
    payload:{arr}
  }
}

export const DispatchListUpdateDailyTui=(item)=>{
  return{
    type:DISPATCH_LIST_UPDATE_DAILY_TUI,
    payload:{item}
  }
}

export const addClassSuccess =()=>{
  return{
    type:ADD_CLASS_SUCCESS
  }
}

export const listAllStudent = ()=>{
  return {
    type:LIST_ALL_STUDENT   
  }
}
export const dispatchListAllStudentToStore =(arr)=>{  
  return{
    type:DISPATCH_LIST_ALL_STUDENT_TO_STORE,
    payload:{arr}
  }
}

export const dispatchAddStudentToStore =(item)=>{
  return{
    type:DISPATCH_ADD_STUDENT_TO_STORE,
    payload:{item}
  }
}

export const dispatchStudentStudentNowToStore=(item)=>{
    return{
      type:DISPATCH_STUDENT_STUDENT_NOW_TO_STORE,
      payload:{item}
    }
}
export const uploadFileExcel = (result)=>{
  return{
    type:UPLOAD_FILE_EXCEL,
    payload:{result}
  }
}

export const closeFormImportExcel = ()=>{
  return{
    type:CLOSE_FORM_IMPORT_EXCEL
  }
}
export const updateArrAfterUploadExcel =(arr)=>{
  return{
    type:UPDATE_ARR_AFTER_UPLOAD_EXCEL,
    payload:{arr}
  }
}

export const dispatchListYearSchool = (arr)=>{
  return{
    type:DISPATCH_LIST_YEAR_SCHOOL,
    payload:{arr}
  }
}

export const openFormAddYearSchool = ()=>{
  return{
    type:OPEN_FORM_ADD_YEAR_SCHOOL  
  }
}
export const addYearSchoolSuccess = ()=>{
    return {
      type:ADD_YEAR_SCHOOL_SUCCESS
    }
}
export const dispatchAddYearSchoolToStore =(item)=>{
  return {
      type:DISPATCH_ADD_YEAR_SCHOOL_TO_STORE,
      payload:{item}
  }
}
export const dispatchListYearSchoolToStore =(arr)=>{
  return{
    type:DISPATCH_LIST_YEAR_SCHOOL_TO_STORE,
    payload:{arr}
  }
}
export const openFormSelect  =()=>{
  return{
    type:OPEN_FORM_SELECT
  }
}
export const dispatchAddCheckDateFood=(item)=>{
  return{
    type:DISPATCH_ADD_CHECK_DATE_FOOD,
    payload:{item}
  }
}
export const dispatchAddManyCheckDateFood=(item)=>{
  return{
    type:DISPATCH_ADD_MANY_CHECK_DATE_FOOD,
    payload:{item}
  }
}
export const dispatchListCheckDateFood =(arr)=>{
  return{
    type:DISPATCH_LIST_CHECK_DATE_FOOD,
    payload:{arr}
  }
}
export const updateCheckDateFoodSuccess = ()=>{
  return{
    type:UPDATE_CHECK_DATE_FOOD_SUCCESS
  }
}

export const   SendIdsToDbAddEmptyNotEat = (item) =>{
    return {
      type:SEND_IDS_TO_DB_ADD_EMPTY_NOT_EAT,
      payload:{item}
    }
}

export const DispatchArrayStudentNotEat = (arr)=>{
  return{
    type:DISPATCH_ARRAY_STUDENT_NOT_EAT,
    payload:{arr}
  }
}
export const DispatchAddStudentRegisSevenDayToStore=(item)=>{
    return{
      type:DISPATCH_ADD_STUDENT_REGIS_SEVEN_DAY_TO_STORE,
      payload:{item}
    }
}
export const DispatchListRegisSevenToStore=(arr)=>{
    return{
      type:DISPATCH_LIST_REGIS_SEVEN_TO_STORE,
      payload:{arr}
    }
}
export const UpdateRegisSevenDaySuccess=()=>{
  return{
    type:UPDATE_REGIS_SEVEN_DAY_SUCCESS
  }
}

export const DispatchAddListStudentNowToStore=(item)=>{
    return {
      type:DISPATCH_ADD_LIST_STUDENT_NOW_TO_STORE,
      payload:{item}
    }
}
export const DispatchAllListStudentNow=(arr)=>{
  return{
    type:DISPATCH_ALL_LIST_STUDENT_NOW,
    payload:{arr}
  }
}
export const DeleteItemStudentNowSuccess=()=>{
  return{
    type:DELETE_ITEM_STUDENT_NOW_SUCCESS
  }
}

//mam non
export const DispatchAddTransferLeftOverToStore=(item)=>{
    return{
      type:DISPATCH_ADD_TRANSFER_LEFT_OVER_TO_STORE,
      payload:{item}
    }
}
export const CloseFormTransferLeftOver=()=>{
    return{
      type:CLOSE_FORM_TRANSFER_LEFT_OVER
    }
}
export const DispatchListTranferToStore=(item)=>{
    return{
      type:DISPATCH_LIST_TRANSFER_TO_STORE,
      payload:{item}
    }
}
export const DeleteTransferLeftSuccess=()=>{
  return{
    type:DELETE_TRANSFER_LEFT_SUCCESS
  }
}

//tieu hoc

export const DispatchAddTransferLeftOverToStoreTh=(item)=>{
  return{
    type:DISPATCH_ADD_TRANSFER_LEFT_OVER_TO_STORE_TH,
    payload:{item}
  }
}
export const CloseFormTransferLeftOverTh=()=>{
  return{
    type:CLOSE_FORM_TRANSFER_LEFT_OVER_TH
  }
}
export const DispatchListTranferToStoreTh=(item)=>{
  return{
    type:DISPATCH_LIST_TRANSFER_TO_STORE_TH,
    payload:{item}
  }
}
export const DeleteTransferLeftSuccessTh=()=>{
    return{
      type:DELETE_TRANSFER_LEFT_SUCCESS_TH
      }
}

export const AddBuffetToStore=(item)=>{
  return{
    type:ADD_BUFFET_TO_STORE,
    payload:{item}
  }
}

export const ListBuffetToStore=(item)=>{
  return{
    type:LIST_BUFFET_TO_STORE,
    payload:{item}
  }
}

export const UpdateAlertSuccessLogout=()=>{
    return{
      type:UPDATE_ALERT_SUCCESS_LOGOUT
    }
}

export const DispatchStudentLeaveTemporatyToStore=(item)=>{
  return{
    type:DISPATCH_STUDENT_LEAVE_TEMPORATY_TO_STORE,
    payload:{item}
  }
}
export const AddStudentLeaveTemporatyToStore=(item)=>{
  return{
    type:ADD_STUDENT_LEAVE_TEMPORATY_TO_STORE,
    payload:{item}
  }
}

export const UpdateStudentLeaveTemporatyAgain=(item)=>{
    return{
      type:UPDATE_STUDENT_LEAVE_TEMPORATY_AGAIN,
      payload:{item}
    }
}

export const GetStudentLeaveTemporatyNotByDateNowToStore=(item)=>{
  return{
    type:GET_STUDENT_LEAVE_TEMPORATY_NOT_BY_DATE_NOW_TO_STORE,
    payload:{item}
  }
}

export const WhenListAllYearSchool_0 =()=>{
  return {
    type:WHEN_LIST_ALL_YEAR_SCHOOL_0
  }
}

export const WhenListAllYearSchool_1 =(item)=>{
  return {
    type:WHEN_LIST_ALL_YEAR_SCHOOL_1,
    payload:{item}
  }
}

export const WhenListAllYearSchoolMore1 =(item)=>{
  return {
    type:WHEN_LIST_ALL_YEAR_SCHOOL_MORE_1,
    payload:{item}
  }
}

export const DispatchAddUpdateDailyTuiToStore=(item)=>{
  return{
    type:DISPATCH_ADD_UPDATE_DAILY_TUI_TO_STORE,
    payload:{item}
  }
}