import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {useDispatch} from 'react-redux'
import { CLOSE_UPDATE_APP_ZALO_ACCESS_TOKEN,  UPDATE_APP_ZALO_ACCESS_TOKEN } from '../NotFoundPage/contant/Zalo'
import { Delete } from '@material-ui/icons'

export default function Index({open,handleClose,value}) {
        const dispatch                                  = useDispatch()        
        const [nameApp,setNameApp]                      = useState(value.nameApp)
        const [domain,setDomain]                        = useState(value.domain)
        const [callBackUrlDomain,setCallBackUrlDomain]  = useState(value.callBackUrlDomain)
        const [app_id,setAppid]             = useState(value.app_id)
        const [secret_key,setSecretkey]     = useState(value.secret_key)
        const [access_token,setAccessToken] = useState(value.access_token)
        const [refresh_token,setRefreshToken] = useState(value.refresh_token)
        const [urlRequestAccessToken,setUrlRequestAccessToken] = useState(value.urlRequestAccessToken)

        const CreateApp=()=>{
            let result={
                urlRequestAccessToken,
                nameApp,
                domain,
                callBackUrlDomain,
                app_id,
                secret_key,
                access_token,
                refresh_token,
                _id:value._id
            }
            if(result.nameApp===null||result.domain===null||result.callBackUrlDomain===null||result.app_id===null||result.secret_key===null||result.access_token===null||result.refresh_token===null) {
                alert('Dữ liệu Thiếu! nhập lại')
                return ()=>{}
            }
            dispatch({type:CLOSE_UPDATE_APP_ZALO_ACCESS_TOKEN})
            dispatch({type:UPDATE_APP_ZALO_ACCESS_TOKEN,payload:{item:result}})
            return ()=>{}
        }
  return (
    <div>      
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Cập nhật Ứng Dụng Zalo Access Token V4</DialogTitle>
        <DialogContent>      
            <TextField
            onChange={(e)=>setUrlRequestAccessToken(e.target.value)}
            margin="dense"
            label="urlRequestAccessToken"
            type="text"
            fullWidth
            size="small"
            defaultValue={urlRequestAccessToken}
            />  
           <TextField
            onChange={(e)=>setNameApp(e.target.value)}
            margin="dense"
            label="Tên Ứng Dụng"
            type="text"
            fullWidth
            size="small"
            defaultValue={nameApp}
            />  
          <TextField
            onChange={(e)=>setDomain(e.target.value)}
            margin="dense"
            label="Domain"
            type="text"
            fullWidth
            size="small"
            defaultValue={domain}
          />  

          <TextField
            onChange={(e)=>setCallBackUrlDomain(e.target.value)}
            margin="dense"
            label="CallBackDomain"
            type="text"
            fullWidth
            size="small"
            defaultValue={callBackUrlDomain}
          />            
          <TextField
            onChange={(e)=>setAppid(e.target.value)}
            margin="dense"
            label="app_id"
            type="text"
            fullWidth
            size="small"
            defaultValue={app_id}
          />
           <TextField
            onChange={(e)=>setSecretkey(e.target.value)}
            margin="dense"
            label="secret_key"
            type="text"
            fullWidth
            size="small"          
            defaultValue={secret_key}
            />

           <TextField
            onChange={(e)=>setAccessToken(e.target.value)}
            margin="dense"
            label="access_token"
            type="text"
            fullWidth
            size="small"
            defaultValue={access_token}
            /><Delete/>
           <TextField
            onChange={(e)=>setRefreshToken(e.target.value)}
            margin="dense"
            label="refresh_token"
            type="text"
            fullWidth
            size="small"
            defaultValue={refresh_token}
          /><Delete/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={()=>CreateApp()} color="primary">
            Cập nhật
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
