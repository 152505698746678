import React, { Component } from 'react';
import {withStyles} from '@material-ui/styles'
import style from './style'
import {Card,
        CardContent,
        Typography,
        Button,
        Box,
} from '@material-ui/core'
import {Field,reduxForm} from 'redux-form'
import TextField from '../../../component/formHelper/TextField'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {bindActionCreators,compose} from 'redux'
import validate from './validate'
import * as Users from '../../../actions/users'
import { IMGCenter, LogoImg } from '../GioiDinhTue/component/style/MainCss/content_us';
import LogoGDT from '../GioiDinhTue/component/assest/img/gdt.jpg'
import {Helmet} from 'react-helmet-async';
import { MainDiv, SeiAward, Wave, Wave1, Wave2 } from './styled';


class Signup extends Component {

  handleSubmitForm=(data)=>{      
         let {getActions}   = this.props
         let {ResetAccountPassword} = getActions
          ResetAccountPassword(data)   
  }
    render() {
      let {classes,handleSubmit,invalid,submitting} = this.props
    return (
      <MainDiv 
      // className={classes.background}
      >
                <Helmet>
                    <title>Phần Mềm Dinh Dưỡng KidTop</title>
                    <meta
                        name="description"
                        content="Phần Mềm Dinh Dưỡng"
                        charSet="utf-8"
                    />
                    <link rel="canonical" href="http://huythanhicare.com"/>
                </Helmet>
      <div className={classes.wrapper}>
      <Card style={{backgroundImage:'linear-gradient(#34495e,#1abc9c,#27ae60,#1abc9c,#34495e'}}>
          <CardContent>
            <form onSubmit={handleSubmit(this.handleSubmitForm)} autoComplete="off">
              <div className="text-xs-center pb-xs">               
                <Typography variant="caption">
                    Khôi Phục Tài Khoản
                </Typography>
              </div>
              <Field
                className={classes.textField}
                fullWidth
                label="Tên account"
                margin="normal"
                name="name"
                component={TextField}
                type="text"                
              />
              <Field
                className={classes.textField}
                fullWidth
                label="Email của bạn"
                margin="normal"
                name="email"
                component={TextField}
                type="email"
                autoComplete="email"
              />
              
              <Button
                disabled={invalid || submitting}
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Gửi
              </Button>   
              <div style={{marginTop:10}} className="pt-1 text-md-center">                      
                       <Link style={{textDecoration:'none'}} to="/login">
                         <Button style={{color:'#ecf0f1'}}>Đăng nhập</Button>
                       </Link>
              </div>         
              <Box onClick={()=>{window.location='http://huythanhicare.com'}} style={{marginTop:10,cursor:'pointer'}} display="flex" justifyContent="center">
                    <SeiAward>SEI AWARD EDUTECH</SeiAward>
              </Box>
            </form>
          </CardContent>
        </Card>
      </div>
      <a style={{textDecoration:'none'}} href="https://cdgdt.com"> 
                  <div style={{
                      marginLeft:'auto',
                      marginRight:'auto',
                      width:'100%',
                      marginTop:'170px'
                    }}>                            
                                <LogoImg>
                                    <IMGCenter src={LogoGDT} alt="cong-dong-gioi-dinh-tue"/>
                                </LogoImg>                            
                  </div>  
      </a>
          <Wave2></Wave2>
           <Wave></Wave>
           <Wave1></Wave1>   
    </MainDiv>
    );
  }
}

const FORM_NAME = 'SIGNUP'
const withForm =reduxForm({
  form:FORM_NAME,
  validate
})

const mapStateToProps = state =>{
  return{

  }
}

const mapDispatchStateToProps = (dispatch)=>{
  return{
      getActions : bindActionCreators(Users,dispatch)
  }
}

const withConnect = connect(mapStateToProps,mapDispatchStateToProps)

export default compose(
  withStyles(style),
  withForm,
  withConnect
)(Signup)