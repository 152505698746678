import {put, takeLatest} from 'redux-saga/effects'
import {DISPATCH_SELECT_REPO}from '../../Contant/Person/cateRepo'
import {OpenSelectRepo}from '../../Actions/cateRepo'


function* DispatchSelectRepoSaga(){
    yield put(OpenSelectRepo())
}

function* CateRepo(){
    yield takeLatest(DISPATCH_SELECT_REPO,DispatchSelectRepoSaga)
}


export default CateRepo