const validate = values => {
  const errors = {};
  // if (!values.email) {
  //   errors.email = 'Email không được bỏ trống';
  // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
  //   errors.email = 'Email không hợp lệ';
  // }
  if (!values.password) {
    errors.password = 'Mật khẩu không được bỏ trống';
  } else if (values.password.trim().length < 6) {
    errors.password = 'Mật khẩu phải từ 6 ký tự trở lên';
  }
  if (!values.confirmpassword) {
    errors.confirmpassword = 'Mật khẩu không được bỏ trống';
  } else if (values.confirmpassword.trim().length < 6) {
    errors.confirmpassword = 'Mật khẩu phải từ 6 ký tự trở lên';
  } else if (values.confirmpassword !== values.password) {
    errors.confirmpassword = 'Mật khẩu không trùng khớp';
  }
  if (!values.isAccept) {
    errors.isAccept = 'Bắt buộc';
  }
  if(!values.idGroup){
    errors.idGroup = 'Quý khách chưa chọn nhóm để đăng nhập'
  }
  if(!values.name){
    errors.name = 'Tên không được để trống'
  }else if(values.name.trim().length<6){
      errors.name ='Tên phải từ 6 ký tự trở lên...' 
    }
  return errors;
};

export default validate;
