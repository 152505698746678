export const CREATE_APP_WEB_AUTO            = "CREATE_APP_WEB_AUTO"
export const DISPATCH_ITEM_APP_WEB_TO_STORE = "DISPATCH_ITEM_APP_WEB_TO_STORE"

export const GET_WEB_AUTO_INFO              = "GET_WEB_AUTO_INFO"
export const UPDATE_WEB_AUTO_INFO           = "UPDATE_WEB_AUTO_INFO"

export const GET_LIST_REGIS_STUDENT_WEB          = "GET_LIST_REGIS_STUDENT_WEB"
export const DISPATCH_LIST_REGIS_STUDENT_WEB     = "DISPATCH_LIST_REGIS_STUDENT_WEB"
export const UPDATE_ITEM_REGIS_STUDENT_WEB      = "UPDATE_ITEM_REGIS_STUDENT_WEB"
export const UPDATE_SUCCESS = "UPDATE_SUCCESS"
export const DELETE_ITEM_REGIS_STUDENT_WEB = "DELETE_ITEM_REGIS_STUDENT_WEB"

export const GET_LIST_GIOI_THIEU_WEB                = "GET_LIST_GIOI_THIEU_WEB" 
export const DISPATCH_LIST_GIOI_THIEU_WEB_TO_STORE  = "DISPATCH_LIST_GIOI_THIEU_WEB_TO_STORE" 
export const UPDATE_GIOI_THIEU_WEB                  = "UPDATE_GIOI_THIEU_WEB"

