export const DATE_INPUT                                      = "DATE_INPUT"
export const LIST_MANAGER_ACCOUNT_ACTIVE                     = "LIST_MANAGER_ACCOUNT_ACTIVE"
export const DISPATCH_LIST_MANAGER_ACCOUNT_ACTIVE_TO_STORE   = "DISPATCH_LIST_MANAGER_ACCOUNT_ACTIVE_TO_STORE"
export const DISPATCH_ADD_LIST_MANAGER_ACCOUNT_TO_DB         = "DISPATCH_ADD_LIST_MANAGER_ACCOUNT_TO_DB"
export const DISPATCH_ADD_LIST_MANAGER_ACCOUNT_TO_STORE      = "DISPATCH_ADD_LIST_MANAGER_ACCOUNT_TO_STORE"
export const DISPATCH_UPDATE_LIST_MANAGER_ACCOUNT            = "DISPATCH_UPDATE_LIST_MANAGER_ACCOUNT"

export const OPEN_FORM_ACTIVE_MANAGER_ACCOUNT           = "OPEN_FORM_ACTIVE_MANAGER_ACCOUNT"
export const CLOSE_FORM_ACTIVE_MANAGER_ACCOUNT          = "CLOSE_FORM_ACTIVE_MANAGER_ACCOUNT"
export const REDIRECT_CLOSE_FORM_ACTIVE_MANAGER_ACCOUNT = "REDIRECT_CLOSE_FORM_ACTIVE_MANAGER_ACCOUNT"

export const DISPATCH_ACTIVE_ACCOUNT_SOCKET             = "DISPATCH_ACTIVE_ACCOUNT_SOCKET"
export const DISPATCH_LIST_STUDEENT_BY_CLASS            = "DISPATCH_LIST_STUDEENT_BY_CLASS"

export const DISPATCH_LIST_CLASS_ACCOUNT_APPROVAL_BY_USER_ID_DATE = "DISPATCH_LIST_CLASS_ACCOUNT_APPROVAL_BY_USER_ID_DATE"
export const DISPATCH_LIST_CLASS_ACCOUNT_APPROVAL_TO_STORE        = "DISPATCH_LIST_CLASS_ACCOUNT_APPROVAL_TO_STORE"

export const DISPATCH_SEND_SOCKET_CHECK_FOOD_TO_MANAGER_ACCOUNT   = "DISPATCH_SEND_SOCKET_CHECK_FOOD_TO_MANAGER_ACCOUNT"
export const DISPATCH_UPDATE_APPROVAL_CHECK_DAY_FOOD              = "DISPATCH_UPDATE_APPROVAL_CHECK_DAY_FOOD"
export const UPDATE_APPROVAL_SUCCESS                              = "UPDATE_APPROVAL_SUCCESS"

export const DISPATCH_ITEM_MANAGER_ACCOUNT_BY_USER_ID             = "DISPATCH_ITEM_MANAGER_ACCOUNT_BY_USER_ID"
export const DISPATCH_REQUEST_MANAGER_CLASS                       = "DISPATCH_REQUEST_MANAGER_CLASS"

export const OPEN_CHECK_DAY_FOOD_CLASS                            = "OPEN_CHECK_DAY_FOOD_CLASS"
export const CLOSE_CHECK_DAY_FOOD_CLASS                           = "CLOSE_CHECK_DAY_FOOD_CLASS"

export const DISPATCH_FIND_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE      = "DISPATCH_FIND_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE"
export const DISPATCH_LIST_CLASS_CHECK_DAY_FOOD_BY_DATE           = "DISPATCH_LIST_CLASS_CHECK_DAY_FOOD_BY_DATE"

export const OPEN_FORM_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE          = "OPEN_FORM_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE"
export const CLOSE_FORM_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE         = "CLOSE_FORM_CHECK_DAY_FOOD_BY_USE_ID_AND_DATE"

export const OPEN_ALERT_FORM_CHECK_DAY_FOOD                       = "OPEN_ALERT_FORM_CHECK_DAY_FOOD"
export const CLOSE_ALERT_FORM_CHECK_DAY_FOOD                      = "CLOSE_ALERT_FORM_CHECK_DAY_FOOD"

export const ACCEPT_UPDATE_APPROVAL_FROM_CLASS                    = "ACCEPT_UPDATE_APPROVAL_FROM_CLASS"

export const DISPATCH_ADD_CHECK_DAY_FOOD_FROM_MANAGER             = "DISPATCH_ADD_CHECK_DAY_FOOD_FROM_MANAGER"

export const DISPATCH_GET_INFO_LIST_OF_USERID                     = "DISPATCH_GET_INFO_LIST_OF_USERID"
export const DISPATCH_GET_INFO_LIST_OF_USERID_TO_STORE            = "DISPATCH_GET_INFO_LIST_OF_USERID_TO_STORE"

export const OPEN_PRINT_CHECK_DAY_FOOD                            = "OPEN_PRINT_CHECK_DAY_FOOD"
export const CLOSE_PRINT_CHECK_DAY_FOOD                           = "CLOSE_PRINT_CHECK_DAY_FOOD"

export const OPEN_ALERT_LIST_CLASS_NOT_CHECK_DAY_FOOD             = "OPEN_ALERT_LIST_CLASS_NOT_CHECK_DAY_FOOD"
export const CLOSE_ALERT_LIST_CLASS_NOT_CHECK_DAY_FOOD            = "CLOSE_ALERT_LIST_CLASS_NOT_CHECK_DAY_FOOD"
export const GET_LIST_CLASS_STUDENT_LEAVE_TEMPORATY               = "GET_LIST_CLASS_STUDENT_LEAVE_TEMPORATY"
export const DISPATCH_LIST_CLASS_STUDENT_LEAVE_TEMPORATY_TO_STORE = "DISPATCH_LIST_CLASS_STUDENT_LEAVE_TEMPORATY_TO_STORE"

export const DISPATCH_LIST_CHECK_DAY_FOOD_BY_MONTH                = "DISPATCH_LIST_CHECK_DAY_FOOD_BY_MONTH"
export const OPEN_PRINT_ALL_CHECK_DAY_FOOD         = "OPEN_PRINT_ALL_CHECK_DAY_FOOD"
export const CLOSE_PRINT_ALL_CHECK_DAY_FOOD        = "CLOSE_PRINT_ALL_CHECK_DAY_FOOD"

export const GET_LIST_STU_SEND_MESS                = "GET_LIST_STU_SEND_MESS"
export const DISPATCH_LIST_STU_SEND_MESS_TO_STORE  = "DISPATCH_LIST_STU_SEND_MESS_TO_STORE"
export const DISPATCH_LIST_STU_SEND_MESS_INTO_MANAGER_ACCOUNT      = "DISPATCH_LIST_STU_SEND_MESS_INTO_MANAGER_ACCOUNT"
export const ADD_ITEM_STU_SEND_MESS_MANAGER_ACOUNT                 = "ADD_ITEM_STU_SEND_MESS_MANAGER_ACOUNT"
export const DISPATCH_ADD_STU_SEND_MESS_MANAGER_ACOUNT_TO_STORE    = "DISPATCH_ADD_STU_SEND_MESS_MANAGER_ACOUNT_TO_STORE"
export const UPDATE_ITEM_LIST_STU_SEND_MESS_MANAGER_ACCOUNT        = "UPDATE_ITEM_LIST_STU_SEND_MESS_MANAGER_ACCOUNT"
export const UPDATE_LIST_STU_SEND_MESS_MANAGET_ACCOUNT = "UPDATE_LIST_STU_SEND_MESS_MANAGET_ACCOUNT"
export const GET_INFO_MESSAGE_AUTO                    = "GET_INFO_MESSAGE_AUTO"
export const DISPATCH_ITEM_INFO_MESSAGE_AUTO_TO_STORE = "DISPATCH_ITEM_INFO_MESSAGE_AUTO_TO_STORE"

export const UPDATE_ITEM_INFO_MESSAGE_AUTO_TO_STORE   = "UPDATE_ITEM_INFO_MESSAGE_AUTO_TO_STORE"
export const OPEN_UPDATE_INFO_MESSAGE_AUTO = "OPEN_UPDATE_INFO_MESSAGE_AUTO"
export const CLOSE_UPDATE_INFO_MESSAGE_AUTO = "CLOSE_UPDATE_INFO_MESSAGE_AUTO"

export const STUDENT_INFO_PAYMENT_TO_MANAGER_ACCOUNT  = "STUDENT_INFO_PAYMENT_TO_MANAGER_ACCOUNT"
export const UPDATE_ITEM_STU_SEND_MESS_MANAGER_ACCOUNT= "UPDATE_ITEM_STU_SEND_MESS_MANAGER_ACCOUNT"


export const UPDATE_MANAGER_ACCOUNT_CLASS = "UPDATE_MANAGER_ACCOUNT_CLASS"

export const UPDATE_ITEM_LIST_STU_SEND_MESS_MANAGER_ACCOUNT_AGAIN       =  "UPDATE_ITEM_LIST_STU_SEND_MESS_MANAGER_ACCOUNT_AGAIN"
export const UPDATE_ITEM_LIST_STU_SEND_MESS_MANAGER_ACCOUNT_AGAIN_ARRAY = "UPDATE_ITEM_LIST_STU_SEND_MESS_MANAGER_ACCOUNT_AGAIN_ARRAY"


export const UPLOAD_LIST_STUDENT_SEND_SMS_ZALO         = "UPLOAD_LIST_STUDENT_SEND_SMS_ZALO"

export const DISPATCH_ITEM_LIST_STUDENT_TO_UPLOAD_EXCEL = "DISPATCH_ITEM_LIST_STUDENT_TO_UPLOAD_EXCEL"
export const REMOVE_ITEM_LIST_STUDENT_TO_UPLOAD_EXCEL   = "REMOVE_ITEM_LIST_STUDENT_TO_UPLOAD_EXCEL"

export const FIND_ITEM_CLASS_SEND_SMS_ZALO_PRV_MONTH = "FIND_ITEM_CLASS_SEND_SMS_ZALO_PRV_MONTH"  //tim ds hoc sinh gui tin nhan thang truoc
export const DISPATCH_FIND_ITEM_CLASS_SEND_SMS_ZALO_PRV_MONTH="DISPATCH_FIND_ITEM_CLASS_SEND_SMS_ZALO_PRV_MONTH"


export const ADD_KHOAN_THU = "ADD_KHOAN_THU"
export const GET_KHOAN_THU = "GET_KHOAN_THU"
export const DISPATCH_ITEM_KHOAN_THU_TO_STORE               = "DISPATCH_ITEM_KHOAN_THU_TO_STORE"
export const DISPATCH_ADD_KHOAN_THU_TO_STORE                = "DISPATCH_ADD_KHOAN_THU_TO_STORE"

export const ADD_UPDATE_STUDENT_REGIS_KHOAN_THU             = "ADD_UPDATE_STUDENT_REGIS_KHOAN_THU"
export const GET_STUDENT_REGIS_KHOAN_THU                    = "GET_STUDENT_REGIS_KHOAN_THU"
export const DISPATCH_ITEM_STUDENT_REGIS_KHOAN_THU_TO_STORE = "DISPATCH_ITEM_STUDENT_REGIS_KHOAN_THU_TO_STORE"
export const UPDATE_STUDENT_REGIS_KHOAN_THU             = "UPDATE_STUDENT_REGIS_KHOAN_THU"

export const CHANGE_MONTH_QUAN_LY           =  "CHANGE_MONTH_QUAN_LY"
export const GET_ALL_LIST_STUDENT_KHOAN_THU = "GET_ALL_LIST_STUDENT_KHOAN_THU"
