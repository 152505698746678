import { LOADING_COMPONENT_CLOSE, LOADING_COMPONENT_OPEN ,ALERT_} from "../contant/LOGIN_PAGE/QuanTri";

export const LoadingComponentOpen=()=>{
    return{
        type:LOADING_COMPONENT_OPEN
    }
}

export const LoadingComponentFalse=()=>{
    return{
        type:LOADING_COMPONENT_CLOSE
    }
}