import axiosServices from '../../../../../api-services/api'
import {API_ENDPOINT}from '../../../../../contant'


const urlCreateAccountManagerStaff = 'person/createAccountManagerStaff' 
const urlEditAccountLogin          = 'person/editAccountLogin'
const urlEditAccountManagerStaff   = 'person/editAccountManagerStaff'
const urlCheckExistNameLogin       = 'person/checkExistNameLogin'
const urlCheckExistNameLoginEdit   = 'person/checkExistNameLoginEdit'
const urlAddStaff                  = 'person/AddStaff'
const urlGetUserIdByIdGroup_8      = 'person/GetUserIdByIdGroup_8'
const urlGetListStaffInfo          = 'person/GetListStaffInfo'
const urlActiveDeactiveUser        = 'person/ActiveDeactiveUser'
const urlAddPaymentStaff           = 'person/AddPaymentStaff'
const urlGetListPaymentStaff       = 'person/GetListPaymentStaff'
const urlUpdatePaymentStaff        = 'person/UpdatePaymentStaff'

const urlGetChamCongByUserId       = 'person/GetChamCongByUserId'
const urlAddAndGetListChamCong     = 'person/AddAndGetListChamCong'
const urlUpdateItemListChamCong    = 'person/UpdateItemListChamCong'

const urlAddLocation               = 'person/AddLocation'
const urlGetLocation               = 'person/GetLocation'

const urlAddTaskJob                 = "person/AddTaskJob"
const urlGetTaskJobs                = "person/GetTaskJobs"

const urlApproveTaskJob             = 'person/ApproveTaskJob'
const urlAddPaymentUpdateTaskJob    = 'person/AddPaymentUpdateTaskJob'



export const CreateAccountManagerStaff = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlCreateAccountManagerStaff}`,item)
}

export const EditAccountLoginApi=(item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlEditAccountLogin}`,item)
}

export const EditAccountManagerStaffApt = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlEditAccountManagerStaff}`,item)
}

export const CheckExistNameLoginApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlCheckExistNameLogin}`,item)
}

export const CheckExistNameLoginEditApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlCheckExistNameLoginEdit}`,item)
}

export const AddStaffApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddStaff}`,item)
}

export const GetUserIdByIdGroup_8Api=()=>{
  return axiosServices.get(`${API_ENDPOINT}/${urlGetUserIdByIdGroup_8}`)
}

export const GetListStaffInfo=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetListStaffInfo}`,item)
}

export const ActiveDeactiveUserApi =(item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlActiveDeactiveUser}`,item)
}

export const AddPaymentStaffApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddPaymentStaff}`,item)
}

export const GetListPaymentStaffApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetListPaymentStaff}`,item)
}

export const UpdatePaymentStaffApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlUpdatePaymentStaff}`,item)
}

export const AddLocationApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddLocation}`,item)
}

export const GetLocationApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetLocation}`,item)
}

export const GetChamCongByUserIdApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetChamCongByUserId}`,item)
}

export const AddAndGetListChamCongApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddAndGetListChamCong}`,item)
}

export const UpdateItemListChamCongApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateItemListChamCong}`,item)
}

export const AddTaskJobApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddTaskJob}`,item)
}

export const GetTaskJobsApi =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetTaskJobs}`,item)
}

export const ApproveTaskJobApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlApproveTaskJob}`,item)
}

export const AddPaymentUpdateTaskJobApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddPaymentUpdateTaskJob}`,item)
}