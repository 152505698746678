import React, { Suspense } from 'react';
import {Button,Dialog,DialogActions,DialogContent,Typography} from '@material-ui/core';
//import {changeUpperCase} from '../fnAllways/index'
import {useStyles} from './styles'

const AlertForm =React.memo(({open,handleClose,value,handleSubmit}) => {
 const classes = useStyles()
  return (
    <Suspense fallback={()=>{}}>    
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">        
        <DialogContent>         
              <Typography className={classes.colorText}>{value}</Typography>                 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Hủy
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Chấp nhận
          </Button>
        </DialogActions>
      </Dialog>
    </Suspense>
  );
})
export default AlertForm