import React, { Component } from 'react';
import {withStyles} from '@material-ui/styles'
import style from './style'
import {Card,
        CardContent,
        Typography,
        Button,
        Box        
} from '@material-ui/core'
import {Field,reduxForm} from 'redux-form'
import TextField from '../../../component/formHelper/TextField'
import Checkbox from '../../../component/formHelper/Checkbox'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {bindActionCreators,compose} from 'redux'
import validate from './validate'
import SelectField from '../../../component/formHelper/Select'
import * as AuthAction from '../../../actions/auth'
import { IMGCenter, LogoImg } from '../GioiDinhTue/component/style/MainCss/content_us';
import LogoGDT from '../GioiDinhTue/component/assest/img/gdt.jpg'
import {Helmet} from 'react-helmet-async';


class Signup extends Component {

  handleSubmitForm=(data)=>{  
    if(data) {
      let {name,password,idGroup}= data    
      let {getActionsAuth}   = this.props
      let {signup} = getActionsAuth
      if(signup){
        signup(name,password,+(idGroup))    
      }      
    }else{
      console.log('loi data')
    }     
  }

  renderSelectField=()=>{
    let {classes}= this.props
    let xhtml = null
      xhtml = (
        <Field                                       
        label="Bạn thuộc nhóm nào"                          
        component={SelectField}
        name="idGroup" 
        fullWidth        
        className={classes.select}
     >
             <option></option>
             <option value={1}>Quản trị</option> 
             <option value={2}>Nhân Viên</option>            
             <option value={3}>Khách Vip</option>          
             <option value={4}>Khách Hàng cá nhân</option>   
             <option value={5}>Nhà nước</option>     
              
      </Field>
      )
    return xhtml
  }
    render() {
      let {classes,handleSubmit,invalid,submitting} = this.props
    return (

      <div className={classes.background}>
                <Helmet>
                    <title>Phần Mềm Dinh Dưỡng KidTop</title>
                    <meta
                        name="description"
                        content="Phần Mềm Dinh Dưỡng"
                        charSet="utf-8"
                    />
                    <link rel="canonical" href="http://huythanhicare.com"/>
                </Helmet>
      <div className={classes.wrapper}>
      <Card style={{backgroundImage:'linear-gradient(#34495e,#1abc9c,#27ae60,#1abc9c,#34495e'}}>
          <CardContent>
            <form onSubmit={handleSubmit(this.handleSubmitForm)} autoComplete="off">
              <div className="text-xs-center pb-xs">               
                <Typography variant="caption" style={{color:'#ecf0f1'}}>
                  Đăng ký để sử dụng chương trình
                </Typography>
              </div>
              <Field
                className={classes.textField}
                fullWidth
                label="Tên đăng nhập"
                margin="normal"
                name="name"
                component={TextField}
                type="text"                
              />
              <Field
                className={classes.textField}
                fullWidth
                label="Password"
                margin="normal"
                name="password"
                component={TextField}
                type="password"
                autoComplete="password"
              />
              <Field
                className={classes.textField}
                fullWidth
                label="Confirm Password"
                margin="normal"
                name="confirmpassword"
                component={TextField}
                type="password"
                autoComplete="comfirmpassword"
              />
               <Box mt={1} mb={1}>
                {this.renderSelectField()}
              </Box>
              <Field
                component={Checkbox}
                label="Tôi đã đọc, và chấp nhận với điều khoản sử dụng dịch vụ"
                className={classes.fullWidth}
                name="isAccept"
              />             
              <Button
                disabled={invalid || submitting}
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Tạo Tài Khoản
              </Button>
              <div className="pt-1 text-md-center">
                <Link to="/login">
                  <Button style={{color:'#ecf0f1'}}>Qúy khách đã có tài khoản...</Button>
                </Link>
              </div>
            </form>
          </CardContent>
      </Card>
      </div>
      <a style={{textDecoration:'none'}} href="https://cdgdt.com"> 
                  <div style={{
                      marginLeft:'auto',
                      marginRight:'auto',
                      width:'100%',
                      marginTop:'46px'
                    }}>                            
                                <LogoImg>
                                    <IMGCenter src={LogoGDT} alt="cong-dong-gioi-dinh-tue"/>
                                </LogoImg>                            
                  </div>  
      </a>

    </div>
    );
  }
}

const FORM_NAME = 'SIGNUP'
const withForm =reduxForm({
  form:FORM_NAME,
  validate
})

const mapStateToProps = state =>{
  return{

  }
}

const mapDispatchStateToProps = (dispatch)=>{
  return{
      getActionsAuth : bindActionCreators(AuthAction,dispatch)
  }
}

const withConnect = connect(mapStateToProps,mapDispatchStateToProps)

export default compose(
  withStyles(style),
  withForm,
  withConnect
)(Signup)