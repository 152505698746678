import React, { Component,Fragment } from 'react';
import {Link} from 'react-router-dom'
import {Card,
  CardContent,
  Typography,
    Button,
    Box
} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import {bindActionCreators,compose} from 'redux'
import {Field,reduxForm} from 'redux-form'
import TextField from '../../../component/formHelper/TextField'
import SelectField from '../../../component/formHelper/Select/index'
import validate from './validate'
import {connect} from 'react-redux'
import {GROUP_USER} from '../../../contant/index'
import style from './style'
import * as AuthAction from '../../../actions/auth'
import * as WebAction from '../NotFoundPage/action'
//import FormCheckLogin from '../../Login/LoginPage/FormCheckLogin/index'
//import FormCheckLogin from './FormCheckLogin/index'
import * as UserAction from '../../../actions/users'
//import { showDateName } from '../../../helper/dateTime/handleDMY';
import { IMGCenter, LogoImg } from '../GioiDinhTue/component/style/MainCss/content_us';
import LogoGDT from '../GioiDinhTue/component/assest/img/gdt.jpg'
import DANG_KY_PM from '../NotFoundPage/component/GioiThieu/DANG_KY_PM';
import {Helmet} from 'react-helmet-async';
import { MainDiv, SeiAward, Wave, Wave1, Wave2 } from './styled';


const VERIFY_PAYMENT = 'verifypayment'
const LOGIN_SUCCESS  = "login/success"

 

class LoginPage extends Component {
  UNSAFE_componentWillMount=()=>{    
    //let timeCurrent = showDateName(Date.now())    
    let {AlertResetAccount,AlertResetAccountFail,AlertVerifyPaymentAccount}= this.props.getActionUser
    //let {GetAutoImportPhatDatAction} =this.props.getAutoImport
    let findPath =this.props.match.path
    // if(timeCurrent==='thứ tư'){
    //     GetAutoImportPhatDatAction()
    // }
    if(findPath.indexOf(VERIFY_PAYMENT)!==-1){
      AlertVerifyPaymentAccount(this.props.match.params)
      return ()=>{}
    }
    if(findPath.indexOf(LOGIN_SUCCESS)!==-1){
      AlertResetAccount(this.props.match.params)
      return ()=>{}
    }   

    if(this.props.match.params.value==='false'){
      AlertResetAccountFail()
      return ()=>{}
    }
  }
  
  CheckLogin =()=>{      
     let {getActionLogin} = this.props
     let {OpenFormCheckLogin} = getActionLogin
     OpenFormCheckLogin()    
  } 

  CloseFormLogin =()=>{
    let {getActionLogin} = this.props
     let {CloseFormCheckLogin} = getActionLogin
     CloseFormCheckLogin()    
  }

  handleSubmitForm = (data)=>{
    if(data){
      //let{name,password,idGroup} = data
      let{name,password} = data
      let {getActionLogin} =this.props
      let {login} = getActionLogin
      if(login){
      //  login(name,password,+(idGroup))
      login(name,password)

      }      
    }else{
      console.log('loi data')
    }      
  }

  renderSelectField=()=>{
    let {classes} = this.props
    let xhtml = null
    xhtml=(
      <Field                                       
      label="Bạn thuộc nhóm nào"                          
      component={SelectField}
      name="idGroup" 
      fullWidth        
      className={classes.select}
      >
            <option/>
            {GROUP_USER.map((item,index)=>{
              return(               
                 <option key={index} value={item.value}>{item.name}</option>
              )
            })}                  
     </Field>
   )
    return xhtml
  }

  render() {
    let {classes,handleSubmit,invalid,submitting,OpenCheckLogin} = this.props
    return (
      <Fragment>       
      <MainDiv 
      // className={classes.background}
      >  
                 <Helmet>
                    <title>Phần Mềm Dinh Dưỡng KidTop</title>
                    <meta
                        name="description"
                        content="Phần Mềm Dinh Dưỡng"
                        charSet="utf-8"
                    />
                    <link rel="canonical" href="http://huythanhicare.com"/>
                </Helmet>
      {OpenCheckLogin?
        <DANG_KY_PM
              open={OpenCheckLogin}
              handleClose={this.CloseFormLogin}
        />:
        <>
           <div className={classes.login}>
                    <Card style={{backgroundImage:'linear-gradient(#34495e,#1abc9c,#27ae60,#1abc9c,#34495e'}}>
                      <CardContent>
                        <form onSubmit={handleSubmit(this.handleSubmitForm)} autoComplete="off">
                          <div className="text-xs-center pb-xs">                          
                            <Typography variant="caption" style={{color:'#ecf0f1'}}>
                              Đăng nhập để sử dụng dịch vụ.
                            </Typography>
                          </div>
                          <Field
                            id="name"
                            label="Tên đăng nhập"
                            className={classes.textField}
                            fullWidth
                            margin="normal"
                            name="name"
                            component={TextField}
                          />
                          <Field
                            autoComplete="password" 
                            id="password"
                            label="Password"
                            className={classes.textField}
                            type="password"
                            fullWidth
                            margin="normal"
                            name="password"
                            component={TextField}
                          />
                          <Box mt={1} mb={2}>
                          {/* {this.renderSelectField()} */}
                          </Box>
                          <Button
                            variant="contained"                  
                            fullWidth
                            type="submit"
                            disabled={invalid || submitting}
                            style={{color:'#09a3ad'}}
                          >
                            Đăng nhập
                          </Button>
                          <div style={{marginTop:10}} className="pt-1 text-md-center">
                              <Button onClick={()=>this.CheckLogin()}  style={{color:'#ecf0f1'}}>Đăng ký tài khoản</Button>
                            <Link style={{textDecoration:'none'}} to="/verifyAccount">
                              <Button style={{color:'#ecf0f1'}}>Quên mật khẩu</Button>
                            </Link>
                          </div>
                          <Box onClick={()=>{window.location='http://huythanhicare.com'}} style={{marginTop:10,cursor:'pointer'}} display="flex" justifyContent="center">
                            <SeiAward>SEI AWARD EDUTECH</SeiAward>
                          </Box>
                        </form>
                      </CardContent>
                    </Card>
           </div>

          {/* <a style={{textDecoration:'none'}} href="https://cdgdt.com"> 
              <div style={{
                  marginLeft:'auto',
                  marginRight:'auto',
                  width:'100%',
                  marginTop:'160px'
                }}>                            
                            <LogoImg>
                                <IMGCenter src={LogoGDT} alt="cong-dong-gioi-dinh-tue"/>
                            </LogoImg>                            
              </div>  
          </a> */}
        </>        
      }     
           <Wave2></Wave2>
           <Wave></Wave>
           <Wave1></Wave1>       
        
       
      </MainDiv> 
      </Fragment>
    );
  }
}

const FORM_NAME = 'LOGIN'
const withForm = reduxForm({
  form:FORM_NAME,
  validate
})

const mapStateToProps = state=>{
  return{
      OpenCheckLogin:state.GovLogin.OpenCheckFormLogin
  }
}

const mapDispatchStateToProps = (dispatch)=>{
  return{
    getActionLogin : bindActionCreators(AuthAction,dispatch),
    getActionUser : bindActionCreators(UserAction,dispatch),
    getAutoImport:bindActionCreators(WebAction,dispatch)
  }
}

const  withConnect = connect(mapStateToProps,mapDispatchStateToProps)

export default compose(
  withStyles(style),
  withForm,
  withConnect
)(LoginPage)