import axiosServices from '../../../../api-services/api'
import {API_ENDPOINT} from  '../../../../contant/index'

const urlAddKiemThuc         = 'AddKiemThuc'
const urlAddKiemThucBaBuoc   = 'AddKiemThucBaBuoc'
const urlUpdateKiemThuc      = 'UpdateKiemThuc'
const urlGetKiemThucBaBuoc   = 'GetKiemThucBaBuoc'
const urlUpdateKiemThucBaBuoc   = 'UpdateKiemThucBaBuoc'

const urlAddInfoKiemThucBaBuoc = "AddInfoKiemThucBaBuoc"
const urlGetInfoKiemThucBaBuoc = "GetInfoKiemThucBaBuoc"
const urlAddCoSoSanXuatApi     = "AddCoSoSanXuat"
const urlUpdateCoSoSanXuatApi  = "UpdateCoSoSanXuat"

export const AddKiemThucApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddKiemThuc}`,item)
}

export const UpdateKiemThucApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateKiemThuc}`,item)
}

export const AddKiemThucBaBuocApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddKiemThucBaBuoc}`,item)
}

export const GetKiemThucBaBuocApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetKiemThucBaBuoc}`,item)
}

export const UpdateKiemThucBaBuocApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateKiemThucBaBuoc}`,item)
}

export const AddInfoKiemThucBaBuocApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddInfoKiemThucBaBuoc}`,item)
}

export const GetInfoKiemThucBaBuocApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetInfoKiemThucBaBuoc}`,item)
}

export const AddCoSoSanXuatApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddCoSoSanXuatApi}`,item)
}

export const UpdateCoSoSanXuatApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateCoSoSanXuatApi}`,item)
}



