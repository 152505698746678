import styled from "styled-components";
import {device} from '../device'
// about us

export const SpaceIn = styled.div`
    ${'' /* margin:auto;
    position:relative;
    left:50; */}
    margin:auto;
    width:70%;   
    margin-top:18px;
`
export const SpaceInBG = styled.div`
    ${'' /* margin:auto;
    position:relative;
    left:50; */}
    margin:auto;
    width:90%;   
    margin-top:18px;
`
export const LineIn = styled.div`
    width:70%;   
    height:1px;
    background:#7f8c8d;
    margin:auto;
    margin-top:5px;
   
`

export const LineInBG = styled.div`
    width:70%;   
    height:1px;
    background:#e67e22;
    margin:auto;
    margin-top:5px;
    @media ${device.mobile}{
         width: 100%;
         margin-top: -88px;
         height:2px;
    }
`
export const ImgLine= styled.img`
    margin:auto;
    display:block;
    margin-top:-26px;;
`
//why choose us
