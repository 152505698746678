import styled from "styled-components";

export const NameLogin = styled.div`
    display:flex;

`

export const TextLogin = styled.p`
    min-width:100px;
`

export const PassLogin = styled.div`
    display:flex;
`

export const EmailLogin = styled.div`
    display:flex;
`

export const TelLogin = styled.div`
    display:flex;
`

export const FooterContent = styled.div`
    display:flex;
    justify-content:space-between;
`

export const DomainInput = styled.div`
    display:flex;
    justify-content:space-between;
`