import {showYYMMDD,showMMYY,showDateName}from '../../helper/dateTime/handleDMY'
import {changeUpperCase} from '../../helper/fnAllways'
import _ from 'lodash'
const Sta = 'thứ bảy'
export const CreateMenuWeekly=(item,DateInput)=>{
  let result = {
    userId:item.userId,
    listFoodPri:"",
    listFoodPri2:"",
    listFoodSe:"",
    dateMenu:showYYMMDD(DateInput)
  }
  return result
}

export const CheckAddItemToListAccountCheckFood=(arr,item)=>{   
    let check = arr.some(v=>v._id===item[0]._id)
    if(!check){
      let newArr = arr.push(item)
      return newArr
    }
    return arr   
}

export const handleFindListClassCheckDayFood=(ListClass,ListOrigin,ListRegis,ListCheckDayFood,date)=>{  
  let filterListByDate        = ListOrigin.filter(v=>showMMYY(v.dateNow)===showMMYY(date))
  let filterListByDateByRegis = ListRegis.filter(v=>showMMYY(v.dateMonth)===showMMYY(date))
        if(ListCheckDayFood.length===0){
          return []
        }
        if(showDateName(date)!==Sta){
          return ListClass.map(v=>{
            let findListStudent       = filterListByDate.filter(vv=>vv.idClass===v._id)
            let findListStudentNotEat = ListCheckDayFood.filter(vv=>vv.idClass===v._id)
            if(findListStudentNotEat.length===0&&findListStudent===0){
              return {}
            }
            if(findListStudentNotEat.length!==0&&findListStudent.length!==0){
              let fListStudent       = findListStudent[0].listStudentsNow
              let fListStudentNotEat = findListStudentNotEat[0].nameStudentNotEat
              let result = {
                nameClass:v.nameClass,
                date,
                numCheckDay:fListStudent.length-fListStudentNotEat.length,
                listNotEat:FindArrListNotEat(fListStudent,fListStudentNotEat),
                listStudent:fListStudent,
                idClass:v._id
              }
              return result
            }            
          })
        }
        if(showDateName(date)===Sta){
            return ListClass.map(v=>{
              let findListAll           = filterListByDate.filter(vv=>vv.idClass===v._id)
              let findListStudent       = filterListByDateByRegis.filter(vv=>vv.idClass===v._id)
              let findListStudentNotEat = ListCheckDayFood.filter(vv=>vv.idClass===v._id)          
              if(findListStudentNotEat.length===0&&findListStudent===0){
                return {}
              }
              if(findListStudentNotEat.length!==0&&findListStudent.length!==0){
                let fListStudent       = findListStudent[0].nameStudentEatSevenDay
                let fListStudentNotEat = findListStudentNotEat[0].nameStudentNotEat
                let result = {
                  nameClass:v.nameClass,
                  date,
                  numCheckDay:fListStudent.length-fListStudentNotEat.length,
                  listNotEat:FindArrListNotEat(findListAll[0].listStudentsNow,fListStudentNotEat),
                  listStudent:FindListStudent(findListAll[0].listStudentsNow,fListStudent),
                  idClass:v._id
                }
                return result
              }
            })
        }        
}

const FindArrListNotEat =(arr,arrValue)=>{
    let arrStu = arrValue.map(item=>{
      let index = arr.findIndex(value=>value.idStudent===item.idStudent)
      if(index>=0){
        return{
          ...item,
          ...arr[index]
        }
      }else{
        return {...item}
      }
  })
  return arrStu.map(v=>changeUpperCase(v.fullName))     
}

const FindListStudent=(arr,arrValue)=>{  
  let arrStu = arrValue.map(item=>{
    let index = arr.findIndex(value=>value.idStudent===item.idStudent)
    if(index>=0){
      return{
        ...item,
        ...arr[index]
      }
    }else{
      return {...item}
    }
})
return arrStu    
}

export const FindClassNotChechDayFood =(arrClass,arrCheckDay)=>{
  let ListClass    = arrClass.map(v=>v.nameClass)
  let ListCheckDay = arrCheckDay.map(v=>v!==undefined?v.nameClass:undefined) 
  return _.difference(ListClass,ListCheckDay)
}

export const ResultAllMonthCheckDayFood=(arrCheckFood,listClass,listOrigin,listRegis)=>{
    let dayInMonth = [...new Set(arrCheckFood.map(v=>showYYMMDD(v.dateNow)))]
    return dayInMonth.map(date=>{
        let filterCheckDayFood = arrCheckFood.filter(vv=>showYYMMDD(vv.dateNow)===date)
        return handleFindListClassCheckDayFood(listClass,listOrigin,listRegis,filterCheckDayFood,date)
    })
}