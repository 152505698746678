import React from 'react'
import AboutUsComponent from './AboutUs'
import DetailComponent  from './Detail'
import LoadingLogo      from '../LoadingLogo/'
import WhyChooseUsComponent from './WhyChooseUs'
import OfferSoftComponent   from './OfferSoft'
import BangGiaComponent from './BangGia_Video/BangGia'
import ServiceComponent from '../Content/Service'
 import CustomerComponent from '../Content/Customer'
//import CompanyComponent from '../Content/Company'
// import TonChiComponent from '../../../main/component/GIO_DINH_TUE_TON_CHI'
// import MucTieuSuMenhComponent from '../../../main/component/AboutUs'
//import DoiTacComponent from './DoiTac'


const Index = React.memo(() => {
    return (
        <div>
            <AboutUsComponent/>
            <LoadingLogo/>
            <CustomerComponent/>
            <DetailComponent/>
            <LoadingLogo/>
            <WhyChooseUsComponent/>
            <OfferSoftComponent/>
            <BangGiaComponent/>
            {/* <CustomerComponent/>                                     */}
            <ServiceComponent/>      
            {/* <MucTieuSuMenhComponent/> */}
            {/* <LoadingLogo/>          */}
            {/* <TonChiComponent/>             */}
             {/* <LoadingLogo/>       
             <DoiTacComponent/>      */}
             <LoadingLogo/>        
            {/* <CompanyComponent/>             */}
        </div>
    )
})

export default Index;

