import {DISPATCH_GET_INFO_STAFF_TO_STORE,DISPATCH_GET_PAYMENT_STAFF_TO_STORE,
DISPATCH_GET_QRCODE_TO_STORE,CHAM_CONG_LOI,DISPATCH_GET_CHAM_CONG_TO_STORE,CHAM_CONG_SUCCESS,
DISPATCH_GET_LIST_MANY_ITEM_TO_STORE,DISPATCH_ADD_IMPORT_FROM_STAFF_TO_STORE,
DISPATCH_LIST_IMPORT_BY_STAFF_TO_STORE,DISPATCH_LIST_TASK_JOB_BY_STAFF_TO_STORE,DISPATCH_LIST_TASK_JOB_UPDATE,DISPATCH_LIST_AUTO_IMPORT_TO_STORE, DISPATCH_LIST_TASK_JOB_PESONAL_TO_STORE,DISPATCH_ADD_PAY_FOR_JOB_TASK_TO_STORE,
CONVERT_READY_TO_PROCESS_TASK_JOB, DISPATCH_VALUE_ADD_DEPARTMENT_UPDATE_TO_STORE,DISPATCH_ADD_STAFF_JOB_TO_STORE,
VALUE_PROCESS_TO_HANDLE_EMPTY
} from '../contant'
export const DispatchGetInfoStaffToStore=(item)=>{
  return{
    type:DISPATCH_GET_INFO_STAFF_TO_STORE,
    payload:{item}
  }
}
export const DispatchGetPaymentStaffToStore=(item)=>{
  return{
    type:DISPATCH_GET_PAYMENT_STAFF_TO_STORE,
    payload:{item}
  }
}

export const DispatchGetQrcodeToStore=(item)=>{
  return{
    type:DISPATCH_GET_QRCODE_TO_STORE,
    payload:{item}
  }
}

export const ChamCongLoi=()=>{
    return{
      type:CHAM_CONG_LOI
    }
}
export const ChamCongSuccess=(item)=>{
  return{
    type:CHAM_CONG_SUCCESS,
    payload:{item}
  }
}

export const DispatchGetChamCongToStore=(item)=>{
    return{
      type:DISPATCH_GET_CHAM_CONG_TO_STORE,
      payload:{item}
    }
}

export const DispatchGetListManyToStore=(item)=>{
    return{
      type:DISPATCH_GET_LIST_MANY_ITEM_TO_STORE,
      payload:{item}
    }
}

export const DispatchAddImportFromStaffToStore=(item)=>{
    return{
      type:DISPATCH_ADD_IMPORT_FROM_STAFF_TO_STORE,
      payload:{item}
    }
}

export const DispatchListImportByStaffToStore=(item)=>{
    return{
      type:DISPATCH_LIST_IMPORT_BY_STAFF_TO_STORE,
      payload:{item}
    }
}

export const DispatchListTaskJobByStaffToStore=(item)=>{
  return{
    type:DISPATCH_LIST_TASK_JOB_BY_STAFF_TO_STORE,
    payload:{item}
  }
}

export const DispatchListTaskJobUpdate=(item)=>{
  return{
    type:DISPATCH_LIST_TASK_JOB_UPDATE,
    payload:{item}
  }
}

export const DispatchListAutoImportToStore=(item)=>{
  return{
    type:DISPATCH_LIST_AUTO_IMPORT_TO_STORE,
    payload:{item}
  }
}

export const DispatchListTaskJobPesonalToStore=(item)=>{
  return{
    type:DISPATCH_LIST_TASK_JOB_PESONAL_TO_STORE,
    payload:{item}
  }
}

export const DispatchAddPayForJobTaskToStore=(item)=>{
  return{
    type:DISPATCH_ADD_PAY_FOR_JOB_TASK_TO_STORE,
    payload:{item}
  }
}

export const ConvertReadyToProcessTaskJob=(item)=>{
  return{
    type:CONVERT_READY_TO_PROCESS_TASK_JOB,
    payload:{item}
  }
}

export const DispatchValueAddDepartmentUpdateToStore=(item)=>{
  return {
    type:DISPATCH_VALUE_ADD_DEPARTMENT_UPDATE_TO_STORE,
    payload:{item}
  }
}

export const DispatchAddTaskJobToStore=(item)=>{
    return{
      type:DISPATCH_ADD_STAFF_JOB_TO_STORE,
      payload:{item}
    }
}

export const ValueProcessHandleEmpty=()=>{
  return{
    type:VALUE_PROCESS_TO_HANDLE_EMPTY
  }
}