import React, { Suspense, useState } from 'react';
import {Button,TextField,DialogActions,DialogContent,Grid,FormHelperText} from '@material-ui/core'
import  {AccountCircle,Person,Work,PersonOutline}from '@material-ui/icons'
import {useStyles} from './styles'
import {useDispatch,useSelector} from 'react-redux'
import {ADD_FORM_SIGN_REGIS}from '../../../../contant/Gov/login'

const Index=React.memo(({handleClose})=> {  
  const ListPrices                           = useSelector(state=>state.GovLogin.ListInfoUserDetail)
  const dispatch                             = useDispatch()
  const userId                               = useSelector(state=>state.GovLogin.userId)
  const classes                              = useStyles()
  const [master,setMaster]                   = useState("")
  const [accounter,setAccounter]             = useState("")
  const [secondaryMaster,setsecondaryMaster] = useState("")
  const [chef,setChef]                       = useState("")
  const [finance,setFinance]                 = useState("")
  const [err,setErr]                         = useState("")
  const [NguoiTruc,setNguoiTruc]             = useState(()=>localStorage.getItem('NguoiTruc'))
  const [valueNguoiTruc,setValueNguoiTruc]   = useState(null)


  const handleChangeNguoiTruc=(e)=>{
      let item={
        userId,
        type:ListPrices[0].typeOfCountEat===0?0:1,//0 mn,1:th
        nguoiTruc:e.target.value
      }     
      setValueNguoiTruc(item)
  }

  const ShowErr=()=>{
    setErr('Lỗi, không được để trống!')
    setTimeout(()=>{
        setErr('')
    },3000)
  }

  const Save=()=>{
      let item ={
        master,
        accounter,
        secondaryMaster,
        chef,
        finance,
        userId
      }
      if(   item.master          ===""||
            item.accounter       ===""||
            item.secondaryMaster ===""||
            item.chef            ===""||
            item.finance         ===""  
        ){
          ShowErr()
          return true
        }       
        if(NguoiTruc===null){
          if(valueNguoiTruc!==null) {
          localStorage.setItem('NguoiTruc',JSON.stringify([valueNguoiTruc]))
          }
        }
        if(NguoiTruc!==null){
          if(valueNguoiTruc!==null){
           let ConvertNguoiTruc = JSON.parse(NguoiTruc)          
           let result = ConvertNguoiTruc.concat(valueNguoiTruc)
           localStorage.removeItem('NguoiTruc')
           localStorage.setItem('NguoiTruc',JSON.stringify(result))
          }
        }
        
        dispatch({
          type:ADD_FORM_SIGN_REGIS,
          payload:{item}
        })
        handleClose()
  }

  return (      
    <Suspense fallback={()=>{}}>         
         <DialogContent> 
           <Grid container>
          <div className={classes.margin}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
              <AccountCircle />
            </Grid>
            <Grid item>
              <TextField id="input-with-icon-grid" 
                        onChange={e=>setMaster(e.target.value)}
                        type="text"
                        defaultValue={""}
                        multiline
              />
              <FormHelperText id="filled-weight-helper-text">Hiệu trưởng</FormHelperText>
            </Grid>
          </Grid>
        </div>

        <div className={classes.margin}>        
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <AccountCircle />
              </Grid>
              <Grid item>
                <TextField id="input-with-icon-grid" 
                          onChange={e=>setAccounter(e.target.value)}
                          defaultValue={""}  
                          type="text"
                   
                />
                <FormHelperText id="filled-weight-helper-text">Kế toán</FormHelperText>
              </Grid>
            </Grid>
        </div>

            <div className={classes.margin}>   
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <Person />
                  </Grid>
                  <Grid item>
                    <TextField id="input-with-icon-grid" 
                              onChange={e=>setsecondaryMaster(e.target.value)}
                              defaultValue={""}              
                              multiline        
                              type="text"
                    />
                    <FormHelperText id="filled-weight-helper-text">
                      {ListPrices[0].typeOfCountEat===0?"Phó Hiệu trưởng":" 0 Phó Hiệu Trưởng / 1 Y tế"}
                    </FormHelperText>
                  </Grid>
                </Grid>
            </div>

           <div className={classes.margin}>   
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                  <Work />
                </Grid>
                <Grid item>
                  <TextField id="input-with-icon-grid" 
                            onChange={e=>setFinance(e.target.value)}
                            defaultValue={""}              
                            multiline        
                            type="text"
                  />
                  <FormHelperText id="filled-weight-helper-text">Thủ Quỹ</FormHelperText>
                </Grid>
              </Grid>
            </div>

           <div className={classes.margin}>   
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <PersonOutline/>
                  </Grid>
                  <Grid item>
                    <TextField id="input-with-icon-grid" 
                              onChange={e=>setChef(e.target.value)}
                              defaultValue={""}              
                              multiline      
                              type="text"  
                    />
                    <FormHelperText id="filled-weight-helper-text">Bếp trưởng</FormHelperText>
                  </Grid>
                </Grid>
          </div> 

          {ListPrices[0].typeOfCountEat===0?<></>:
             <div className={classes.margin}>
              <Grid container spacing={1} alignItems="flex-end">
                      <Grid item>
                        <PersonOutline/>
                      </Grid>
                      <Grid item>
                        <TextField id="input-with-icon-grid" 
                                  onChange={handleChangeNguoiTruc}
                                  defaultValue={""}              
                                  multiline      
                                  type="text"  
                        />
                        <FormHelperText id="filled-weight-helper-text">Người Trực</FormHelperText>
                      </Grid>
              </Grid> 
           </div> 
           } 
        
                  
          </Grid>
        </DialogContent>
           
        <DialogActions>
          <span className={classes.textAlert}>{err}</span> 
          <Button variant="contained" onClick={handleClose} color="secondary">
            Thoát
          </Button>        
          <Button variant="contained" onClick={()=>Save()} color="primary">
            Lưu
          </Button>
          
        </DialogActions>
    </Suspense>
  );
})

export default Index
