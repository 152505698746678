import styled from "styled-components";
import {device} from '../device'


export const LineIn =styled.div`
        @media ${device.mobile}{
            width:80%;
            height:2px;
            background:#4cb2b6;
            margin:auto;
        }

`