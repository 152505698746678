import {take,takeLatest,takeEvery,put,call} from 'redux-saga/effects'
import * as TypeStaff from '../../contant/table/Staff'
import {deleteArrayUserIdInDepartmentStaff,updateArrayUserIdInDepartmentStaff} from '../../api-services/user'
import * as uiLoading from '../../actions/uiLoading'
import * as actionStaff from '../../actions/auth'
import {STATUS_CODE} from '../../contant/index'

function* listStaffSaga({payload}) {  
    while(true){
         yield take(TypeStaff.LIST_STAFF)                 
    }   
}

function* deleteStaffArraySaga({payload}){      
     yield put(uiLoading.loadingSuccess())    
     //yield delay(300)
     const value = payload.allId.map(item=>item.userId)     
     let res = yield call(deleteArrayUserIdInDepartmentStaff,value)     
    if(res.status===STATUS_CODE.SUCCESS){
      yield put(actionStaff.deleteStaffArraySuccess())     
    }else {
      yield put(actionStaff.deleteStaffArrayFail())
    }
    yield put(uiLoading.loadingFail())
}

function* editStaffSaga({payload}){  
    yield put(uiLoading.loadingSuccess())
    //yield delay(150)
    let res = yield call(updateArrayUserIdInDepartmentStaff,payload.newData)      
    //console.log(res)
    if(res.data.result==='success'){
        yield put(actionStaff.editStaffSuccess())
    }else{
      yield put(actionStaff.editStaffFail())
    }
    yield put(uiLoading.loadingFail())
  
  }

function* StaffSaga() {
    yield takeLatest(TypeStaff.ADD_ITEM_LIST_STAFF, listStaffSaga)
    yield takeLatest(TypeStaff.DELETE_STAFF_ARRAY,deleteStaffArraySaga)    
    yield takeLatest(TypeStaff.EDIT_STAFF,editStaffSaga)

}

export default StaffSaga