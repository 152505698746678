import React ,{Suspense}from 'react'
const QuanLyKho = React.lazy(()=>import('../../APP_ADMIN_PERSON/Person/index'))

const index = () => {
  return (
    <Suspense fallback={<>Loading...</>}>
      <QuanLyKho/>
    </Suspense>   
  )
}

export default index
