import * as TypeAuth from '../contant/auth'
import * as TypeStaff from '../contant/table/Staff'
import * as TypeGov from '../contant/table/Gov'
import * as TypePerson from '../contant/table/Person'
import * as TypeVip from '../contant/table/Vip'
import {OPEN_FORM_CHECK_LOGIN,CLOSE_FORM_CHECK_LOGIN,DISPATCH_LOGIN_PAGE_CREATE_ACCOUNT,DISPATCH_LIST_USER_STORE} from '../contant/auth'
import { DISPATCH_LIST_SOFT_PRICE_TO_STORE } from '../contant/Gov/login'


export const DispatchListSoftPriceToStore =(item)=>{
    return {
      type:DISPATCH_LIST_SOFT_PRICE_TO_STORE,
      payload:{item}
    }
}

export const signup = (name,password,idGroup)=>{
  return{
    type:TypeAuth.SIGNUP,
    payload:{
      name,password,idGroup
    }
  }
}
export const signupSuccess = (messageSuccess)=>{
    return{
      type:TypeAuth.SIGNUP_SUCCESS,
      payload:{messageSuccess}
    }
}

export const signupFail = (messageFail)=>{
  return{
    type:TypeAuth.SIGNUP_FAIL,
    payload:{messageFail}
  }
}

export const signupFailCheckUsername = ()=>{
  return{
    type:TypeAuth.SIGNUP_FAIL_CHECK_USERNAME    
  }
}

export const login = (name,password,idGroup)=>{
  return{
    type:TypeAuth.LOGIN,
    payload:{name,password,idGroup}
  }
}

export const loginSuccess = (messageSuccessLogin)=>{
  return{
    type:TypeAuth.LOGIN_SUCCESS,
    payload:{messageSuccessLogin}
  }
}


export const getListFrom = (dbUser,dbInfo,dbDepartment,listAllUser)=>{
  return{
    type:TypeAuth.GET_LIST_FORM,
    payload:{
      dbInfo,
      dbDepartment,
      dbUser,
      listAllUser
    }
  }
}


export const loginFail = (messageFailLogin)=>{
  return {
    type:TypeAuth.LOGIN_FAIL,
    payload:{messageFailLogin}
  }
}

export const loginFailCheckUserPass = ()=>{
  return{
    type:TypeAuth.LOGIN_FAIL_CHECK_USER_PASS
  }
}

//staff
export const listStaff= ()=>{
  return{
    type:TypeStaff.LIST_STAFF
  }
}

export const addItemListStaff = (item)=>{
  return{
    type:TypeStaff.ADD_ITEM_LIST_STAFF,
    payload:{
      item
    }
  }
}
//staff

//person
export const listPerson = ()=>{
  return{
    type:TypePerson.LIST_PERSON
  }
}

export const addItemListPerson = (person)=>{
  return{
    type:TypePerson.ADD_ITEM_LIST_PERSON,
    payload:{
      person
    }
  }
}
//person

//gov
export const listGov = ()=>{
  return{
      type:TypeGov.LIST_GOV
  }
}

export const addItemListGov = (data)=>{
  return{
    type:TypeGov.ADD_ITEM_LIST_GOV,
    payload:{
      data
    }
  }
}
//gov

//vip
export const listVip = ()=>{
  return{
      type:TypeVip.LIST_VIP
  }
}

export const addItemListVip = (vip)=>{
  return{
    type:TypeVip.ADD_ITEM_LIST_VIP,
    payload:{
      vip
    }
  }
}
//vip


//deleteGovArray 
export const deleteGovArray = (array)=>{
  return{
    type:TypeGov.DELETE_GOV_ARRAY,
    payload:{
      array
    }
  }
}
export const deleteGovArraySuccess = ()=>{
  return{
    type:TypeGov.DELETE_GOV_ARRAY_SUCCESS
  }
}
export const deleteGovArrayFail = ()=>{
  return{
    type:TypeGov.DELETE_GOV_ARRAY_FAIL
  }
}
//deleteGovArray

//deletePersonArray 
export const deletePersonArray = (array)=>{
  return{
    type:TypePerson.DELETE_PERSON_ARRAY,
    payload:{
      array
    }
  }
}
export const deletePersonArraySuccess = ()=>{
  return{
    type:TypePerson.DELETE_PERSON_ARRAY_SUCCESS
  }
}
export const deletePersonArrayFail = ()=>{
  return{
    type:TypePerson.DELETE_PERSON_ARRAY_FAIL
  }
}
//deletePersonArray

//deletePersonArray 
export const deleteVipArray = (array)=>{
  return{
    type:TypeVip.DELETE_VIP_ARRAY,
    payload:{
      array
    }
  }
}
export const deleteVipArraySuccess = ()=>{
  return{
    type:TypeVip.DELETE_VIP_ARRAY_SUCCESS
  }
}
export const deleteVipArrayFail = ()=>{
  return{
    type:TypeVip.DELETE_VIP_ARRAY_FAIL
  }
}
//deletePersonArray

//deleteStaffArray 
export const deleteStaffArray = (array)=>{
  return{
    type:TypeStaff.DELETE_STAFF_ARRAY,
    payload:{
      array
    }
  }
}
export const deleteStaffArraySuccess = ()=>{
  return{
    type:TypeStaff.DELETE_STAFF_ARRAY_SUCCESS
  }
}
export const deleteStaffArrayFail = ()=>{
  return{
    type:TypeStaff.DELETE_STAFF_ARRAY_FAIL
  }
}
//deleteStaffArray

//edit gov
export const editGov = (value)=>{
  return{
    type:TypeGov.EDIT_GOV,
    payload:{
      value
    }
  }
}
export const editGovSuccess = ()=>{
  return{
    type:TypeGov.EDIT_GOV_SUCCESS
  }
}
export const editGovFail= ()=>{
  return{
    type:TypeGov.EDIT_GOV_FAIL
  }
}
//editGov

//edit staff
export const editStaff = (value)=>{
  return{
    type:TypeStaff.EDIT_STAFF,
    payload:{
      value
    }
  }
}
export const editStaffSuccess = ()=>{
  return{
    type:TypeStaff.EDIT_STAFF_SUCCESS
  }
}
export const editStaffFail= ()=>{
  return{
    type:TypeStaff.EDIT_STAFF_FAIL
  }
}
//editStaff

//edit person
export const editPerson = (value)=>{
  return{
    type:TypePerson.EDIT_PERSON,
    payload:{
      value
    }
  }
}
export const editPersonSuccess = ()=>{
  return{
    type:TypePerson.EDIT_PERSON_SUCCESS
  }
}
export const editPersonFail= ()=>{
  return{
    type:TypePerson.EDIT_PERSON_FAIL
  }
}
//editPerson

//edit vip
export const editVip = (value)=>{
  return{
    type:TypeVip.EDIT_VIP,
    payload:{
      value
    }
  }
}
export const editVipSuccess = ()=>{
  return{
    type:TypeVip.EDIT_VIP_SUCCESS
  }
}
export const editVipFail= ()=>{
  return{
    type:TypeVip.EDIT_VIP_FAIL
  }
}
export const OpenFormCheckLogin=()=>{
  return{
    type:OPEN_FORM_CHECK_LOGIN
  }
}
export const CloseFormCheckLogin=()=>{
  return{
    type:CLOSE_FORM_CHECK_LOGIN
  }
}
export const DispatchLoginPageCreateAccount=()=>{
  return{
    type:DISPATCH_LOGIN_PAGE_CREATE_ACCOUNT
  }
}
export const DispatchListUserStore=(arr)=>{
  return{
    type:DISPATCH_LIST_USER_STORE,
    payload:{arr}
  }
}
//edit vip



