import React from 'react';
//import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    // '& > * + *': {
    //    margin:'auto',
    // },
    // zIndex:90000000,
    marginTop:'-50px'
  },
}));

export default function CustomizedSnackbars({open,text,handleClose}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>      
      <Snackbar open={open} autoHideDuration={800} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
        {text}
        </Alert>
      </Snackbar>
      <Alert severity="success">{text}</Alert>
    </div>
  );
}
