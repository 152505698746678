import React from 'react'
import {AddTypeOfServiceInput} from '../../Helper/App_Service'
import {TextField} from '@material-ui/core'
import {useDispatch} from 'react-redux'
import {DISPATCH_VALUE_ADD_TO_SAGA} from '../../../Contant/Admin/AddViewAppService'
import {DeleteLastString} from '../../../Helper/Fn/String'
export const AddHtml=()=>{
  const dispatch = useDispatch()
  const handleChange =(e)=>{    
     let item ={
      nameTypeOfService:DeleteLastString(e.target.value),      
    }
    dispatch({
      type:DISPATCH_VALUE_ADD_TO_SAGA,
      payload:{item}
    })
 }
  return AddTypeOfServiceInput.map((v,i)=>{
    return(      
        <TextField
            key={i}
            placeholder ={v.placeholder}
            name        ={v.name}
            variant     ={v.variant}
            type        ={v.type}
            defaultValue=""
            onChange    ={handleChange}
        />      
    )
  })
}