import styled ,{keyframes}from "styled-components";


export const textclip = keyframes`
   from {
    background-position: 200% center;
  }
`

export const my_animation = keyframes`
        from {
            -moz-transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
        }
        to {
            -moz-transform: translateX(120%);
            -webkit-transform: translateX(120%);
            transform: translateX(120%);
        }
`

export const MainDiv = styled.div`
    width:100%;
    height:90px;
    margin-top:-90px;
    position:relative;
    z-index:-1;    
`

export const MainContent = styled.div`
        z-index:-1;
        position:absolute;
        top:35px;
        left:15%;
        font-size:20px;
        font-weight: 500;
        font-family: 'Roboto', cursive; 
        text-transform: uppercase;
        background-image: linear-gradient(
            -225deg,
            #EE5A24 0%,
            #FFC312 29%,
            #F79F1F 55%,
            #EE5A24 67%,
            #EA2027 100%
        );  
        background-size: auto auto;
        background-clip: border-box;
        background-size: 200% auto;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        ${'' /* animation: ${textclip} 10s linear infinite; */}
        display: inline-block;        
        -moz-transform: translateX(-200%);
        -webkit-transform: translateX(-200%);
        transform: translateX(-300%);            
        -moz-animation: ${my_animation} 15s linear infinite;
        -webkit-animation: ${my_animation} 15s linear infinite;
        animation: ${my_animation} 15s linear infinite;            

        @media(max-width:768px){
            font-size:15px;
            font-weight:300;
            ${'' /* -moz-transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);            
            -moz-animation: ${my_animation} 10s linear infinite;
            -webkit-animation: ${my_animation} 10s linear infinite;
            animation: ${my_animation} 10s linear infinite;             */}
        }
        ${'' /* @media (min-width:769px) and (max-width:1024px){
                z-index:-1;
                position:absolute;
                top:35px;
                left:10%;
                font-size:18px;
                font-weight:300;
                font-family: 'Pacifico', cursive; 
                text-transform: uppercase;
                background-image: linear-gradient(
                    -225deg,
                    #FFC312 0%,
                    #F79F1F 29%,
                    #EE5A24 67%,
                    #EA2027 100%
                );  
                background-size: auto auto;
                background-clip: border-box;
                background-size: 200% auto;
                background-clip: text;
                text-fill-color: transparent;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                animation: ${textclip} 10s linear infinite;
                display: inline-block; */}
        ${'' /* } */}
`

export const MainContent2 = styled.div`
        z-index:-1;
        position:absolute;
        top:35px;
        left:65%;
        font-size:25px;
        font-weight: 500;
        font-family: 'Pacifico', cursive; 
        text-transform: uppercase;
        background-image: linear-gradient(
            -225deg,
            #ED4C67 0%,
            #B53471 29%,
            #833471 67%,
            #6F1E51 100%
        );  
        background-size: auto auto;
        background-clip: border-box;
        background-size: 200% auto;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: ${textclip} 10s linear infinite;
        display: inline-block;
        @media (max-width:768px){
            display:none;
        }
        @media(min-width:769px) and (max-width:1024px){
                z-index:-1;
                position:absolute;
                top:35px;
                left:55%;
                font-size:18px;
                font-weight:300;
                font-family: 'Pacifico', cursive; 
                text-transform: uppercase;
                background-image: linear-gradient(
                    -225deg,
                    #ED4C67 0%,
                    #B53471 29%,
                    #833471 67%,
                    #6F1E51 100%
                );    
                background-size: auto auto;
                background-clip: border-box;
                background-size: 200% auto;
                background-clip: text;
                text-fill-color: transparent;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                animation: ${textclip} 10s linear infinite;
                display: inline-block;
        }
`