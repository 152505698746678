import React,{useEffect, useState} from 'react'
import {Box, Button, Grid,Paper} from '@material-ui/core'
import TableList from './tableList'
import {useStyles} from './styles'
import {useSelector,useDispatch}from 'react-redux'
import _ from 'lodash'
import ShowDateName from '../FnComponent/showDateName'
import {FIND_MENU_WEEKLY_DB_BY_USERID_DATE_NOW,OPEN_COUNT_EAT_CHECK_DAY_FOOD,CLOSE_COUNT_EAT_CHECK_DAY_FOOD, ADD_ITEM_STU_SEND_MESS, GET_ITEM_STU_SEND_MESS} from '../../contant/Class'
import {DISPATCH_UPDATE_APPROVAL_FROM_CLASS,GET_STUDENT_LEAVE_TEMPORATY_BY_CLASS}from '../../contant/Class'
import io from 'socket.io-client'
import {API_ENDPOINT} from '../../../contant'
import {FindListStuNotEat}from './helper'
import ViewListStu from './ViewList'
import {CLOSE_LIST_STUDENT_NOT_EAT,CLOSE_UPDATE_APPROVAL_CHECK_DAY_FOOD,CLOSE_CHECK_DAY_FOOD_COUNT_EAT}from '../../contant/Class'
import {DISPATCH_GET_INFO_LIST_OF_USERID}from '../../contant/ManagerAccount'
import ApprovalList from '../ManagerClass/index'
import SelectUpdateApproval from './UpdateApproval'
import FormCheckDayFood from './TotalCheckDayFood'
import ClockComponent from '../../../utils/Clock'
import ManagerStuComponent from './ManagerStu'
import { HandleUserAccountLogin } from './fn'
//import { Alert } from '@material-ui/lab'
//import { HandleUserAccountLogin } from './fn'

let socket
socket = io(API_ENDPOINT)

const Index = React.memo(() => {
  const ItemManagerActiveAccount  = useSelector(state=>state.ManagerAccount.ItemManagerActiveAccount)
  const ItemClassLeaveTemporaty   = useSelector(state=>state.ManagerAccount.ItemClassLeaveTemporaty) 
  const classes                   = useStyles()
  const dispatch                  = useDispatch()
  const CountEat                  = useSelector(state => state.ManagerAccount.CountEat)
  const DateInput                 = useSelector(state=>state.ManagerAccount.DateInput)
  const OpenListStuNotEat         = useSelector(state=>state.ManagerAccount.OpenListStuNotEat)
  const OpenCheckDayFoodCountEat  = useSelector(state=>state.ManagerAccount.OpenCheckDayFoodCountEat)
  const OpenUpdateApprovalCheckDayFood  = useSelector(state=>state.ManagerAccount.OpenUpdateApprovalCheckDayFood)
  const ListApprovalCheckDayFood  = useSelector(state=>state.ManagerAccount.ListApprovalCheckDayFood)
  const userIdCurrent             = useSelector(state=>state.ManagerAccount.userIdCurrent)
  const ItemApproval              = ListApprovalCheckDayFood!==null&&ListApprovalCheckDayFood!==undefined?ListApprovalCheckDayFood.filter(v=>v.userClass===userIdCurrent):[]
  const FindNumStuNotEat          = ItemApproval.length!==0?ItemApproval[0].checkDayFood.nameStudentsNotEat:undefined

  useEffect(() => {     
      if(ItemManagerActiveAccount.length!==0&&ItemManagerActiveAccount!==""&&ItemManagerActiveAccount!==undefined){
        let item = {
          userId:_.first(ItemManagerActiveAccount).userId,
          date:DateInput
        }
        dispatch({
                type:FIND_MENU_WEEKLY_DB_BY_USERID_DATE_NOW,
                payload:{item}
                })    
        dispatch({
            type:GET_STUDENT_LEAVE_TEMPORATY_BY_CLASS,
              payload:{item:{
                userId:ItemManagerActiveAccount[0].userId,
                idClass:ItemManagerActiveAccount[0].idClass,
                dateNow:DateInput
            }}})
        // dispatch({
        //   type:DISPATCH_LIST_CLASS_ACCOUNT_APPROVAL_BY_USER_ID_DATE,
        //   payload:{item}
        //  })
      }      
      //MA:ManagerAccount, MC:ManagerClass , CA: ClassActive
      //MC send CA    
      socket.on('RequestApprovalClassFromManagerClassServer',data=>{
        dispatch({
          type:DISPATCH_UPDATE_APPROVAL_FROM_CLASS,
          payload:{item:data.data}
        })
        dispatch({
          type:DISPATCH_GET_INFO_LIST_OF_USERID,
          payload:{item:{
            idClass:data.data.checkDayFood.idClass,
            userId:data.data.checkDayFood.userId,
            dateNow:DateInput
          }}
       })
      })      
      //MA send MC
      socket.on('RequestApprovalClassFromManagerAccount',data=>{
        dispatch({
          type:DISPATCH_GET_INFO_LIST_OF_USERID,
          payload:{item:{
            idClass:data.data.checkDayFood.idClass,
            userId:data.data.checkDayFood.userId,
            dateNow:DateInput
          }}
        })

        dispatch({
          type:DISPATCH_UPDATE_APPROVAL_FROM_CLASS,
          payload:{item:data.data}
        })
    })    
  },[ItemManagerActiveAccount])

  const ShowApproval =()=>{
    if(ItemApproval.length!==0&&ItemApproval[0].status===true){
      return <Button color="primary">Đã Phê Duyệt</Button>
    }
    if(ItemApproval.length!==0&&ItemApproval[0].status===false){
      return <Button color="secondary">Đợi Phê Duyệt</Button>
    }
  }

  const ResultListStudent                  = useSelector(state=>state.ManagerAccount.ResultListStudent) 
  let {ListStudentNow, ListStudentByMonth,nameClass
  }  = ResultListStudent
  const FindListStu                        = FindListStuNotEat(ListStudentNow,FindNumStuNotEat)

  useEffect(()=>{     
      if(ListStudentNow!==null&&ListStudentNow!==undefined&&ListStudentNow.length!==0){
        let item ={idStudentNow:ListStudentByMonth[0]._id,userId:ListStudentByMonth[0].userId}
        dispatch({type:GET_ITEM_STU_SEND_MESS,payload:{item}})
      }
  },[ListStudentNow])
  
  const handleCountEat=()=>{
      dispatch({
        type:OPEN_COUNT_EAT_CHECK_DAY_FOOD
      })
  }
  const ExitSaveCountEat =()=>{
    dispatch({
      type:CLOSE_COUNT_EAT_CHECK_DAY_FOOD
    })
  }
  const CloseListStudentNotEat=()=>{
    dispatch({
      type:CLOSE_LIST_STUDENT_NOT_EAT
    })
  }
  const CloseUpdateApprovalCheckDayFood=()=>{
    dispatch({
      type:CLOSE_UPDATE_APPROVAL_CHECK_DAY_FOOD
    })
  }
  //close CheckDayFood count Eat 
  const CloseCheckDayFoodCountEat =()=>{
    dispatch({
      type:CLOSE_CHECK_DAY_FOOD_COUNT_EAT
    })
  }
  const [SelectBaoAn,setSelectBaoAn] = useState(true)
  const [SelectStu,setSelectStu]     = useState(false)

  const HandleUserAccount=()=>{
      return HandleUserAccountLogin(ItemManagerActiveAccount)
  }

  return (
    <>  
      <ClockComponent/>   
      <ShowDateName/>

      {/* {ListStudentNow!==undefined&&ListStudentNow.length!==0? */}
          <>  
                <Box display="flex" justifyContent="center">
                <Button size="small" variant='contained' style={{color:SelectBaoAn?'green':''}} onClick={()=>{setSelectBaoAn(true)
                                                                      setSelectStu(false)
                }}>Báo Ăn</Button>&nbsp;&nbsp;

                {HandleUserAccount()===false?
                <Button size="small" variant='contained' style={{color:SelectStu?'green':''}} onClick={()=>{setSelectBaoAn(false)
                                                                      setSelectStu(true)
                }}>Quản Lý Danh Sách</Button>:<></>
              }               

              </Box>
              {SelectBaoAn?
                <>
                                    {OpenCheckDayFoodCountEat===true?<FormCheckDayFood
                                                              listStu ={ResultListStudent}
                                                              handleClose={CloseCheckDayFoodCountEat}
                      />:
                      <>  
                          {ItemManagerActiveAccount!==undefined&&ItemManagerActiveAccount.length!==0&&ItemManagerActiveAccount[0].role===0?
                            <>
                              {/* phê duyệt chấm ăn */}    
                                  {ItemApproval.length===0&&
                                          <>              
                                            {CountEat===0&&<Button variant="outlined" color="secondary" onClick={()=>handleCountEat()} className={classes.marginBaoAn} size="small">
                                              Chấm Ăn
                                                          </Button>
                                            }          
                                          </>  
                                  }                   
                                        {ShowApproval()}
                                {/* phê duyệt chấm ăn */}
                              <br></br>
                              <Grid container>       
                                <Grid item xs={12} sm={12}>
                                    <Paper>
                                        <TableList List={ResultListStudent}
                                                        //CountEat={CountEat}
                                                        ExitSaveCountEat={ExitSaveCountEat}
                                                        NumStu ={FindNumStuNotEat}
                                                        ClassTmp = {ItemClassLeaveTemporaty}
                                        />
                                    </Paper>
                                </Grid>  
                                {OpenListStuNotEat&&<ViewListStu
                                  open={OpenListStuNotEat}
                                  handleClose={CloseListStudentNotEat}
                                  listStu={FindListStu}
                                />}
                              {/* update Approval */}
                                {OpenUpdateApprovalCheckDayFood&&<SelectUpdateApproval
                                    open={OpenUpdateApprovalCheckDayFood}
                                    handleClose={CloseUpdateApprovalCheckDayFood}
                                />}
                              {/* update Approval */}
                              </Grid>
                                </>:
                            <>
                                  <ApprovalList/>
                            </>    
                          }           
                      </>}    
                </>:
                <>
                    <ManagerStuComponent/>
                </>
              } 
          </>
          
          {/* :         
              <Box>
                <Alert severity='warning' color='warning'>Danh Sách Học Sinh Không Tồn Tại, Vui Lòng Liên Hệ Với Bộ Phận Quản Lý</Alert>
              </Box>         */}
       {/* }    */}
    </>
  )
})

export default Index