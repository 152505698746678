import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: 450,
    maxHeight:500
  },
  marginBaoAn:{
    marginBottom:'5px'
  },
  selectAll:{
    cursor:'pointer'
  },
  SaveCountEat:{
    cursor:'pointer',
    color:'red'
  },
  numStu:{
    cursor:'pointer',
    marginLeft: '182px',
    marginTop: '-23px'
  },
  UpdateApproval:{
    cursor:'pointer',
    marginLeft: '224px',
    marginTop: '-50px'
  },
  CheckDayFood:{
    cursor:'pointer',
    marginLeft:'258px',
    marginTop:'-52px'
  },
  CheckDayFoodDiff:{
    cursor:'pointer',
    marginLeft:'258px',
    marginTop:'-25px'
  }
}));