import {  makeStyles } from '@material-ui/core/styles';

export const useStyles =makeStyles(theme=>({ 
  table: {
    maxWidth: 650,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}))