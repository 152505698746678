import React from 'react';
import {Dialog,Box} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import UpdateComponent from  '../Update/ComponentUpdate'

export default function Index({open,handleClose}) {  
  return (      
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">       
        <Box textAlign="center">
               <Alert variant="filled" severity="warning">Tên Chức danh - Cập nhật thông tin</Alert>
        </Box>       
         <UpdateComponent handleClose={handleClose}/>
      </Dialog>
  );
}
