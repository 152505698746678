import React,{Fragment} from 'react'
import style from './style'
import {withStyles, Grid,DialogContent,DialogActions,Button} from '@material-ui/core'
import {reduxForm,Field} from 'redux-form'
import renderTextField from '../../../component/formHelper/TextField/index'
import {connect} from 'react-redux'
import {bindActionCreators,compose} from 'redux'
import renderSelectField from '../../../component/formHelper/Select/index'
import validate from './validate'
import * as actionsUserPage from '../../../actions/pageUser'
import * as actionsStaff from '../../../actions/auth'

class UserPageForm extends React.Component{
  handleSubmitForm=(data)=>{       
    //console.log(data)
     let{formDepartment,Staff} = this.props
     let{addFormDepartment}= formDepartment
     let {listStaff,addItemListStaff} = Staff
     //dispatch action show list nhan vien
     listStaff()
     addItemListStaff(data)
     addFormDepartment(data)      
  } 
  renderSelectAccount = ()=>{    
      let {name,valueId,groupParent} = this.props     
      let xhtml = ''
      if(name&&valueId&&groupParent){
        xhtml =(
          <Fragment>
          <Grid item md={6}>                
            <Field
                margin="dense"            
                label="Tên Account"
                name="userId"
                type="text"
                component={renderSelectField}                                        
                fullWidth             
              >     
                       <option/>
                       <option  value={valueId}>{name}</option>                       
                    )                       
              </Field>                      
          </Grid>              
          <Grid item md={6}>                
            <Field
                margin="dense"            
                label="Trực thuộc"
                name="infoUserId"
                type="text"
                component={renderSelectField}                                        
                fullWidth             
              >     
                    <option/>                           
                   {groupParent.map((item,index)=>{
                      return (<option value={item._id} key={index}>{item.workPlace}</option> )
                    })}                     
              </Field>                      
          </Grid>               
      </Fragment>
        )
      }     
    return xhtml
      
  } 

  render() {
    let {handleOpenCloseModal,handleSubmit,invalid,submitting} = this.props           
    let {hideFormUser} = handleOpenCloseModal
    return (
      <Fragment>
         <form onSubmit={handleSubmit(this.handleSubmitForm)} autoComplete="off">         
          <DialogContent>  
            <Grid container spacing={1}>             
                  {this.renderSelectAccount()}                
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"      
                        label="Tên Phòng Ban"
                        name="departmentName"
                        component={renderTextField}
                        type="text"                     
                        fullWidth             
                      />       
                  </Grid>
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"      
                        label="Tên đầy đủ"
                        name="humanName"
                        component={renderTextField}
                        type="text"                     
                        fullWidth             
                      />       
                  </Grid>
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"          
                        label="Số điên thoại"
                        name="tel"
                        component={renderTextField}
                        type="text"                      
                        fullWidth             
                      />       
                  </Grid>
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"          
                        label="Địa chỉ"
                        name="address"
                        component={renderTextField}
                        type="text"                      
                        fullWidth             
                      />       
                  </Grid>
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"        
                        label="Email"
                        name="email"
                        component={renderTextField}
                        type="text"                      
                        fullWidth             
                      />       
                  </Grid>                  
                  {/* <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"           
                        label="Ảnh đại diện"
                        name="avatar"
                        component={renderTextField}
                        type="text"
                        //value=''
                        fullWidth             
                      />       
                  </Grid>            
                  <Grid item md={6}>
                    <Field
                        autoFocus
                        margin="dense"           
                        label="Chữ Ký"
                        name="signature"
                        component={renderTextField}
                        type="text"
                        // value=''
                        fullWidth             
                      />       
                  </Grid>                   */}
               </Grid>       
          </DialogContent>
          <DialogActions>           
            <Button onClick={hideFormUser} color="default" variant="contained" >
              Hủy
          </Button>
          <Button disabled={invalid||submitting}  color="primary" type="submit" variant="contained">
              Lưu lại
          </Button>
          </DialogActions>   
    </form>    
      </Fragment>                  
    )
  }
}

const mapStateToProps = state =>{
      return {
        //initialValues:state.task.taskEditting,
        //task:state.task.taskEditting ? state.task.taskEditting:'',
        // status:state.task.taskEditting.status ? state.task.taskEditting.status:''      
       // open:state.userPage.showFormUser,
        close:state.userPage.showForm,
        listUserNhaNuoc:state.userPage.listUserGroupNhaNuoc      
      }
}

const mapDispatchStateToProps = dispatch =>{
  return{
      handleOpenCloseModal: bindActionCreators(actionsUserPage,dispatch),
      formDepartment: bindActionCreators(actionsUserPage,dispatch),
      Staff: bindActionCreators(actionsStaff,dispatch)
  }
}


const FORM_NAME = "FORM_MANAGER"

const withConnect = connect(mapStateToProps,mapDispatchStateToProps)

const withReduxForm = reduxForm({
  form:FORM_NAME,
  validate
})

export default compose(
  withStyles(style),
  withConnect, 
  withReduxForm
  )(UserPageForm)