import React from 'react';
import {useSelector,useDispatch} from 'react-redux'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import * as TypeGov from '../../../contant/Gov/softFood'


 const ActiveModal=React.memo(()=> {          
   const Box = useSelector(state=>state.ActiveSoftFood.openModalBox)           
   const dispatch = useDispatch()  
   const handleActiveRedirect=()=>{
     dispatch({
       type:TypeGov.ACTIVE_SOFT_FOOD_FAIL
     })
   }
 return (
    <div>     
      <Dialog open={Box} aria-labelledby="form-dialog-title">
         <DialogContent>
          <DialogContentText color="primary">
           Chương Trình chưa được kích hoạt, vui lòng liên hệ với quản trị
          </DialogContentText>         
        </DialogContent>
        <DialogActions >         
          <Button onClick={handleActiveRedirect} variant="contained" color="secondary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
})


export default ActiveModal