import { updateArrById } from "../../../../../APP_ADMIN_PERSON/Reducer/fn";
import { toastSuccess } from "../../../../../helper/toastHelper";
import { ADD_UPDATE_QUAN_TRI_FAIL, ADD_UPDATE_QUAN_TRI_SUCCESS, GET_NEW_UPDATE_LOGIN_ACCOUNT_QUAN_TRI } from "../../contant/LOGIN_PAGE/OpenNavBarQuanTri";
import {CREATE_NEW_ACCOUNT_QUANTRI_SUCCESS,CREATE_NEW_ACCOUNT_QUANTRI_FAIL,LOADING_COMPONENT_CLOSE,LOADING_COMPONENT_OPEN, CLOSE_NEW_ACCOUNT_QUANTRI_SUCCESS, CLOSE_NEW_ACCOUNT_QUANTRI_FAIL, CLOSE_FORM_AFTER_CREATE_SUCCESS, OPEN_FORM_BEFORE_CREATE_SUCCESS ,GET_ALL_ACCOUNT_TO_STORE_QUAN_TRI, LOGIN_PAGE_QUAN_TRI_FAIL, LOGIN_PAGE_QUAN_TRI_SUCCESS,  LOGOUT_SYSTEM, UPDATE_ACCOUNT_LOGIN_QUAN_TRI_SUCCESS, UPDATE_ACCOUNT_LOGIN_QUAN_TRI_FAIL} from "../../contant/LOGIN_PAGE/QuanTri";

//LoginPageQuanTri:reducer

let initialState={
   TaiKhoanSieuQuanTri:false,
   TaiKhoanQuanLy:false,
   TaiKhoanNhanVien:false,
   TaiKhoanKhachHang:false,
   TaiKhoanDoiTac:false,
   Loading:false,
   AlertSuccess:false,   
   AlertFail:false,
   TextAlert:"",
   ListAccount:[],
   CloseForm:false,
   //Show Component
   ShowComponentQuanTri:false,
   ItemLogin:null,
  //  ShowComponentQuanLy:false,
  //  ShowComponentNhanVien:false,
  //  ShowComponentKhachHang:false,
  //  ShowComponentDoiTac:false
}

const CREATE_SUCCESS = "Khởi Tạo Thành Công"
const UPDATE_SUCCESS = "Cập Nhật Thành Công"
const UPDATE_FAIL = "Cập Nhật Lỗi"



const IndexReducer =(state=initialState,action)=>{
            switch (action.type) {
              case GET_NEW_UPDATE_LOGIN_ACCOUNT_QUAN_TRI:
                toastSuccess('Cập Nhật Tài Khoản Thành Công')
                return{
                  ...state,
                  ItemLogin:action.payload.item
                }
              case UPDATE_ACCOUNT_LOGIN_QUAN_TRI_SUCCESS:
                return{
                  ...state,
                  AlertSuccess:true,
                  TextAlert:UPDATE_SUCCESS,
                  ListAccount:updateArrById(state.ListAccount,action.payload.item)
                }
              case UPDATE_ACCOUNT_LOGIN_QUAN_TRI_FAIL:
                return{
                  ...state,
                  AlertFail:true,
                  TextAlert:action.payload.item.Result,
                }
              case LOGOUT_SYSTEM:
                return{
                  ...state,
                    ShowComponentQuanTri:false,                
                    TaiKhoanSieuQuanTri:state.ItemLogin.role===0&&false,
                    TaiKhoanQuanTri:state.ItemLogin.role===1&&false,
                    TaiKhoanQuanLy:state.ItemLogin.role===2&&false,
                    TaiKhoanNhanVien:state.ItemLogin.role===3&&false,
                    TaiKhoanKhachHang:state.ItemLogin.role===4&&false,
                    TaiKhoanDoiTac:state.ItemLogin.role===5&&false,
                    ItemLogin:null,
                }

              case LOGIN_PAGE_QUAN_TRI_FAIL:
                  return{
                    ...state,
                    TaiKhoanSieuQuanTri:false,
                    AlertFail:true,
                    TextAlert:action.payload.item,
                    ItemLogin:null,
                    ShowComponentQuanTri:false
                  }

              case LOGIN_PAGE_QUAN_TRI_SUCCESS:
                  let Item   = action.payload.item.findNameLogin
                  let GetAll = action.payload.item.GetAll
                  return{
                    ...state,
                    ListAccount:GetAll,
                    ShowComponentQuanTri:true,
                    ItemLogin:Item,                   
                    TaiKhoanSieuQuanTri:Item.role===0?true:false,
                    TaiKhoanQuanTri:Item.role===1?true:false,
                    TaiKhoanQuanLy:Item.role===2?true:false,
                    TaiKhoanNhanVien:Item.role===3?true:false,
                    TaiKhoanKhachHang:Item.role===4?true:false,
                    TaiKhoanDoiTac:Item.role===5?true:false,
                    AlertSuccess:true,
                    TextAlert:"Đăng Nhập Thành Công"
                  }

              case GET_ALL_ACCOUNT_TO_STORE_QUAN_TRI:
                return{
                  ...state,
                  ListAccount:action.payload.item
                }
              // case OPEN_TAI_KHOAN_SIEU_QUAN_TRI:
              //   return{
              //       ...state,
              //       TaiKhoanSieuQuanTri:true
              //   }
              // case CLOSE_TAI_KHOAN_SIEU_QUAN_TRI:
              //   return{
              //       ...state,
              //       TaiKhoanSieuQuanTri:false
              //   }
              case CREATE_NEW_ACCOUNT_QUANTRI_SUCCESS:
                return{
                  ...state,
                  AlertSuccess:true,
                  TextAlert:CREATE_SUCCESS,
                  ListAccount:[action.payload.item].concat(state.ListAccount)
                }
              case CREATE_NEW_ACCOUNT_QUANTRI_FAIL:
                return{
                  ...state,
                  AlertFail:true,
                  TextAlert:action.payload.item
                }                
                  
              case CLOSE_NEW_ACCOUNT_QUANTRI_SUCCESS:
                return{
                  ...state,
                  AlertSuccess:false,
                  // TextAlert:""
                }

              case CLOSE_NEW_ACCOUNT_QUANTRI_FAIL:
                  return{
                    ...state,
                    AlertFail:false,
                    // TextAlert:""
                  }

              case LOADING_COMPONENT_CLOSE:
                return{
                  ...state,
                  Loading:false
                }
              case LOADING_COMPONENT_OPEN:
                return{
                  ...state,
                  Loading:true
                }
              case CLOSE_FORM_AFTER_CREATE_SUCCESS:
                return{
                    ...state,
                    CloseForm:false
                }
              case OPEN_FORM_BEFORE_CREATE_SUCCESS:
                return{
                  ...state,
                  CloseForm:true
                }

               //INFO GROUP QUANTRI
               case ADD_UPDATE_QUAN_TRI_FAIL:
                return{
                  ...state,
                  AlertFail:true,
                  TextAlert:UPDATE_FAIL,
                }
            case ADD_UPDATE_QUAN_TRI_SUCCESS:
                  return{
                    ...state,
                    AlertSuccess:true,
                    TextAlert:UPDATE_SUCCESS,
                  }
                
         
                default:   return state;
            }
}

export default IndexReducer