export const ADD_CAN_DO_SUC_KHOE_BMI          = "ADD_CAN_DO_SUC_KHOE_BMI"
export const CREATE_CAN_DO_SUC_KHOE           = "CREATE_CAN_DO_SUC_KHOE"

export const DISPATCH_CREATE_CAN_DO_TO_STORE  = "DISPATCH_CREATE_CAN_DO_TO_STORE"
export const UPDATE_CAN_DO_SUC_KHOE   = "UPDATE_CAN_DO_SUC_KHOE"

export const ADD_UPLOAD_FILE_HOC_SINH_CAN_DO   = "ADD_UPLOAD_FILE_HOC_SINH_CAN_DO"
export const DISPATCH_ID_CLASS_TO_STORE_CAN_DO = "DISPATCH_ID_CLASS_TO_STORE_CAN_DO"
export const DISPATCH_MONTH_YEAR_CAN_DO_TO_STORE = "DISPATCH_MONTH_YEAR_CAN_DO_TO_STORE"

export const DISPATCH_ADD_HOC_SINH_CAN_DO_TO_STORE   = "DISPATCH_ADD_HOC_SINH_CAN_DO_TO_STORE"
export const GET_LIST_HOC_SINH_CAN_DO      = "GET_LIST_HOC_SINH_CAN_DO"
export const DISPATCH_LIST_HOC_SINH_CAN_DO_TO_STORE = "DISPATCH_LIST_HOC_SINH_CAN_DO_TO_STORE"

export const UPLOAD_FILE_CAN_DO_FAIL = "UPLOAD_FILE_CAN_DO_FAIL"
export const UPDATE_HOC_SINH_CAN_DO  = "UPDATE_HOC_SINH_CAN_DO"
export const UPDATE_HOC_SINH_CAN_DO_AGAIN = "UPDATE_HOC_SINH_CAN_DO_AGAIN"
export const DISPATCH_UPDATE_HOC_SINH_CAN_DO_AGAIN = "DISPATCH_UPDATE_HOC_SINH_CAN_DO_AGAIN"

export const DELETE_HOC_SINH_CAN_DO = "DELETE_HOC_SINH_CAN_DO"
export const CREATE_HOC_SINH_CAN_DO = "CREATE_HOC_SINH_CAN_DO"