import styled,{keyframes} from 'styled-components'
import {device} from '../../../device'

export const gradient = keyframes`
    100% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    0% {
        background-position: 0% 0%;
    }
`


export const ContentVideo = styled.div`
width:100%;
height:700px;
margin:auto;
padding:50px;
display:flex;
background:#171616;   
    &:hover{
        transition-delay:2s;
        background: linear-gradient(315deg,#34495e 10%, rgba(0, 0, 0, 0.87) 38%,  #7f8c8d  68%,#16a085 85%, rgba(0, 0, 0, 0.87) 98%);
        animation: ${gradient} 15s ease infinite;
        background-size: 200% 200%;
        background-attachment: fixed;
    }
@media (max-width:768px){
        display:block;
        height:800px;  
        padding:20px;      
}
`

export const VideoContent = styled.div`
        width:50%;
        height:100%;
        margin:auto;
        margin-left:20px;
        padding:20px;
        padding-top:40px;
        background:#171616;
        @media ${device.mobile}{
                margin:auto;
                width:100%;
                height:400px;
                padding:0;
                margin-top:-328px;
                background:white;
        }
`

export const HuongDanContent = styled.div`
        width:50%;
        height:100%;
        margin:auto;
        padding:20px;
        padding-top:40px;
        background:#171616;
        @media ${device.mobile}{
                margin:auto;
                width: 95%;
                height: 460px;
                padding: 0;
                margin-top: 124px;
                margin-left: 14px;
                background:white;
        }

`

