import React, { Suspense } from 'react';
import {Grid,DialogContent,Dialog,Box} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import styled from 'styled-components';
import ListRowView from './ListRowView'
import {useSelector} from 'react-redux'

const StyledDialog = styled(Dialog)`
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  max-width:600px;
}
`
const StyledDialogContent = styled(DialogContent)`
  .MuiDialogContent-root { 
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 15px 65px;
  } 
`
const Index =({open,handleClose})=> {  
  const EditTemplete = useSelector(state=>state.GovLogin.EditTemplete)
  const ShowSettingAdvance =()=>{
    return(
      <>
          <Box textAlign="center">    
          {EditTemplete===true?(
                  <Alert variant="filled" severity="error">Cập nhật Biểu mẫu</Alert> 
              ):(
                <Alert variant="filled" severity="info">Thông tin Biểu mẫu</Alert> 
              )}
             </Box>      
             <>       
                      <StyledDialogContent>                   
                            <Grid container>  
                                      <ListRowView                                      
                                      />                                                                          
                             </Grid>                     
                      </StyledDialogContent>
              </>     
      </>
    )
  }
  return (    
      <Suspense fallback={()=>{}}>
      <StyledDialog open={open} onClose={handleClose}>                    
                  {ShowSettingAdvance()}
      </StyledDialog>
      </Suspense>
  );
}

export default React.memo(Index)