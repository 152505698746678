export const GET_THANH_TOAN_BY_USER               = "GET_THANH_TOAN_BY_USER"
export const UPDATE_THANH_TOAN_BY_USER            = "UPDATE_THANH_TOAN_BY_USER"
export const DISPATCH_THANH_TOAN_BY_USER_TO_STORE = "DISPATCH_THANH_TOAN_BY_USER_TO_STORE"
export const UPDATE_THANH_TOAN_BY_USER_GIA_HAN    = "UPDATE_THANH_TOAN_BY_USER_GIA_HAN"


export const EMAIL_SEND_NPP_SUCCESS = "EMAIL_SEND_NPP_SUCCESS" //gui email cho ke toan de xuat hoa don

export const GET_ADD_PAYMENT_KIDTOP                = "GET_ADD_PAYMENT_KIDTOP"
export const DISPATCH_LIST_PAYMENT_KIDTOP_TO_STORE = "DISPATCH_LIST_PAYMENT_KIDTOP_TO_STORE"
export const UPDATE_PAYMENT_KIDTOP                = "UPDATE_PAYMENT_KIDTOP"

export const DISPATCH_VALUE_PAYMENT_KIDTOP        = "DISPATCH_VALUE_PAYMENT_KIDTOP"