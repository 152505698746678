import React,{useEffect,useRef, useState} from 'react';
import {Dialog,Box,Typography,TableContainer,Table,TableHead,TableRow,TableCell,TableBody,Paper, Grid
,DialogContent} from '@material-ui/core'
import {useSelector} from 'react-redux'
import {useStyles}from './styles'
import {A5,A4}from 'paper-print-react'
import {changeUpperCase}from '../../../../helper/fnAllways'
import {showDD,showMM,showYYYY} from '../../../../helper/dateTime/handleDMY'
import {ShowDateName} from '../../ClassActive/helper'
import { Fragment } from 'react';
import styled from 'styled-components'



const StyledDialog = styled(Dialog)`
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  max-width:844px;
}
`
const StyleDialogContent = styled(DialogContent)`
.MuiDialogContent-root.sc-fzqNJr.hxhJDK{
  margin: -50px 0 0px -14px;
}
`
export default function Index({open,handleClose,list}) {
  const classes = useStyles()
  const ListInfoUser                 = useSelector(state=>state.ManagerAccount.ListInfoUser)  //list
  let{infoUser,templeteUser}         = ListInfoUser

  


  const ShowList=(list)=>{
      return list.map((v,i)=>{
        return<>
              <TableRow key={i} border="1"> 
                    <TableCell align="center">{i+1}</TableCell>
                    <TableCell align="center">{changeUpperCase(v.nameClass)}</TableCell>
                    <TableCell align="center">{v.numCheckDay}</TableCell> 
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>                   
               </TableRow>                      
            </>
      })
  }

  const HtmlShow =(v)=>{    
    let SumListNotEat =  (v.map(v=>v.numCheckDay)).reduce((x,y)=>x+y,0)
    return(   
      <>   
        <A5 landscape >
          <Box display="flex" p={1} 
          className={v.length>5?classes.PrintAllHeader:""}
          >
              <Box p={1} flexGrow={1}>                  
                  <Typography>{templeteUser.masterOrigin.toUpperCase()}</Typography>  
                  <Typography component="h6">{changeUpperCase(infoUser.workPlace)}</Typography>
              </Box>                           
          </Box>  
          <Box display="flex" justifyContent="center">       
            <Box p={1}>
                <Typography className={classes.head3Repo} component="h3">BÁO ĂN CÁC LỚP</Typography>                 
            </Box>               
          </Box>  

          <Box display="flex" justifyContent="center">       
            <Box p={1}>
                <Typography className={classes.date}>{ShowDateName(v[0].date).dateName},&nbsp;ngày&nbsp;{showDD(v[0].date)}&nbsp;tháng&nbsp;{showMM(v[0].date)}&nbsp;năm&nbsp;{showYYYY(v[0].date)}</Typography>                            
            </Box>               
          </Box>       

          <TableContainer component={Paper} border="1">    
          <Table className={classes.table} stickyHeader aria-label="sticky table" size="small" border="1" >
              <TableHead border="1">
                <TableRow >
                  <TableCell align="center">Stt</TableCell>                                    
                  <TableCell align="center">Lớp</TableCell>                  
                  <TableCell align="center">Sỉ Số báo ăn</TableCell>
                  <TableCell align="center" border="1">Ký Tên</TableCell>
                  <TableCell align="center" border="1">Ghi chú</TableCell>
                </TableRow>
              </TableHead>
              <TableBody border="1">       
                      {ShowList(v)}
                      <TableRow border="1">
                        <TableCell align="center" colSpan="2" >Tổng số</TableCell>
                        <TableCell align="center">{SumListNotEat}</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className={v.length>5?classes.PrintAllFooter:""}>
            <Grid container >
                <Grid item xs={4}>
                <Box p="1" display="flex" justifyContent="center" className={classes.footerOne}>
                        <Typography component="h4">Cấp Dưỡng</Typography>
                    </Box>
                    {/* <Box p="1" display="flex" justifyContent="center" className={classes.footerSecond}>
                        <Typography>{changeUpperCase(infoUser.directorName)}</Typography>
                    </Box> */}
                </Grid>
                <Grid item xs={4}>
                <Box p="1" display="flex" justifyContent="center" className={classes.footerOne}>
                        <Typography component="h4">Kế toán</Typography>
                    </Box>
                </Grid>
              <Grid item xs={4}>
                    <Box p="1" display="flex" justifyContent="center" className={classes.footerOne}>
                        <Typography component="h4">HIỆU TRƯỞNG</Typography>
                    </Box>
                    <Box p="1" display="flex" justifyContent="center" className={classes.footerSecond}>
                        <Typography>{changeUpperCase(infoUser.directorName)}</Typography>
                    </Box>
              </Grid>
            </Grid>  
          </div>
        </A5>       
      </>      
    )
  }

  const ShowAll=()=>{
    return list.map((v,i)=>{
        return<Fragment key={i} >
              {HtmlShow(v)}
            </Fragment>
    })
  }

  return (       
         <StyledDialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">  
         <StyleDialogContent>     
             {ShowAll()}             
         </StyleDialogContent>
         </StyledDialog>   
  )
}
