import React,{Fragment} from 'react'
import {compose,bindActionCreators} from 'redux'
import cn from 'classnames'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/styles'
import style from './style'
import Header from './Header/index'
import Sidebar from './SideBar/index'
import * as uiActions from '../../actions/uiLoading'


class index extends React.Component{  

  handleToggleSidebar = value => {
    const { uiActions } = this.props;
    const { showSidebar, hideSidebar } = uiActions;
    if (value === true) {
      showSidebar();
    } else {
      hideSidebar();
    }
  }

  render() {
    let {children,name,showSidebar,classes} = this.props    
    
    return (
      <Fragment>        
           <Header name={name} onToggleSidebar={this.handleToggleSidebar} open={showSidebar} 
           />
            <div className={classes.wrapper}>
          <Sidebar
            showSidebar={showSidebar}
            onToggleSidebar={this.handleToggleSidebar}
          />
          <div
            className={cn(classes.wrapperContent, {
              [classes.shiftLeft]: showSidebar === true
            })}
          >
            {children}
          </div>
        </div>        
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    showSidebar: state.uiLoading.showSidebar,    
    loginUser:state.users.loginUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uiActions: bindActionCreators(uiActions, dispatch)
  };
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withStyles(style), 
  withConnect
  )(index)