import React from 'react'
import style from './style'
import {withStyles} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'


class TaskFind extends React.Component{  

  render() {
    let {classes,valueInputSearch} = this.props
    return (
          <form noValidate="off">
            <div >
            <TextField                 
                  label="Ban can tim gi"
                  type="search"                  
                  margin="normal"
                  className={classes.searchHeader}
                  onChange={valueInputSearch}
            />            
            </div>            
          </form>
    )
  }
}

export default withStyles(style)(TaskFind)