import React, { useState } from 'react';
import {Box, Button,Dialog,DialogActions,DialogContent,TextField,Typography} from '@material-ui/core';
import { changeUpperCase, ValidateEmailAddress, ValidTel } from '../../../../helper/fnAllways';
import { Alert } from '@material-ui/lab';
import { UpdateByIdStudent } from './Fn';
import { UPDATE_ITEM_STU_SEND_MESS } from '../../../contant/Class';
import { useDispatch } from 'react-redux';



const Index=React.memo(({open,handleClose})=> {
  const dispatch                   = useDispatch()
  const [email,setEmail]           = useState(open.value.email===null?"":open.value.email)
  const [tel,setTel]               = useState(open.value.tel===null?"":open.value.tel)
  const [nameParent,setNameParent] = useState(open.value.nameParent===null?"":open.value.nameParent)
  const [errEmail,setErrEmail]     = useState("")
  const [errTel,setErrTel]         = useState("")
  const [errNameParent,setErrNameParent] = useState("")
  const SaveItem=()=>{
      let itemStudent={
          idStudent:open.value.idStudent,
          fullName:open.value.fullName,
          nameParent:nameParent,
          email,
          tel,
          sendEmail:false,
          sendSms:false,          
          message:open.value.message
      }     

       if(itemStudent.nameParent===""){
          setErrNameParent(false)
          setTimeout(()=>{
                setErrNameParent("")
          },3000)
          return
       }
       if(!ValidTel(itemStudent.tel)){
          setErrTel(false)
          setTimeout(()=>{
              setErrTel("")
          },3000)
       }
   
       if(!ValidateEmailAddress(itemStudent.email)){
        setErrEmail(false)
        setTimeout(()=>{                
            setErrEmail("")
        },3000)
        return
       }
     let updateItem = UpdateByIdStudent(open.List,itemStudent)
     dispatch({type:UPDATE_ITEM_STU_SEND_MESS,payload:{item:updateItem}})     
     handleClose()
  }
  return (
    <div>           
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
          <Box>
              <Alert severity='info'>
                 <Typography>Thông tin Học Sinh : {changeUpperCase(open.value.fullName)}</Typography>
              </Alert>
          </Box>
        <DialogContent>
            <TextField variant='outlined' fullWidth color='primary'  style={{margin:4}} size="small" label="Họ Tên Cha Mẹ"
                onChange={e=>setNameParent(e.target.value)}
                defaultValue={nameParent} 
                error={errNameParent===false?true:false}                                        
            />
            <TextField variant='outlined' fullWidth color='primary'  style={{margin:4}} size="small" label="Số Điện Thoại"
                onChange={e=>setTel(e.target.value)}
                defaultValue={tel}     
                error={errTel===false?true:false}       
            />
            <TextField variant='outlined' fullWidth color='primary'  style={{margin:4}} size="small" label="Email"
                onChange={e=>setEmail(e.target.value)}
                defaultValue={email}   
                error={errEmail===false?true:false}           
            />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary">
          Thoát
          </Button>
          <Button onClick={()=>SaveItem()} color="primary" autoFocus>
           Lưu
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})

export default Index
