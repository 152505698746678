import axiosServices from '../../../../api-services/api'
import {API_ENDPOINT} from  '../../../../contant/index'


const urlAddTableAge = "addTableAgeNutrikid"
const urlAddFirstTableAge = "addFirstTableAgeNutrikid"

export const AddTableAgeApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddTableAge}`,item)
}

export const AddFirstTableAgeApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddFirstTableAge}`,item)
}