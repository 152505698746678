import * as TypeGov from '../../contant/Gov/softFood'

export const ActiveSoftFood = () =>{
  return {
    type:TypeGov.ACTIVE_SOFT_FOOD,    
  }
}

export const ActiveSoftFoodCloseBox = () =>{
  return {
    type:TypeGov.ACTIVE_SOFT_FOOD_CLOSE_BOX,    
  }
}

export const ActiveSoftFoodFail =()=>{
  return {
    type:TypeGov.ACTIVE_SOFT_FOOD_FAIL
  }
}

export const ActiveSoftFoodStatus =(item)=>{
  return{
    type:TypeGov.ACTIVE_SOFT_FOOD_STATUS,
    payload:{item}
  }
}
export const ActiveSoftFoodUserStatus =(user)=>{
  return{
    type:TypeGov.ACTIVE_SOFT_FOOD_USER_STATUS,
    payload:{user}
  }
}

export const DispatchGetListUserThanhToanToStore=(item)=>{
    return{
      type:TypeGov.DISPATCH_GET_LIST_USER_THANH_TOAN_TO_STORE,
      payload:{item}
    }
}