import React, { Suspense } from 'react';
import {Button,Dialog,DialogActions,DialogContent,Typography} from '@material-ui/core';
import {changeUpperCase} from '../fnAllways/index'
import {useStyles} from './styles'

const AlertForm =React.memo(({open,value,handleSubmit}) => {
 const classes = useStyles()
  return (
    <Suspense fallback={()=>{}}>    
      <Dialog open={open} aria-labelledby="form-dialog-title">        
        <DialogContent>         
              <Typography className={classes.colorText}>{changeUpperCase(value)}</Typography>                 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} variant="contained" color="secondary">
            Thoát
          </Button>         
        </DialogActions>
      </Dialog>
    </Suspense>
  );
})
export default AlertForm