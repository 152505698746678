import axiosServices from '../../../../api-services/api'
import {API_ENDPOINT} from  '../../../../contant/index'

const urlGetAllMealNutrikid = 'GetAllMealNutrikid'

export const GetAllMealNutrikidApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetAllMealNutrikid}`,item)
}




