export const REDIRECT_WHEN_NOT_CREATE_ID_YEAR_SCHOOL = "REDIRECT_WHEN_NOT_CREATE_ID_YEAR_SCHOOL"
export const OPEN_FORM_CREATE_ACCOUNT_CLASS          = "OPEN_FORM_CREATE_ACCOUNT_CLASS"
export const CLOSE_FORM_CREATE_ACCOUNT_CLASS         = "CLOSE_FORM_CREATE_ACCOUNT_CLASS"
export const DISPATCH_ITEM_CREATE_ACCOUNT_TO_STORE   = "DISPATCH_ITEM_CREATE_ACCOUNT_TO_STORE"
export const GET_LIST_CREATE_ACCOUNT_NOT_SAVE        = "GET_LIST_CREATE_ACCOUNT_NOT_SAVE"
export const UPDATE_ITEM_CREATE_ACCOUNT_NOT_SAVE     = "UPDATE_ITEM_CREATE_ACCOUNT_NOT_SAVE"

export const DISPATCH_ID_CLASS_ACTIVE_TO_STORE       = "DISPATCH_ID_CLASS_ACTIVE_TO_STORE"


export const OPEN_FORM_VIEW_ACCOUNT_CLASS            = "OPEN_FORM_VIEW_ACCOUNT_CLASS"
export const CLOSE_FORM_VIEW_ACCOUNT_CLASS           = "CLOSE_FORM_VIEW_ACCOUNT_CLASS"

export const DISPATCH_ACTIVE_ALL_ACCOUNT             = "DISPATCH_ACTIVE_ALL_ACCOUNT"
export const DISPATCH_ACTIVE_ALL_ACCOUNT_ALL_TO_STORE= "DISPATCH_ACTIVE_ALL_ACCOUNT_ALL_TO_STORE"

export const DISPATCH_GET_LIST_ACTIVE_ACCOUNT           = "DISPATCH_GET_LIST_ACTIVE_ACCOUNT"
export const DISPATCH_GET_LIST_ACTIVE_ACCOUNT_TO_STORE  = "DISPATCH_GET_LIST_ACTIVE_ACCOUNT_TO_STORE"

export const OPEN_FORM_ALERT_WHEN_NOT_SELECT_YEAR_SCHOOL  = "OPEN_FORM_ALERT_WHEN_NOT_SELECT_YEAR_SCHOOL"
export const CLOSE_FORM_ALERT_WHEN_NOT_SELECT_YEAR_SCHOOL = "CLOSE_FORM_ALERT_WHEN_NOT_SELECT_YEAR_SCHOOL"

export const DISPATCH_ITEM_MANAGER_ACCOUNT_CLASS_ACTIVE_TO_STORE = "DISPATCH_ITEM_MANAGER_ACCOUNT_CLASS_ACTIVE_TO_STORE"

export const DISPATCH_COUNT_EAT_MANAGER_ACCOUNT           = "DISPATCH_COUNT_EAT_MANAGER_ACCOUNT"
export const OPEN_FORM_EMPTY_COUNT_EAT                    = "OPEN_FORM_EMPTY_COUNT_EAT"
export const CLOSE_FORM_EMPTY_COUNT_EAT                   = "CLOSE_FORM_EMPTY_COUNT_EAT"

export const DISPATCH_ARR_EMPTY_COUNT_EAT                 = "DISPATCH_ARR_EMPTY_COUNT_EAT"

export const FIND_MENU_WEEKLY_DB_BY_USERID_DATE_NOW       = "FIND_MENU_WEEKLY_DB_BY_USERID_DATE_NOW"

export const DISPATCH_ITEM_MENU_WEEKLY_TO_STORE           = "DISPATCH_ITEM_MENU_WEEKLY_TO_STORE"

export const ADD_APPROVAL_CHECK_DAY_FOOD                            = "ADD_APPROVAL_CHECK_DAY_FOOD" //tao 1 phe duyet cham an
export const DISPATCH_ITEM_APPROVAL_CHECK_DAY_FOOD_TO_STORE         = "DISPATCH_ITEM_APPROVAL_CHECK_DAY_FOOD_TO_STORE"
export const DISPATCH_ITEM_APPROVAL_CHECK_DAY_FOOD_TO_STORE_FROM_DB = "DISPATCH_ITEM_APPROVAL_CHECK_DAY_FOOD_TO_STORE_FROM_DB"
export const DISPATCH_UPDATE_APPROVAL_FROM_CLASS                    = "DISPATCH_UPDATE_APPROVAL_FROM_CLASS"


export const DISPATCH_MANAGER_ACCOUNT_RECICVE_REQUEST     = "DISPATCH_MANAGER_ACCOUNT_RECICVE_REQUEST"
export const DISPATCH_CLASS_ACTIVE_AWAIT_APPROVAL_ACCEPT  = "DISPATCH_CLASS_ACTIVE_AWAIT_APPROVAL_ACCEPT"

export const OPEN_ALERT_LIST_STUDENT_NOT_REGIS            = "OPEN_ALERT_LIST_STUDENT_NOT_REGIS"
export const CLOSE_ALERT_LIST_STUDENT_NOT_REGIS           = "CLOSE_ALERT_LIST_STUDENT_NOT_REGIS"
export const DISPATCH_LOGOUT_USER_CLASS                   = "DISPATCH_LOGOUT_USER_CLASS"

export const OPEN_LIST_STUDENT_NOT_EAT           = "OPEN_LIST_STUDENT_NOT_EAT"
export const CLOSE_LIST_STUDENT_NOT_EAT          = "CLOSE_LIST_STUDENT_NOT_EAT"

export const OPEN_UPDATE_APPROVAL_CHECK_DAY_FOOD   = "OPEN_UPDATE_APPROVAL_CHECK_DAY_FOOD"
export const CLOSE_UPDATE_APPROVAL_CHECK_DAY_FOOD  = "CLOSE_UPDATE_APPROVAL_CHECK_DAY_FOOD"

export const OPEN_COUNT_EAT_CHECK_DAY_FOOD          = "OPEN_COUNT_EAT_CHECK_DAY_FOOD"
export const CLOSE_COUNT_EAT_CHECK_DAY_FOOD          = "CLOSE_COUNT_EAT_CHECK_DAY_FOOD"

export const DISPATCH_DATE_INPUT_TO_UPDATE_COUNT_EAT = "DISPATCH_DATE_INPUT_TO_UPDATE_COUNT_EAT"
export const DISPATCH_CANCEL_UPDATE_COUNT_EAT        = "DISPATCH_CANCEL_UPDATE_COUNT_EAT"

export const DISPATCH_CHANGE_UPDATE_CHECK_DAY_FOOD    = "DISPATCH_CHANGE_UPDATE_CHECK_DAY_FOOD"

export const OPEN_CHECK_DAY_FOOD_COUNT_EAT            = "OPEN_CHECK_DAY_FOOD_COUNT_EAT"
export const CLOSE_CHECK_DAY_FOOD_COUNT_EAT           = "CLOSE_CHECK_DAY_FOOD_COUNT_EAT"

export const GET_STUDENT_LEAVE_TEMPORATY_BY_CLASS     = "GET_STUDENT_LEAVE_TEMPORATY_BY_CLASS"
export const DISPATCH_LIST_LEAVE_TEMPORATY_BY_CLASS_TO_STORE = "DISPATCH_LIST_LEAVE_TEMPORATY_BY_CLASS_TO_STORE"

export const DISPATCH_ITEM_STU       = "DISPATCH_ITEM_STU"
export const CLOSE_DISPATCH_ITEM_STU = "CLOSE_DISPATCH_ITEM_STU"

export const ADD_ITEM_STU_SEND_MESS         = "ADD_ITEM_STU_SEND_MESS"
export const DISPATCH_ADD_STU_MESS_TO_STORE = "DISPATCH_ADD_STU_MESS_TO_STORE"
export const UPDATE_ITEM_STU_SEND_MESS      = "UPDATE_ITEM_STU_SEND_MESS"
export const GET_ITEM_STU_SEND_MESS         = "GET_ITEM_STU_SEND_MESS"


export const ADD_UPDATE_STUDENT_REGIS_KHOAN_THU  = "ADD_UPDATE_STUDENT_REGIS_KHOAN_THU"
export const ITEM_CLASS_TO_STORE = "ITEM_CLASS_TO_STORE"

