import React from 'react';
import {Dialog,DialogContent,TableContainer,TableHead,TableCell,TableRow,TableBody,Paper,Table,Typography} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import {useStyles} from './styles'
import {changeUpperCase} from '../../../../helper/fnAllways'
import {ArrStudentSort,sortByNameLast} from '../../../../helper/tableList/tableList'
import {useSelector} from 'react-redux'
import {showMMYY} from '../../../../helper/dateTime/handleDMY'
import _ from 'lodash';

export default function Index({open,handleClose,value,name,idClass}) {
  const classes = useStyles()
  const SelectDate                    = useSelector(state => state.ManagerAccount.SelectDate)
  const ListStudentLeaveTemporaty     = useSelector(state=>state.ManagerAccount.ListStudentLeaveTemporaty)    
  const FilterIdClass                 = ListStudentLeaveTemporaty.filter(v=>v.idClass===idClass)

  const ShowInfoLeaveTmp =(vv)=>{        
      if(FilterIdClass.length===0) return false
      return _.first(FilterIdClass).students.some(v=>v.active===true&&vv.idStudent===v.idStudent)      
  }
  const showList=()=>{
    return sortByNameLast(ArrStudentSort(value)).map((v,i)=>{      
      return <TableRow key={i}>
                <TableCell align="center">{i+1}</TableCell>
                <TableCell align="left">{changeUpperCase(v.fullName)}
                {ShowInfoLeaveTmp(v)===true&&<>---&nbsp;<Typography component="span" color="secondary">Nghỉ tạm thời</Typography></>}
                </TableCell>               
      </TableRow>
    })
  }
  return (    
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Alert severity="info">Danh sách học sinh {showMMYY(SelectDate)} Lớp {changeUpperCase(name)}</Alert>
        <DialogContent>          
        <TableContainer component={Paper}>    
            <Table className={classes.table} stickyHeader aria-label="sticky table" size="small" >
              <TableHead>
                <TableRow>
                  <TableCell align="center">Stt</TableCell>                                    
                  <TableCell align="left">Tên Học Sinh</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>       
                      {showList()}
              </TableBody>
            </Table>
      </TableContainer>
        </DialogContent>     
      </Dialog>
  );
}
