import { TextField,Button, Checkbox} from '@material-ui/core';
import React, { useState } from 'react';
import { ValidTel,ValidateEmailAddress } from '../../../helper/fnAllways';
import { MenuCap,Points,MemberCards,Sex,Tinh_Cach,Thu_Nhap,Hon_Nhan,Partner,CO_HOI,Nguon_Co_Hoi} from './fn';
import { ContentDiv, ItemDiv, MainDiv, TextName } from './style';
import {useDispatch} from 'react-redux'
import { DISPATCH_CREATE_NEW_CRM } from '../../../contant/CRM';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const AddCrm = ({open,handleClose}) => {
    const dispatch               = useDispatch()
    const [typeCap, setTypeCap]  = useState(7)
    const [HoTen,setHoTen]       = useState("")
    const [Sdt,setSdt]           = useState("")
    const [CCCD,setCCCD]         = useState("")
    const [NgaySinh,setNgaySinh] = useState("")
    const [DiaChi,setDiaChi]     = useState("")
    const [TinhTp,setTinhTp]     = useState("")
    const [Email,setEmail]       = useState("")
    const [TichDiem,setTichDiem] = useState(0)
    // const [TheThanhVien,setTheThanhVien] = useState(0)
    const [GhiChu,setGhiChu]     = useState("")


    const [ToChuc,setToChuc]     = useState("")
    const [ChucDanh,setChucDanh] = useState("")
    const [TinhCach,setTinhCach] = useState(1)
    const [GioiTinh,setGioiTinh] = useState(1)
    const [ThuNhap,setThuNhap]   = useState(5)
    const [HonNhan,setHonNhan]     = useState(1)
    //const [KHPartner,setKhPartner]       = useState(1)
    const [arrKHPartner,setArrKHPartner] = useState([])
    const [Spdv,setSpdv]           = useState("")
    const [UuDai,setUuDai]         = useState("")

    const [NguoiGioiThieu,setNguoiGioiThieu] = useState("")
    const [NguoiPhuTrach,setNguoiPhuTrach] = useState("")
    const [DoanhSoDuKien,setDoanhSoDuKien] = useState("")
    const [ChietKhau,setChietKhau]         = useState("")
    const [LoiNhuan,setLoiNhuan]           = useState("")
    const [LienHeLanCuoi,setLienHeLanCuoi] = useState("")
    const [HanhDongTiepTheo,setHanhDongTiepTheo]   = useState("")
    const [LanLienHeTiepTheo,setLanLienHeTiepTheo] = useState("")
    const [TinhTrangCoHoi,setTinhTrangCoHoi] = useState(1)
    const [NguonCoHoi,setNguonCoHoi] = useState(1)


    const [errHT,setErrHT] = useState(false)
    const [errTC,setErrTC] = useState(false)
    const [errCD,setErrCD] = useState(false)

    const [errSdt,setErrSdt]         = useState(false)
    const [errEmail,setErrEmail]     = useState(false)
    const [errTextSdt,setErrTextSdt] = useState("")
    const [errTextEmail,setErrTextEmail] = useState("")

    const icon        = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const SaveData=()=>{
        if(HoTen===null||HoTen===""){
                setErrHT(true)
                setTimeout(()=>{
                    setErrHT(false)
                },3000)
                return
        }

    //     if(ToChuc===null||ToChuc===""){
    //         setErrTC(true)
    //         setTimeout(()=>{
    //             setErrTC(false)
    //     },3000)
    //     return
    //     }

    //     if(ChucDanh===null||ChucDanh===""){
    //         setErrCD(true)
    //         setTimeout(()=>{
    //             setErrCD(false)
    //     },3000)
    //     return
    //    }   

       if(!ValidTel(Sdt)&&Sdt!==""){
        setErrSdt(true)
        setErrTextSdt("Lỗi, SĐT không đúng định dạng!")
        setTimeout(()=>{
            setErrSdt(false)
            setErrTextSdt("")
        },3000)
        return
     }   
     if(!ValidateEmailAddress(Email)&&Email!==""){
        setErrEmail(true)
        setErrTextEmail("Lỗi, Email không đúng định dạng!")
        setTimeout(()=>{
            setErrEmail(false)
            setErrTextEmail("")
        },3000)
        return
     }   
     let item ={
        typeCap,HoTen,Sdt,CCCD,NgaySinh,DiaChi,TinhTp,Email,TichDiem:+TichDiem,GhiChu,
        ToChuc,ChucDanh,TinhCach,GioiTinh:+GioiTinh,ThuNhap:+ThuNhap,HonNhan:+HonNhan,KHPartner:arrKHPartner,Spdv,UuDai,
        NguoiGioiThieu,NguoiPhuTrach,DoanhSoDuKien:+DoanhSoDuKien,ChietKhau:+ChietKhau,LoiNhuan:+LoiNhuan,LienHeLanCuoi,HanhDongTiepTheo,LanLienHeTiepTheo,TinhTrangCoHoi:+TinhTrangCoHoi,NguonCoHoi:+NguonCoHoi
     }
     //console.log(item)

     dispatch({type:DISPATCH_CREATE_NEW_CRM,payload:{item}})
     handleClose()
    }

    return (
        <>
                <Button variant="outlined" color="primary"
                onClick={()=>SaveData()}
                style={{display:'block',position:'fixed',marginTop:30,marginBottom:30}}
                >Lưu Dữ Liệu</Button>
            <MainDiv>
                <ContentDiv>
                {/* row 1 */}
                   {/* Loai Tai khoan */}
                        <ItemDiv>
                                <TextName style={{color:'#27ae60'}} >&nbsp;Loại Tài Khoản : </TextName>
                                <TextField
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={typeCap}
                                onChange={e=>setTypeCap(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {MenuCap.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>                       
                        </ItemDiv>
                        {/* Ho Ten */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>Họ tên : </TextName>
                            <TextField
                            error={errHT}
                            onChange={e=>setHoTen(e.target.value)}
                            fullWidth size="small" variant="outlined"
                            multiline
                            />                            
                        </ItemDiv>
                        {/* SDT */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;Số Điện Thoại : </TextName>
                            <TextField
                               multiline
                                error={errSdt}
                                helperText={errTextSdt}
                            onChange={e=>setSdt(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* CCCD */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;CCCD: </TextName>
                            <TextField   
                            multiline                       
                            onChange={e=>setCCCD(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Ngay Sinh */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;Ngày Sinh: </TextName>
                            <TextField  
                             id="date"
                            type="date"
                            defaultValue={NgaySinh}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            onChange={e=>setNgaySinh(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* dia chi */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;Địa chỉ: </TextName>
                            <TextField       
                            multiline                    
                            onChange={e=>setDiaChi(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Tinh/tp */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;Tỉnh/Tp: </TextName>
                            <TextField        
                            multiline                   
                            onChange={e=>setTinhTp(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                            {/* Email */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;Email: </TextName>
                            <TextField       
                            multiline                     
                            onChange={e=>setEmail(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>   
                            {/* Tich Diem */}
                        <ItemDiv>
                                <TextName style={{color:'#27ae60'}}>&nbsp;Tích Điểm : </TextName>
                                <TextField
                                    type="number"
                                    onChange={e=>setTichDiem(e.target.value)}
                                    fullWidth size="small" variant="outlined"
                                />
                        </ItemDiv>
                            {/* The ThanhVien */}
                        {/* <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;Thẻ Thành Viên: </TextName>
                                <TextField
                                multiline
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={TheThanhVien}
                                onChange={e=>setTheThanhVien(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {MemberCards.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>                       
                        </ItemDiv>               */}
                        {/* Ghi Chu */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;Ghi Chú : </TextName>
                            <TextField 
                            multiline
                            onChange={e=>setGhiChu(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>    
                {/* row 1 */}
                                       
                {/* row 2 */}
                            {/* To chuc */}
                        <ItemDiv>
                            <TextName>&nbsp;Tổ Chức : </TextName>
                            <TextField
                            multiline
                            error={errTC}
                            onChange={e=>setToChuc(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* chuc danh */}
                        <ItemDiv>
                            <TextName>&nbsp;Chức Danh : </TextName>
                            <TextField
                            multiline
                            error={errCD}
                            onChange={e=>setChucDanh(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* tinh cach */}
                        <ItemDiv>
                            <TextName>&nbsp;Tính cách : </TextName>
                            <TextField
                                multiline
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={TinhCach}
                                onChange={e=>setTinhCach(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {Tinh_Cach.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>  
                        </ItemDiv>
                            {/* gioi tinh */}
                        <ItemDiv>
                            <TextName>&nbsp;Giới Tính: </TextName>
                            <TextField
                                multiline
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={GioiTinh}
                                onChange={e=>setGioiTinh(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {Sex.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>  
                        </ItemDiv> 
                            {/* thu nhap */}
                        <ItemDiv>
                            <TextName>&nbsp;Thu Nhập : </TextName>
                            <TextField
                                multiline
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={ThuNhap}
                                onChange={e=>setThuNhap(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {Thu_Nhap.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>  
                        </ItemDiv> 
                            {/* tt hon nhan */}
                        <ItemDiv>
                            <TextName>&nbsp;TT Hon Nhân : </TextName>
                            <TextField
                                multiline
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={HonNhan}
                                onChange={e=>setHonNhan(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {Hon_Nhan.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>  
                        </ItemDiv> 
                            {/* kh partner */}
                        <ItemDiv>
                            <TextName>&nbsp;KH Partner : </TextName>
                            <Autocomplete
                            fullWidth
                          multiple
                          options={Partner}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.label}
                          onChange={(e,newValue)=>setArrKHPartner(newValue)}
                          defaultValue={[]}
                          renderOption={(option, { selected }) =>{
                                return(
                                            <React.Fragment>
                                              <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}    
                                                size="small"
                                              />
                                              {option.label}
                                            </React.Fragment>   
                                    )
                              }
                          }                          
                          renderInput={(params) => (
                            <TextField {...params} 
                            variant="outlined" 
                            fullWidth
                            size='small'
                            />
                          )}
                        />       
                        </ItemDiv>    
                            {/* spdv */}
                        <ItemDiv>
                            <TextName>&nbsp;Spdv : </TextName>
                            <TextField
                            multiline
                            onChange={e=>setSpdv(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                            {/* uu dai */}
                        <ItemDiv>
                            <TextName>&nbsp;CS Ưu Đãi : </TextName>
                            <TextField
                            multiline
                            onChange={e=>setUuDai(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>

                {/* row 3 */}       
                       {/* Nguoi Gioi Thieu */}
                        <ItemDiv>
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Người Giới Thiệu : </TextName>
                            <TextField 
                            multiline
                            onChange={e=>setNguoiGioiThieu(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Nguoi Phu Trach */}
                         <ItemDiv>
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Người Phụ Trách : </TextName>
                            <TextField 
                            multiline
                            onChange={e=>setNguoiPhuTrach(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Doanh thu */}
                        <ItemDiv>                    
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Doanh Số Dự Kiến: </TextName>
                            <TextField 
                            type="number"                            
                            onChange={e=>setDoanhSoDuKien(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Chiet Khau */}
                        <ItemDiv>                    
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Chiết Khấu: </TextName>
                            <TextField 
                            type="number"                            
                            onChange={e=>setChietKhau(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* LoiNhuan */}
                        <ItemDiv>                    
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Lợi Nhuận: </TextName>
                            <TextField 
                            type="number"
                            onChange={e=>setLoiNhuan(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Lien he Lan Cuoi */}
                        <ItemDiv>
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Liên Hệ Lần Cuối : </TextName>
                            <TextField
                            multiline
                            onChange={e=>setLienHeLanCuoi(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Hanh Dong Tiep Theo */}
                        <ItemDiv>
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Hành Động Tiếp Theo : </TextName>
                            <TextField 
                            multiline
                            onChange={e=>setHanhDongTiepTheo(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Lan Lien he Tiep Theo */}
                        <ItemDiv>
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Lần Liên Hệ Tiếp Theo : </TextName>
                            <TextField 
                            multiline
                            onChange={e=>setLanLienHeTiepTheo(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Tinh Trang Co Hoi */}
                        <ItemDiv>
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Tình Trạng Cơ Hội : </TextName>
                            <TextField
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={TinhTrangCoHoi}
                                onChange={e=>setTinhTrangCoHoi(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {CO_HOI.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                             </TextField>  
                        </ItemDiv>
                        {/* Nguon Co Hoi */}
                        <ItemDiv>
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Nguồn Cơ Hội : </TextName>
                            <TextField                                
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={NguonCoHoi}
                                onChange={e=>setNguonCoHoi(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {Nguon_Co_Hoi.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                             </TextField>  
                        </ItemDiv>                    
                                  
                </ContentDiv>
            </MainDiv>
        </>
        
    );
};

export default AddCrm;