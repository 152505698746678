import {call,put,takeLatest} from 'redux-saga/effects'
import { DispatchItemTuyenDungToStore, DispatchListPriceSoftToStore, DispatchUpdateTuyenDungSuccess } from '../action/holdingtech'
import { GetAddTuyenDungApi, GetItemListSoftPriceApi, UpdateTuyenDungApi } from '../api/holdingtech'
import { DISPATCH_GET_LIST_PRICE_SOFT } from '../contant/holdingtech'
import { GET_ADD_TUYEN_DUNG, UPDATE_ITEM_TUYEN_DUNG } from '../GioiDinhTue/component/contant'

function* DispatchGetListPriceSoftSaga(){
       let res =  yield call(GetItemListSoftPriceApi)
        if(res.data.result==='success'){
            yield put(DispatchListPriceSoftToStore(res.data.data))
        }
}

function* GetAddTuyenDungSaga(){
        let res = yield call(GetAddTuyenDungApi)
        if(res.data.result==='success'){
            yield put(DispatchItemTuyenDungToStore(res.data.data))
        }
}

function* UpdateItemTuyenDungSaga({payload}){
    let res = yield call(UpdateTuyenDungApi,payload.item)
    if(res.data.result==='success'){
        yield put(DispatchUpdateTuyenDungSuccess(res.data.data))
    }
}

function* IndexSaga(){
    yield takeLatest(DISPATCH_GET_LIST_PRICE_SOFT,DispatchGetListPriceSoftSaga)
    yield takeLatest(GET_ADD_TUYEN_DUNG,GetAddTuyenDungSaga)
    yield takeLatest(UPDATE_ITEM_TUYEN_DUNG,UpdateItemTuyenDungSaga)
}

export default IndexSaga