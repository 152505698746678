import _ from 'lodash'
import { PAYMENT_STAFF,PAYMENT_MASTER,PAYMENT_MASTER_NOT,PAYMENT_MASTER_AND_IMPORT } from '../../../../../TaiKhoanNhanVienDangNhap/Component_CongViec/Fn'

export const CheckExistNameCate=(arr,item)=>{
  return arr.some(v=>v.nameCategory.toLowerCase().trim()===item.nameCategory.toLowerCase().trim())
}


export const handleOldNewPaymentInStatus = (payment,oldNew)=>{  

  if(payment===true&&oldNew)    return 0  
  if(payment===false&&oldNew)   return 1

  if(payment===true&&!oldNew)   return 2
  if(payment===false&&!oldNew) return 3
//  //cong ty da thanh toan
//   if(payment===PAYMENT_MASTER&&oldNew)       return 0
//   if(payment===PAYMENT_MASTER&&!oldNew)      return 2
// //cong ty chua thanh toan
//   if(payment===PAYMENT_MASTER_NOT&&oldNew)   return 1
//   if(payment===PAYMENT_MASTER_NOT&&!oldNew)  return 3
// //nhan vien tam ung
//   if(payment===PAYMENT_STAFF&&oldNew)       return 4 //hang moi, nhan vien ung tien thanh toan, cty tra cho nhan vien
//   if(payment===PAYMENT_STAFF&&!oldNew)      return 5 //hang cu, nhan vien thanh toan
// //nhap hang da co
//   if(payment===PAYMENT_MASTER_AND_IMPORT&&oldNew) return 6
//   if(payment===PAYMENT_MASTER_AND_IMPORT&&!oldNew) return 7
}

export const ConvertOldNewPaymentInStatus=(payment,oldNew)=>{
   //cong ty da thanh toan
   if(payment===PAYMENT_MASTER&&oldNew)       return 0
   if(payment===PAYMENT_MASTER&&!oldNew)      return 2
 //cong ty chua thanh toan
   if(payment===PAYMENT_MASTER_NOT&&oldNew)   return 1
   if(payment===PAYMENT_MASTER_NOT&&!oldNew)  return 3
 //nhan vien tam ung
   if(payment===PAYMENT_STAFF&&oldNew)       return 4 //hang moi, nhan vien ung tien thanh toan, cty tra cho nhan vien
   if(payment===PAYMENT_STAFF&&!oldNew)      return 5 //hang cu, nhan vien thanh toan
 //nhap hang da co
   if(payment===PAYMENT_MASTER_AND_IMPORT&&oldNew)  return 6
   if(payment===PAYMENT_MASTER_AND_IMPORT&&!oldNew) return 7
}


export const handlePaymentTrueOrFalse = (item)=>{
  if(item.status===0||item.status===2) return true
  if(item.status===1||item.status===3) return false
}

export const handleOldNewTrueOrFalse = (item)=>{
  if(item.status===0||item.status===1) return true
  if(item.status===2||item.status===3) return false
}

export const CheckExistAddMaterial=(arr,item)=>{
  let {nameCategory,nameMaterial}  = item
  let filterByNameMaterial = arr.filter(v=>v.nameCategory.toLowerCase().trim()===nameCategory.toLowerCase().trim())
  return filterByNameMaterial[0].listMaterials.some(v=>v.name.toLowerCase().trim()===nameMaterial.toLowerCase().trim())
}

export const SearchListMaterial=(arr,Search)=>{
  return arr.filter(v=>v.name.toLocaleLowerCase().indexOf(Search)!==-1)
}

export const SearchSuppliesByRepo=(arr,Search)=>{
  return arr.filter(v=>v.dateInput.indexOf(Search)!==-1||
                    v.nameSupplies.indexOf(Search)!==-1||
                    v.nameMaterial.indexOf(Search)!==-1||
                    v.payment.indexOf(Search)!==-1||
                    v.oldNew.indexOf(Search)!==-1
  )
}

export const SumTotalQuanTity_price=(arr)=>{
    return arr.map(v=>v.quantity*v.price).reduce((x,y)=>x+y,0)
}
export const SumTotalQuanTity=(arr)=>{
  return arr.map(v=>v.quantity).reduce((x,y)=>x+y,0)
}
export const SumTotalPrice=(arr)=>{
  return arr.map(v=>v.price).reduce((x,y)=>x+y,0)
}

export const AllArrMaterial=(arr)=>{ 
  let result =[]
  arr.map(v=>{
    v.listMaterials.map(vv=>{
      let item = {
        userId:v.userId,
        nameCategory:v.nameCategory,
        ...vv
      }
      result.push(item)
    })
  })
  return result
}

export const ALL_MATERIALS = "Tất cả vật tư"

export const SumQuantityInventory= (arr,item)=>{
  let findMaterial = arr.filter(v=>v.idNameMaterial===item._id)
  if(findMaterial.length===0){
    return 0
  }
  return (findMaterial.map(v=>v.quantity)).reduce((x,y)=>x+y)
}

export const CheckExistMaterialInArr=(arr,item)=>{
  return arr.some(v=>v.idNameMaterial===item._id)
}

export const AllMaterials =(arr,itemRepo,listImport,listExport,listSupplies)=>{  
  let filterByRepo = listImport.filter(v=>v.idNameRepo===itemRepo._id)
  let allArrMaterial = AllArrMaterial(arr)  
  let ArrOrgin = filterByRepo.map(v=>{ //idNameMaterial
    return{
      ...v,
      nameMaterial:(findByName(v,allArrMaterial))[0].name,
      nameSupplies:(findSupplies(v,listSupplies))[0].name,
      dateInput:v.dateInput,
      payment:filterPayment(v,ArrPaymentOldNew),
      oldNew:filterOldNew(v,ArrPaymentOldNew),
      expireDate:v.expireDate===null?"":v.expireDate
    }
  })
  let ArrNameMaterial = [...new Set(ArrOrgin.map(v=>v.idNameMaterial))]

  //them export sau
  let arrMaterialResult = ArrNameMaterial.map(v=>{
    let item = findNameMaterial(v,allArrMaterial)
    let quantity = QuantityResult(v,ArrOrgin)
    return{      
      name:item[0].name,
      unit:item[0].unit,
      quantity
    }
  })
  return{ArrOrgin,ArrNameMaterial,allArrMaterial,arrMaterialResult}
}

export const ArrPaymentOldNew = [
  {value:0,payment:'thanh toán',oldNew:'mới'},
  {value:1,payment:'công nợ',oldNew:'mới'},
  {value:2,payment:'thanh toán',oldNew:'cũ'},
  {value:3,payment:'công nợ',oldNew:'cũ'},
  {value:4,payment:'phải trả nhân viên',oldNew:'mới'},
  {value:5,payment:'phải trả nhân viên',oldNew:'cũ'},
  {value:6,payment:'còn trong kho-đã thanh toán',oldNew:'mới'},
  {value:7,payment:'còn trong kho-đã thanh toán',oldNew:'cũ'},
  //{value:8,payment:'còn trong kho-chưa thanh toán',oldNew:'mới'},
 // {value:9,payment:'còn trong kho-chưa thanh toán',oldNew:'cũ'}
]

export const filterPayment =(item,arr)=>{
  return (arr.filter(v=>v.value===item.status))[0].payment
}
export const filterOldNew =(item,arr)=>{
  return (arr.filter(v=>v.value===item.status))[0].oldNew
}

// console.log(ArrOrgin)
//   console.log(ArrNameMaterial)
//   console.log(allArrMaterial)
//   console.log(arrMaterialResult)
export const findSupplies=(item,arr)=>{  
  return arr.filter(v=>v._id===item.idSupplies)
}
export const findSupplies_=(item,arr)=>{  
  return arr.filter(v=>v.idSupplies===item._id)
}

export const findByName=(item,arr)=>{ 
  return arr.filter(v=>v._id===item.idNameMaterial)
}

const findNameMaterial = (value, arr)=>{
    return arr.filter(v=>v._id===value)
}

const QuantityResult= (value,listImport)=>{
      if(listImport!==undefined){
          let find = listImport.filter(v=>v.idNameMaterial===value)
          let findQuantity = find.map(v=>v.quantity).reduce((x,y)=>x+y)
          return findQuantity
      }
}

// export const handleOldNewPaymentInStatus = (payment,oldNew)=>{
//   if(payment&&oldNew)   return 0 //thanh toan, hang moi
//   if(!payment&&oldNew)  return 1 //chua thanh toan, hang moi
//   if(payment&&!oldNew)  return 2 //thanh toan. hang cu
//   if(!payment&&!oldNew) return 3 //chua thanh toan, hang cu
// }

export const NUMBER_REQUEST = 15
export const MESSAGE_LIMITED = "Lỗi kết nối, đăng nhập lại"
export const TIME_RESET      = 100000

//gia nhap binh quan gia quyen
export const PriceImport = (arr,item)=>{
  let findPrice= arr.filter(v=>v.idNameMaterial===item.idNameMaterial)
  let number  =findPrice.length
  return (findPrice.map(v=>v.price).reduce((x,y)=>x+y,0))/number
}