import _ from 'lodash'

const  desc=(a, b, orderBy)=> {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export const getSorting=(order, orderBy)=> {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}


export const stableSort=(array, comparator)=> {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export const sortArrAsc=(arr)=>{//tang dan
    return _.orderBy(arr,["nameSupplies","begin","nameMaterial",'name','nameCategory','nameBlockClass','nameClass','dateMeal','dateNow','nameTypeMaterial','date','last',"workPlace",'nameCreateRepo','timeStamp','nameUSER','workPlace'],['asc','asc','asc','asc','asc','asc','asc','asc','asc','asc','asc','asc','asc','asc','asc','asc'])
}

export const ArrStudentSort=(arr)=>{
  return  arr.map(v=>{
    if(v.fullName===null||v.fullName===undefined){
      return ""
    }
    let string = v.fullName.split(' ')
    let findName=string[string.length-1]
    let Item={
      ...v,
      last:findName.toLowerCase()
    }
    return Item
  })
}
export const ArrMaterialSort=(arr)=>{
  return  arr.map(v=>{
      let string = v.nameMaterial.split(' ')
      let findName=string[0]
      let Item={
        ...v,
        begin:findName.toLowerCase()
      }
      return Item
  })
}

export const ArrStudentSortByName=(arr)=>{  
  let newArr=  arr.map(v=>{
    let string = v.name.split(' ')
    let findName=string[string.length-1]
    let Item={
      ...v,
      last:findName
    }
    return Item
  })
  return newArr
}

export const ArrListHocSinhCanDo=(arr)=>{
  let newArr=  arr.map(v=>{
    let string = v.nameHocSinh.split(' ')
    let findName=string[string.length-1]
    let Item={
      ...v,
      last:findName
    }
    return Item
  })
  return newArr
}

export const sortByNameLast=(arr)=>{
 return arr.sort((a,b)=>a.last.localeCompare(b.last))
}

export const sortByNameBegin=(arr)=>{
  return arr.sort((a,b)=>a.begin.localeCompare(b.begin))
 }

export const sortAscPaymentList = (arr)=>{
  return _.orderBy(arr,["last"],["asc"])
}

export const sortArrDsc = (arr)=>{
   return _.orderBy(arr,["name",'nameBlockClass',"last","workPlace",'timeStamp'],["dsc",'dsc',"dsc","dsc",'dsc'])
}
export const sortArrByName = (arr)=>{
  return _.orderBy(arr,[arr[2]],['asc'])
}

export const UploadFileExcel = (newArr,RowClassStudent,NameYearSchool)=>{
  return  newArr.map(v=>{     
      if(v.name===undefined){           
        let item={
            fullName:v.fullName.toLowerCase(),
            userId:RowClassStudent.userId,
            idBlockClass:RowClassStudent.idBlockClass,
            idClass:RowClassStudent._id,
            idYearSchool:NameYearSchool._id
          }              
          return item         
        }
          let item2 = {
            fullName:(v.fullName+' '+v.name).toLowerCase(),
            userId:RowClassStudent.userId,
            idBlockClass:RowClassStudent.idBlockClass,
            idClass:RowClassStudent._id,
            idYearSchool:NameYearSchool._id        
        }      
        return item2
      })  
}
