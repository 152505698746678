export const CaiDat =[
    {name:'Danh Mục Nguyên Liệu, Thực Phẩm',id:'wcKd12IvWrM'},
    {name:'Các Khoản Thu/Phiếu Ăn',id:'W0QFWBvm-V0'},
    {name:'Nhà Cung Cấp',id:'ZEq1dZojLsg'},
    {name:'Tạo, Nhập Kho',id:'ZDKx-akJfhU'}
]

export const ThucDonTuan = [
    {name:'Thực Đơn',id:'4VYllfnDMDM'},
]

export const HocSinh = [
    {name:'Khởi Tạo Năm Học',id:'TjpJD-46GqY'},
    {name:'Thêm, sửa xoá, Phân Cấp Khối/ Nhóm',id:'SsvRe3NqNZc'},
    {name:'Upload Danh Sách Học Sinh excel',id:'qN3GANHcdJY'},
    {name:'Báo sỉ số',id:'nUgi0b5Sb6A'},
    {name:'Thêm, Sửa, Xoá danh sách học sinh sau khi đã báo sỉ số', id:'67B6wtRoa08'},
    {name:'Báo Ăn(Chấm Ăn) trong ngày',id:'O_LWD7c-vns'},
    {name:'Đăng ký học thứ 7',id:'4dRGv7gLUYQ'},
]

export const ThuChi = [
    {name:'Nút Chức Năng : Chọn Thời Gian',id:'lsHn-2Fs1Z8'},
    {name:'Nút Chức Năng : In-Bảng Tính Ăn Hằng Ngày',id:'S7c3O1Hk88k'},
    {name:'Nút Chức Năng : In-Phiếu Xuất Kho',id:'h17_Jpv6ybA'},
    {name:'Nút Chức Năng : In-Phiếu Đề Nghị Thanh Toán',id:'whftdq8RTCw'},
    {name:'Nút Chức Năng : Ăn Buffet',id:'eXI7eHflZZA'},
    {name:'Nút Chức Năng : Chi Tiết Tiền Thưà Thiếu',id:'0Huj-FnpmKA'},
    {name:'Nút Chức Năng : Sao Chép Tính Ăn',id:''},
    {name:'Nút Chức Năng : Dữ Liệu Tính Ăn',id:'qHPKNiNuSa0'},
    {name:'Nút Chức Năng : Kiểm Thực Ba Bước',id:'2ihMZv9cFN8'},
    {name:'Nút Chức Năng : Xoá Tất Cả Nguyên Liệu',id:'jOLEydzBOaQ'},
    {name:'Nút Chức Năng : Hiển Thị Danh Mục',id:'CZRzXS3eKgE'},
    {name:'Nút Chức Năng : Tổng Hợp - Thanh Toán Tiền Phiếu Ăn Thừa',id:'0Huj-FnpmKA'},
    {name:'Nút Chức Năng : Tổng Hợp - Báo Ăn',id:'z5WzENfyjeI'},
    {name:'Nút Chức Năng : Tổng Hợp - Danh Sách Thu, Thanh Toán Tiền Ăn',id:'wqjC-eEotaE'},
    {name:'Nút Chức Năng : Tổng Hợp - Bảng Chấm Ăn Các Lớp',id:'Cm-E3CuPWao'},
    {name:'Nút Chức Năng : Tổng Hợp - Danh Sách Thu Thanh Toán Tiền Ăn Các Lớp',id:'gcmag0n4WsQ'},
    {name:'Nút Chức Năng : Tổng Hợp - Chi Tiết Danh Mục Nguyên Liệu',id:'a2tDOKON-oQ'},
    {name:'Nút Chức Năng : Tổng Hợp - Chi Tiết Nguyên Liệu',id:'v5R12Qz4BOY'},
    {name:'Nút Chức Năng : Tổng Hợp - Danh Mục Nguyên Liệu Theo Nhà Cung Cấp',id:'1rd01Yc89Bg'},
    {name:'Nút Chức Năng : Tổng Hợp - Danh Mục Nguyên Liệu',id:'XggFOV7RFDM'},
    {name:'Nút Chức Năng : Tổng Hợp - Số Kho',id:'AfEtOc1TLEQ'},
    {name:'Thêm, sửa, xoá nguyên liệu',id:''},
]

export const QuanTri = [
    {name:'Chức năng thẻ Học Sinh',id:''},
    {name:'Chức năng thẻ Tính Ăn',id:''},
    {name:'Chức năng thẻ Tài Khoản Phòng Ban',id:''},
    {name:'Chức năng thẻ Quản Lý Danh Sách Thu Tiền - Quản Lý Khoản Thu',id:''},
]


export const TinhKhauPhan = [
    {name:'Hướng Dẫn Tính Khẩu Phần Ăn - Tạo mới khẩu phần ăn-Làm Thủ Công Nhóm Mẫu Giáo',id:'NFZNvXpxcVg'},
    {name:'Hướng Dẫn Tính Khẩu Phần Ăn - Tạo mới khẩu phần ăn-Cân Đối Nguyên Liệu tự động',id:'r9y-LP99YlU'},
    {name:'Hướng Dẫn Tính Khẩu Phần Ăn - Tạo mới khẩu phần ăn-Sử dụng thư viện-các tab chức năng',id:'5hwa2UZUCpo'},
    {name:'Hướng Dẫn Tính Khẩu Phần Ăn - Tạo mới khẩu phần ăn-Thêm Mới Nhóm Nhà Trẻ',id:'j4TcuZppUok'},
    

    {name:'Hướng Dẫn Chức Năng Tạo Kiểm Thực Ba Bước',id:''},

    {name:'Chức năng thẻ Dinh Dưỡng Thục Phẩm',id:'mPN6Kf2tiCo'},
    {name:'Chức năng thẻ Bảng Độ Tuổi Và Số Người',id:'mPN6Kf2tiCo'},
    {name:'Chức Năng thẻ Thư Viện Thực Đơn - Tab Thực Đơn Tuần',id:'FvONtNeS_KQ'},
    {name:'Chức Năng thẻ Thư Viện Thực Đơn - Tab Nguyên Liệu',id:'4_HJpYgo3H4'},
    {name:'Chức Năng thẻ Thư Viện Thực Đơn - Tab Thư Viện Của Tôi',id:'om-6fX01e9Q'},
    {name:'Chức Năng thẻ Thư Viện Thực Đơn - Tab Thư Viện',id:'6S3lOiPndxc'},
    {name:'Chức Năng thẻ Thư Viện Thực Đơn - Tab Tìm Thực Đơn',id:'pbw0JzFR_18'},
   
]

export const CanDo =[
    {name:'Hướng Dẫn Upload danh sách học sinh cân đo',id:'aNgwyRYYuWc'},
    {name:'Hướng Dẫn Cân Đo',id:'8RqJSnjl4hg'}
]

export const WebSite =[
    {name:'Hướng Dẫn Tạo Website cho trường của bạn',id:''}
]

export const ThongTinKhachHang =[
    {name:'Hướng Dẫn Khai Báo Thông Tin',id:''},
    {name:'Hướng Dẫn Thẻ Phí Các Loại',id:''},
    {name:'Hướng Dẫn Thẻ Biểu Mẫu',id:''},
    {name:'Hướng Dẫn Chức Danh',id:''}
]