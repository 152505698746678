import styled,{css,keyframes} from 'styled-components'
import { device } from '../../../../GioiDinhTue/component/style/MainCss/device'
//import { MainColor } from '../../main/component/color'


//main chua web, kich thuoc phu het trinh duyet
//header bang chieu ngang main, 

export const gradient = keyframes`
     0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
`
export const MainCss= styled.div`
        padding:0px;
        margin:0px;
        box-sizing:border-box;
`

export const Header = styled.div`  
       
        ${'' /* overflow: hidden; */}
        background: linear-gradient(315deg,#d35400 10%, rgba(0, 0, 0, 0.87) 38%, rgba(0, 0, 0, 0.87) 68%,#f1c40f 85%, rgba(255,25,25,1) 98%);
        animation: ${gradient} 30s ease infinite;
        background-size: 200% 200%;
        background-attachment: fixed;

        position:fixed;
        top:0;
        left:0;
        right:0;
        display:inline-block;
        padding:0;
        margin:0;
        height:auto;
        z-index:100000;       
`       

export const HeaderMobile=styled.div`
        z-index:100000;       
        @media ${device.mobile}{
                 width:auto;
                 height:50px;
                 background:#212121;
        }  
        @media (max-width:1023px){
                 width:auto;
                 height:50px;
                 background:#212121;
        }         
        @media ${device.pc}{
                display:none;
        }
`

export const UL_Header = styled.ul` 
        margin:0;
        z-index:999999999;
        @media ${device.mobile}{
                display:none;
        }       
        @media (max-width:1023px){
                display:none;
        }               
`

export const UL_Header_ = styled.ul`   
        z-index:999999999;
        @media ${device.mobile}{
                        ${props=>props.variant===true?
                        css `display:content`:
                        css `display:none`       
                        }
                }    
        @media (max-width:1023px){
                ${props=>props.variant===true?
                css `display:content`:
                css `display:none`       
                }
        }              
        ${props=>props.variant===true?
                css `display:content`:
                css `display:none`       
        }        
       
`

export const UL_SubNav = styled.ul`

`

// export const LI_header= styled.li`
//         list-style:none;
//         display:inline-block;
//         &:hover .block{
//             background-color:#7f8c8d;
//             ${'' /* background-color:linear-gradient(315deg, rgba(101,0,94,1) 3%, rgba(60,132,206,1) 38%, rgba(48,238,226,1) 68%, rgba(255,25,25,1) 98%); */}
//             transition: 0.5s all ease-in-out;
//             cursor:pointer;
//       }      
// `

export const A_Link =styled.a`
    text-decoration:none;
    color:#FFFFFF;
    line-height:46px;
    padding:0 24px;    
    display:inline-block;    
    text-transform:uppercase;
    cursor:pointer;
    font-size:.8rem;
`

// SECOND

export const LI_header_Second= styled.li`
        list-style:none;            
        position:relative;
        display:inline-block;
        top:100%;
        left:0;
        &:hover .block{       
            ${'' /* background-color:#95a5a6; */}
        background: linear-gradient(315deg,#d35400 10%, rgba(0, 0, 0, 0.87) 38%, rgba(0, 0, 0, 0.87) 68%,#f1c40f 85%, rgba(255,25,25,1) 98%);
        animation: ${gradient} 30s ease infinite;
        background-size: 200% 200%;
        background-attachment: fixed;
            transition: 0.5s all ease-in-out;
            margin-left:1px;
          }
        &:hover .ShowBox{
                display:block;
                transition: 0.8s all ease-in-out;
        }
`

export const UL_SubNav_Second = styled.ul`    
        position:absolute;
        background: linear-gradient(315deg,#d35400 10%, rgba(0, 0, 0, 0.87) 38%, rgba(0, 0, 0, 0.87) 68%,#f1c40f 85%, rgba(255,25,25,1) 98%);
        ${'' /* animation: ${gradient} 30s ease infinite;
        background-size: 200% 200%;
        background-attachment: fixed; */}
        ${'' /* background-color:#ecf0f1; */}
        list-style:none;
        z-index:1;
        padding:0;
        min-width:250px;       
        box-shadow:0 3px rgba(0,0,0,0.3);
        display:none;       
        overflow:auto;
`

export const LI_header_block_second=styled.li`
        &:hover .block_second{
            background-color:#95a5a6;
            transition: 0.5s all ease-in-out;
            margin:0 1px;
            cursor:pointer;
        }
`


export const A_Link_Second =styled.a`
        text-decoration:none;
        color:#ecf0f1;
        line-height:46px;
        padding:0 24px;            
        display:block;     
`

export const SearchHeader = styled.div`
            float: right;
            margin-top: -60px;
            padding: 28px 29px;
            height: 60px;           
            &:hover {
                background-color:#c0392b;
                transition: 0.3s all ease-in-out;
                cursor:pointer;
            }     
            @media ${device.mobile}{
               display:none;
            }
            @media ${device.tablet}{
               display:none;
            }
`
export const SearchHeader_ = styled.div`
            padding: 28px 29px;
            height: 60px;   
            top: -20px;
            z-index: 10;
            right: -11px;
            position: absolute;        
            &:hover {
                ${'' /* background-color:black; */}
                transition: 0.3s all ease-in-out;
                cursor:pointer;
            }                 
            @media ${device.pc}{
               display:none;
            }
            @media ${device.tablet}{
               display:none;
            }
`

export const SearchIconClass =styled.i`
        color:#FFFFFF;        
`

export const LI_header= styled.li`
        list-style:none;
        display:inline-block;
        &:hover .block{
            background-color:#95a5a6;
            transition: 0.5s all ease-in-out;
            cursor:'pointer';
        }
        &:hover .in-block{
        cursor:pointer;
        }

        @media ${device.mobile}{
            display:block;
        }
`
// SECOND

// can giua can chinh bang chieu cao header 46px

