import React,{useState} from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,Typography,Tooltip, Box} from '@material-ui/core'
import {useStyles}from './styles'
import TableNotSeven from './TableNotSeven'
import {changeUpperCase} from '../../../helper/fnAllways'
import {Today} from '@material-ui/icons'
import {Visibility} from '@material-ui/icons'
import {useDispatch,useSelector} from 'react-redux'
import {OPEN_LIST_STUDENT_NOT_EAT,OPEN_UPDATE_APPROVAL_CHECK_DAY_FOOD,OPEN_CHECK_DAY_FOOD_COUNT_EAT} from '../../contant/Class'
import {showMMYY} from '../../../helper/dateTime/handleDMY'

const Index=React.memo(({List,ClassTmp,
  //CountEat,
  ExitSaveCountEat,NumStu})=> {
  const dispatch = useDispatch()
  const CountEat            = useSelector(state => state.ManagerAccount.CountEat)
  const DateInput           = useSelector(state => state.ManagerAccount.DateInput)
  const {nameClass,ListStudentNow,numStudent,ListStudentByMonthRegisSevenDay} = List
  const classes  = useStyles() 
  const [selectAll,setSelectAll]   = useState(0)

  const handleSelectAll =()=>{
    setSelectAll(1)
  }
  const handleCancelSelectAll=()=>{
    setSelectAll(0)
  }
  const ShowNotEat =()=>{     
      if(NumStu===undefined){
        return <>Chưa Chấm Ăn</>
      }
      if(NumStu.length!==0||NumStu.length===0){
        return <>
                <Tooltip title="Danh Sách Báo Vắng">
                  <Typography color="secondary" component={"span"} className={classes.numStu} onClick={()=>showStuNotEat()}><Visibility/>{NumStu.length}</Typography>
                </Tooltip> &nbsp;&nbsp;
                {UpdateApproval()}
               </>
      }      
  }

  const UpdateApproval =()=>{
    return <Tooltip title="Cập nhật báo ăn">
                <Typography color="primary" component={"span"} className={classes.UpdateApproval} onClick={()=>UpdateApprovalCheckDayFood()}><Today/></Typography>
           </Tooltip>
  }

  const CheckDayFoodCountEat=()=>{
      return <Typography color="secondary" component={"span"} style={{fontSize:12}} className={NumStu!==undefined?classes.CheckDayFood:classes.CheckDayFoodDiff} onClick={()=>CheckDayFoodCountEatForm()}>{`Bảng Chấm Ăn ${showMMYY(DateInput)}`}</Typography>      
      }

  //show bao vang
    const showStuNotEat=()=>{
          dispatch({
            type:OPEN_LIST_STUDENT_NOT_EAT
          })
    }   
  //show bao vang

  //update Approval Check Day Food 
    const UpdateApprovalCheckDayFood=()=>{
        dispatch({
          type:OPEN_UPDATE_APPROVAL_CHECK_DAY_FOOD
        })
    }

  //update Approval Check Day Food
  //check Day food count eat hien thi bao an
  const CheckDayFoodCountEatForm=()=>{
    dispatch({
      type:OPEN_CHECK_DAY_FOOD_COUNT_EAT
    })
  }
  return ( 
    <>
      <Box display="flex" justifyContent="flex-start">
              {/* <Alert severity="info">
                {changeUpperCase(nameClass)} &nbsp; Sỉ số :{numStudent} &nbsp; {ShowNotEat()} &nbsp;{CheckDayFoodCountEat()}            
              </Alert> */}
              <Typography color='primary' component="h3">Lớp : {changeUpperCase(nameClass)}</Typography>&nbsp;&nbsp;&nbsp;
              <Typography color="secondary" component="h4">Sỉ số : {numStudent}</Typography>&nbsp;
              <Typography color="secondary" component="h5">{ShowNotEat()}</Typography>&nbsp;
              <Typography color="secondary" component="h6">{CheckDayFoodCountEat()}</Typography>&nbsp;
          </Box>
      <TableContainer component={Paper} className={classes.table}>       
         
          <Table  stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
              <TableCell align="left">STT</TableCell>   
              <TableCell align="left">Tên học sinh</TableCell>  
              {CountEat===1?
              <>
                            <TableCell>Báo Vắng/
                              {selectAll===0? <Typography color="secondary" className={classes.selectAll} onClick={()=>handleSelectAll()}>Chọn tất cả</Typography>:
                                              <Typography color="secondary" className={classes.selectAll} onClick={()=>handleCancelSelectAll()}>Bỏ tất cả</Typography>
                              }</TableCell>                           
              </>
              :<></>}         
              </TableRow>
            </TableHead>
            <TableBody>        
                <TableNotSeven
                    ListStudentNow={ListStudentNow}
                    ListSeven={ListStudentByMonthRegisSevenDay}
                    selectAll={selectAll}
                    ExitSaveCountEat={ExitSaveCountEat}
                    Tmp = {ClassTmp}
                />              
            </TableBody>
          </Table>
      </TableContainer>
    </>
  );
})

export default Index
