import { DISPATCH_RESULT_WEB_AUTO_TO_STORE,DISPATCH_INFO_USER_DETAIL_WEB,
    SEND_REGIS_STUDENT_WEB_SUCCESS,
    SEND_REGIS_STUDENT_WEB_SUCCESS_FAIL
} from "../contant";

export const SendRegisStudentWebSuccessFail=()=>{
    return{
        type:SEND_REGIS_STUDENT_WEB_SUCCESS_FAIL
    }
}
export const SendRegisStudentWebSuccess=()=>{
    return{
        type:SEND_REGIS_STUDENT_WEB_SUCCESS
    }
}

export const DispatchInfoUserDetailWeb = (item)=>{
    return{
        type:DISPATCH_INFO_USER_DETAIL_WEB,
        payload:{item}
    }
}

export const DispatchResultWebAutoToStore=(item)=>{
    return{
        type:DISPATCH_RESULT_WEB_AUTO_TO_STORE,
        payload:{item}
    }
}