import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import format from "date-fns/format";
import viLocale from "date-fns/locale/vi";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import {Button,Dialog,DialogActions,DialogContent} from '@material-ui/core';
import {Alert} from '@material-ui/lab'
import {DISPATCH_DATE_INPUT_TO_UPDATE_COUNT_EAT} from '../../../contant/Class'
import {showDDMMYY} from '../../../../helper/dateTime/handleDMY'

const localeMap = {  
  vi: viLocale
};

class ViLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "dd MMM yyyy", { locale: this.locale });
  }
}

const localeUtilsMap = {   
  vi: ViLocalizedUtils
};

const localeFormatMap = { 
  vi: "d MMM yyyy"
};

const localeCancelLabelMap = {  
  vi: "Hủy"
};

const Index=React.memo(({open,handleClose})=> { 
  const dispatch = useDispatch()
  const [locale]                      = useState("vi");
  const [selectedDate, setDateChange] = useState(new Date()); 
  const DateInput                     = useSelector(state=>state.ManagerAccount.DateInput)
  const SelectUpdateCheckDayFood=()=>{
        
        if(showDDMMYY(DateInput)===showDDMMYY(selectedDate)){
            dispatch({
            type:DISPATCH_DATE_INPUT_TO_UPDATE_COUNT_EAT,
            payload:{item:selectedDate}
          })
          handleClose()
        }else{
          alert('Ngày bạn chọn phải trùng với ngày hiện tại')
        }                
  }
  return (
     
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Alert severity="info">Xem, Cập nhật Báo Ăn</Alert>
        <DialogContent>
        <MuiPickersUtilsProvider utils={localeUtilsMap[locale]} locale={localeMap[locale]}  >
                                  <KeyboardDatePicker
                                    value={selectedDate}
                                    onChange={setDateChange}
                                    format={localeFormatMap[locale]} 
                                    cancelLabel={localeCancelLabelMap[locale]} 
                                    label="Chọn ngày"                  
                                  >                                 
                                 </KeyboardDatePicker>
                 </MuiPickersUtilsProvider> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" size="small" variant="contained">
            Thoát
          </Button>        
          <Button onClick={()=>SelectUpdateCheckDayFood()} size="small" variant="contained" color="primary">Xem</Button>
        </DialogActions>
      </Dialog>
  )
})

export default Index
