import { DeleteItemById, updateArrById } from "../../../../../helper/fnAllways"
import { toastError, toastSuccess } from "../../../../../helper/toastHelper"
import {  ADD_JOB_STAFF_NHAN_VIEN_VAT_TU, APPROVE_JOB_STAFF_FALSE, APPROVE_JOB_STAFF_TRUE, DELETE_JOB_STAFF_NHAN_VIEN, DELETE_JOB_STAFF_NHAN_VIEN_VAT_TU, DISPATCH_ADD_JOB_STAFF_NHAN_VIEN_STORE, DISPATCH_GET_INFO_CUS_NHAN_VIEN_TO_STORE, DISPATCH_JOB_STAFF_NHAN_VIEN_VAT_TU, DISPATCH_SEARCHING_GET_JOB_STAFF_NHAN_VIEN, UPDATE_JOB_STAFF_CONG_LAO_DONG, UPDATE_JOB_STAFF_NHAN_VIEN, UPDATE_JOB_STAFF_NHAN_VIEN_STORE, UPDATE_JOB_STAFF_NHAN_VIEN_VAT_TU, UPDATE_JOB_STAFF_NHAN_VIEN_VAT_TU_DB } from "../../contant/LOGIN_PAGE/OpenNavBarNhanVien";
import { HandleAddArrJobStaff, HandleDeleteArrJobStaff, HandleUpdateArrJobStaff } from "./Fn";

let initialState={
   ListKH:[],
   ListJob:[],
   ListDetailProduct:[],
   ListJobStaffSearch:[],
   ListJobStaff:[],
   ValueEditJobStaff:null
}

 //const CREATE_SUCCESS = "Khởi Tạo Thành Công"
 const UPDATE_SUCCESS = "Cập Nhật Thành Công"
// const UPDATE_FAIL    = "Cập Nhật Lỗi"
const IndexReducer =(state=initialState,action)=>{
            switch (action.type) {             
            case UPDATE_JOB_STAFF_CONG_LAO_DONG:
              return{
                ...state,
                ListJobStaffSearch:updateArrById(state.ListJobStaffSearch,action.payload.item)
              }
              case APPROVE_JOB_STAFF_FALSE:       
              toastError('Từ Chối Phê Duyệt')         
                return{
                  ...state,
                  ListJobStaffSearch:updateArrById(state.ListJobStaffSearch,action.payload.item)
                } 
              case APPROVE_JOB_STAFF_TRUE:       
              toastSuccess('Đã Phê Duyệt')         
                return{
                  ...state,
                  ListJobStaffSearch:updateArrById(state.ListJobStaffSearch,action.payload.item)
                } 
              case ADD_JOB_STAFF_NHAN_VIEN_VAT_TU:
                toastSuccess('Cập Nhật Thành Công!')
                let resultAdd = HandleAddArrJobStaff(state.ListJobStaffSearch,state.ValueEditJobStaff,action.payload.item)
                  return{
                    ...state,
                    ValueEditJobStaff:resultAdd.item,
                    ListJobStaffSearch:resultAdd.list         
                  }
              case DELETE_JOB_STAFF_NHAN_VIEN_VAT_TU:
                toastSuccess('Cập Nhật Thành Công!')
                let resultDelete = HandleDeleteArrJobStaff(state.ListJobStaffSearch,state.ValueEditJobStaff,action.payload.item)
                  return{
                    ...state,
                    ValueEditJobStaff:resultDelete.item,
                    ListJobStaffSearch:resultDelete.list         
                  }
              case UPDATE_JOB_STAFF_NHAN_VIEN_VAT_TU:
                toastSuccess('Cập Nhật Thành Công!')
                let result = HandleUpdateArrJobStaff(state.ListJobStaffSearch,state.ValueEditJobStaff,action.payload.item)                
                return{
                  ...state,
                  ValueEditJobStaff:result.item,
                  ListJobStaffSearch:result.list                  
                }
              case DISPATCH_JOB_STAFF_NHAN_VIEN_VAT_TU:
                return{
                  ...state,
                  ValueEditJobStaff:action.payload.item
                }
              case DELETE_JOB_STAFF_NHAN_VIEN:
                toastSuccess(UPDATE_SUCCESS)
                return{
                  ...state,
                  ListJobStaffSearch:DeleteItemById(state.ListJobStaffSearch,action.payload.item)
                }
              case UPDATE_JOB_STAFF_NHAN_VIEN_STORE:
                toastSuccess(UPDATE_SUCCESS)
                return{
                  ...state,
                  ListJobStaffSearch:updateArrById(state.ListJobStaffSearch,action.payload.item)
                }
              case DISPATCH_SEARCHING_GET_JOB_STAFF_NHAN_VIEN:
                return{
                  ...state,
                  ListJobStaffSearch:action.payload.item
                }
              case DISPATCH_ADD_JOB_STAFF_NHAN_VIEN_STORE:
                toastSuccess('Thêm Mới Thành Công')
                return{
                  ...state,
                  ListJobStaff:[action.payload.item].concat(state.ListJobStaff)
                }
              case DISPATCH_GET_INFO_CUS_NHAN_VIEN_TO_STORE:
                return{
                    ...state,
                    ListKH:action.payload.item.ListKH,
                    ListJob:action.payload.item.ListJob,
                    ListDetailProduct:action.payload.item.ListDetailProduct
                }
                default: return state;
            }
}

export default IndexReducer