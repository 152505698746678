import React,{useState}from 'react'
import {TableContainer,Table,TableHead,TableRow,TableCell,TableBody,Paper} from '@material-ui/core'
import {Visibility}from '@material-ui/icons'
import {useStyles} from './styles'
import {changeUpperCase} from '../../../../helper/fnAllways'
import FormShowListNotEat from './ListStuNotEat'
import FormShowListStu from './ListStu'


export default function Index({list}){
  const classes = useStyles()
  const [showListNotEat,setShowListNotEat] = useState(false)
  const [showListStu,setShowListStu] = useState(false)
  const [valueTranfer,setValue]      = useState(null)
  const [nameTranfer,setName]      = useState(null)
  const [idClass,setIdClass]       = useState(null)
  const SumListNotEat= (list.map(v=>v.numCheckDay)).reduce((x,y)=>x+y,0)
  const ShowList=()=>{
      return list.map((v,i)=>{
        return <TableRow key={i}>
                    <TableCell align="center">{i+1}</TableCell>
                    <TableCell align="center">{changeUpperCase(v.nameClass)}</TableCell>
                    <TableCell align="center">{v.numCheckDay}</TableCell>
                    <TableCell align="center"><Visibility color="secondary" className={classes.Point} onClick={()=>handleListNotEat(v.listNotEat,v.nameClass)}/>{v.listNotEat.length}</TableCell>
                    <TableCell align="center"><Visibility color="primary"   className={classes.Point} onClick={()=>handleListStu(v.listStudent,v.nameClass,v.idClass)}/>{v.listStudent.length}</TableCell>
               </TableRow>
      })
  }

  // hien thi danh sach bao vang
    const handleListNotEat=(v,i)=>{
      setShowListNotEat(true)
      setValue(v)
      setName(i)
    }
    const CloseListNotEat=()=>{
      setShowListNotEat(false)
    }

  //hien thi danh sach hoc sinh trong thang
  const handleListStu=(v,i,id)=>{
      setShowListStu(true)
      setValue(v)
      setName(i)
      setIdClass(id)
  }
  const CloseListStu=()=>{
    setShowListStu(false)
  }
  return (      
    <>
      
          {showListNotEat&&<FormShowListNotEat
                open={showListNotEat}
                handleClose={CloseListNotEat}
                value={valueTranfer}
                name={nameTranfer}
          />}
          {showListStu&&<FormShowListStu
                open={showListStu}
                handleClose={CloseListStu}
                value={valueTranfer}
                name={nameTranfer}
                idClass={idClass}
          />}
       
      <TableContainer component={Paper}>    
        <Table className={classes.table} stickyHeader aria-label="sticky table" size="small" >
          <TableHead>
            <TableRow>
            <TableCell align="center">Stt</TableCell>                                    
              <TableCell align="center">Lớp</TableCell>                  
              <TableCell align="center">Sỉ Số báo ăn</TableCell>
              <TableCell align="center">Số báo vắng</TableCell>
              <TableCell align="center">Danh sách</TableCell>        
            </TableRow>
          </TableHead>
          <TableBody>       
                  {ShowList()}
                  <TableRow>
                    <TableCell align="center" colSpan="2">Tổng số</TableCell>
                    <TableCell align="center">{SumListNotEat}</TableCell>
                  </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}










