import {call, put, takeLatest} from 'redux-saga/effects'
import { ADD_MATERIAL_NUTRIKID,GET_LIST_MATERIAL_NUTRIKID,ADD_MATERI_NUTRIKID,UPLOAD_IMPORT_LIST_MATERIAL_NUTRIKID, UPDATE_MATERIAL_NUTRIKID } from '../contant/material'
import { loadingSuccess,loadingFail } from '../../../../actions/uiLoading'
import { AddMaterialNutrikidApi,GetMaterialNutrikidApi,AddMateriNutrikidApi } from '../api/material'
import { DispatchAddMaterialNutrikidToStore,DispatchListMaterialToStore,DispatchAddMateriNutrikidToStore,DispatchUpdateMaterialNutrikid} from '../action/material'

const SUCCESS = 'success'
const FAIL    = 'fail'

function* AddMaterialNutrikidSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(AddMaterialNutrikidApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(loadingFail())
        yield put(DispatchAddMaterialNutrikidToStore(res.data.data))
    }
}

function* GetListMaterialNutrikidSaga(){
        yield put(loadingSuccess())
        let res = yield call(GetMaterialNutrikidApi)
        if(res.data.result===SUCCESS){
            yield put(loadingFail())
            yield put(DispatchListMaterialToStore(res.data.data))
        }
       // console.log('dang lay')
}

function* AddMateriNutrikidSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(AddMateriNutrikidApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(loadingFail())
        yield put(DispatchAddMateriNutrikidToStore(res.data.data))
    }
}

function* UploadImportListMaterialNutrikidSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(AddMateriNutrikidApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(loadingFail())
        yield put(DispatchAddMateriNutrikidToStore(res.data.data))
    }
}

function* UpdateMaterialNutrikidSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(AddMateriNutrikidApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(loadingFail())
        yield put(DispatchUpdateMaterialNutrikid(res.data.data))
    }
}

function* MaterialSaga(){
        yield takeLatest(ADD_MATERIAL_NUTRIKID,AddMaterialNutrikidSaga)
        yield takeLatest(GET_LIST_MATERIAL_NUTRIKID,GetListMaterialNutrikidSaga)
        yield takeLatest(ADD_MATERI_NUTRIKID,AddMateriNutrikidSaga)
        yield takeLatest(UPLOAD_IMPORT_LIST_MATERIAL_NUTRIKID,UploadImportListMaterialNutrikidSaga)
        yield takeLatest(UPDATE_MATERIAL_NUTRIKID,UpdateMaterialNutrikidSaga)
}

export default MaterialSaga