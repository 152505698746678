import {CLOSE_FORM_REGIS_ACCOUNT_KIDSTOP,CLOSE_FORM_FUNCTION_ACCOUNT_KIDSTOP,OPEN_FORM_FUNCTION_ACCOUNT_KIDSTOP,OPEN_FORM_REGIS_ACCOUNT_KIDSTOP } from "../contant/StateCss";
let initialState={
   FormRegisKidstop:false,
   FormFnKidstop:false
}
const IndexReducer =(state=initialState,action)=>{
    switch (action.type) {
       case CLOSE_FORM_REGIS_ACCOUNT_KIDSTOP:
        return{
                 ...state,
                 FormRegisKidstop:true
        }
        case OPEN_FORM_REGIS_ACCOUNT_KIDSTOP:
            return{
                ...state,
                FormRegisKidstop:false
            }
        case CLOSE_FORM_FUNCTION_ACCOUNT_KIDSTOP:
            return{
                ...state,
                FormFnKidstop:true
            }
        case OPEN_FORM_FUNCTION_ACCOUNT_KIDSTOP:
            return{
                ...state,
                FormFnKidstop:false
            }
        default: return state
    }
}

export default IndexReducer