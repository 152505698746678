import React from 'react';
import {DialogContent, DialogActions} from '@material-ui/core'

function Index({InputHtml,ActionButton}){  
  return(
    <>
        <DialogContent>
              {InputHtml()}
       </DialogContent>
       <DialogActions>
              {ActionButton}
       </DialogActions>
    </>
  )
}

export default Index