import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: theme.spacing(1),
  },
  table: {
    minWidth: 650,
  },
  Point:{
    cursor:'pointer'
  },
  PrintCountEat:{
    cursor:'pointer',
    marginTop:'-21px',
    marginLeft:'186px'
  },
  PrintAll:{
    cursor:'pointer',
    marginTop:'-24px',
    marginLeft:'215px'
  },
  head1:{
    marginTop:'-37px',
    marginLeft:'-38px'
   },
   head2:{
     marginTop:'10px',
     marginBottom:'10px',   
   },
   head3:{
     marginTop:"-20px"
   },
   head3Repo:{
     fontWeight:"bold",
     marginTop:'-23px'
   },
   date:{
      marginTop:'-22px'
   },
   head4:{
     marginTop:'10px'
   },
   footerOne:{
     marginTop:'15px'
   },
   footerSecond:{
     marginTop:'65px'
   },
   PrintAllFooter:{
     marginBottom:'400px'
   },
   PrintAllHeader:{
    marginTop:'30px'
  }
}));