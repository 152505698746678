import {put,takeEvery,call,select, takeLatest} from 'redux-saga/effects'
import {DispatchAddStudentRegisSevenDayToStore,DispatchListRegisSevenToStore,UpdateRegisSevenDaySuccess, 
  //updateCheckDateFoodSuccess
} from '../../../actions/Gov/student/student'
import {AddStudentRegisSevenDay,ListStudentRegisSevenDay,UpdateStudentRegisSevenDay}from '../../../api-services/student/student'
import {DISPATCH_ADD_STUDENT_REGIS_SEVEN_DAY,LIST_REGIS_SEVEN,DISPATCH_UPDATE_REGIS_SEVEN_DAY} from '../../../contant/Gov/student/dispatch'
import * as uiLoading from '../../../actions/uiLoading'


function* AddStudentRegisSevenDaySaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(AddStudentRegisSevenDay,payload.item)
    if(res.data.result==='success'){    
      yield put(DispatchAddStudentRegisSevenDayToStore(res.data.data))
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }    
}

function* ListRegisSevenSaga(){
  yield put (uiLoading.loadingSuccess())
  let userId = yield select(state=>state.GovLogin.userId)
  let id = {id:userId} 
  let res=yield call(ListStudentRegisSevenDay,id)
  if(res.data.result==='success'){  
    yield put(DispatchListRegisSevenToStore(res.data.data))  
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }    
}

function* UpdateRegisSevenDaySaga({payload}){
  yield put (uiLoading.loadingSuccess())
  let res = yield call(UpdateStudentRegisSevenDay,payload.item)
  if(res.data.result==='success'){  
    yield put(UpdateRegisSevenDaySuccess())
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }    
}

function* regisSevenDaySaga(){
  yield takeLatest(DISPATCH_ADD_STUDENT_REGIS_SEVEN_DAY,AddStudentRegisSevenDaySaga)
  yield takeLatest(LIST_REGIS_SEVEN,ListRegisSevenSaga)
  yield takeLatest(DISPATCH_UPDATE_REGIS_SEVEN_DAY,UpdateRegisSevenDaySaga)
}

export default regisSevenDaySaga