import React from 'react'
import {Search} from '@material-ui/icons'

export const SearchComponent = [
  {
    id:'standard-basic',
    label:<Search color="primary"/>
  }
]
  


