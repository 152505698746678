
  const size = {   
    mobile: '740px',   
    tableMin: '740px',
    tableMax:'1023px',
    pc: '1024px',
  }  


  export const device = {   
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(min-width: ${size.tableMin} and (max-width:${size.tableMax}))`,   
    pc: `(min-width: ${size.pc})`
  };

