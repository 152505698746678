import {  makeStyles } from '@material-ui/core/styles';

export const useStyles =makeStyles(theme=>({ 
   buttonNextCountEat:{
      paddingLeft:"140px"
   },
   root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}))