import {toastSuccess,toastError} from '../helper/toastHelper'
import * as TypeAuth from '../contant/auth'
import * as TypeStaff from '../contant/table/Staff'
import * as TypeGov from '../contant/table/Gov'
import * as TypePerson from '../contant/table/Person'
import * as TypeVip from '../contant/table/Vip'
import * as notifi from '../contant/notification'
import _ from 'lodash'
import { ACTIVE_SOFT_FOOD_STATUS } from '../contant/Gov/softFood'
//import { ConvertTrueFalseActive } from './fn'
import { updateArrById } from '../helper/fnAllways'

export const initialState = { 
  DBInfo:[],
  DBDepartment:[],
  DBUser:[],
  DBStaff:[],
  DBGov:[],
  DBVip:[],
  DBPerson:[],
  Admin:[],
  Gov:[],
  Staff:[],
  Vip:[],
  Person:[],
  GetData:[],//nhom nha nuoc,
  ListAllUser:[]
}

const authReducer = (state=initialState,action)=>{
  switch (action.type) {
    case ACTIVE_SOFT_FOOD_STATUS:
      let updateItem = updateArrById(state.DBGov,action.payload.item)
      return{
        ...state,
        DBGov:updateItem
      }
    case TypeAuth.SIGNUP:
        //let {dataSignup} = action.payload
        return {
          ...state          
        }

    case TypeAuth.SIGNUP_SUCCESS:
       let {messageSuccess} = action.payload
        toastSuccess(messageSuccess)
        return{
          ...state
        }

    case TypeAuth.SIGNUP_FAIL:
      let {messageFail} = action.payload      
      toastError(messageFail)      
      return{
          ...state
        }
      
    case TypeAuth.SIGNUP_FAIL_CHECK_USERNAME:      
      toastError(notifi.SIGNUP_FAIL_CHECK_USERNAME)
      return{
        ...state
      }

    case TypeAuth.LOGIN:
        // let {dataLogin} = action.payload
        return {
          ...state
        }

    case TypeAuth.LOGIN_SUCCESS:
      let {messageSuccessLogin} = action.payload
      toastSuccess(messageSuccessLogin)
      return {
        ...state,
      }

    case TypeAuth.LOGIN_FAIL:
      let {messageFailLogin} = action.payload
      toastError(messageFailLogin)
      return {
        ...state
      }      
        
    case TypeAuth.LOGIN_FAIL_CHECK_USER_PASS:
      toastError(notifi.LOGIN_FAIL_CHECK_USER_PASS)
      return{
        ...state
      }

      case TypeAuth.GET_LIST_FORM:
      let {dbDepartment,dbInfo,dbUser,listAllUser} = action.payload       
      let idAdmin = listAllUser.filter(item=>item.idGroup===1)   
      let idStaff  = listAllUser.filter(item=>item.idGroup===2) 
      let idGov    = listAllUser.filter(item=>item.idGroup===5) 
      let idVip    = listAllUser.filter(item=>item.idGroup===3)   
      let idPerson = listAllUser.filter(item=>item.idGroup===4)    
     
      let listGov = dbUser.filter(value=>value.idGroup===5)
      let getResult = listGov.map(item=>dbInfo.filter(result=>result.userId===item._id))   
      getResult = (_.flatten(getResult))
     
      //nhan vien               
      let findStaff = idStaff.map(item=>dbDepartment.filter(result=>result.userId===item._id))       
      findStaff = _.flatten(findStaff)
      //nha nuoc
      let findGov  = idGov.map(item=>dbInfo.filter(result=>result.userId===item._id))
      findGov = _.flatten(findGov)
      //khach vip 
      let findVip = idVip.map(item=>dbDepartment.filter(result=>result.userId===item._id))
      findVip = _.flatten(findVip)
     
      let findGetDataGovVip = findVip.map(item=>getResult.filter(result=>result.userId!==item.infoUserId))
      findGetDataGovVip=(_.flatten(findGetDataGovVip))
      //khach tu nhan
      let findPerson = idPerson.map(item=>dbDepartment.filter(result=>result.userId===item._id))
      findPerson = _.flatten(findPerson)     
      
      return{
        ...state,
        Admin:idAdmin,
        Gov:idGov,
        Staff:idStaff,
        Person:idPerson,
        Vip:idVip,
        DBInfo:dbInfo,
        DBDepartment:dbDepartment,
        DBUser:dbUser,//nha nuoc
        DBListAllUser:listAllUser,
        DBStaff:findStaff,
        DBGov:findGov,
        DBVip:findVip,
        DBPerson:findPerson,
        //GetData:_.flatten(getResult),
        GetData:findGetDataGovVip
      }

        //statff
        case TypeStaff.LIST_STAFF:
        return{...state}

        case TypeStaff.ADD_ITEM_LIST_STAFF:
          let {item} = action.payload
          let oldArray = state.DBStaff
          let newArray= oldArray.concat(item)     
          return {
            ...state,
            DBStaff:newArray
          }
         //staff

           //person
           case TypePerson.LIST_PERSON:
           return{...state}

           case TypePerson.ADD_ITEM_LIST_PERSON:            
            let {person} = action.payload
            let oldArray1 = state.DBPerson
            let newArray1= oldArray1.concat(person)     
            return {
              ...state,
              DBPerson:newArray1
            }
            //person

          //gov
          case TypeGov.LIST_GOV:
            return {...state}

          case TypeGov.ADD_ITEM_LIST_GOV:
          //console.log(action.payload)
          let {data} = action.payload
          //console.log(data)
          let oldArray2 = state.DBGov
          let newArray2= oldArray2.concat(data)     
          return {
            ...state,
            DBGov:newArray2
          }
          //gov

            //vip
            case TypeVip.LIST_VIP:
              return {...state}
  
            case TypeVip.ADD_ITEM_LIST_VIP:
            //console.log(action.payload)
            let {vip} = action.payload
            //console.log(data)
            let oldArray3 = state.DBVip
            let newArray3= oldArray3.concat(vip)     
            return {
              ...state,
              DBVip:newArray3
            }
            //vip

          //deleteGov
                case TypeGov.DELETE_GOV_ARRAY:
                  // console.log(action.payload.allId)
                  // console.log(state.DBGov)
                  let arrayCurrent=action.payload.allId
                  let arrayResult =state.DBGov
                  let newResult = _.difference(arrayResult,arrayCurrent)
                  console.log(newResult)
                return{
                  ...state,         
                  DBGov:newResult    
                }

                case TypeGov.DELETE_GOV_ARRAY_SUCCESS:
                  toastSuccess('Xoá Thành Công')
                  return{
                    ...state
                  }
                case TypeGov.DELETE_GOV_ARRAY_FAIL:
                  toastError('Lỗi, kiểm tra lại, xóa thất bại')
                  return{
                    ...state
                  }
                //deleteGov

              //deleteStaff
              case TypeStaff.DELETE_STAFF_ARRAY:                
                let arrayCurrent2=action.payload.allId
                let arrayResult2 =state.DBStaff
                let newResult2 = _.difference(arrayResult2,arrayCurrent2)
                
              return{
                ...state,         
                DBStaff:newResult2    
              }

              case TypeStaff.DELETE_STAFF_ARRAY_SUCCESS:
                toastSuccess('Xoá Thành Công')
                return{
                  ...state
                }
              case TypeStaff.DELETE_STAFF_ARRAY_FAIL:
                toastError('Lỗi, kiểm tra lại, xóa thất bại')
                return{
                  ...state
                }
              //deleteStaff

               //deletePerson
               case TypePerson.DELETE_PERSON_ARRAY:                
               let arrayCurrent3=action.payload.allId
               let arrayResult3 =state.DBPerson
               let newResult3 = _.difference(arrayResult3,arrayCurrent3)               
                return{
                  ...state,         
                  DBPerson:newResult3    
                }

             case TypePerson.DELETE_PERSON_ARRAY_SUCCESS:
               toastSuccess('Xoá Thành Công')
               return{
                 ...state
               }
             case TypePerson.DELETE_PERSON_ARRAY_FAIL:
               toastError('Lỗi, kiểm tra lại, xóa thất bại')
               return{
                 ...state
               }
             //deletePerson

              //deleteVip
              case TypeVip.DELETE_VIP_ARRAY:                
              let arrayCurrent4=action.payload.allId
              let arrayResult4 =state.DBVip
              let newResult4 = _.difference(arrayResult4,arrayCurrent4)               
               return{
                 ...state,         
                 DBVip:newResult4
               }

            case TypeVip.DELETE_VIP_ARRAY_SUCCESS:
              toastSuccess('Xoá Thành Công')
              return{
                ...state
              }
            case TypeVip.DELETE_VIP_ARRAY_FAIL:
              toastError('Lỗi, kiểm tra lại, xóa thất bại')
              return{
                ...state
              }
            //deleteVip

             //editGov
              case TypeGov.EDIT_GOV:{
              let value = action.payload.newData
              let {DBGov} = state
              let index = DBGov.findIndex(item=>item.userId===value.userId)
              if(index!==-1){
                let newDBGov = [
                  ...DBGov.slice(0,index),
                  value,
                  ...DBGov.slice(index+1)
                ]
                return {
                ...state,
                DBGov:newDBGov
               }
              }
              return {...state}
            }              

              case TypeGov.EDIT_GOV_SUCCESS:
                toastSuccess('Cập nhật thành công')
                return{
                  ...state
                }
              case TypeGov.EDIT_GOV_FAIL:
                toastError('Lỗi, kiểm tra lại, cập nhật thất bại')
                return{
                  ...state
                }
      //editGov

       //editStaff
       case TypeStaff.EDIT_STAFF:{
        let value1 = action.payload.newData
        let {DBStaff} = state
        let index = DBStaff.findIndex(item=>item.userId===value1.userId)
        if(index!==-1){
          let newDBStaff = [
            ...DBStaff.slice(0,index),
            value1,
            ...DBStaff.slice(index+1)
          ]
          return {
          ...state,
          DBStaff:newDBStaff
         }
        }
        return {...state}
      }              

        case TypeStaff.EDIT_STAFF_SUCCESS:
          toastSuccess('Cập nhật thành công')
          return{
            ...state
          }
        case TypeStaff.EDIT_STAFF_FAIL:
          toastError('Lỗi, kiểm tra lại, cập nhật thất bại')
          return{
            ...state
          }
   //editStaff

   //editPerson
   case TypePerson.EDIT_PERSON:{
    let value2 = action.payload.newData
    let {DBPerson} = state
    let index2 = DBPerson.findIndex(item=>item.userId===value2.userId)
    if(index2!==-1){
      let newDBPerson = [
        ...DBPerson.slice(0,index2),
        value2,
        ...DBPerson.slice(index2+1)
      ]
      return {
      ...state,
      DBPerson:newDBPerson
     }
    }
    return {...state}
  }              

    case TypePerson.EDIT_PERSON_SUCCESS:
      toastSuccess('Cập nhật thành công')
      return{
        ...state
      }
    case TypePerson.EDIT_PERSON_FAIL:
      toastError('Lỗi, kiểm tra lại, cập nhật thất bại')
      return{
        ...state
      }
  //editPerson

  //editVip
  case TypeVip.EDIT_VIP:{
    let value3 = action.payload.newData
    let {DBVip} = state
    let index3 = DBVip.findIndex(item=>item.userId===value3.userId)
    if(index3!==-1){
      let newDBVip = [
        ...DBVip.slice(0,index3),
        value3,
        ...DBVip.slice(index3+1)
      ]
      return {
      ...state,
      DBVip:newDBVip
     }
    }
    return {...state}
  }              

    case TypeVip.EDIT_VIP_SUCCESS:
      toastSuccess('Cập nhật thành công')
      return{
        ...state
      }
    case TypeVip.EDIT_VIP_FAIL:
      toastError('Lỗi, kiểm tra lại, cập nhật thất bại')
      return{
        ...state
      }
  //editVip
  case TypeAuth.DISPATCH_LIST_USER_STORE:
    //console.log(action.payload.arr)
    return{
      ...state,
      ListAllUser:action.payload.arr
    }

    default: return state     
  }
}

export default authReducer
