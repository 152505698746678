//import uuidv4 from 'uuid'
import _ from 'lodash'

export const HandleListJob=(listAll,Job)=>{ 
   if(Job.ListJobs.length===0)return listAll
   if(Job.ListJobs.length!==0){
      let result =  Job.ListJobs.map(v=>{
      let FilterJob = listAll.filter(vv=>vv.IdLoaiCongViec===v._id)
      return FilterJob
   })
      return _.flatten(result.filter(v=>v!==undefined))   
   }   
}

export const HandleArrPhatDat=(arr)=>{
   
   let newArr = arr.map(v=>{
      return{
         ...v,
         //_id:uuidv4(),
         priceOri:handlePriceName(v.priceName),
         priceSell:handlePriceSell(v.priceName)
      }
   })
   let arrCate = newArr.map(v=>v.nameCate)
   let newArrCate = [...new Set(arrCate)]
   let result = newArrCate.map(v=>{
      return{
         name:v,
      }
   })
   return {
    arrCateAll:newArr,
    arrCateItem:result
   }
}

export  const handlePriceName=(value)=>{
   let newValue = value.split('.')
   if(newValue.length===2){
      return +(newValue[0].concat(newValue[1]))
   }
   if(newValue.length===3){
     return +(newValue[0].concat(newValue[1].concat(newValue[2])))
   }
   return 0
}

export const handlePriceSell=(value)=>{
      let findValue = handlePriceName(value)
      if(findValue<500000)return findValue*2.6
      if(findValue>500000&&findValue<1500000) return findValue*1.6
      if(findValue>1500000&&findValue<2000000) return findValue*1.5
      if(findValue>2000000&&findValue<3000000)  return findValue*1.3
      if(findValue>3000000&&findValue<5000000) return findValue*1.3
      if(findValue>5000000)return findValue*1.25
}