import { Button, DialogActions, DialogContent, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { CLOSE_TUYEN_DUNG, UPDATE_ITEM_TUYEN_DUNG } from '../../../contant';
import{useDispatch,useSelector} from 'react-redux'

const DoiTac = () => {
    const ItemTuyenDung = useSelector(state=>state.MainWeb.ItemTuyenDung)  

    const ValueTuyenDung = useSelector(state=>state.MainWeb.ValueTuyenDung)
    const HandleShowNameTuyenDung=(value)=>{
        if(value==="DOI_TAC")return 'DOI_TAC'
        if(value==="THI_TRUONG")return 'THI_TRUONG'
        if(value==="CHAM_SOC")return 'CHAM_SOC'
        if(value==="CTV")return 'CTV'   
    }
    const dispatch = useDispatch()
    const [nameTuyenDung,setNameTuyenDung] = useState(HandleShowNameTuyenDung(ValueTuyenDung.value))
    const [nameCus,setNameCus]             = useState("")
    const [fullNameCus,setFullNameCus]     = useState("")
    const [tel,setTel]                     = useState("")
    const [email,setEmail]                 = useState("")
    const [taxCode,setTaxCode]             = useState("")
    const [address,setAddress]             = useState("")

    const [errNamCus,setErrNamCus]           = useState(false)
    const [errFullNameCus,setErrFullNameCus] = useState(false)
    const [errTel,setErrTel]                 = useState(false)
    const [errEmail,setErrEmail]             = useState(false)
    const [errTaxCode,setErrTaxCode]         = useState(false)
    const [errAdd,setErrAdd]                 = useState(false)

    const SendInfo=()=>{
        let item ={
            nameTuyenDung,
            nameCus,
            fullNameCus,
            tel,
            email,
            taxCode,
            address
        }
        if(item.nameCus===""){
            setErrNamCus(true)
            setTimeout(()=>{
                setErrNamCus(false)
            },3000)
            return
        }
        if(item.fullNameCus===""){
            setErrFullNameCus(true)
            setTimeout(()=>{
                setErrFullNameCus(false)
            },3000)
            return
        }
        if(item.address===""){
            setErrAdd(true)
            setTimeout(()=>{
                setErrAdd(false)
            },3000)
            return
        }
        if(item.tel===""){
            setErrTel(true)
            setTimeout(()=>{
                setErrTel(false)
            },3000)
            return
        }
        if(item.taxCode===""){
            setErrTaxCode(true)
            setTimeout(()=>{
                setErrTaxCode(false)
            },3000)
            return
        }
        if(item.email===""){
            setErrEmail(true)
            setTimeout(()=>{
                setErrEmail(false)
            },3000)
            return
        }
       let newResult = [ItemTuyenDung].map(v=>{
        return{
            ...v,
            ListTuyenDung:v.ListTuyenDung.concat(item)
        }
       })[0]
       dispatch({type:UPDATE_ITEM_TUYEN_DUNG,payload:{item:newResult}})
       dispatch({type:CLOSE_TUYEN_DUNG})
       return ()=>{}
    }

    return (        
        <>
            <DialogContent dividers>
                    <Typography gutterBottom>
                                Hệ Thống Phần Mềm Dinh Dưỡng đã đạt những tiêu chuẩn khắt khe nhất để hỗ trợ người tiêu dùng trong
                                việc tính toán, xử lý số liệu.
                                Vì vậy cần tìm kiếm các đối tác đủ năng lực mở để rộng thị trường, tiếp cận khách hàng nhanh hơn để đảm bảo có doanh thu bền vững và mang lại lợi ích cho khách hàng.                       
                    </Typography>  
                    <Typography gutterBottom>
                            <TextField
                                fullWidth
                                size="small"
                                label="Tên Doanh Nghiệp / Đại Lý"
                                variant="filled"
                                onChange={e=>setNameCus(e.target.value)}
                                error={errNamCus}
                            />
                    </Typography>    
                    <Typography gutterBottom>
                            <TextField
                                fullWidth
                                size="small"
                                label="Tên Người Liên Hệ"
                                variant="filled"
                                onChange={e=>setFullNameCus(e.target.value)}
                                error={errFullNameCus}
                            />
                    </Typography>    
                    <Typography gutterBottom>
                            <TextField
                                fullWidth
                                size="small"
                                label="Địa chỉ"
                                variant="filled"
                                onChange={e=>setAddress(e.target.value)}
                                error={errAdd}
                            />
                    </Typography> 
                    <Typography gutterBottom>
                            <TextField
                                fullWidth
                                size="small"
                                label="Số Điện Thoại"
                                variant="filled"
                                onChange={e=>setTel(e.target.value)}
                                error={errTel}
                            />
                    </Typography>    
                    <Typography gutterBottom>
                            <TextField
                                fullWidth
                                size="small"
                                label="Mã Số Thuế"
                                variant="filled"
                                onChange={e=>setTaxCode(e.target.value)}
                                error={errTaxCode}
                            />
                    </Typography>    
                    <Typography gutterBottom>
                            <TextField
                                fullWidth
                                size="small"
                                label="Email"
                                variant="filled"
                                onChange={e=>setEmail(e.target.value)}
                                error={errEmail}
                            />
                    </Typography>    
            </DialogContent>
            <DialogActions>
                <Button autoFocus color="primary" onClick={()=>SendInfo()}>
                    Gửi Thông Tin
                </Button>
                <Button autoFocus onClick={()=>dispatch({type:CLOSE_TUYEN_DUNG})} color="secondary">
                    Thoát
                </Button>
            </DialogActions>
        </>
    );
};

export default DoiTac;