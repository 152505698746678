 const validate = values =>{
  const errors ={}
  const {userId,email,tel,taxCode,address,workPlace,directorName} = values
  if(!userId){
    errors.userId = "Không được để trống"
  } 
  //email
  if(!email){
    errors.email = "Email không được để trống"
  }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
    errors.email = "Email không hợp lệ"
  }  
  //tel
  if(!tel){
    errors.tel="Số điện thoại không được để trống"
  }else if( tel.trim().length<10 || tel.trim().length>13){
    errors.tel="Số điện thoại không tồn tại"
  }
  //taxCode ma so thue
  if(!taxCode){
    errors.taxCode="Mã số thuế không được để trống"
  }else if(taxCode.trim().length<10 || taxCode.trim().length>13){
    errors.taxCode="Mã số thuế không hợp lệ"
  }
  //address
  if(!address){
    errors.address="Địa chỉ không được để trống"
  }else if(address.trim().length<10 || address.trim().length>100){
    errors.address="ít nhất 10 đến 100 ký tự"
  }
  //workPlace
  if(!workPlace){
    errors.workPlace="Nơi làm việc không được để trống"
  }else if(workPlace.trim().length<10 || workPlace.trim().length>100){
    errors.workPlace="Có ít nhất 10 đến 100 ký tự"
  }
  //directorName
  if(!directorName){
    errors.directorName="Nơi làm việc không được để trống"
  }else if(directorName.trim().length<10 || directorName.trim().length>100){
    errors.directorName="Có ít nhất 10 đến 100 ký tự"
  }

  return errors  
}

export default validate


