import {createStore,compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {createBrowserHistory} from 'history'
import {routerMiddleware} from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../saga/rootSaga'
import rootReducers from '../reducers'

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  shouldHotReload: false
})
: compose


export const history = createBrowserHistory()

const configStore = ()=>{
  const middlewares = [thunk,sagaMiddleware,routerMiddleware(history)]
  const enhancers =[applyMiddleware(...middlewares)]
  const store = createStore(
    rootReducers(history),
    composeEnhancers(...enhancers)    
    )
  sagaMiddleware.run(rootSaga)
  return store
}



export default configStore