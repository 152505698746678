import { toastSuccess,toastError } from "../../../../helper/toastHelper"
import {DISPATCH_THANH_TOAN_BY_USER_TO_STORE,UPDATE_THANH_TOAN_BY_USER,UPDATE_THANH_TOAN_BY_USER_GIA_HAN,EMAIL_SEND_NPP_SUCCESS, DISPATCH_LIST_PAYMENT_KIDTOP_TO_STORE, DISPATCH_VALUE_PAYMENT_KIDTOP} from '../contant/ThanhToan'

export const  initialState={
        ItemThanhToan:null,
        PaymentKidtop:null,
        ItemPaymentKidTop:null
}

const Index =(state=initialState,action)=>{
    switch (action.type) {
        case DISPATCH_VALUE_PAYMENT_KIDTOP:
            return{
                ...state,
                ItemPaymentKidTop:action.payload.item
            }
        case DISPATCH_LIST_PAYMENT_KIDTOP_TO_STORE:
            return{
                ...state,
                PaymentKidtop:action.payload.item
            }
        case DISPATCH_THANH_TOAN_BY_USER_TO_STORE:
            return{
                ...state,
                ItemThanhToan:action.payload.item,               
            }            
        case UPDATE_THANH_TOAN_BY_USER:
            //toastSuccess('Đã Cập Nhật')
            return{
                ...state,
                ItemThanhToan:action.payload.item
            } 
        case UPDATE_THANH_TOAN_BY_USER_GIA_HAN:
            //console.log(action.payload.item.result)
            return{
                ...state,
                ItemThanhToan:action.payload.item.result
            }
        case EMAIL_SEND_NPP_SUCCESS:
            if(action.payload.item===true){
                 toastSuccess('Chúng tôi sẽ gửi thông báo qua email, cám ơn quý khách!')
                           return{
                             ...state
                          }
            }
            if(action.payload.item===false){
                toastError('Gửi email lỗi, bạn thử lại! Nếu không được vui lòng liên hệ trực tiếp với cúng tôi')
                return{
                    ...state
                 }
            }           
    
        default: return state
    }
}

export default Index