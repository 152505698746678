import React, { useEffect } from "react"
import {useDispatch} from 'react-redux'
import {Table,TableBody,TableCell,TableHead, TableRow, TableContainer, Grid, Button} from '@material-ui/core'
import {Edit} from '@material-ui/icons'
import { OPEN_UPDATE_APP_ZALO_ACCESS_TOKEN, UPDATE_APP_ZALO_ACCESS_TOKEN } from "../NotFoundPage/contant/Zalo"
import { ToolSendNofiPhone, ToolUpdateRefreshToken } from "./fn"
const Index=({listToken})=>{
    const dispatch    = useDispatch()
    const UpdateToken = (value)=>{
        dispatch({type:OPEN_UPDATE_APP_ZALO_ACCESS_TOKEN,payload:{item:value}})
    }
    const UpdateRefreshToken=async(value)=>{                          
                        let result =await ToolUpdateRefreshToken(value)
                        //console.log(result)
                        if(result.expires_in!==undefined){
                                     //cap nhat form
                            let newValue = [value].map(v=>{
                                return{
                                    ...v,
                                    access_token:result.access_token,
                                    refresh_token:result.refresh_token
                                }
                            })[0]
                             dispatch({type:UPDATE_APP_ZALO_ACCESS_TOKEN,payload:{item:newValue}})
                             return()=>{}
                        }
                        if(result===false){
                            alert('Lỗi Zalo, cấp lại token')
                            return ()=>{}
                        }                      
    }

    const SendNofiPhone=async(value)=>{
        let result = await ToolSendNofiPhone(value)
    }
             return(
                <Grid item container spacing={2}>
                        <TableContainer>
                                                <Table stickyHeader size="small" style={{width:'50%'}}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>STT</TableCell>
                                                    <TableCell>UrlRequestAccessToken</TableCell>
                                                    <TableCell>Tên Ứng Dụng</TableCell>
                                                    <TableCell>Domain</TableCell>
                                                    <TableCell>App_id</TableCell>
                                                    <TableCell>CallBackUrl</TableCell>
                                                    <TableCell>Secret_key</TableCell>
                                                    <TableCell style={{width:200}}>AccessToken</TableCell>
                                                    <TableCell style={{width:200}}>Refresh_token</TableCell>
                                                    <TableCell>Update Refresh_token</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {listToken.length!==0&&<>
                                                    {listToken.map((v,i)=>{
                                                        return(
                                                            <TableRow hover key={i}>
                                                                <TableCell>{i+1}
                                                                            <Button variant="contained" size="small"
                                                                                    color="primary"
                                                                                    onClick={()=>UpdateRefreshToken(v)}
                                                                            >Refresh Token</Button>&nbsp;
                                                                            <Button color="primary" variant="contained" size="small"
                                                                                    onClick={()=>SendNofiPhone(v)}
                                                                            >
                                                                            Send Nofi Phone
                                                                            </Button>
                                                                </TableCell>
                                                                <TableCell>{v.urlRequestAccessToken}</TableCell>
                                                                <TableCell>{v.nameApp}</TableCell>
                                                                <TableCell>{v.domain}</TableCell>
                                                                <TableCell>{v.app_id}</TableCell>
                                                                <TableCell>{v.callBackUrlDomain}</TableCell>
                                                                <TableCell>{v.secret_key}</TableCell>
                                                                <TableCell style={{width:200}}>{v.access_token}</TableCell>
                                                                <TableCell style={{width:200}}>{v.refresh_token}</TableCell>
                                                                <TableCell><Edit style={{color:'orange',cursor:'pointer'}} onClick={()=>UpdateToken(v)}/></TableCell>
                                                            </TableRow>
                                                        )
                                                    })}                                    
                                                </>}                            
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                </Grid>        
                  
            )
}

export default Index