import {DISPATCH_LIST_PRICE_SOFT_TO_STORE} from '../contant/holdingtech'
import { DISPATCH_ITEM_TUYEN_DUNG_TO_STORE, DISPATCH_UPDATE_TUYEN_DUNG_SUCCESS } from '../GioiDinhTue/component/contant'

export const DispatchListPriceSoftToStore = (item) =>{
    return {
        type:DISPATCH_LIST_PRICE_SOFT_TO_STORE,
        payload:{item}
    }
}

export const DispatchItemTuyenDungToStore =(item)=>{
    return {
        type:DISPATCH_ITEM_TUYEN_DUNG_TO_STORE,
        payload:{item}
    }
}

export const DispatchUpdateTuyenDungSuccess =(item)=>{
    return {
        type:DISPATCH_UPDATE_TUYEN_DUNG_SUCCESS,
        payload:{item}
    }
}