import {call, put,takeEvery} from 'redux-saga/effects'
import { loadingSuccess,loadingFail } from '../../../../actions/uiLoading'
import { DispatchAddFirstTableAgeToStore,DispatchAddTableToStore } from '../action/tableAge'
import { AddTableAgeApi,AddFirstTableAgeApi } from '../api/tableAge'
import { ADD_FIRST_TABLE_AGE, ADD_TABLE_AGE } from '../contant/tableAge'

const SUCCESS = 'success'
const FAIL    = 'fail'


function* AddFirstTableAgeSaga({payload}){
        //yield put(loadingSuccess())
        let res = yield call(AddFirstTableAgeApi,payload.item)
        if(res.data.result===SUCCESS){
         // yield put(loadingFail())
          yield put(DispatchAddFirstTableAgeToStore(res.data.data))
        }    
}

function* AddTableAgeSaga({payload}){
   // yield put(loadingSuccess())
    let res = yield call(AddTableAgeApi,payload.item)
    if(res.data.result===SUCCESS){
    //    yield put(loadingFail())
        yield put(DispatchAddTableToStore(res.data.data))
      }
}

function* TableAgeSaga(){
        yield takeEvery(ADD_FIRST_TABLE_AGE,AddFirstTableAgeSaga)
        yield takeEvery(ADD_TABLE_AGE,AddTableAgeSaga)
}

export default TableAgeSaga