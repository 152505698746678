import React,{Suspense} from 'react'
//import Gov from './Gov'
import {compose} from 'redux'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { GET_LIST_USER_THANH_TOAN } from '../../../contant/Gov/softFood'

const Gov     = React.lazy(()=>import('./Gov'))
const BangGia = React.lazy(()=>import('./BangGia'))

const Index =React.memo(()=>{   
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch({type:GET_LIST_USER_THANH_TOAN})
  },[])
  return (
    <Suspense fallback={()=>{}}>
       <BangGia/>
       <Gov/>
    </Suspense>   
  )
})

export default compose(
)(Index)