import axiosServices from '../../../../api-services/api'
import {API_ENDPOINT} from  '../../../../contant/index'

const urlAddMaterialNutrikid = 'addMaterialNutrikid'
const urlGetMaterialNutrikid = 'getMaterialNutrikid'
const urlAddMateriNutrikid   = 'addMateriNutrikid'

export const AddMaterialNutrikidApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddMaterialNutrikid}`,item)
}

export const GetMaterialNutrikidApi=()=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetMaterialNutrikid}`)
}

export const AddMateriNutrikidApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddMateriNutrikid}`,item)
}

