import * as TypeTask from '../contant/task'
import {STATUS} from '../contant/index'

export const taskList = () =>{
    return {
      type:TypeTask.TASK_LIST
      //payload:{params}
    }
}

export const taskListSuccess = (data)=>{
  return {
    type:TypeTask.TASK_LOADING_SUCCESS,
    payload: data 
  }
}

export const taskListFail = (err)=>{
  return {
    type:TypeTask.TASK_LOADING_FALING,
    payload: err    
  }
}

export const filterTaskSuccess = (dataFilter) => {
    return{
        type:TypeTask.FILTER_TASK_SUCCESS,
        payload:{dataFilter}
    }
}

export const filterTaskFind =(keyword)=>{
  return {
    type:TypeTask.FILTER_TASK,
    payload:{keyword}
  }
}

export const addTask = (title,description)=>{//data:title,description
  return{
      type:TypeTask.ADD_TASK,
      payload:{title,description}
  }
}

export const addTaskSuccess = (data) =>{
    return {
      type:TypeTask.ADD_TASK_SUCCESS,
      payload:data
    }
}

export const addTaskFail = (error)=>{
    return {
      type:TypeTask.ADD_TASK_FAIL     
    }
}

export const taskEditting = (task)=>{
  return{
    type:TypeTask.TASK_EDITTING,
    payload:{task}
  }
}

export const updateTask = (title,description,status=STATUS[0].status,_id)=>{
  return {
    type:TypeTask.UPDATE_TASK,
    payload:{
      title,description,status,_id
    }
  }
}

export const updateTaskSuccess = (data)=>{
  return {
    type:TypeTask.UPDATE_TASK_SUCCESS,
    payload:{data}
  }
}

export const updateTaskFail = (err)=>{
  return {
    type:TypeTask.UPDATE_TASK_FAIL,
    payload:{err}
  }
}

export const deleteTask = (_id)=>{
    return{
      type:TypeTask.DELETE_TASK,
      payload:{_id}
    }
}

export const deleteTaskSuccess = (data)=>{
  return {
    type:TypeTask.DELETE_TASK_SUCCESS,
    payload:{data}
  }
}

export const deleteTaskFail = (err)=>{
  return{
    type:TypeTask.DELETE_TASK_FAIL,
    payload:{err}
  }
}