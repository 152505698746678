import React,{ useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import YouTube from 'react-youtube';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ThongTinKhachHang } from '../HuongDan/fn';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'red',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 500,
    },
  }));

export default function CustomizedDialogs({handleClose,open}) {   
    const [state, setState] = useState({
    id: '',
    name: '',
    });    
    const handleChange = (event) => {
        setState({        
          id:event.target.value
        });
      };
    const classes = useStyles();
    
    const opts ={
              height: '500',
              width: '500',
            }
    const _onReady=(event)=>{
            event.target.playVideo();
    }



  return (
    <div>    
      <Dialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle style={{color:'green'}} id="customized-dialog-title" onClose={handleClose}>
            Hướng Dẫn Sử Dụng Tab Cài Đặt           
        </DialogTitle>
        <DialogContent>         
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-native-simple">Chọn Nội Dung Xem Hướng Dẫn</InputLabel>
                <Select
                native
                value={state.id}
                onChange={handleChange}                
                >
                <option aria-label="None" value="" />
                {ThongTinKhachHang.map((v,i)=>{
                    return(
                         <option value={v.id} key={i}>{v.name}</option>
                    )
                })}                           
                </Select>
            </FormControl>   
                {state.id!==""&&      
                <div style={{marginTop:15}}>
                   <YouTube videoId={state.id} opts={opts} onReady={_onReady} />
                </div>
                }
        </DialogContent>        
      </Dialog>
    </div>
  );
}
