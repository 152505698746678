export const DAILY_MEAL        = "Tiền ăn" //tien an hang ngay      //DailyMeal
export const DAILY_TUITION     = "Học phí" //hoc phi hang thang  //DailyTui
export const SUMMER_TUITION    ="Phục vụ hè" // phi hoc he                //Summer
export const BREAKFAST_FEE     = "Tiền ăn sáng" //phuc vu an sang          //Breaf
export const INFRASTRUCTURE    = "Cơ sở vật chất" //co so vat chat        //Infras
export const CLEAN_FUEL        = "Hoạt Động Bán Trú" //ve sinh chat dot  //Clean
export const NUM_OF_MEAL_MONTH = "Số buổi ăn"             //NumEat
export const NUM_OF_MEAL_7     = "Số buổi ăn thứ 7"          //NumEat
export const SERVICE_BOARDING  = "Phục vụ bán trú"                      // Boarding
export const SERVICE_SEVEN_DAY = "Phục vụ thứ 7"                        //Seven
export const LEARN_LANGUAGUE   = "Học phí tiếng anh"                   //LearnLg
//export const SET_CLEAN_FUEL    = "Vệ sinh"
//export const SET_FUEL          = "Chất đốt"

export const ARR_COST =[
  DAILY_MEAL,
  DAILY_TUITION,
  BREAKFAST_FEE,
  SERVICE_SEVEN_DAY,
  CLEAN_FUEL,
  SERVICE_BOARDING,
  INFRASTRUCTURE,
  SUMMER_TUITION,   
  LEARN_LANGUAGUE
]
export const ARR_DEFINE_COST = [
  {DailyTui:DAILY_TUITION},
  {DailyMeal:DAILY_MEAL},
  {Summer:SUMMER_TUITION},
  {Breaf:BREAKFAST_FEE},
  {InFras:INFRASTRUCTURE},
  {Clean:CLEAN_FUEL},  
  {Boarding:SERVICE_BOARDING},
  {Seven:SERVICE_SEVEN_DAY},
  {LeanLg:LEARN_LANGUAGUE}
]


export const OPEN_VIEW = "OPEN_VIEW"
export const OPEN_ADD = "OPEN_ADD"

export const ADD_COST_STUDENT ="ADD_COST_STUDENT" 
export const ADD_COST_STUDENT_SUCCESS ="ADD_COST_STUDENT_SUCCESS"
export const ADD_COST_STUDENT_FAIL ="ADD_COST_STUDENT_FAIL"
export const EDIT_COST_STUDENT = "EDIT_COST_STUDENT"
export const DELETE_COST_STUDENT = "DELETE_COST_STUDENT"
export const LIST_COST_STUDENT = "LIST_COST_STUDENT"
export const NAME_COST_STUDENT = "NAME_COST_STUDENT"
export const GET_LIST = "GET_LIST"

export const ADD_MATERIAL = "ADD_MATERIAL"
export const ADD_MATERIAL_STORE = "ADD_MATERIAL_STORE"
export const LIST_ALL_MATERIAL = "LIST_ALL_MATERIAL"
export const LIST_ALL_CATEGORY_MATERIAL ="LIST_ALL_CATEGORY_MATERIAL"
export const DISPATCH_ITEM_CATE_MATERIAL_TO_STORE = "DISPATCH_ITEM_CATE_MATERIAL_TO_STORE"

export const ADD_TYPE_MATERIAL = "ADD_TYPE_MATERIAL"
export const LIST_ALL_TYPE_MATERIAL = "LIST_ALL_TYPE_MATERIAL"
export const ADD_TYPE_MATERIAL_STORE = "ADD_TYPE_MATERIAL_STORE"
export const LIST_ALL_CATEGORY_TYPE_MATERIAL ="LIST_ALL_CATEGORY__TYPE_MATERIAL"
export const LIST_TYPE_MATERIAL_BY_GROUP ="LIST_TYPE_MATERIAL_BY_GROUP"



export const EDIT_MATERIAL = "EDIT_MATERIAL"
export const EDIT_TYPE_MATERIAL = "EDIT_TYPE_MATERIAL"

export const OPEN_LIST_MATERIAL = "OPEN_LIST_MATERIAL"
export const OPEN_LIST = "OPEN_LIST"

export const ADD_TYPE_MATERIAL_SUCCESS = "ADD_TYPE_MATERIAL_SUCCESS"


export const OPEN_ALERT_FORM_ACTION_MATERAIL      = "OPEN_ALERT_FORM_ACTION_MATERAIL"
export const CLOSE_ALERT_FORM_ACTION_MATERAIL     = "CLOSE_ALERT_FORM_ACTION_MATERAIL"
export const OPEN_ALERT_DELETE_CATE_MATERIAL      = "OPEN_ALERT_DELETE_CATE_MATERIAL"
export const CLOSE_ALERT_DELETE_CATE_MATERIAL      = "CLOSE_ALERT_DELETE_CATE_MATERIAL"

export const OPEN_ALERT_DO_NOT_DELETE   = "OPEN_ALERT_DO_NOT_DELETE"
export const CLOSE_ALERT_DO_NOT_DELETE   = "CLOSE_ALERT_DO_NOT_DELETE"

export const OPEN_ALLOW_DELETE    = "OPEN_ALLOW_DELETE"
export const CLOSE_ALLOW_DELETE   = "CLOSE_ALLOW_DELETE"

export const DISPATCH_ITEM_DELETE_MATERIAL =  "DISPATCH_ITEM_DELETE_MATERIAL"
export const DISPATCH_ITEM_DELETE_TYPE_MATERIAL =  "DISPATCH_ITEM_DELETE_TYPE_MATERIAL"

export const OPEN_DELETE_TYPE_MATERIAL = "OPEN_DELETE_TYPE_MATERIAL"
export const CLOSE_DELETE_TYPE_MATERIAL = "CLOSE_DELETE_TYPE_MATERIAL"
