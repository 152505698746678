import _ from 'lodash'

export const UpdateListStudentNow =(arr,list)=>{
    let Arr = arr.map(item=>{
      let index = list.findIndex(value=>value._id===item._id)
      if(index!==-1){
        return null       
      }else{
        return{...item}
      }
    })
    let newArr = Arr.filter(v=>v!==null)
    let result = _.union(newArr,list)
    return result
}


export const UpdateListStudentClassByYearSchool =(arr,item)=>{  
   let index = arr.findIndex(value=>value._id===item.idStudent)
   let newArr = []
   if(index!==-1){
     newArr = [
       ...arr.slice(0,index),
       item={
            ...arr[index],
            fullName:item.fullName
       },      
      ...arr.slice(index+1)
     ]
   }else{
     newArr=[]
   }
   return newArr
}