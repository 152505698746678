import React ,{useState} from 'react';
import { UL_Header_ ,Header,HeaderMobile,A_Link, UL_Header, LI_header_block_second, UL_SubNav_Second, LI_header_Second, A_Link_Second,} from '../../../style/MainCss/navbar';
import LogoGDT from '../../../assest/img/huythanh.png'
import {Link} from 'react-scroll'
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {  SearchHeader_, SearchIconClass } from '../../../style/MainCss/search';
import { LI_header } from '../../../main/component/Responsive/HeaderNav';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { OPEN_CREATE_QRCODE, OPEN_PROCESS_BMI, OPEN_TUYEN_DUNG,OPEN_CREATE_WEBSITE } from '../../../contant';
import {useDispatch} from 'react-redux'


const Navbar = () => {
    const dispatch           = useDispatch()
    const [toogle,setToogle] = useState(false)

    const DOI_TAC =()=>{
            dispatch({
            type:OPEN_TUYEN_DUNG,
            payload:{item:{value:'DOI_TAC'}}                
        })
    }

    const THI_TRUONG =()=>{
        dispatch({
            type:OPEN_TUYEN_DUNG,
            payload:{item:{value:'THI_TRUONG'}}                
        })
    }

    const CHAM_SOC=()=>{
        dispatch({
            type:OPEN_TUYEN_DUNG,
            payload:{item:{value:'CHAM_SOC'}}                
        })
    }

    const CTV=()=>{
        dispatch({
            type:OPEN_TUYEN_DUNG,
            payload:{item:{value:'CTV'}}                
        })
    }

    const ProcessBMI =()=>{
       dispatch({type:OPEN_PROCESS_BMI})
    }

    const CreateQrcode =()=>{
        dispatch({type:OPEN_CREATE_QRCODE})
    }

    const CreateWeb=()=>{
        dispatch({type:OPEN_CREATE_WEBSITE})
    }

    return (
        <Header>
        {/* begin nav*/}
                <HeaderMobile>
                        <img style={{height:50,marginLeft:10,padding:5}} src={LogoGDT}/>
                </HeaderMobile>

                <UL_Header_ variant={toogle}>
                    {/* <LI_header >
                        <A_Link className="block">
                                <a target='_blank' style={{textDecoration:"none",color:'white'}} href="https://cdgdt.com" onClick={()=>setToogle(!toogle)} className="in-block" spy={true} smooth={true} offset={0} duration={500}>Trang Chủ</a>
                        </A_Link>                    
                    </LI_header> */}
            
                    <LI_header >
                        <A_Link className="block">
                                    <Link onClick={()=>setToogle(!toogle)} to="VIDEO" className="in-block" spy={true} smooth={true} offset={50} duration={1100}  >Video Hướng Dẫn</Link>
                        </A_Link>                                   
                    </LI_header>               

                    <LI_header >
                        <A_Link className="block">
                                    <Link onClick={()=>setToogle(!toogle)} to="CONTACT" className="in-block" spy={true} smooth={true} offset={50} duration={1200}  >Liên Hệ</Link>
                        </A_Link>                                   
                    </LI_header>

                    <LI_header >
                        <A_Link className="block">
                                <a style={{textDecoration:"none",color:'white',fontWeight:'bold'}} href="/login" className="in-block">Đăng Nhập</a>
                        </A_Link>                                   
                    </LI_header>     

                    <LI_header >
                        <A_Link className="block">
                                <a href="/thiet-bi-cong-nghe" style={{textDecoration:"none",color:'white',fontWeight:'bold'}} className="in-block">Công Nghệ</a>
                        </A_Link>                                   
                    </LI_header>        

                    <LI_header_Second>
                        <A_Link className="block">Tuyển Dụng
                    <i><ArrowDropDownIcon/></i> 
                        </A_Link>
                        <UL_SubNav_Second className='ShowBox'>                   
                            <LI_header_block_second onClick={()=>DOI_TAC()} >
                                <A_Link_Second className="block_second">Tìm Kiếm Đối Tác</A_Link_Second>
                            </LI_header_block_second>                    

                            <LI_header_block_second  onClick={()=>THI_TRUONG()} >
                            <A_Link_Second className="block_second" >Phát Triển Thị Trường</A_Link_Second>
                            </LI_header_block_second>

                            <LI_header_block_second  onClick={()=>CHAM_SOC()} >
                            <A_Link_Second className="block_second">Chăm Sóc Khách Hàng</A_Link_Second>
                            </LI_header_block_second>
                            <LI_header_block_second  onClick={()=>CTV()} >
                            <A_Link_Second className="block_second">Làm Cộng Tác Viên</A_Link_Second>
                            </LI_header_block_second>
                        </UL_SubNav_Second>
                    </LI_header_Second>

                    <LI_header_Second>
                        <A_Link className="block">TIỆN ÍCH
                    <i><ArrowDropDownIcon/></i> 
                        </A_Link>
                        <UL_SubNav_Second className='ShowBox'>      
                        <LI_header_block_second
                            onClick={()=>CreateWeb()}                    
                            ><A_Link_Second className="block_second" >Tạo WebSite</A_Link_Second>
                            </LI_header_block_second>       

                            <LI_header_block_second
                            onClick={()=>ProcessBMI()}                    
                            ><A_Link_Second className="block_second" >Tính BMI</A_Link_Second>
                            </LI_header_block_second>                    
                            <LI_header_block_second
                            onClick={()=>CreateQrcode()}    
                            >
                            <A_Link_Second className="block_second" >Tạo Mã QRCODE</A_Link_Second></LI_header_block_second>
                        </UL_SubNav_Second>
                    </LI_header_Second>

                    {/* <LI_header_Second>
                        <A_Link className="block">CHÚNG TÔI
                    <i><ArrowDropDownIcon/></i> 
                        </A_Link>
                        <UL_SubNav_Second className='ShowBox'>
                            <LI_header_block_second>
                            <A_Link_Second className="block_second" href='/cong-nghe'>Holding Teachnology</A_Link_Second></LI_header_block_second>
                            <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-tu-van'>Tư Vấn Đấu Thầu</A_Link_Second></LI_header_block_second>
                            <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-travel-2'>Lữ Hành Du Lịch</A_Link_Second></LI_header_block_second>
                            <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-soft-3'>Soft - Phần Mềm</A_Link_Second></LI_header_block_second>
                            <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-media'>Truyền Thông</A_Link_Second></LI_header_block_second>
                        </UL_SubNav_Second>
                    </LI_header_Second> */}
                    {/* end nav */}
                </UL_Header_>
                
                <UL_Header >
                    {/* <LI_header>
                        <A_Link className="block">
                                <a  style={{textDecoration:"none",color:'white'}} href="https://cdgdt.com" target='_blank'  className="in-block" 
                                >CỘNG ĐỒNG GĐT</a>
                        </A_Link>                    
                    </LI_header>            */}

                    <LI_header>
                        <A_Link className="block">
                                    <Link to="VIDEO" className="in-block" spy={true} smooth={true} offset={50} duration={1100}  >Video Hướng Dẫn</Link>
                        </A_Link>                                   
                    </LI_header>                  

                    <LI_header>
                        <A_Link className="block">
                                    <Link to="CONTACT" className="in-block" spy={true} smooth={true} offset={50} duration={1200}  >Liên Hệ</Link>
                        </A_Link>                                   
                    </LI_header>

                    <LI_header >
                        <A_Link className="block">
                                    <a style={{textDecoration:"none",color:'white',fontWeight:'bold'}} href="/login" className="in-block">Đăng Nhập</a>
                        </A_Link>                                   
                    </LI_header>

                    <LI_header >
                        <A_Link className="block">
                                <a href="/thiet-bi-cong-nghe" style={{textDecoration:"none",color:'white',fontWeight:'bold'}} className="in-block">Thiết Bị Công Nghệ</a>
                        </A_Link>                                   
                    </LI_header>       

                    <LI_header_Second>
                        <A_Link className="block">Tuyển Dụng
                    <i><ArrowDropDownIcon/></i> 
                        </A_Link>
                        <UL_SubNav_Second className='ShowBox'>                   
                            <LI_header_block_second onClick={()=>DOI_TAC()} >
                                <A_Link_Second className="block_second">Tìm Kiếm Đối Tác</A_Link_Second>
                            </LI_header_block_second>                    

                            <LI_header_block_second  onClick={()=>THI_TRUONG()} >
                            <A_Link_Second className="block_second" >Phát Triển Thị Trường</A_Link_Second>
                            </LI_header_block_second>

                            <LI_header_block_second  onClick={()=>CHAM_SOC()} >
                            <A_Link_Second className="block_second">Chăm Sóc Khách Hàng</A_Link_Second>
                            </LI_header_block_second>
                            <LI_header_block_second  onClick={()=>CTV()} >
                            <A_Link_Second className="block_second">Làm Cộng Tác Viên</A_Link_Second>
                            </LI_header_block_second>
                        </UL_SubNav_Second>
                    </LI_header_Second>

                    <LI_header_Second>
                        <A_Link className="block">TIỆN ÍCH
                    <i><ArrowDropDownIcon/></i> 
                        </A_Link>
                        <UL_SubNav_Second className='ShowBox'>        
                        <LI_header_block_second
                            onClick={()=>CreateWeb()}                    
                            ><A_Link_Second className="block_second" >Tạo WebSite</A_Link_Second>
                            </LI_header_block_second>                  
                            <LI_header_block_second
                            onClick={()=>ProcessBMI()}                    
                            ><A_Link_Second className="block_second" >Tính BMI</A_Link_Second>
                            </LI_header_block_second>                    
                            <LI_header_block_second
                            onClick={()=>CreateQrcode()}    
                            >
                            <A_Link_Second className="block_second" >Tạo Mã QRCODE</A_Link_Second></LI_header_block_second>
                        </UL_SubNav_Second>
                    </LI_header_Second>

                    {/* <LI_header_Second>
                        <A_Link className="block">CHÚNG TÔI
                    <i><ArrowDropDownIcon/></i> 
                        </A_Link>
                        <UL_SubNav_Second className='ShowBox'>
                            <LI_header_block_second>
                            <A_Link_Second className="block_second" href='/cong-nghe'>Holding Technology</A_Link_Second></LI_header_block_second>
                            <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-tu-van'>Tư Vấn Đấu Thầu</A_Link_Second></LI_header_block_second>
                            <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-travel-2'>Lữ Hành Du Lịch</A_Link_Second></LI_header_block_second>
                            <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-soft-3'>Soft - Phần Mềm</A_Link_Second></LI_header_block_second>
                            <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-media'>Truyền Thông</A_Link_Second></LI_header_block_second>
                        </UL_SubNav_Second>
                    </LI_header_Second> */}
                    {/* end nav */}
                </UL_Header>               

            <SearchHeader_ onClick={()=>setToogle(!toogle)}>
                <SearchIconClass >
                    {toogle?<CloseIcon/>:<MenuIcon/>}                    
                </SearchIconClass>
            </SearchHeader_>
        {/* end search */}
       </Header>
    );
};

export default Navbar;
