import axiosServices from '../api'
import {API_ENDPOINT} from '../../contant/index'


const urlAddRepository              = 'repository/add'
const urlListRepository             = 'repository/get'
const urlEditRepository             = 'repository/update'
const urlDeleteRepository           = 'repository/delete'
const urlGetAllYearSchoolRepository = "repository/allYearschoolRepository"




export const AddRepository = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddRepository}`,item)
}
export const ListRepository = (id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListRepository}`,id)
}
export const EditRepository = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlEditRepository}`,item)
}
export const DeleteRepository = (item)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteRepository}`,item)
}
export const GetAllYearSchoolRepository =(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetAllYearSchoolRepository}`,item)
}
