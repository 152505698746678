import _ from 'lodash'
import {showDateName} from '../../../../helper/dateTime/handleDMY'
const Sta = 'thứ bảy'

export const HandleNewResultCheckDayFood=(ListClass,ListTmp,date)=>{  
  if(ListTmp.length===0) return ListClass
  return NewArrResult(ListClass,ListTmp,date)
}


const NewArrResult=(ListClass,ArrListClass,date)=>{ 
//console.log(ListClass)
  let NewListClass = ListClass.filter(v=>v!==undefined)
  return NewListClass.map(v=>{
      let index = ArrListClass.findIndex(vv=>vv.idClass===v.idClass)
      if(index!==-1){      
        return{
            ...v,
            numCheckDay:showDateName(date)!==Sta?v.numCheckDay - HandleNumCheckDay(ArrListClass[index].students):v.numCheckDay,
        }
      }
      else{
        return{...v}
     }
  })
}
const HandleNumCheckDay=(item)=>{
    if(item.length===0)return 0
    return (item.filter(v=>v.active===true)).length
}

export const AllResultCheckDayFood =(arr,ListTmp)=>{
  if(ListTmp.length===0) return arr
  return arr.map(v=>{
     return NewArrResult(v,ListTmp,v[0].date)
  })
}

