import {put,call, takeLatest} from 'redux-saga/effects'
import {ADD_CREATE_ACCOUNT_STAFF, CHECK_EXIST_NAME_LOGIN,GET_LIST_MANAGER_STAFF,
UPDATE_ITEM_INFO_STAFF_ACTIVE_DEACTIVE,ADD_PAYMENT_STAFF, GET_LIST_PAYMENT_STAFF, UPDATE_PAYMENT_STAFF, CHECK_EXIST_NAME_LOGIN_EDIT, EDIT_ACCOUNT_STAFF,
ADD_LOCATION,
GET_LOCATON,
GET_CHAM_CONG_BY_USER_ID,ADD_AND_GET_LIST_CHAM_CONG,UPDATE_ITEM_LIST_CHAM_CONG, ADD_TASK_JOB, GET_LIST_TASK_JOB,DISPATCH_APRROVE_TASK_JOB, DISPATCH_APRROVE_TASK_JOB_CONG_TAC_PHI,DISPATCH_ADD_PAYMENT_MATERIAL_UPDATE_TASK_JOB, UPDATE_LIST_TASK_JOB
} from '../contant'
import {CreateAccountManagerStaff,CheckExistNameLoginApi,AddStaffApi,GetUserIdByIdGroup_8Api,GetListStaffInfo,ActiveDeactiveUserApi, AddPaymentStaffApi, GetListPaymentStaffApi,UpdatePaymentStaffApi,CheckExistNameLoginEditApi, EditAccountManagerStaffApt, EditAccountLoginApi,
AddLocationApi,GetLocationApi,GetChamCongByUserIdApi,AddAndGetListChamCongApi, UpdateItemListChamCongApi, AddTaskJobApi,  GetTaskJobsApi, ApproveTaskJobApi, AddPaymentUpdateTaskJobApi
} from  '../api'
import {DispatchExistNameLoginToStore,DispatchAddStaffToStore,DispatchGetListInfoStaff,DispatchAddPaymentStaffToStore,DispatchGetListPaymentStaffToStore, DispatchItemEditAccountStaffToStore,
DispatchAddLocationToStore,DispatchGetListLocation,DispatchGetChamCongByUserIdToStore, DispatchAddTaskJobToStore, DispatchTaskJobsToStore, UpdateTaskJobPayment, ListPricePayment, UpdateListPricePayment
} from '../action'
import {handleArrListStaffInfo,ItemStaff,ConverItemToUpdateEditStaff, HandleTaskJobInfoDetailForCustomerAfter} from './Fn'
 import { OpenAlertSuccess,CloseAlertSuccess,
      } from '../../../../Actions/helper'
import {loadingSuccess,loadingFail} from '../../../../../actions/uiLoading'

const FAIL ='fail'
const SUCCESS = 'success'

function* AddCreateAccountStaffSaga({payload}){   
    yield put(loadingSuccess())
    let {item} = payload
    let itemCreateAccount = {
        name:item.nameLogin,
        idGroup:8,
        password:item.pass
    }
    let res =yield call(CreateAccountManagerStaff,itemCreateAccount)
    if(res.data.result===SUCCESS){
        yield put(loadingFail())
        let AddStaff={
            userId:item.userId,
            idStaff:res.data.resultData._id,
            infoStaff:{
                fullName:item.nameStaff,
                typeOf:item.level // chuc vu
            },
            department:{
                name:item.department
            }
        }        
        yield put(loadingSuccess())
        let res2=yield call(AddStaffApi,AddStaff)     
        if(res2.data.result===SUCCESS){
            yield put(loadingFail())
            yield put(DispatchAddStaffToStore(ItemStaff(res.data.resultData,res2.data.data)))
        }
    }
    if(res.data.result===FAIL){
        yield loadingFail()
        yield put(DispatchExistNameLoginToStore(true))
    }    
 }

function* CheckExistNameLoginSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(CheckExistNameLoginApi,payload.item)
    if(res.data.result===SUCCESS){
            yield put(loadingFail())
            yield put(DispatchExistNameLoginToStore(res.data.data))
    }
}

function* GetListManagerStaffSaga({payload}){
    //yield put(loadingSuccess())
    let res = yield call(GetUserIdByIdGroup_8Api)
    if(res.data.result===SUCCESS){
       // yield put(loadingFail())
        let arr = res.data.data.map(v=>{
            return {
                idStaff:v._id,
                userId:payload.item.userId
            }       
        })
        let res2 = yield call(GetListStaffInfo,arr)     
        let result = res2.data.data.filter(v=>v!==null)
        let item = {arrUser:res.data.data,arrStaff:result,ListStaffInfo:handleArrListStaffInfo(res.data.data,result)}                
        //console.log(item)
        yield put(DispatchGetListInfoStaff(item)) 
    }
}

function* UpdateItemInfoStaffActiveDeactiveSaga({payload}){  
    yield put(loadingSuccess())
    let resultUserLogin = {
        _id:payload.item.idStaff,
        idGroup:payload.item.status?88:8,
    }
   let res = yield call(ActiveDeactiveUserApi,resultUserLogin)
   if(res.data.result===SUCCESS){       
        yield put(loadingFail())
        if(payload.item.status===true){
            yield put(OpenAlertSuccess(`Kích hoạt tài khoản --- ${payload.item.nameLogin} --- thành công`))
        }
        if(payload.item.status===false){
            yield put(CloseAlertSuccess(`Vô hiệu hóa tài khoản --- ${payload.item.nameLogin} --- thành công`))
       }
   }else{
       alert('UpdateItemInfoStaffActiveDeactiveSaga')
   }
}

function* AddPaymentStaffSaga({payload}){
   yield put(loadingSuccess())
   let res = yield call(AddPaymentStaffApi,payload.item)
   if(res.data.result===SUCCESS){
        yield put(loadingFail())
        yield put(OpenAlertSuccess('Lưu dữ liệu thành công'))
        yield put(DispatchAddPaymentStaffToStore(res.data.data))
   }
}

function* GetListPaymentStaffSaga({payload}){
    //console.log(payload)
       // yield put(loadingSuccess())
        let res = yield call(GetListPaymentStaffApi,payload.item)
        if(res.data.result===SUCCESS){
    //console.log(res.data.data)
        //    yield put(loadingFail())
            yield put(DispatchGetListPaymentStaffToStore(res.data.data))
        }
}

function* UpdatePaymentStaffSaga({payload}){
        yield put(loadingSuccess())
        let res = yield call(UpdatePaymentStaffApi,payload.item)
        if(res.data.result===SUCCESS){
            yield put(loadingFail())
            let res2 = yield call(GetListPaymentStaffApi,payload.item)
            yield put(DispatchGetListPaymentStaffToStore(res2.data.data))
            yield put(OpenAlertSuccess('Cập nhật thành công'))
        }
}

function* CheckExistNameLoginEditSaga({payload}){
        yield put(loadingSuccess())
        let res = yield call(CheckExistNameLoginEditApi,payload.item)
        if(res.data.result===SUCCESS){
            yield put(loadingFail())
            yield put(DispatchExistNameLoginToStore(res.data.data))
        }
}

function* EditAccountStaffSaga({payload}){
        yield put(loadingSuccess())
        let{item,ItemEditStaff} = payload.Item          
        let EditStaff={
            _id:item._id,
            userId:item.userId,
            idStaff:item.idStaff,
            infoStaff:{
                fullName:item.nameStaff.toLowerCase(),
                typeOf:item.level // chuc vu
            },
            department:{
                name:item.department
            }
        }
       let res = yield call(EditAccountManagerStaffApt,EditStaff)
       if(res.data.result===SUCCESS){
           yield put(loadingFail())
           yield put(DispatchItemEditAccountStaffToStore(ConverItemToUpdateEditStaff(item)))                    
            if(item.nameLogin!==ItemEditStaff.nameLogin){
                let itemUserLogin ={
                    _id:item.idStaff,
                    name:item.nameLogin,
                    password:item.pass_id
                }
            yield put(loadingSuccess())            
            let res2 = yield call(EditAccountLoginApi,itemUserLogin)       
            if(res2.data.result===SUCCESS){
                yield put(loadingFail())
            }
            }
            yield put(OpenAlertSuccess('Cập nhật thành công'))
       }
        
}


function* AddLocationSaga({payload}){
        yield put(loadingSuccess())        
        let res  = yield call(AddLocationApi,payload.item)

        if(res.data.result==='fail'){
            yield put(loadingFail())
            yield put(CloseAlertSuccess("Vi trí chưa xác định, vui lòng thử lại"))
        }
        if(res.data.result===SUCCESS){
            yield put(loadingFail())
            yield put(DispatchAddLocationToStore(res.data.data))
        }
}

function* GetLocationSaga({payload}){
    yield put(loadingSuccess())        
        let res = yield call(GetLocationApi,payload.item)
        if(res.data.result===SUCCESS){
              yield put(DispatchGetListLocation(res.data.data))
              yield put(loadingFail())
        }
}

function* GetChamCongByUserIdSaga({payload}){ 
    yield put(loadingSuccess())     
    let res = yield call(GetChamCongByUserIdApi,payload.item)   
    if(res.data.result===SUCCESS){
           yield put(loadingFail())
            yield put(DispatchGetChamCongByUserIdToStore(res.data.data))
    }
}

function* AddAndGetListChamCongSaga({payload}){
    yield put(loadingSuccess())    
    let res = yield call(AddAndGetListChamCongApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(loadingFail())
        yield put(DispatchGetChamCongByUserIdToStore(res.data.data))
    }
}

function* UpdateItemListChamCongSaga({payload}){
        yield put(loadingSuccess())  
        let res = yield call(UpdateItemListChamCongApi,payload.item)
        if(res.data.result===SUCCESS){
            yield put(loadingFail())
            yield put(DispatchGetChamCongByUserIdToStore(res.data.data))
        }
}

function* AddTaskJobSaga({payload}){
    yield put(loadingSuccess())  
    let res = yield call(AddTaskJobApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(loadingFail())
        yield put(DispatchAddTaskJobToStore(res.data.data))
    }
}


function* GetListTaskJobSaga({payload}){
    //yield put(loadingSuccess())  
     let res = yield call(GetTaskJobsApi,payload.item)
    if(res.data.result===SUCCESS){
        let priceTaskJob = HandleTaskJobInfoDetailForCustomerAfter(res.data.data)
       // yield put(loadingFail())
        yield put(ListPricePayment(priceTaskJob))
        yield put(DispatchTaskJobsToStore(res.data.data))
    }
}

function* DispatchApproveTaskJobSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(ApproveTaskJobApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(loadingFail())
   }
}

function* DispatchApproveTaskJobCongTacPhiSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(ApproveTaskJobApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(loadingFail())
   }
}

function* DispatchAddPaymentMaterialUpdateTaskJobSaga({payload}){
    yield put(loadingSuccess())
    let res  =   yield call(AddPaymentUpdateTaskJobApi,payload.item)    
    //let res2 =   yield call(GetTaskJobsApi,payload.item)
    //let priceTaskJob = HandleTaskJobInfoDetailForCustomerAfter(res2.data.data)
   if(res.data.result===SUCCESS){
    yield put(loadingFail())
    //yield put(UpdateListPricePayment(priceTaskJob))
    yield put(UpdateTaskJobPayment(res.data.data))
    }    
}

function* UpdateListTaskJobSaga({payload}){
    yield put(loadingSuccess())
    let res = yield call(ApproveTaskJobApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(loadingFail())
   }
}




function* ManagerStaffSaga(){
    yield takeLatest(ADD_CREATE_ACCOUNT_STAFF,AddCreateAccountStaffSaga)
    yield takeLatest(CHECK_EXIST_NAME_LOGIN,CheckExistNameLoginSaga)
    yield takeLatest(GET_LIST_MANAGER_STAFF,GetListManagerStaffSaga)
    yield takeLatest(UPDATE_ITEM_INFO_STAFF_ACTIVE_DEACTIVE,UpdateItemInfoStaffActiveDeactiveSaga)
    yield takeLatest(ADD_PAYMENT_STAFF,AddPaymentStaffSaga)
    yield takeLatest(GET_LIST_PAYMENT_STAFF,GetListPaymentStaffSaga)
    yield takeLatest(UPDATE_PAYMENT_STAFF,UpdatePaymentStaffSaga)
    yield takeLatest(CHECK_EXIST_NAME_LOGIN_EDIT,CheckExistNameLoginEditSaga)
    yield takeLatest(EDIT_ACCOUNT_STAFF,EditAccountStaffSaga)
    yield takeLatest(ADD_LOCATION,AddLocationSaga)
    yield takeLatest(GET_LOCATON,GetLocationSaga)
    yield takeLatest(GET_CHAM_CONG_BY_USER_ID,GetChamCongByUserIdSaga)
    yield takeLatest(ADD_AND_GET_LIST_CHAM_CONG,AddAndGetListChamCongSaga)
    yield takeLatest(UPDATE_ITEM_LIST_CHAM_CONG,UpdateItemListChamCongSaga)
    yield takeLatest(ADD_TASK_JOB,AddTaskJobSaga)
    yield takeLatest(GET_LIST_TASK_JOB,GetListTaskJobSaga)
    yield takeLatest(DISPATCH_APRROVE_TASK_JOB,DispatchApproveTaskJobSaga)
    yield takeLatest(DISPATCH_APRROVE_TASK_JOB_CONG_TAC_PHI,DispatchApproveTaskJobCongTacPhiSaga)
    yield takeLatest(DISPATCH_ADD_PAYMENT_MATERIAL_UPDATE_TASK_JOB,DispatchAddPaymentMaterialUpdateTaskJobSaga)
    yield takeLatest(UPDATE_LIST_TASK_JOB,UpdateListTaskJobSaga)
    
}

export default ManagerStaffSaga