import { toastError, toastSuccess } from "../../../../helper/toastHelper"
import { DISPATCH_RESULT_WEB_AUTO_TO_STORE,DISPATCH_INFO_USER_DETAIL_WEB ,SEND_REGIS_STUDENT_WEB_SUCCESS, SEND_REGIS_STUDENT_WEB_SUCCESS_FAIL, DISPATCH_REGIS_LOPHOC_MAM_NON,
    OPEN_VIEW_IMAGE_SLIDE,CLOSE_VIEW_IMAGE_SLIDE} from "../contant"

let initialState={
    ItemInfoWeb:null,
    InfoUserDetailWeb:null,
    ValueRegis:null,
    ValueSlideImg:null,
    OpenSlideImg:false
}

const IndexReducer = (state=initialState,action)=>{
    switch (action.type) {
        case CLOSE_VIEW_IMAGE_SLIDE:
            return{
                ...state,
                OpenSlideImg:false,
                ValueSlideImg:null
            }
        case OPEN_VIEW_IMAGE_SLIDE:
            console.log(action.payload.item)
            return{
                ...state,
                OpenSlideImg:true,
                ValueSlideImg:action.payload.item
            }
        case DISPATCH_REGIS_LOPHOC_MAM_NON:
            return{
                ...state,
                ValueRegis:action.payload.item
            }
        case SEND_REGIS_STUDENT_WEB_SUCCESS_FAIL:
            toastError('Tên và số điện thoại này đã được gửi, kiểm tra lại')
            return{
                ...state
            }

        case SEND_REGIS_STUDENT_WEB_SUCCESS:
            toastSuccess('Gửi Thông Tin Thành Công')
            return{
                ...state
            }
        case DISPATCH_INFO_USER_DETAIL_WEB:
            return{
                ...state,
                InfoUserDetailWeb:action.payload.item
            }
        case DISPATCH_RESULT_WEB_AUTO_TO_STORE:
            return {
                ...state,
                ItemInfoWeb:action.payload.item
            }                       
        default: return state            
    }
}

export default IndexReducer