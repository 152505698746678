import React,{useState} from 'react';
import {Grid,List,Card,CardHeader,ListItem,ListItemText,ListItemIcon,Checkbox,Button,Divider} from '@material-ui/core'
import {useStyles} from './styles'
import {FastForward,ArrowBack} from '@material-ui/icons'
import {useSelector} from 'react-redux'
import SelectPrice from '../SelectPrice/index'
import {FilterDupplicateItem} from '../../../../../helper/GovLogin/index'


function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const Index=({value,HandleCloseSelectCost})=> {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const ListCost = useSelector(state=>state.CostStudent.listCostStudent)
  let newArr = FilterDupplicateItem(ListCost)  
  //console.log(ListCost)
  let ResultArr1 = []
  const [left, setLeft] = useState(newArr);
  const [right, setRight] =useState(ResultArr1);
  const [err,setErr] = useState('')
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [resultValue,setResultValue] = useState('')
  const [OpenSelectPrice,SetOpenSelectPrice] = useState(false)

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'chọn tất cả' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} `}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={` ${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
  const HandleClickNext=()=>{  
        let Arr =[]
        ListCost.map(v=>{right.map(i=>{
            if(i===v.name){
                Arr.push(v)                
            }
          })
        })              
        let item ={Arr,value}
        setResultValue(item)
        SetOpenSelectPrice(true)
  }
  const HandleReturn=()=>{
    SetOpenSelectPrice(false)
  }
  const HandleReturnOne=()=>{
    HandleCloseSelectCost()
  }

  return (
    <>
    {OpenSelectPrice&&(<SelectPrice
        Item={resultValue}
        Return={HandleReturn}
    />)}

    {!OpenSelectPrice&&(
           <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
           <Grid item>{customList('Chọn', left)}</Grid>
           <Grid item>
             <Grid container direction="column" alignItems="center">
               <Button
                 variant="outlined"
                 size="small"
                 className={classes.button}
                 onClick={handleCheckedRight}
                 disabled={leftChecked.length === 0}
                 aria-label="move selected right"
               >
                 &gt;
               </Button>
               <Button
                 variant="outlined"
                 size="small"
                 className={classes.button}
                 onClick={handleCheckedLeft}
                 disabled={rightChecked.length === 0}
                 aria-label="move selected left"
               >
                 &lt;
               </Button>
             </Grid>
           </Grid>
           <Grid item>{customList('Đã Chọn', right)}</Grid>
           <Grid>
           <Button color="secondary" size="large" onClick={()=>HandleReturnOne()}>
                <ArrowBack/>
             </Button>
             {right.length!==0?(
                <Button color="primary" size="large" onClick={()=>HandleClickNext()}>
                 <FastForward/>
             </Button>
             ):""}            
           </Grid>
         </Grid>
         )} 
    </>
  );
}

export default React.memo(Index)