import React, { useEffect, useState } from 'react';
import {  Grid, Typography } from '@material-ui/core';
import huythanhImg from '../../../assest/img/LogoCom/carosel/huythanhicare.png'
import { NameSoftH3 } from './style';
import { Banner, MainAbout, MainRibon,DivImg, CupImg, CupDiv, MainPic, BannerMobile, PhaoHoa, PhaoHoa2, PhaoHoa3, IMG_ASEAN, DivCup, IMG_CUP_ASEAN, DivNumLeft, DivNumRight, NumLeft, NumLeftCha, NumRight, NumRightCha } from './styleRibbon';
import CupSei from './Cup.png'
import Asean2024 from './ASEAN_2024.png'
import CupAsean2024 from './CUP_ASEAN.png'
import SeiAwardComponent from './DialogOpenSeiAward'
import { ColorAboutUs, TextColor } from './color';
import TextSlideComponent from './TextOneSlide' 
//import AccountCircleIcon from '@material-ui/icons/AccountCircle';
//import AccountBoxIcon from '@material-ui/icons/AccountBox';


const AboutUs = React.memo(() => {
    const [openSeiAward,setOpenSeiAward] = useState(false)
    const ViewSeiAward=()=>{
        setOpenSeiAward(true)
    }    
    const [countUser,setCountUser] = useState(0)
    const [countFree,setCountFree] = useState(0)
   

    const RunCount = (value)=>{     
            let Counter = 0
            let interval = setInterval(()=>{
                    Counter = Counter + 1
                    setCountFree(prvcount=>prvcount+1)
                    if(Counter===value){
                        clearInterval(interval)
                    }
            },0.001)  
            return ()=>clearInterval()
    }
    const RunCount_ = (value)=>{     
        let Counter = 0
        let interval = setInterval(()=>{
                Counter = Counter + 1
                setCountUser(prvcount=>prvcount+1)
                if(Counter===value){
                    clearInterval(interval)
                }
        },0.002)  
        return ()=>clearInterval()
    }
    useEffect(()=>{
            RunCount(1000)
            RunCount_(500)                         
    },[]) 
 
    return (
        <>
            {openSeiAward&&<SeiAwardComponent
                open={openSeiAward}
                handleClose ={()=>setOpenSeiAward(false)}
            />}
            <TextSlideComponent/>
             <MainAbout>
                <Grid id="VE_CHUNG_TOI" container spacing="2" >
                                <Grid item sm="6">
                                    <div style={{width:'80%',margin:'auto'}}>
                                        <Typography component="h2" style={{color:`${ColorAboutUs}`,lineHeight:2,fontSize:20,fontWeight:'bolder'}}>ABOUT US</Typography>
                                        <NameSoftH3 >Phần Mềm Dinh Dưỡng KidTop</NameSoftH3>
                                        <Typography component="h3" style={{color:`${TextColor}`}}>
                                        Chúng tôi hân hạnh giới thiệu đến quý khách Phần mềm dinh dưỡng chuyên nghiệp KIDTOP.
                                        </Typography>
                                        <Typography component="h4" style={{color:`${TextColor}`}}>
                                            KidTop là sự kết hợp giữa 5 tính năng quan trọng: Nutrikids(Tính khẩu phần ăn cho mọi đối tượng), Kiểm thực 3 bước ,Quản lý thu chi, Cân Đo tự động, và Tạo Website tự động, giúp cho việc quản lý dinh dưỡng, kiểm tra thực phẩm và quản lý tài chính, trở nên đơn giản và hiệu quả hơn bao giờ hết. 
                                        </Typography>
                                    </div>                    
                                </Grid>
                                <Grid item sm="6">                                    
                                    <MainPic>
                                        <DivImg src={huythanhImg} alt="phan-mem-dinh-duong"/>                                    
                                    <CupDiv style={{cursor:'pointer'}} onClick={()=>ViewSeiAward()}>
                                        <CupImg src={CupSei} alt="phan-mem-dinh-duong"/>
                                    </CupDiv>
                                  </MainPic>
                                </Grid>
                                    <MainRibon>
                                        <Banner style={{cursor:'pointer'}} onClick={()=>ViewSeiAward()}>
                                           SEI Awards Edutech 2023               
                                        </Banner>
                                        <div style={{display:'flex',justifyContent:'spaceBetween'}}>
                                            <DivNumLeft>
                                                    <NumLeft>+{countFree}</NumLeft>
                                                    <NumLeftCha>ACC</NumLeftCha>
                                            </DivNumLeft>
                                            <DivCup>                                              
                                                    <IMG_ASEAN src={Asean2024}/>
                                                    <IMG_CUP_ASEAN src={CupAsean2024}/>
                                            </DivCup>
                                            <DivNumRight>
                                                    <NumRight>+{countUser}</NumRight>
                                                    <NumRightCha>USER</NumRightCha>
                                            </DivNumRight>
                                        </div>                                        
                                        <BannerMobile style={{cursor:'pointer'}} onClick={()=>ViewSeiAward()}>
                                           SEI Awards Edutech 2023    
                                        </BannerMobile>
                                    </MainRibon>                       
                </Grid>
                 {/* <PhaoHoa></PhaoHoa>
                 <PhaoHoa2></PhaoHoa2> 
                 <PhaoHoa3></PhaoHoa3> */}
            </MainAbout>
        </>    
    );
});

export default AboutUs;