const style = ()=>({
  loading:{
          position:"fixed",
          left:0,
          right:0,
          top:0,
          bottom:0,
          zIndex:1000,
          background:"rgba(0,0,0,0.4)"
  },
  loadingImg:{
    position:"fixed",
    marginLeft:"auto",
    marginRight:"auto",
    top:"30%",
    left:0,
    right:0,
    width:250
  }
})

export default style