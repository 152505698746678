import styled,{css,keyframes} from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme)=>({
    root: {
      minWidth: 275,
      background:'#e67e22',
      // backgroundImage:'linear-gradient(0,#4cb2b6,#27ae60)',
      marginTop:10,
      marginBottom:10,
      //padding: 10,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    
}));

  const  SlideIn = keyframes`
    0%{
        opacity: 0;
    }
   
     60%{
        opacity:0.5
    }
   
    100%{
        opacity:1;
    }
`

const HideSlideIn=keyframes`
  0%{
    opacity:1;
    scale:100%;
  }
  60%{
    opacity:0.5;
    scale:50%;
  }
  100%{
    opacity:0;
    scale:0%;
  }    
`

export const gradient = keyframes`
    100% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    0% {
        background-position: 0% 0%;
    }
`

export const MainContent = styled.div`
   
`

export const DangKyDiv = styled.div`        
      z-index:1000;
       ${(props)=>props.variant===true?
       css`
       animation:${HideSlideIn} 2s 1 linear;     
       visibility: hidden;
       `:
       css`
       animation:${SlideIn} 2s 1 linear;
       visibility: visible;
       `
       }
       
`

export const ButtonStyleHide=styled.button`
display: inline-block;
width: 100%;
height: 40px;
border-radius:6px;
background-color: #e74c3c;
color:${(props)=>props.variant==='outline'?'#fff':'#dcdde1'};
    background-color:${(props)=>props.variant==='outline'?
        '#e74c3c':'#c0392b'
    } ;
text-align:center;
font-size: 15px;
cursor: pointer;
border: none;
font-weight: bold;

transition: 0.2s all ease-in-out;
&:hover{
        color:${(props)=>props.variant!=='outline'?'#7f8c8d':'#dcdde1'};
        background-color:${(props)=>props.variant!=='outline'?
            '#e74c3c':'#c0392b'
         } ;
    }
`

  export const ButtonStyle=styled.button`
    display: inline-block;
    width: 100%;
    height: 40px;
    border-radius:6px;
    background-color: #1e88e5;
    color:${(props)=>props.variant==='outline'?'#fff':'#dcdde1'};
        background-color:${(props)=>props.variant==='outline'?
            '#1e88e5':'#0097e6'
        } ;
    text-align:center;
    font-size: 15px;
    cursor: pointer;
    border: none;
    font-weight: bold;

    transition: 0.2s all ease-in-out;
    &:hover{
            color:${(props)=>props.variant!=='outline'?'#4caf50':'#dcdde1'};
            background-color:${(props)=>props.variant!=='outline'?
                    '#1e88e5':'#0097e6'
             };
        }
  `

