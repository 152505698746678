export const managerRepo = "quản lý kho"
export const managerStaff= "quản lý nhân viên"
export const managerSell = "quản lý bán hàng"
export const managerDiff = "quản lý khác"
export const AlertDeActiveManagerRepo = "Chương trình quản lý kho chưa được kích hoạt, liên hệ với quản trị"
export const AlertDeActiveManagerStaff = "Chương trình quản lý nhân viên chưa được kích hoạt, liên hệ với quản trị"
export const AlertDeActiveManagerSell = "Chương trình quản lý bán hàng chưa được kích hoạt, liên hệ với quản trị"

export const BtnExit          = "Thoát"
export const TypeOfService = [
  {
    type:"A",
    name:'mua bán, sửa chữa'
  },
  {
    type:"B",
    name:"dịch vụ ăn uống"
  },
  {
    type:"C",
    name:"dịch vụ khác"
  }
]

export const TyepOfApp =[
  {
    type:"AA",
    name:"quản lý kho"
  },
  {
    type:"BB",
    name:"quản lý nhân viên"
  },
  {
    type:"CC",
    name:"quản lý bán hàng"
  },  
  {
    type:"CC",
    name:"quản lý khác"
  } 
]

export const SELECT_NAME_TO_PAY_TASK =[  //ten nay se duoc tao ra de tim trong danh muc kho lam ten nhap vao noi dung cong viec nhan vien, lam dieu kien thanh toan
    {name:'hhdv'},
   {name:'sửa chữa cntt'},
   {name:'sửa chữa điện, nước'},  
   {name:'bảo hành'}, 
]

export const NAME_PAY_TASK = [
  'cung cấp hàng hoá dịch vụ',
  'bảo trì',
  'bảo hành',
  'dịch vụ sửa chữa'
]

export const SELECT_TYPE_OF_PAY_TASK = [
  {name:'hhdv'},
  {name:'bảo trì'},
  {name:'bảo hành'},
  {name:'dịch vụ sửa chữa'}
]

export const SELECT_NAME_OF_PAY_CUS =[
  {name:'miễn phí'},
  {name:'trả phí'}
]

export const SELECT_INSURANCE =[
  {name:'12 tháng'},
  {name:'24 tháng'},
  {name:'36 tháng'},
  {name:'48 tháng'}
]

export const WEB_PD = 'https://phatdatcomputer.com.vn'