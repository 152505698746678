import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Route} from 'react-router-dom'


function mapStateToProps(state) {
  return {

  };
}

class index extends Component { 
  render() {
    let {component:YourComponent,...remainProps} = this.props
    return (
     <Route
          {...remainProps}
          render={routeProps=>{
            return(
                  <YourComponent {...routeProps}/>
            )
          }}
     />
    )
  }
}

export default connect(
  mapStateToProps,
)(index);