import React,{useState} from 'react';
import { UL_Header,Header,A_Link, HeaderMobile, UL_Header_, LI_header_Second, UL_SubNav_Second, LI_header_block_second, A_Link_Second,} from '../../style/MainCss/navbar';
import { SearchHeader,SearchHeader_,SearchIconClass } from '../../style/MainCss/search';
import SearchIcon from '@material-ui/icons/Search';
import {Link} from 'react-scroll'
import { LI_header } from './Responsive/HeaderNav';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import LogoGDT from '../../assest/img/gdt.jpg'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


const HeaderComponent = () => {
    const [toogle,setToogle] = useState(false)
    return (
    <Header>
        {/* begin nav*/}
        <HeaderMobile>
                <img style={{height:50,marginLeft:10,padding:5}} src={LogoGDT}/>
        </HeaderMobile>

        <UL_Header_ variant={toogle}>
             <LI_header >
                <A_Link className="block">
                        <Link onClick={()=>setToogle(!toogle)} to="TRANG_CHU" className="in-block" spy={true} smooth={true} offset={0} duration={500}  >Trang Chủ</Link>
                </A_Link>                    
            </LI_header>

            {/* <LI_header >
                   <A_Link className="block">
                      <Link onClick={()=>setToogle(!toogle)} to="VE_CHUNG_TOI" className="in-block" spy={true} smooth={true} offset={50} duration={500}  >Về Chúng Tôi</Link> 
                  </A_Link>  
           </LI_header> */}

           {/* <LI_header >
                   <A_Link className="block">
                      <Link onClick={()=>setToogle(!toogle)} to="TON_CHI" className="in-block" spy={true} smooth={true} offset={50} duration={800}>TÔN CHỈ</Link> 
                  </A_Link>  
           </LI_header> */}

            <LI_header >
                <A_Link className="block">
                            <Link onClick={()=>setToogle(!toogle)} to="THANH_VIEN" className="in-block" spy={true} smooth={true} offset={50} duration={900}  >Thành Viên</Link>
                </A_Link>
            </LI_header>  

            <LI_header >
                <A_Link className="block">
                            <Link onClick={()=>setToogle(!toogle)} to="SEO_LEADERS" className="in-block" spy={true} smooth={true} offset={50} duration={1100}  >CEO LEADERS</Link>
                </A_Link>                                   
            </LI_header>

            <LI_header >
                <A_Link className="block">
                            <Link onClick={()=>setToogle(!toogle)} to="CONTACT" className="in-block" spy={true} smooth={true} offset={50} duration={1200}  >Liên Hệ</Link>
                </A_Link>                                   
            </LI_header>

            <LI_header >
                <A_Link className="block">
                           <a style={{textDecoration:"none",color:'white',fontWeight:'bold'}} href="/login" className="in-block">Đăng Nhập</a>
                </A_Link>                                   
            </LI_header>

            <LI_header >
                <A_Link className="block">
                           <a style={{textDecoration:"none",color:'white',fontWeight:'bold'}} href="/" className="in-block">Phần Mềm KidTop</a>
                </A_Link>                                   
            </LI_header>

            <LI_header_Second>
                <A_Link className="block">CHÚNG TÔI
               <i><ArrowDropDownIcon/></i> 
                </A_Link>
                <UL_SubNav_Second className='ShowBox'>
                    <LI_header_block_second><A_Link_Second className="block_second" href='/cong-nghe'>Công Nghệ</A_Link_Second></LI_header_block_second>
                    <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-tu-van/'>Tư Vấn Đấu Thầu</A_Link_Second></LI_header_block_second>
                    <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-travel-2/'>Lữ Hành Du Lịch</A_Link_Second></LI_header_block_second>
                    <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-soft-3/'>Soft - Phần Mềm</A_Link_Second></LI_header_block_second>
                    <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-media/'>Truyền Thông</A_Link_Second></LI_header_block_second>
                    <LI_header_block_second><A_Link_Second className="block_second" href='https://noithatthuangia.vn/'>Thiết Kế Nội Thất</A_Link_Second></LI_header_block_second>
                </UL_SubNav_Second>
             </LI_header_Second>
            {/* end nav */}
        </UL_Header_>
        
        <UL_Header >
             <LI_header>
                <A_Link className="block">
                        <Link to="TRANG_CHU" className="in-block" spy={true} smooth={true} offset={0} duration={500}  >Trang Chủ</Link>
                </A_Link>                    
            </LI_header>

            {/* <LI_header>
                   <A_Link className="block">
                      <Link  to="VE_CHUNG_TOI" className="in-block" spy={true} smooth={true} offset={50} duration={500}  >Về Chúng Tôi</Link> 
                  </A_Link>  
           </LI_header> */}

           {/* <LI_header>
                   <A_Link className="block">
                      <Link  to="TON_CHI" className="in-block" spy={true} smooth={true} offset={50} duration={800}>TÔN CHỈ</Link> 
                  </A_Link>  
           </LI_header> */}

            <LI_header>
                <A_Link className="block">
                            <Link to="THANH_VIEN" className="in-block" spy={true} smooth={true} offset={50} duration={900}  >Thành Viên</Link>
                </A_Link>
            </LI_header>  

            <LI_header>
                <A_Link className="block">
                            <Link to="SEO_LEADERS" className="in-block" spy={true} smooth={true} offset={50} duration={1100}  >C0-FOUNDER</Link>
                </A_Link>                                   
            </LI_header>

            <LI_header>
                <A_Link className="block">
                            <Link to="CONTACT" className="in-block" spy={true} smooth={true} offset={50} duration={1200}  >Liên Hệ</Link>
                </A_Link>                                   
            </LI_header>


            <LI_header >
                <A_Link className="block">
                            <a style={{textDecoration:"none",color:'white',fontWeight:'bold'}} href="/login" className="in-block">Đăng Nhập</a>
                </A_Link>                                   
            </LI_header>
            
            <LI_header >
                <A_Link className="block">
                           <a style={{textDecoration:"none",color:'white',fontWeight:'bold'}} href="/" className="in-block">Phần Mềm KidTop</a>
                </A_Link>                                   
            </LI_header>

            <LI_header_Second>
                <A_Link className="block">CHÚNG TÔI
               <i><ArrowDropDownIcon/></i> 
                </A_Link>
                <UL_SubNav_Second className='ShowBox'>
                    <LI_header_block_second><A_Link_Second className="block_second" href='/cong-nghe'>Công Nghệ</A_Link_Second></LI_header_block_second>
                    <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-tu-van/'>Tư Vấn Đấu Thầu</A_Link_Second></LI_header_block_second>
                    <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-travel-2/'>Lữ Hành Du Lịch</A_Link_Second></LI_header_block_second>
                    <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-soft-3/'>Soft - Phần Mềm</A_Link_Second></LI_header_block_second>
                    <LI_header_block_second><A_Link_Second className="block_second" href='https://cdgdt.com/holding-media/'>Truyền Thông</A_Link_Second></LI_header_block_second>
                    <LI_header_block_second><A_Link_Second className="block_second" href='https://noithatthuangia.vn/'>Thiết Kế Nội Thất</A_Link_Second></LI_header_block_second>
                </UL_SubNav_Second>
             </LI_header_Second>
            {/* end nav */}
        </UL_Header>
        {/* begin search */}
             <SearchHeader>
                <SearchIconClass >
                    <SearchIcon/>
                </SearchIconClass>
             </SearchHeader>

             <SearchHeader_ onClick={()=>setToogle(!toogle)}>
                <SearchIconClass >
                    {toogle?<CloseIcon/>:<MenuIcon/>}                    
                </SearchIconClass>
             </SearchHeader_>
        {/* end search */}
    </Header>
    );
};

export default HeaderComponent;