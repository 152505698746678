import {put,takeEvery,call,select, takeLatest}from 'redux-saga/effects'
import {UPLOAD_TRANSFER_LEFT_OVER,UPLOAD_TRANSFER_LEFT_OVER_TH,DISPATCH_GET_LIST_TRANSFER,DELETE_TRANSFER_LEFT_OVERS_BY_MONTH_CLASS, DISPATCH_GET_LIST_TRANSFER_TH, DELETE_TRANSFER_LEFT_OVERS_BY_MONTH_CLASS_TH} from '../../contant/Gov/countEat/dispatch'
import {handlePrvMonthTransfer} from '../../helper/dateTime/handleDMY'
import {TransferLeftOverToAddDb} from '../../helper/countEat/transfer'
import {AddTransferLeftOver,GetTransferLeftOver,DeleteTransferLeftOver}from '../../api-services/student/student'
import * as uiLoading from '../../actions/uiLoading'
import {DispatchAddTransferLeftOverToStore,CloseFormTransferLeftOver,DispatchListTranferToStore,DeleteTransferLeftSuccess,DispatchAddTransferLeftOverToStoreTh,CloseFormTransferLeftOverTh, DispatchListTranferToStoreTh, DeleteTransferLeftSuccessTh} from '../../actions/Gov/student/student'
const SUCCESS = 'success'
function* UploadTransferLeftOverSaga({payload}){
    yield put(uiLoading.loadingSuccess())  
    // danh sach so phieu an thua hoc thang truoc
    let arrTransfer = payload.item
    // cac thoong tin ve lop, nam hoc ten tk...
    let valueTransfer = yield select(state=>state.CountEat.ValuesTransferLeftOver)
    let {DateInput,FilterListStudent} = valueTransfer
    //tim thang truoc do
    let PrvMonth = handlePrvMonthTransfer(DateInput)
    let result = TransferLeftOverToAddDb(PrvMonth,arrTransfer,FilterListStudent)
    let res = yield call(AddTransferLeftOver,result)
    if(res.data.result===SUCCESS){
        yield put(uiLoading.loadingFail())
        yield put(DispatchAddTransferLeftOverToStore(res.data.data))
        yield put(CloseFormTransferLeftOver())
    }else{
        alert('Lỗi, liên hệ với quản trị')
    }
}

function* UploadTransferLeftOverThSaga({payload}){
    yield put(uiLoading.loadingSuccess())  
    // danh sach so phieu an thua hoc thang truoc
    let arrTransfer = payload.item
    // cac thoong tin ve lop, nam hoc ten tk...
    let valueTransfer = yield select(state=>state.CountEat.ValuesTransferLeftOverTh)
    let {DateInput,FilterListStudent} = valueTransfer
   // console.log(arrTransfer)
   // console.log(valueTransfer)
    //tim thang truoc do
    let PrvMonth = handlePrvMonthTransfer(DateInput)
    let result = TransferLeftOverToAddDb(PrvMonth,arrTransfer,FilterListStudent)
    let res = yield call(AddTransferLeftOver,result)
    //console.log(res)
    if(res.data.result===SUCCESS){
        yield put(uiLoading.loadingFail())
        yield put(DispatchAddTransferLeftOverToStoreTh(res.data.data))
        yield put(CloseFormTransferLeftOverTh())
    }else{
        alert('Lỗi, liên hệ với quản trị')
    }
}


function* DispatchGetListTransferSaga({payload}){
    yield put(uiLoading.loadingSuccess()) 
        let item ={ idYearSchool:payload.idYearSchool,
            userId:payload.userId}
        let res = yield call(GetTransferLeftOver,item)
        if(res.data.result===SUCCESS){
            yield put(uiLoading.loadingFail())
            yield put(DispatchListTranferToStoreTh(res.data.data))
        }
}

function* DispatchGetListTransferThSaga({payload}){
    yield put(uiLoading.loadingSuccess()) 
        let item ={ idYearSchool:payload.idYearSchool,
            userId:payload.userId}
        let res = yield call(GetTransferLeftOver,item)
        if(res.data.result===SUCCESS){
            yield put(uiLoading.loadingFail())
            yield put(DispatchListTranferToStoreTh(res.data.data))
        }
}

function* DeleteTransferLeftOversByMonthSaga({payload}){
    yield put(uiLoading.loadingSuccess()) 
    let res = yield call(DeleteTransferLeftOver,payload.item)
    if(res.data.result===SUCCESS){
        yield put(uiLoading.loadingFail())
        yield put(DeleteTransferLeftSuccess())
    }else{
        alert('Lỗi, xóa không được, liên hệ với Quản trị')
    }
}

function* DeleteTransferLeftOversByMonthThSaga({payload}){
    yield put(uiLoading.loadingSuccess()) 
    let res = yield call(DeleteTransferLeftOver,payload.item)
    if(res.data.result===SUCCESS){
        yield put(uiLoading.loadingFail())
        yield put(DeleteTransferLeftSuccessTh())
    }else{
        alert('Lỗi, xóa không được, liên hệ với Quản trị')
    }
}

function* TransferSaga(){
    yield takeLatest(UPLOAD_TRANSFER_LEFT_OVER,UploadTransferLeftOverSaga)
    yield takeLatest(UPLOAD_TRANSFER_LEFT_OVER_TH,UploadTransferLeftOverThSaga)
    yield takeLatest(DISPATCH_GET_LIST_TRANSFER,DispatchGetListTransferSaga)
    yield takeLatest(DISPATCH_GET_LIST_TRANSFER_TH,DispatchGetListTransferThSaga)
    yield takeLatest(DELETE_TRANSFER_LEFT_OVERS_BY_MONTH_CLASS,DeleteTransferLeftOversByMonthSaga)
    yield takeLatest(DELETE_TRANSFER_LEFT_OVERS_BY_MONTH_CLASS_TH,DeleteTransferLeftOversByMonthThSaga)

}

export default TransferSaga