
import {  showDDMMYY } from '../../../../../../../helper/dateTime/handleDMY'
import {filterOldNew,filterPayment,ArrPaymentOldNew,findByName,} from '../../Categories/Component/Fn'
export const handleUpdateImportMaterial=(item,arrAll,listSupplies)=>{
    let {ArrOrgin,ArrNameMaterial,allArrMaterial,arrMaterialResult}   = arrAll   
    ArrOrgin = FindNewArrOrigin(ArrOrgin,item,listSupplies,allArrMaterial)     
    return {ArrOrgin,ArrNameMaterial,allArrMaterial,arrMaterialResult} 
}

let FindNewArrOrigin =(ArrOrgin,item,listSupplies,allArrMaterial)=>{
  let index = ArrOrgin.findIndex(vv=>vv._id===item._id)
    if(index!==-1){
      let resultItem = {
        _id:item._id,
        userId:item.userId,
        dateInput:item.dateInput,
        expireDate:item.expireDate!==null||item.expireDate!==""?showDDMMYY(item.expireDate):"",
        idNameMaterial:item.idNameMaterial,
        idNameRepo:item.idNameRepo,
        idSupplies:item.idSupplies,
        nameMaterial:findByName(item,allArrMaterial)[0].name,
        nameSupplies:findSupplies(item,listSupplies)[0].name,
        price:item.price,
        quantity:item.quantity,
        payment:filterPayment(item,ArrPaymentOldNew),
        oldNew:filterOldNew(item,ArrPaymentOldNew),
      }    
      return [
        ...ArrOrgin.slice(0,index),
        resultItem,
        ...ArrOrgin.slice(index+1)
      ]    
    }      
}

const findSupplies=(item,arr)=>{
  return arr.filter(v=>v._id===item.idSupplies)
}


export const handleDeleteImportMaterial=(arr,item)=>{
    let {ArrOrgin,ArrNameMaterial,allArrMaterial,arrMaterialResult} = arr
    ArrOrgin = ArrOrgin.filter(v=>v._id!==item._id)
    return  {ArrOrgin,ArrNameMaterial,allArrMaterial,arrMaterialResult} 
}