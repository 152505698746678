//add
export const ADD_REPO = "ADD_REPO"
export const SHOW_OPEN_ADD_REPO   = "SHOW_OPEN_ADD_REPO"
export const CLOSE_OPEN_ADD_REPO  = "CLOSE_OPEN_ADD_REPO"
export const DISPATCH_VALUE_ADD_CREATE_REPO = "DISPATCH_VALUE_ADD_CREATE_REPO"
export const DISPATCH_VALUE_ADD_CREATE_REPO_TO_STORE    = "DISPATCH_VALUE_ADD_CREATE_REPO_TO_STORE"
export const DISPATCH_VALUE_ADD_CREATE_REPO_TO_DB       = "DISPATCH_VALUE_ADD_CREATE_REPO_TO_DB"
export const DISPATCH_ADD_SUCCESS_CREATE_REPO_TO_STORE          = "DISPATCH_ADD_CREATE_REPO_TO_STORE"
//view
export const SHOW_OPEN_VIEW_REPO = "SHOW_OPEN_VIEW_REPO"
export const CLOSE_OPEN_VIEW_REPO = "CLOSE_OPEN_VIEW_REPO"

//list 
export const LIST_ALL_CREATE_REPO = "LIST_ALL_CREATE_REPO"
export const DISPATCH_LIST_ALL_CREATE_REPO_TO_STORE = "DISPATCH_LIST_ALL_CREATE_REPO_TO_STORE"

//edit
export const UPDATE_CREATE_REPO_TO_DB = "UPDATE_CREATE_REPO_TO_DB"

export const OPEN_VIEW_INFO_REPO          = "OPEN_VIEW_INFO_REPO"
export const CLOSE_VIEW_INFO_REPO         = "CLOSE_VIEW_INFO_REPO"
export const UPDATE_VIEW_INFO_REPO        = "UPDATE_VIEW_INFO_REPO"