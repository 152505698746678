import React,{Fragment} from 'react'
import {withStyles} from '@material-ui/core'
import style from './style'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import {bindActionCreators} from 'redux'
import * as FormTaskActions from '../../actions/taskForm'
import {connect} from 'react-redux'



class Form extends React.Component{
  render() {    
    let{open, classes,getFormTaskAction,component,title} = this.props
    let{hideFormTask} = getFormTaskAction
    return (     
        <Dialog open={open} onClose={hideFormTask} aria-labelledby="form-dialog-title">
          <div className={classes.bgcolor} >            
             <div className={classes.textCv}> {title} </div>
             <CloseIcon className={classes.icon}/>
          </div>
          <Fragment>
            {component}
          </Fragment>
        </Dialog>        
    );
  }
}

const mapStateToProps = state =>{
  return{
      open:state.formTask.showForm,
      component:state.formTask.component,
      title:state.formTask.title
  }
}

const mapDispatchStateToProps = (dispatch)=>{
    return{
      getFormTaskAction : bindActionCreators(FormTaskActions,dispatch)
    }
}
export default withStyles(style)(connect(mapStateToProps,mapDispatchStateToProps)(Form))