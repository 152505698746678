import { showMMYY } from '../../../../../helper/dateTime/handleDMY'
import { updateArrById } from '../../../../../helper/fnAllways'
import {toastSuccess}from '../../../../../helper/toastHelper'
import { NewNameTask, PAYMENT_STAFF_GET_MATERIAL, PAY_TASK_JOB } from '../../../TaiKhoanNhanVienDangNhap/Component_CongViec/Fn'
import { STATUS_COMPLETE, STATUS_PROCESS } from '../component/Fn'
import {OPEN_FORM_CREATE_ACCOUNT,CLOSE_FORM_CREATE_ACCOUNT,DISPATCH_EXIST_NAME_LOGIN_TO_STORE,DISPATCH_DEFAULT_VALUE_EXIST_NAME_LOGIN,
DISPATCH_ADD_STAFF_TO_STORE,DISPATCH_GET_LIST_INFO_STAFF,UPDATE_ITEM_INFO_STAFF_ACTIVE_DEACTIVE,OPEN_SETTING_PAYMENT,CLOSE_SETTING_PAYMENT,
OPEN_LIST_SELL_PRODUCT,CLOSE_LIST_SELL_PRODUCT, DISPATCH_NEW_LIST_AFTER_DELETE_SELL_PRODUCT,DISPATCH_NEW_LIST_AFTER_ADD_SELL_PRODUCT,
DISPATCH_ADD_PAYMENT_STAFF_TO_STORE,DISPATCH_GET_LIST_PAYMENT_STAFF_TO_STORE,OPEN_EDIT_PAYMENT_STAFF,CLOSE_EDIT_PAYMENT_STAFF,OPEN_EDIT_LIST_SELL_PRODUCT,CLOSE_EDIT_LIST_SELL_PRODUCT, DISPATCH_EDIT_ITEM_AFTER_ADD_SELL_PRODUCT, DISPATCH_ITEM_DELETE_AFTER_EDIT_SELL_PRODUCT,OPEN_EDIT_STAFF,CLOSE_EDIT_STAFF,
DISPATCH_ITEM_EDIT_ACCOUNT_STAFF_TO_STORE,
OPEN_DISPATCH_ITEM_STAFF_TO_VIEW_FROM_MANAGER_STAFF,CLOSE_DISPATCH_ITEM_STAFF_TO_VIEW_FROM_MANAGER_STAFF,
OPEN_VIEW_IMAGE,CLOSE_VIEW_IMAGE,
DISPATCH_ADD_LOCATION_TO_STORE,DISPATCH_GET_LIST_LOCATION,DISPATCH_GET_CHAM_CONG_BY_USERID_TO_STORE,OPEN_EDIT_CHAM_CONG,CLOSE_EDIT_CHAM_CONG,OPEN_ADD_JOB,CLOSE_ADD_JOB,DISPATCH_ADD_TASK_JOB_STORE,DISPATCH_TASK_JOBS_TO_STORE,
//CONG VIEC
GET_LIST_TASK_JOB_BY_STAFF_CONG_VIEC,GET_LIST_TASK_JOB_BY_STAFF_CONG_VIEC_NONE,
DISPATCH_SELECT_MONTH_CONG_VIEC,TYPE_PAYMENT_SELECT_PAY_TASK_JOB_CONG_VIEC,
FIND_BY_CUSTOMER_CONG_VIEC,DISPATCH_FIND_BY_TASK_CONG_VIEC, DISPATCH_DEFAULT_TYPE_PAYMENT,
DISPATCH_APRROVE_TASK_JOB,
DISPATCH_APRROVE_TASK_JOB_CONG_TAC_PHI,
//cong no
DISPATCH_NAME_CUSTOMER_CONG_NO,
//Payment
UPDATE_TASK_JOB_PAYMENT,
LIST_PRICE_PAYMENT,UPDATE_LIST_PRICE_PAYMENT, SHOW_OPEN_INFO_STAFF, SHOW_CLOSE_INFO_STAFF, UPDATE_LIST_TASK_JOB,
UPDATE_LIST_TASK_JOB_MANGER_STAFF
} from '../contant'
import {DeleteArrEditPaymentStaff, 
  //FilterStaffByCustomer, 
  HandleUpdateArrPricePayment} from '../reducer/Fn'



export const initialState={
    OpenFormCreateAccount:false,
    OpenSettingPayment:false,
    OpenListSellProduct:false,
    OpenEditPaymentStaff:false,
    OpenEditListSellProduct:false,
    OpenViewImage:false,
    OpenViewItemStaff:false,
    OpenEditStaff:false,
    OpenEditChamCong:false,
    ItemStaff:{},
    CheckExistNameLogin:0,
    ListStaffInfo:[],
    ListUser:[],
    ListStaff:[],
    ActiveListStaff:false,
    ListArrSellProduct:[],
    ItemDeleteSellProduct:{},
    ListPaymentStaff:[],
    ActiveListPaymentStaff:false,
    ItemEditPaymentStaff:{
      Item:{},
      EditItem:{}
    },
    ItemEditStaff:{},
    ItemInfoStaff:{},
    ListGpsLocation:[],
    ListChamCong:[],
    ActiveListChamCong:false,
    ItemStaffToChamCong:{},
    OpenAddJob:false,
    ListTaskJob:[],
    ActiveListTaskJob:false,
    ListTaskJobCongTacPhi:[],
    //TAB CONG VIEC
    ListTaskJobByStaff:undefined,
    ItemPaymentStaff:"",
    MonthSelect:showMMYY(Date.now()),
    TypePayment:PAYMENT_STAFF_GET_MATERIAL,
    ListTaskJobByCustomer:[],
    ListTaskJobByTask:[],
    ItemCustomer:"",
    ListStaffByCustomer:[],
    ListTaskJobCongNo:[],
    ListPriceExport:[],
    ListPricePayment:[],
    ItemStaff:"",
    ShowInfoStaff:false,
    Staff:"",
    //ItemCustomer:""
}

const Index=(state=initialState,action)=>{
    switch (action.type) {     
        case OPEN_FORM_CREATE_ACCOUNT:
          return{
            ...state,
            OpenFormCreateAccount:true
          }
          case CLOSE_FORM_CREATE_ACCOUNT:
            return{
              ...state,
              OpenFormCreateAccount:false
            }
          case DISPATCH_EXIST_NAME_LOGIN_TO_STORE:
            return{
              ...state,
              CheckExistNameLogin:action.payload.item
            }
          case DISPATCH_DEFAULT_VALUE_EXIST_NAME_LOGIN:
            return {
              ...state,
              CheckExistNameLogin:0
            }
          case DISPATCH_ADD_STAFF_TO_STORE:
            toastSuccess('Lưu dữ liệu thành công')
              return{
                ...state,
                ListStaffInfo:state.ListStaffInfo.concat(action.payload.item),
                CheckExistNameLogin:0
              }
          case DISPATCH_GET_LIST_INFO_STAFF:
            return{
              ...state,
              ActiveListStaff:true,
              ListStaffInfo:action.payload.item.ListStaffInfo,
              ListUser:action.payload.item.arrUser,
              ListStaff:action.payload.item.arrStaff
            }
          case UPDATE_ITEM_INFO_STAFF_ACTIVE_DEACTIVE:
            return{
              ...state,
              ListStaffInfo:updateArrById(state.ListStaffInfo,action.payload.item)
            }     
          case OPEN_SETTING_PAYMENT:
            return{
              ...state,
              OpenSettingPayment:true,
              ItemStaff:action.payload.item
            } 
          case CLOSE_SETTING_PAYMENT:
            return{
              ...state,
              OpenSettingPayment:false,
              ItemStaff:{}
            }     
          case OPEN_LIST_SELL_PRODUCT:
            return{
              ...state,
             OpenListSellProduct:true,
             ListArrSellProduct:action.payload.item,
             ItemDeleteSellProduct:{}
            }
          case CLOSE_LIST_SELL_PRODUCT:
            return{
              ...state,            
              OpenListSellProduct:false,
            }
          case DISPATCH_NEW_LIST_AFTER_DELETE_SELL_PRODUCT:
            return{
              ...state,
              ListArrSellProduct:action.payload.item.arrSellProduct,
              ItemDeleteSellProduct:action.payload.item.itemDelete
            }
          case DISPATCH_NEW_LIST_AFTER_ADD_SELL_PRODUCT:
            return{
              ...state,
              ListArrSellProduct:action.payload.item
            }
          case DISPATCH_ADD_PAYMENT_STAFF_TO_STORE:
            return{
              ...state,
              ListPaymentStaff:state.ListPaymentStaff.concat(action.payload.item)
            }
          case DISPATCH_GET_LIST_PAYMENT_STAFF_TO_STORE:
            return{
              ...state,
              ActiveListPaymentStaff:true,
              ListPaymentStaff:action.payload.item
            }
          case OPEN_EDIT_PAYMENT_STAFF:
            return{
              ...state,
              OpenEditPaymentStaff:true,//mo form edit
              ItemEditPaymentStaff:{
                  Item:action.payload.item.Item,
                  EditItem:action.payload.item.EditItem
              }
            }
          case CLOSE_EDIT_PAYMENT_STAFF:
            return{
              ...state,
              OpenEditPaymentStaff:false,
              ItemEditPaymentStaff:{
                  Item:{},
                  EditItem:{}
              }
            }
          case OPEN_EDIT_LIST_SELL_PRODUCT:
            return{
              ...state,
              OpenEditListSellProduct:true
            }
          case CLOSE_EDIT_LIST_SELL_PRODUCT:
            return{
              ...state,
              OpenEditListSellProduct:false
            }
          case DISPATCH_EDIT_ITEM_AFTER_ADD_SELL_PRODUCT:
            return{
              ...state,
              ItemEditPaymentStaff:{
                Item:state.ItemEditPaymentStaff.Item,
                EditItem:action.payload.item
              }
            }
          case DISPATCH_ITEM_DELETE_AFTER_EDIT_SELL_PRODUCT:
            let result = DeleteArrEditPaymentStaff(state.ItemEditPaymentStaff.EditItem,action.payload.item)
            return{
              ...state,
              ItemEditPaymentStaff:{
                Item:state.ItemEditPaymentStaff.Item,
                EditItem:result
              }
            }
            case CLOSE_EDIT_LIST_SELL_PRODUCT:
              return{
                ...state,
                ItemEditPaymentStaff:{
                  Item:{},
                  EditItem:{}
                }
              }
            case OPEN_EDIT_STAFF:
              return{
                ...state,
                OpenEditStaff:true,
                ItemEditStaff:action.payload.item
              }
            case CLOSE_EDIT_STAFF:
              return{
                ...state,
                OpenEditStaff:false
              }
            case DISPATCH_ITEM_EDIT_ACCOUNT_STAFF_TO_STORE:
              return{
                ...state,
                ListStaffInfo:updateArrById(state.ListStaffInfo,action.payload.item)
              }
            case OPEN_DISPATCH_ITEM_STAFF_TO_VIEW_FROM_MANAGER_STAFF:
              return{
                ...state,
                ItemInfoStaff:action.payload.item,
                OpenViewItemStaff:true
              }
              case CLOSE_DISPATCH_ITEM_STAFF_TO_VIEW_FROM_MANAGER_STAFF:
              return{
                ...state,
                OpenViewItemStaff:false
              }
            case OPEN_VIEW_IMAGE:
              return{
                ...state,
                OpenViewImage:true
              }
            case CLOSE_VIEW_IMAGE:
              return{
                ...state,
                OpenViewImage:false
              }
            case DISPATCH_ADD_LOCATION_TO_STORE:
              toastSuccess('Thêm thành công')
              return{
                ...state,
                ListGpsLocation:state.ListGpsLocation.concat(action.payload.item)
              }
            case DISPATCH_GET_LIST_LOCATION:
              return{
                ...state,
                ListGpsLocation:action.payload.item
              }
            case DISPATCH_GET_CHAM_CONG_BY_USERID_TO_STORE:
              return{
                ...state,
                ListChamCong:action.payload.item,
                ActiveListChamCong:true
              }
            case OPEN_EDIT_CHAM_CONG:
              return{
                ...state,
                OpenEditChamCong:true,
                ItemStaffToChamCong:action.payload.item
              }
            case CLOSE_EDIT_CHAM_CONG:
              return{
                ...state,
                OpenEditChamCong:false
              }
          case OPEN_ADD_JOB:
            return{
              ...state,
              OpenAddJob:true
            }
          case CLOSE_ADD_JOB:
            return{
              ...state,
              OpenAddJob:false
            }
          case DISPATCH_ADD_TASK_JOB_STORE:
            toastSuccess('Khởi tạo thành công')
            return{
              ...state,
              ListTaskJob:[action.payload.item].concat(state.ListTaskJob)              
            }
         case DISPATCH_TASK_JOBS_TO_STORE:
           //console.log(state.ItemStaff)
          return {
            ...state,
            ActiveListTaskJob:true,
            ListTaskJob:action.payload.item,
            ListTaskJobCongTacPhi:action.payload.item.filter(v=>v.status===PAY_TASK_JOB),
            ListTaskJobByStaff:action.payload.item.filter(v=>v.Staff[0].idStaff===action.payload.item.idStaff&&(v.status===STATUS_COMPLETE||v.status===STATUS_PROCESS)&&v.taskJob.length!==0)
          }
          //TAB CONG VIEC
          case GET_LIST_TASK_JOB_BY_STAFF_CONG_VIEC:
              return{
                ...state,
              //  ItemStaff:action.payload.item,
                ListTaskJobByStaff:state.ListTaskJob.filter(v=>v.Staff[0].idStaff===action.payload.item.idStaff&&(v.status===STATUS_COMPLETE||v.status===STATUS_PROCESS)&&v.taskJob.length!==0),
                ItemPaymentStaff:state.ListPaymentStaff.filter(v=>v.idStaff===action.payload.item.idStaff),
                Staff:action.payload.item
              }
          case GET_LIST_TASK_JOB_BY_STAFF_CONG_VIEC_NONE:
            return{
              ...state,
              ListTaskJobByStaff:undefined,
              ItemPaymentStaff:"",
              Staff:""
            }
          case SHOW_CLOSE_INFO_STAFF:
            return{
              ...state,
              ShowInfoStaff:false
            }
          case SHOW_OPEN_INFO_STAFF:
            return{
              ...state,
              ShowInfoStaff:true
            }

          case DISPATCH_SELECT_MONTH_CONG_VIEC:        
            return{
              ...state,
              MonthSelect:action.payload.item,
              // ListTaskJobCongTacPhi:state.ListTaskJobCongTacPhi.filter(v=>showMMYY(v.dateBegin)===state.MonthSelect)
            }
          case TYPE_PAYMENT_SELECT_PAY_TASK_JOB_CONG_VIEC:
            return{
              ...state,
              TypePayment:action.payload.item
            }
          case FIND_BY_CUSTOMER_CONG_VIEC:
            if(action.payload.item!==""){
              let filterNotNull = state.ListTaskJobByStaff.filter(v=>v.taskJob[0]!==null)
              return{
                  ...state,
                  ListTaskJobByCustomer:state.ListTaskJobByStaff,
                  ListTaskJobByStaff:filterNotNull.filter(v=>v.taskJob[0].idCustomer===action.payload.item)
                }
            }
            if(action.payload.item===""){
              return{
                  ...state,
                  ListTaskJobByStaff:state.ListTaskJobByCustomer              
             }
            }          
          case DISPATCH_FIND_BY_TASK_CONG_VIEC:
            if(action.payload.item!==""){
              return{
                ...state,
                ListTaskJobByTask:state.ListTaskJobByStaff,
                ListTaskJobByStaff:state.ListTaskJobByStaff.filter(v=>NewNameTask(v.nameTask)===action.payload.item),
              }
            }
            if(action.payload.item===""){
              return{
                ...state,
                ListTaskJobByStaff:state.ListTaskJobByTask,
              }
            }
          case DISPATCH_DEFAULT_TYPE_PAYMENT:
            return{
              ...state,
              TypePayment:PAYMENT_STAFF_GET_MATERIAL
            }
          case DISPATCH_APRROVE_TASK_JOB:
                  return{
                          ...state,
                          ListTaskJobByStaff:updateArrById(state.ListTaskJobByStaff,action.payload.item),
                          ListTaskJob:updateArrById(state.ListTaskJob,action.payload.item),
                 }
          case DISPATCH_APRROVE_TASK_JOB_CONG_TAC_PHI:
            return{
              ...state,
              ListTaskJobCongTacPhi:updateArrById(state.ListTaskJobCongTacPhi,action.payload.item)
            }           
            //cong no
         case DISPATCH_NAME_CUSTOMER_CONG_NO:   
            if(action.payload.item!==null){             
              
              let findByListTaskJob = state.ListTaskJob.filter(v=>v.status===STATUS_COMPLETE&&v.typeOf===5)
              //let ListStaffByCustomer = FilterStaffByCustomer(findByListTaskJob,action.payload.item)
              return{
                ...state,                
                ListTaskJobCongNo:findByListTaskJob.filter(v=>v.taskJob[0].idCustomer===action.payload.item._id),
                ItemCustomer:action.payload.item
              }
            }
            if(action.payload.item===null){
              return{
                ...state,
                ListTaskJobCongNo:[],
                ItemCustomer:"",
                ListStaffByCustomer:[]
              }
            }
         case UPDATE_TASK_JOB_PAYMENT:
           let resultUpdate = HandleUpdateArrPricePayment(state.ListPricePayment,action.payload.item)
           return{
             ...state,
             ListTaskJob:updateArrById(state.ListTaskJob,action.payload.item),
             ListTaskJobByStaff:updateArrById(state.ListTaskJobByStaff,action.payload.item),
             ListPricePayment:resultUpdate
           }
        case LIST_PRICE_PAYMENT:
          return{
            ...state,
            ListPricePayment:action.payload.item
          }
        case UPDATE_LIST_PRICE_PAYMENT:
            return{
              ...state,
              ListPricePayment:action.payload.item
            }
        case UPDATE_LIST_TASK_JOB:
          return{
            ...state,
            ListTaskJob:updateArrById(state.ListTaskJob,action.payload.item),
            ListTaskJobCongNo:updateArrById(state.ListTaskJobCongNo,action.payload.item)          
          }
        case UPDATE_LIST_TASK_JOB_MANGER_STAFF:
          return{
            ...state,
            ListTaskJob:action.payload.item
          }
        
        
        
        
  
      default: return state    
  }
}

export default Index
