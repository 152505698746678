import { DISPATCH_ADD_MATERIAL_MUTRIKID_TO_STORE,DISPATCH_LIST_MATERIAL_TO_STORE,
DISPATCH_ADD_MATERI_NUTRIKID_TO_STORE,UPLOAD_IMPORT_LIST_MATERIAL_NUTRIKID,
DISPATCH_UPDATE_MATERIAL_NUTRIKID
} from "../contant/material";

export const DispatchAddMaterialNutrikidToStore=(item)=>{
    return{
        type:DISPATCH_ADD_MATERIAL_MUTRIKID_TO_STORE,
        payload:{item}
    }
}

export const DispatchListMaterialToStore=(item)=>{
    return{
        type:DISPATCH_LIST_MATERIAL_TO_STORE,
        payload:{item}
    }
}

export const DispatchAddMateriNutrikidToStore=(item)=>{
    return {
        type:DISPATCH_ADD_MATERI_NUTRIKID_TO_STORE,
        payload:{item}
    }
}

export const UploadImportListMaterialNutrikid=(item)=>{
    return{
        type:UPLOAD_IMPORT_LIST_MATERIAL_NUTRIKID,
        payload:{item}
    }
}

export const DispatchUpdateMaterialNutrikid=(item)=>{
    return{
        type:DISPATCH_UPDATE_MATERIAL_NUTRIKID,
        payload:{item}
    }
}