import {DISPATCH_GET_QRCODE_TO_STORE,UPDATE_SUCCESS,UPDATE_FAIL} from '../Contant/Person/qrcode'
import {toastError,toastSuccess} from '../../helper/toastHelper'

  export const initalState={
      ListQrcode:{},
      QrcodeInList:[],
      QrcodeTypeof1:{}
  }
  
  const Index = (state=initalState,action)=>{
    switch (action.type) {
      case DISPATCH_GET_QRCODE_TO_STORE:
        return{
          ...state,
          ListQrcode:action.payload.item,
          QrcodeInList:action.payload.item.qrcodes,
          QrcodeTypeof1:action.payload.item.qrcodes.filter(v=>v.typeof===1)
        }
        case UPDATE_SUCCESS:
          toastSuccess('Lưu dữ liệu thành công')
          return{
            ...state
          }
          case UPDATE_FAIL:
            toastError('Lỗi, kiểm tra lại')
            return{
              ...state
            }
  
      default: return state
    }
  }
  
  export default Index