import React, { useEffect } from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {OPEN_BLOCK_APP_ACTIVE_MANAGER_SELL,CLOSE_BLOCK_APP_ACTIVE_MANAGER_SELL,EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_SELL}from '../../../Contant/Admin/AppTypeOfService'
import FormActive from '../../../Helper/FormOpen/index'
import {AlertDeActiveManagerSell,BtnExit} from '../../../Contant/index'
import HtmlAction from '../../Fn'
const Index = () => {
  const dispatch = useDispatch()
  const ItemAppActive = useSelector(state=>state.AdminAddService.ItemAppActive[0].managerSell)
  const OpenBlockAppActiveManagerSell = useSelector(state=>state.AdminAddService.OpenBlockAppActiveManagerSell)
  useEffect(()=>{
        if(ItemAppActive===false){
            dispatch({
              type:OPEN_BLOCK_APP_ACTIVE_MANAGER_SELL
            })
        }
        if(ItemAppActive===true){
          dispatch({
          type:CLOSE_BLOCK_APP_ACTIVE_MANAGER_SELL
        })
      }      
  })
  const CloseFormActive=()=>{
        dispatch({
          type:CLOSE_BLOCK_APP_ACTIVE_MANAGER_SELL
        })
  }

  const handleBtnExit=()=>{
      dispatch({
        type:EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_SELL
      })
  }

  return (
    <>
      {OpenBlockAppActiveManagerSell&&<FormActive
          open={OpenBlockAppActiveManagerSell}
          handleClose={CloseFormActive}
          ComponentAction={<HtmlAction
                alertText={AlertDeActiveManagerSell}
                fn={handleBtnExit}
                btn={BtnExit}          
          />}
      />}    
  </>
  )
}

export default Index