import {handlePrvMonthTransfer,showMMYY} from '../dateTime/handleDMY'
export const TransferLeftOverToAddDb=(date,arrTransfer,arrListStudent)=>{     
      let newArr = arrListStudent.map(v=>{
        let index = arrTransfer.findIndex(vv=>handleString(vv.fullName)===handleString(v.fullName))
        if(index>=0){
          return {
            ...v,
            ...arrTransfer[index]
          }
        }else{
          return{...v}
        }
      })
    return newArr.map(v=>{
        let item ={
          dateInput:date,
          userId:v.userId,
          idClass:v.idClass,
          idBlockClass:v.idBlockClass,
          idYearSchool:v.idYearSchool,
          sum:v.sum===undefined?0:v.sum,
          id:v._id, //id student
          fullName:v.fullName
        }
        return item
      })
      //return result.filter(v=>v.sum!==0)
}

const handleString=(v)=>{
      return (((v.toLowerCase()).split(/[\s,]+/g)).join(" ")).trim()
}


export const FilterItemTransferByClassByMonth =(date,ListTransfer,ItemStudent)=>{      
      let PrvMonth = handlePrvMonthTransfer(date)
      let result =ListTransfer.filter(v=>showMMYY(v.dateInput)===showMMYY(PrvMonth)&&
                                                v.userId===ItemStudent.userId&&
                                                v.idYearSchool === ItemStudent.idYearSchool&&
                                                v.idClass      === ItemStudent.idClass&&                                               
                                                v.idBlockClass  === ItemStudent.idBlockClass
      )
      return result.map(v=>{
        let item ={
          fullName:v.fullName,
          userId:v.userId,
          idYearSchool:v.idYearSchool,
          idClass:v.idClass,
          idBlockClass:v.idBlockClass,
          _id:v.id,
          sum:v.sum
        }
        return item
      })
    }


export const FindItemTransferByClassByMonthPaymentListTotal =(date,ListTransfer)=>{      
  let PrvMonth = handlePrvMonthTransfer(date)
  return ListTransfer.filter(v=>showMMYY(v.dateInput)===showMMYY(PrvMonth))                                         
  

}

export const FilterItemTransferByClassByMonthPaymentListTotal=(ListTranfer,idBlockClass)=>{
    let result = ListTranfer.filter(v=>v.idBlockClass===idBlockClass)
    return result.map(v=>{
    let item ={
      fullName:v.fullName,
      userId:v.userId,
      idYearSchool:v.idYearSchool,
      idClass:v.idClass,
      idBlockClass:v.idBlockClass,
      _id:v.id,
      sum:v.sum
    }
    return item
  })
}

export const CheckListTransferByMonth =(date,ListTransfer,itemClass)=>{  
    if(itemClass===null||date===null){
        return []
  }
    let PrvMonth = handlePrvMonthTransfer(date)
    return ListTransfer.filter(v=>showMMYY(v.dateInput)===showMMYY(PrvMonth)&&
                                  v.idClass  === itemClass._id 
    )
}