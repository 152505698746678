export const OPEN_FORM_ADD_CATEGORY              = "OPEN_FORM_ADD_CATEGORY"
export const CLOSE_FORM_ADD_CATEGORY             = "CLOSE_FORM_ADD_CATEGORY"
export const ADD_CATEGORY                        = "ADD_CATEGORY"
export const DISPATCH_ITEM_ADD_CATEGORY_TO_STORE = "DISPATCH_ITEM_ADD_CATEGORY_TO_STORE"
export const GET_CATEGORY                        = "GET_CATEGORY"
export const DISPATCH_LIST_CATEGORY_TO_STORE     = "DISPATCH_LIST_CATEGORY_TO_STORE"
export const UDPATE_CATEGORY_MANAGER_REPO        = "UDPATE_CATEGORY_MANAGER_REPO"

export const OPEN_FORM_ADD_MATERIAL              = "OPEN_FORM_ADD_MATERIAL"
export const CLOSE_FORM_ADD_MATERIAL             = "CLOSE_FORM_ADD_MATERIAL"
export const ADD_MATERIAL_MANAGER_REPO           = "ADD_MATERIAL_MANAGER_REPO"
export const DISPATCH_CATEGORY_TO_STORE_AFTER_ADD_MATERIAL = "DISPATCH_CATEGORY_TO_STORE_AFTER_ADD_MATERIAL"
export const ALERT_ADD_SUCCESS                   = "ALERT_ADD_SUCCESS"

export const OPEN_LIST_CATEGORY_MATERIAL         = "OPEN_LIST_CATEGORY_MATERIAL"
export const CLOSE_LIST_CATEGORY_MATERIAL        = "CLOSE_LIST_CATEGORY_MATERIAL"
export const DISPATCH_VALUE_CATEGORY_TO_STORE    = "DISPATCH_VALUE_CATEGORY_TO_STORE"
export const UPDATE_MATERIAL_MANAGER_REPO        = "UPDATE_MATERIAL_MANAGER_REPO"
export const UPDATE_LIST_ALL_MATERIAL_REPO       = "UPDATE_LIST_ALL_MATERIAL_REPO"

export const DELETE_CATEGORY_MATERIAL_REPO       = "DELETE_CATEGORY_MATERIAL_REPO"

export const OPEN_UPLOAD_EXCEL                   = "OPEN_UPLOAD_EXCEL"
export const CLOSE_UPLOAD_EXCEL                  = "CLOSE_UPLOAD_EXCEL"
export const ADD_MATERIALS_MANAGER_REPO          = "ADD_MATERIALS_MANAGER_REPO"


//nhap xuat kho
export const OPEN_FORM_IMPORT_MATERIAL           = "OPEN_FORM_IMPORT_MATERIAL"
export const CLOSE_FORM_IMPORT_MATERIAL          = "CLOSE_FORM_IMPORT_MATERIAL"
export const ADD_IMPORT_MATERIAL                 = "ADD_IMPORT_MATERIAL" //nhap kho
export const DISPATCH_ITEM_IMPORT_MATERIAL_TO_STORE = "DISPATCH_ITEM_IMPORT_MATERIAL_TO_STORE"
export const GET_LIST_IMPORT_MATERIAL               = "GET_LIST_IMPORT_MATERIAL"
export const DISPATCH_GET_LIST_IMPORT_MATERIAL_TO_STORE ="DISPATCH_GET_LIST_IMPORT_MATERIAL_TO_STORE"

export const CREATE_ALL_MATERIAL_TO_STORE_TO_SELECT  = "CREATE_ALL_MATERIAL_TO_STORE_TO_SELECT"

export const OPEN_FORM_DELETE_MATERIAL           = "OPEN_FORM_DELETE_MATERIAL"
export const CLOSE_FORM_DELETE_MATERIAL          = "CLOSE_FORM_DELETE_MATERIAL"

export const DELETE_MATERIAL_MAMAGER_REPO        = "DELETE_MATERIAL_MAMAGER_REPO"
export const STATUS_DELETE_FALSE                 = "STATUS_DELETE_FALSE"

//import
export const DISPATCH_ALL_MATERIALS              = "DISPATCH_ALL_MATERIALS"
export const MANAGER_REPO                        = "MANAGER_REPO"
export const MANAGER_SUPPLIES                    = "MANAGER_SUPPLIES"
export const OPEN_FORM_UPDATE_IMPORT_MATERIAL           = "OPEN_FORM_UPDATE_IMPORT_MATERIAL"
export const CLOSE_FORM_UPDATE_IMPORT_MATERIAL          = "CLOSE_FORM_UPDATE_IMPORT_MATERIAL"
export const UPDATE_IMPORT_MATERIAL              = "UPDATE_IMPORT_MATERIAL"

export const OPEN_FORM_DELETE_IMPORT_MATERIAL  =  "OPEN_FORM_DELETE_IMPORT_MATERIAL"
export const CLOSE_FORM_DELETE_IMPORT_MATERIAL =  "CLOSE_FORM_DELETE_IMPORT_MATERIAL"
export const DELETE_IMPORT_MATERIAL            =  "DELETE_IMPORT_MATERIAL"

export const OPEN_FORM_AUTO_IMPORT                       = "OPEN_FORM_AUTO_IMPORT"
export const CLOSE_FORM_AUTO_IMPORT                      = "CLOSE_FORM_AUTO_IMPORT"
export const AUTO_IMPORT                                 = "AUTO_IMPORT"

export const GET_LIST_IMPORT_STAFF                       = "GET_LIST_IMPORT_STAFF"
export const DISPATCH_GET_LIST_IMPORT_STAFF_TO_STORE     = "DISPATCH_GET_LIST_IMPORT_STAFF_TO_STORE"
export const LOGOUT                                      = "LOGOUT"

export const OPEN_FORM_APPROVE_IMPORT                    = "OPEN_FORM_APPROVE_IMPORT"
export const CLOSE_FORM_APPROVE_IMPORT                   = "CLOSE_FORM_APPROVE_IMPORT"

export const OPEN_VIEW_IMAGE_IMPORT_STAFF                = "OPEN_VIEW_IMAGE_IMPORT_STAFF"

export const CLOSE_VIEW_IMAGE_IMPORT_STAFF                = "CLOSE_VIEW_IMAGE_IMPORT_STAFF"

export const DDOS_ATTACK = "DDOS_ATTACK"

export const UPDATE_ITEM_IMPORT_STAFF = "UPDATE_ITEM_IMPORT_STAFF"
export const UPDATE_AUTO_IMPORT       = "UPDATE_AUTO_IMPORT"