import { Box,  Card, CardActions, CardContent, Select, TextField, Typography } from '@material-ui/core';
import React, { useEffect,  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValidateEmailAddress } from '../../../../../helper/fnAllways';
import {   DISPATCH_OTP_TO_DB, REGIS_ACCOUNT_APP_KIDSTOP } from '../../contant/GioiThieu';
//import {CLOSE_FORM_REGIS_ACCOUNT_KIDSTOP} from '../../contant/StateCss'
import { useStyles,ButtonStyle ,DangKyDiv} from './styleDangKy';
import AlertComponent from './Alert'
import LoadingComponent from '../GioiThieu/loading'


const FAIL    = 'fail'
const SUCCESS = 'success'
const TIMEOUT = 1000*60*3//3phut
const TIMER   = TIMEOUT/1000

const DANG_KY_PM = () => {   
    // css
    const FormRegisKidstopCss              = useSelector(state=>state.StateCss.FormRegisKidstop)
    const ValueAlert                       = useSelector(state=>state.GioiThieuWeb.ValueAlert)
    const Loading                          = useSelector(state=>state.GioiThieuWeb.Loading)
    const ValueItem                        = useSelector(state=>state.GioiThieuWeb.ValueItem)
    const dispatch                         = useDispatch()
    const classes                          = useStyles()
    const [email,setEmail]                 = useState("")
    const [nameLogin,setNameLogin]         = useState("")
    const [nameCus,setNameCus]             = useState("")
    const [tel,setTel]                     = useState("")
    const [add,setAdd]                     = useState("")
    const [pass,setPass]                   = useState("")    
    const [rePass,setRepass]               = useState("")
    const [objectUser,setObjectUser]       = useState(0)
    const [errObjectUser,setErrObjectUser] = useState(false)
    const [errEmail,setErrEmail]           = useState(false)
    const [errNameLogin,setErrNameLogin]   = useState(false)
    const [errTel,setErrTel]               = useState(false)
    const [errNameCus,setErrNameCus]       = useState(false)
    const [errAdd,setErrAdd]               = useState(false)
    const [errPass,setErrPass]             = useState(false)
    const [errRePass,setErrRePass]         = useState(false)
    const [textPass,setTextPass]           = useState("")
    const [textNameLogin,setTextNameLogin] = useState("")

    const [openInputOpt,setInputOpt]       = useState(ValueAlert)
    //const countRef                         = useRef(null)
    const [timer,setTimer]                 = useState(TIMER)
    const [otp,setOtp]                     = useState(null)
    const [CloseVerifyOtp,setCloseVerifyOtp] = useState(false)
    
    useEffect(()=>{
            if(timer===0){
                  clearInterval(()=>TimerRemain())
                  clearTimeout(()=>ChangeTimeInputOpt())
                  setEmail("")
                  setNameLogin("")
                  setAdd("")
                  setNameCus("")
                  setPass("")
                  setRepass("")
            }
    },[])
     useEffect(()=>{               
        if(ValueItem!==null){
            if(ValueItem.check&&ValueItem.value!==null){
                  setCloseVerifyOtp(true)
                  clearInterval(()=>TimerRemain())
                  clearTimeout(()=>ChangeTimeInputOpt())
                  setEmail("")
                  setNameLogin("")
                  setAdd("")
                  setNameCus("")
                  setTel("")
                  setPass("")
                  setRepass("")                
            }
        }      
        
     },[ValueAlert])

     useEffect(()=>{
        if(ValueAlert===SUCCESS&&ValueItem===null){
            TimerRemain()
            ChangeTimeInputOpt()
            setInputOpt(ValueAlert)       
        }
           if(openInputOpt===FAIL){
                  clearInterval(()=>TimerRemain())
                  clearTimeout(()=>ChangeTimeInputOpt())
                  setInputOpt(ValueAlert)
           }
     },[ValueAlert])
     
     const TimerRemain=()=>{
       setInterval(() => {            
            setTimer((timer) => 
            {
               let result = timer - 1
                if(result===0){
                    return ()=> {}
                }
                return result
            })          
          }, 1000)
     }
     const ChangeTimeInputOpt=()=>{
        setTimeout(()=>{
            setInputOpt(FAIL)
        },TIMEOUT)
     }
    const ShowErrEmail=()=>{
            setErrEmail(true)
            setTimeout(()=>{
                setErrEmail(false)
            },2000)
    }
     const ShowErrNameLogin=()=>{
        setErrNameLogin(true)
        setTimeout(()=>{
            setErrNameLogin(false)
        },2000)
      }
    const ShowErrNameCus=()=>{
        setErrNameCus(true)
        setTimeout(()=>{
            setErrNameCus(false)
        },2000)
    }
    const ShowErrTel=()=>{
        setErrTel(true)
        setTimeout(()=>{
            setErrTel(false)
        },2000)
    }
    const ShowErrAdd=()=>{
        setAdd(true)
        setTimeout(()=>{
            setAdd(false)
        },2000)
    }
        const ShowErrPass=()=>{
            setErrPass(true)
            setTimeout(()=>{
                setErrPass(false)
            },2000)
        }
        const ShowErrRePass=()=>{
            setErrRePass(true)
            setTimeout(()=>{
                setErrRePass(false)
            },2000)
        }
        const ShowTextPass=()=>{
            setTextPass('Nhập Khẩu Không Trùng Khớp, Nhập Lại')
            setTimeout(()=>{
                setTextPass(null)
            },2000)
        }
        const ShowLongTextPass=()=>{
            setTextPass('Mật Khẩu ít nhất có 8 ký tựi')
            setTimeout(()=>{
                setTextPass(null)
            },2000)
        }

    const SaveRegis=()=>{
        let item ={
            tel,
            email,
            nameLogin,
            nameCus,
            add,
            pass,
            rePass,
            objectUser
        }
        //console.log(item)
        if(item.email===""||item.email===null||!ValidateEmailAddress(item.email))     return ShowErrEmail()
        if(item.nameCus===""||item.nameCus===null)        return ShowErrNameCus()
        if(item.tel===""||item.tel===null)               return ShowErrTel()
        if(item.add===""||item.add===null)                return ShowErrAdd()
        if(item.nameLogin===""||item.nameLogin===null)    return ShowErrNameLogin()
        if(item.nameLogin.length<6) {
            ShowErrNameLogin()
            setTextNameLogin('Tên đăng nhập ít nhất 6 ký tự')
            setTimeout(()=>{
                    setTextNameLogin("")
            },3000)
            return
        }
        if(item.pass===""||item.pass===null)              return ShowErrPass()
        if(item.rePass===""||item.rePass===null)          return ShowErrRePass()
       
        if(item.pass.length<8)  {
            ShowLongTextPass()
            ShowErrPass()
            return
        }
        if(item.pass!==item.rePass){
            ShowTextPass()
            ShowErrPass()
            ShowErrRePass()
            return
        }           
            dispatch({type:REGIS_ACCOUNT_APP_KIDSTOP,payload:{item}})
            
    }
    const VerifyOtp=()=>{
            if(otp===null||otp===""){
                return alert('OTP không hợp lệ')
            }            
            dispatch({type:DISPATCH_OTP_TO_DB,payload:{item:{otp:otp.trim()}}})                        
            clearInterval(()=>{return false})
            clearTimeout(()=>{return false})
            setEmail("")
            setNameLogin("")
            setAdd("")
            setNameCus("")
            setPass("")
            setRepass("")
    }

  
    // const HideBtnRegis=()=>{     
    //     dispatch({type:CLOSE_FORM_REGIS_ACCOUNT_KIDSTOP})
    // }

    const SelectUser=()=>{
        return(
            <>
               <form noValidate autoComplete="off" >
                    <Typography style={{color:'white',margin:5,fontSize:12}}>
                            Đối Tượng Sử Dụng
                    </Typography>
                        {/* <InputLabel htmlFor="age-native-simple"></InputLabel> */}
                        <Select
                        native
                        defaultValue={objectUser}
                        onChange={e=>setObjectUser(e.target.value)}
                        error={errObjectUser}
                        fullWidth
                        variant='outlined'     
                        style={{background:'#ecf0f1',fontFamily:'Roboto',fontSize:14,fontStyle:'italic',}}                                                                 
                        >
                        <option value={0} style={{fontSize:14,fontStyle:'italic',fontFamily:'Roboto'}}>Cấp Mầm Non</option>
                        <option value={1} style={{fontSize:14,fontStyle:'italic',fontFamily:'Roboto'}}>Cấp Tiểu Học</option>
                        <option value={1} style={{fontSize:14,fontStyle:'italic',fontFamily:'Roboto'}}>Khác</option>
                        </Select>
              </form>
            </>
        )
    }

    const Login=()=>{
        window.location.assign('http://huythanhicare.com/login')
    }


    return (
        <>
              <AlertComponent/>         
              {/* {!switchHideFormRegis&&                    */}
                    <DangKyDiv variant={FormRegisKidstopCss}>             
                        <Card className={classes.root}>                       
                              <Box display="flex" justifyContent="center" >
                                <Typography style={{color:'#ecf0f1',fontFamily:'Tahoma',marginTop:5,fontWeight:'bold'}}>
                                    Đăng Ký Sử Dụng Phần Mềm Dinh Dưỡng KidsTop            
                                </Typography>    
                                {/* <Typography style={{color:'#ecf0f1',fontFamily:'Roboto',fontStyle:'italic'}}>
                                   Miễn Phí Sử Dụng 30 ngày      
                                </Typography>     */}
                            </Box>  
                            {/* <Box display="flex" justifyContent="center" >                                  
                                <Typography style={{color:'#ecf0f1',fontFamily:'Roboto',fontStyle:'italic',fontWeight:'bold',fontSize:12}}>
                                   Miễn Phí Sử Dụng 30 Ngày      
                                </Typography>    
                            </Box>  
                            <Box display="flex" justifyContent="center" >                                  
                                <Typography style={{color:'#ecf0f1',fontFamily:'Roboto',fontStyle:'italic',fontWeight:'bold',fontSize:12}}>
                                    Hoàn trả tiền mua sản phẩm trong 15 ngày sử dụng nếu không phù hợp!
                                </Typography>    
                            </Box>   */}
                            <CardContent>
                                <form noValidate autoComplete="off" >
                                        <Typography style={{color:'white',margin:5,fontSize:12}} >Email Address</Typography>
                                        <TextField 
                                            onChange={e=>setEmail(e.target.value)}
                                            error={errEmail}
                                            fullWidth variant="outlined" size="small" style={{background:'white'}}
                                            defaultValue={email}                                                                
                                            />
                                </form>
                                <form noValidate autoComplete="off" >
                                        <Typography style={{color:'white',margin:5,fontSize:12}} >Tên Khách Hàng</Typography>
                                        <TextField 
                                            onChange={e=>setNameCus(e.target.value)}
                                            error={errNameCus}
                                            fullWidth variant="outlined" size="small" style={{background:'white'}}
                                            defaultValue={nameCus}                                
                                            />
                                </form>                                
                                <form noValidate autoComplete="off" >
                                        <Typography style={{color:'white',margin:5,fontSize:12}} >Số Điện thoại</Typography>
                                        <TextField 
                                            onChange={e=>setTel(e.target.value)}
                                            error={errTel}
                                            fullWidth variant="outlined" size="small" style={{background:'white'}}
                                            defaultValue={tel}                                
                                            />
                                </form>
                                 <form noValidate autoComplete="off" >
                                        <Typography style={{color:'white',margin:5,fontSize:12}} >Địa Chỉ</Typography>
                                        <TextField 
                                            onChange={e=>setAdd(e.target.value)}
                                            error={errAdd}
                                            fullWidth variant="outlined" size="small" style={{background:'white'}}
                                            defaultValue={add}                                
                                            />
                                 </form>
                                 <form noValidate autoComplete="off" >
                                        <Typography style={{color:'white',margin:5,fontSize:12}}>Tên Đăng Nhập</Typography>
                                        <TextField 
                                        onChange={e=>setNameLogin(e.target.value)}
                                        error={errNameLogin}
                                        fullWidth variant="outlined" size="small" style={{background:'white'}}
                                        defaultValue={nameLogin}   
                                        helperText={textNameLogin}                                
                                        />
                                    </form>
                                    <form noValidate autoComplete="off" >
                                        <Typography style={{color:'white',margin:5,fontSize:12}}>Mật Khẩu</Typography>
                                        <TextField
                                        onChange={e=>setPass(e.target.value)}
                                        error={errPass}
                                        helperText={textPass}
                                        defaultValue={pass}
                                        type="password"
                                        fullWidth variant="outlined" size="small" style={{background:'white'}}/>
                                    </form>
                                    <form noValidate autoComplete="off" >
                                    <Typography style={{color:'white',margin:5,fontSize:12}}>Nhập Lại Mật Khẩu</Typography>
                                    <TextField 
                                    onChange={e=>setRepass(e.target.value)}
                                    error={errRePass}
                                    helperText={textPass}
                                    type="password"
                                    defaultValue={rePass}
                                    fullWidth variant="outlined" size="small" style={{background:'white'}}
                                    />
                                   </form>     

                                   {SelectUser()}
                                
                                    {CloseVerifyOtp?<></>:
                                                    <>
                                                    {openInputOpt===SUCCESS?
                                                            <form noValidate autoComplete="off" >
                                                            <Typography style={{color:'white',margin:5,fontSize:12}}>Nhập Mã OPT</Typography>
                                                                <TextField                                   
                                                                    onChange={e=>setOtp(e.target.value)}
                                                                    fullWidth variant="outlined" size="small" style={{background:'white'}}
                                                                    helperText={`OTP Hết Hạn Sau ${timer}s`}
                                                                    error={true}
                                                                />
                                                            </form>
                                                        :<></>
                                                        }
                                                    </>
                                    }                    
                                
                            </CardContent>  
                                    {Loading?   <Box display="flex" justifyContent="center">
                                                <LoadingComponent/>
                                                </Box>
                                    :<></>}                            

                                    {Loading?<></>:
                                        <CardActions>                           
                                                {openInputOpt===SUCCESS?<ButtonStyle variant="outline" onClick={()=>VerifyOtp()}>Xác Minh OTP</ButtonStyle>
                                                :<></>}
                                                {openInputOpt!==SUCCESS?
                                                <>
                                                    <ButtonStyle variant="outline" onClick={()=>SaveRegis()}>Đăng Ký Sử Dụng</ButtonStyle>
                                                    <ButtonStyle variant="outline" onClick={()=>Login()}>Đăng Nhập</ButtonStyle>
                                                </>
                                                :<></>}  
                                                {/* <ButtonStyleHide variant="outline" onClick={()=>HideBtnRegis()}>Ẩn Đăng Ký</ButtonStyleHide> */}
                                            
                                        </CardActions>                          
                                    }

                        </Card>                
                    </DangKyDiv>
              {/* }                     */}
        </>
      
    );
};

export default DANG_KY_PM;