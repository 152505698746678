import {showDD,showMM,showYYYY,showDateName,showDDMMYY}from '../../../helper/dateTime/handleDMY'
import {UpperCase}from '../../../helper/fnAllways'
import {showYYMMDD} from '../../../helper/dateTime/handleDMY'
import _ from 'lodash'
export const ShowDateName =(date)=>{
  let DD       = showDD(date)
  let MM       = showMM(date)
  let YYYY     = showYYYY(date) 
  let DDMMYY = `${YYYY}/${MM}/${DD}`
  let result = {
    dateName:UpperCase(showDateName(DDMMYY)),
    date:showDDMMYY(date)
  }
  return result
}

export const FindItemToCountEat=(ListStudent,MenuWeekly,DateInput)=>{ 
    if(ListStudent!==undefined&&MenuWeekly!==undefined&&MenuWeekly!==""){
      let item = {
          dateNow:showYYMMDD(DateInput),        
          idMenuWeekLy:MenuWeekly!==undefined?MenuWeekly._id:"",         
          idClass:ListStudent.length!==0?ListStudent[0].idClass:null,
          idBlockClass:ListStudent.length!==0?ListStudent[0].idBlockClass:null,
          idYearSchool:ListStudent.length!==0?ListStudent[0].idYearSchool:null,
          userId:ListStudent.length!==0?ListStudent[0].userId:null,
          nameStudentsNotEat:[],
          students:[]
      }
      return item
    }
}

export const ItemApprovalCountEat=(item,userIdCurrent)=>{
      let newItem = {
        userClass:userIdCurrent,
        userId:item.userId,
        idClass:item.idClass,
        idYearSchool:item.idYearSchool,
        dateApproval:item.dateNow,
        status:false,
        messageClass:`Đợi phê duyệt chấm ăn`,
        messageManager:``,
        checkDayFood:item        
      }
      return newItem
}

export const FindListStudentSaturday=(List,ListSeven)=>{      
      if(List!==undefined&&ListSeven!==undefined&&List.length!==0&&ListSeven.length!==0){
          return ListSeven[0].nameStudentEatSevenDay.map(item=>{
            let index = List.findIndex(value=>value.idStudent===item.idStudent)
            if(index>=0){
              return{
                ...item,
                ...List[index]
              }              
               }else{
                return{...item}
              }
          })          
      }
}

export const FindListStuNotEat =(arr,arrValue)=>{
  if(arr!==undefined&&arrValue!==undefined){    
    let newArr = arrValue.map(v=>{
      let item={
        idStudent:v
      }
      return item
    })
    return newArr.map(item=>{
          let index = arr.findIndex(value=>value.idStudent===item.idStudent)
          if(index>=0){
            return{
              ...item,
              ...arr[index]
            }
          }else{
            return {...item}
          }
    })
  }
  
}

export const CheckStudentLeaveTemporaty=(arrTmp,item)=>{return arrTmp.some(v=>v.idStudent===item.idStudent&&v.active===true)}


export const HandleListStudentSendMess=(arr)=>{
    return arr.map((v)=>{
      return{
        idStudent:v.idStudent,
        fullName:v.fullName,
        nameParent:null,
        email:null,
        tel:null,
        sendEmail:false,
        sendSms:false,
        message:[],
        
      }      
    })
}