import { showYYMMDD } from "../../helper/dateTime/handleDMY"

export const CreateNewArrListActiveAccount=(arr)=>{
  return arr.map(v=>{
    let item={
      name:v.userAccount,
      email:v.email,
      active:v.active,
      nameClass:v.nameClass,
      idClass:v.idClass,
      role:v.role,
      _id:v._id,
      password:'',    
      userId:v.userId  
    }
    return item
  })
}



export const updateArrApproval= (arr,value)=>{    
  if(arr!==null&&arr!==undefined&&arr.length!==0){
     let index = arr.findIndex(item=>item.userClass===value.userClass&&showYYMMDD(item.dateApproval)===showYYMMDD(value.dateApproval))
    if(index!==-1){
      let newArr =[
        ...arr.slice(0,index),
        value,
        ...arr.slice(index+1)
      ]   
      return newArr
    }
    else{
     return arr
   } 
  }
}

export const CheckExitManagerClass=(arr,arrList,item)=>{ 
  let filterRole_1 = arrList.listAccount.filter(v=>v.role===1)
  let filterItem = filterRole_1.filter(v=>v.idClass===arr[0].idClass)
  if(filterItem!==undefined&&filterItem.length!==0&&filterItem[0].userId===item.userId){
    return item
  }
  return null
}