import styled,{keyframes} from "styled-components";

export const  gradient = keyframes`
     0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

export const MainDiv = styled.div`
  width:100%;
  height:100%;
  background-color:orange;
  background: linear-gradient(-45deg,#23d5ab,#95a5a6,#f39c12,#1abc9c,#95a5a6,#f1c40f);
  background-size: 400% 400%;
  animation: ${gradient} 50s ease infinite;
  ${'' /* height: 100vh; */}
`

