import {put,call, takeLatest} from 'redux-saga/effects'
import {DISPATCH_UPDATE_ITEM_APP_ACTIVE,EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_REPO,EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_STAFF,EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_SELL} from '../../Contant/Admin/AppTypeOfService'
import {UpdateItemAppActive} from  '../../Api-services/ADMIN_APP_SERVICE'
import {push}from 'connected-react-router'
import {REDIRECT_LOGIN_PERSON} from '../../../contant/auth'
function* DispatchUpdateItemAppActive({payload}){
    yield call(UpdateItemAppActive,payload.item)
}

function* ExitBtnManagerRepo(){
    yield put(push(REDIRECT_LOGIN_PERSON))
}

function* ExitBtnManagerStaff(){
  yield put(push(REDIRECT_LOGIN_PERSON))
}

function* ExitBtnManagerSell(){
  yield put(push(REDIRECT_LOGIN_PERSON))
}

function* AppSaga(){
  yield takeLatest(DISPATCH_UPDATE_ITEM_APP_ACTIVE,DispatchUpdateItemAppActive)
  yield takeLatest(EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_REPO,ExitBtnManagerRepo)
  yield takeLatest(EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_STAFF,ExitBtnManagerStaff)
  yield takeLatest(EXIT_PAGE_WHEN_CLICK_BTN_MANAGER_SELL,ExitBtnManagerSell)

}

export default AppSaga