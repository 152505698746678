import React ,{useState, useEffect}from 'react';
import EnhancedTableHead from './TableHead'
import EnhancedTableToolbar from './TableToobar'
import {useStyles} from './style'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TablePagination from '@material-ui/core/TablePagination';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {useSelector,useDispatch} from 'react-redux'
import * as TypePerson from '../../../contant/table/Person'
import PersonFormEdit from './edit/editPerson/EditPerson'
import _ from 'lodash'
import { Button } from '@material-ui/core';
import AppList from './AppList/index'
import {OPEN_SELECT_APP_ACTIVE,CLOSE_SELECT_APP_ACTIVE} from  '../../../APP_ADMIN_PERSON/Contant/Admin/AppTypeOfService'


const  desc=(a, b, orderBy)=> {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
 
const stableSort=(array, cmp)=> {  
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

 const getSorting=(order, orderBy)=> {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             

const Staff=()=> {    
  let DBPerson = useSelector(state=>state.auth.DBPerson)  
  let user     = useSelector(state=>state.auth.DBListAllUser)
  const [lengthRow] = useState(DBPerson.length)  
  const [allId,setAllId]  = useState([])
  const [rows,setRows] = useState(DBPerson) 
  const [open,setOpen] = useState(false)  
  const [editForm,setEditForm] = useState({})
  const [openEdit,setOpenEdit] = useState(false)
  const [showColor,setShowColor] = useState(false)
  const [color,setColor] = useState('')
  const [idColor,setIdColor] = useState('') 
  const OpenAppActive = useSelector(state=>state.AdminAddService.OpenAppActive)
  const [itemAppActive,setItemAppActive] = useState(null)
   //tuong duong coponentWillMount
  useEffect(()=>{
    setRows(DBPerson)    
    setAllId(DBPerson.map(item=>item._id))        
  },[DBPerson])

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleCloseEdit = ()=>{
    setOpenEdit(false)
  }
 
  const dispatch = useDispatch()

  const handleDeletePerson=async(allId)=>{        
     dispatch({
       type:TypePerson.DELETE_PERSON_ARRAY,
       payload:{allId}
     })   
    //console.log(allId)
         if(allId.length===lengthRow){     
         setRows([])
         }else{      
           let newResultId = _.difference(rows,allId)      
           setRows(newResultId)
         }    
         setOpen(false)
         setSelected([])    
  }
  const FindNameUser =(id,userList)=>{
      let findUser = userList.filter(v=>v._id===id)
      return findUser[0].length!==0?findUser[0].name:<></>
  }
  const classes = useStyles(); 
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('Tên tổ chức')
  const [selected, setSelected] = useState([])  
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false)

  const [rowsPerPage, setRowsPerPage] = useState(5)
  const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  } 

  const [anchorEl, setAnchorEl] = useState(null);

  const OpenSelectApp = (row) => {
        //setSelected(event.currentTarget.value)
        //setAnchorEl(event.currentTarget);
        dispatch({
          type:OPEN_SELECT_APP_ACTIVE
        })
        setItemAppActive(row)
  };

  const CloseSelectApp=()=>{
    dispatch({
      type:CLOSE_SELECT_APP_ACTIVE
    })
  }

  // const CloseSelectApp = () => {
  //   setAnchorEl(null);
  //   setSelected([])
  // };
  
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.humanName)     
      setSelected(newSelecteds);
      setAllId(rows)      
      return;
    }
    setSelected([]);    
  }

  // const resetSelect = ()=>{
  //   setSelected([])
  // }

  const editPerson = (allId)=>{  
      setOpenEdit(true)
      setEditForm(allId[0])   
      setSelected([])       
  }
  
  const handleAlertColorEdit = (userId,itemColor)=>{
          setIdColor(userId)
          setColor(itemColor)
          setShowColor(true)
          setTimeout(()=>{
            setColor('')
            setShowColor(false)
          },3000)
  }

  const handleClick = (event, humanName,_id) => {
    const selectedIndex = selected.indexOf(humanName);
    let newSelected = []   
    
    if (selectedIndex === -1 ) {
         newSelected = newSelected.concat(selected, humanName)    
                      
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    //xac dinh id truoc khi xoa
    setSelected(newSelected);   
    let get = newSelected.map(item=>rows.filter(result=>result.humanName===item))
    let arrayGet =_.flatten(get)   
    setAllId(arrayGet)    
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const handleChangeDense = event => {
    setDense(event.target.checked);
  } 

  const isSelected = workPlace => selected.indexOf(workPlace) !== -1
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const searchFn=(value)=>{             
            if(value!==''||value!==null){
              value = value.toLowerCase()
              let search = rows.filter((row)=>{
                return (row.humanName.toLowerCase().indexOf(value)!==-1 ||
                        //row.departmentName.toLowerCase().indexOf(value)!==-1||
                        row.tel.toLowerCase().indexOf(value)!==-1||
                        row.address.toLowerCase().indexOf(value)!==-1||
                        row.email.toLowerCase().indexOf(value)!==-1
                )
              })
              setRows(search)  
            }
            if(value.length===''||value.length===null||value===''||value===null||value.length===0){
               setRows(DBPerson)
            }      
  }
  
  return (     
    <>   
      {openEdit===true && (<PersonFormEdit
            openEdit={openEdit}
            editForm={editForm}
            handleCloseEdit={handleCloseEdit}
            handleAlertColorEdit={handleAlertColorEdit}                      
        />)}
        {OpenAppActive&&<AppList
            open={OpenAppActive}
            handleClose={CloseSelectApp}
            row={itemAppActive}
        />}
      <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar 
        numSelected={selected.length} 
        allId={allId}
        rows={rows} 
        handleDeletePerson={handleDeletePerson}
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        editPerson={editPerson}
        searchFn={searchFn}         
        />        
          <Table stickyHeader aria-label="sticky table" 
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            // aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onResetAll={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />           
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.humanName);
                  const nonSelected    = []
                  const labelId = `enhanced-table-checkbox-${index}`;
                 
                  return (
                    <TableRow                     
                      className={showColor&&idColor===row.userId?classes.colorAlert:''}
                      hover
                      onClick={event => handleClick(event, row.humanName,row._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.humanName}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell align="left">{FindNameUser(row.userId,user)}</TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">{row.humanName.toUpperCase()}
                      </TableCell>                      
                      <TableCell align="left">{row.tel}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.address}</TableCell>
                       <TableCell align="left">{row.avatar}</TableCell>       
                       <TableCell align="left">{row.signture}</TableCell>
                       <TableCell align="left" ><>
                       <Button aria-controls="simple-menu" aria-haspopup="true" onClick={()=>OpenSelectApp(row)} size="small" variant="contained" color="secondary">App</Button>
                          {/* <AppList
                              anchorEl={anchorEl}
                              handleClose={CloseSelectApp}
                              row={row}    
                          /> */}
                       </></TableCell>
                   </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>        
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Thu Gọn"
      />
    </div>  
    </>
  );
}

export default Staff