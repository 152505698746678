import { Box, Button } from '@material-ui/core'
import React,{useEffect,useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {PASSWORD_LOGIN} from '../../../contant/index'
import { CLOSE_UPDATE_APP_ZALO_ACCESS_TOKEN, GET_ACCESS_TOKEN, OPEN_UPDATE_APP_ZALO_ACCESS_TOKEN } from '../NotFoundPage/contant/Zalo'
import InputForm from './formAccess'
import ViewTable from './viewAccess'
import UpdateTokenForm from './UpdateAccess'
const CallBackPageZalo = () =>{
    const [view,setView]  = useState(false)
    const [add,setAdd]    = useState(false)
    const dispacth                     = useDispatch()
    const [valueAccess,setValueAccess] = useState(null)
    const [char,setChar]               = useState(false)

    const ListAccessToken   = useSelector(state=>state.ZaloAccessToken.ListAccessToken)
    const ItemToUpdateToken = useSelector(state=>state.ZaloAccessToken.ItemToUpdateToken)
    const OpenUpdate        = useSelector(state=>state.ZaloAccessToken.OpenUpdate)
     useEffect(()=>{
        console.log('ok')
        //lay access token
    },[])
    // useEffect(()=>{
    //        let value = window.prompt('Nhập Mật Khẩu')
    //        if(value===PASSWORD_LOGIN){
    //                     setValueAccess(value)
    //                     setChar(true)
    //                     return ()=>{}
    //        }
    // },[])
   
    return(
        <>
            <Box display="flex" justifyContent="center" marginTop={5}>
                            <Button variant='outlined' onClick={()=>{
                                let value = window.prompt('Nhập Mật Khẩu')
                                        if(value===PASSWORD_LOGIN){
                                            setView(true)
                                            setAdd(false)
                                            //goi api get access token
                                            dispacth({type:GET_ACCESS_TOKEN})
                                        }
                            }}>Xem</Button>&nbsp;&nbsp;
                            <Button variant='outlined'
                                    onClick={()=>{
                                        setView(false)
                                        setAdd(true)
                                        let value = window.prompt('Nhập Mật Khẩu')
                                        if(value===PASSWORD_LOGIN){
                                                     setValueAccess(value)
                                                     setChar(true)
                                        }
                                    }}
                            >Thêm</Button>

            </Box>

            {add&&<>
                    {char?<InputForm
                        open={char}
                        handleClose={()=>setChar(false)}
                    />:<></>}
            </>}               

            {view&&<ViewTable
                listToken ={ListAccessToken}
            />}
            {OpenUpdate&&<UpdateTokenForm
                    value ={ItemToUpdateToken}
                    open={OpenUpdate}
                    handleClose={()=>dispacth({type:CLOSE_UPDATE_APP_ZALO_ACCESS_TOKEN})}
            />}
        </>
    )
}

export default CallBackPageZalo

