import React from 'react';
import {Menu,MenuItem,Tooltip} from '@material-ui/core';
import {Add,Visibility } from '@material-ui/icons';
import {useDispatch} from 'react-redux'
import {OPEN_ADD_TYPE_OF_SERVICE,OPEN_VIEW_TYPE_OF_SERVICE} from '../../Contant/Admin/AddViewAppService'

export default function SimpleMenu({anchorEl,handleClose}) {  
  const dispatch = useDispatch()
  const AddTypeOfServices=()=>{
      dispatch({
        type:OPEN_ADD_TYPE_OF_SERVICE
      })
      handleClose()
  }
  const ViewTypeOfServices=()=>{
    dispatch({
      type:OPEN_VIEW_TYPE_OF_SERVICE
    })
    handleClose()
  }

  const AddApp=()=>{
      handleClose()
  }
  const ViewAddApp=()=>{
    handleClose()
}
  return (
    <>     
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem >Loại Hình Kinh Doanh 
          <Tooltip title="Thêm" aria-label="Thêm">
            <Add color="primary" onClick={()=>AddTypeOfServices()}/> 
          </Tooltip>
          <Tooltip title="Xem" aria-label="Xem">
             <Visibility color="secondary" onClick={()=>ViewTypeOfServices()} />
         </Tooltip>
        </MenuItem>
        <MenuItem>Phần mềm &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Tooltip title="Thêm" aria-label="Thêm">
            <Add color="primary" onClick={()=>AddApp()}/>
            </Tooltip>
            <Tooltip title="Xem" aria-label="Xem">
              <Visibility color="secondary" onClick={()=>ViewAddApp()}/>
          </Tooltip>
        </MenuItem>       
      </Menu>
    </>
  )
}
