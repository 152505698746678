export const  initialState={

}

const Index =(state=initialState,action)=>{
    switch (action.type) {
        case "helo":
            return{
                ...state,
            }
            
    
        default: return state
    }
}

export default Index