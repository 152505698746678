import * as TypePageAction from '../contant/pageUser'
import {toastSuccess,toastError} from '../helper/toastHelper'

let initialState = {
  //addAccount
  ValueComponent:null, 
  showForm:false,
  showFormUser:false, 
  //addAcount

  //department gov
  showFormDepartmentGov:false,
  showActionDepartmentGov:false,
  componentDepartmentGov:null,
  //department gov
  listUserGroupNhaNuoc:[],
  listUserGroupNhanVien:[],
  listUserGroupQuanTri:[],
  listUserGroupTuNhan:[],
  listUserGroupVip:[],
  listUserGroupDoiTac:[],
  id:'',  
  titleForm:'',  
  ValueFindId:{},
  componentForm:null,
  listAllUser:[],
  listAllInfoUser:[],
  listDepartment:[],
  ValueDepartment:{},  
}


const userPageReducer = (state=initialState,action)=>{
  switch (action.type) {
    //add Account
    case TypePageAction.HIDE_ACTION_FORM_USER:
      return{
        ...state,
        showForm:false               
      }

    case TypePageAction.SHOW_ACTION_FORM_USER:        
        return{
          ...state,          
          showForm:true,         
        }

    case TypePageAction.SHOW_FORM_USER:
      //khoi tao lay du lieu db
      return{
        ...state,
        showFormUser:true
      }

    case TypePageAction.SHOW_COMPONENT_FORM_USER:   
    return{
      ...state,
      ValueComponent:action.payload.component      
    }
    //add account

    //department gov
    case TypePageAction.HIDE_FORM_DEPARTMENT_GOV:
      return{
        ...state,
        showFormDepartmentGov:false               
      }

    case TypePageAction.SHOW_ACTION_DEPARTMENT_GOV:
      return{
        ...state,
        showFormDepartmentGov:true
      }

    case TypePageAction.SHOW_FORM_DEPARTMENT_GOV:        
        return{
          ...state,          
          showFormDepartmentGov:true,         
        }   


    case TypePageAction.SHOW_COMPONENT_DEPARTMENT_GOV:   
    return{
      ...state,
      componentDepartmentGov:action.payload.component      
    }

    case TypePageAction.HIDE_FORM_DEPARTMENT_GOV_WHEN_SUBMIT:     
      return{
        ...state,
        showFormDepartmentGov:false,
        componentDepartmentGov:null               
      }
    //department gov


    case TypePageAction.DO_NOT_ADD_FORM:
      toastError('Không còn tài khoản để thêm')
      return{
        ...state
      }

    case TypePageAction.GET_LIST_GROUP_NHA_NUOC:
      let {data} = action.payload     
      return{
        ...state,
        listUserGroupNhaNuoc:data,
        DBInfo:state.DBInfo       
      }
   
    case TypePageAction.GET_LIST_ALL_USER:    
      //nha nuoc
      let NhaNuoc = action.payload.data.filter(item=>item.idGroup===5)
      let QuanTri = action.payload.data.filter(item=>item.idGroup===1)
      let TuNhan = action.payload.data.filter(item=>item.idGroup===4)
      let NhanVien = action.payload.data.filter(item=>item.idGroup===2)
      let Vip = action.payload.data.filter(item=>item.idGroup===3)
      let DoiTac = action.payload.data.filter(item=>item.idGroup===6)
      // console.log(NhaNuoc,'nhanuoc')  
      // console.log(QuanTri,'quantri')  
      // console.log(TuNhan,'tunhan')  
      // console.log(NhanVien,'nhanvien')  
      // console.log(Vip,'vip')  
      // console.log(state.listAllInfoUser,'nhanuoc')  
        
      return{
        ...state,
        listAllUser:action.payload.data,
        listUserGroupNhaNuoc:NhaNuoc,
        listUserGroupNhanVien:NhanVien,
        listUserGroupQuanTri:QuanTri,
        listUserGroupTuNhan:TuNhan,
        listUserGroupVip:Vip,
        listUserGroupDoiTac:DoiTac,
        listAllInfoUser:state.listAllInfoUser
      }
    
    case TypePageAction.HIDE_FORM_USER:
        return{
          ...state,
          showFormUser:false,
          showForm:false,
          ValueComponent:null,   
          ValueFindId:{},
          id:''
        } 


    case TypePageAction.ADD_FORM_INFO_USER:      
       return{
         ...state,
         showFormUser:false,
         listUserGroupNhaNuoc:[],
         ValueComponent:null,
         showForm:false
       }    

     

    case TypePageAction.ADD_FORM_DEPARTMENT:      
      return {
        ...state,
        showFormUser:false,        
        ValueComponent:null,
        showForm:false,        
      }

    case TypePageAction.ADD_FORM_DEPARTMENT_SUCCESS:
    toastSuccess('Thêm dữ liệu ok')  
    return{
        ...state,
        showFormUser:false,        
        ValueComponent:null,
        showForm:false,
        listDepartment:[]            
      }

    case TypePageAction.ADD_FORM_INFO_USER_FAIL:
      toastError('Lỗi, kiểm tra lại!')
      return{
        ...state,
        showFormUser:false,
         listDepartment:[],
         ValueComponent:null,
         showForm:false         
      }

    case TypePageAction.BEGIN_INSTALL_ID:
      return{
        ...state
      }


    case TypePageAction.CHECK_EXIT_ID:
        return{
          ...state,
          id:action.payload.id                  
        }

      case TypePageAction.BEGIN_INSTALL_FORM:
        return{
          ...state
        }
     

        case TypePageAction.INFO_USER:
          return{
            ...state,
            title:action.payload.title,
            form:action.payload.form,
            name:action.payload.name     
          }

        case TypePageAction.GET_LIST_ALL_INFO_USER:       
          return{
            ...state,
            listAllInfoUser:action.payload.data
          }
     
          case TypePageAction.GET_LIST_DEPARTMENT:            
            return{
              ...state,
              listDepartment:action.payload.data
            }

      case TypePageAction.ADD_FORM_INFO_USER_SUCCESS:
        toastSuccess('Thêm mới dữ liệu thành công')
        return{
          ...state,
          title:'',
          form:'',
          name:''
        }                         

    default: return state      
  }
}

export default userPageReducer