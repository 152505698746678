export const DISPATCH_GET_INFO_STAFF_TO_STORE = "DISPATCH_GET_INFO_STAFF_TO_STORE"
export const UPLOAD_FILE_TO_DB                = "UPLOAD_FILE_TO_DB"
export const UPDATE_INFO_STAFF                = "UPDATE_INFO_STAFF"
export const OPEN_UPDATE_INFO_STAFF_AGAIN                = "OPEN_UPDATE_INFO_STAFF_AGAIN"
export const CLOSE_UPDATE_INFO_STAFF_AGAIN                = "CLOSE_UPDATE_INFO_STAFF_AGAIN"

export const DISPATCH_GET_PAYMENT_STAFF_TO_STORE = "DISPATCH_GET_PAYMENT_STAFF_TO_STORE"
export const DISPATCH_GET_PAYMENT_STAFF          = "DISPATCH_GET_PAYMENT_STAFF"
export const GET_QRCODE_IN_ACCOUNT_LOGIN         = "GET_QRCODE_IN_ACCOUNT_LOGIN"
export const DISPATCH_GET_QRCODE_TO_STORE        = "DISPATCH_GET_GRCODE_TO_STORE"
export const CHAM_CONG_LOI                       = "CHAM_CONG_LOI"
export const CHAM_CONG_SUCCESS                       = "CHAM_CONG_SUCCESS"

export const CHAM_CONG                           = "CHAM_CONG"
export const GET_CHAM_CONG                       = "GET_CHAM_CONG"
export const DISPATCH_GET_CHAM_CONG_TO_STORE     = "DISPATCH_GET_CHAM_CONG_TO_STORE"
export const DELETE_BAO_NGHI_CHAM_CONG           = "DELETE_BAO_NGHI_CHAM_CONG"
export const UPDATE_CHAM_CONG_NUMBER_1           = "UPDATE_CHAM_CONG_NUMBER_1" //cap nhat gio ca 1



export const GET_LIST_SUPPLIES_MATERIAL_COMMODITY_BY_USER_ID = "GET_LIST_SUPPLIES_MATERIAL_COMMODITY_BY_USER_ID"
export const DISPATCH_GET_LIST_MANY_ITEM_TO_STORE = "DISPATCH_GET_LIST_MANY_ITEM_TO_STORE"

export const OPEN_ADD_IMPORT_REPO                    = "OPEN_ADD_IMPORT_REPO"
export const CLOSE_ADD_IMPORT_REPO                   = "CLOSE_ADD_IMPORT_REPO"

export const ADD_IMPORT_FROM_STAFF                   = "ADD_IMPORT_FROM_STAFF"
export const DISPATCH_ADD_IMPORT_FROM_STAFF_TO_STORE = "DISPATCH_ADD_IMPORT_FROM_STAFF_TO_STORE"

export const OPEN_EDIT_IMPORT_FROM_STAFF             = "OPEN_EDIT_IMPORT_FROM_STAFF"
export const CLOSE_EDIT_IMPORT_FROM_STAFF            = "CLOSE_EDIT_IMPORT_FROM_STAFF"

export const EDIT_IMPORT_FROM_STAFF                  = "EDIT_IMPORT_FROM_STAFF"
export const DELETE_EDIT_IMPORT_STAFF                = "DELETE_EDIT_IMPORT_STAFF"

export const GET_LIST_IMPORT_BY_STAFF                = "GET_LIST_IMPORT_BY_STAFF"
export const DISPATCH_LIST_IMPORT_BY_STAFF_TO_STORE  = "DISPATCH_LIST_IMPORT_BY_STAFF_TO_STORE"

export const GET_LIST_TASK_JOB_BY_STAFF              = "GET_LIST_TASK_JOB_BY_STAFF"

export const DISPATCH_LIST_TASK_JOB_BY_STAFF_TO_STORE= "DISPATCH_LIST_TASK_JOB_BY_STAFF_TO_STORE"

export const CONVERT_READY_TO_PROCESS_TASK_JOB       = "CONVERT_READY_TO_PROCESS_TASK_JOB"

export const UPDATE_EDIT_DEPARTMENT                  = "UPDATE_EDIT_DEPARTMENT"

export const DISPATCH_VALUE_PROCESS_TO_STORE         = "DISPATCH_VALUE_PROCESS_TO_STORE"

export const DISPATCH_LIST_TASK_JOB_UPDATE           = "DISPATCH_LIST_TASK_JOB_UPDATE"
export const CONVERT_READY_TO_PROCESS_TASK_JOB_TO_SAGA = "CONVERT_READY_TO_PROCESS_TASK_JOB_TO_SAGA"

export const GET_LIST_AUTO_IMPORT                    = "GET_LIST_AUTO_IMPORT"
export const DISPATCH_LIST_AUTO_IMPORT_TO_STORE      = "DISPATCH_LIST_AUTO_IMPORT_TO_STORE"

export const STAFF_SELECT_TASK_JOB                   = "STAFF_SELECT_TASK_JOB"

export const OPEN_ADD_TASK_JOB                       = "OPEN_ADD_TASK_JOB"
export const CLOSE_ADD_TASK_JOB                      = "CLOSE_ADD_TASK_JOB"
export const CONVERT_READY_TO_COMPLETE_TASK_JOB      = "CONVERT_READY_TO_COMPLETE_TASK_JOB"
export const DELETE_TASK_JOB_BY_STAFF                = "DELETE_TASK_JOB_BY_STAFF"

export const DISPATCH_ITEM_VALUE_PESONAL_TO_STORE    = "DISPATCH_ITEM_VALUE_PESONAL_TO_STORE"
export const DISPATCH_UPDATE_ITEM_VALUE_PERSON       = "DISPATCH_UPDATE_ITEM_VALUE_PERSON"
export const DISPATCH_LIST_TASK_JOB_PESONAL_TO_STORE = "DISPATCH_LIST_TASK_JOB_PESONAL_TO_STORE"
export const VALUE_PROCESS_TO_HANDLE                 = "VALUE_PROCESS_TO_HANDLE" 
export const GET_LIST_TASK_JOB_STAFF_BY_MONTH        = "GET_LIST_TASK_JOB_STAFF_BY_MONTH"
export const NAME_STATUS                             = "NAME_STATUS"
export const FIND_BY_CUSTOMER                        = "FIND_BY_CUSTOMER"
export const DEFAULT_VALUE_SWITCH_LOCK_ICON          = "DEFAULT_VALUE_SWITCH_LOCK_ICON"

export const OPEN_ADD_PAY_FOR_JOB_TASK               = "OPEN_ADD_PAY_FOR_JOB_TASK" //cong tac phi
export const CLOSE_ADD_PAY_FOR_JOB_TASK              = "CLOSE_ADD_PAY_FOR_JOB_TASK" //cong tac phi

export const ADD_PAY_FOR_JOB_TASK                    = "ADD_PAY_FOR_JOB_TASK"
export const DISPATCH_ADD_PAY_FOR_JOB_TASK_TO_STORE  = "DISPATCH_ADD_PAY_FOR_JOB_TASK_TO_STORE"
export const DISPATCH_FIND_BY_MONTH_PAY_JOB_STAFF    = "DISPATCH_FIND_BY_MONTH_PAY_JOB_STAFF"

export const UPDATE_TASK_JOB_BY_STAFF                = "UPDATE_TASK_JOB_BY_STAFF"

export const OPEN_ADD_MORE_DEPARTMENT                = "OPEN_ADD_MORE_DEPARTMENT"
export const CLOSE_ADD_MORE_DEPARTMENT               = "CLOSE_ADD_MORE_DEPARTMENT"

export const SAVE_ADD_MORE_DEPARTMENT                = "SAVE_ADD_MORE_DEPARTMENT"
export const DISPATCH_VALUE_ADD_DEPARTMENT_UPDATE_TO_STORE          = "DISPATCH_VALUE_ADD_DEPARTMENT_UPDATE_TO_STORE"

export const OPEN_EDIT_PAY_FOR_JOB_TASK              = "OPEN_EDIT_PAY_FOR_JOB_TASK"
export const CLOSE_EDIT_PAY_FOR_JOB_TASK             = "CLOSE_EDIT_PAY_FOR_JOB_TASK"
export const EDIT_PAY_FOR_JOB_TASK                   = "EDIT_PAY_FOR_JOB_TASK"


//tong hop
export const TYPE_PAYMENT_SELECT_PAY_TASK_JOB        = "TYPE_PAYMENT_SELECT_PAY_TASK_JOB"
export const DISPATCH_MONTH_TASK_JOB                 = "DISPATCH_MONTH_TASK_JOB"

export const ADD_TASK_JOB_BY_STAFF                   = "ADD_TASK_JOB_BY_STAFF"
export const DISPATCH_ADD_STAFF_JOB_TO_STORE         = "DISPATCH_ADD_STAFF_JOB_TO_STORE"

export const DISPATCH_MONTH_SELECT_TO_STORE          = "DISPATCH_MONTH_SELECT_TO_STORE"
export const DISPATCH_FIND_NAME_TASK_TO_STORE        = "DISPATCH_FIND_NAME_TASK_TO_STORE"
export const DISPATCH_FIND_BY_ID                     = "DISPATCH_FIND_BY_ID"

export const OPEN_SHOW_IMG                           = "OPEN_SHOW_IMG"
export const CLOSE_SHOW_IMG                          = "CLOSE_SHOW_IMG"

export const VALUE_PROCESS_TO_HANDLE_EMPTY           = "VALUE_PROCESS_TO_HANDLE_EMPTY"

export const OPEN_TRA_CUU  = "OPEN_TRA_CUU"
export const CLOSE_TRA_CUU = "CLOSE_TRA_CUU"