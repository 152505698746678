import * as TypeLoading from '../contant/uiLoading'


var initialState = {
  loading:false,

}

const loading = (state=initialState,action)=>{
      switch (action.type) {
        case TypeLoading.LOADING_SUCCESS:
              return{   
               ...state,               
                loading:true,                
              }

        case TypeLoading.LOADING_FAIL:
          return{       
            ...state,
            loading:false          
          }

        case TypeLoading.SHOW_SIDEBAR:
          return{
            ...state,
            showSidebar:true
          }

        case TypeLoading.HIDE_SIDEBAR:
          return {
            ...state,
            showSidebar:false
          }
      
        default: return  state          
        
      }
}

export default loading