import React,{useContext} from 'react';
import {useStyles} from './styles'
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper} from '@material-ui/core'
import {GovMain} from '../../Admin/AdminHomePage/index'


export default function StickyHeadTable() {
  const classes = useStyles();  
  const arrGov  = useContext(GovMain)

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
                <TableCell>Tên Account</TableCell>
                <TableCell>Nhóm tài khoản</TableCell>
                <TableCell>Trạng thái</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {arrGov.map((v,i)=>{
              return(
                 <TableRow key={i}>
                  <TableCell>{v.name}</TableCell>
                  <TableCell>{v.room}</TableCell>
                  <TableCell className={classes.colorTextOnline}>online</TableCell>
                </TableRow>
              )              
            })}               
          </TableBody>
        </Table>
      </TableContainer>     
    </Paper>
  );
}
