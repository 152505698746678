import React from 'react';
import { ImgLine, LineIn ,SpaceIn} from './style';
import imgIcon from './icon.png'
const Line = () => {
    return (
           <SpaceIn>
                        <LineIn>
                        </LineIn>                
                        <ImgLine  src={imgIcon} alt="nutrkids-nutrikid"/>
           </SpaceIn>        
    );
};

export default Line;