import React from 'react';
import { MainChooseUs, Round,TextChooseUs,ContentRow,RowLeft,RowRight,
InLeft,InRight, TextOne, InRightLeft, InRightRight, TextTwo,InLeftMobile, IconMobileLeft, InRightLeftMobile, InRightRightMobile, ContentRowTwo, MainBox,
} from './style';
import LineCompoent from '../FnComponent/Line'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import MoodIcon from '@material-ui/icons/Mood'
import { Box } from '@material-ui/core'
// import KidTopLogo from '../FnComponent/kidtopLogo.jpg'
// import YouTuBeIcon from '../FnComponent/youtubeIcon.png'

const WhyChooseUs = () => {
    return (
        <MainChooseUs id="CHOOSE_US">
            <TextChooseUs >TẠI SAO BẠN NÊN CHỌN CHÚNG TÔI</TextChooseUs>
            <LineCompoent/>            
                  <MainBox>                 
                    <Box display="flex" justifyContent="center">
                            <ContentRow>
                                <RowLeft>
                                    <InLeft>
                                            <SupervisedUserCircleIcon style={{fontSize:120,color:'white'}}/>
                                    </InLeft>

                                    <InLeftMobile>
                                            <IconMobileLeft><SupervisedUserCircleIcon style={{fontSize:50,color:'white'}}/></IconMobileLeft>
                                    </InLeftMobile>

                                    <InRight>
                                        <TextOne>CHUYÊN GIA TƯ VẤN</TextOne>
                                    </InRight>
                                </RowLeft>
                                <Round></Round> 
                                <RowRight> 
                                    {/* mobile */}
                                    <InRightLeftMobile>
                                        <IconMobileLeft>
                                                <ContactPhoneIcon style={{fontSize:50,color:'white'}}/>
                                        </IconMobileLeft>
                                    </InRightLeftMobile>
                                    <InRightRightMobile>
                                                <TextTwo>HỖ TRỢ 24/7</TextTwo>
                                    </InRightRightMobile>
                                    {/* mobile */}

                                    {/* pc */}
                                    <InRightLeft>
                                        <TextTwo>HỖ TRỢ 24/7</TextTwo>
                                    </InRightLeft>     
                                    <InRightRight>
                                    <ContactPhoneIcon style={{fontSize:100,color:'white',marginLeft:32,marginTop:15}}/>
                                    </InRightRight>   
                                    {/* pc */}
                                </RowRight>                                                                       
                            </ContentRow>  
                    </Box>                   
                    
                    <Box display="flex" justifyContent="center">
                            <ContentRowTwo>
                                                <RowLeft>
                                                    <InLeft>
                                                            <GroupWorkIcon style={{fontSize:120,color:'white'}}/>
                                                    </InLeft>

                                                    <InLeftMobile>
                                                            <IconMobileLeft><GroupWorkIcon style={{fontSize:50,color:'white'}}/></IconMobileLeft>
                                                    </InLeftMobile>

                                                    <InRight>
                                                        <TextOne >ĐỘI NGŨ NHIỆT HUYẾT</TextOne>
                                                    </InRight>
                                                </RowLeft>
                                                <Round></Round> 

                                                <RowRight> 

                                                    <InRightLeftMobile>
                                                        <IconMobileLeft>
                                                                <MoodIcon style={{fontSize:50,color:'white'}}/>
                                                        </IconMobileLeft>
                                                    </InRightLeftMobile>
                                                    <InRightRightMobile>
                                                                <TextTwo>94% HÀI LÒNG</TextTwo>
                                                    </InRightRightMobile>
                                                


                                                    <InRightLeft>
                                                        <TextTwo>94% HÀI LÒNG</TextTwo>
                                                    </InRightLeft>     
                                                    <InRightRight>
                                                    <MoodIcon style={{fontSize:100,color:'white',marginLeft:32,marginTop:15}}/>
                                                    </InRightRight>  

                                                </RowRight>                                           
                            </ContentRowTwo>  
                    </Box>

                 </MainBox>     
            <LineCompoent/>   
        </MainChooseUs>
    );
};

export default WhyChooseUs; 