import {  makeStyles } from '@material-ui/core/styles';

export const useStyles =makeStyles(theme=>({  
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor:'pointer',
    fontFamily:'TimeNewRoman',
    background:"#7ed6df"
  },
}))