import axiosServices from '../../api-services/api'
import {API_ENDPOINT}from '../../contant'

const UrlAddManagerActiveAccount            = "managerAccountActive"
const UrlListManagerActiveAccount           = "managerAccountActive"
const UrlAddListManagerActiveAccount        = "managerAccountActive/Add"
const UrlUpdateListManagerActiveAccount     = "managerAccountActive/Update"
const UrlActiveAllAccount                   = "managerAccount/ActiveAll"
const UrlGetActiveAllAccountById            = "managerAccount/GetActiveAll"
const UrlFindUserName                       = 'managerAccount/FindUserName'
const UrlFindMenuWeeklyByUserAndDate        = "managerAccount/FindMenuWeeklyByUserAndDate"
const UrlAddApprrovalCheckDayFood           = "managerAccount/AddApprovalCheckDayFood"
const UrlListApprovalCheckDayFood           = "managerAccount/ListApprovalCheckDayFood"
const UrlListApprovalByUserIdDate           = "managerAccount/ListApprovalByUserIdDate"
const UrlListApprovalUpdate                 = "managerAccount/ListApprovalUpdate"

const UrlItemManagerAccountByUserId         = "managerAccount/ManagerAccountByUserId"
const UrlFindCheckDayFoodByUserIdAndDate    = "managerAccount/FindCheckDayFoodByUserIdAndDate"
const UrlChangeUpdateCheckDayFood           = "managerAccount/ChangeUpdateCheckDayFood"
const UrlAddOrUpdateCheckDayFood            = "managerAccount/AddOrUpdateCheckDayFood"
const UrtGetInfoListOfUser                  = "managerAccount/GetInfoListOfUser"

const UrlCheckToAddMenuWeelies              = "managerAccount/CheckToAddMenuWeelies"

const UrlGetStudentLeaveTemporatyByClass    = "managerAccount/GetStudentLeaveTemporatyByClass"
const UrlGetListClassStudentLeaveTemporaty  = "managerAccount/GetListClassStudentLeaveTemporaty"

const UrlAddStuSendMess                     = "managerAccount/AddStuSendMess"
const UrlUpdateStuSendMess                  = "managerAccount/UpdateStuSendMess"

const UrlGetListStuSendMess                 = "managerAccount/GetListStuSendMess"
const UrlGetItemStuSendMess                 = "managerAccount/GetItemStuSendMess"

const UrlGetInfoMessageAuto                 = "managerAccount/GetInfoMessageAuto"
const UrlUpdateInfoMessageAuto              = "managerAccount/UpdateInfoMessageAuto"

const UpdateManagerAccountClass             = 'managerAccount/UpdateManagerAccountClass'
const UrlFindItemClassSendSmsZaloPrvMonth   = 'managerAccount/FindItemClassSendSmsZaloPrvMonth'

const UrlAddKhoanThu                        = 'managerAccount/AddKhoanThu'
const UrlGetKhoanThu                        = 'managerAccount/GetKhoanThu'
const UrlAddUpdateStudentRegisKhoanThu      = 'managerAccount/AddUpdateStudentRegisKhoanThu'
const UrlGetStudentRegisKhoanThu            = 'managerAccount/GetStudentRegisKhoanThu'
const UrlUpdateStudentRegisKhoanThu         = 'managerAccount/UpdateStudentRegisKhoanThu'
const UrlGetAllListStudentKhoanThu          = 'managerAccount/GetAllListStudentKhoanThu'

export const GetAllListStudentKhoanThuApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlGetAllListStudentKhoanThu}`,item)
}

export const UpdateStudentRegisKhoanThuApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlUpdateStudentRegisKhoanThu}`,item)
}

export const GetStudentRegisKhoanThuApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlGetStudentRegisKhoanThu}`,item)
}

export const AddUpdateStudentRegisKhoanThuApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlAddUpdateStudentRegisKhoanThu}`,item)
}

export const GetKhoanThuApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlGetKhoanThu}`,item)
}

export const AddKhoanThuApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${UrlAddKhoanThu}`,item)
}

export const UpdateManagerAccountClassApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UpdateManagerAccountClass}`,item)
}

export const AddManagerActiveAccount = (item) =>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlAddManagerActiveAccount}`,item)
}

export const ListManagerActiveAccount = () =>{
  return axiosServices.get(`${API_ENDPOINT}/${UrlListManagerActiveAccount}`)
}

export const AddListManagerActiveAccount =(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlAddListManagerActiveAccount}`,item)
}

export const UpdateManagerActiveAccount =(item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${UrlUpdateListManagerActiveAccount}`,item)
}

export const ActiveAllAccount=(item)=>{
    return axiosServices.put(`${API_ENDPOINT}/${UrlActiveAllAccount}`,item)
}

export const GetActiveAllAccountById =(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlGetActiveAllAccountById}`,item)
}

export const FindUserName =(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlFindUserName}`,item)
}

export const FindMenuWeeklyByUserAndDate=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${UrlFindMenuWeeklyByUserAndDate}`,item)
}

export const AddApprovalCheckDayFood =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${UrlAddApprrovalCheckDayFood}`,item)
}

export const ListApprovalCheckDayFood=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${UrlListApprovalCheckDayFood}`,item)
}
export const ListApprovalByUserIdDate=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${UrlListApprovalByUserIdDate}`,item)
}

export const ListApprovalUpdate=(item)=>{
    return axiosServices.put(`${API_ENDPOINT}/${UrlListApprovalUpdate}`,item)
}

export const ItemManagerAccountByUserId=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${UrlItemManagerAccountByUserId}`,item)
}

export const FindCheckDayFoodByUserIdAndDate=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlFindCheckDayFoodByUserIdAndDate}`,item)
}

export const ChangeUpdateCheckDayFood=(item)=>{
    return axiosServices.put(`${API_ENDPOINT}/${UrlChangeUpdateCheckDayFood}`,item)
}

export const AddOrUpdateCheckDayFood=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${UrlAddOrUpdateCheckDayFood}`,item)
}

export const GetInfoListOfUser=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrtGetInfoListOfUser}`,item)
}

export const CheckToAddMenuWeelies=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${UrlCheckToAddMenuWeelies}`,item)
}

export const GetStudentLeaveTemporatyByClass =(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlGetStudentLeaveTemporatyByClass}`,item)
}

export const GetListClassStudentLeaveTemporaty=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlGetListClassStudentLeaveTemporaty}`,item)
}

export const AddStuSendMessApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlAddStuSendMess}`,item)
}

export const UpdateStuSendMessApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlUpdateStuSendMess}`,item)
}

export const GetListStuSendMessApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlGetListStuSendMess}`,item)
}

export const GetItemStuSendMessApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlGetItemStuSendMess}`,item)
}

export const GetInfoMessageAutoApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlGetInfoMessageAuto}`,item)
}

export const UpdateInfoMessageAutoApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlUpdateInfoMessageAuto}`,item)
}

export const FindItemClassSendSmsZaloPrvMonthApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${UrlFindItemClassSendSmsZaloPrvMonth}`,item)
}