import {fork,all} from 'redux-saga/effects'
import MaterialSaga from './material'
import MealSaga from './meal'
import NutrikidSaga from './nutrikid'
import TableAgeSaga from './tableAge'
import LibraryMealSaga from './libraryMeal'
import KiemThucSaga from './kiemThuc'
import ThanhToanSaga from './thanhToan'
import CreateAutoWebMamNon from '../../WebSiteMamNon/Saga'


function* IndexSaga(){
    yield all(
        [
           yield fork(MaterialSaga),
           yield fork(MealSaga),
           yield fork(NutrikidSaga),
           yield fork(TableAgeSaga),
           yield fork(LibraryMealSaga),
           yield fork(KiemThucSaga),
           yield fork(ThanhToanSaga),
           yield fork(CreateAutoWebMamNon)
        ]
    )
}

export default IndexSaga