export const HanldeDupplicate=(arr)=>{
    let arrIdClass = arr.map(v=>v.idClass)
    let Dupp = [...new Set(arrIdClass)]
   return Dupp.map(v=>{
        let index = arr.findIndex(vv=>vv.idClass===v)
        if(index>=0){
            return{
                ...arr[index]
            }
        }   
    })
}

export const handleNewItemListStuMessSend=(Item,newItem)=>{    
    return [Item].map(v=>{
        return{
            ...v,
            listStudent:handleNewListStu(newItem[0].listStudent)
        }
    })[0]
}

const handleNewListStu=(list)=>{
        return  list.map(v=>{
            return{
                ...v,
                sendSms:v.sendSms==='YESYES'?'YES':'NO'
            }
        })
}