import React, { Suspense, useEffect, useState } from 'react';
import {Button,TextField,DialogActions,DialogContent,Grid,FormHelperText} from '@material-ui/core'
import  {AccountCircle,Person,Work,PersonOutline}from '@material-ui/icons'
import {useStyles} from './styles'
import {useDispatch,useSelector} from 'react-redux'
import {UPDATE_FORM_SIGN_REGIS} from '../../../../../contant/Gov/login'

const Index= React.memo(({handleClose})=> {  
  const ListPrices                           = useSelector(state=>state.GovLogin.ListInfoUserDetail)
  const dispatch                             = useDispatch()
  const userId                               = useSelector(state=>state.GovLogin.userId)
  const ItemFormSignRegis                    = useSelector(state=>state.GovLogin.ItemFormSignRegis)
  const classes                              = useStyles()
  const [master,setMaster]                   = useState(ItemFormSignRegis.master)
  const [accounter,setAccounter]             = useState(ItemFormSignRegis.accounter)
  const [secondaryMaster,setsecondaryMaster] = useState(ItemFormSignRegis.secondaryMaster)
  const [chef,setChef]                       = useState(ItemFormSignRegis.chef)
  const [finance,setFinance]                 = useState(ItemFormSignRegis.finance)
  const [NguoiTruc,setNguoiTruc]             = useState(()=>localStorage.getItem('NguoiTruc'))
  const [valueNguoiTruc,setValueNguoiTruc]   = useState(null)
  const [nameNguoiTruc,setNameNguoiTruc]     = useState(null)

  useEffect(()=>{
    let value = JSON.parse(localStorage.getItem('NguoiTruc'))   
    if(value!==null){
       setNguoiTruc(value)
       let filterNguoiTruc = value.filter(v=>v.userId===userId)
       if(filterNguoiTruc.length!==0){
                setNameNguoiTruc(filterNguoiTruc[0].nguoiTruc)
       }
    }
  },[valueNguoiTruc])

  const handleChangeNguoiTruc=(e)=>{
    let item= {
      userId,
      type:ListPrices[0].typeOfCountEat===0?0:1,//0 mn,1:th
      nguoiTruc:e.target.value
    }
    setValueNguoiTruc(item)
  }
  const SaveIt=()=>{
      let item ={
        master,
        accounter,
        secondaryMaster,
        chef,
        finance,
        userId,
        _id:ItemFormSignRegis._id
      }
      // if(   item.master          ===ItemFormSignRegis.master&&
      //       item.accounter       ===ItemFormSignRegis.accounter&&
      //       item.secondaryMaster ===ItemFormSignRegis.secondaryMaster&&
      //       item.chef            ===ItemFormSignRegis.chef&&
      //       item.finance         ===ItemFormSignRegis.finance  
      //   ){         
      //     handleClose()
      //     return true
      //   }        
         if(
           //item.master         === ItemFormSignRegis.master&&
        //   item.accounter       === ItemFormSignRegis.accounter&&
        //   item.secondaryMaster === ItemFormSignRegis.secondaryMaster&&
        //   item.chef            === ItemFormSignRegis.chef&&
        //   item.finance         === ItemFormSignRegis.finance&&
          valueNguoiTruc       !== null&&
          NguoiTruc            === null
          ){       
            localStorage.setItem('NguoiTruc',JSON.stringify([valueNguoiTruc]))      
         }        
        
        if(NguoiTruc!==null){
          if(valueNguoiTruc!==null){              
           let filterNguoiTruc = NguoiTruc.filter(v=>v.userId!==valueNguoiTruc.userId)
           let result = filterNguoiTruc.concat(valueNguoiTruc)           
           localStorage.removeItem('NguoiTruc')
           localStorage.setItem('NguoiTruc',JSON.stringify(result))
          }
        }
        dispatch({
          type:UPDATE_FORM_SIGN_REGIS,
          payload:{item}
        })
        handleClose()        
  }

  return (      
    <Suspense fallback={()=>{}}>         
         <DialogContent> 
           <Grid container>
          <div className={classes.margin}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
              <AccountCircle />
            </Grid>
            <Grid item>
              <TextField id="input-with-icon-grid" 
                        onChange={e=>setMaster(e.target.value)}
                        type="text"
                        defaultValue={ItemFormSignRegis.master}
                        multiline
              />
              <FormHelperText id="filled-weight-helper-text">Hiệu trưởng</FormHelperText>
            </Grid>
          </Grid>
        </div>

        <div className={classes.margin}>        
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <AccountCircle />
              </Grid>
              <Grid item>
                <TextField id="input-with-icon-grid" 
                          onChange={e=>setAccounter(e.target.value)}
                          defaultValue={ItemFormSignRegis.accounter}  
                          type="text"
                   
                />
                <FormHelperText id="filled-weight-helper-text">Kế toán</FormHelperText>
              </Grid>
            </Grid>
        </div>

            <div className={classes.margin}>   
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <Person />
                  </Grid>
                  <Grid item>
                    <TextField id="input-with-icon-grid" 
                              onChange={e=>setsecondaryMaster(e.target.value)}
                              defaultValue={ItemFormSignRegis.secondaryMaster}              
                              multiline        
                              type="text"
                    />
                    <FormHelperText id="filled-weight-helper-text">
                      {ListPrices[0].typeOfCountEat===0?"Phó Hiệu trưởng":" 0 Phó Hiệu Trưởng / 1 Y tế"}
                    </FormHelperText>
                  </Grid>
                </Grid>
            </div>

           <div className={classes.margin}>   
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                  <Work />
                </Grid>
                <Grid item>
                  <TextField id="input-with-icon-grid" 
                            onChange={e=>setFinance(e.target.value)}
                            defaultValue={ItemFormSignRegis.finance}              
                            multiline        
                            type="text"
                  />
                  <FormHelperText id="filled-weight-helper-text">Thủ Quỹ</FormHelperText>
                </Grid>
              </Grid>
            </div>

              <div className={classes.margin}>   
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <PersonOutline/>
                  </Grid>
                  <Grid item>
                    <TextField id="input-with-icon-grid" 
                              onChange={e=>setChef(e.target.value)}
                              defaultValue={ItemFormSignRegis.chef}              
                              multiline      
                              type="text"  
                    />
                    <FormHelperText id="filled-weight-helper-text">Bếp trưởng</FormHelperText>
                  </Grid>
                </Grid>
          </div> 


          {/* nguoi truc */}
          {ListPrices[0].typeOfCountEat===0?<></>:
             <div className={classes.margin}>
              <Grid container spacing={1} alignItems="flex-end">
                      <Grid item>
                        <PersonOutline/>
                      </Grid>
                      <Grid item>
                        <TextField id="input-with-icon-grid" 
                                  onChange={handleChangeNguoiTruc}
                                  defaultValue={nameNguoiTruc}              
                                  multiline      
                                  type="text"  
                        />
                        <FormHelperText id="filled-weight-helper-text">Người Trực</FormHelperText>
                      </Grid>
              </Grid> 
           </div> 
           } 
                  
          </Grid>
        </DialogContent>
           
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="secondary">
            Thoát
          </Button>
         <Button variant="outlined" onClick={()=>SaveIt()} color="secondary">
            Cập nhật
          </Button>                 
        </DialogActions>
    </Suspense>
  );
})

export default Index
