export const TITLE_BLOCK                 ="Danh mục các khối lớp"
export const BUTTON_CLOSE                ="Đóng"
export const BUTTON_DETAIL               ="Chi tiết"
export const NAME_TABLE_BLOCK_HEAD       ="Tên các khối"
export const NAME_CLASS                  ="tên lớp"
export const NAME_TABLE_ACTION_HEAD      ="Thao tác"
export const NAME_TITLE_ADD_FORM_BLOCK   ="Thêm danh mục khối lớp"
export const NAME_TITLE_INPUT_FORM_BLOCK ="Tên Khối"
export const LABEL_INPUT_ADD_FORM_BLOCK  ="Nhập tên khối"
export const ALERT_TEXT_INPUT_EMPTY      ="chưa nhập liệu"
export const ALERT_TEXT_INPUT_LENGTH_10  ="ít nhất 10 ký tự"
export const LABEL_INPUT_ADD_FORM_CLASS  ="Nhập tên lớp"
export const ALERT_EXIT_NAME_BLOCK_CLASS ="tên này đã tồn tại, nhập lại tên khác"
export const ADD_BLOCK_SUCCESS_ALERT     ="thêm dữ liệu thành công"
export const ADD_CLASS_SUCCESS_ALERT     = "Thêm Thành công, ấn nút quay lại để cập nhật dữ liệu"


//student
export const LIST_TITLE_STUDENT           = "danh sách học sinh"
export const NAME_STUDENT                 = "họ tên học sinh"
export const UPDATE_NAME_STUDENT          = "cập nhật tên học sinh"
export const CREATE_DATE                  = "ngày khởi tạo"
export const ACTION_TABLE                 = "thao tác"
export const TITLE_ADD_STUDENT            = "thêm học sinh"
export const NUMBER_ROW                   = "stt"

//year school
export const ALERT_CREATE_YEAR_SCHOOL     = "dữ liệu đã được khởi tạo!"
export const NAME_YEAR_SCHOOL             = "tên năm học!"

export const ALERT_DUPLICATE_DATA_CONTINOUS = "lưu ý! tên này đã được tạo, bạn có chắc muốn tiếp tục"
export const ALERT_DUPLICATE_DATA_CANCEL    = "lưu ý! tên này đã được tạo!"

export const ALERT_DELETE_DATA           = "dữ liệu sẽ bị xóa, bạn có chắc chắn"

//check Day Food
export const CHECK_DAY_FOOD               = "chấm ăn"
export const NOT_EAT_DAY_FOOD             = "báo vắng"
export const FINISH_FOOD_BUTTON           = "lưu"
export const CANCEL_CHECK_DAY_FOOD        = "hủy"
export const VIEW_CHECK_DAY_FOOD          = "xem"

export const ALERT_ADD_DATE_MEAL          = "bạn chưa tạo thực đơn cho ngày hôm nay, quay lại trang thực đơn tuần để khởi tạo!"
export const ALERT_ADD_DATE_MEAL_EMPTY    = "không có học sinh vắng, bạn có chắc chắn ?"
export const DUPLICATE_CHECK_DAY_FOOD     = "ngày khởi tạo đã tồn tại, không thể thêm được ?"
export const ALERT_VIEW_CHECK_DAY_FOOD    = "chọn ngày xem chấm ăn"
export const ALERT_EMPTY_CHECK_DAY_FOOD   = "ngày bạn chọn chưa lên thực đơn, vui lòng thêm thực đơn!"
export const ALERT_EMPTY_CHECK_DAY_FOOD_WEEK  = "ngày bạn chọn chưa thực hiện chấm ăn, vui lòng thực hiện chấm ăn!"

export const ALERT_EMPTY_STUDENT_LIST      = "không xem được, chưa tạo danh sách học sinh"
export const ALERT_UPDATE_CHECK_DATE_FOOD  = "cập nhật chấm ăn thành công"
export const ALERT_DO_NOT_CHECK_DATE_FOOD  = "ngày đang xem chưa chấm ăn, vui lòng chấm ăn"

export const LIST_MATERIAL_FOODS           = "danh sách các thực phẩm đi chợ"