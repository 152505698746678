import {put,call,select, takeLatest} from 'redux-saga/effects'
import * as TypeGov from '../../../contant/Gov/softFood'
import {addDateMeal,updateDateMeal,getListDateMealByUserId} from '../../../api-services/weeklyFood/dateMeal'
import {LIST_ALL_DATE_MEAL} from '../../../contant/Gov/student/dispatch'
import * as uiLoading from '../../../actions/uiLoading'
import * as actionGov from '../../../actions/Gov/weeklyMeal/dateInput'
import {dispatchListAllDateMeal,dispatchAddDateMealToStore} from '../../../actions/Gov/weeklyMeal/dateInput'
import {UpdateItemAfterEdit} from '../../../helper/ListTable/ListTable'

function* addDateMealSaga({payload}){      
      yield put(uiLoading.loadingSuccess())
     let res = yield call(addDateMeal,payload.data)     
      if(res.data.result==='success'){
        yield put(actionGov.addDateMealDbSuccess())       
        yield put(dispatchAddDateMealToStore(res.data.data))
      }else{
        yield put(actionGov.addDateMealDbFail())
      }
      yield put(uiLoading.loadingFail())
}

function* listAllDateMealSaga(){
  yield put(uiLoading.loadingFail())
  let userId = yield select(state=>state.GovLogin.userId)
  let id={id:userId}
  let res = yield call(getListDateMealByUserId,id)
  if(res.data.result==='success'){
    yield put(dispatchListAllDateMeal(res.data.data))
    yield put(uiLoading.loadingFail())
  }
}

function* updateDateMealSaga({payload}){
  yield put(uiLoading.loadingFail())
  let res = yield call(updateDateMeal,payload.data) 
  if(res.data.result==='success'){
    //yield put(dispatchListAllDateMeal(res.data.data))
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }  
}

function* insertDateSaga({payload}){
  //console.log(payload)
   let res = yield call(addDateMeal,payload.data)  
  // console.log(res) 
   let Value =res.data.data    
    let {list} = payload        
    let newList =UpdateItemAfterEdit(list,Value)      
    let value = newList.value.list   
    let data = newList.valueObject    
    yield put(actionGov.insertDateMealDb(value,data))
}

function* updateDateMealDiffSaga({payload}){
  yield put(uiLoading.loadingFail())
  let res = yield call(updateDateMeal,payload.data) 
  if(res.data.result==='success'){
    //yield put(dispatchListAllDateMeal(res.data.data))
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }  
}

function* dateInputSaga(){ 
  yield takeLatest(TypeGov.ADD_DATE_MEAL_DB, addDateMealSaga)  
  yield takeLatest(TypeGov.UPDATE_DATE_MEAL_DB, updateDateMealSaga)
  yield takeLatest(TypeGov.INSERT_DATE, insertDateSaga)
  yield takeLatest(LIST_ALL_DATE_MEAL,listAllDateMealSaga)
  yield takeLatest(TypeGov.UPDATE_DATE_MEAL_DB_DIFF, updateDateMealDiffSaga)

}

export default dateInputSaga