import { ALERT_REGIS_ACCOUNT,
    LOADING_START,LOADING_STOP,CLOSE_ALERT,GET_AUTO_IMPORT_PHAT_DAT, } from "../contant/GioiThieu";
    
import  {DISPATCH_MATERIAL_CATE_ALL_PHAT_DAT_TO_STORE,DISPATCH_IMG_DETAIL_PRODUCT_TO_STORE, DISPATCH_FIND_VALUE_IMG_TO_STORE, UPDATE_ITEM_DETAIL_PRODUCT} from '../contant/ThietBiCongNghe'
import {DISPATCH_ACCESS_TOKEN_TO_STORE} from '../contant/Zalo'



export const UpdateItemDetailProduct=(item)=>{
        return{
            type:UPDATE_ITEM_DETAIL_PRODUCT,
            payload:{item}
        }
}

export const DispatchFindValueImgToStore =(item)=>{
    return{
        type:DISPATCH_FIND_VALUE_IMG_TO_STORE,
        payload:{item}
    }
}

export const DispatchAccessTokenToStore =(item)=>{
    return {
        type:DISPATCH_ACCESS_TOKEN_TO_STORE,
        payload:{item}
    }
}

export const GetAutoImportPhatDatAction=()=>{
    return{
        type:GET_AUTO_IMPORT_PHAT_DAT,
    }
}

export const AlertResetAcountAction=(item)=>{
            return {
                type:ALERT_REGIS_ACCOUNT,
                payload:{item}
            
            }
}

export const LoadingStart = ()=>{
    return{
        type:LOADING_START
    }
}

export const LoadingStop =()=>{
    return{
        type:LOADING_STOP
    }
}

export const CloseAlert=()=>{
    return{
        type:CLOSE_ALERT
    }
}

export const DispatchMaterialCateAllPhatDatToStore=(item)=>{
        return{
            type:DISPATCH_MATERIAL_CATE_ALL_PHAT_DAT_TO_STORE,
            payload:{item}
        }
}

export const DispatchImgDetailProductToStore=(item)=>{
    return{
        type:DISPATCH_IMG_DETAIL_PRODUCT_TO_STORE,
        payload:{item}
    }
}