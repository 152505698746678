import * as TypeGov from '../../contant/Gov/login'
import {OPEN_FORM_CHECK_LOGIN,CLOSE_FORM_CHECK_LOGIN }from '../../contant/auth'
import {DISPATCH_LIST_INFO_USER_DETAIL,DISPATCH_ITEM_INFO_USER_DETAIL,EDIT_ITEM_INFO_USER_DETAIL,
  EDIT_ARR_INFO_USER_DETAIL,DISPATCH_DELETE_ITEM_INFO_USER_DETAIL,
DISPATCH_CLOSE_DELETE_ITEM_INFO_USER_DETAIL,DISPATCH_OPEN_DELETE_ITEM_INFO_USER_DETAIL,DELETE_SUCCESS,
OPEN_TEMPLETE,CLOSE_TEMPLETE,DISPATCH_ADD_TEMPLETE_TO_STORE,DISPATCH_LIST_ITEM_TO_STORE_TEMPLETE,SHOW_LIST_VIEW_TEMPLETE,CLOSE_SHOW_LIST_VIEW_TEMPLETE,
ADD_TEMPLETE_SUCCESS,EDIT_VIEW_TEMPLETE_TRUE,EDIT_VIEW_TEMPLETE_FALSE,EDIT_ITEM_TEMPLETE,
LOGIN_FAIL_ACCOUNT_ONLY,ALERT_LOGIN_FAIL_ACCOUNT_ONLY,OPEN_FORM_SIGN_REGIS,CLOSE_FORM_SIGN_REGIS,
ADD_FORM_SIGN_REGIS_SUCCESS_AND_DISPATCH_TO_STORE,DISPATCH_ITEM_FORM_REGIS_TO_STORE,
CLOSE_UPDATE_FORM_SIGN_REGIS,OPEN_UPDATE_FORM_SIGN_REGIS,UPDATE_FORM_SIGN_REGIS
} from '../../contant/Gov/login'
import {toastError, toastSuccess} from '../../helper/toastHelper'
import {updateArrById,CheckShowEditTrueOrFalse} from '../../helper/fnAllways/index'
export const initialState = {
  GovLogin:{},//xac dinh account nao dang truy cap vao tk nha nuoc
  userId:'',
  OpenCheckFormLogin:false,
  ListGov:[],
  OpenInfoGov:false,
  ItemUser:"",
  OpenSettingAdvance:false,
  ListInfoUserDetail:[],
  ShowEdit:undefined,
  OpenFormDelete:false,
  OpenTemplete:false,
  ItemTemplete:"",
  OpenViewTemplete:"",
  EditTemplete:false,
  OpenFormSignRegis:false,
  OpenUpdateFormSignRegis:false,
  ItemFormSignRegis:{},
  ListPriceSoft:[]
}

const GovLoginReducer = (state=initialState,action)=>{
    switch (action.type) {

      case TypeGov.DISPATCH_LIST_SOFT_PRICE_TO_STORE:
        return{
          ...state,
          ListPriceSoft:action.payload.item
        }

      case TypeGov.GET_INFO_USER_LOGIN:
         let {user} = action.payload             
         return{
           ...state,
           GovLogin:user,
           userId:user.userId,
         }       
      
        case OPEN_FORM_CHECK_LOGIN:
          return{
            ...state,
            OpenCheckFormLogin:true
          }

        case CLOSE_FORM_CHECK_LOGIN:
          return{
            ...state,
            OpenCheckFormLogin:false
          }

        case TypeGov.LIST_GOV:
          let Item = action.payload.arr
          return{
            ...state,
            ListGov:Item.length!==0?Item[0]:[]
          }

        case TypeGov.OPEN_DETAIL_INFO_GOV:
          return{
            ...state,
            OpenInfoGov:true
          }

        case TypeGov.CLOSE_DETAIL_INFO_GOV:
          return{
            ...state,
            OpenInfoGov:false
          }

        case TypeGov.UPDATE_INFO_GOV_SUCCESS:
          toastSuccess("Cập nhật thông tin thành công")
          return{
           ...state
          }

        case TypeGov.UPDATE_PASS_SUCCESS:
          toastSuccess("Mật Khẩu đã được thay đổi, Hệ thống tự động đăng xuất sau 5 giây")
          return{
            ...state
          }
        case TypeGov.OPEN_SETTING_ADVANCE:
          return{
            ...state,
            OpenSettingAdvance:true
          }
        case TypeGov.CLOSE_SETTING_ADVANCE:
          return{
            ...state,
            OpenSettingAdvance:false
          }
        case DISPATCH_LIST_INFO_USER_DETAIL:
          return{
            ...state,
            ListInfoUserDetail:action.payload.arr,
            ShowEdit:CheckShowEditTrueOrFalse(action.payload.arr)
          }
        case DISPATCH_ITEM_INFO_USER_DETAIL:
          return{
            ...state,
            ListInfoUserDetail:state.ListInfoUserDetail.concat(action.payload.item)
          }       
        case EDIT_ITEM_INFO_USER_DETAIL:
          return{
            ...state,
            ListInfoUserDetail:updateArrById(state.ListInfoUserDetail,action.payload.itemEdit)
          }
        case EDIT_ARR_INFO_USER_DETAIL:
          return{
            ...state,
            ListInfoUserDetail:action.payload.newArr,
            ShowEdit:CheckShowEditTrueOrFalse(action.payload.newArr)
          }
        case DISPATCH_DELETE_ITEM_INFO_USER_DETAIL:
          return{
            ...state,
            ListInfoUserDetail:state.ListInfoUserDetail.filter(item=>item._id!==action.payload.item._id)
          }
        case DISPATCH_OPEN_DELETE_ITEM_INFO_USER_DETAIL:
          return{
            ...state,
            OpenFormDelete:true
          }
        
          case DISPATCH_CLOSE_DELETE_ITEM_INFO_USER_DETAIL:
            return{
              ...state,
              OpenFormDelete:false
            }
          case DELETE_SUCCESS:
            toastSuccess('Xóa Thành Công')
            return{
              ...state
            }
          case OPEN_TEMPLETE:
            return{
              ...state,
              OpenTemplete:true
            }
          case CLOSE_TEMPLETE:
            return{
              ...state,
              OpenTemplete:false
            }
          case DISPATCH_ADD_TEMPLETE_TO_STORE:
            return{
              ...state,
              ItemTemplete:action.payload.item
            }
          case DISPATCH_LIST_ITEM_TO_STORE_TEMPLETE:
            return{
              ...state,
              ItemTemplete:action.payload.item
            }
          case SHOW_LIST_VIEW_TEMPLETE:
            return{
              ...state,
              OpenViewTemplete:true
            }
          case CLOSE_SHOW_LIST_VIEW_TEMPLETE:
            return{
              ...state,
              OpenViewTemplete:false
            }
          case ADD_TEMPLETE_SUCCESS:
            toastSuccess("Khơỉ tạo thành công")
            return{
              ...state
            }
          case EDIT_VIEW_TEMPLETE_FALSE:
            return{
              ...state,
              EditTemplete:false
            }
          case EDIT_VIEW_TEMPLETE_TRUE:
            return{
              ...state,
              EditTemplete:true
            }
          case EDIT_ITEM_TEMPLETE:
            return{
              ...state,
              ItemTemplete:updateArrById(state.ItemTemplete,action.payload.Item)
            }
          case LOGIN_FAIL_ACCOUNT_ONLY:                    
            return{
              ...state              
            }
          case ALERT_LOGIN_FAIL_ACCOUNT_ONLY:
            toastError('Bạn không thể đăng nhập, tài khoản này đang được sử dụng')
            return{
              ...state
            }
          case OPEN_FORM_SIGN_REGIS:
            return{
              ...state,
              OpenFormSignRegis:true
            }
            case CLOSE_FORM_SIGN_REGIS:
              return{
                ...state,
                OpenFormSignRegis:false
              }
           case ADD_FORM_SIGN_REGIS_SUCCESS_AND_DISPATCH_TO_STORE:
             toastSuccess('Lưu Thành Công!')
             return{
               ...state,
               ItemFormSignRegis:action.payload.item
             }
          case DISPATCH_ITEM_FORM_REGIS_TO_STORE:
            return{
              ...state,
              ItemFormSignRegis:action.payload.item
            }
          case OPEN_UPDATE_FORM_SIGN_REGIS:
            return{
              ...state,
              OpenUpdateFormSignRegis:true
            }
          case CLOSE_UPDATE_FORM_SIGN_REGIS:
            return{
              ...state,
              OpenUpdateFormSignRegis:false
            }
          case UPDATE_FORM_SIGN_REGIS:
            toastSuccess('Cập nhật thành công!')
            return{
              ...state,
              ItemFormSignRegis:action.payload.item
            }
      default: return state      
    }
}

export default GovLoginReducer