import { Box, Button, DialogActions, DialogContent, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { CLOSE_TUYEN_DUNG, UPDATE_ITEM_TUYEN_DUNG } from '../../../contant';
import{useDispatch,useSelector} from 'react-redux'
import { convertBase64 } from '../../../../../../../utils/FnBase64';
import { image_message_type } from '../../../../../../../contant';

const DoiTac = () => {
    const ItemTuyenDung = useSelector(state=>state.MainWeb.ItemTuyenDung)  

    const ValueTuyenDung = useSelector(state=>state.MainWeb.ValueTuyenDung)
    const HandleShowNameTuyenDung=(value)=>{
        if(value==="DOI_TAC")return 'DOI_TAC'
        if(value==="THI_TRUONG")return 'THI_TRUONG'
        if(value==="CHAM_SOC")return 'CHAM_SOC'
        if(value==="CTV")return 'CTV'   
    }
    const dispatch = useDispatch()
    const [nameTuyenDung,setNameTuyenDung] = useState(HandleShowNameTuyenDung(ValueTuyenDung.value))
    // const [nameCus,setNameCus]             = useState("")
    const [fullNameCus,setFullNameCus]     = useState("")
    const [tel,setTel]                     = useState("")
    const [email,setEmail]                 = useState("")
    const [taxCode,setTaxCode]             = useState("")
    const [address,setAddress]             = useState("")
    const [avatar,setAvatar]               = useState("")
    const [errAvatar,setErrAvatar]         = useState(false)

    const [errFullNameCus,setErrFullNameCus] = useState(false)
    const [errTel,setErrTel]                 = useState(false)
    const [errEmail,setErrEmail]             = useState(false)
    const [errAdd,setErrAdd]                 = useState(false)

    const SendInfo=()=>{
        let item ={
            avatar,
            nameTuyenDung,
            fullNameCus,
            tel,
            email,
            address
        }
        if(item.avatar===""){
            setErrAvatar(true)
            setTimeout(()=>{
                setErrAvatar(false)
            },3000)
            return
        }
        if(item.fullNameCus===""){
            setErrFullNameCus(true)
            setTimeout(()=>{
                setErrFullNameCus(false)
            },3000)
            return
        }
        if(item.address===""){
            setErrAdd(true)
            setTimeout(()=>{
                setErrAdd(false)
            },3000)
            return
        }
        if(item.tel===""){
            setErrTel(true)
            setTimeout(()=>{
                setErrTel(false)
            },3000)
            return
        }
        if(item.email===""){
            setErrEmail(true)
            setTimeout(()=>{
                setErrEmail(false)
            },3000)
            return
        }
       let newResult = [ItemTuyenDung].map(v=>{
        return{
            ...v,
            ListTuyenDung:v.ListTuyenDung.concat(item)
        }
       })[0]
       dispatch({type:UPDATE_ITEM_TUYEN_DUNG,payload:{item:newResult}})
       dispatch({type:CLOSE_TUYEN_DUNG})
       return ()=>{}
    }


    const handleFileUpload=async(e)=>{
        let file = e.target.files[0]
        let base64 = await convertBase64(file)
        if(file===""){
                alert('Vui lòng chọn file')
                return false
            }
      if(image_message_type.indexOf(file.type)===-1){
          alert('File không đúng định dạng, thử lại')
          return false
      } 
      setAvatar(base64) 
   }


    return (        
        <>
            <DialogContent dividers>
                    <Typography gutterBottom>
                                Công ty chúng tôi cần tuyển dụng nhân viên kỹ thuật hỗ trợ khách hàng online hoặc trực tiếp. Giải đáp các thắc mắc, sự cố liên quan đến sử dụng phần mềm dinh dưỡng cho khác hàng.
                                Lương thoả thuận, chế độ đầy đủ hợp lý, đào tạo trước khi làm việc. Vui lòng nhập thông tin đầy đủ.                                
                    </Typography>  
                    <Typography gutterBottom>
                        <Box display="flex">
                            {avatar!==""&&
                            <>
                                &nbsp;
                              <img src={avatar} style={{width:80,height:80,borderRadius:'50%'}}/>
                            </>
                            }
                            
                                <TextField
                                    type='file'
                                    fullWidth
                                    size="small"
                                    variant="filled"
                                    onChange={handleFileUpload}
                                    error={errAvatar}
                                    helperText="Ảnh Chân Dung"
                                />      
                        </Box>                                                  
                    </Typography>    
                    <Typography gutterBottom>
                            <TextField
                                fullWidth
                                size="small"
                                label="Tên Người Liên Hệ"
                                variant="filled"
                                onChange={e=>setFullNameCus(e.target.value)}
                                error={errFullNameCus}
                            />
                    </Typography>    
                    <Typography gutterBottom>
                            <TextField
                                fullWidth
                                size="small"
                                label="Địa chỉ"
                                variant="filled"
                                onChange={e=>setAddress(e.target.value)}
                                error={errAdd}
                            />
                    </Typography> 
                    <Typography gutterBottom>
                            <TextField
                                fullWidth
                                size="small"
                                label="Số Điện Thoại"
                                variant="filled"
                                onChange={e=>setTel(e.target.value)}
                                error={errTel}
                            />
                    </Typography>  
                    <Typography gutterBottom>
                            <TextField
                                fullWidth
                                size="small"
                                label="Email"
                                variant="filled"
                                onChange={e=>setEmail(e.target.value)}
                                error={errEmail}
                            />
                    </Typography>    
            </DialogContent>
            <DialogActions>
                <Button autoFocus color="primary" onClick={()=>SendInfo()}>
                    Gửi Thông Tin
                </Button>
                <Button autoFocus onClick={()=>dispatch({type:CLOSE_TUYEN_DUNG})} color="secondary">
                    Thoát
                </Button>
            </DialogActions>
        </>
    );
};

export default DoiTac;