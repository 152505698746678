import { DISPATCH_LIST_TYPE_OF_KIEM_THUC_TO_STORE ,DISPATCH_ITEM_KIEM_THUC_BA_BUOC_TO_STORE_IN_DATE,
DISPATCH_LIST_KIEM_THUC_BA_BUOC,DISPATCH_UPDATE_KIEM_THUC_BA_BUOC,
DISPATCH_INFO_KIEM_THU_BA_BUOC_TO_STORE,DISPATCH_ITEM_CO_SO_SAN_XUAT_TO_STORE
} from "../contant/KiemThuc";

export const DispatchlistTypeOfKiemThucToStore=(item)=>{
        return{
            type:DISPATCH_LIST_TYPE_OF_KIEM_THUC_TO_STORE,
            payload:{item}
        }
}

export const DispatchItemKiemThucBaBuocToStoreInDate=(item)=>{
    return{
        type:DISPATCH_ITEM_KIEM_THUC_BA_BUOC_TO_STORE_IN_DATE,
        payload:{item}
    }
}

export const DispatchListKiemThucBaBuoc=(item)=>{
    return{
        type:DISPATCH_LIST_KIEM_THUC_BA_BUOC,
        payload:{item}
    }
}

export const DispatchUpdateKiemThucBaBuoc=(item)=>{
    return{
        type:DISPATCH_UPDATE_KIEM_THUC_BA_BUOC,
        payload:{item}
    }
}

export const DispatchInfoKiemThucBaBuocToStore=(item)=>{
    return{
        type:DISPATCH_INFO_KIEM_THU_BA_BUOC_TO_STORE,
        payload:{item}
    }
}

export const DispatchItemCoSoSanXuatToStore=(item)=>{
    return{
        type:DISPATCH_ITEM_CO_SO_SAN_XUAT_TO_STORE,
        payload:{item}
    }
}