import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {useSelector} from 'react-redux'
import { Delete, Edit } from '@material-ui/icons';
import { TablePagination, TextField } from '@material-ui/core';
import { ItemDiv, TextName } from './style';
import { HandleCoHoi, HandleGioiTinh, HandleHonNhan, HandleNguonCoHoi, HandleThuNhap, HandleTinhCach, MenuCap } from './fn';
import { DELETE_ITEM_CRM, DISPATCH_ITEM_TYPE_CAP_TO_STORE } from '../../../contant/CRM';
import {useDispatch} from 'react-redux'
import EditCrmComponent from './edit'
import { handleNumberDot } from '../../../helper/cost/costStudent';
import { showDDMMYY } from '../../../helper/dateTime/handleDMY';
import styled from 'styled-components'

const StyledTableRow = styled(TableRow)`
.MuiTableCell-sizeSmall {
    padding: 1px 1px 1px 1px
    }
`

const useStyles = makeStyles({
  table: {
    minWidth: 850,
  },
});

export default function DenseTable() {
  const classes  = useStyles();
  const dispatch = useDispatch()
  const Crm      = useSelector(state=>state.Crm.ListCrm)
  const Cap      = useSelector(state=>state.Crm.typeCap)

  const [typeCap, setTypeCap]      = useState(Cap);
  const [ListCrm,setListCrm]       = useState(Crm.filter(v=>v.typeCap===typeCap)) 

  const [editItem,setEditItem]   = useState(false)
  const [valueEdit,setValueEdit] = useState(false)

  const [search,setSearch]       = useState("")

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(()=>{
    dispatch({type:DISPATCH_ITEM_TYPE_CAP_TO_STORE,payload:{item:typeCap}})
},[typeCap])

  useEffect(()=>{
    setListCrm(Crm.filter(v=>v.typeCap===Cap))
  },[Crm,Cap])

  useEffect(()=>{
    setTypeCap(Cap)
  },[Cap])

  const EditItem=(value)=>{
      setEditItem(true)
      setValueEdit(value)
  } 

  const DeleteItem=(item)=>{
        if(window.confirm('Dữ Liệu Sẽ Bị Xoá,Bạn Có Chắc Chắn')){
                dispatch({type:DELETE_ITEM_CRM,payload:{item}})
                setListCrm(Crm.filter(v=>v.typeCap===typeCap))
        }
  }

  useEffect(()=>{
      if(search!==""){
        let result     = Crm.filter(v=>v.typeCap===typeCap)
        let findResult =    result.filter(v=>v.HoTen.toLowerCase().indexOf(search)!==-1||
                            v.ToChuc.toLowerCase().indexOf(search)!==-1||
                            v.ChucDanh.toLowerCase().indexOf(search)!==-1||
                            v.Sdt.indexOf(search)!==-1)
        setListCrm(findResult)        
      }
      if(search===""){
        setListCrm(Crm.filter(v=>v.typeCap===typeCap))
      }
  },[search])

  const HandleTichDiem=(value)=>{
    if(value<=100)return "Hạng G"
    if(value>101&&value<=200)return "Hạng Đ"
    if(value>200&&value<=300)return "Hạng T"
    if(value>300)return "Hạng GDT"
  } 

  return (    
    <>
      {editItem?<EditCrmComponent               
                handleClose={()=>setEditItem(false)}
                value={valueEdit}
      />:
        <>
            <ItemDiv style={{marginTop:'50px',marginBottom:'15px'}}>
                    <TextName>&nbsp;Loại Tài Khoản : </TextName>
                            <TextField
                            fullWidth
                            size='small'
                            id="outlined-select-currency-native"
                            select
                            defaultValue={Cap}
                            onChange={e=>setTypeCap(+e.target.value)}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            >
                            {MenuCap.map((option) => (
                                <option key={option.value} value={option.value}>
                                {option.label}
                                </option>
                            ))}
                            </TextField>                       
            </ItemDiv>
            <ItemDiv style={{marginTop:'25px',marginBottom:'15px'}}>
                    <TextName>&nbsp;Tìm kiếm : </TextName>
                            <TextField
                            fullWidth
                            size='small'
                            variant="outlined"
                            type="text"                            
                            defaultValue={search}
                            onChange={e=>setSearch(e.target.value)}                            
                            />                       
            </ItemDiv>
            <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={ListCrm.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                  <TableContainer component={Paper} style={{background:'#09a3ad'}}>                 
                    <Table className={classes.table} stickyHeader size="small" aria-label="a dense table">
                      <TableHead border="1">
                        <StyledTableRow border="1" >
                          <TableCell align="center" style={{color:'#c0392b'}}>STT</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Họ Tên</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>SĐT</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>CCCD</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Ngày Sinh</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Địa Chỉ</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Tỉnh TP</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Email</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Tích Điểm</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Thẻ Thành Viên</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Ghi Chú</TableCell>



                          <TableCell align="center" style={{color:'#c0392b'}}>Tổ Chức</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Chức Danh</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Tính Cách</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Giới Tính</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Thu Nhập</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Hôn Nhân</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Partner</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>SPDV</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Ưu Đãi</TableCell>
                          


                          <TableCell align="center" style={{color:'#c0392b'}}>Người Giới Thiệu</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Người Phụ Trách</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Doanh Số Dự Kiến</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Chiết Khấu</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Lợi Nhuận</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Liên Hệ Lần Cuối</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>HĐ Tiếp Theo</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Lần Liên Hệ Tiếp Theo</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Tình Trạng Cơ Hội</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Nguồn Cơ Hôi</TableCell>
                          <TableCell align="center" style={{color:'#c0392b'}}>Thao Tác</TableCell>                  
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? ListCrm.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : ListCrm
                        ).map((row,i) => (
                          <StyledTableRow key={i} hover border="1">
                            <TableCell align="center">{i+1}</TableCell>
                            <TableCell align="center">{row.HoTen}</TableCell>
                            <TableCell align="center">{row.Sdt}</TableCell>
                            <TableCell align="center">{row.CCCD}</TableCell>
                            <TableCell align="center">{showDDMMYY(row.NgaySinh)}</TableCell>
                            <TableCell align="center">{row.DiaChi}</TableCell>
                            <TableCell align="center">{row.TinhTp}</TableCell>
                            <TableCell align="center">{row.Email}</TableCell>
                            <TableCell align="center">{row.TichDiem}</TableCell>
                            <TableCell align="center">{HandleTichDiem(+row.TichDiem)}</TableCell>
                            <TableCell align="center">{row.GhiChu}</TableCell>

                            
                            <TableCell align="center">{row.ToChuc}</TableCell>
                            <TableCell align="center">{row.ChucDanh}</TableCell>
                            <TableCell align="center">{HandleTinhCach(row.TinhCach)}</TableCell>
                            <TableCell align="center">{HandleGioiTinh(row.GioiTinh)}</TableCell>
                            <TableCell align="center">{HandleThuNhap(row.ThuNhap)}</TableCell>
                            <TableCell align="center">{HandleHonNhan(row.HonNhan)}</TableCell>
                            <TableCell align="center">{row.KHPartner.length!==0&&
                            row.KHPartner.map((v,i)=>{
                              return(
                                <span key={i}>{v.label}&nbsp;</span>
                              )
                            })
                            }</TableCell>
                            <TableCell align="center">{row.Spdv}</TableCell>
                            <TableCell align="center">{row.UuDai}</TableCell>


                            <TableCell align="center">{row.NguoiGioiThieu}</TableCell>
                            <TableCell align="center">{row.NguoiPhuTrach}</TableCell>
                            <TableCell align="center">{row.DoanhSoDuKien!==0?handleNumberDot(row.DoanhSoDuKien):0}</TableCell>
                            <TableCell align="center">{row.ChietKhau}</TableCell>
                            <TableCell align="center">{row.LoiNhuan!==0?handleNumberDot(row.LoiNhuan):0}</TableCell>
                            <TableCell align="center">{row.LienHeLanCuoi}</TableCell>
                            <TableCell align="center">{row.HanhDongTiepTheo}</TableCell>
                            <TableCell align="center">{row.LanLienHeTiepTheo}</TableCell>
                            <TableCell align="center">{HandleCoHoi(row.TinhTrangCoHoi)}</TableCell>
                            <TableCell align="center">{HandleNguonCoHoi(row.NguonCoHoi)}</TableCell>
                            <TableCell align="center">
                                  <Edit style={{color:'orange',cursor:'pointer'}}
                                    onClick={()=>EditItem(row)}
                                  />
                                  <Delete style={{color:'red',cursor:'pointer'}}
                                      onClick={()=>DeleteItem(row)}
                                  />
                            </TableCell>  
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>   
        </>
        }                  
    </>   
  );
}
