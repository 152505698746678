import { showMMYY, showYYMMDD } from "../../../../../../../helper/dateTime/handleDMY"
import { DeleteItemById, updateArrById } from "../../../../../../../helper/fnAllways"
import { sortArrAsc } from "../../../../../../../helper/tableList/tableList"
import _ from 'lodash'
const MAU_GIAO = "mẫu giáo"
const NHA_TRE  = "nhà trẻ"

export const HandleFilterByDateMeal = (arr,date)=>{
    return arr.filter(v=>showYYMMDD(v.dateMenu)===showYYMMDD(date))
}

export const DeleteAllMaterial=(Item)=>{
    return [Item].map(v=>{
        return{
            ...v,
            materials:[]
        }
    })[0]
}

export const ResetAllMaterialMass=(Item)=>{
        return [Item].map(v=>{
            return{
                ...v,
                materials:ResetMass(v.materials)
            }
        })[0]
}
const ResetMass=(materials)=>{
    return materials.map(v=>{
        return{
            ...v,
            mass:0
        }
    })
}
export const HandleNewItemChiPhi=(item,value)=>{  
        let result = [item].map(v=>{
            return{
                ...v,
                currentItem:NewCurrentItem(v.currentItem,value)
            }
        })[0]
        return {resultDb:result.currentItem,resultCurrent:result}
}

const NewCurrentItem =(currentItem,value)=>{
        return [currentItem].map(v=>{
                return{
                    ...v,
                    chiPhi:+value
                }
        })[0]
}

export const UpdateItemNumberNutrikid=(item,number)=>{
          return [item].map(v=>{
            let oldNumber = v.numberEat
            return{
                ...v,
                numberEat:+number,
                materials:HandleMaterials(v.materials,number,oldNumber)
            }
        })[0]
}

const HandleMaterials=(arr,newValue,oldValue)=>{    
    return arr.map(v=>{
        return{
            ...v,
            mass:(+((v.mass*newValue/oldValue).toFixed(0)))
        }
    })
}

export const UpdateItemMoneyNumberNutrikid=(item,number)=>{
    return [item].map(v=>{
        return{
            ...v,
            moneyEat:+number
        }
    })[0]
}

export const AddMaterialNutrikid=(Item,value)=>{
    if(value!==null||value!==""||value!==undefined){
                let newItem={
                idNameMaterial:value._id,
                mass:0,
                priceLose:0,
                price:0,
                repository:false
            }
            return [Item].map(v=>{
                return{
                    ...v,
                    materials:[newItem].concat(v.materials)
                    //v.materials.concat(newItem)
                }
            })[0]
    }   
}

export const NewListAllMaterialDateNutrikid =(arr,item,tableAge)=>{ 
    if(item!==""||item!==null||item||undefined){
        let filterTableAge = tableAge.tableAge.filter(v=>v._id===item.idTableInAge)[0]
        return item.materials.map(v=>{
            return{
            ...v,   
            currentItem:item,
            itemMaterial:arr.filter(vv=>vv._id===v.idNameMaterial)[0],
            itemTableAge:filterTableAge     
        }
    })
    }    
}

export const CheckExitAddMaterialNutrikid=(Item,value)=>{  
    if(value===null) return false
    return Item.materials.some(v=>v.idNameMaterial===value._id)
}

//update Mass 
export const UpdateMass=(Item,valueMass)=>{  
    if((valueMass!==null||valueMass!==""||valueMass!==undefined)&&Item!==undefined){
        let {currentItem} = Item     
        let newItem = {
            mass:valueMass===""||valueMass===null||valueMass===undefined?0:+(valueMass),
            price:Item.price,
            priceLose:Item.priceLose,
            repository:Item.repository,
            _id:Item._id,
            idNameMaterial:Item.idNameMaterial,
        }
        let resultDb      = UpdateProcess(currentItem,newItem)
        let resultCurrent = [Item].map(v=>{
            return{
                ...v,
                mass:+(newItem.mass),
                currentItem:resultDb
            }
        })[0]
        return{resultCurrent,resultDb}
    }    
}

export const UpdatePriceLose=(Item,valuePriceLose)=>{
    if((valuePriceLose!==null||valuePriceLose!==""||valuePriceLose!==undefined)&&Item!==undefined){
        let {currentItem} = Item
        let newItem = {
            mass:Item.mass,
            price:Item.price,
            priceLose:valuePriceLose===""||valuePriceLose===null||valuePriceLose===undefined?0:+(valuePriceLose),
            repository:Item.repository,
            _id:Item._id,
            idNameMaterial:Item.idNameMaterial
        }
        let resultDb      = UpdateProcess(currentItem,newItem)
        let resultCurrent = [Item].map(v=>{
            return{
                ...v,
                priceLose:+(newItem.priceLose),
                currentItem:resultDb
            }
        })[0]
        return{resultCurrent,resultDb}
    }    
}

export const UpdatePrice=(Item,valuePrice)=>{
    if((valuePrice!==null||valuePrice!==""||valuePrice!==undefined)&&Item!==undefined){
        let {currentItem} = Item
        let newItem = {
            mass:Item.mass,
            price:valuePrice===""||valuePrice===null||valuePrice===undefined?0:+(valuePrice),
            priceLose:Item.priceLose,
            repository:Item.repository,
            _id:Item._id,
            idNameMaterial:Item.idNameMaterial
        }
        let resultDb      = UpdateProcess(currentItem,newItem)
        let resultCurrent = [Item].map(v=>{
            return{
                ...v,
                price:+(newItem.price),
                currentItem:resultDb

            }
        })[0]
        return{resultCurrent,resultDb}
    }    
}

export const DeleteMaterial=(Item)=>{
    let {currentItem} = Item
    let resultDB = [currentItem].map(v=>{
        return{
            ...v,
            materials:DeleteItemById(v.materials,Item)
        }
    })[0]
    let resultCurrent=[Item].map(v=>{
        return{
            ...v,
            currentItem:resultDB
        }
    })[0]
    return{resultCurrent,resultDB}
}

const UpdateProcess=(currentItem,item)=>{  
    if(currentItem!==undefined){
        return [currentItem].map(v=>{       
        return{
            ...v,
            materials:updateArrById(v.materials,item)
        }
        })[0]
    }        
}


export const FindPrvTableMeal=(ListMeal,ItemMeal,prvDate)=>{
        if(ItemMeal!==undefined&&ItemMeal!==null){
            let  filterListMeal = ListMeal.filter(v=>v.idTableInAge===ItemMeal.idTableInAge)
            let newListMeal = filterListMeal.map(v=>{
                let date = showYYMMDD(v.dateTime)
                let myDate = date.split("-")
                let newDate = new Date( myDate[0],myDate[1]-1,myDate[2])
                return {
                    ...v,
                    timeStamp:newDate.getTime()
                }
            })
            let newList = sortArrAsc(newListMeal)
            let findIndex = newList.findIndex(v=>v._id===ItemMeal._id)       
            return newList[findIndex-1]
        }
        
}

export const FindCurrentTableMeal=(ListMeal,ItemMeal,DateTime)=>{
    let  filterListMeal = ListMeal.filter(v=>v.idTableInAge===ItemMeal.idTableInAge&&showMMYY(v.dateTime)===showMMYY(DateTime))
    let newListMeal = filterListMeal.map(v=>{
        let date = showYYMMDD(v.dateTime)
        let myDate = date.split("-")
        let newDate = new Date( myDate[0],myDate[1]-1,myDate[2])
        return {
            ...v,
            timeStamp:newDate.getTime()
        }
    })
    let newList = sortArrAsc(newListMeal)
    let findIndex = newList.findIndex(v=>v._id===ItemMeal._id)       
    return newList[findIndex-1]
}

export const FindFirstMeal=(ListMeal)=>{
    let newList = ListMeal.map(v=>{
        return{
            ...v,
            timeStamp:ConvertUTCDate(v.dateTime)
        }
    })
    let newlistAsc = sortArrAsc(newList)
    return newlistAsc[0]
}

export const ConvertUTCDate=(date)=>{
    let myDate  = showYYMMDD(date).split("-")
    let newDate = new Date( myDate[0],myDate[1]-1,myDate[2])   
    return newDate    
}

export const ConvertTimeStamp=(day)=>{
    let date = showYYMMDD(day)
    let myDate = date.split("-")
    let newDate = new Date( myDate[0],myDate[1]-1,myDate[2])
    return newDate.getTime()
}

export const HandleLuyKe=(valueIn,PrvTableMeal,Luyke)=>{         
      if(PrvTableMeal===undefined){
        if(valueIn!==""&&Luyke===null){
            let {currentItem}  = valueIn
            let luyKeSoNguoiAn   = +((+(currentItem.numberEat)).toFixed(0))//+ngay hom truoc //suat an luy ke
            let luyKeTieuChuan = +(currentItem.numberEat*currentItem.moneyEat).toFixed(0) 
            let daChiLuyKe     = +((+(HandleDaChiLuyKeNgay(currentItem.materials))+currentItem.chiPhi).toFixed(0))
            let moneyLeft      =  +((currentItem.numberEat*currentItem.moneyEat)-(+(+(HandleDaChiLuyKeNgay(currentItem.materials))).toFixed(0))-currentItem.chiPhi).toFixed(0)
            let chiPhi         = currentItem.chiPhi
            return [currentItem].map(v=>{
                return{
                    ...v,
                    moneyLeft,
                    luyKeSoNguoiAn,
                    luyKeTieuChuan,
                    daChiLuyKe,                                
                    //moneyLeft,
                    chiPhi
                }
            })[0]
        }
        if(valueIn!==""&&Luyke!==null){
            let {currentItem}  = valueIn
            let luyKeSoNguoiAn = +((+(currentItem.numberEat+Luyke.luyKeSoNguoiAn)).toFixed(0))
            let luyKeTieuChuan = +((currentItem.numberEat*currentItem.moneyEat).toFixed(0)) + +(Luyke.luyKeTieuChuan)
            let daChiLuyKe     = +((+(HandleDaChiLuyKeNgay(currentItem.materials))).toFixed(0))+ +(Luyke.daChiLuyKe)+currentItem.chiPhi
            let moneyLeft      = +(((currentItem.numberEat*currentItem.moneyEat)-(+(+(HandleDaChiLuyKeNgay(currentItem.materials))).toFixed(0))-currentItem.chiPhi).toFixed(0))+ (+(Luyke.moneyLeft))
            let chiPhi         = currentItem.chiPhi
       //     console.log(moneyLeft)
            return [currentItem].map(v=>{
                return{
                    ...v,
                    moneyLeft,
                    luyKeSoNguoiAn,
                    luyKeTieuChuan,
                    daChiLuyKe,                                
                    //moneyLeft,
                    chiPhi
                }
            })[0]
        }
      }
     if(PrvTableMeal!==undefined){
            let numberEatPrv       = +((+(PrvTableMeal.luyKeSoNguoiAn)).toFixed(0)) //suat an ngay hom truoc
            let luyKeNgayPrv       = +((+PrvTableMeal.numberEat*PrvTableMeal.moneyEat).toFixed(0))
            let moneyLeftPrv       = +((luyKeNgayPrv - HandleDaChiLuyKeNgayPrv(PrvTableMeal)-PrvTableMeal.chiPhi).toFixed(0))
            // let daChiLuyKeNgayPrv  = +((+(HandleDaChiLuyKeNgayPrv(PrvTableMeal))).toFixed(0))+PrvTableMeal.daChiLuyKe
            let daChiLuyKeNgayPrv  = +PrvTableMeal.daChiLuyKe
            //let chiPhi             = PrvTableMeal.chiPhi
            if(valueIn!==""){
                        let {currentItem}  = valueIn
                        let luyKeSoNguoiAn = +((+(currentItem.numberEat+numberEatPrv)).toFixed(0)) //+ngay hom truoc //suat an luy ke
                        let luyKeTieuChuan = +(((currentItem.numberEat*currentItem.moneyEat)+PrvTableMeal.luyKeTieuChuan).toFixed(0))
                        let daChiLuyKe     = +((+(HandleDaChiLuyKeNgay(currentItem.materials))+daChiLuyKeNgayPrv+currentItem.chiPhi).toFixed(0))
                        let moneyLeft      =  +((currentItem.numberEat*currentItem.moneyEat)-HandleDaChiLuyKeNgay(currentItem.materials)-currentItem.chiPhi+moneyLeftPrv).toFixed(0)
                        let chiPhi         = currentItem.chiPhi
                        return [currentItem].map(v=>{
                            return{
                                ...v,
                                moneyLeft,
                                luyKeSoNguoiAn,
                                luyKeTieuChuan,
                                daChiLuyKe,                                
                                chiPhi,
                            }
                        })[0]
            }
     }
}

 const HandleDaChiLuyKeNgayPrv =(TableMeal)=>{
         return TableMeal.materials.length===0?0:HandleSumCho(TableMeal.materials)
 }

 const HandleDaChiLuyKeNgay =(materials)=>{
        return materials.length===0?0:HandleSumCho(materials)
}

 export const HandleSumCho=(materials)=>{
     let item = materials.map(v=>v.price*(+(+(v.mass/100).toFixed(5)))).reduce((x,y)=>x+y,0)
     return +((+(item)).toFixed(0))
 } 


 //when action is true
 
 export const HandleLuyKeWhenTrue=(valueIn,PrvTableMeal)=>{    
   let resultValue = [valueIn].map(v=>{
       return{
           ...v,
           chiPhi:v.chiPhi+PrvTableMeal.chiPhi,
           daChiLuyKe:v.daChiLuyKe+PrvTableMeal.daChiLuyKe,
           luyKeSoNguoiAn:v.luyKeSoNguoiAn+PrvTableMeal.luyKeSoNguoiAn,
           luyKeTieuChuan:v.luyKeTieuChuan+PrvTableMeal.luyKeTieuChuan,
           moneyLeft:v.moneyLeft+PrvTableMeal.moneyLeft===null?0:PrvTableMeal.moneyEat
       }
   })[0]
   let resultDateTimeLuyKe =  [PrvTableMeal].map(v=>{
       return{
           ...v,
           action:false
       }
   })[0]

   return{resultValue,resultDateTimeLuyKe}         
}

export const NewItemTableMeal=(Item,NewValue)=>{
    return [Item].map(v=>{
        let OldValue = v.numberEat
        return {
            ...v,
            numberEat:NewValue,
            materials:HandleMaterials(v.materials,NewValue,OldValue)
        }
    })[0]
}


export const ChangeNewListMeal=(arr,item,ItemTableAge)=>{
   return item.materials.map(v=>{
        return{
            ...v,
            currentItem:item,
            idNameMaterial:v.idNameMaterial,
            itemMaterial:FindItemMaterial(arr,v),
            itemTableAge:ItemTableAge
        }
    })
}

const FindItemMaterial =(arr,value)=>{
     let find = arr.filter(v=>v.itemMaterial._id===value.idNameMaterial)
     return find[0].itemMaterial
}


export const ProcessLoadBalanceMoney=(arrList,arrCurrent)=>{      
       if(arrList.length!==0){
        return  arrCurrent.map(v=>{
                  let filterMaterial = arrList[0].arrResult.filter(vv=>vv.idNameMaterial===v.idNameMaterial)[0]
                  let {TBNumberEat,TB1MassNumberEat} = filterMaterial
                  let {numberEat} = v.currentItem               
                  return {
                      ...v,
                      mass:+((numberEat*TB1MassNumberEat/TBNumberEat).toFixed(0))*numberEat
                  }
                })
       }    
}

export const handlelamTronSo=(arr)=>{    
    return [arr].map(v=>{
        return{
            ...v,
            materials:handleLamTronMaterial(v.materials)
        }
    })[0]
    //console.log(result)
    return  arr
}

const handleLamTronMaterial=(arr)=>{   
     return arr.map(v=>{
        return{
            ...v,
            mass:v.mass!==0?handleMassLamTron(v.mass):0
        }
    })
}

const handleMassLamTron=(mass)=>{     
    let massStr = mass.toString()
    let splitMass = massStr.split('.')
     let char = splitMass[0]
     let charEnd = +(splitMass[0].slice(-1))   
     
    if((charEnd===0||char.length===1)){         
            return +char        
    }
    if((charEnd!==0&&char.length!==1&&char.length>2)){  
        let strChar = char.length-2
        let getNumStrChar = char.slice(0,strChar)     
        return +(getNumStrChar+'0'+'0')      
     }   
     if((charEnd!==0&&char.length!==1&&char.length>1&&char.length===2)){  
        let strChar = char.length-1
        let getNumStrChar = char.slice(0,strChar)  
        return +(getNumStrChar+'0')      
     }             
}

    export const UpdateNewMealWeekly=(item,value)=>{        
        return item.map(v=>{
            return{
                ...v,
                listFoodPri:value.sang,
                listFoodPri2:`${value.truaCanh},${value.truaKho}`,
                listFoodSe:`${value.chieuXe},${value.chieuTrangMieng}`
            }
        })[0]
    }


    export const CanDoiSoDuVe0=(number,ItemMeal)=>{
            return [ItemMeal].map(v=>{
                return{
                    ...v,
                    materials:HandleMealMaterials(v.materials,number)
                }
            })[0]
    }

    const HandleMealMaterials=(materials,number)=>{
        //console.log(materials[0])
        let filterMaterial=materials.filter(v=>v.mass!==0)
        let Not0=materials.filter(v=>v.mass===0)
            let numberTB = +((number/filterMaterial.length).toFixed(7))
                let result = filterMaterial.map(v=>{
                        return{
                            ...v,
                            mass:handleMealMass(numberTB,v.price,v.mass)
                        }
                    })
                    if(Not0.length!==0){
                            return Not0.concat(result)
                    }
                    if(Not0.length===0){
                        return result
                    }
    }

    const handleMealMass=(numberTB,price,mass)=>{
            return +((((((price*mass)/100)+numberTB)/price)*100).toFixed(4))
    }


    export const UpdateArrAllRow=(arr,Item)=>{
        //console.log(Item,'',arr)
        return arr.map(v=>{
            return{
                ...v,
                currentItem:Item,
                mass:HandleList(v._id,Item.materials).mass,
                price:HandleList(v._id,Item.materials).price,
                priceLose:HandleList(v._id,Item.materials).priceLose
            }
        })        
    }

    const HandleList=(id,materials)=>{
        let filterBy = materials.filter(v=>v._id===id)[0]
        return{
            mass:filterBy.mass,
            price:filterBy.price,
            priceLose:filterBy.priceLose
        }
    }

    // const handleItemMeal=(Item)=>{
    //     return [Item].map(v=>{
    //         return {
    //             ...v,
    //             moneyLeft:v.luyKeTieuChuan-v.daChiLuyKe-v.moneyLeft
    //         }
    //     })[0]
    // }

    export const HandleAutoInputMass=(value,Item,ItemProcess)=>{    

       return[value].map(v=>{
            return{
                ...v,
                materials:AutoImportProcess(v.materials,ItemProcess,v.numberEat)
            }
        })
     //   console.log(result)
      //  return [value]
        //   return [value].map(v=>{
        //     return{
        //         ...v,
        //         materials:AutoImportMass(v.materials,Item)
        //     }
        //    })
        
    }

    const AutoImportProcess=(arrMaterial,arrProcess,numberEat)=>{            
           return arrMaterial.map(v=>{
                let findMaterial = handleFindMaterial(v,arrProcess,numberEat)
                return{
                    ...v,
                    price:v.price===0?findMaterial.price:v.price,
                    mass:v.mass===0?findMaterial.mass:v.mass
                }
            })
    }

    const handleFindMaterial=(value,arrProcess,numberEat)=>{
            let itemFind = arrProcess[0].arrResult.filter(v=>v.idNameMaterial===value.idNameMaterial)
            //console.log(itemFind)
            if(itemFind.length){
                let newArr = itemFind[0].arr.map(v=>{
                    return {
                        ...v,
                        numberEatLess:v.numberEat-numberEat<0?-(v.numberEat-numberEat):v.numberEat-numberEat,                       
                    }
                })
                let minItem = minElement(newArr)
                return newArr[minItem]
            }
            if(!itemFind.length)return value
    }

    const  minElement=(array)=>{
        let min =  array[0].numberEatLess;
        let min_index= 0;   
        /*So sánh từng số trong mảng với giá trị đầu tiên để tìm ra giá trị nhỏ nhất*/
        for (let i = 1; i < array.length; ++i) {
            if (min > array[i].numberEatLess) { //Thay đổi giá trị nhỏ nhất nếu tìm ra số nhỏ hơn
                min = array[i].numberEatLess;
                min_index =i;
            }
        }
        //console.log("min= ",min);
        //console.log("min_index= ",min_index);
        return min_index
    }
   

    
    const AutoImportMass=(arr,arrList)=>{
         return  arr.map(v=>{
                let ItemMass = arrList.filter(vv=>vv._id===v._id)
                if(ItemMass[0].currentItem.age.toLowerCase()===MAU_GIAO){                    
                        return {
                            ...v,
                            mass:+((ItemMass[0].itemMaterial.tro*ItemMass[0].currentItem.numberEat*1.6).toFixed(0))
                        }
                }
                if(ItemMass[0].currentItem.age.toLowerCase()===NHA_TRE){
                    return {
                        ...v,
                        mass:+((ItemMass[0].itemMaterial.cholesterol*ItemMass[0].currentItem.numberEat*1.6).toFixed(0))
                    }
                }
            })
    }


    export const HandleFilterPriceDup=(arrMeal)=>{
        let newJoinMaterial = arrMeal.map(v=>{
            return v.materials
        })
        let newMaterial = _.flatten(newJoinMaterial)
        let getIdMaterial = newMaterial.map(v=>v.idNameMaterial)
        let duppId = [...new Set(getIdMaterial)]
        let checkPrice = duppId.map(v=>{
            let filterPrice = newMaterial.filter(vv=>vv.idNameMaterial===v)
            if(filterPrice.length===2){
                let priceBegin       = filterPrice[0].price
                let priceArr         = filterPrice.map(v=>v.price)
                
                let result =  priceArr.map(v=>{
                    if(v!==priceBegin){
                        return {
                            id:filterPrice[0].idNameMaterial,
                            check:false
                        }
                    }
                    if(v===priceBegin){
                        return{
                            id:filterPrice[0].idNameMaterial,
                            check:true
                        }
                    }
                })
                let filterArrFalse = result.filter(v=>v.check===false)
                let filterArrTrue  = result.filter(v=>v.check===true)
                if(filterArrFalse.length){
                    return filterArrFalse
                }
                return filterArrTrue           
               
            }
            if(filterPrice.length===1){
                 return {
                    id:filterPrice[0].idNameMaterial,
                    check:true
                 }
            }           
        })
       return  _.flattenDeep(checkPrice).filter(v=>v.check===false)
    }