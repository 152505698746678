import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Suspense } from 'react';
import { GET_CHAM_CONG_BY_USER_ID, GET_LIST_MANAGER_STAFF, GET_LIST_PAYMENT_STAFF, GET_LIST_TASK_JOB } from '../contant';
import { useSelector,useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { GET_CATEGORY, GET_LIST_IMPORT_MATERIAL, GET_LIST_IMPORT_STAFF } from '../../QuanLyKho/MainTab/Categories/Contant';
import { GET_CUSTOMER, GET_LIST_SUPPLIES } from '../../../../Contant/Person/supplies';


const ManagerAccount = React.lazy(()=>import('../component/'))
const Staff          = React.lazy(()=>import('../component/Staff'))
const Job            = React.lazy(()=>import('../component/Job'))
const QuanLyCongNo   = React.lazy(()=>import('../component/ManagerPaymentCustomer'))

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div" variant="body2">{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const dispatch     = useDispatch()

  const ValueSelectCreateRepo    = useSelector(state=>state.CateRepo.ValueSelectCreateRepo)
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {   
      if(ValueSelectCreateRepo!==null){
           dispatch({
              type:GET_LIST_TASK_JOB,
              payload:{item:ValueSelectCreateRepo}
            })  
     }     
  },[]) 

  // useEffect(() => {
  //   //return()=>{
  //      if(ValueSelectCreateRepo!==null){
  //       dispatch({
  //         type:GET_LIST_PAYMENT_STAFF,
  //         payload:{item:{userId:ValueSelectCreateRepo.userId}}
  //       })
  
  //       dispatch({
  //         type:GET_CHAM_CONG_BY_USER_ID,
  //         payload:{item:{userId:ValueSelectCreateRepo.userId}}
  //       })
  
  //        dispatch({
  //           type:GET_CATEGORY,
  //           payload:{item:{
  //             userId:ValueSelectCreateRepo.userId
  //           }}
  //        })
  
  //          dispatch({
  //           type:GET_LIST_IMPORT_MATERIAL,
  //           payload:{item:{
  //             userId:ValueSelectCreateRepo.userId
  //           }}
  //         })
        
  //         dispatch({
  //                type:GET_LIST_SUPPLIES,
  //                payload:{item:ValueSelectCreateRepo}
  //         })
  
  //           dispatch({
  //              type:GET_CUSTOMER,
  //              payload:{item:ValueSelectCreateRepo}
  //           })
  
  //          dispatch({
  //           type:GET_LIST_IMPORT_STAFF,
  //           payload:{item:{userId:ValueSelectCreateRepo.userId}}
  //          })        
       
  //         dispatch({
  //           type:GET_LIST_MANAGER_STAFF,
  //           payload:{item:{userId:ValueSelectCreateRepo.userId}}
  //         })
  
  //         dispatch({
  //           type:GET_LIST_IMPORT_MATERIAL,
  //           payload:{item:{
  //             userId:ValueSelectCreateRepo.userId
  //           }}
  //         })
  //         dispatch({
  //           type:GET_LIST_TASK_JOB,
  //           payload:{item:ValueSelectCreateRepo}
  //         })  
  //      }
  //  // }      
  // },[])

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{background:'#4065ef'}}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Quản Lý Tài Khoản" {...a11yProps(0)} />
          <Tab label="Nhân Viên" {...a11yProps(1)} />
          <Tab label="Công Việc" {...a11yProps(2)} />
          <Tab label="Khách hàng"   {...a11yProps(3)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <Suspense fallback={<>loading...</>}>
            <ManagerAccount/>
        </Suspense>      
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Suspense fallback={<>loading...</>}>
           <Staff/>
        </Suspense>              
      </TabPanel>      

      <TabPanel value={value} index={2}>
        <Suspense fallback={<>loading...</>}>
         <Job/>
        </Suspense>             
      </TabPanel>

      <TabPanel value={value} index={3}>
        <Suspense fallback={<>loading...</>}>
           <QuanLyCongNo/>
        </Suspense>      
      </TabPanel>

    </div>
  );
}
