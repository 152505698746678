import { DISPATCH_ITEM_APP_WEB_TO_STORE,DISPATCH_LIST_REGIS_STUDENT_WEB ,DISPATCH_LIST_GIOI_THIEU_WEB_TO_STORE, UPDATE_SUCCESS} from "../Contant";



export const DispatchListRegisStudentWeb=(item)=>{
    return{
        type:DISPATCH_LIST_REGIS_STUDENT_WEB,
        payload:{item}
    }
}

export const DispatchItemAppWebToStore =(item)=>{
    return{
        type:DISPATCH_ITEM_APP_WEB_TO_STORE,
        payload:{item}
    }
}

export const DispatchListGioiThieuWebToStore=(item)=>{
    return{
        type:DISPATCH_LIST_GIOI_THIEU_WEB_TO_STORE,
        payload:{item}
    }
}

export const UpdateSuccess = ()=>{
    return {
        type:UPDATE_SUCCESS
    }
}