import {DISPATCH_ITEM_ADD_CATEGORY_TO_STORE,DISPATCH_LIST_CATEGORY_TO_STORE,DISPATCH_CATEGORY_TO_STORE_AFTER_ADD_MATERIAL,
ADD_MATERIALS_MANAGER_REPO,CLOSE_UPLOAD_EXCEL,DISPATCH_ITEM_IMPORT_MATERIAL_TO_STORE,DISPATCH_GET_LIST_IMPORT_MATERIAL_TO_STORE,
DISPATCH_GET_LIST_IMPORT_STAFF_TO_STORE
} from  '../Contant'

export const  DispatchItemAddCategoryToStore =(item)=>{
    return{
      type:DISPATCH_ITEM_ADD_CATEGORY_TO_STORE,
      payload:{item}
    }
}

export const DispatchListCategoryToStore=(item)=>{
  return{
    type:DISPATCH_LIST_CATEGORY_TO_STORE,
    payload:{item}
  }
}

export const DispatchCategoryToStoreAfterAddMaterial=(item)=>{
    return {
      type:DISPATCH_CATEGORY_TO_STORE_AFTER_ADD_MATERIAL,
      payload:{item}
    }
}

export const AddMaterialsManagerRepo=(item)=>{
  return{
    type:ADD_MATERIALS_MANAGER_REPO,
    payload:{item}
  }
}

export const CloseUploadExcel=()=>{
  return{
    type:CLOSE_UPLOAD_EXCEL
  }
}

export const DispatchItemImportMaterial=(item)=>{
    return {
      type:DISPATCH_ITEM_IMPORT_MATERIAL_TO_STORE,
      payload:{item}
    }
}

export const DispatchGetListImportMaterialToStore=(item)=>{
    return{
      type:DISPATCH_GET_LIST_IMPORT_MATERIAL_TO_STORE,
      payload:{item}
    }
}

export const DispatchGetListImportStaffToStore=(item)=>{
  return{
    type:DISPATCH_GET_LIST_IMPORT_STAFF_TO_STORE,
    payload:{item}
  }
}