import React from 'react'
import {showDDMMYY,showMMYY,showDD,showDDMM,showMM, showYYYY} from '../../helper/dateTime/handleDMY'
import {sortArrAsc} from '../tableList/tableList'
import _ from 'lodash'
import {DocTienBangChu} from '../fnAllways/index'
import {handleFindIndexTypeMaterial,handleFindIndexCateMaterial,handleDateInRepo} from '../dateTime/handleDMY'
export const checkExistAddTypeMaterial = (arr,item,date)=>{
    let newArr = ListTypeMaterialByDateInput(arr,date)
  return newArr.some(v=>(v.idTypeMaterial===item._id))
}
export const ListTypeMaterialByDateInput=(arr,date)=>{
  return arr.filter(v=>showDDMMYY(v.dateMeal)===showDDMMYY(date))   
}

//in phieu xuat kho
export const FindValueArrPaymentOrder =(arrMealDate,arrRepo,arrTypeMaterial,arrMaterial,dateInput)=>{
   let ListArr          = ListTypeMaterialByDateInput(arrMealDate,dateInput)   
   let newListArr       = FilterListTypeMaterial(ListArr,arrRepo)
   let findTypeMaterial = handleFindIndexTypeMaterial(newListArr,arrTypeMaterial)
   let ArrCateMaterial  = handleFindIndexCateMaterial(findTypeMaterial,arrMaterial)
   let NameCate         = ArrCateMaterial.map(v=>v.name)   
   NameCate             = NameCate.filter((item,index)=>NameCate.indexOf(item)===index)
   let doctien = new DocTienBangChu()
   let Total = sumTotal(newListArr)
   let result ={
     NameCate,
     ArrCateMaterial,
     Total,
     NumberMoney:doctien.doc(Total)
   }
   return result
}

export const HandlePriceMaterial = (nameCate, ArrCateMaterial)=>{    
     let newArr =   ArrCateMaterial.filter(item=>item.name===nameCate)
     let total = sumTotal(newArr)
     return total
}

const FilterListTypeMaterial=(ListArr,arrRepo)=>{
   let newListArr = ListArr.map(v=>{
    let arrResult =[]
    arrRepo.filter(i=>{
      if(i.idTypeMaterial===v.idTypeMaterial){
        arrResult.push(v)
      }
      return true
    })
    return arrResult
  })
  newListArr = _.flatten(newListArr)
  return _.difference(ListArr,newListArr)
 
}
//in phieu xuat kho

export const FindValueArrExportRepo =(arrMealDate,arrRepo,arrTypeMaterial,dateInput)=>{   
  let newArr = ListTypeMaterialByDateInput(arrMealDate,dateInput)
  let newArrRepo = UnitArr(arrRepo,arrTypeMaterial)
  let newArr2 = ResultArrTypeMaterial(newArrRepo,newArr) 
  let Result = newArr2.filter(v=>v!==null)  
   let doctien = new DocTienBangChu()
   let total   = sumTotal(Result)
   let result = {
     Result,
     sum:total,
     numberMoney:doctien.doc(total)
   }
   return result
}

const UnitArr=(arrRepo,arrTypeMaterial)=>{
 return arrRepo.map(item=>{
    let index = arrTypeMaterial.findIndex(value=>value._id===item.idTypeMaterial)
    if(index>=0){
      return{
        ...item,
        ...arrTypeMaterial[index]
      }
    }else{
      return{...item}
    }
  })
}
const ResultArrTypeMaterial=(newArrRepo,newArr)=>{
 return newArrRepo.map(item=>{
    let index = newArr.findIndex(value=>value.idTypeMaterial===item.idTypeMaterial)
    if(index>=0){
      return{
        ...item,
        ...newArr[index]
      }
    }else{
      return null      
    }
  })
}

export const NameTypeMaterial = (arr,item)=>{   
    let value = arr.filter(v=>v._id===item)  
         let name = value[0].name
          return name
}
export const ShowNameSupplies = (arr,id)=>{  
  let value = arr.filter(v=>v._id===id)
  if(value.length){
    return value[0].nameSupplies
  }
  if(!value.length)return ""
}
export const UnitTypeMaterial = (arr,item)=>{
  let value = arr.filter(v=>v._id===item)
  let unit = value[0].unit
  return unit
}

export const sumPriceTypeMaterial = (arr)=>{
   return  arr.map(v=>v.price*v.quantity)
}


export const sumTotal = (arr)=>{
  let sum =  sumPriceTypeMaterial(arr)
  return sum.reduce((x,y)=>x+y,0)
}

export const SumQuantity=(arr)=>{ 
  return arr.map(v=>1*v.quantity) 
 
}
export const SUM_QUANTITY = (arr)=>{  
   let sum = SumQuantity(arr)   
   return sum.reduce((x,y)=>x+y,0)  
}


export const HandleUpdateArrCheckDayFoods = (arrCheckDay,arrDispatch)=>{  
 return arrCheckDay.map(item=>{
    let index = arrDispatch.findIndex(value=>value._id===item._id)
    if(index>=0){
      return{
          ...item,
          ...arrDispatch[index]
        }
      }
      else{
        return {...item}
      }
    })      
  }

export const ShowIconDelete=(arrCheckFood,DateInput,idClass)=>{  
  //pahi sap xep thoi gina tang dan moi chinh xax
  let NewArr     = (sortArrAsc(arrCheckFood)).filter(v=>showMMYY(v.dateNow) === showMMYY(DateInput) &&
                                                         v.idClass          === idClass
  )  
  let findResult = NewArr.length>0?NewArr[NewArr.length-1]:[]
  return findResult.students  
}

export const CheckExistRow = (arr,item)=>{ 
  return arr.some(v=>v.idStudent===item._id)
}

export const RemoveStudentToArr = (arrCheckDay,dateNow,idClass,student) =>{   
    let result1 = arrCheckDay.filter(v=>showMMYY(dateNow)  === showMMYY(dateNow)&&
                                                v.idClass  === idClass                                   
    )
    let result2 = result1.map(v=>{
       v.nameStudentNotEat = v.nameStudentNotEat.filter(i=>i.idStudent!==student)      
       let newArr = {...v}
      return newArr
    })
    return result2
}
export const handleSumPriceTypeMaterial =(arr)=>{
    let sum = sumTotal(arr)    
    let newArr={
      arr,
      sumPriceTypeMaterial:sum
    }   
    return newArr
}

const handleFilterDate =(dd,arr)=>{
 return arr.filter(vv=>{
    let date = +(showDD(vv.dateMeal))
    if(date===dd){
      return vv
    }else{
      return ""
    }
  })          
}
export const handleResultPriceOfDate=(value,dd,arr)=>{   
    let result = arr.filter(vv=>vv.nameMaterial===value)     
    let result2 = handleFilterDate(dd,result)    
    let totalPriceOfDate = result2.length===0?"":sumTotal(result2)    
    return totalPriceOfDate    
}
export const handleTotalPriceOfDate = (dd,arr)=>{
      let result = handleFilterDate(dd,arr)
      return result.length===0?"":sumTotal(result)
}

export const ArrNumDate =(number)=>{
    let num =[]
      for (let i=1; i <= number; i++) {
        num.push(i)          
      }
  return num
}
export const TotalPriceByMaterial = (name,arr)=>{  
    let result = arr.filter(v=>v.nameMaterial===name)    
    return result.length===0?"":sumTotal(result)
}

export const TotalPrice  = (arrPrice)=>{
    return arrPrice.length===0?"":sumTotal(arrPrice)
}

export const HandleResultPriceSupplies = (arrMealDate,itemSupplies,ListTypeMaterial)=>{  
  let newArrMealDate = filterIdSupplies(arrMealDate,itemSupplies)
  let findArr = handleFindTypeMaterial(newArrMealDate,ListTypeMaterial)
  let result = {
    findArr,
    sumPQ:sumTotal(findArr),
    sumQ:SUM_QUANTITY(findArr),
    dateBegin:findArr.length===0?"":showDDMM(findArr[0].dateMeal),
    dateEnd:findArr.length===0?"":showDDMM(findArr[findArr.length-1].dateMeal)
  }  
 return result
}
const filterIdSupplies = (arrMealDate,itemSupplies)=>{
  return arrMealDate.filter(v=>v.idSupplies===itemSupplies._id)
}

 const handleFindTypeMaterial = (newArrMealDate,ListTypeMaterial)=>{
  return newArrMealDate.map(item=>{
    let index = ListTypeMaterial.findIndex(value=>value._id===item.idTypeMaterial)
    if(index>=0){
      return{
        ...item,
        ...ListTypeMaterial[index]
      }
    }else{
      return{...item}
    }
  }) 
}
//kiem tra xem khi nao cap nhat
export const CheckUpdateItemBalanceBegin=(arrBalance,Item,date)=>{
    let CheckTrue = true //khong cap nhat
    let CheckFalse = false // cap nhat
    let NewItem = arrBalance.filter(v=>v.idTypeMaterial===Item.idTypeMaterial&&showMMYY(v.dateCreate)===showMMYY(date))
    let New = NewItem[NewItem.length-1]
    if((New.balanceBegin===Item.balanceBegin)&&
        (New.priceBalanceBegin===Item.priceBalanceBegin)&&
        (New.adjustBalanceBegin===Item.adjustBalanceBegin)&&
        (New.priceAdjustBalanceBegin===Item.priceAdjustBalanceBegin)&&
        (showMMYY(New.dateCreate)===showMMYY(Item.dateCreate))&&
        (New.idTypeMaterial===Item.idTypeMaterial)&&
        (New._id===Item._id)){
          return CheckTrue  //khong cap nhat
        }else{
          return CheckFalse //cap nhat
        }
}
//kiem tra xem khi nao cap nhat

//tim idTypeMAterail them vao mang thang nhap vao ADD_BALANCE
export const FindIdTypeMaterialAddBalance = (arrBalance,date,userId)=>{
    let arrIdTypeMaterial=[...new Set(arrBalance.map(v=>v.idTypeMaterial))]//xac dinh cac pt va loai bo cac pt trung nhau trong mang
    let arrFind = arrBalance.filter(v=>showMMYY(v.dateCreate)===showMMYY(date))
    let NewArrFind = arrFind.map(v=>v.idTypeMaterial)
    //lay nhung phan tu khac nhau giua 2 arr
    let resultFind = _.difference(arrIdTypeMaterial,NewArrFind)
    let newResult =resultFind.map(v=>{
      let item={
        idTypeMaterial:v,
        userId,
        dateCreate:date
      }
      return item
    })
    return newResult
}
//tim idTypeMAterail them vao mang thang nhap vao ADD_BALANCE


//so kho
export const ManageRepositoryResult =(ArrRepo,ArrImport,date,ItemRepo,ArrAllMaterial,ArrBalance)=>{  
  let ItemBalanceBegin = FilterBalanceBegin(ArrBalance,ItemRepo,date)
  let FilterAllMaterialByMM       = ArrAllMaterial.filter(v=>showMMYY(v.dateMeal)===showMMYY(date))  
  let resultEXPORT = FilterAllMaterialByMM.filter(v=>v.idTypeMaterial===ItemRepo.idTypeMaterial)
  let FilterMM_IMPORT             = ArrImport.filter(v=>showMMYY(v.dateImport)===showMMYY(date))
  let resultIMPORT = FilterMM_IMPORT.filter(v=>v.idTypeMaterial===ItemRepo.idTypeMaterial)
  let result = HandleDataExportImport(resultIMPORT,resultEXPORT,ItemBalanceBegin)  
  let findItemToEdit = FindItemToEditBalanceBegin(ArrBalance,ItemRepo,date,result.ResultArrImEx)
  let Result ={result,findItemToEdit}
  return Result
}

const FindItemToEditBalanceBegin = (ArrBalance,ItemRepo,date,valueEdit)=>{
       let FindItem= ArrBalance.filter(v=>v.idTypeMaterial===ItemRepo.idTypeMaterial&&
                        showMMYY(v.dateCreate)===showMMYY(date)
        )
   
    let Find = FindItem[FindItem.length-1]   
    let valueItem = valueEdit[valueEdit.length-1]
    let PriceValue = valueItem.value==1?valueItem.priceEX:valueItem.priceIM
    let PriceGas   = valueItem.ExSumlessMoney
    let item={
        _id:Find._id,
        idTypeMaterial:Find.idTypeMaterial,
        balanceBegin:ItemRepo.nameTypeMaterial==='gas'?1:valueItem.ExSumlessQuantity.toFixed(2),
        priceBalanceBegin:ItemRepo.nameTypeMaterial==='gas'?Math.floor(PriceGas):Math.floor(PriceValue),
        userId:Find.userId,
        dateCreate:Find.dateCreate,
        adjustBalanceBegin:Find.adjustBalanceBegin,
        priceAdjustBalanceBegin:Math.floor(Find.priceAdjustBalanceBegin)
    }
    return item
}

const FilterBalanceBegin =(ArrBalance,ItemRepo,DateInput)=>{
    let Find      = ArrBalance.filter(v=>v.idTypeMaterial===ItemRepo.idTypeMaterial)    
    if(parseInt(showMM(DateInput))===1){
      let FindYYYY = JSON.stringify(parseInt(showYYYY(DateInput)) -1) //doi co thanh chuoi xac dinh nam -1      
      let dateFind = `12/${FindYYYY}`
      let FindFilterMM = Find.filter(v=>showMMYY(v.dateCreate)===dateFind)      
      return FindFilterMM.length!==0?FindFilterMM[0]:0
     }else{       
     let FindFilterMM = Find.filter(v=>showMMYY(v.dateCreate)<showMMYY(DateInput)&&showYYYY(v.dateCreate)===showYYYY(DateInput)) 
     return FindFilterMM[FindFilterMM.length-1]
     }           
}

const HandleDataExportImport = (IMPORT,EXPORT,ItemBalanceBegin)=>{   
    let handleBalanceBegin = HandleBalanceBegin(ItemBalanceBegin)
    let handleAdjustBalanceBegin = HandleAdjustBalanceBegin(ItemBalanceBegin)
    let handleIMPORT = sortArrAsc(HandleImport(IMPORT))
    let handleEXPORT = HandleExport(EXPORT)    
    let ArrBalance =  [handleBalanceBegin,handleAdjustBalanceBegin]    
    let NewArrReuslt = ArrBalance.concat(sortArrAsc(handleIMPORT.concat(handleEXPORT)))
    let ResultImEx  = HandleResultExIm(NewArrReuslt)
    return ResultImEx    
}
    const HandleImport =(Import)=>{
        return Import.map(v=>{
          let newItem ={
            value:0,
            number:"N"+showDD(v.dateImport)+showMM(v.dateImport),
            priceIM:v.price,
            quantityIM:v.quantity,
            content:"Nhập kho",
            date:showDDMMYY(v.dateImport),   
            _id:v._id                 
          }
          return newItem
      })
    }
    const HandleExport=(Export)=>{     
        return  Export.map(v=>{
          let NewItem = {
            value:1,
            number:"X"+showDD(v.dateMeal)+showMM(v.dateMeal),
            priceEX:v.price,
            quantityEX:v.quantity,
            content:"Xuất kho",
            date:showDDMMYY(v.dateMeal),
            _id:v._id
          }
          return NewItem
        })
    }
    const HandleBalanceBegin=(Item)=>{
      console.log(Item)
       let result={
        value:2,
        number:0,
        content:"Số dư đầu năm",
        quantity:(Item===null||Item===undefined)?0:Item.balanceBegin,
        price:(Item===null||Item===undefined)?0:Item.priceBalanceBegin,
        _id:Item!==undefined&&Item._id
     }
     return result
    }
    const HandleAdjustBalanceBegin=(Item)=>{
      let result =  {
          value:3,
          number:0,
          content:"Điều chỉnh số dư đầu năm",
          quantity:Item===null||Item===undefined?0:Item.adjustBalanceBegin,
          price:Item===null||Item===undefined?0:Item.priceAdjustBalanceBegin,
          _id:Item!==undefined&&Item._id        
      }
      return result
    }
   
    const HandleResultExIm =(arr)=>{    
      let ArrImEx = arr.filter(v=>v.value===1||v.value===0)   
      let ArrBalance = arr.filter(v=>v.value!==1&&v.value!==0)          
      let FindImEx = FindResultImEx(ArrImEx,ArrBalance)
      let FindSumLess = FindSumLessMM(FindImEx)      
      let result={ResultArrImEx:ArrBalance.concat(FindImEx),
                  FindSumLess
      }
      return result
    }

    const FindSumLessMM=(SumLessMM)=>{     
      let ArrEx = SumLessMM.filter(v=>v.value===1)
      let ArrIm = SumLessMM.filter(v=>v.value===0)      
      let resultSumLess ={
       SumExTotal : SumTotalEx(ArrEx),
       SumExQuantityTotal: SumTotalExQuantity(ArrEx),      
       SumImTotal  : SumTotalIm(ArrIm),
       SumImQuantityTotal: SumTotalImQuantity(ArrIm),
       SumQuantityTon:SumLessMM[SumLessMM.length-1].ExSumlessQuantity,
       SumMoneyTon:SumLessMM[SumLessMM.length-1].ExSumlessMoney
      }
      return resultSumLess
    }
   
    const FindResultImEx = (ArrImEx,ArrBalance)=>{    
      let tonDauKyQuantity = SUM_QUANTITY(ArrBalance)
      let tonDauKyMoney    = sumTotal(ArrBalance)
      //xu ly them 1 nhap kho ao trong mang nay de ra kq dung
      let arrImEx = HandleItemFirstIM(ArrImEx)

      //xu ly them 1 nhap kho ao trong mang nay de ra kq dung

      
      let ItemQuantity = 0
      let ItemMoney    = 0
      let newArr = arrImEx.map((v,i)=>{       
        if(v.value===1){
          ItemQuantity = ItemQuantity - v.quantityEX
          ItemMoney = ItemMoney - (v.priceEX*v.quantityEX)
          let Item ={
            ...v,
            ExSumlessQuantity:ItemQuantity,
            ExSumlessMoney:ItemMoney
          }
          return Item
        }
         if(v.value===0){  
           let index = arrImEx.indexOf(v)
           let valueIndex = ArrImEx[index-1]
           if(valueIndex!==undefined&&valueIndex){        
            ItemQuantity =  v.quantityIM + ItemQuantity
            ItemMoney    =  (v.priceIM * v.quantityIM) + ItemMoney
            let Item ={
              ...v,
              ExSumlessQuantity:ItemQuantity,
              ExSumlessMoney: ItemMoney,                
            }
            return Item
           }
            ItemQuantity =  v.quantityIM + tonDauKyQuantity
            ItemMoney    =  (v.priceIM * v.quantityIM) + tonDauKyMoney
            let Item ={
              ...v,
              ExSumlessQuantity:ItemQuantity,
              ExSumlessMoney: ItemMoney,                
            }
            return Item
        }
      })        
        if(newArr[0].number===0){
          newArr.splice(0,1)
        }    
      return newArr
    }

    const HandleItemFirstIM =(arr)=>{
        //lay phan dau tien cua mang kiem tra xem co nhap kho hay khong        
        if(arr.length!==0&&arr[0].value!==0){        
          let item =[{
            content:"Nhap Kho",
            number:0,
            priceIM:0,
            quantityIM:0,
            value:0,
          }]
           return item.concat(arr)
        }         
        return arr
    }    
     
    const TotalEx = (arr)=>{
      return arr.map(v=>v.quantityEX*v.priceEX)
    }
    const TotalExQuantity=(arr)=>{
      return arr.map(v=>v.quantityEX)
    }
    const SumTotalEx = (arr)=>{
      let sum = TotalEx(arr)
      return sum.reduce((x,y)=>x+y,0)
    }
    const SumTotalExQuantity=(arr)=>{
      let sum = TotalExQuantity(arr)
      return sum.reduce((x,y)=>x+y,0)
    }
    //xuat

    //nhap
    const TotalIm = (arr)=>{
      return arr.map(v=>v.quantityIM * v.priceIM)
    }
    const TotalImQuantity=(arr)=>{
      return arr.map(v=>v.quantityIM)
    }
    const SumTotalIm =(arr)=>{
      let sum = TotalIm(arr)
      return sum.reduce((x,y)=>x+y,0)
    }
    const SumTotalImQuantity=(arr)=>{
      let sum = TotalImQuantity(arr)
      return sum.reduce((x,y)=>x+y,0)
    }
    //nhap     
//so kho
//list student now
export const HandleNewArrListStudentNow=(item)=>{
  //console.log(item)
 //console.log(item)
    return item.listStudentsNow.map(v=>{
      let newArr={
        _id:v.idStudent,
        fullName:v.fullName,
        idClass:item.idClass,
        idBlockClass:item.idBlockClass,
        idYearSchool:item.idYearSchool,
        userId:item.userId     
      }
      return newArr
    })
}
export const HandleAllArrListStudentNow = (arr)=>{
  if(arr.length!==0){
     let result = arr.map(v=>{
      return  v.listStudentsNow.map(i=>{
      let newArr={
        _id:i.idStudent,
        fullName:i.fullName,
        idClass:v.idClass,
        idBlockClass:v.idBlockClass,
        idYearSchool:v.idYearSchool,
        userId:v.userId
      }
      return newArr
    })
    })
  return _.flatten(result)
  }
  return []  
}
export const HandleAllArrListRegisSeven = (arr)=>{
   let result = arr.map(v=>{
      return  v.nameStudentEatSevenDay.map(i=>{
      let newArr={
        _id:i.idStudent,
        //fullName:i.fullName,
        idClass:v.idClass,
        idBlockClass:v.idBlockClass,
        idYearSchool:v.idYearSchool,
        userId:v.userId
      }
      return newArr
    })
  })
  return _.flatten(result)
}
export const HandleShowListStudentNow=(ListOriginAllStudentNow,StudentClass,date,id)=>{
  let  lastMonth    = showMMYY(handleDateInRepo(date))
  let currentMonth = showMMYY(date)      
  let CheckExistIdClass = StudentClass.some(v=>v.idClass===id)//true co , false khong
  let FilterArrListOriginInLastMonthByIdClass     = ListOriginAllStudentNow.filter(v=>v.idClass===id&&showMMYY(v.dateNow)===lastMonth)
  let FilterArrListOriginInCurrentMonthByIdClass  = ListOriginAllStudentNow.filter(v=>v.idClass===id&&showMMYY(v.dateNow)===currentMonth)      
  let NumStudentCurrent = FilterArrListOriginInCurrentMonthByIdClass.length!==0?FilterArrListOriginInCurrentMonthByIdClass[0].listStudentsNow.length:(<></>) 
  let result= {
     lastMonth,
     currentMonth ,     
     CheckExistIdClass,//true co , false khong
     FilterArrListOriginInLastMonthByIdClass,
     FilterArrListOriginInCurrentMonthByIdClass,      
     NumStudentCurrent 
  }
  return result  
}
export const HandleResultListStudentCurrent=(ListOriginAllStudentNow,StudentClass,date,id)=>{
    let {CheckExistIdClass,FilterArrListOriginInCurrentMonthByIdClass,FilterArrListOriginInLastMonthByIdClass,NumStudentCurrent} = HandleShowListStudentNow(ListOriginAllStudentNow,StudentClass,date,id)
    if(!CheckExistIdClass){ //tra ve rong
      let item={
        arr:[],
        deleteValue:null
      }
      return item         
   }
   if(CheckExistIdClass){
     if(FilterArrListOriginInCurrentMonthByIdClass.length===0&& FilterArrListOriginInLastMonthByIdClass.length===0){
       let item={
         arr:StudentClass,
         deleteValue:true
       }
       return  item
     }       
     if(FilterArrListOriginInLastMonthByIdClass.length!==0&&FilterArrListOriginInCurrentMonthByIdClass.length===0){         
       let item = {
         arr:HandleNewArrListStudentNow(FilterArrListOriginInLastMonthByIdClass[0]),
         deleteValue:0//co su hien dien cua mang hoc sinh trong thang truoc
       }
       return item
     }
      let Item ={
        arr:HandleNewArrListStudentNow(FilterArrListOriginInCurrentMonthByIdClass[0]),
          deleteValue:false
        }         
        return Item
    }
  }

//list student now