const validate = values => {
  const errors = {};

  if(!values.name){
    errors.name = 'Tên không được để trống'
  }else if(values.name.trim().length<6){
      errors.name ='Tên phải từ 6 ký tự trở lên...' 
    }
  if(!values.email){
    errors.email = 'Email không được để trống'
  }
  return errors;
};

export default validate;
