import axiosServices from '../api'
import {API_ENDPOINT} from '../../contant/index'


const urlAddImportTypeMaterial          = 'importTypeMaterial/add'
const urlListImportTypeMaterial         = 'importTypeMaterial/get'
const urlEditImportTypeMaterial         = 'importTypeMaterial/update'
const urlDeleteImportTypeMaterial       = 'importTypeMaterial/delete'
const urlDeleteAndAddImportTypeMaterial ='importTypeMaterial/deleteAndAddImportTypeMaterial'


const urlAddBalance                    = 'importTypeMaterial/balance/add'
const urlListBalance                   = 'importTypeMaterial/balance/get'
const urlUpdateBalance                 = 'importTypeMaterial/balance/update'

export const AddImportTypeMaterial = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddImportTypeMaterial}`,item)
}
export const ListImportTypeMaterial = (id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListImportTypeMaterial}`,id)
}
export const EditImportTypeMaterial = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlEditImportTypeMaterial}`,item)
}
export const DeleteImportTypeMaterial=(item)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlDeleteImportTypeMaterial}`,item)
}
export const DeleteAndAddImportTypeMaterial=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlDeleteAndAddImportTypeMaterial}`,item)
}


//balance
export const AddBalance = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddBalance}`,item)
}
export const ListBalance = (id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListBalance}`,id)
}
export const UpdateBalance = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateBalance}`,item)
}


//balance

