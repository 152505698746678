import { API_ENDPOINT } from "../../../../../contant";
import axiosServices from "../../../../../api-services/api";

const urlCreateInfoGroupQuanTri = 'CreateInfoGroupQuanTri'
const urlAddInfoGroupQuanTri    = 'AddInfoGroupQuanTri'

const urlUpdateBussinesQuanTri  = 'UpdateBussinesQuanTri'
const urlDeleteBussinesQuanTri  = 'DeleteBussinesQuanTri'

const urlAddItemSuppliesQuanTri = 'AddItemSuppliesQuanTri'
const urlUpdateItemSuppliesQuanTri = 'UpdateItemSuppliesQuanTri'
const urlDeleteItemSuppliesQuanTri = 'DeleteItemSuppliesQuanTri'
const urlAddItemCusQuanTriApi   = 'AddItemCusQuanTri'
//PARTMENT
const urlAddPartmentQuanTriApi = 'AddPartmentQuanTri'
const urlUpdatePartmentQuanTriApi = 'UpdatePartmentQuanTri'
const urlDeletePartmentQuanTriApi = 'DeletePartmentQuanTri'
//JOB
const urlAddJobQuanTriApi = 'AddJobQuanTri'
const urlUpdateJobQuanTriApi = 'UpdateJobQuanTri'
const urlDeleteJobQuanTriApi = 'DeleteJobQuanTri'
//CATE
const urlAddCateQuanTriApi    = 'AddCateQuanTri'
const urlDeleteCateQuanTriApi = 'DeleteCateQuanTri'
const urlUpdateCateQuanTriApi = 'UpdateCateQuanTri'

//PRODUCT 
const urlAddProductQuanTriApi    = "AddProductQuanTri"
const urlUpdateProductQuanTriApi = "UpdateProductQuanTri"
const urlDeleteProductQuanTriApi = "DeleteProductQuanTri"

//GET INFO CUS LISTSUPPLIES 
const urlGetInfoCusListSupplies      = "GetInfoCusListSupplies"

const urlDeactiveAccountInfoCusLogin = "DeactiveAccountInfoCusLogin"
const urlActiveAccountInfoCusLogin   = "ActiveAccountInfoCusLogin"
//STAFF

//DETAIL PRODUCT
const urlAddDetailProductQuanTri        = "AddDetailProductQuanTri"
const urlGetDetailProductQuanTri        = "GetDetailProductQuanTri"
const urlUpdateDetailProductQuanTri     = "UpdateDetailProductQuanTri"
const urlChangeHideDetailProductQuanTri = "ChangeHideDetailProductQuanTri"
const urlChangeShowDetailProductQuanTri = "ChangeShowDetailProductQuanTri"
const urlDeleteDetailProductQuanTri     = "DeleteDetailProductQuanTri"
const urlUpdateAvatarDetailProductQuanTri = "UpdateAvatarDetailProductQuanTri"
const urlGetIdLoginInfoCus              = "GetIdLoginInfoCus"
const urlGetListJobQuanTriByCus         = 'GetListJobQuanTriByCus'
//Payment Invoice
const urlUpdatePaymentInvoiceJobStaff      = 'UpdatePaymentInvoiceJobStaff'
const urlUpdateAllPaymentInvoiceJobStaff   = 'UpdateAllPaymentInvoiceJobStaff'
//BAO_GIA_HOP_DONG_NGHIEM_THU
const urlCreateBaoGiaCusJobStaff           = 'CreateBaoGiaCusJobStaff'
const urlSearchBaoGiaCusJobStaff           = 'SearchBaoGiaCusJobStaff'
const urlUpdateBaoGiaCusJobStaff           = 'UpdateBaoGiaCusJobStaff'
const urlEditItemListProductCusJobStaff    = 'EditItemListProductCusJobStaff'
const urlDeleteItemListProductCusJobStaff  = 'DeleteItemListProductCusJobStaff'
const urlDeleteItemRowListProductCusJobStaff = 'DeleteItemRowListProductCusJobStaff'
const urlEditAddItemListProductCusJobStaff = 'EditAddItemListProductCusJobStaff'
const urlUpdateLoginAccountQuanTri         = 'UpdateLoginAccountQuanTri'

const urlUpdateAccountInfoQuanTri      = 'UpdateAccountInfoQuanTri'
const urlUpdateAccountInfoQuanTriAgain = 'UpdateAccountInfoQuanTriAgain'
const urlUpdateJobStaffCongLaoDong  = 'UpdateJobStaffCongLaoDong'
const urlCreateOrGetGiamDinhNghiemThu  = 'CreateOrGetGiamDinhNghiemThu'
const urlUpdateGiamDinhNghiemThu  = 'UpdateGiamDinhNghiemThu'
const urlCompleteHopDong= 'CompleteHopDong'
const urlUnCompleteHopDong= 'UnCompleteHopDong'

export const UnCompleteHopDongApi = item=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUnCompleteHopDong}`,item)
}

export const CompleteHopDongApi = item=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlCompleteHopDong}`,item)
}

export const UpdateGiamDinhNghiemThuApi = item=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateGiamDinhNghiemThu}`,item)
}

export const CreateOrGetGiamDinhNghiemThuApi = item=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlCreateOrGetGiamDinhNghiemThu}`,item)
}

export const UpdateJobStaffCongLaoDongApi = item=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateJobStaffCongLaoDong}`,item)
}

export const UpdateAccountInfoQuanTriAgainApi = item=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateAccountInfoQuanTriAgain}`,item)
}

export const UpdateAccountInfoQuanTriApi = item=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateAccountInfoQuanTri}`,item)
}

export const UpdateLoginAccountQuanTriApi = item =>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateLoginAccountQuanTri}`,item)
}

export const EditAddItemListProductCusJobStaffApi = item=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlEditAddItemListProductCusJobStaff}`,item)
}

export const DeleteItemRowListProductCusJobStaffApi = item=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteItemRowListProductCusJobStaff}`,item)
}

export const DeleteItemListProductCusJobStaffApi=item=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteItemListProductCusJobStaff}`,item)
}
export const EditItemListProductCusJobStaffApi = item=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlEditItemListProductCusJobStaff}`,item)
}
export const UpdateBaoGiaCusJobStaffApi    = item => {
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateBaoGiaCusJobStaff}`,item)
}

export const SearchBaoGiaCusJobStaffApi   =item=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlSearchBaoGiaCusJobStaff}`,item)
}

export const CreateBaoGiaCusJobStaffApi    = item => {
    return axiosServices.post(`${API_ENDPOINT}/${urlCreateBaoGiaCusJobStaff}`,item)
}

export const UpdateAllPaymentInvoiceJobStaffApi = item=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateAllPaymentInvoiceJobStaff}`,item)
}

export const UpdatePaymentInvoiceJobStaffApi = item=>{
      return axiosServices.post(`${API_ENDPOINT}/${urlUpdatePaymentInvoiceJobStaff}`,item)
}

export const GetListJobQuanTriByCusApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetListJobQuanTriByCus}`,item)
}

export const GetIdLoginInfoCusApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetIdLoginInfoCus}`,item)
}

export const UpdateAvatarDetailProductQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateAvatarDetailProductQuanTri}`,item)
}

export const DeleteDetailProductQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteDetailProductQuanTri}`,item)
}

export const ChangeShowDetailProductQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlChangeShowDetailProductQuanTri}`,item)
}

export const ChangeHideDetailProductQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlChangeHideDetailProductQuanTri}`,item)
}

export const UpdateDetailProductQuanTriApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateDetailProductQuanTri}`,item)
}

export const GetDetailProductQuanTriApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetDetailProductQuanTri}`,item)
}

export const AddDetailProductQuanTriApi =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddDetailProductQuanTri}`,item)
}

export const ActiveAccountInfoCusLoginApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlActiveAccountInfoCusLogin}`,item)
}
export const DeactiveAccountInfoCusLoginApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeactiveAccountInfoCusLogin}`,item)
}
export const GetInfoCusListSuppliesApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetInfoCusListSupplies}`,item)
}

export const DeleteProductQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteProductQuanTriApi}`,item)
}

export const UpdateProductQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateProductQuanTriApi}`,item)
}

export const AddProductQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddProductQuanTriApi}`,item)
}

export const DeleteCateQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteCateQuanTriApi}`,item)
}

export const UpdateCateQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateCateQuanTriApi}`,item)
}

export const AddCateQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddCateQuanTriApi}`,item)
}


//PARTMENT
export const DeletePartmentQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeletePartmentQuanTriApi}`,item)
}
export const UpdatePartmentQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdatePartmentQuanTriApi}`,item)
}
export const AddPartmentQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddPartmentQuanTriApi}`,item)
}
//JOB


export const DeleteJobQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteJobQuanTriApi}`,item)
}

export const UpdateJobQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateJobQuanTriApi}`,item)
}

export const AddJobQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddJobQuanTriApi}`,item)
}

export const AddItemCusQuanTriApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddItemCusQuanTriApi}`,item)
}

export const DeleteItemSuppliesQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteItemSuppliesQuanTri}`,item)
}

export const UpdateItemSuppliesQuanTriApi =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateItemSuppliesQuanTri}`,item)
}

export const AddItemSuppliesQuanTriApi=(item)=>{
        return axiosServices.post(`${API_ENDPOINT}/${urlAddItemSuppliesQuanTri}`,item)
}

export const DeleteBussinesQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteBussinesQuanTri}`,item)
}

export const CreateInfoGroupQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlCreateInfoGroupQuanTri}`,item)
}

export const AddInfoGroupQuanTriApi =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddInfoGroupQuanTri}`,item)
}

export const UpdateBussinesQuanTriApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateBussinesQuanTri}`,item)
}