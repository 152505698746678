import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

 const BoxDelete=({open,handleClose,allId,handleDeletePerson})=> {                   
 return (
    <div>     
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
         <DialogContent>
          <DialogContentText>
           Các nội dung đã chọn sẽ bị xóa vĩnh viễn, bạn có chắc chắn???
          </DialogContentText>         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>handleDeletePerson(allId)} color="secondary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default BoxDelete