import QRcode from 'qrcode'
export const GenerateQrcode=async(item)=>{
  try {
  return await QRcode.toDataURL(item)
  } catch (error) {
    console.log(error)
  }
}

export const GenerateQrcodeFile=async(img,item)=>{
  try {
  return await QRcode.toFile(img,item)
  } catch (error) {
    console.log(error)
  }
}