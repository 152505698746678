import { API_ENDPOINT } from '../../../contant'
import axiosServices from '../../../api-services/api'

const urlGetItemListSoftPrice        = 'GetItemListPrice'
const urlGetAddTuyenDung             = 'GetAddTuyenDung'
const urlUpdateTuyenDung             = 'UpdateTuyenDung'

export const GetItemListSoftPriceApi = ()=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetItemListSoftPrice}`)
}

export const GetAddTuyenDungApi=()=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetAddTuyenDung}`)
}

export const UpdateTuyenDungApi =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateTuyenDung}`,item)
}