import styled,{ keyframes} from 'styled-components'
import SuMenhTamNhin from '../../assest/img/sumenhtamnhin.jpg'
import { MainColor } from '../../main/component/color'
import { LINE_TEXT_PX ,WIDTH_IMG} from './contant'
import {device} from './device'

const rotation=keyframes`
from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(359deg);
  }
`

export const ContentUs = styled.div`
    margin-top:5px;
    ${'' /* margin-bottom:20px; */}
    margin-left:auto;
    margin-right:auto;
    width:100%;    
`

export const  ContentTriTue=styled.div`  
                top:0;
                left:0;              
                width:100%;
                margin-top:0px;                            
                height:1000px;
                padding-bottom:20px;
                margin-bottom:0px;
                background:url(${SuMenhTamNhin}) center center /  cover no-repeat;
                position:relative;
                margin-left:auto;
                margin-right:auto;
`

export const BGContentTriTue=styled.div`
                margin-left:auto;
                margin-right:auto;
                width:100%;
                height:100%;                
                z-index:2;
                background-color:rgba(0,0,0,0.6);
                position:absolute;
`

export const ContentUsWithBg = styled.div`   
        z-index:3;                        
        position:absolute; 
        width:100%; 
`

export const ContentSection = styled.div`  
        padding-top:200px;        
        width:60%;
        margin:auto;
        @media ${device.mobile}{
                padding-top:0;                
        }
        @media ${device.tablet}{
                padding-top:0;                
        }
`


export const SectionHeadingUs=styled.h2`
        text-align:center;
        font-size:30px;
        font-weight:700;
        letter-spacing:2px;
        color:${MainColor};
        @media ${device.mobile}{
                font-size:20px;            
        }
        @media ${device.tablet}{
                font-size:20px;          
        }
`


export const SectionLine = styled.div`
    display:inline-block;
    text-align:center;
    width:${LINE_TEXT_PX}px;
    height:3px;
    background:${MainColor};
    margin-left:calc(50% - ${LINE_TEXT_PX}px + ${LINE_TEXT_PX/2}px);    margin-bottom:15px;   
`


export const SectionSubHeading = styled.p`
    text-align:center;
    font-size:17px;
    color:white;
    text-align:justify;
    line-height:1.6rem;
`

export const LogoImg=styled.div`
        display:inline-block;
        text-align:center;  
        margin-left:calc(50% - ${WIDTH_IMG}px + ${WIDTH_IMG/2}px);    
        ${'' /* border:2px solid ${MainColor}; */}
        ${'' /* border-radius:3px;     */}
         ${'' /* animation: ${rotation} 5s infinite linear;             */}
`

export const IMGCenter = styled.img`
        height:80px;              
`
