import React,{useState} from 'react';
import Switch from '@material-ui/core/Switch';
import {useDispatch} from 'react-redux'
import {FindItemOfAppActiveToSave} from '../../Fn'
import {DISPATCH_UPDATE_ITEM_APP_ACTIVE} from '../../../../../APP_ADMIN_PERSON/Contant/Admin/AppTypeOfService'

const Switches=({Value,ValueActive,AppActive})=> { 
  const [state, setState] = useState({CheckActive:ValueActive})  
  const dispatch = useDispatch()   
  const handleChange = value =>event=> {
    setState({ ...state, [value]: event.target.checked });  
    let item = FindItemOfAppActiveToSave(Value,!ValueActive,AppActive)
    dispatch({
      type:DISPATCH_UPDATE_ITEM_APP_ACTIVE,
      payload:{item}
    })
  };  
  
  return (
    <>
      <Switch
        checked={state.CheckActive}
        onChange={handleChange('CheckActive')}
        defaultValue={"CheckActive"}
        inputProps={{ 'aria-label': 'secondary checkbox' }}       
      />        
    </>
  );
}

export default Switches

