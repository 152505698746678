    import {SHOW_CONTACT_WEB,CLOSE_CONTACT_WEB} from '../contant/Contact'

    let initialState={
        OpenContactWeb:false
    }    
  
    
    const IndexReducer =(state=initialState,action)=>{
                switch (action.type) {
                case SHOW_CONTACT_WEB:
                    return{
                        ...state,
                        OpenContactWeb:true
                    }
                case CLOSE_CONTACT_WEB:
                    return{
                        ...state,
                        OpenContactWeb:false
                    }
                    default: return state
                }
    }
    
    export default IndexReducer