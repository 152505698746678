import React from 'react';
import { TinhNangMain,BGShadow, ListTinhNang,ListTinhNangIn, ItemTinhNang } from './style';
import NutrikidImg from './imgMeal/nutrikid.jpg'
import KiemThucImg from './imgMeal/kiemthuc.jpg'
import ThuChiImg   from './imgMeal/thuChi.jpg'
import WebsiteImg from './imgMeal/website.jpg'
import CanDoImg from './imgMeal/cando.jpg'
import { MainColor } from './color';

const Detail = React.memo(() => {
    return (
        <TinhNangMain id="san-pham" class="main">
            <BGShadow class="imgMain"></BGShadow>
            <ListTinhNang class="list">     
                     <h2 style={{textAlign:"center",fontWeight:'bolder',fontSize:30,color:`${MainColor}`,marginTop:30,marginBottom:30}}>TÍNH NĂNG KIDTOP</h2>                              
                     <ListTinhNangIn>
                            <ItemTinhNang class="item">                                       
                                        <img style={{height:120,marginTop:20}} src={NutrikidImg} alt="nutrikid-nutrikids-tinh-khau-phan-an"/>                                    
                                        <h3 style={{color:`${MainColor}`,fontWeight:'bold',fontSize:20}}>NUTRIKIDS</h3>
                                        <p style={{display:'block',width:'90%',margin:'auto',color:'#ecf0f1'}}>
                                        Kiểm soát lượng dinh dưỡng trong bữa ăn cho mọi độ tuổi dễ dàng hơn bao giờ hết. Phần mềm cung cấp các chỉ số dinh dưỡng để giúp người sử dụng có thể đánh giá chất lượng và cải thiện chế độ ăn uống.
                                        </p>
                                        <div style={{width:'90%',height:3,background:'#f5f6fa',margin:'auto',marginTop:16}}></div>
                             </ItemTinhNang>
                             <ItemTinhNang class="item">                                       
                                        <img style={{height:120,marginTop:20}} src={KiemThucImg} alt="nutrikid-nutrikids-tinh-khau-phan-an"/>                                    
                                        <h3 style={{color:`${MainColor}`,fontWeight:'bold',fontSize:20}}>KIỂM THỰC 3 BƯỚC</h3>
                                        <p style={{display:'block',width:'90%',margin:'auto',color:'#ecf0f1'}}>
                                            Giúp kiểm tra thực phẩm đảm bảo an toàn cho sức khỏe, cung cấp các công cụ để kiểm tra độ tươi, độ chín, nguồn gốc và quy trình sản xuất của thực phẩm, giúp người dùng có được thông tin chính xác và đầy đủ nhất.       
                                        </p>
                                        <div style={{width:'90%',height:3,background:'#f5f6fa',margin:'auto',marginTop:16}}></div>
                             </ItemTinhNang>                             
                              <ItemTinhNang class="item">                                       
                                        <img style={{height:120,marginTop:20}} src={ThuChiImg} alt="nutrikid-nutrikids-tinh-khau-phan-an"/>                                    
                                        <h3 style={{color:`${MainColor}`,fontWeight:'bold',fontSize:20}}>QUẢN LÝ THU CHI</h3>
                                        <p style={{display:'block',width:'90%',margin:'auto',color:'#ecf0f1'}}>
                                        Quản lý tài chính trở nên đơn giản và hiệu quả. Phần mềm cung cấp các công cụ để quản lý các khoản thu và chi tiêu, giúp người dùng theo dõi tài chính một cách nhanh chóng và chính xác.                    
                                        </p>
                                        <div style={{width:'90%',height:3,background:'#f5f6fa',margin:'auto',marginTop:35}}></div>
                             </ItemTinhNang>  
                             <ItemTinhNang class="item">                                       
                                        <img style={{height:120,marginTop:20}} src={CanDoImg} alt="nutrikid-nutrikids-tinh-khau-phan-an"/>                                    
                                        <h3 style={{color:`${MainColor}`,fontWeight:'bold',fontSize:20}}>CÂN ĐO</h3>
                                        <p style={{display:'block',width:'90%',margin:'auto',color:'#ecf0f1'}}>
                                            Tính Toán Cân Đo sẽ không bị mất thời gian, giúp người dùng theo dõi và xử lý, tổng hợp số liệu, cân đo của từng đối tượng được nhanh chóng và chính xác.                    
                                        </p>
                                        <div style={{width:'90%',height:3,background:'#f5f6fa',margin:'auto',marginTop:35}}></div>
                             </ItemTinhNang>  
                             <ItemTinhNang class="item">                                       
                                        <img style={{height:120,marginTop:20}} src={WebsiteImg} alt="nutrikid-nutrikids-tinh-khau-phan-an"/>                                    
                                        <h3 style={{color:`${MainColor}`,fontWeight:'bold',fontSize:20}}>TẠO WEBSITE</h3>
                                        <p style={{display:'block',width:'90%',margin:'auto',color:'#ecf0f1'}}>
                                            Tạo Website giúp cho bạn tiết kiệm chi phí xây dựng 1 website, với vài thao tác bạn sẽ có được 1 website chuyên nghiệp và nhanh chóng với chi phí thấp nhất
                                        </p>
                                        <div style={{width:'90%',height:3,background:'#f5f6fa',margin:'auto',marginTop:35}}></div>
                             </ItemTinhNang>  
                    </ListTinhNangIn>                    
            </ListTinhNang>
        </TinhNangMain>
    );
});

export default Detail;
