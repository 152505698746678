export const MenuCap = [
    {value:1,label:'Tổng Giám Đốc'},
    {value:2,label:'Giám Đốc'},
    {value:3,label:'Trưởng Phòng'},
    {value:4,label:'Trưởng Nhóm'},
    {value:5,label:'Nhân Viên'},
    {value:6,label:'Cộng Tác Viên'},
    {value:7,label:'Khách Hàng'},
]

export const Partner =[
    {value:1,label:'HOLDING GROUP'},
    {value:2,label:'HOLDING TECHNOLOGY'},
    {value:3,label:'HOLDING TRAVEL'},
    {value:4,label:'HOLDING SOFT'},
    {value:5,label:'HOLDING MEDIA'},
    {value:8,label:'HOLDING INTERIOR'},
    {value:9,label:'HOLDING LAND'},
    {value:10,label:'HOLDING SERVICE'},
    {value:11,label:'HOLDING ACADEMY'},
    {value:12,label:'HOLDING FUND'},
    {value:6,label:'GDT INTERIOR'},
    {value:7,label:'GDT ADVICE'},
    {value:13,label:'GDT FINANCE'},
    {value:14,label:'GDT ACCOUNTING'},
    {value:15,label:'GDT INSURANCE'},
    {value:16,label:'GDT FUND'},
    {value:17,label:'GDT BANK'},
    {value:17,label:'GDT STARTUP-MENTOR'},
    {value:19,label:'GDT SOFT'},
    {value:20,label:'GDT TECHNOLOGY'},
    {value:21,label:'GDT CRM-ERP-AGI'},
    {value:22,label:'GDT E-COMMERCE'},
    {value:23,label:'GDT WEB'},
    {value:24,label:'GDT DROPSHIPPING'},
    {value:25,label:'GDT AFFILIATE'},
    {value:26,label:'GDT MEDIA'},
    {value:27,label:'GDT ADVERTISING'},
    {value:28,label:'GDT PROMOTION'},
    {value:29,label:'GDT MARKETING'},
    {value:30,label:'GDT EVENT'},
    {value:31,label:'GDT RETAIL'},
    {value:33,label:'GDT MART'},
    {value:34,label:'GDT CONSTRUCTION'},
    {value:35,label:'GDT ARCHITECT'},
    {value:36,label:'GDT M-E'},
    {value:37,label:'GDT PAINT'},
    {value:38,label:'GDT INTERIOR'},
    {value:39,label:'GDT EQUIPMENT'},
    {value:40,label:'GDT ELECTRONIC'},
    {value:41,label:'GDT RESORT'},
    {value:42,label:'GDT HOTEL'},
    {value:43,label:'GDT VILLA'},
    {value:44,label:'GDT VILLAGE'},
    {value:45,label:'GDT RESTAURANT'},
    {value:46,label:'GDT SERVICE'},
    {value:47,label:'GDT COFFEE'},
    {value:48,label:'GDT ELEVATOR'},
    {value:49,label:'GDT AGARWOOD'},
    {value:50,label:'GDT FENG SHUI'},
    {value:51,label:'GDT LAND'},
    {value:52,label:'GDT TRAVEL'},
    {value:53,label:'GDT RENTAIL'},
    {value:54,label:'GDT OFFICE & HR'},
    {value:55,label:'GDT SCHOOL'},
    {value:56,label:'GDT EDUCATION'},
    {value:57,label:'GDT SPORT'},
    {value:58,label:'GDT BOOK'},
    {value:59,label:'GDT ADVENTURE'},
    {value:60,label:'GDT GYM'},
    {value:61,label:'GDT ACADEMY'},
    {value:62,label:'GDT DELIVERY'},
    {value:63,label:'GDT CAR'},
    {value:64,label:'GDT TAXI'},
    {value:65,label:'GDT BICYCLE'},
    {value:66,label:'GDT MOTORBIKE'},
    {value:67,label:'GDT VEHICLES'},
    {value:68,label:'GDT TICKETS'},
    {value:69,label:'GDT PAPERS'},
    {value:70,label:'GDT CLOTHES'},
    {value:71,label:'GDT BAG'},
    {value:72,label:'GDT CAMERA'},
    {value:73,label:'GDT FLYCAM'},
    {value:74,label:'GDT STATIONERY'},
    {value:75,label:'GDT LAUNDRY'},
    {value:76,label:'GDT SOUVENIR'},
    {value:77,label:'GDT UNIFORM'},
    {value:78,label:'GDT FOOTWEAR'},
    {value:79,label:'GDT MOBILE'},
    {value:80,label:'GDT GOLD'},
    {value:81,label:'GDT WATCH'},
    {value:82,label:'GDT QUARTZ'},

]

export const MemberCards =[
    {value:1,label:'Hạng G'},
    {value:2,label:'Hạng Đ'},
    {value:3,label:'Hạng T'},
    {value:4,label:'Hạng GĐT'},    
]

export const Points = [
        {value:1,label:'Hạng G'},
        {value:2,label:'Hạng Đ'},
        {value:3,label:'Hạng T'},
        {value:4,label:'Hạng GĐT'}
]

export const Sex =[
    {value:1,label:'Nam'},
    {value:2,label:'Nữ'},
    {value:3,label:'LGBT'}
]

export const Thu_Nhap =[
    {value:1,label:'Rất Giàu'},
    {value:2,label:'Giàu'},
    {value:3,label:'Khá giả'},
    {value:5,label:'Trung bình'},
    {value:6,label:'Thấp'}
]

export const Tuoi =[
    {value:1,label:'Cao tuổi'},
    {value:2,label:'Trung niên'},
    {value:3,label:'Trưỏng thành'},
    {value:4,label:'Thanh niên'},
    {value:5,label:'Thiếu niên'},
    {value:6,label:'Thiếu Niên'},
]


export const Hon_Nhan = [
    {value:1,label:'Độc Thân'},
    {value:2,label:'Thành Hôn'},
    {value:3,label:'Ly Thân'},
    {value:4,label:'GĐ 1 con'},
    {value:5,label:'GĐ 2 con'},
    {value:6,label:'GĐ 3 con'},    
]

export const CO_HOI =[
    {value:1,label:'Đang Hoạt Động'},
    {value:2,label:'Thành công'},
    {value:3,label:'Thất bại'},
    {value:4,label:'Lạnh'},
    {value:5,label:'Ấm'},
    {value:6,label:'Nóng'},
]

export const HandleCoHoi=(key)=>{
    switch (key) {
        case 1: return "Đang Hoạt Động"
        case 2: return "Thành công"
        case 3: return "Thất bại"
        case 4: return "Lạnh"
        case 5: return "Ấm"
        case 6: return "Nóng"           
    
        default:
    }
}

export const Tinh_Cach =[
    {value:1,label:'Khó chịu'},
    {value:2,label:'Dễ tính'},
    {value:3,label:'Bình Thường'},
    {value:4,label:'Nóng tính'},
    {value:5,label:'Thân thiện'},
]

export const Nguon_Co_Hoi =[  
        {value:1,label:'Giới thiệu'},
        {value:2,label:'FaceBook'},
        {value:3,label:'FANPAGE'},
        {value:4,label:'Website'},
        {value:5,label:'TIKTOK'},
        {value:6,label:'YOUTUBE'},                    
        {value:7,label:'EMAIL'},     
]

export const HandleNguonCoHoi=(key)=>{
    switch (key) {
        case 1:return "Giới thiệu"
        case 2:return "FaceBook"
        case 3:return "FANPAGE"
        case 4:return "Website"
        case 5:return "TIKTOK"
        case 6:return "YOUTUBE"
        case 7:return "EMAIL"            
    
        default:
    }
}


export const HandleGioiTinh =(key)=>{
    switch (key) {
        case 1:return "NAM"
        case 2:return "NỮ"
        case 3:return "LGBT"
            
    
        default: 
    }
}

export const HandleTinhCach=(key)=>{
    switch (key) {
        case 1: return "Khó chịu"
        case 2: return "Dễ tính"
        case 3: return "Bình Thường"
        case 4: return "Nóng Tính"
        case 5: return "Thân Thiện"                 
    
        default:
    }
}

export const HandleThuNhap=(key)=>{
    switch (key) {
        case 1: return "Rất Giàu"
        case 2: return "Giàu"
        case 3: return "Khá giả"
        case 4: return "Trung bình"
        case 5: return "Thấp"                
    
        default:
    }
}

export const HandleHonNhan=(key)=>{
    switch (key) {
        case 1: return "Độc Thân"
        case 2: return "Thành Hôn"
        case 3: return "Ly Thân"
        case 4: return "GĐ 1 con"
        case 5: return "GĐ 2 con"        
        case 6: return "GĐ 3 con"          
    
        default:
    }
}

