import { DISPATCH_ADD_FIRST_TABLE_AGE_TO_STORE,DISPATCH_ADD_TABLE_AGE_TO_STORE } from "../contant/tableAge";

export const DispatchAddFirstTableAgeToStore=(item)=>{
    return{
        type:DISPATCH_ADD_FIRST_TABLE_AGE_TO_STORE,
        payload:{item}
    }
}

export const DispatchAddTableToStore=(item)=>{
    return{
        type:DISPATCH_ADD_TABLE_AGE_TO_STORE,
        payload:{item}
    }
}