import { Button, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DISPATCH_ITEM_TYPE_CAP_TO_STORE } from '../../../contant/CRM';
import OpenAddComponent from './dialogKh'
//import { MenuCap } from './fn';
import ListCrmComponent from './ListCrm'
//import { ItemDiv, TextName } from './style';
import {useDispatch} from 'react-redux'

const Index = () => {
    //const dispatch = useDispatch()
    const [openAddCrm,setOpenAddCrm] = useState(false)
 
        return (
        <div>           
            <div style={{display:'flex',justifyContent:'flex-start'}}>
                    {!openAddCrm?<Button size="small" color="primary" variant='contained' onClick={()=>setOpenAddCrm(true)}>Tạo Mới CRM</Button>:
                    <Button size="small" color="secondary" variant='contained' onClick={()=>setOpenAddCrm(false)}>Quay Lại</Button>                    
                    }
            </div>            

            {openAddCrm?
            <OpenAddComponent
                open={openAddCrm}
                handleClose={()=>setOpenAddCrm(false)}
            />
            :
            <ListCrmComponent/>}
        </div>
    );
};

export default Index;