import { DISPATCH_ALL_MEAL_NUTRIKID_TO_STORE,DISPATCH_ADD_MEAL_NUTRIKID_TO_STORE_LIBRARY ,DISPATCH_VALUE_ADD_MEAL_WEEK} from "../contant/LibraryMeal";

export const DispatchAllMealButrikidToStore=(item)=>{
    return{
        type:DISPATCH_ALL_MEAL_NUTRIKID_TO_STORE,
        payload:{item}
    }
}

export const DispatchAddMealNutrikidToStoreLibrary=(item)=>{
    return{
        type:DISPATCH_ADD_MEAL_NUTRIKID_TO_STORE_LIBRARY,
        payload:{item}
    }
}

export const DispatchValueAddMealWeek=(item)=>{
        return {
            type:DISPATCH_VALUE_ADD_MEAL_WEEK,
            payload:{item}
        }
}