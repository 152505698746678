export const DISPATCH_SELECT_REPO = "DISPATCH_SELECT_REPO"
export const OPEN_SELECT_REPO     = "OPEN_SELECT_REPO"
export const CLOSE_SELECT_REPO    = "CLOSE_SELECT_REPO"
export const DISPATCH_SELECT_CREATE_REPO_TO_STORE = "DISPATCH_SELECT_CREATE_REPO_TO_STORE"


//supplies 
export const ADD_SUPPLIES = "ADD_SUPPLIES"

export const ADD_SUPPLIES_SUCCESS_NOTIFICATION = "ADD_SUPPLIES_SUCCESS_NOTIFICATION"
export const CLOSE_SUPPLIES_SUCCESS_NOTIFICATION="CLOSE_SUPPLIES_SUCCESS_NOTIFICATION"

 


export const OPEN_EDIT_SUPPLIES = "OPEN_EDIT_SUPPLIES"
export const CLOSE_EDIT_SUPPLIES ="CLOSE_EDIT_SUPPLIES" 
export const OPEN_ADD_SUPPLIES = "OPEN_ADD_SUPPLIES"
export const CLOSE_ADD_SUPPLIES = "CLOSE_ADD_SUPPLIES"
export const GET_LIST_SUPPLIES  = "GET_LIST_SUPPLIES"

export const OPEN_ADD_CUSTOMER  = "OPEN_ADD_CUSTOMER"
export const CLOSE_ADD_CUSTOMER = "CLOSE_ADD_CUSTOMER"



