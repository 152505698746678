import React, { Suspense } from 'react';
import {MenuItem,FormHelperText,FormControl,Select, Button, Typography} from '@material-ui/core';
import {useStyles} from './styles'
import {FastRewind,Save} from '@material-ui/icons'
import {FilterDupplicateItem,HandleShowPrice} from '../../../../../helper/GovLogin/index'
import {handleNumberDot} from '../../../../../helper/cost/costStudent'
import {useSelector,useDispatch} from  'react-redux'
import {ADD_INFO_USER_DETAIL,EDIT_ITEM_INFO_USER_DETAIL,CLOSE_SETTING_ADVANCE,EDIT_ARR_INFO_USER_DETAIL} from '../../../../../contant/Gov/login'
import {CheckItemToInsertInfoUserDetail,handleEditInfoUserDetail} from '../../../../../helper/GovLogin/index'

const Index = ({Item,Return})=> {
  const dispatch = useDispatch()
  const classes = useStyles();
  const NewArr = FilterDupplicateItem(Item.Arr)   
  const userId= useSelector(state=>state.GovLogin.userId)
  const ListInfoUserDetail = useSelector(state=>state.GovLogin.ListInfoUserDetail)
  const ShowPriceHTML=(item,arr)=>{
      let FindPrice = HandleShowPrice(item,arr)     
      return(<>
             <Select                        
              label="Chọn giá" 
              onChange={handleChange}   
              defaultValue={""}
            >
              {FindPrice.map((v,i)=>{
                return(
                <MenuItem value={v} key={i} >{handleNumberDot(v.price)}</MenuItem>
                )
              })}            
             </Select>                
             </>
      )
  }
  const handleChange=(e)=>{
      let ITEM = e.target.value
      let item={
        userId,
        price:ITEM.price,
        name:ITEM.name,
        typeOfCountEat:+Item.value, //mam non hay tieu hoc
        edit:false
      }    
      let CheckResult = CheckItemToInsertInfoUserDetail(ListInfoUserDetail,item)
      if(CheckResult==="done"){
        return true
      } 
      if(CheckResult==="edit"){
        let find = handleEditInfoUserDetail(ListInfoUserDetail,item)
        let itemEdit={
          _id:find[0]._id,
          userId:find[0].userId,
          price:item.price,
          name:find[0].name,
          typeOfCountEat:item.typeOfCountEat,
          edit:false
        }
        dispatch({
          type:EDIT_ITEM_INFO_USER_DETAIL,
          payload:{itemEdit}
        })
        return true
      }
       if(CheckResult==="add"||!ListInfoUserDetail.length){
         dispatch({
          type:ADD_INFO_USER_DETAIL,
          payload:{item}
        })
        return true
      }     
  }

  const showItemHTML =()=>{
    return NewArr.map((v,i)=>{
      return(     
       <FormControl className={classes.formControl} key={i}>
          {ShowPriceHTML(v,Item.Arr)}        
       <FormHelperText>{v}</FormHelperText>
      </FormControl>      
    )}     
    )
  } 

  const handleReturn=()=>{
      Return()
  }

  const CreateSave=()=>{
      let newArr = ListInfoUserDetail.map(v=>{
         let item={
          _id:v._id,
          userId:v.userId,
          price:v.price,
          typeOfCountEat:v.typeOfCountEat,
          name:v.name,
          edit:true
        }
        return item
      })
      dispatch({
        type:CLOSE_SETTING_ADVANCE
      })
      dispatch({
        type:EDIT_ARR_INFO_USER_DETAIL,
        payload:{newArr}
      })
  }

  return (
    <Suspense fallback={()=>{}}>
        <div>      
            {showItemHTML()}
        </div>
          <Typography component="div" className={classes.buttonReturn}>
            <Button color="secondary" size="small" onClick={()=>handleReturn()} className={classes.buttonForward}>
                <FastRewind/>
              </Button>
              <Button color="primary" size="small" onClick={()=>CreateSave()} className={classes.buttonSave}>
                <Save/>
              </Button>
          </Typography>
    </Suspense>
  );
}

export default React.memo(Index)