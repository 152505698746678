//xac dih diem neo,thay doi gia tri de hien thi
import React from 'react'
import {withStyles} from '@material-ui/styles'
import {compose} from 'redux'
import { connect } from 'react-redux';
import style from './style'
import{AppBar,
  Toolbar,
  IconButton,
  Typography,
  MenuItem, 
  Menu
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import MoreIcon from '@material-ui/icons/MoreVert'
import {AccountCircle} from '@material-ui/icons'
import {AUTHORIZATION_KEY} from '../../../contant/index'
import axiosService from '../../../api-services/api'
import {withRouter} from 'react-router'


const mobileMenuId = "primary-search-account-menu-mobile"
const menuId ="primary-search-account-menu"

class Index extends React.Component {
  constructor(props){
        super(props)
        this.state={
          mobileMoreAnchorEl:null,        
          anchorEl:null,      
        }
  }


  handleProfileMenuOpen = (event)=>{   
      this.setState({
        mobileMoreAnchorEl:event.currentTarget
      })
  }

  handleMobileMenuOpen=(event)=>{    
      this.setState({
        anchorEl:event.currentTarget
      })
  }

  handleMobileMenuClose = ()=>{
   this.setState({
      mobileMoreAnchorEl:null
   })
  }
  
  handleMenuClose = ()=>{
     this.setState({
      anchorEl:null
     })
  }

  handleToggleSideBar=()=>{
    const { onToggleSidebar, open } = this.props;
    if (onToggleSidebar) {
      onToggleSidebar(!open);
    }
  }

  handleLogout = () => {
    localStorage.removeItem(AUTHORIZATION_KEY);
    axiosService.removeHeader('Authorization');
    const { history } = this.props;
    if (history) {
      history.push('/login');
    }
  };

  renderMobileMenu=()=>{  
    let {mobileMoreAnchorEl}=this.state   
    let isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
    return ( <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={this.handleMobileMenuClose}
    >      
      <MenuItem onClick={this.handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={this.handleLogout}
        >
          <AccountCircle />
        </IconButton>
       <p>Đăng xuất</p>
      </MenuItem>
    </Menu>)
  }

  renderMenu = ()=>{
    let {anchorEl} = this.state
    let isMenuOpen = Boolean(anchorEl)
    return(
      <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={this.handleMenuClose}
    >
      <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
    </Menu>
    )
  }

  render() {
    let {classes ,loginUser
    } = this.props     
    return (
      <div className={classes.grow}>
      <AppBar position="static" color="secondary">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={this.handleToggleSideBar}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
          {loginUser.name}-{loginUser._id}
          </Typography>          
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>            
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={this.handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={this.handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {this.renderMobileMenu()}
      {this.renderMenu()}
    </div>
    );
  }
}

const mapStateToProps = state =>{
  return{
      loginUser: state.users.loginUser
  }
}

const mapDispatchStateToProps =  null

const withConnect = connect(
  mapStateToProps,
  mapDispatchStateToProps
)


export default compose(
  withStyles(style),
  withConnect
)(withRouter(Index))