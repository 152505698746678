import axiosServices from '../../../api-services/api'
import {API_ENDPOINT}   from '../../../contant/index'

const urlAddCreateRepo     = 'person/addCreateRepo'
const urlListAllCreateRepo = 'person/addCreateRepo/idPerson'
const urlUpdateCreateRepo  = 'person/updateCreateRepo'

const urlAddSupplies       = 'person/addCreateSupplies'

const urlEditSupplies      = 'person/editSupplies'
const urlGetListSuppliesByUserIdRepo ='person/getListSuppliesByUserIdRepo'
const urlAddCustomer       = 'person/AddCustomer'
const urlGetCustomer       = 'person/GetCustomer'
const urlEditCustomer      = 'person/EditCustomer'
const urlUpdateViewInfoRepo = "person/UpdateViewInfoRepo"
const urlGetByIdCustomer    = 'person/GetByIdCustomer'


//create repo
export const AddCreateRepoApi =(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddCreateRepo}`,item)
}
export const ListAllCreateRepoApi=(id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListAllCreateRepo}`,id)
}
export const updateCreateRepoApi=(item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateCreateRepo}`,item)
}
// create repo
export const AddSuppliesApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddSupplies}`,item)
}
export const EditSuppliesApi=(item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlEditSupplies}`,item)
 
}
export const GetListSuppliesByUserIdRePo=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetListSuppliesByUserIdRepo}`,item)
}

export const AddCustomerApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddCustomer}`,item)
}

export const GetCustomerApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetCustomer}`,item)
}

export const EditCustomerApi=(item)=>{
    return axiosServices.put(`${API_ENDPOINT}/${urlEditCustomer}`,item)
}

export const UpdateViewInfoRepoApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlUpdateViewInfoRepo}`,item)
}

export const GetByIdCustomerApi=(item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetByIdCustomer}`,item)
}