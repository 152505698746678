import { DeleteItemById, updateArrById } from "../../../../../helper/fnAllways"
import _ from 'lodash'
import { FilterNameDetailProduct } from "../../component/LOGIN_PAGE/NavBarQuanTri/ComponentQuanTri/CongViec/NhanVien/TraCuu/Fn"

export const UpdateItemInfoQuanTriAgain = (Item,item)=>{
    return [Item].map(v=>{
        return{
            ...v,
            InfoCusSupplies:updateArrById(v.InfoCusSupplies,item)
        }
    })[0]
}

export const UpdateItemInfoQuanTri=(Item,item)=>{
    return [Item].map(v=>{
        return{
            ...v,
            InfoCusSupplies:v.InfoCusSupplies.concat(item)
        }
    })[0]
}
export const HandleNewSearchBaoGia=(arr,Item)=>{
        let index = arr.findIndex(item=>item._id===Item._id)
        if(index!==-1){
            return[
                ...arr.slice(0,index),
                Item,
                ...arr.slice(index+1)
            ]
        }
        if(index===-1)return null
}       

export const DeleteItemListProductCus=(item,payload)=>{    
   return [item].map(v=>{
        return{
            ...v,
            ListProductCus:HandleDeleteItemListProductCus(v.ListProductCus,payload._idVatTu)
        }
    })[0]
}

const HandleDeleteItemListProductCus = (list,item)=>{
    return list.filter(v=>v._id!==item)
}
export const FilterListProductCus =(list,item)=>{
    return list.filter(v=>v._id===item._id)[0]
}

export const HandleUpdateListProductCus =(List,item)=>{
    let findByBaoGia = List.filter(v=>v._id===item.IdBaoGia)
    let FindItem = findByBaoGia.map(v=>{
        return{
            ...v,
            ListProductCus:HandleListProductCus(v.ListProductCus,item)
        }
    })[0]
    let result = HandleNewSearchBaoGia(List,FindItem)
    return result.filter(v=>v!==null)
}

const HandleListProductCus=(list,item)=>{
    let findItem = list.filter(v=>v._id===item._id)
    let newItem = findItem.map(v=>{
        return{
            ...v,
            priceCus:item.priceCus,
            quantity:item.quantity,
            unit:item.unit,
            nameVatTu:item.nameVatTu
        }
    })[0]
    let newUpdate = updateArrById(list,newItem)
    return newUpdate
}

export const HandleListNameBaoGia=(List)=>{
    return List.filter(v=>v.nameJob.includes('báo giá')===true)
}

export const HandleListJobByCus=(list,product)=>{
    let newResult = list.map(v=>{
        return  v.VatTu.map(vv=>{
            return {
                ...vv,
                nameVatTu:FilterNameDetailProduct(vv.idVatTu,product).nameDetailProduct,
                unit:FilterNameDetailProduct(vv.idVatTu,product).unit,
                DateBegin:v.ThoiGianBatDau,
                DateEnd:v.ThoiGianKetThuc,
                Status:v.Status,
                PhongBan:v.PhongBan,
                PaymentCus:v.PaymentCus,
                Invoice:v.Invoice,
                idJobStaff:v._id
            }
        })
    })
    return _.flatten(newResult)
}

export const HandleAddArrJobStaff =(List,ItemList,Item)=>{   
    let updateItemList =[ItemList].map(v=>{
        return{
          ...v,
          VatTu:v.VatTu.concat(Item)
        }
      })[0]    
    return {list:updateArrById(List,updateItemList),item:updateItemList}
}

export const HandleDeleteArrJobStaff =(List,ItemList,Item)=>{   
    let updateItemList =[ItemList].map(v=>{
        return{
          ...v,
          VatTu:HandleDelete(v.VatTu,Item)
        }
      })[0]    
    return {list:updateArrById(List,updateItemList),item:updateItemList}
}

const HandleDelete=(list,item)=>{
        if(item._id!==undefined||item._id!==null){
            console.log(item,'123')
            return list.filter(v=>v._id!==item._id)
        }
        if(item._id===undefined||item._id===null){
            console.log(item,'127')
            return list.filter(v=>v.idVatTu!==item.idVatTu)
        }

}

export const HandleUpdateArrJobStaff =(List,ItemList,Item)=>{   
        let updateItemList =[ItemList].map(v=>{
            return{
              ...v,
              VatTu:updateArrById(v.VatTu,Item)
            }
          })[0]          
          return {list:updateArrById(List,updateItemList),item:updateItemList}
}

export const ListInfoBussinesByBussnies =(item,ItemBussines)=>{
    if(ItemBussines!==null){
        return item.InfoCusSupplies.filter(v=>v.typeOf===0&&v.idNameBussines===ItemBussines._id)
    }
    return []
}

export const ListInfoBussines =(result,item,ItemBussines)=>{
        let newResult = UpdateItemSuppliesQuanTri(result,item)
        return newResult.InfoCusSupplies.filter(v=>v.typeOf===0&&v.idNameBussines===ItemBussines._id)
}

export const UpdateItemSuppliesQuanTri = (result,item)=>{
    return [item].map(v=>{
        return{
            ...v,
            InfoCusSupplies:[result].concat(v.InfoCusSupplies)
        }
    })[0]
}

export const UpdateItemJobQuanTri = (result,item)=>{
    return [item].map(v=>{
        return{
            ...v,
            Job:[result].concat(v.Job)
        }
    })[0]
}

export const UpdateItemProductQuanTri = (result,item)=>{
    return [item].map(v=>{
        return{
            ...v,
            Product:[result].concat(v.Product)
        }
    })[0]
}

export const UpdateItemCateQuanTri = (result,item)=>{
    return [item].map(v=>{
        return{
            ...v,
            Cate:[result].concat(v.Cate)
        }
    })[0]
}

export const UpdateItemPartmentQuanTri = (result,item)=>{
    return [item].map(v=>{
        return{
            ...v,
            Partment:[result].concat(v.Partment)
        }
    })[0]
}

export const UpdateItemQuanTriBussnies =(result,item)=>{   
    return [item].map(v=>{
        return{
            ...v,
            Bussines:[result].concat(v.Bussines)
        }        
    })[0]
}

export const InfoBussines =(result,item)=>{
        return UpdateItemQuanTriBussnies(result,item).Bussines
}