//input them typeofservice
export const AddTypeOfServiceInput = [
  {
    nane:'typeOfService',
    placeholder:'Loại hình kinh doanh',
    variant:'filled',
    type:'text',
  }
]
//edit type of service

export const ColViewTypeOfService = [
  { id: 'name',   label: 'Tên loại hình kinh doanh', minWidth: 220 },
  { id: 'action', label: 'Thao tác', minWidth: 150 },  
]

export const CreateDataColViewTypeOfService=(name, action) =>{
  return { name, action }
}

