import {DISPATCH_VALUE_ADD_TO_STORE,DISPATCH_ADD_SUCCESS_TO_STORE_TYPE_OF_SERVICE,
  DISPATCH_LIST_TYPE_OF_SERVICE_TO_STORE
  } from '../Contant/Admin/AddViewAppService'
  import {DISPATCH_ITEM_ADD_APP_ACTIVE_STORE,DISPATCH_LIST_APP_ACTIVE_TO_STORE,DISPATCH_APP_ACTIVE_BY_USER_WHEN_LOGIN} from '../Contant/Admin/AppTypeOfService'
  
  export const DispatchValueAddToStore =(item)=>{
    return{
      type:DISPATCH_VALUE_ADD_TO_STORE,
      payload:{item}
    }
  }
  
  export const DispatchAddSuccessToStore=(item)=>{
    return{
      type:DISPATCH_ADD_SUCCESS_TO_STORE_TYPE_OF_SERVICE,
      payload:{item}
    }
  }
  
  export const DispatchListTypeOfServiceToStore=(item)=>{
    return{
      type:DISPATCH_LIST_TYPE_OF_SERVICE_TO_STORE,
      payload:{item}
    }
  }
  
  //app active
  export const DispatchItemAddAppActiveStore=(item)=>{
      return {
        type:DISPATCH_ITEM_ADD_APP_ACTIVE_STORE,
        payload:{item}
      }
  }
  
  export const DispatchListAppActiveToStore=(item)=>{
      return{
        type:DISPATCH_LIST_APP_ACTIVE_TO_STORE,
        payload:{item}
      }
  }
  
  export const DispatchAppActiveByUserWhenLogin =(item)=>{
    return{
      type:DISPATCH_APP_ACTIVE_BY_USER_WHEN_LOGIN,
      payload:{item}
    }
  }