import { API_ENDPOINT } from "../../../../../contant";
import axiosServices from "../../../../../api-services/api";

const urlGetInfoCusKhachHang        = "GetInfoCusKhachHang"
const urlGetListJobStaffToKhachHang = 'GetListJobStaffToKhachHang'
const urlGetListHopDongInvoiceToKhachHang = 'GetListHopDongInvoiceToKhachHang'


export const GetListHopDongInvoiceToKhachHangApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetListHopDongInvoiceToKhachHang}`,item)
}

export const GetInfoCusKhachHangApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetInfoCusKhachHang}`,item)
}

export const GetListJobStaffToKhachHangApi = item=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetListJobStaffToKhachHang}`,item)
}

