import React,{useState} from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,Tooltip, Typography} from '@material-ui/core';
import {Check,Visibility}from '@material-ui/icons'
import {useSelector,useDispatch} from 'react-redux'
import {useStyles}from './styles'
import {changeUpperCase} from '../../../helper/fnAllways'
import _ from 'lodash'
import ViewList from '../ManagerAccount/ClassApproval/ViewList'
import {DISPATCH_UPDATE_APPROVAL_CHECK_DAY_FOOD,
  DISPATCH_ADD_CHECK_DAY_FOOD_FROM_MANAGER
}from '../../contant/ManagerAccount'
import {RequestApprovalClassFromManagerClass}from '../ManagerAccount/socket/activeAccount'
import DateName from '../FnComponent/InputDateName'
import {handleResultClass,CheckDayFoodArr,ItemCheckDayFood} from './helper'
import { showDDMMYY } from '../../../helper/dateTime/handleDMY';



export default function Index({list}) { 
  const classes  = useStyles()
  const dispatch = useDispatch()
  const DateInput  = useSelector(state=>state.ManagerAccount.DateInput)
  const ListOriginAllStudentNow = useSelector(state=>state.Student.ListOriginAllStudentNow)
  const ListClass               = useSelector(state=>state.Student.ListClass)  
  const [showView,setShowView]= useState(false)
  const [listStu,setListStu] = useState([])

  // xem
    const handleView =(listStudent)=>{
        setShowView(true)
        setListStu(listStudent)
    }
    const closeView=()=>{
        setShowView(false)
    }
  // xem
  //phe duyet
    const HandleApproval=(item)=>{
        let newItem ={
          ...item,
          status:true,
          messageClass:"",
          messageManager:"Đã phê duyệt chấm ăn",
          type:1
        }       
        dispatch({
          type:DISPATCH_UPDATE_APPROVAL_CHECK_DAY_FOOD,
          payload:{item:newItem}
        })
        //gui socket cho cac lop
        let{checkDayFood} = item       
        if(checkDayFood!==undefined&&checkDayFood.nameStudentsNotEat!==undefined){
           let NewItem = {
            ...checkDayFood,
            nameStudentNotEat:CheckDayFoodArr(checkDayFood.nameStudentsNotEat)
          }
          let ItemAddCheck = ItemCheckDayFood(NewItem)
          dispatch({
            type:DISPATCH_ADD_CHECK_DAY_FOOD_FROM_MANAGER,
            payload:{item:ItemAddCheck}
          })
        }
        //gui socket tu classs active len server
         RequestApprovalClassFromManagerClass(newItem)
    }
  //phe duyet
  const showList =()=>{
      return list.map((v,i)=>{
        let result = handleResultClass(ListClass,v,ListOriginAllStudentNow,DateInput)

         if(result!==undefined){
                return(
                    <TableRow key={i}>
                      <TableCell>
                        {changeUpperCase(result.findNameClass[0].nameClass)}
                      </TableCell>
                      <TableCell>
                      <Tooltip title="xem">
                        <Visibility color="primary" className={classes.Point} 
                        onClick={()=>handleView(result.findListStuNotEat)}
                        />            
                      </Tooltip>
                        <Typography component="span" color="secondary">
                          {result.findListStuNotEat.length}
                          </Typography>
                        </TableCell>
                      <TableCell>                         
                          <Tooltip title="Phê Duyệt">
                              <Check color="primary" className={classes.Point}
                               onClick={()=>HandleApproval(v)}
                              />
                          </Tooltip>
                        </TableCell>
                        <TableCell><DateName DateInput={v.dateApproval}/></TableCell>
                  </TableRow>
                  )
         }  
      })
  }

  return (
    <>      
          {showView&&<ViewList
                open={showView}
                handleClose={closeView}
                listStu = {listStu}
          />}
          <TableContainer component={Paper}>    
            <Table className={classes.table} stickyHeader aria-label="sticky table" size="small" >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Tên Lớp</TableCell>
                  <TableCell align="left">Báo vắng</TableCell>
                  <TableCell align="left">Phê duyệt</TableCell>
                  <TableCell align="left">Ngày Yêu cầu</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>                        
                   {showList()}
              </TableBody>
            </Table>
        </TableContainer>
      </>  
  );
}
