import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import sale20 from './TU20.png'
import { showDDMMYY, showYYMMDD } from '../../../../../../../helper/dateTime/handleDMY';


const color = '#2c3e50'
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


export default function CustomizedDialogs({open,handleClose}) {

  const [dateExpire,setDateExpire] = useState(`2023-09-04`)
  useEffect(()=>{
      if(dateExpire!==showYYMMDD(Date.now())){
        handleClose()
      }
  },[dateExpire])

  return (
    <div>     
      <Dialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle style={{color:'red'}} id="customized-dialog-title" onClose={handleClose}>
          BIG SALE (Chương trình sẽ hết hạn sau ngày {showDDMMYY(dateExpire)})
        </DialogTitle>
        <DialogContent dividers>     
            <img style={{height:300}} src={sale20} alt="dinh-duong-hoc-duong"/>  
        </DialogContent>     
      </Dialog>
    </div>
  );
}
