import {fork,all} from 'redux-saga/effects'
import CreateRepo from './createRepo'
import CateRepo from './cateRepo'
import CategoriesMangerRepo from '../../Person/App/QuanLyKho/MainTab/Categories/Saga/index'
import ManagerStaffSaga from '../../Person/App/QuanlyNhanVien/saga'
import AccountLoginStaff from '../../Person/TaiKhoanNhanVienDangNhap/saga'
import QrcodeSaga from './qrcode'

function* Index(){
  yield all(
    [
      yield fork(CreateRepo),
      yield fork(CateRepo),
      yield fork(CategoriesMangerRepo),
      yield fork(ManagerStaffSaga),
      yield fork(AccountLoginStaff),
      yield fork(QrcodeSaga)
    ]
  ) 
}

export default Index