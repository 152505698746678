import {LIST_COST_STUDENT,ADD_COST_STUDENT_FAIL,ADD_COST_STUDENT_SUCCESS} from '../../../contant/Gov/cost'

export const ListCostStudent = (arr)=>{
  return{
    type:LIST_COST_STUDENT,
    payload:{arr}    
  }
}

export const AddCostStudentSuccess = (item)=>{
  return{
    type:ADD_COST_STUDENT_SUCCESS,
    payload:{item}
  }
}

export const AddCostStudentFail = ()=>{
  return{
    type:ADD_COST_STUDENT_FAIL
  }
}