import React from 'react'
import TaiKhoanNhanVien from '../../../APP_ADMIN_PERSON/Person/TaiKhoanNhanVienDangNhap/component'


const Index = () => {
  return (
   <TaiKhoanNhanVien/>
  )
}

export default Index
