import _ from 'lodash'
import {showMMYY}from '../../helper/dateTime/handleDMY'
import {UpperCase} from '../../helper/fnAllways'
import {sortByNameLast,ArrStudentSort}from '../../helper/tableList/tableList'
export const  handleFindClass =(date,ItemUser,ListClass,ListStudent,ListRegis)=>{
   let user = _.first(ItemUser)   
   if(user!==undefined&&user.role===0&&user.active===true){
      let filterClass         = ListClass.filter(v=>v._id===user.idClass)
      let nameClass             = filterClass.length!==0?UpperCase(filterClass[0].nameClass):""
      let ListStudentByMonth    = ListStudent.filter(v=>showMMYY(v.dateNow)===showMMYY(date)&&v.idClass===filterClass[0]._id)
      let ListStudentNow        = ListStudentByMonth.length!==0?sortByNameLast(ArrStudentSort(ListStudentByMonth[0].listStudentsNow)):[]
      let numStudent            = ListStudentNow.length
      let ListStudentByMonthRegisSevenDay = ListRegis.filter(v=>showMMYY(v.dateMonth)===showMMYY(date)&&v.idClass===filterClass[0]._id)
      return {ListStudentNow,ListStudentByMonthRegisSevenDay,nameClass,numStudent,ListStudentByMonth}
    }
  //hieu pho cap duong
  if(user!==undefined&&user.role===1){
    return ""
  }
  if(user!==undefined&&user.role===0&&user.active===false){
    return false
  }  
  
  //xac dinh mam non hay tieu hoc
}

