import { CREATE_NEW_ACCOUNT_QUANTRI_SUCCESS,CREATE_NEW_ACCOUNT_QUANTRI_FAIL,CLOSE_FORM_AFTER_CREATE_SUCCESS,
    GET_ALL_ACCOUNT_TO_STORE_QUAN_TRI,
    LOGIN_PAGE_QUAN_TRI_FAIL,
    LOGIN_PAGE_QUAN_TRI_SUCCESS,
    UPDATE_ACCOUNT_LOGIN_QUAN_TRI_SUCCESS,
    UPDATE_ACCOUNT_LOGIN_QUAN_TRI_FAIL
} from "../contant/LOGIN_PAGE/QuanTri";

export const UpdateAccountLoginQuanTriSuccess = (item)=>{
    return{
        type:UPDATE_ACCOUNT_LOGIN_QUAN_TRI_SUCCESS,
        payload:{item}
    }
}

export const UpdateAccountLoginQuanTriFail = (item)=>{
    return{
        type:UPDATE_ACCOUNT_LOGIN_QUAN_TRI_FAIL,
        payload:{item}
    }
}

export const LoginPageQuanTriSuccess =(item)=>{
    return{
        type:LOGIN_PAGE_QUAN_TRI_SUCCESS,
        payload:{item}
    }
}

export const LoginPageQuanTriFail =(item)=>{
    return{
        type:LOGIN_PAGE_QUAN_TRI_FAIL,
        payload:{item}
    }
}

export const GetAllAccountToStoreQuanTri=(item)=>{
        return{
            type:GET_ALL_ACCOUNT_TO_STORE_QUAN_TRI,
            payload:{item}
        }
}

export const CreateNewAccountQuanTriSuccess =(item)=>{
        return {
            type:CREATE_NEW_ACCOUNT_QUANTRI_SUCCESS,
            payload:{item}
        }
}

export const CreateNewAccountQuanTriFail =(item)=>{
    return {
        type:CREATE_NEW_ACCOUNT_QUANTRI_FAIL,
        payload:{item}
    }
}

export const CloseFormAfterCreateSuccess =()=>{
    return{
        type:CLOSE_FORM_AFTER_CREATE_SUCCESS
    }
}