import React,{useState,useEffect} from 'react'
import {Fab} from '@material-ui/core'
import {Add} from '@material-ui/icons'
import ListAppAdd from './AddTypeOfService/index'
import {useDispatch, useSelector} from 'react-redux'
import {CLOSE_ADD_TYPE_OF_SERVICE,GET_LIST_TYPE_OF_SERVICE,CLOSE_VIEW_TYPE_OF_SERVICE} from '../Contant/Admin/AddViewAppService'
import FormOpen from '../Helper/FormOpen/index'
import Component from '../Helper/FormOpen/Component_HTML_ACTION'
import {AddHtml} from './AddTypeOfService/HandleActionButton/Input'
import {ViewHtml} from './ViewEditTypeOfService/HandleActionButton/Input'
import ActionButtonAddSave from './AddTypeOfService/HandleActionButton/Button'
import LOADING from '../Helper/FormAlert/Loading/index'
import {CLOSE_ALERT_SUCCESS,CLOSE_DUPPLICATE} from '../Contant/helper'
import {ALERT_DUPPLICATE} from '../Contant/helperText'
import AlertComponent from '../Helper/FormAlert/Alert/index'


const Index = React.memo(() => {  
  const dispatch       = useDispatch()
  const Loading        = useSelector(state=>state.Helper.Loading)
  const AlertOpen      = useSelector(state=>state.Helper.AlertOpen)
  const DupplicateOpen = useSelector(state=>state.Helper.DupplicateOpen)
  const TextAlert      = useSelector(state=>state.Helper.TextAlert)
  const OpenTypeOfService = useSelector(state=>state.AdminAddService.OpenAddTypeOfService)
  const OpenViewTypeOfService = useSelector(state=>state.AdminAddService.OpenViewTypeOfService)

  const [anchorEl, setAnchorEl] = useState(null);
  const [valueDefault,setValueDefault] = useState(false)
  
  useEffect(() => {
    dispatch({
      type:GET_LIST_TYPE_OF_SERVICE
    })   
  },[valueDefault])
  const AddApp = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const CloseAddApp = () => {
    setAnchorEl(null);
  };



  //close Form Add
  const CloseFormAddTypeOfService=()=>{
        dispatch({
          type:CLOSE_ADD_TYPE_OF_SERVICE
        })
  }
  //close Form View
  const CloseFormViewTypeOfService=()=>{
    dispatch({
      type:CLOSE_VIEW_TYPE_OF_SERVICE
    })
  }
  //close Alert text
  const CloseAlert=()=>{
    dispatch({
      type:CLOSE_ALERT_SUCCESS
    })    
  }
  //close dupplicate 
  const CloseDupplicate=()=>{
    dispatch({
      type:CLOSE_DUPPLICATE
    })
  }
  
  return (
    <>
        {/* loading */}
        {Loading&&<LOADING/>}
        {AlertOpen&&(<AlertComponent
                    open={AlertOpen}
                    handleClose={CloseAlert}
                    result     ="success"
                    text       ={TextAlert}
        />)}
        {/* trung lap du lieu */}
        {DupplicateOpen&&<AlertComponent
              open={DupplicateOpen}
              handleClose={CloseDupplicate}
              result     ="error"              
              text       = {ALERT_DUPPLICATE}
        />}
        {/* them type ò service */}
        {OpenTypeOfService&&<FormOpen
              open={OpenTypeOfService}
              handleClose={CloseFormAddTypeOfService}
              ComponentAction = {<Component
                    InputHtml ={AddHtml}
                    ActionButton={<ActionButtonAddSave/>}
              />}
        />}
        {/* view type of service */}
        {OpenViewTypeOfService&&<FormOpen
              open={OpenViewTypeOfService}
              handleClose={CloseFormViewTypeOfService}
              ComponentAction={<Component
                  InputHtml={ViewHtml}
                  ActionButton={<></>}
              />}
        />}

          <Fab size="medium" color="primary" onClick={AddApp}
                      aria-controls="simple-menu"
                     aria-haspopup="true"           
          >
            <Add/>          
          </Fab>     
             <ListAppAdd 
                     anchorEl={anchorEl}
                     handleClose={CloseAddApp}
            />            
      </>
  )
})

export default Index
