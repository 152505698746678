import { makeStyles } from '@material-ui/core/styles'

export const useStyles =makeStyles(theme=>({  
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background:'#74b9ff'
  },
  colorTextOnline:{
    color:'#00b894'
  }
}))