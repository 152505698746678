export const ALERT_YEAR        = "ALERT_YEAR"
export const ALERT_NOTI_YEAR   = "Vui Lòng Khởi Tạo Năm Học"
export const OPEN_ALERT_YEAR   = "OPEN_ALERT_YEAR"
export const CLOSE_ALERT_YEAR  = "CLOSE_ALERT_YEAR"

export const OPEN_INFO_USER_DETAIL     = "OPEN_INFO_USER_DETAIL"
export const CLOSE_INFO_USER_DETAIL    = "CLOSE_INFO_USER_DETAIL"
export const NOTI_INFO_USER_DETAIL     = "Quý Khách chưa khởi tạo các loại phí và số buổi ăn"

export const OPEN_ALERT_TEMPLETE       = "OPEN_ALERT_TEMPLETE"
export const CLOSE_ALERT_TEMPLETE      = "CLOSE_ALERT_TEMPLETE"
export const NOTI_ALERT_TEMPLETE       = "Quý Khách chưa khởi tạo biểu mẫu"

export const OPEN_STUDENT_EAT_SEVEN_DAY = "OPEN_STUDENT_EAT_SEVEN_DAY"
export const CLOSE_STUDENT_EAT_SEVEN_DAY = "CLOSE_STUDENT_EAT_SEVEN_DAY"
export const NOTI_STUDENT_EAT_SEVEN_DAY = "Những lớp chưa đăng ký học thứ 7: "
export const DISPATCH_ARR_STUDENT_EAT_SEVEN_DAY = "DISPATCH_ARR_STUDENT_EAT_SEVEN_DAY"


export const ALERT_OPEN_INFO_COST  =  "ALERT_OPEN_INFO_COST" //thong bao khai bao khoi tao thoong tin ban dau
export const ALERT_CLOSE_INFO_COST  =  "ALERT_CLOSE_INFO_COST" //thong bao khai bao khoi tao thoong tin ban dau

