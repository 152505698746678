

const validate = values =>{
  const errors ={}
  const {idGroup} = values
  //email
  if(!idGroup){
    errors.idGroup = "Email không được để trống"
  }
  return errors  
}

export default validate


