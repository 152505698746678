import axiosServices from '../../../../api-services/api'
import {API_ENDPOINT} from '../../../../contant'

const urlGetInfoStaff = 'person/GetInfoStaff'
const urlUploadImageStaff = 'person/UploadImageStaff'
const urlUpdateInfoStaff  = 'person/UpdateInfoStaff'
const urlGetPaymentStaff  =  'person/GetPaymentStaff'
const urlGetChamCongStaff = 'person/GetChamCongStaff'
const urlAddChamCongStaff = 'person/AddChamCongStaff'
const urlUpdateItemNumber = "person/UpdateItemNumber"
const urlGetManyItem      = 'person/GetManyItem'
const urlAddImportFromStaff        = "person/AddImportFromStaff"
const urlEditImportFromStaff       = "person/EditImportFromStaff"
const urlDeleteEditImportFromStaff = "person/DeleteEditImportFromStaff"
const urlGetListImportByStaff      = "person/GetListImportByStaff"
const urlGetListTaskJobByStaff     = "person/GetListTaskJobByStaff"
const urlUpdateTaskJobByStaff      = "person/UpdateTaskJobByStaff"
const urlConvertReadyToProcessTaskJob = "person/ConvertReadyToProcessTaskJob"
const urlConvertReadyToProcessTaskJobSaveDb = "person/ConvertReadyToProcessTaskJobSaveDb"

const urlUpdateEditDepartment         = 'person/UpdateEditDepartment'
const urlGetListAutoImport            = "person/GetListAutoImport"
const urlStaffSelectTaskJob       = 'person/StaffSelectTaskJob'
const urlDeleteTaskJobByStaff     = 'person/DeleteTaskJobByStaff'
const urlGetListTaskJobStaffByMonth = 'person/GetListTaskJobStaffByMonth'


//cong tac phi
const urlAddPayForTaskJob         = 'person/AddPayForTaskJob'

const urlAddMoreDepartment        = 'person/AddMoreDepartment'



export const GetInfoStaffApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetInfoStaff}`,item)
}

export const UploadImageStaffApi=(item)=>{
    return axiosServices.put(`${API_ENDPOINT}/${urlUploadImageStaff}`,item)
}

export const UpdateInfoStaffApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateInfoStaff}`,item)
}

export const GetPaymentStaffApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetPaymentStaff}`,item)
}

export const GetChamCongApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetChamCongStaff}`,item)
}

export const AddChamCongStaffApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddChamCongStaff}`,item)
}

export const UpdateItemNumberApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateItemNumber}`,item)
}

export const GetManyItemApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetManyItem}`,item)
}

export const AddImportFromStaffApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddImportFromStaff}`,item)
}

export const EditImportFromStaffApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlEditImportFromStaff}`,item)
}

export const DeleteEditImportFromStaffApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteEditImportFromStaff}`,item)
}

export const GetListImportByStaffApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetListImportByStaff}`,item)
}

export const GetListTaskJobByStaffApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetListTaskJobByStaff}`,item)
}

export const ConvertReadyToProcessTaskJobApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlConvertReadyToProcessTaskJob}`,item)
}

export const ConvertReadyToProcessTaskJobSaveDbApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlConvertReadyToProcessTaskJobSaveDb}`,item)
}

export const UpdateEditDepartmentApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateEditDepartment}`,item)
}

export const GetListAutoImportApi=()=>{
    return axiosServices.get(`${API_ENDPOINT}/${urlGetListAutoImport}`)
}

export const StaffSelectTaskJobApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlStaffSelectTaskJob}`,item)
}

export const DeleteTaskJobByStaffApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteTaskJobByStaff}`,item)
}

export const GetListTaskJobStaffByMonthApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetListTaskJobStaffByMonth}`,item)
}

export const AddPayForJobTaskApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddPayForTaskJob}`,item)
}

export const UpdateTaskJobByStaffApi=(item)=>{
        return axiosServices.post(`${API_ENDPOINT}/${urlUpdateTaskJobByStaff}`,item)
}

export const AddMoreDepartmentApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddMoreDepartment}`,item)
}