import {DISPATCH_LIST_PAYMENT_KIDTOP_TO_STORE, DISPATCH_THANH_TOAN_BY_USER_TO_STORE,EMAIL_SEND_NPP_SUCCESS} from '../contant/ThanhToan'

export const DispatchListPaymentKidtopToStore =(item)=>{
    return{
        type:DISPATCH_LIST_PAYMENT_KIDTOP_TO_STORE,
        payload:{item}
    }
}

export const DispatchThanhToanByUserToStore=(item)=>{
    return {
        type:DISPATCH_THANH_TOAN_BY_USER_TO_STORE,
        payload:{item}
    }
}

export const EmailSendNppSuccess=(item)=>{
        return{
            type:EMAIL_SEND_NPP_SUCCESS,
            payload:{item}
        }
}