import uuidv4 from "uuid"
import { TO_CHUC,CA_NHAN } from "../../QuanLyKho/MainTab/Supplies/Customer/fn"


export const LEVEL_STAFF =[
  {value:11,name:"Trưởng Bộ Phận"},
  {value:22,name:"Phó Trưởng Bộ Phận"},
  {value:33,name:"Nhân Viên"},
  {value:33,name:'Giáo Viên'},
  {value:33,name:'Bảo Vệ'},
  {value:33,name:'Y Tế'},
  {value:33,name:'Tạp Vụ'},
  {value:11,name:'Hiệu Trưởng'},
  {value:22,name:'Hiệu Phó'},
  {value:33,name:'Kế Toán'}
]

export const ACTIVE   = "Kích hoạt"
export const DEACTIVE = "Chưa kích hoạt"

export const  NAME_DEPARTMENT = [
  {name:'Phòng Kinh Doanh'},
  {name:'Phòng Kỹ Thuật-Điện Dân Dụng-Công Nghiệp'},
  {name:'Phòng Kỹ Thuật-Công Nghệ Thông Tin'},
  {name:'Phòng Tài Chính'},
  {name:'Phòng Nhân Sự'},
  {name:'Phòng Giám Đốc'},
  {name:'Phòng Phó Giám Đốc'},
  {name:'Hiệu Trưởng'},
  {name:'Hiệu Phó'},
  {name:'Kế Toán'},
  {name:'Giáo Viên'},
  {name:'Bảo Vệ'},
  {name:'Tạp Vụ'},
  {name:'Y Tế'},
]

export const  TECHNICAL_STAFF =[
  {value:1,name:'thay thế'},//sua chua khong bao hanh
  {value:2,name:'bảo trì'},//sua chua co bao hanh
  {value:3,name:'bán mới'}//ban moi 1 thiet bi hoa 1 khôi linh kien
]

export const DICH_VU = "Dịch Vụ Sửa Chữa"
export const BAO_TRI = "Bảo Trì"
export const BAN_MOI = "Bán Mới"

export const STATUS_READY    = "Sẵn Sàng"
export const STATUS_PROCESS  = "Đang Xử Lý"
export const STATUS_COMPLETE = "Hoàn Thành"


export const TYPE_PAYMENT_STAFF = [
  {value:1,name:'lương theo thời gian',detail:"Đây là hình thức trả lương dựa trên số giờ mà người lao động đã hoàn thành. Vì vậy, mức lương này có thể sẽ cố định, trừ khi người lao động xin nghỉ không lương, Thêm vào đó, mức lương này có thể sẽ có thêm vài khoản phụ cấp như: phụ cấp tiền xăng nếu Doanh nghiệp điều đi làm xa,… Theo hình thức trả lương này, người lao động không lo về mức thu nhập theo mỗi tháng, vì số tiền lương trừ là cố định, nghỉ bao nhiêu ngày trừ bấy nhiêu lương theo quy định của công ty.Hình thức này ta cũng thấy phổ biến ở các nơi tuyển nhiều nhân viên part time dành cho sinh viên, Họ làm bao nhiêu giờ, sẽ được tính bấy nhiêu lương"},
  {value:2,name:'lương theo sản phẩm',detail:"Đây là hình thức trả lương gắn liền với chất lượng sản phẩm, số lượng sản phẩm và hiệu quả làm việc. Hình thức này sẽ thúc đẩy người lao động siêng năng hơn, nâng cao năng suất để tích cực tạo ra nhiều sản phẩm hơn. Người giám sát sẽ dựa trên các cơ sở tài liệu về hạch toán kết quả lao động để xem xét các sản phẩm.Hình thức này cũng có thể áp dụng đối với người lao động trực tiếp tạo sản phẩm ra thành phầm, cũng có thể áp dụng với các lao động gián tiếp tạo ra sản phẩm, đây gọi là tiền lương sản phẩm gián tiếp.Ưu điểm của hình thức này: đảm bảo được nguyên tắc phân phối theo chất lượng và khối lượng lao động, khuyến khích người lao động làm việc có năng suất hơn."},
  {value:3,name:'lương khoán',detail:"Trả lương khi người lao động hoàn thành khối lượng công việc theo đúng chất lượng được giao là hình thức này. Hình thức này có thể tính theo thời gian (giờ) trên đơn vị sản phẩm hoặc trên doanh thu, thậm chí là lãi gộp theo tháng. Tiền lương mà mức chi trả của Doanh nghiệp phải cao hơn mức lương tối thiểu của nhà nước"},
  {value:4,name:'lương theo doanh thu',detail:"Đây là hình thức lương-thưởng mà thu nhập người lao động phụ thuộc theo việc đạt được mục tiêu doanh số và chính sách lương-thưởng doanh số của công ty. Hình thức này thường áp dụng cho nhân viên kinh doanh, nhân viên bán thời gian…họ sẽ hưởng lương theo doanh thu.Có thể là lương-thưởng theo doanh số cá nhân, theo nhóm hoặc theo các hình thức thưởng kinh doanh khác: công nợ, phát triển thị trường…"},
  {value:5,name:'hình thức khác',detail:"Không thuộc các trường hợp trên"}//cong tac vien
]

export const TIME_JOB_IN_DAY =[ //thoi gian gio lam viec trong ngay
  {value:1,name:'5h/ngày'},
  {value:2,name:'6h/ngày'},
  {value:3,name:'7h/ngày'},
  {value:4,name:'8h/ngày'},
  {value:5,name:'9h/ngày'},
  {value:6,name:'10h/ngày'},
  {value:7,name:'11h/ngày'},
  {value:8,name:'12h/ngày'},
  {value:6,name:'tăng ca'},
]

export const CheckExistLevel_11=(arr,item)=>{  
    let filterLevel_11 = arr.filter(v=>v.level.toLowerCase()===LEVEL_STAFF[0].name.toLowerCase())  //truong bo phan        
    return filterLevel_11.some(v=>v.department===item.department&&item.level===11)    
}

export const CheckExistLevel_11_Edit=(arr,EditItem,item)=>{ 
  let filterArr = arr.filter(v=>v._id!==EditItem._id)
  return CheckExistLevel_11(filterArr,item)
}

export const FilterNameLevel = (arr,item)=>{
  return arr.filter(v=>v.value===item)[0].name
}

export const TYPE_OF_ALL = "ALL"
export const TYPE_OF_ONE = "ONE"


export const HandleFindStaff=(arrStaff,department)=>{
  let findResult =  arrStaff.map(v=>{
    let index = department.findIndex(vv=>vv.name===v.department.name)
    if(index!==-1){
      return{
          idStaff:v.idStaff
      }
    }
  })
  return(findResult.filter(v=>v!==undefined)).map(v=>{
    return{
      ...v,
      id:uuidv4()
    }
  })
}

export const HandleTaskJob=(value,arr)=>{
    if(value.typeOf===TO_CHUC){
    return  arr.map(v=>{
        return{
          id:uuidv4(),
          idCustomer:value._id,
          idDepartmentCustomer:v._id,
          typeOf:STATUS_READY
        }
      })      
    }
    if(value.typeOf===CA_NHAN){
      return[
        {
          id:uuidv4(),
          idCustomer:value._id,
          typeOf:STATUS_READY
        }
      ]
    }
}

