import { Box,  Card,  Typography,Accordion,AccordionSummary,AccordionDetails} from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {  ChucNangDiv, useStyles } from './styleChucNang';
//import { LOGIN_APP_KIDSTOP } from '../../contant/GioiThieu';
//import {CLOSE_FORM_FUNCTION_ACCOUNT_KIDSTOP} from '../../contant/StateCss'
import {useDispatch,useSelector} from 'react-redux'
import { LOGIN_APP_KIDSTOP, 
        //OPEN_VIDEO_YOUTUBE 
} from '../../contant/GioiThieu';


const DANG_KY_PM = () => {
    const FormFnKidstopCss              = useSelector(state=>state.StateCss.FormFnKidstop)
    const dispatch                      = useDispatch()
    const classes                       = useStyles()

    const RedirectLoginApp=()=>{
               dispatch({type:LOGIN_APP_KIDSTOP})
    }

//     const VTTT_HUYTHANH           = "5Y9xMZWSpTU"
//     const VIDEO_GIOITHIEU_KIDSTOP = "gd_Hqw6wrnc"
//     const VIDEO_3IN1              = "t52duhSx3E0"
//     const VIDEO_HDSD_KISTOP_FULL  = "yBQzP5l-FWM"
//     const VIDEO_CHAM_AN_DIEN_TU   = "aijAWj5S0wU"
//     const VIDEO_QUAN_LY_THU       = "Aen3ciwsLSk"
//     const VIDEO_UPLOAD_EXCEL      = "PmH9rdEiOz4"
//     const VIDEO_KIDTOP            = "VEZmmM6pMpg"
//     const HDSD_P1                 = "5RCP27bEb_8"
//     const HDSD_P2                 = "zxXN_rX7JaY"
//     const HDSD_P3                 = "shFkG8H4cls"
//     const HDSD_P4                 = "Kx6kNlI7vE0"
//     const HDSD_P5                 = "USa3zoPQGXg"

//     const OpenYouTube=(value)=>{
//         dispatch({type:OPEN_VIDEO_YOUTUBE,payload:{item:value}})
//     }

    return (
        <ChucNangDiv variant={FormFnKidstopCss}>
             <Card className={classes.root}>
                 {/* 1 */}
                <Typography component="div">                   
                           <Box display="flex" justifyContent="center" >
                                <Typography style={{color:'#ecf0f1',fontFamily:'Tahoma',paddingBottom:20,fontSize:18,fontWeight:'bold'}}>
                                                Các Chức Năng Phần Mềm Dinh Dưỡng KidTop
                                </Typography>       
                            </Box>  
                </Typography>

                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{backgroundColor:'#7f8c8d'}}
                >
                 <Typography className={classes.heading} style={{color:'white',fontSize:15}}> Đối tượng sử dụng phần mềm</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{marginTop:-5,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Khối Mầm Non Tư Thục, nhà nước</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Khối Tiểu Học</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Khu Công Nghiệp Có Tổ Chức Bếp Ăn</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Các trung tâm cần số liệu về dinh dưỡng(phòng GYM, các cá nhân có nhu cầu về ăn uống kiểm soát dinh dưỡng...)</Typography>
                    </AccordionDetails>
                </Accordion>
             
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{backgroundColor:'#7f8c8d'}}
                   >
                   <Typography className={classes.heading} style={{color:'white',fontSize:15}}>Video Hướng Dẫn</Typography>
                    </AccordionSummary>

                    {/* <AccordionDetails style={{fontSize:12,fontStyle:'italic',color:'#ff7d32',cursor:'pointer'}} 
                    onClick={()=>{OpenYouTube(VIDEO_KIDTOP)}}
                    >
                     <Typography><a style={{color:`#ff7d32`,fontWeight:'bold',cursor:'pointer',textDecoration:'none'}}> SOFT NUTRIKIDS KIDTOP - HƯỚNG DẪN NHANH GỌN SỬ DỤNG DỄ DÀNG</a></Typography>
                     </AccordionDetails> */}

                    {/* <AccordionDetails style={{fontSize:12,fontStyle:'italic',color:'#ff7d32',cursor:'pointer'}} 
                    onClick={()=>{OpenYouTube(VTTT_HUYTHANH)}}
                    >
                    <Typography><a style={{color:'#2980b9',fontWeight:'bold',cursor:'pointer',textDecoration:'none'}}> - VIỄN THÔNG TIN HỌC HUY THÀNH - SOFT KIDTOP</a></Typography>
                    </AccordionDetails> */}

                    <AccordionDetails style={{marginTop:-10,fontSize:12,fontStyle:'italic',color:'#ff7d32'}}>
                            <Typography><a style={{color:'#ff7d32',fontWeight:'bold',textDecoration:'none'}} href='https://www.youtube.com/channel/UCd9dsakRvJlHuQXM0Nq_D3Q' target='blank'> - KÊNH KIDTOP YOUTUBE</a></Typography>
                    </AccordionDetails>                   

                    {/* <AccordionDetails style={{marginTop:-25,fontSize:12,fontStyle:'italic',color:'#ff7d32',cursor:'pointer'}} 
                    onClick={()=>{OpenYouTube(VIDEO_GIOITHIEU_KIDSTOP)}}
                    >
                            <Typography><a style={{color:'#ff7d32',textDecoration:'none'}}> - Tạo WebSite tự động</a></Typography>
                    </AccordionDetails> */}

                    {/* <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#ff7d32'}}>
                            <Typography><a style={{color:'#ff7d32',textDecoration:'none',cursor:'pointer'}} 
                                          onClick={()=>{OpenYouTube(VIDEO_3IN1)}}
                             > - KIDTOP - SOFT 3IN1 NUTRIKID, KIỂM THỰC 3 BƯỚC & QL THU CHI</a></Typography>
                    </AccordionDetails>             */}

                    {/* <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#ff7d32',cursor:'pointer'}}  
                        onClick={()=>{OpenYouTube(VIDEO_HDSD_KISTOP_FULL)}}
                        >
                            <Typography><a style={{color:'#ff7d32'}}> - HDSD SOFT KIDTOP - FULL</a></Typography>
                    </AccordionDetails> */}

                    {/* <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#ff7d32',cursor:'pointer'}}  
                        onClick={()=>{OpenYouTube(VIDEO_CHAM_AN_DIEN_TU)}}
                        >
                            <Typography><a style={{color:'#ff7d32'}}> - Chấm Ăn Điện Tử </a></Typography>
                    </AccordionDetails> */}


                    {/* <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#ff7d32',cursor:'pointer'}}  
                        onClick={()=>{OpenYouTube(VIDEO_QUAN_LY_THU)}}
                        >
                            <Typography><a style={{color:'#ff7d32'}}> - Quản Lý Các Khoản Thu </a></Typography>
                    </AccordionDetails>

                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#ff7d32',cursor:'pointer'}}  
                        onClick={()=>{OpenYouTube(VIDEO_UPLOAD_EXCEL)}}
                        >
                            <Typography><a style={{color:'#ff7d32'}}> - Upload Excel Các Khoản Thu </a></Typography>
                    </AccordionDetails> */}

                   

                    {/* <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#ff7d32',cursor:'pointer'}}  
                        onClick={()=>{OpenYouTube(HDSD_P1)}}
                        >
                            <Typography><a style={{color:'#ff7d32',textDecoration:'none'}}> - HDSD P1 - LẦN ĐẦU TIÊN ĐĂNG NHẬP PHẦN MỀM</a></Typography>
                    </AccordionDetails>

                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#ff7d32',cursor:'pointer'}}  
                        onClick={()=>{OpenYouTube(HDSD_P2)}}
                        >
                            <Typography><a style={{color:'#ff7d32'}}> - HDSD P2 - CHỨC NĂNG TAB NUTRIKID, TÍNH KHẨU PHẦN ĂN, THƯ VIỆN THỰC ĐƠN</a></Typography>
                    </AccordionDetails>

                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#ff7d32',cursor:'pointer'}}  
                        onClick={()=>{OpenYouTube(HDSD_P3)}}
                        >
                            <Typography><a style={{color:'#ff7d32'}}> - HDSD P3 - KIỂM THỰC 3 BƯỚC</a></Typography>
                    </AccordionDetails>

                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#ff7d32',cursor:'pointer'}}  
                        onClick={()=>{OpenYouTube(HDSD_P4)}}
                        >
                            <Typography><a style={{color:'#ff7d32'}}> - HDSD P4 - THÊM SỬA XÓA HỌC SINH, CHẤM ĂN CHO CÁC NGÀY QUÁ KHỨ</a></Typography>
                    </AccordionDetails>

                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#ff7d32',cursor:'pointer'}}  
                        onClick={()=>{OpenYouTube(HDSD_P5)}}
                        >
                            <Typography><a style={{color:'#ff7d32'}}> - HDSD P5 - CÁC LOẠI BÁO CÁO VÀ CÁCH XỬ LÝ TIỀN THỪA THIẾU</a></Typography>
                    </AccordionDetails> */}
                          
                </Accordion>

                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{backgroundColor:'#7f8c8d'}}
                >
                   <Typography className={classes.heading} style={{color:'white',fontSize:15}}>Cân Đo</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{marginTop:-5,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Đầy đủ các biểu mẫu</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Tự động tổng hợp số liệu cân đo</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Tra cứu thuận tiện nhanh chóng</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Hỗ trợ Upload file, kết xuất file</Typography>
                    </AccordionDetails>                                     
               </Accordion> 

               <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{backgroundColor:'#7f8c8d'}}
                >
                   <Typography className={classes.heading} style={{color:'white',fontSize:15}}>Tạo Website</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{marginTop:-5,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Tạo Website nhanh chóng</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Tiết kiệm thời gian, chi phí</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Thay đổi nội dung tuỳ chỉnh thoải mái</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Tốc độ nhanh, Không giới hạn băng thông</Typography>
                    </AccordionDetails>                                     
               </Accordion> 
               
            {/* 2 */}       
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{backgroundColor:'#7f8c8d'}}
                >
                   <Typography className={classes.heading} style={{color:'white',fontSize:15}}>Tính Khẩu Phần Ăn Cho Trẻ</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{marginTop:-5,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Hóa Đơn Đi Chợ Ngày</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Kết Quả Thiết Lập Dưỡng Chất Ngày</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Dưỡng Chất Dành Cho 1 Trẻ</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Kết Quả Dưỡng Chất</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Sổ Tính Tiền Ăn</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Bảng Tổng Hợp Dưỡng Chất Theo Nhóm Trẻ Theo Tháng</Typography>
                    </AccordionDetails>                    
                </Accordion>
            {/* 3 */}
            <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{backgroundColor:'#7f8c8d'}}
                >
                   <Typography className={classes.heading} style={{color:'white',fontSize:15}}>Kiểm Thực Ba Bước</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{marginTop:-5,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Mẫu Biểu Kiểm Tra Chế Biến Thức Ăn</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Mẫu Biểu Kiểm Tra Khi Chế Biến Thức Ăn</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Mẫu Biểu Kiểm Tra Trước Khi Ăn</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Theo Dõi Lưu Mẫu Và Hủy Mẫu Thức Ăn</Typography>
                    </AccordionDetails>                   
                    
             </Accordion>

             {/* 4 */}
             <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{backgroundColor:'#7f8c8d'}}
                >
                   <Typography className={classes.heading} style={{color:'white',fontSize:15}}>Quản Lý Thu Chi Bán Trú</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{marginTop:-5,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Tổng Hợp Phiếu Ăn Thừa</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Tổng Hợp Báo Ăn</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Chấm Ăn (Tạo ra rất nhiều tài khoản con để báo ăn, kiểm soát chấm ăn chặt chẽ, chính xác)</Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Tổng Hợp Danh Sách Thu Thanh Toán Tiền Ăn </Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Chi Tiết Danh Mục Nguyên Liệu</Typography>
                    </AccordionDetails>    
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Chi Tiết Nguyên Liệu</Typography>
                    </AccordionDetails> 
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Tổng Hợp Danh Mục Nguyên Liệu</Typography>
                    </AccordionDetails>  
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Tổng Hợp Danh Mục Nguyên Liệu Theo Nhà Cung Cấp</Typography>
                    </AccordionDetails> 
                    <AccordionDetails style={{marginTop:-20,fontSize:12,fontStyle:'italic',color:'#c0392b'}}>
                            <Typography>Sổ Kho</Typography>
                    </AccordionDetails>                  
             </Accordion> 
                 
             {/* <CardActions>
                           <ButtonStyle variant="outline" onClick={()=>RedirectLoginApp()} >Đăng Nhập Phần Mềm</ButtonStyle>
             </CardActions>        */}
           
      </Card>       
        </ChucNangDiv>           
    );
};

export default DANG_KY_PM;