import {createMuiTheme} from '@material-ui/core/styles'
import {pink,cyan,red,indigo,blue} from '@material-ui/core/colors'
import yellow from '@material-ui/core/colors/yellow/'

const theme = createMuiTheme({
  colorCyan:{
    primary:cyan[400],
    secondary:cyan[200]
  },
  colorPink:{
    primary:pink[400],
    secondary:pink[200]
  },
  colorYellow:{
    primary:yellow[600],
    secondary:yellow[200]
  },
  colorBlue:{
    primary:blue[500],
    secondary:blue[200]
  },
  palette: {
    primary: indigo,
    secondary: red,
    error: red
  },
  typography: {
    fontFamily: 'Roboto,"Helvetica Neue",Arial,sans-serif',
    headline: {
      fontSize: '1rem'
    },
    subheading: {
      fontSize: '0.8125rem'
    },
    button: {
      fontWeight: 400,
      textTransform: 'initial'
    }
  },
  shape: {
    borderRadius: 4
  },
  text: {
    default: '#000000'
  }
})

export default theme