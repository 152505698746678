import { TextField,Button,Checkbox} from '@material-ui/core';
import React, { useState } from 'react';
import { ValidTel,ValidateEmailAddress } from '../../../helper/fnAllways';
import { MenuCap,Sex,Tinh_Cach,Thu_Nhap,Hon_Nhan,Partner,CO_HOI,Nguon_Co_Hoi} from './fn';
import { ContentDiv, ItemDiv, MainDiv, TextName } from './style';
import {useDispatch} from 'react-redux'
import { UPDATE_ITEM_CRM } from '../../../contant/CRM';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const AddCrm = ({value,handleClose}) => {
    const dispatch =useDispatch()    
    const [typeCap, setTypeCap] = useState(value.typeCap);
    const [HoTen,setHoTen]       = useState(value.HoTen)
    const [Sdt,setSdt]           = useState(value.Sdt)
    const [CCCD,setCCCD]         = useState(value.CCCD)
    const [NgaySinh,setNgaySinh] = useState(value.NgaySinh)
    const [DiaChi,setDiaChi]     = useState(value.DiaChi)
    const [TinhTp,setTinhTp]     = useState(value.TinhTp)
    const [Email,setEmail]       = useState(value.Email)
    const [TichDiem,setTichDiem] = useState(value.TichDiem)
    // const [TheThanhVien,setTheThanhVien] = useState(0)
    const [GhiChu,setGhiChu]     = useState(value.GhiChu)


    const [ToChuc,setToChuc]     = useState(value.ToChuc)
    const [ChucDanh,setChucDanh] = useState(value.ChucDanh)
    const [TinhCach,setTinhCach] = useState(value.TinhCach)
    const [GioiTinh,setGioiTinh] = useState(value.GioiTinh)
    const [ThuNhap,setThuNhap]   = useState(value.ThuNhap)
    const [HonNhan,setHonNhan]     = useState(value.HonNhan)
    const [KHPartner,setKhPartner]       = useState(value.KHPartner)
    const [arrKHPartner,setArrKHPartner] = useState(value.KHPartner)
    const [Spdv,setSpdv]           = useState(value.Spdv)
    const [UuDai,setUuDai]         = useState(value.UuDai)

    const [NguoiGioiThieu,setNguoiGioiThieu] = useState(value.NguoiGioiThieu)
    const [NguoiPhuTrach,setNguoiPhuTrach] = useState(value.NguoiPhuTrach)
    const [DoanhSoDuKien,setDoanhSoDuKien] = useState(value.DoanhSoDuKien)
    const [ChietKhau,setChietKhau]         = useState(value.ChietKhau)
    const [LoiNhuan,setLoiNhuan]           = useState(value.LoiNhuan)
    const [LienHeLanCuoi,setLienHeLanCuoi] = useState(value.LienHeLanCuoi)
    const [HanhDongTiepTheo,setHanhDongTiepTheo]   = useState(value.HanhDongTiepTheo)
    const [LanLienHeTiepTheo,setLanLienHeTiepTheo] = useState(value.LanLienHeTiepTheo)
    const [TinhTrangCoHoi,setTinhTrangCoHoi] = useState(value.TinhTrangCoHoi)
    const [NguonCoHoi,setNguonCoHoi] = useState(value.NguonCoHoi)

    const [errHT,setErrHT] = useState(false)
    const [errTC,setErrTC] = useState(false)
    const [errCD,setErrCD] = useState(false)

    const [errSdt,setErrSdt]         = useState(false)
    const [errEmail,setErrEmail]     = useState(false)
    const [errTextSdt,setErrTextSdt] = useState("")
    const [errTextEmail,setErrTextEmail] = useState("")


    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;


    const SaveData=()=>{
        if(HoTen===null||HoTen===""){
                setErrHT(true)
                setTimeout(()=>{
                    setErrHT(false)
                },3000)
                return
        }

    //     if(ToChuc===null||ToChuc===""){
    //         setErrTC(true)
    //         setTimeout(()=>{
    //             setErrTC(false)
    //     },3000)
    //     return
    //     }

    //     if(ChucDanh===null||ChucDanh===""){
    //         setErrCD(true)
    //         setTimeout(()=>{
    //             setErrCD(false)
    //     },3000)
    //     return
    //    }   
     if(!ValidTel(Sdt)&&Sdt!==""){
        setErrSdt(true)
        setErrTextSdt("Lỗi, SĐT không đúng định dạng!")
        setTimeout(()=>{
            setErrSdt(false)
            setErrTextSdt("")
        },3000)
        return
     }   
     if(!ValidateEmailAddress(Email)&&Email!==""){
        setErrEmail(true)
        setErrTextEmail("Lỗi, Email không đúng định dạng!")
        setTimeout(()=>{
            setErrEmail(false)
            setErrTextEmail("")
        },3000)
        return
     }   
    //  let item ={
    //     _id:value._id,typeCap:+typeCap,HoTen,ToChuc,ChucDanh,Sdt,Email,DoanhSoDuKien,LienHeLanCuoi,HanhDongTiepTheo,LanLienHeTiepTheo,TinhTrangCoHoi,NguonCoHoi,
    //     NguoiPhuTrach,GhiChu
    //  }

    let item ={
        _id:value._id,typeCap,HoTen,Sdt,CCCD,NgaySinh,DiaChi,TinhTp,Email,TichDiem:+TichDiem,GhiChu,
        ToChuc,ChucDanh,TinhCach,GioiTinh:+GioiTinh,ThuNhap:+ThuNhap,HonNhan:+HonNhan,KHPartner:arrKHPartner,Spdv,UuDai,
        NguoiGioiThieu,NguoiPhuTrach,DoanhSoDuKien:+DoanhSoDuKien,ChietKhau:+ChietKhau,LoiNhuan:+LoiNhuan,LienHeLanCuoi,HanhDongTiepTheo,LanLienHeTiepTheo,TinhTrangCoHoi:+TinhTrangCoHoi,NguonCoHoi:+NguonCoHoi
     }

     dispatch({type:UPDATE_ITEM_CRM,payload:{item}})
     handleClose()
    }

    return (
        <MainDiv>
            <Button variant="outlined" color="primary"
            onClick={()=>SaveData()}
            >Cập Nhật Dữ Liệu</Button>&nbsp;&nbsp;
            <Button variant="outlined" color="secondary"
            onClick={()=>handleClose()}
            >Trở Lại</Button>
                 <ContentDiv>
                {/* row 1 */}
                   {/* Loai Tai khoan */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}} >&nbsp;Loại Tài Khoản : </TextName>
                                <TextField
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={typeCap}
                                onChange={e=>setTypeCap(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {MenuCap.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>                       
                        </ItemDiv>
                        {/* Ho Ten */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>Họ tên : </TextName>
                            <TextField
                            defaultValue={HoTen}
                            error={errHT}
                            onChange={e=>setHoTen(e.target.value)}
                            fullWidth size="small" variant="outlined"
                            multiline
                            />                            
                        </ItemDiv>
                        {/* SDT */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;Số Điện Thoại : </TextName>
                            <TextField
                                defaultValue={Sdt}
                                multiline
                                error={errSdt}
                                helperText={errTextSdt}
                            onChange={e=>setSdt(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* CCCD */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;CCCD: </TextName>
                            <TextField   
                            defaultValue={CCCD}
                            multiline                       
                            onChange={e=>setCCCD(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Ngay Sinh */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;Ngày Sinh: </TextName>
                            <TextField  
                             id="date"
                            type="date"
                            defaultValue={NgaySinh}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            onChange={e=>setNgaySinh(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* dia chi */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;Địa chỉ: </TextName>
                            <TextField     
                            defaultValue={DiaChi}  
                            multiline                    
                            onChange={e=>setDiaChi(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Tinh/tp */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;Tỉnh/Tp: </TextName>
                            <TextField    
                            defaultValue={TinhTp}    
                            multiline                   
                            onChange={e=>setTinhTp(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                            {/* Email */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;Email: </TextName>
                            <TextField    
                            defaultValue={Email}   
                            multiline                     
                            onChange={e=>setEmail(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>   
                            {/* Tich Diem */}
                        <ItemDiv>
                                <TextName style={{color:'#27ae60'}}>&nbsp;Tích Điểm : </TextName>
                                <TextField
                                    defaultValue={TichDiem}
                                    type="number"
                                    onChange={e=>setTichDiem(e.target.value)}
                                    fullWidth size="small" variant="outlined"
                                />
                        </ItemDiv>
                            {/* The ThanhVien */}
                        {/* <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;Thẻ Thành Viên: </TextName>
                                <TextField
                                multiline
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={TheThanhVien}
                                onChange={e=>setTheThanhVien(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {MemberCards.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>                       
                        </ItemDiv>               */}
                        {/* Ghi Chu */}
                        <ItemDiv>
                            <TextName style={{color:'#27ae60'}}>&nbsp;Ghi Chú : </TextName>
                            <TextField 
                            defaultValue={GhiChu}
                            multiline
                            onChange={e=>setGhiChu(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>    
                {/* row 1 */}
                                       
                {/* row 2 */}
                            {/* To chuc */}
                        <ItemDiv>
                            <TextName>&nbsp;Tổ Chức : </TextName>
                            <TextField
                            defaultValue={ToChuc}
                            multiline
                            error={errTC}
                            onChange={e=>setToChuc(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* chuc danh */}
                        <ItemDiv>
                            <TextName>&nbsp;Chức Danh : </TextName>
                            <TextField
                            defaultValue={ChucDanh}
                            multiline
                            error={errCD}
                            onChange={e=>setChucDanh(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* tinh cach */}
                        <ItemDiv>
                            <TextName>&nbsp;Tính cách : </TextName>
                            <TextField                                
                                multiline
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={TinhCach}
                                onChange={e=>setTinhCach(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {Tinh_Cach.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>  
                        </ItemDiv>
                            {/* gioi tinh */}
                        <ItemDiv>
                            <TextName>&nbsp;Giới Tính: </TextName>
                            <TextField
                                multiline
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={GioiTinh}
                                onChange={e=>setGioiTinh(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {Sex.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>  
                        </ItemDiv> 
                            {/* thu nhap */}
                        <ItemDiv>
                            <TextName>&nbsp;Thu Nhập : </TextName>
                            <TextField
                                multiline
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={ThuNhap}
                                onChange={e=>setThuNhap(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {Thu_Nhap.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>  
                        </ItemDiv> 
                            {/* tt hon nhan */}
                        <ItemDiv>
                            <TextName>&nbsp;TT Hon Nhân : </TextName>
                            <TextField
                                multiline
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={HonNhan}
                                onChange={e=>setHonNhan(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {Hon_Nhan.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>  
                        </ItemDiv> 
                            {/* kh partner */}
                        <ItemDiv>
                            <TextName>&nbsp;KH Partner : </TextName>
                            <Autocomplete
                            fullWidth
                          multiple
                          options={Partner}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.label}
                          onChange={(e,newValue)=>setArrKHPartner(newValue)}
                          defaultValue={KHPartner}
                          renderOption={(option, { selected }) =>{
                                return(
                                            <React.Fragment>
                                              <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}    
                                                size="small"
                                              />
                                              {option.label}
                                            </React.Fragment>   
                                    )
                              }
                          }                          
                          renderInput={(params) => (
                            <TextField {...params} 
                            variant="outlined" 
                            fullWidth
                            size='small'
                            />
                          )}
                        />       
                        </ItemDiv>    
                            {/* spdv */}
                        <ItemDiv>
                            <TextName>&nbsp;Spdv : </TextName>
                            <TextField
                            defaultValue={Spdv}
                            multiline
                            onChange={e=>setSpdv(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                            {/* uu dai */}
                        <ItemDiv>
                            <TextName>&nbsp;CS Ưu Đãi : </TextName>
                            <TextField
                            multiline
                            onChange={e=>setUuDai(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>

                {/* row 3 */}       
                       {/* Nguoi Gioi Thieu */}
                        <ItemDiv>
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Người Giới Thiệu : </TextName>
                            <TextField 
                            defaultValue={NguoiGioiThieu}
                            multiline
                            onChange={e=>setNguoiGioiThieu(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Nguoi Phu Trach */}
                         <ItemDiv>
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Người Phụ Trách : </TextName>
                            <TextField 
                            defaultValue={NguoiPhuTrach}
                            multiline
                            onChange={e=>setNguoiPhuTrach(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Doanh thu */}
                        <ItemDiv>                    
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Doanh Số Dự Kiến: </TextName>
                            <TextField 
                            defaultValue={DoanhSoDuKien}
                            type="number"                            
                            onChange={e=>setDoanhSoDuKien(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Chiet Khau */}
                        <ItemDiv>                    
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Chiết Khấu: </TextName>
                            <TextField 
                            defaultValue={ChietKhau}
                            type="number"                            
                            onChange={e=>setChietKhau(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* LoiNhuan */}
                        <ItemDiv>                    
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Lợi Nhuận: </TextName>
                            <TextField 
                            defaultValue={LoiNhuan}
                            type="number"
                            onChange={e=>setLoiNhuan(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Lien he Lan Cuoi */}
                        <ItemDiv>
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Liên Hệ Lần Cuối : </TextName>
                            <TextField
                            defaultValue={LienHeLanCuoi}
                            multiline
                            onChange={e=>setLienHeLanCuoi(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Hanh Dong Tiep Theo */}
                        <ItemDiv>
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Hành Động Tiếp Theo : </TextName>
                            <TextField 
                            defaultValue={HanhDongTiepTheo}
                            multiline
                            onChange={e=>setHanhDongTiepTheo(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Lan Lien he Tiep Theo */}
                        <ItemDiv>
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Lần Liên Hệ Tiếp Theo : </TextName>
                            <TextField 
                            defaultValue={LanLienHeTiepTheo}
                            multiline
                            onChange={e=>setLanLienHeTiepTheo(e.target.value)}
                            fullWidth size="small" variant="outlined"/>
                        </ItemDiv>
                        {/* Tinh Trang Co Hoi */}
                        <ItemDiv>
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Tình Trạng Cơ Hội : </TextName>
                            <TextField
                                multiline
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={TinhTrangCoHoi}
                                onChange={e=>setTinhTrangCoHoi(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {CO_HOI.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                             </TextField>  
                        </ItemDiv>
                        {/* Nguon Co Hoi */}
                        <ItemDiv>
                            <TextName style={{color:"#8e44ad"}}>&nbsp;Nguồn Cơ Hội : </TextName>
                            <TextField
                                multiline
                                fullWidth
                                size='small'
                                id="outlined-select-currency-native"
                                select
                                defaultValue={NguonCoHoi}
                                onChange={e=>setNguonCoHoi(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {Nguon_Co_Hoi.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                             </TextField>  
                        </ItemDiv>                    
                                  
                </ContentDiv>
        </MainDiv>
    );
};

export default AddCrm;