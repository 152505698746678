import {call,delay,put,takeEvery,takeLatest} from 'redux-saga/effects'
import { DispatchGetInfoCusKhachHangToStore, DispatchHopDongInvoiceToKhachHangToStore, GetListJobStaffToKhachHangToStore } from '../../action/INFO_GROUP_KHACH_HANG'
import { LoadingComponentFalse, LoadingComponentOpen } from '../../action/Loading'
import { GetInfoCusKhachHangApi, GetListHopDongInvoiceToKhachHangApi, GetListJobStaffToKhachHangApi } from '../../api/ROOT_CONG_NGHE/InfoGroupKhachHang'
import { GET_INFO_CUS_KHACH_HANG, GET_LIST_HOP_DONG_INVOICE_TO_KHACH_HANG, GET_LIST_HOP_DONG_INVOICE_TO_KHACH_HANG_TO_STORE, GET_LIST_JOB_STAFF_TO_KHACH_HANG } from '../../contant/LOGIN_PAGE/OpenNavBarKhachHang'

const FAIL    = 'fail'
const SUCCESS = 'success'

function* GetInfoCusKhachHangSaga({payload}){
    yield put(LoadingComponentOpen())
    let res = yield call(GetInfoCusKhachHangApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(DispatchGetInfoCusKhachHangToStore(res.data.data))
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* GetListJobStaffToKhachHangSaga({payload}){
    yield put(LoadingComponentOpen())
    let res = yield call(GetListJobStaffToKhachHangApi,payload.item)
    
    if(res.data.result===SUCCESS){
        yield put(GetListJobStaffToKhachHangToStore(res.data.data))
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* GetListHopDongInvoiceToKhachHangSaga({payload}){
    yield put(LoadingComponentOpen())
    let res = yield call(GetListHopDongInvoiceToKhachHangApi,payload.item)    
    if(res.data.result===SUCCESS){
        yield put(DispatchHopDongInvoiceToKhachHangToStore(res.data.data))
        yield put(LoadingComponentFalse())
    }
    if(res.data.result===FAIL){
        yield put(LoadingComponentFalse())
    }    
}

function* IndexSaga(){
    //GIAM_DINH_NGHIEM_THU         
    yield takeLatest(GET_LIST_HOP_DONG_INVOICE_TO_KHACH_HANG,GetListHopDongInvoiceToKhachHangSaga)    
    yield takeLatest(GET_INFO_CUS_KHACH_HANG,GetInfoCusKhachHangSaga)    
    yield takeLatest(GET_LIST_JOB_STAFF_TO_KHACH_HANG,GetListJobStaffToKhachHangSaga)
   
}

export default IndexSaga

