import React from 'react';
import NavbarComponent from '../Header/Navbar'
import LoadingLogo from '../LoadingLogo'
import ContentComponent from '../Content'
import FooterComponent from '../../../main/component/footer';
const Index = () => {
    return (
        <div>        
            <div>
                <NavbarComponent/>
            </div>           
            <div
              style={{marginTop:50}}
            >
                <LoadingLogo/>
            </div>
                <ContentComponent/>          
                <FooterComponent/>
        </div>
    );
};

export default Index;