import {take,takeLatest,put,call} from 'redux-saga/effects'
import * as TypeGov from '../../contant/table/Gov'
import * as TypeGovForActive from '../../contant/Gov/softFood'
import {deleteByArrayIdInfoUser,updateInfoUserByUserId,ActiveSoftFood} from '../../api-services/user'
import * as uiLoading from '../../actions/uiLoading'
import * as actionGov from '../../actions/auth'
import {STATUS_CODE} from '../../contant/index'


function* listGovSaga() {  
    while(true){
         yield take(TypeGov.LIST_GOV)                 
    }   
}

function* deleteGovArraySaga({payload}){   
    yield put(uiLoading.loadingSuccess())    
    //yield delay(300)
    const value = payload.allId.map(item=>item.userId) 
    let res = yield call(deleteByArrayIdInfoUser,value)
    if(res.status===STATUS_CODE.SUCCESS){
      yield put(actionGov.deleteGovArraySuccess())     
    }else {
      yield put(actionGov.deleteGovArrayFail())
    }
    yield put(uiLoading.loadingFail())
}

function* editGovSaga({payload}){  
  yield put(uiLoading.loadingSuccess())
  //yield delay(150)
  let res = yield call(updateInfoUserByUserId,payload.newData) 
   
  if(res.data.result==='success'){
      yield put(actionGov.editGovSuccess())
  }else{
    yield put(actionGov.editGovFail())
  }
  yield put(uiLoading.loadingFail())
}

function* ActiveSoftFoodStatusSaga({payload}){ 
   yield call(ActiveSoftFood,payload.item) 
}

function* GovSaga() {
    yield takeLatest(TypeGov.ADD_ITEM_LIST_GOV, listGovSaga)
    yield takeLatest(TypeGov.DELETE_GOV_ARRAY,deleteGovArraySaga)
    yield takeLatest(TypeGov.EDIT_GOV,editGovSaga)
    yield takeLatest(TypeGovForActive.ACTIVE_SOFT_FOOD_STATUS, ActiveSoftFoodStatusSaga)

}

export default GovSaga