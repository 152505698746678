import { updateArrById } from '../../../../helper/fnAllways'
import {DISPATCH_LIST_TYPE_OF_KIEM_THUC_TO_STORE,UPDATE_LIST_TYPE_OF_KIEM_THUC,OPEN_FORM_UPDATE,CLOSE_FORM_UPDATE,DISPATCH_ACTIVE_UPDATE_KIEM_THUC,DISPATCH_LIST_MATERIAL_DATE_TO_STORE,
    DISPATCH_ITEM_KIEM_THUC_BA_BUOC_TO_STORE_IN_DATE,
    DISPATCH_DATE_TIME_KIEM_THUC,DISPATCH_LIST_KIEM_THUC_BA_BUOC,  DISPATCH_UPDATE_KIEM_THUC_BA_BUOC, DISPATCH_INFO_KIEM_THU_BA_BUOC_TO_STORE,
    DISPATCH_ITEM_CO_SO_SAN_XUAT_TO_STORE,
    UPDATE_ITEM_CO_SO_SAN_XUAT,DISPATCH_LIST_CLASS_NUMBER,ADD_KIEM_THUC_BA_BUOC,SET_ITEM_ADD_KIEM_THUC_TO_NULL
} from '../contant/KiemThuc'
import { toastSuccess } from '../../../../helper/toastHelper'

export const initialState={
    ListTypeOfKiemThuc:[],
    OpenFormUpdate:false,
    ActiveUpdate:false,
    ListMaterialInDate:[],//danh sach nguyen lieu di cho trong ngay
    ItemKiemThucBaBuoc:null,
    DateTime:null,
    ListKiemThucBaBuoc:[],
    InfoKiemThucBaBuoc:null,
    ItemCoSoSanXuat:null,
    ItemListClassNumber:null,
    ItemToAddKiemThuc:null
}

const Index = (state=initialState,action)=>{    
    switch (action.type){
        case SET_ITEM_ADD_KIEM_THUC_TO_NULL:
            return{
                ...state,
                ItemToAddKiemThuc:null
            }
        case ADD_KIEM_THUC_BA_BUOC:
            return{
                ...state,
                ItemToAddKiemThuc:action.payload.item
            }
        case DISPATCH_LIST_CLASS_NUMBER:
            return{
                ...state,
                ItemListClassNumber:action.payload.item
            }
        case UPDATE_ITEM_CO_SO_SAN_XUAT:
            toastSuccess('Đã Cập Nhật')
            return{
                ...state,                
            }
        case DISPATCH_ITEM_CO_SO_SAN_XUAT_TO_STORE:
            return{
                ...state,
                ItemCoSoSanXuat:action.payload.item
            }
        case DISPATCH_INFO_KIEM_THU_BA_BUOC_TO_STORE:
            return{
                ...state,
                InfoKiemThucBaBuoc:action.payload.item
            }
        // case UPDATE_KIEM_THUC_BA_BUOC:
        //     return{
        //         ...state,
        //         ListKiemThucBaBuoc:updateArrById(state.ListKiemThucBaBuoc,action.payload.item)
        //     } 
        case DISPATCH_UPDATE_KIEM_THUC_BA_BUOC:
            return{
                ...state,
                ListKiemThucBaBuoc:updateArrById(state.ListKiemThucBaBuoc,action.payload.item)
            }
        case DISPATCH_LIST_KIEM_THUC_BA_BUOC:
            return{
                ...state,
                ListKiemThucBaBuoc:action.payload.item
            }
        case DISPATCH_DATE_TIME_KIEM_THUC:
            return{
                ...state,
                DateTime:action.payload.item
            }
        case DISPATCH_ITEM_KIEM_THUC_BA_BUOC_TO_STORE_IN_DATE:
            toastSuccess('đã khởi tạo')
             return{
                 ...state,
                //ItemKiemThucBaBuoc:action.payload.item
             }
        case DISPATCH_LIST_MATERIAL_DATE_TO_STORE:
            return{
                ...state,
                ListMaterialInDate:action.payload.item
            }
        case DISPATCH_LIST_TYPE_OF_KIEM_THUC_TO_STORE:
            return{
                ...state,
                ListTypeOfKiemThuc:action.payload.item
            }
        case UPDATE_LIST_TYPE_OF_KIEM_THUC:
            return{
                ...state,
                ListTypeOfKiemThuc:updateArrById(state.ListTypeOfKiemThuc,action.payload.item)
            }
        case OPEN_FORM_UPDATE:
            return{
                ...state,
                OpenFormUpdate:true
            }
        case CLOSE_FORM_UPDATE:
            return{
                ...state,
                OpenFormUpdate:false
            }
        case DISPATCH_ACTIVE_UPDATE_KIEM_THUC:
            return{
                ...state,
                ActiveUpdate:true
            }
            
        default: return state
    }
}

export default Index