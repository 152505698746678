import React from 'react'
import {Provider} from 'react-redux'
import configStore,{history} from '../../redux/configStore'
import {ConnectedRouter} from 'connected-react-router'
import {MuiThemeProvider} from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import theme from '../../common/theme/theme'
import Uiloading from '../../component/uiLoading/index'
import FormTask from '../../component/Form/index'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Route, Switch} from 'react-router-dom'
import {ADMIN_ROUTER,ROUTES, GOV_ROUTER,STAFF_ROUTER,PERSON_ROUTER,CLASS_ACTIVE_ROUTER,PERSON_STAFF_ROUTER, WEB_MAM_NON, CREATE_MY_WEB} from '../../contant/router'
import AdminLayoutRoute from '../../common/AdminLayoutRoute'
import DefaultLayoutRoute from '../../common/DefaultLayouRoute'
import UserCusLayoutRoute from '../../common/UserCusLayoutRoute'
import UserStaffLayoutRoute from '../../common/UserStaffLayoutRoute'
import UserPersonLayoutRoute from '../../common/UserPersonLayoutRoute'
import ClassActiveLayoutRoute from '../../common/ClassActiveLayoutRoute'
import TaiKhoanNhanVienDangNhap from '../../common/TaiKhoanNhanVienDangNhap'
import ZaloPageThongBaoPage from '../../container/Login/ZaloPageCallBack'
import CongDongGioDinhTue from '../../container/Login/GioiDinhTue/component/main'
import CongNgheComponent   from '../../container/Login/GioiDinhTue/component/CongNghe/component'
import CreateMyWebComponent from '../../container/Login/MyWeb/component'
import ThietBiCongNghe from '../../container/Login/NotFoundPage/ThietBiCongNghe'
import { withStyles } from '@material-ui/styles'
import style from './style'
import {HelmetProvider} from 'react-helmet-async';


const store = configStore()

const App =React.memo(()=>{
    const renderAdminRouter=()=>{
      let xhtml = null
        xhtml = (
            ADMIN_ROUTER.map(route=>{
                return (
                      <AdminLayoutRoute
                          key={route.path}
                          path={route.path}
                          component={route.component}
                          name={route.name}
                          exact={route.exact}                       
                      />
                )
            })
        )
      return xhtml
    }

    const renderTaiKhoanNhanVienDangNhap=()=>{
        let xhtml = null
        xhtml = (
          PERSON_STAFF_ROUTER.map(route=>{
            return(
                <TaiKhoanNhanVienDangNhap
                key={route.path}
                path={route.path}
                component={route.component}
                name={route.name}
                exact={route.exact} 
                />
            )
          })
        )
        return xhtml
      }

    const renderUserCusNNRouter=()=>{
      let xhtml = null
        xhtml = (
            GOV_ROUTER.map(route=>{
                return (
                      <UserCusLayoutRoute
                          key={route.path}
                          path={route.path}
                          component={route.component}
                          name={route.name}
                          exact={route.exact}                       
                      />
                )
            })
        )
      return xhtml
    }

    const renderClassActiveRouter=()=>{
        let xhtml = null
        xhtml = (
            CLASS_ACTIVE_ROUTER.map(route=>{
                return (
                      <ClassActiveLayoutRoute
                          key={route.path}
                          path={route.path}
                          component={route.component}
                          name={route.name}
                          exact={route.exact}                       
                      />
                )
            })
        )
      return xhtml
    }

    const renderDefaultRouter = ()=>{
        let xhtml = null
          xhtml = ROUTES.map((item,index)=>{
            return(
              <DefaultLayoutRoute
                    component = {item.component}
                    path={item.path}
                    exact = {item.exact}
                    key={index}
              />
            )
          })
        return xhtml
      }

    const renderStaffRouter=()=>{
        let xhtml = null
          xhtml = STAFF_ROUTER.map(route=>{
            return (
                  <UserStaffLayoutRoute
                      key={route.path}
                      path={route.path}
                      component={route.component}
                      name={route.name}
                      exact={route.exact}                       
                  />
            )
          })
        return xhtml
    }

    const renderPersonRouter=()=>{
        let xhtml = null
          xhtml = PERSON_ROUTER.map(route=>{
            return (
                  <UserPersonLayoutRoute
                      key={route.path}
                      path={route.path}
                      component={route.component}
                      name={route.name}
                      exact={route.exact}                       
                  />
            )
          })
        return xhtml
    }

      const renderWebMamNon=()=>{    
      return  WEB_MAM_NON.map((item,index)=>{
            return(
              <DefaultLayoutRoute
                      component = {item.component}
                      path={item.path}
                      exact = {item.exact}
                      key={index}
                />
            )
          })      
      }

      const renderCreateMyWeb=()=>{
        return CREATE_MY_WEB.map((item,index)=>{
          return(
            <CreateMyWebComponent
                      component = {item.component}
                      path={item.path}
                      exact = {item.exact}
                      key={index}
            />
          )
        })
      }
  
    const renderRoutes=()=>{
        let xhtml = null
          xhtml= (
            <>
              <Switch>
                {renderAdminRouter()}
                {renderUserCusNNRouter()}
                {renderDefaultRouter()}
                {renderStaffRouter()}
                {renderPersonRouter()}
                {renderTaiKhoanNhanVienDangNhap()}
                {renderClassActiveRouter()}
                {renderWebMamNon()}
                {renderCreateMyWeb()}
                <Route path='/zalo-thongbao'     component={ZaloPageThongBaoPage}/>
                <Route path='/cong-dong-gioi-dinh-tue' component={CongDongGioDinhTue}/>
                <Route path='/thiet-bi-cong-nghe' component={ThietBiCongNghe}/>
                {/* <Route path='/vat-ly-tri-lieu'  component={VatLyTriLieuComponent}/> */}
                {/* <Route path='/cong-nghe' component={CongNgheComponent}/> */}
                <Route path='/' component={CongNgheComponent}/>  
                {/* <Redirect path='/'/>             */}
              </Switch>
            </>
          )
        return xhtml
    }

  // renderWeb(){
  //   let xhtml=null
  //   xhtml = ROUTE_WEB.map((route,i)=>{
  //       return(
  //         <NotFoundPage
  //                 key={i}
  //                 path={route.path}
  //                 component={route.component}
  //                 name={route.name}
  //                 exact={route.exact}                       
  //             />
  //       )
  //   })
  //   return xhtml
  // }
  
    return (
      <Provider store={store}>
        <HelmetProvider>       
          <ConnectedRouter history={history}>
            <MuiThemeProvider theme={theme}>
                <Uiloading/>
                <ToastContainer/>  
                <CssBaseline/>            
                <FormTask/>              
                {renderRoutes()}
            </MuiThemeProvider>
          </ConnectedRouter>
       </HelmetProvider>
      </Provider>
    );
  
})

export default withStyles(style)(App)