import {fork,all} from 'redux-saga/effects'
import IndexSaga from './index'

function* RootSaga(){
    yield all(
        [
           yield fork(IndexSaga)
        ]
    )
}

export default RootSaga