import React, { Suspense,useEffect,useState } from 'react';
import { GET_INFO_USER_DETAIL_WEB, GET_LINK_CHECK_DOMAIN } from '../contant';
import {useDispatch,useSelector} from 'react-redux'
import { GET_LIST_GIOI_THIEU_WEB } from '../../../../ComponentGov/SoftFoodTabMain/WebSiteMamNon/Contant';
import {Helmet} from 'react-helmet-async';


const TinTucComponent    = React.lazy(()=>import('./TinTuc'))
const LopHocComponent    = React.lazy(()=>import('./LopHoc'))
const DoiNguComponent    = React.lazy(()=>import('./DoiNgu'))
const GioiThieuComponent = React.lazy(()=>import('./GioiThieu'))
const HeaderComponent  = React.lazy(()=>import('./header'))
const SliderComponent  = React.lazy(()=>import('./sliderHeader'))
const ContentIntro     = React.lazy(()=>import('./ContentIntro'))
const MainContent      = React.lazy(()=>import('./MainContent'))
const MainContent2     = React.lazy(()=>import('./MainContent2'))
const MainContent3     = React.lazy(()=>import('./MainContent3'))
const MainContent4     = React.lazy(()=>import('./MainContent4'))
const DangKyHoc        = React.lazy(()=>import('./DangKyHoc'))
const Footer           = React.lazy(()=>import('./footer'))



const Index = React.memo((props) => {
   const dispatch = useDispatch()
   //const nameId   = props.match.params   
   const [linkWeb,setLinkWeb] = useState(window.location.href)
   const ItemInfoWeb          = useSelector(state=>state.RenderWebAutoMamNon.ItemInfoWeb)
   const InfoUserDetailWeb    = useSelector(state=>state.RenderWebAutoMamNon.InfoUserDetailWeb)   

   useEffect(()=>{
                dispatch({type:GET_LINK_CHECK_DOMAIN,payload:{item:{mydomain:linkWeb}}})
   },[])

   useEffect(()=>{
        if(ItemInfoWeb!==null){
                dispatch({type:GET_INFO_USER_DETAIL_WEB,payload:{item:{userId:ItemInfoWeb.userId}}})     
                dispatch({type:GET_LIST_GIOI_THIEU_WEB,payload:{item:{userId:ItemInfoWeb.userId}}})
        }
   },[ItemInfoWeb])
   //check ten mien
    return (
        <>
                {InfoUserDetailWeb!==null&&
                
                <Helmet>
                    <title>{InfoUserDetailWeb!==null&&InfoUserDetailWeb.workPlace}</title>
                    <meta
                        name="description"
                        content={InfoUserDetailWeb!==null&&InfoUserDetailWeb.workPlace}
                        charSet="utf-8"
                    />
                    <link rel="canonical" href={ItemInfoWeb!==null&&ItemInfoWeb.linkWeb}/>
                </Helmet>
                }
             <div id="HOME" style={{margin:'auto',padding:0}}>   
                {ItemInfoWeb!==null&&
                        <>                        
                                <Suspense fallback={()=>{}}>
                                        <HeaderComponent/>
                                </Suspense>                                

                                <Suspense fallback={()=>{}}>
                                        <SliderComponent/>
                                </Suspense>                               

                                <Suspense fallback={()=>{}}>
                                        <ContentIntro/>
                                </Suspense>

                                <Suspense fallback={()=>{}}>
                                        <GioiThieuComponent />
                                </Suspense>

                                <Suspense fallback={()=>{}}>
                                        <DoiNguComponent/>
                                </Suspense>

                                <Suspense fallback={()=>{}}>
                                        <TinTucComponent/>
                                </Suspense>

                                <Suspense fallback={()=>{}}>
                                        <MainContent/>
                                </Suspense>

                                <Suspense fallback={()=>{}}>
                                        <MainContent2/>
                                </Suspense>

                                <Suspense fallback={()=>{}}>
                                        <MainContent3/>
                                </Suspense>

                                <Suspense fallback={()=>{}}>
                                        <MainContent4/>
                                </Suspense>

                                <Suspense fallback={()=>{}}>
                                        <LopHocComponent/>
                                </Suspense>

                                <Suspense fallback={()=>{}}>
                                        <DangKyHoc />
                                </Suspense>

                                <Suspense fallback={()=>{}}>
                                        <Footer/>
                                </Suspense>
                        </>                   
                }         
             </div>     
        </>      
    );
});

export default Index;   