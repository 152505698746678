import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { CLOSE_DISPATCH_ITEM_STU } from '../../../contant/Class';
import TableComponent from './TableList'
import AddComponentStu from './AddInfoStu'


const Index = React.memo(() => {
    const dispatch     = useDispatch()
    const ItemStu      = useSelector(state => state.ManagerAccount.ItemStu)
    const ItemStuSendMess    = useSelector(state=>state.ManagerAccount.ItemStuSendMess) 
    const CloseFormAdd = ()=>{
            dispatch({type:CLOSE_DISPATCH_ITEM_STU})
    }    
    const ShowAlert=()=>{
        alert('Không có Danh Sách Học Sinh')
        return
    }
    return (
        <div>
            {ItemStu!==null?<AddComponentStu
                    open={ItemStu}
                    handleClose={CloseFormAdd}
                    //value={ItemStu}
            />:<></>}
            {ItemStuSendMess!==null?<TableComponent/>:ShowAlert()}
        </div>
    );
});

export default Index;
