import { API_ENDPOINT } from "../../../../../contant";
import axiosServices from "../../../../../api-services/api";

const urlCreateNewAccountQuanTri   = 'CreateNewAccountQuanTri'
const urlCreateAccountQuanTri      = 'CreateAccountQuanTri'
const urlLoginDatabaseCongNghe     = 'LoginToDatabaseCongNghe'
const urlUpdateAccountLoginQuanTri = 'UpdateAccountLoginQuanTri'


export const UpdateAccountLoginQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateAccountLoginQuanTri}`,item)
}


export const CreateNewAccountQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlCreateNewAccountQuanTri}`,item)
}


export const CreateAccountQuanTriApi = (item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlCreateAccountQuanTri}`,item)
}

export const LoginToDatabaseCongNgheApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlLoginDatabaseCongNghe}`,item)
}

