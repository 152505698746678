export const OPEN_FORM_CREATE_ACCOUNT  = "OPEN_FORM_CREATE_ACCOUNT"
export const CLOSE_FORM_CREATE_ACCOUNT = "CLOSE_FORM_CREATE_ACCOUNT"
export const ADD_CREATE_ACCOUNT_STAFF  = "ADD_CREATE_ACCOUNT_STAFF"
export const CHECK_EXIST_NAME_LOGIN    = "CHECK_EXIST_NAME_LOGIN"
export const DISPATCH_EXIST_NAME_LOGIN_TO_STORE = "DISPATCH_EXIST_NAME_LOGIN_TO_STORE"
export const DISPATCH_DEFAULT_VALUE_EXIST_NAME_LOGIN ="DISPATCH_DEFAULT_VALUE_EXIST_NAME_LOGIN"
export const DISPATCH_ADD_STAFF_TO_STORE = "DISPATCH_ADD_STAFF_TO_STORE"
export const GET_LIST_MANAGER_STAFF      = "GET_LIST_MANAGER_STAFF"
export const GET_LIST_STAFF_INFO         = "GET_LIST_STAFF_INFO"
export const DISPATCH_GET_LIST_INFO_STAFF = "DISPATCH_GET_LIST_INFO_STAFF"
export const UPDATE_ITEM_INFO_STAFF_ACTIVE_DEACTIVE = "UPDATE_ITEM_INFO_STAFF_ACTIVE_DEACTIVE"
export const OPEN_SETTING_PAYMENT       = "OPEN_SETTING_PAYMENT"
export const CLOSE_SETTING_PAYMENT      = "CLOSE_SETTING_PAYMENT"

export const OPEN_LIST_SELL_PRODUCT      = "OPEN_LIST_SELL_PRODUCT"
export const CLOSE_LIST_SELL_PRODUCT     = "CLOSE_LIST_SELL_PRODUCT"
export const DISPATCH_NEW_LIST_AFTER_DELETE_SELL_PRODUCT ="DISPATCH_NEW_LIST_AFTER_DELETE_SELL_PRODUCT"
export const DISPATCH_NEW_LIST_AFTER_ADD_SELL_PRODUCT ="DISPATCH_NEW_LIST_AFTER_ADD_SELL_PRODUCT"
export const ADD_PAYMENT_STAFF  = "ADD_PAYMENT_STAFF"

export const DISPATCH_ADD_PAYMENT_STAFF_TO_STORE        = "DISPATCH_ADD_PAYMENT_STAFF_TO_STORE"
export const GET_LIST_PAYMENT_STAFF                     = "GET_LIST_PAYMENT_STAFF"
export const DISPATCH_GET_LIST_PAYMENT_STAFF_TO_STORE   = "DISPATCH_GET_LIST_PAYMENT_STAFF_TO_STORE"

export const OPEN_EDIT_PAYMENT_STAFF         = "OPEN_EDIT_PAYMENT_STAFF"
export const CLOSE_EDIT_PAYMENT_STAFF        = "CLOSE_EDIT_PAYMENT_STAFF"

export const OPEN_EDIT_LIST_SELL_PRODUCT  = "OPEN_EDIT_LIST_SELL_PRODUCT"
export const CLOSE_EDIT_LIST_SELL_PRODUCT = "CLOSE_EDIT_LIST_SELL_PRODUCT"
export const DISPATCH_EDIT_ITEM_AFTER_ADD_SELL_PRODUCT = "DISPATCH_EDIT_ITEM_AFTER_ADD_SELL_PRODUCT"
export const DISPATCH_ITEM_DELETE_AFTER_EDIT_SELL_PRODUCT = "DISPATCH_ITEM_DELETE_AFTER_EDIT_SELL_PRODUCT"
export const UPDATE_PAYMENT_STAFF  = "UPDATE_PAYMENT_STAFF"

export const OPEN_EDIT_STAFF             = "OPEN_EDIT_STAFF"
export const CLOSE_EDIT_STAFF            = "CLOSE_EDIT_STAFF"

export const CHECK_EXIST_NAME_LOGIN_EDIT = "CHECK_EXIST_NAME_LOGIN_EDIT"
export const EDIT_ACCOUNT_STAFF   = "EDIT_ACCOUNT_STAFF"
export const DISPATCH_ITEM_EDIT_ACCOUNT_STAFF_TO_STORE = "DISPATCH_ITEM_EDIT_ACCOUNT_STAFF_TO_STORE"
export const OPEN_DISPATCH_ITEM_STAFF_TO_VIEW_FROM_MANAGER_STAFF = "OPEN_DISPATCH_ITEM_STAFF_TO_VIEW_FROM_MANAGER_STAFF"
export const CLOSE_DISPATCH_ITEM_STAFF_TO_VIEW_FROM_MANAGER_STAFF = "CLOSE_DISPATCH_ITEM_STAFF_TO_VIEW_FROM_MANAGER_STAFF"
export const OPEN_VIEW_IMAGE  = "OPEN_VIEW_IMAGE"
export const CLOSE_VIEW_IMAGE = "CLOSE_VIEW_IMAGE"


export const ADD_LOCATION                    = "ADD_LOCATION"
export const DISPATCH_ADD_LOCATION_TO_STORE  = "DISPATCH_ADD_LOCATION_TO_STORE"
export const GET_LOCATON                     = "GET_LOCATION"
export const DISPATCH_GET_LIST_LOCATION      = "DISPATCH_GET_LIST_LOCATION"

export const GET_CHAM_CONG_BY_USER_ID        = "GET_CHAM_CONG_BY_USER_ID"
export const DISPATCH_GET_CHAM_CONG_BY_USERID_TO_STORE = "DISPATCH_GET_CHAM_CONG_BY_USERID_TO_STORE"

export const OPEN_EDIT_CHAM_CONG            = "OPEN_EDIT_CHAM_CONG"
export const CLOSE_EDIT_CHAM_CONG           = "CLOSE_EDIT_CHAM_CONG"
export const ADD_AND_GET_LIST_CHAM_CONG     = "ADD_AND_GET_LIST_CHAM_CONG"
export const UPDATE_ITEM_LIST_CHAM_CONG     = "UPDATE_ITEM_LIST_CHAM_CONG"

export const OPEN_ADD_JOB                   = "OPEN_ADD_JOB"
export const CLOSE_ADD_JOB                  = "CLOSE_ADD_JOB"
export const ADD_TASK_JOB                   = "ADD_TASK_JOB"
export const DISPATCH_ADD_TASK_JOB_STORE    = "DISPATCH_ADD_TASK_JOB_STORE"
export const GET_LIST_TASK_JOB              = "GET_LIST_TASK_JOB"
export const DISPATCH_TASK_JOBS_TO_STORE    = "DISPATCH_TASK_JOBS_TO_STORE"

//CONG VIEC 
export const GET_LIST_TASK_JOB_BY_STAFF_CONG_VIEC     = "GET_LIST_TASK_JOB_BY_STAFF_CONG_VIEC"
export const GET_LIST_TASK_JOB_BY_STAFF_CONG_VIEC_NONE     = "GET_LIST_TASK_JOB_BY_STAFF_CONG_VIEC_NONE"
export const TYPE_PAYMENT_SELECT_PAY_TASK_JOB_CONG_VIEC = "TYPE_PAYMENT_SELECT_PAY_TASK_JOB_CONG_VIEC"

export const DISPATCH_SELECT_MONTH_CONG_VIEC = "DISPATCH_SELECT_MONTH_CONG_VIEC"
export const FIND_BY_CUSTOMER_CONG_VIEC      = "FIND_BY_CUSTOMER_CONG_VIEC"

export const DISPATCH_FIND_BY_TASK_CONG_VIEC = "DISPATCH_FIND_BY_TASK_CONG_VIEC"
export const DISPATCH_DEFAULT_TYPE_PAYMENT   = "DISPATCH_DEFAULT_TYPE_PAYMENT"
export const DISPATCH_APRROVE_TASK_JOB       = "DISPATCH_APRROVE_TASK_JOB"
export const DISPATCH_APRROVE_TASK_JOB_CONG_TAC_PHI  = "DISPATCH_APRROVE_TASK_JOB_CONG_TAC_PHI"

//cong no
export const DISPATCH_NAME_CUSTOMER_CONG_NO = "DISPATCH_NAME_CUSTOMER_CONG_NO"

export const DISPATCH_ADD_PAYMENT_MATERIAL_UPDATE_TASK_JOB="DISPATCH_ADD_PAYMENT_MATERIAL_UPDATE_TASK_JOB"

export const UPDATE_TASK_JOB_PAYMENT   = "UPDATE_TASK_JOB_PAYMENT" 
export const LIST_PRICE_PAYMENT        = "LIST_PRICE_PAYMENT"

export const UPDATE_LIST_PRICE_PAYMENT = "UPDATE_LIST_PRICE_PAYMENT"
export const SHOW_OPEN_INFO_STAFF      = "SHOW_OPEN_INFO_STAFF"
export const SHOW_CLOSE_INFO_STAFF     = "SHOW_CLOSE_INFO_STAFF"

export const UPDATE_LIST_TASK_JOB      = "UPDATE_LIST_TASK_JOB"

export const UPDATE_LIST_TASK_JOB_MANGER_STAFF = "UPDATE_LIST_TASK_JOB_MANGER_STAFF"