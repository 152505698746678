import {call, put, takeLatest}from 'redux-saga/effects'
import { DispatchItemAppWebToStore,DispatchListGioiThieuWebToStore,DispatchListRegisStudentWeb, UpdateSuccess } from '../Action'
import { CreateWebAutoInfoApi, GetWebAutoInfoApi, UpdateWebAutoInfoApi,GetListRegisStudentWebApi, GetListGioiThieuWebApi, UpdateGioiThieuWebApi, UpdateItemRegisStudentWebApi, DeleteItemRegisStudentWebApi } from '../Api/CreateWeb'
import { CREATE_APP_WEB_AUTO, DELETE_ITEM_REGIS_STUDENT_WEB, GET_LIST_GIOI_THIEU_WEB, GET_LIST_REGIS_STUDENT_WEB, GET_WEB_AUTO_INFO,UPDATE_GIOI_THIEU_WEB,UPDATE_ITEM_REGIS_STUDENT_WEB,UPDATE_WEB_AUTO_INFO } from '../Contant'

const SUCCESS = 'success'

function* CreateAppWebAutoSaga({payload}){
            let res = yield call(CreateWebAutoInfoApi,payload.item)
            if(res.data.result===SUCCESS){
                  yield put(DispatchItemAppWebToStore(res.data.data))
            }
}

function* GetWebAutoInfoSaga({payload}){
    let res = yield call(GetWebAutoInfoApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(DispatchItemAppWebToStore(res.data.data))
    }    
}

function* UpdateWebAutoInfoSaga({payload}){
    let res = yield call(UpdateWebAutoInfoApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(DispatchItemAppWebToStore(res.data.data))
    } 
}

function* GetListRegisStudentWebSaga({payload}){
    let res = yield call(GetListRegisStudentWebApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(DispatchListRegisStudentWeb(res.data.data))
    } 
}

function* GetListGioiThieuWebSaga({payload}){
    let res = yield call(GetListGioiThieuWebApi,payload.item)
    if(res.data.result===SUCCESS){
        yield put(DispatchListGioiThieuWebToStore(res.data.data))
    } 
}

function* UpdateGioiThieuWebSaga({payload}){
   let res = yield call(UpdateGioiThieuWebApi,payload.item)
   if(res.data.result===SUCCESS){
        yield put(DispatchListGioiThieuWebToStore(res.data.data))
    } 
}

function* UpdateItemRegisStudentWebSaga({payload}){
   let res =  yield call(UpdateItemRegisStudentWebApi,payload.item)
    if(res.data.result===SUCCESS){

    } 
}

function* DeleteItemRegisStudentWebSaga({payload}){
      let res =  yield call(DeleteItemRegisStudentWebApi,payload.item)
        // if(res.data.result===SUCCESS){
        //     yield put(UpdateSuccess())
        // } 
}

function* IndexSaga(){
    yield takeLatest(CREATE_APP_WEB_AUTO,CreateAppWebAutoSaga)
    yield takeLatest(GET_WEB_AUTO_INFO,GetWebAutoInfoSaga)
    yield takeLatest(UPDATE_WEB_AUTO_INFO,UpdateWebAutoInfoSaga)
    yield takeLatest(GET_LIST_REGIS_STUDENT_WEB,GetListRegisStudentWebSaga)
    yield takeLatest(GET_LIST_GIOI_THIEU_WEB,GetListGioiThieuWebSaga)
    yield takeLatest(UPDATE_GIOI_THIEU_WEB,UpdateGioiThieuWebSaga)
    yield takeLatest(UPDATE_ITEM_REGIS_STUDENT_WEB,UpdateItemRegisStudentWebSaga)
    yield takeLatest(DELETE_ITEM_REGIS_STUDENT_WEB,DeleteItemRegisStudentWebSaga)
}


export default IndexSaga