import { showDDMMYY, showYYMMDD } from "../../../../helper/dateTime/handleDMY"
import { CompareDayToLockApp } from "../../../../utils/DayTime"

export const LOCK_APP = "Phiên Bản Dùng Thử"
export const TINH_AN  = "Tính Ăn"

export const itemList=[
    {nameSoft:'kidtop full 3in1/tháng',priceSoft:800000,timeout:31,
    promotion:0,timeProtion:0,activePromotion:false
    },
    {nameSoft:'kidtop full 3in1/quý',priceSoft:1800000,timeout:93,
    promotion:0,timeProtion:0,activePromotion:false
    },
    {nameSoft:'kidtop full 3in1/năm',priceSoft:4900000,timeout:366,
    promotion:0,timeProtion:0,activePromotion:false
    },
    {nameSoft:'nutrikids',priceSoft:1200000,timeout:366,
    promotion:0,timeProtion:0,activePromotion:false
    },
    {nameSoft:'quản lý thu chi',priceSoft:2800000,timeout:366,
    promotion:0,timeProtion:0,activePromotion:false
    },
    {nameSoft:'kiểm thực ba bước',priceSoft:1800000,timeout:366,
    promotion:0,timeProtion:0,activePromotion:false
    }
]

const SOFT_KIDTOP_MONTH   = "kidtop full 3in1/tháng"
const SOFT_KIDTOP_3MONTH  = "kidtop full 3in1/quý"
const SOFT_KIDTOP_12MONTH = "kidtop full 3in1/năm"
const SOFT_NUTRIKID       = "nutrikids"
const SOFT_THU_CHI        = "quản lý thu chi"
const SOFT_3BUOC          = "kiểm thực ba bước"
const SOFT_TAO_WEB        = "tạo webSite tự động"

const LOCK         = "LOCK"
const UNLOCK       = "UNLOCK"
const NUTRIKIDS    = "nutrikids"
const WEB_AUTO     = "webauto"



export const HandlePaymentKidtop =(item,value)=>{
    if(value.value===TINH_AN){    
        let check = item.filter(v=>v.dateExpire!==null&&(v.nameSoft===SOFT_THU_CHI||v.nameSoft===SOFT_KIDTOP_MONTH||v.nameSoft===SOFT_KIDTOP_3MONTH||v.nameSoft===SOFT_KIDTOP_12MONTH)&&v.approve===true)
        if(check.length){   
            let resultCompare = CompareDayToLockApp(check[0].dateExpire,showYYMMDD(new Date()))
            if(resultCompare===LOCK){
                return {
                    name:LOCK_APP,
                    value:false
                }
            }
            if(resultCompare===UNLOCK){
                return {
                    name:`Ngày Hết Hạn ${showDDMMYY(check[0].dateExpire)}`,
                    value:check[0]
                }  
            }                                
        }
        if(!check.length){
            return {
                name:LOCK_APP,
                value:false
            }
        }
    }
    if(value.value===NUTRIKIDS){    
        let check = item.filter(v=>v.dateExpire!==null&&(v.nameSoft===SOFT_NUTRIKID||v.nameSoft===SOFT_KIDTOP_MONTH||v.nameSoft===SOFT_KIDTOP_3MONTH||v.nameSoft===SOFT_KIDTOP_12MONTH)&&v.approve===true)
        if(check.length){   
            let resultCompare = CompareDayToLockApp(check[0].dateExpire,showYYMMDD(new Date()))
            if(resultCompare===LOCK){
                return {
                    name:LOCK_APP,
                    value:false
                }
            }
            if(resultCompare===UNLOCK){
                return {
                    name:`Ngày Hết Hạn ${showDDMMYY(check[0].dateExpire)}`,
                    value:check[0]
                }  
            }                                
        }
        if(!check.length){
            return {
                name:LOCK_APP,
                value:false
            }
        }
    }
    if(value.value===SOFT_3BUOC){    
        let check = item.filter(v=>v.dateExpire!==null&&(v.nameSoft===SOFT_3BUOC||v.nameSoft===SOFT_KIDTOP_MONTH||v.nameSoft===SOFT_KIDTOP_3MONTH||v.nameSoft===SOFT_KIDTOP_12MONTH)&&v.approve===true)
        if(check.length){   
            let resultCompare = CompareDayToLockApp(check[0].dateExpire,showYYMMDD(new Date()))
            if(resultCompare===LOCK){
                return {
                    name:LOCK_APP,
                    value:false
                }
            }
            if(resultCompare===UNLOCK){
                return {
                    name:`Ngày Hết Hạn ${showDDMMYY(check[0].dateExpire)}`,
                    value:check[0]
                }  
            }                                
        }
        if(!check.length){
            return {
                name:LOCK_APP,
                value:false
            }
        }
    }
    if(value.value===SOFT_TAO_WEB){    
        let check = item.filter(v=>v.dateExpire!==null&&(v.nameSoft===SOFT_3BUOC||v.nameSoft===SOFT_KIDTOP_MONTH||v.nameSoft===SOFT_KIDTOP_3MONTH||v.nameSoft===SOFT_KIDTOP_12MONTH||v.nameSoft===SOFT_3BUOC)&&v.approve===true)
        if(check.length){   
            let resultCompare = CompareDayToLockApp(check[0].dateExpire,showYYMMDD(new Date()))
            if(resultCompare===LOCK){
                return {
                    name:LOCK_APP,
                    value:false
                }
            }
            if(resultCompare===UNLOCK){
                return {
                    name:`Ngày Hết Hạn ${showDDMMYY(check[0].dateExpire)}`,
                    value:check[0]
                }  
            }                                
        }
        if(!check.length){
            return {
                name:LOCK_APP,
                value:false
            }
        }
    }
}