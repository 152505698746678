export const FETCH_LIST_USERS             = "FETCH_LIST_USER"
export const SHOW_LIST_USERS              = "SHOW_LIST_USERS"
export const SAVE_USER_LOGIN_INFO         = "USER_LOGIN_INFO"
export const RESET_PASSWORD_ADMIN         = "RESET_PASSWORD_ADMIN"
export const RESET_PASSWORD_ADMIN_SUCCESS = "RESET_PASSWORD_ADMIN_SUCCESS"
export const OPEN_RESET_PASSWORD_ADMIN    = "OPEN_RESET_PASSWORD_ADMIN"
export const CLOSE_RESET_PASSWORD_ADMIN   = "CLOSE_RESET_PASSWORD_ADMIN"
export const RESET_ACCOUNT_PASSWORD       = "RESET_ACCOUNT_PASSWORD"
export const ACCOUNT_IS_NOT_DEFINE        = "ACCOUNT_IS_NOT_DEFINE"
export const RESET_ACCOUNT_SUCCESS        = "RESET_ACCOUNT_SUCCESS"

export const ALERT_RESET_ACCOUNT_SUCCESS  = "ALERT_RESET_ACCOUNT_SUCCESS" 
export const ALERT_RESET_ACCOUNT          = "ALERT_RESET_ACCOUNT" 
export const ALERT_RESET_ACCOUNT_FAIL     = "ALERT_RESET_ACCOUNT_FAIL" 

export const ALERT_VERIFY_PAYMENT_ACCOUNT = "ALERT_VERIFY_PAYMENT_ACCOUNT"
export const ALERT_VERIFY_PAYMENT_ACCOUNT_TO_STORE = "ALERT_VERIFY_PAYMENT_ACCOUNT_TO_STORE"

// export const USER_ACTIONS = [
//   { icon: <FileCopyIcon />, name: 'Copy' },
//   { icon: <SaveIcon />, name: 'Save' },
//   { icon: <PrintIcon />, name: 'Print' },
//   { icon: <ShareIcon />, name: 'Share' },
//   { icon: <FavoriteIcon />, name: 'Like' },
// ];