

export const ArrListCateDetailByWeb=(arrAll,arrSanpham,Item)=>{
 let ArrByNameCateOrigin = [...new Set(arrAll.map(v=>v.nameCate))]//tao ten danh mucj
 arrAll =  arrAll.map(v=>{
   let index = arrSanpham.findIndex(vv=>vv.nameMaterial===v.nameMaterial)
   if(index!==-1){
     return{
       ...v,
       linkImgSanpham:arrSanpham[index].img,
       price:convertNumber(v.price)
     }
   }else{
     return{...v,
          linkImgSanpham:"",
          price:convertNumber(v.price)
    }
   }
 })

let newArrAllOrigin = ArrByNameCateOrigin.map(v=>{
  let index = arrAll.findIndex(vv=>vv.nameCate===v)
  if(index!==-1){
    return{
      nameCate:v,
      arrMaterial:filterByNameMaterial(arrAll,v),
    }
  }
})

let arrAllToDbToDelete = newArrAllOrigin.shift()
//let arrByNameCateToDBToDelete = ArrByNameCateOrigin.shift()
let arrAllToDB = newArrAllOrigin.concat(arrAllToDbToDelete)
let ItemAddAutoImport = {
  linkWeb:Item.linkWeb,
  type:0,
  tagCate:Item.tagCate,
  tagMaterial:Item.tagMaterial,
  list:arrAllToDB
}

//let arrByNameCateToDB = ArrByNameCateOrigin.concat(arrByNameCateToDBToDelete)
let ItemAddCategory=handleCreateToAddCategory(newArrAllOrigin,Item)
  return{ItemAddCategory,ItemAddAutoImport}
}

const handleCreateToAddCategory = (arr,item)=>{
  return arr.map(v=>{
    return{
      userId:item.userId,
      nameCategory:v.nameCate==="máy bộ pd"?"máy bộ":v.nameCate,
      listMaterials:v.arrMaterial.map(vv=>{
        return{
          name:vv.nameMaterial,
          unit:""
        }
      })
    }
  })
}

const filterByNameMaterial = (arr,v)=>{
  return arr.filter(vv=>vv.nameCate===v)
}

const convertNumber=(item)=>{
  let result = item.split(/[.]/)
  if(result.length===3){
    return+(result[0]+result[1]+result[2])
  }
  if(result.length===2){
    return+(result[0]+result[1])
  }
  if(result.length===4){
    return +(result[0]+result[1]+result[2]+result[3])
  }  
}