import { Box ,Button, Fab} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ListOfTuyenDungComponent from './ListOfTuyenDung';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GET_ADD_TUYEN_DUNG } from '../../../container/Login/GioiDinhTue/component/contant';
import{useDispatch,useSelector} from 'react-redux'

const Index = () => {
    const dispatch = useDispatch()
    const [doiTac,setDoiTac]       = useState(false)
    const [thiTruong,setThiTruong] = useState(false)
    const [chamSoc,setChamSoc]     = useState(false)
    const [ctv,setCtv]             = useState(false)

    return (
        <div>
            <Fab variant='round' size="small" >                
                <AutorenewIcon onClick={()=>dispatch({type:GET_ADD_TUYEN_DUNG})}/>
            </Fab>
            <Box display="flex" justifyContent="center">
                <Button variant='contained' size="small" color={doiTac?'primary':''} onClick={()=>{
                    setDoiTac(true)
                    setThiTruong(false)
                    setChamSoc(false)
                    setCtv(false)
                }}>Đối Tác</Button> &nbsp;
                <Button variant='contained' size="small" color={thiTruong?'primary':''}
                onClick={()=>{
                    setDoiTac(false)
                    setThiTruong(true)
                    setChamSoc(false)
                    setCtv(false)}}
                >Thị trường</Button> &nbsp;
                <Button variant='contained' size="small" color={chamSoc?'primary':''}
                 onClick={()=>{
                    setDoiTac(false)
                    setThiTruong(false)
                    setChamSoc(true)
                    setCtv(false)}}
                >Chăm Sóc</Button> &nbsp;
                <Button variant='contained' size="small" color={ctv?'primary':''}
                 onClick={()=>{
                    setDoiTac(false)
                    setThiTruong(false)
                    setChamSoc(false)
                    setCtv(true)}}
                >CTV</Button> &nbsp;
            </Box>
            {doiTac&&<ListOfTuyenDungComponent value={'DOI_TAC'}/>}
            {thiTruong&&<ListOfTuyenDungComponent value={'THI_TRUONG'}/>}
            {chamSoc&&<ListOfTuyenDungComponent value={'CHAM_SOC'}/>}
            {ctv&&<ListOfTuyenDungComponent value={'CTV'}/>}
        </div>
    );
};

export default Index;