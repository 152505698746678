import React,{Suspense, useState} from 'react';
import {Button,Grid,DialogContent,DialogActions,Dialog,TextField,Box,Checkbox,FormControlLabel} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import {useStyles} from './styles'
import {useSelector,useDispatch} from 'react-redux'
import styled from 'styled-components';
import {changeUpperCase}  from '../../../helper/fnAllways/index'
import {DISPATCH_EDIT_ITEM_GOV,UPDATE_PASS_GOV} from '../../../contant/Gov/login'



const StyledDialog = styled(Dialog)`
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  max-width:600px;
}
`
const StyledDialogContent = styled(DialogContent)`
  .MuiDialogContent-root { 
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 15px 65px;
  } 
`
const Index =React.memo(({open,handleClose})=> {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [editInfo,setEditInfo] = useState(false)
  const [changePass,setChangePass] = useState(false)
  const userLogin = useSelector(state=>state.users.loginUser)
  const userId = useSelector(state=>state.GovLogin.userId)
  const ItemGov = useSelector(state=>state.GovLogin.ListGov)
  const [active,setActive] = useState(ItemGov.activeSoftFood)
  const [workPlace,setWorkPlace] = useState(ItemGov.workPlace)
  const [tel,setTel] = useState(ItemGov.tel)
  const [taxCode,setTaxCode] = useState(ItemGov.taxCode)
  const [address,setAddress] = useState(ItemGov.address)
  const [email,setEmail] = useState(ItemGov.email)
  const [directorName,setDirectorName] = useState(ItemGov.directorName)
  const [pass,setPass] = useState("")
  const [rePass,setRepass] = useState("")
  const [err,setErr] = useState('')
  
  const hanldeErrDuplicatePass = ()=>{
    setErr('Mật Khẩu không trùng khớp, nhập lại')
    setTimeout(()=>{
        setErr('')
    },3000)
  }
  const handleInputShort=()=>{
    setErr('Mật khẩu ít nhất 6 ký tự')
    setTimeout(()=>{
        setErr('')
    },3000)
  }
    const ViewContent = ()=>{
      return(
        <>
                <Grid item md={12}>
                      <TextField
                        id="standard-full-width"
                        label="Tên Khách Hàng"
                        value={changeUpperCase(ItemGov.workPlace)}                                      
                        style={{ margin: 8 }}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="standard-helperText"
                        label="email"
                        value={ItemGov.email}                                      
                        style={{ margin: 8 }}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="email"
                        helperText="Email để phục hồi mật khẩu, bạn phải nhập chính xác"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="standard-full-width"
                        label="Điện thoại"
                        value={ItemGov.tel}                                      
                        style={{ margin: 8 }}                  
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />       
                    </Grid>
                    <Grid item md={12}>
                    <TextField
                          id="standard-full-width"
                          label="Mã số thuế"
                          value={ItemGov.taxCode}
                          style={{ margin: 8 }}                  
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />       
                    </Grid>
                    <Grid item md={12}>
                    <TextField
                          id="standard-full-width"
                          label="Địa chỉ"
                          value={changeUpperCase(ItemGov.address)}
                          style={{ margin: 8 }}                  
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />            
                    </Grid>
                    <Grid item md={12}>
                    <TextField
                            id="standard-full-width"
                            label="Người đại diện"
                            value={changeUpperCase(ItemGov.directorName)}
                            style={{ margin: 8 }}                  
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />      
                    </Grid>
                    <Grid item md={12} className={classes.itemActive}>
                      <Box >Phần mềm tính ăn
                        <FormControlLabel
                        control={<Checkbox checked={ItemGov.activeSoftFood} />}
                        label={active?"Kích hoạt":"Chưa kích hoạt"}
                        />
                      </Box>
                    </Grid>               
        </>
      )
    }
    const ViewContentEdit =()=>{
      return(
        <>
          <Grid item md={12}>
            <TextField
              //id="standard-full-width"
              label="Tên Khách hàng"
              defaultValue={changeUpperCase(ItemGov.workPlace)}                                      
              onChange={e=>setWorkPlace(e.target.value.toLowerCase())}                                    
              style={{ margin: 8 }}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />            
          </Grid>
          <Grid item md={12}>
                    <TextField
                      id="standard-helperText"
                      label="email"
                      defaultValue={ItemGov.email}        
                      onChange={e=>setEmail(e.target.value)}                              
                      style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="email"
                      helperText="Email để  kích hoạt và phục hồi mật khẩu, bạn phải nhập chính xác"
                    />
                  </Grid>
          <Grid item md={12}>
            <TextField
              //id="standard-full-width"
              label="Điện thoại"
              defaultValue={ItemGov.tel}                                      
              onChange={e=>setTel(e.target.value)}                                 
              style={{ margin: 8 }}                  
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
            />       
          </Grid>
          <Grid item md={12}>
          <TextField
                //id="standard-full-width"
                label="Mã số thuế"
                defaultValue={ItemGov.taxCode}
                onChange={e=>setTaxCode(e.target.value)}      
                style={{ margin: 8 }}                  
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                type="text"
              />       
          </Grid>
          <Grid item md={12}>
          <TextField
                //id="standard-full-width"
                label="Địa chỉ"
                defaultValue={changeUpperCase(ItemGov.address)}
                onChange={e=>setAddress(e.target.value.toLowerCase())}  
                style={{ margin: 8 }}                  
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                type="text"
              />            
          </Grid>
          <Grid item md={12}>
          <TextField
                  //id="standard-full-width",
                  label="Người đại diện" 
                  defaultValue={changeUpperCase(ItemGov.directorName)}
                  onChange={e=>setDirectorName(e.target.value.toLowerCase())}      
                  style={{ margin: 8 }}                  
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                />      
          </Grid>
          <Grid item md={12}>
            <Box className={classes.itemActive}>Phần mềm tính ăn
              <FormControlLabel
              control={<Checkbox checked={ItemGov.activeSoftFood} />}
              label={active?"Kích hoạt":"Chưa kích hoạt"}
              />
            </Box>
          </Grid>    
        </>
      )     
    }

    const EditInfoGov=()=>{
      setEditInfo(true)
    }

    const SaveEditInfoGov=()=>{
            let item = {
              _id:ItemGov._id,
              workPlace,
              email,
              tel,
              taxCode,
              address,
              directorName,    
              activeSoftFood:ItemGov.activeSoftFood,
              userId 
            }
          if(item._id===ItemGov._id&&
            item.workPlace===ItemGov.workPlace&&
            item.email===ItemGov.email&&
            item.tel === ItemGov.tel &&
            item.taxCode === ItemGov.taxCode&&
            item.address === ItemGov.address&&
            item.directorName === ItemGov.directorName&&
            item.activeSoftFood === ItemGov.activeSoftFood &&
            item.userId === ItemGov.userId
            ){
              setEditInfo(false)
              return true
            }
            
              dispatch({
              type:DISPATCH_EDIT_ITEM_GOV,
              payload:{item}
              })
              //console.log(item)
              setEditInfo(false)
            
            
    }

    const ShowViewEditChangePass=()=>{
      return(
        <>
             <Grid item md={12}>
            <TextField
              label="Tên đăng nhập"
              value={userLogin.name}                                                      
              style={{ margin: 8 }}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              autoComplete="off"
            />                    
          </Grid>
          <Grid item md={12}>
                    <TextField
                      label="Mật Khẩu Mới"
                      defaultValue={pass}      
                      onChange={e=>setPass(e.target.value)}                              
                      style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="password"
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      label="Nhập lại mật khẩu mới"
                      defaultValue={rePass}    
                      onChange={e=>setRepass(e.target.value)}                                
                      style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="password"
                      autoComplete="off"
                    />
                  </Grid>
        </>
      )
    }
    const HandleChangePass=()=>{
      setChangePass(true)
    }
    const SavePass=()=>{     
      if(pass!==rePass){
        hanldeErrDuplicatePass()
        setPass("")
        setRepass("")
        return true
      }
      if(pass.length<6){
          handleInputShort()
          setPass("")
          setRepass("")
          return true
      }
      let item={
        idGroup:userLogin.idGroup,
        name:userLogin.name,
        password:pass
      }      
      dispatch({
        type:UPDATE_PASS_GOV,
        payload:{item}
      })
      setChangePass(false)
    }
  
  return (    
        <Suspense fallback={()=>{}}>
            <StyledDialog open={open} onClose={handleClose}>        
                  <Box textAlign="center">    
                  {changePass===true?(<Alert variant="filled" severity="info">Thay đổi mật khẩu</Alert>):(
                                <>              
                                      {editInfo===true?(
                                          <Alert variant="filled" severity="error">Cập nhật thông tin                   
                                          </Alert>
                                      ):(
                                            <Alert variant="filled" severity="info">Thông tin khách hàng </Alert>        
                                            
                                      )}
                                </>
                      )}             
                  </Box>      
                  <>       
                      <StyledDialogContent>                   
                                  <Grid container>
                                        {changePass===true?ShowViewEditChangePass():(
                                          <>
                                            {editInfo===true?ViewContentEdit():ViewContent()}                                        
                                            </>
                                        )}                                     
                                  </Grid>       
                      
                            </StyledDialogContent>
                            <DialogActions>
                                {changePass===true?(
                                  <>
                                    <p className={classes.alertText}>{err}</p>
                                      <Button onClick={()=>SavePass()} variant="contained" color="primary">
                                        Lưu mật khẩu
                                      </Button>                                   
                                  </>
                                ):(
                                      <>
                                      <Button onClick={()=>HandleChangePass()} variant="contained" color="secondary">
                                        Thay đổi mật khẩu
                                      </Button>  
                                      {/* <Button onClick={handleClose} variant="contained" color="secondary">
                                        Thoát
                                      </Button>     */}
      
                                      {editInfo===true?(
                                          <Button onClick={()=>SaveEditInfoGov()} variant="contained" color="primary">
                                                  Lưu
                                          </Button>  
                                      ):(
                                        <Button onClick={()=>EditInfoGov()} variant="contained" color="primary">
                                          Chỉnh sửa
                                        </Button>  
                                      )}                
                                    </>                           
                                  )}
                            
                            </DialogActions>   

                      </>     
            </StyledDialog>
      </Suspense>
  );
})

export default Index