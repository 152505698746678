import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { List, ListItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import {GOV_ROUTER,STAFF_ROUTER,PERSON_ROUTER,CLASS_ACTIVE_ROUTER} from '../../../contant/router';
import styles from './style';
import {connect} from 'react-redux'
import {compose,bindActionCreators} from 'redux'
import * as GovActiveSoftFood from  '../../../actions/Gov/softFood'


class Sidebar extends Component {  
  toggleDrawer = value => {
    //eslint-disable-next-line react/prop-types
    const { onToggleSidebar } = this.props;
    if (onToggleSidebar) {
      onToggleSidebar(value);
    }    
  }

 handleOpenModal=()=>{
   const {openBoxActive}=this.props
   let {ActiveSoftFood}=openBoxActive
   ActiveSoftFood()  
}

showHTMLRouter=()=>{
  let {loginUser} = this.props
  if(loginUser.idGroup===5){
    return this.showHTMLGOV()
  }
  if(loginUser.idGroup===2){
    return this.showHTMLSTAFF()
  }
  if(loginUser.idGroup===4){
    return this.showHTMLPERSON()
  }
  if(loginUser.idGroup===7){
    return this.showCLASSACTIVE()
  }
}

showHTMLGOV=()=>{
  let {classes} = this.props
  let xhtml = null
  xhtml  = GOV_ROUTER.map((item, index) => {                      
    return (
              <NavLink
                to={`${item.path ? item.path : ''}`}
                exact
                className={classes.menuLink}
                activeClassName={classes.menuActive}
                key={index}
              >                 
              {(item.name==="Phần mềm tính ăn")?(
                 <ListItem className={classes.menuItem} button onClick={()=>this.handleOpenModal()}>
                 <Typography variant="body1"><item.icon color="primary"/>{item.name}</Typography>
                </ListItem>
              ):(
                <ListItem className={classes.menuItem} button>
                <Typography variant="body1"><item.icon color="primary"/>{item.name}</Typography>
               </ListItem>
              )}                                 
              </NavLink>
            );           
  })  
  return xhtml   
}

showHTMLSTAFF=()=>{
    let {classes} = this.props
    let xhtml = null
    xhtml  = STAFF_ROUTER.map((item, index) => {                      
      return (
                <NavLink
                  to={`${item.path ? item.path : ''}`}
                  exact
                  className={classes.menuLink}
                  activeClassName={classes.menuActive}
                  key={index}
                >            
                  <ListItem className={classes.menuItem} button>
                  <Typography variant="body1"><item.icon color="primary"/>{item.name}</Typography>
                 </ListItem>              
                </NavLink>
              );           
    })  
    return xhtml   
}
showHTMLPERSON=()=>{
  let {classes} = this.props
  let xhtml = null
  xhtml  = PERSON_ROUTER.map((item, index) => {                      
    return (
              <NavLink
                to={`${item.path ? item.path : ''}`}
                exact
                className={classes.menuLink}
                activeClassName={classes.menuActive}
                key={index}
              >            
                <ListItem className={classes.menuItem} button>
                <Typography variant="body1"><item.icon color="primary"/>{item.name}</Typography>
               </ListItem>              
              </NavLink>
            );           
  })  
  return xhtml   
}

showCLASSACTIVE = ()=>{
  let {classes} = this.props
  let xhtml = null
  xhtml  = CLASS_ACTIVE_ROUTER.map((item, index) => {                      
    return (
              <NavLink
                to={`${item.path ? item.path : ''}`}
                exact
                className={classes.menuLink}
                activeClassName={classes.menuActive}
                key={index}
              >            
                <ListItem className={classes.menuItem} button>
                <Typography variant="body1"><item.icon color="primary"/>{item.name}</Typography>
               </ListItem>              
              </NavLink>
            );           
  })  
  return xhtml   
}


  renderList() {
    const { classes,loginUser} = this.props;         
    let xhtml = null;
    xhtml = (
      <div
       
        className={classes.list}       
        role="presentation"
        onClick={() => this.toggleDrawer(false)}
        onKeyDown={() => this.toggleDrawer(false)}
      >
        <List component="div" >         
          {this.showHTMLRouter()}
        </List>
      </div>
    );
    return xhtml;
  }

  render() {
    const { classes, showSidebar } = this.props   
    return (
      <Drawer
        open={showSidebar}
        onClose={() => this.toggleDrawer(false)}
        variant="persistent"       
        classes={{
          paper: classes.drawerPaper
        }}
        ModalProps={{
          keepMounted: false,
          className: classes.modal,
          BackdropProps: {
            className: classes.backdrop
          },
          onBackdropClick: () => this.toggleDrawer(false)
        }}
      >
        {this.renderList()}
      </Drawer>
    );
  }
}

const mapStateToProps = state =>{
  return {
    GovLogin:state.GovLogin.GovLogin,
    loginUser:state.users.loginUser,

  }
}

const mapDispatchStateToProps = dispatch =>{
  return{
        openBoxActive :bindActionCreators(GovActiveSoftFood,dispatch)
  }
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchStateToProps
)

export default compose(
  withStyles(styles),
  withConnect
)(Sidebar)
