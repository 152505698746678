import _ from "lodash"

export const DeleteArrEditPaymentStaff=(arr,item)=>{
  return{
    _id:arr._id,
    idStaff:arr.idStaff,
    userId:arr.userId,
    paymentBasic:arr.paymentBasic,
    paymentHour:arr.paymentHour,
    paymentMore:arr.paymentMore,   
    typeofPayment:arr.typeofPayment,
    paymentSellProduct:filterNewArr(arr.paymentSellProduct,item),
  }
}

const filterNewArr = (arr,item)=>{
    let result=arr.filter(v=>v.idMaterialProduct!==item.idMaterialProduct)
    return result.map(v=>{
      return{
          idMaterialProduct:v.idMaterialProduct,
          nameSellProduct:v.nameSellProduct,
          priceSellProduct:v.priceSellProduct
      }
    })
}

export const HandleUpdateArrPricePayment=(arr,item)=>{
  let newItem = item.taskJob.map(v=>{
    return v.infoDetailForCustomerAfter.map(vv=>vv)
  })
  let resultArr = _.flatten(newItem)
  let newResult = arr.concat(resultArr)
  let filterById = [... new Set(newResult.map(v=>v._id))]
  let result = filterById.map(v=>{
    let index= newResult.findIndex(vv=>vv._id===v)
    if(index!==-1){
      return{
        ...newResult[index]
      }
    }
  })
  return result
}

export const FilterStaffByCustomer=(arr,item)=>{
  console.log(arr)
  console.log(item)
  let filterNotNull = arr.filter(v=>v.taskJob[0]!==null)
  console.log(filterNotNull)
  let filterByCustomer = filterNotNull.filter(v=>v.taskJob[0].idCustomer===item._id)
  console.log(filterByCustomer)
}