import _ from 'lodash'
import { showDDMMYY } from '../../../../helper/dateTime/handleDMY'
import { changeUpperCase } from '../../../../helper/fnAllways'
import { ConvertTimeStamp } from '../component/nutrikids/khauPhanAn/tinhKhauPhan/fn'

export const ConvertListAllMaterials=(arr)=>{
        let newArr = arr.map(v=>{
            return{
                listMaterial:v.listMaterial
            }
        })
        let newArr_ = newArr.map(v=>{
            return v.listMaterial
        })    
        return _.flatten(newArr_)
}

export const ListMealAgeFn=(item)=>{
    //console.log(item)
    if(item!==""&&item!==undefined&&item.tableAge.length!==0){       
          return item.tableAge.map(v=>{
            return{
                //idTableAge:item._id,
                idTableInAge:v._id,
                userId:item.userId,
                name:`${changeUpperCase(v.meal)}-${changeUpperCase(v.age)}`,
                meal:v.meal,
                age:v.age,
                engeryAskSchool:v.askSchool.engery,
                protidAskSchool:v.askSchool.protid,
                lipidAskSchool:v.askSchool.lipid,
                glucoAskSchool:v.askSchool.gluco,
                engeryAskAll:v.askAll===undefined?0:v.askAll.engery,
                protidAskAll:v.askAll===undefined?0:v.askAll.protid,
                lipidAskAll:v.askAll===undefined?0:v.askAll.lipid,
                glucoAskAll:v.askAll===undefined?0:v.askAll.gluco,
                standard:v.standard.filter(vv=>vv.active===true)[0]
            }
        })
    }   
}

export const FilterByDateTime=(arr,date)=>{
    return arr.filter(v=>showDDMMYY(v.dateTime)===showDDMMYY(date))
}

export const HandleCheckMenuMealNutrikid=(Item)=>{
    //console.log(Item)
    //console.log(Item[0].menu)
        return Item.filter(v=>v.menu!==undefined&&v.menu!==null)       
}

export const PrvDateTime =(date)=>{
    return date.setDate(date.getDate() - 1)
}

export const FindDateTimeLuyKe=(ListMeal,DateTime,TableMeal)=>{
    let filterListMeal = ListMeal.filter(v=>v.typeOf===10&&v.idTableInAge===TableMeal.idTableInAge) //tim mien gioi han luy ke
    let find = filterListMeal.filter(v=>ConvertTimeStamp(v.timeBeginLuyKe)<=ConvertTimeStamp(DateTime)&&ConvertTimeStamp(v.timeEndLuyKe)>=ConvertTimeStamp(DateTime))
    if(find.length!==0) return find[0]
    if(find.length===0) return null
}

export const UpdateListMeal = (List,itemMeal)=>{
   return List.map(v=>{
        let index = itemMeal.findIndex(vv=>vv._id===v._id)
        if(index>=0){
            return{
                ...v,
                ...itemMeal[index]
            }
        }else{
            return{
                ...v
            }
        }
    })
}