export const DISABLE_ROUTE_THIET_BI_CONG_NGHE   = "DISABLE_ROUTE_THIET_BI_CONG_NGHE"

export const ROUTE_THIET_BI_CONG_NGHE   = "ROUTE_THIET_BI_CONG_NGHE"
export const ROUTER_MAIN                = "ROUTER_MAIN"
export const DISPATCH_MATERIAL_CATE_ALL_PHAT_DAT_TO_STORE = "DISPATCH_MATERIAL_CATE_ALL_PHAT_DAT_TO_STORE"
export const DISPATCH_VALUE_SAN_PHAM_0  =  "DISPATCH_VALUE_SAN_PHAM_0"
export const DISPATCH_VALUE_SAN_PHAM_1  =  "DISPATCH_VALUE_SAN_PHAM_1"
export const DISPATCH_VALUE_SAN_PHAM_2  =  "DISPATCH_VALUE_SAN_PHAM_2"
export const SELECT_VALUE_PRODUCT_TBCN   = "SELECT_VALUE_PRODUCT_TBCN"
export const DISPATCH_VALUE_DETAIL_PRODUCT_TO_DB_HANDLE = "DISPATCH_VALUE_DETAIL_PRODUCT_TO_DB_HANDLE"
export const DISPATCH_IMG_DETAIL_PRODUCT_TO_STORE = "DISPATCH_IMG_DETAIL_PRODUCT_TO_STORE"
export const OPEN_SHOW_DETAIL_PRICE = "OPEN_SHOW_DETAIL_PRICE"
export const CLOSE_SHOW_DETAIL_PRICE = "CLOSE_SHOW_DETAIL_PRICE"
export const ADD_CART_IN_ARR            = "ADD_CART_IN_ARR"
export const REMOVE_CART_IN_ARR         = "REMOVE_CART_IN_ARR"
export const UPDATE_CART_IN_ARR         = "UPDATE_CART_IN_ARR"
export const DISPATCH_FIND_GET_VALUE_IMG      = "DISPATCH_FIND_GET_VALUE_IMG"
export const DISPATCH_FIND_VALUE_IMG_TO_STORE = "DISPATCH_FIND_VALUE_IMG_TO_STORE"

export const OPEN_FORM_DETAIL_ITEM_PRODUCT    = "OPEN_FORM_DETAIL_ITEM_PRODUCT"
export const CLOSE_FORM_DETAIL_ITEM_PRODUCT   = "CLOSE_FORM_DETAIL_ITEM_PRODUCT"
export const UPDATE_ITEM_DETAIL_PRODUCT       = "UPDATE_ITEM_DETAIL_PRODUCT"

//WEB CONG NGHE
export const OPEN_FORM_LOGIN_REGIS  = "OPEN_FORM_LOGIN_REGIS"
export const CLOSE_FORM_LOGIN_REGIS = "CLOSE_FORM_LOGIN_REGIS"

export const DISPATCH_LOGIN_TO_DATABASE_CONG_NGHE = "DISPATCH_LOGIN_TO_DATABASE_CONG_NGHE"
