import React, { Suspense, useEffect, useState } from 'react';
import MainComponent from './Main'
import {useDispatch,useSelector} from 'react-redux'
import { DISPATCH_GET_LIST_PRICE_SOFT } from '../../../../contant/holdingtech';
import KhuyenMaiComponent from './POPUP/KHUYENMAI'
import BMIComponent from './BMI'
import QrcodeComponent from './QRCODE'
import TuyenDungComponent from './TuyenDung/OpenDialog'
import { CLOSE_CREATE_QRCODE, CLOSE_CREATE_WEBSITE, CLOSE_PROCESS_BMI, CLOSE_TUYEN_DUNG, GET_ADD_TUYEN_DUNG } from '../../contant';
import {Helmet} from 'react-helmet-async';
import CreateWebsiteComponent from './CreateWebsite'
//import EventComponent from './SuKien/HappyNewYear'

const Index = React.memo(() => {
    const OpenProcessBMI    = useSelector(state=>state.MainWeb.OpenProcessBMI)
    const OpenQrcode        = useSelector(state=>state.MainWeb.OpenQrcode)
    const OpenCreateWebsite = useSelector(state=>state.MainWeb.OpenCreateWebsite)
    const OpenTuyenDung     = useSelector(state=>state.MainWeb.OpenTuyenDung)
    const ValueTuyenDung    = useSelector(state=>state.MainWeb.ValueTuyenDung)

    const [openPopUp,setOpenPopUp] = useState(true)
    const dispatch                 = useDispatch()

    useEffect(()=>{
        dispatch({type:DISPATCH_GET_LIST_PRICE_SOFT})
        dispatch({type:GET_ADD_TUYEN_DUNG})
    },[])

    const ClosePopUp = ()=>{
        setOpenPopUp(false)
    }

    return (
        <div>        
                <Helmet>
                    <title>Phần Mềm Dinh Dưỡng KidTop</title>
                    <meta
                        name="description"
                        content="Phần Mềm Dinh Dưỡng"
                        charSet="utf-8"
                    />
                    <link rel="canonical" href="http://huythanhicare.com"/>
                </Helmet>

               {/* <EventComponent/> */}

                <Suspense fallback={()=>{}}>                 
   
                <MainComponent/>  
                    {OpenCreateWebsite&&<CreateWebsiteComponent
                        open={OpenCreateWebsite}
                        handleClose={()=>{
                            dispatch({type:CLOSE_CREATE_WEBSITE})
                        }}
                    />}
                    {OpenTuyenDung&&<TuyenDungComponent
                            open={OpenTuyenDung}
                            handleClose={()=>dispatch({type:CLOSE_TUYEN_DUNG})}
                            value={ValueTuyenDung}
                    />}
                    {OpenQrcode&&
                        <QrcodeComponent
                            open={OpenQrcode}
                            handleClose={()=>{dispatch({type:CLOSE_CREATE_QRCODE})}}
                        />
                    }       
                    {OpenProcessBMI&&<BMIComponent
                            open={OpenProcessBMI}
                            handleClose={()=>{
                                dispatch({type:CLOSE_PROCESS_BMI})
                            }}
                    />} 

                    {openPopUp&&<KhuyenMaiComponent
                                open={openPopUp}
                                handleClose={ClosePopUp}
                    />}

                </Suspense>
        </div>
    );
});

export default Index;