import * as TypeUsers from '../contant/users'

export const fetchListUser = ()=>{
    return{
      type:TypeUsers.FETCH_LIST_USERS
    }
}

export const getListUser = (data)=>{
    return{
      type:TypeUsers.SHOW_LIST_USERS,
      payload:{data}
    }
}



export const saveUserLoginInfo = (user) =>{
  return {
    type:TypeUsers.SAVE_USER_LOGIN_INFO,
    payload:{user}
  }
}

export const ResetPassSuccess =()=>{
  return{
    type:TypeUsers.RESET_PASSWORD_ADMIN_SUCCESS
  }
}

export const ResetAccountPassword =(data)=>{
    return{
      type:TypeUsers.RESET_ACCOUNT_PASSWORD,
      payload:{data}
    }
}
export const ResetAccountSuccess=()=>{
  return{
    type:TypeUsers.RESET_ACCOUNT_SUCCESS
  }
}
export const AccountIsNotDefine=()=>{
  return{
    type:TypeUsers.ACCOUNT_IS_NOT_DEFINE
  }
}

export const AlertResetAccountSuccess=(name)=>{
  return{
    type:TypeUsers.ALERT_RESET_ACCOUNT_SUCCESS,
    payload:{name}   
  }
}

export const AlertResetAccount=(id)=>{
  return{
    type:TypeUsers.ALERT_RESET_ACCOUNT,
    payload:{id}
  }
}

export const AlertResetAccountFail=(id)=>{
  return{
    type:TypeUsers.ALERT_RESET_ACCOUNT_FAIL,
    payload:{id}
  }
}


export const AlertVerifyPaymentAccount =(id)=>{
  return{
    type:TypeUsers.ALERT_VERIFY_PAYMENT_ACCOUNT,
    payload:{id}
  }
}

export const AlertVerifyPaymentAccountToStore =(item)=>{
  return{
    type:TypeUsers.ALERT_VERIFY_PAYMENT_ACCOUNT_TO_STORE,
    payload:{item}
  }
}

