//import {  makeStyles } from '@material-ui/core/styles';
import styled,{keyframes} from "styled-components";

// export const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,   
//   },
// }));

export const  gradient = keyframes`
     0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

export const MainDiv = styled.div`
  width:100%;
  height:100%;
  background-color:orange;
  background: linear-gradient(-45deg,#27ae60, #8e44ad,#23a6d5, #23d5ab,#34495e,#1abc9c,#8e44ad,#7f8c8d);
  background-size: 400% 400%;
  animation: ${gradient} 50s ease infinite;
  ${'' /* height: 100vh; */}
`

