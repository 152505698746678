import { SELECT_NAME_TO_PAY_TASK } from "../../../../Contant"

export const FindNameTask =(arr,item)=>{    
  let result = []
  arr.map((v,i)=>{    
    if(v.name.toLowerCase().trim().includes(item.toLowerCase().trim())===true){   
     result.push(v)
    }
  })
  return result
}