import {select,fork,call,put,take,delay,takeLatest,takeEvery} from 'redux-saga/effects'
import * as TypeTask from '../../contant/task'
import * as uiLoading from '../../actions/uiLoading'
import {getListTask,addTask,editTask,deleteTask} from '../../api-services/task'
import * as actionTaskForm from '../../actions/taskForm'
import * as actions from '../../actions/task'
import {STATUS_CODE,STATUS} from '../../contant/index'


function* taskListAction(){
  while(true){    
      const action = yield take(TypeTask.TASK_LIST)
      yield put(uiLoading.loadingSuccess())
      //console.log(action.payload.params)    
      const res = yield call(getListTask,action.payload)
      //console.log(res)
      if(res.status===STATUS_CODE.SUCCESS){
        yield put(actions.taskListSuccess(res.data.data))
      }else{
        yield put(actions.taskListFail('err'))
      }     
      yield delay(500)
      yield put(uiLoading.loadingFail())
  }
}

function* findFilterTask({payload}){
  yield delay(200) 
  let {keyword} = payload   
  let list = yield select(state => state.task.LiskTask)     
  let filteredTask = list.filter(x =>
   x.title.toLowerCase().includes(keyword))
   if(keyword.length>0){
      yield put(actions.filterTaskSuccess(filteredTask))
   }else{      
      let res = yield call(getListTask)      
      yield put(actions.taskListSuccess(res.data.data))
   }   
 }

function* addTaskSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  const {title,description} =payload   
  const res = yield call(addTask,{
    title,
    description,
    status:STATUS[0].status
  })  
  //ket qua lay duoc la 1 chuoi
  //chuyen chuoi thanh 1 object 
   let  data = JSON.parse(res.config.data) 
  if(res.status===STATUS_CODE.SUCCESS){       
    yield put(actions.addTaskSuccess(data))    
    yield put(actionTaskForm.hideFormTask()) 
    yield put(actions.filterTaskFind('')) 
  }else{
    yield put(actions.addTaskFail('err'))
  }
  yield delay(500)
  yield put(uiLoading.loadingFail())  
}


function* updateTaskSaga({payload}){
  let {title,description,status,_id} = payload 
  yield put(uiLoading.loadingSuccess())   
  let res = yield call(editTask,{title,description,status},_id)     
  if(res.status===STATUS_CODE.SUCCESS){
    yield put(actions.updateTaskSuccess(payload))   
    yield put(actionTaskForm.hideFormTask())
  }else{
    yield put(actions.updateTaskFail('err'))
    yield put(actionTaskForm.hideFormTask())
  }
  yield delay(500)
  yield put(uiLoading.loadingFail())
}

function* deleteTaskSaga({payload}){  
    const{_id} = payload    
    yield put(uiLoading.loadingSuccess())
    const res = yield call(deleteTask,_id)   
    // console.log(res.data)
    if(res.status===STATUS_CODE.SUCCESS){     
      yield put(actions.deleteTaskSuccess(_id))
      yield put(actionTaskForm.hideFormTask())
    }else{
      yield put(actions.deleteTaskFail('loi'))
    }
    yield delay(500)
    yield put(uiLoading.loadingFail())
}

function* taskSaga(){
  yield fork(taskListAction)
  yield takeLatest(TypeTask.FILTER_TASK,findFilterTask) 
  yield takeLatest(TypeTask.ADD_TASK,addTaskSaga)
  yield takeLatest(TypeTask.UPDATE_TASK, updateTaskSaga)
  yield takeLatest(TypeTask.DELETE_TASK, deleteTaskSaga)
}

export default taskSaga

