import {fork,all} from 'redux-saga/effects'
import taskSaga from './task'
import authSaga from './auth'
import pageUserSaga from './pageUser'
import staffSaga from './staff'
import govSaga from './gov'
import personSaga from './person'
import vipSaga from './vip'

function* AdminSaga(){
  yield all(
    [
      yield fork(taskSaga),
      yield fork(authSaga),
      yield fork(pageUserSaga),
      yield fork(staffSaga),
      yield fork(govSaga),
      yield fork(personSaga),
      yield fork(vipSaga)
    ]
  )
}

export default AdminSaga