import { 
  //lighten, 
  makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  colorError:{
    color:'red',
    marginTop:'1px',
    fontSize:12
  }
}));

