import React,{Suspense, useState} from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,Fab,Select,FormControl, 
  MenuItem,FormHelperText} from '@material-ui/core'
import {useStyles} from './styles'
import {Delete,Edit,Cancel,Save,Add} from '@material-ui/icons'
import {useSelector,useDispatch} from 'react-redux'
import {handleNumberDot} from '../../../../../../helper/cost/costStudent'
import {DISPATCH_DELETE_ITEM_INFO_USER_DETAIL,DISPATCH_OPEN_DELETE_ITEM_INFO_USER_DETAIL,DISPATCH_CLOSE_DELETE_ITEM_INFO_USER_DETAIL,
  EDIT_ITEM_INFO_USER_DETAIL,ADD_INFO_USER_DETAIL
} from '../../../../../../contant/Gov/login'
import AlertForm from  '../../../../../../helper/componentFn/alertForm'
import {FilterSelectCost,FilterSelectCostNotItem} from '../../../../../../helper/GovLogin/index'


const Index=React.memo(()=> {
  const dispatch= useDispatch()
  const classes = useStyles()
  const ListInfoDetail = useSelector(state=>state.GovLogin.ListInfoUserDetail)
  const ListCost       = useSelector(state=>state.CostStudent.listCostStudent)
  const OpenFormDelete = useSelector(state=>state.GovLogin.OpenFormDelete)
  
  const [EditForm,setEditForm] =   useState(false)
  const [AddForm,setAddForm]   =   useState(false)
  const [item,setItemDelete] = useState('')
  const [itemEdit,setItemEdit]= useState('')
  const [ArrEdit,setArrEdit] = useState([])
  const [itemValue,setItemValue] = useState('')
  const [itemAdd,setItemAdd]     = useState("")
  //delete
  const handleDelete=(Item)=>{
      dispatch({
        type:DISPATCH_OPEN_DELETE_ITEM_INFO_USER_DETAIL
      })
     setItemDelete(Item)
  }
  const CloseFormDelete=()=>{
    dispatch({
      type:DISPATCH_CLOSE_DELETE_ITEM_INFO_USER_DETAIL
    })
  }
  const handleSubmitDelete=()=>{
    dispatch({
      type:DISPATCH_DELETE_ITEM_INFO_USER_DETAIL,
      payload:{item}
    })
    dispatch({
      type:DISPATCH_CLOSE_DELETE_ITEM_INFO_USER_DETAIL
    })
  }
  //delete

  //edit
  const handleEdit=(Item)=>{
      setItemEdit(Item)
      setEditForm(true)
      let FindArr = FilterSelectCost(ListCost,ListInfoDetail,Item)
      setArrEdit(FindArr)
  }

  const handleCancel=()=>{
      setEditForm(false)
  }
  const handleSave=()=>{
    let CheckExist = ListInfoDetail.filter(v=>(v._id===itemValue._id&&
                                                v.userId===itemValue.userId&&
                                                v.edit===itemValue.edit&&
                                                v.price===itemValue.price&&
                                                v.name===itemValue.name&&
                                                v.typeOfCountEat===itemValue.typeOfCountEat
      ))
      if(CheckExist.length!==0){
        setEditForm(false)
        return true
      }
    dispatch({
      type:EDIT_ITEM_INFO_USER_DETAIL,
      payload:{itemEdit:itemValue}
    })
    setEditForm(false)
  }

  const handleChange =(e)=>{
      let item ={
        _id:itemEdit._id,
        userId:itemEdit.userId,
        edit:itemEdit.edit,
        price:e.target.value.price,
        name:e.target.value.name,
        typeOfCountEat:itemEdit.typeOfCountEat
      }
      setItemValue(item)        
  }
  //edit

  const ShowListHTML=()=>{
      return ListInfoDetail.map((v,i)=>{
        return(
          <TableRow key={i}>
            <TableCell align="center">{i+1}</TableCell>
            <TableCell align="center">{v.name}</TableCell>
            <TableCell align="center">{handleNumberDot(v.price)}</TableCell>
            <TableCell align="right"><Fab color="secondary" size="small" onClick={()=>handleDelete(v)}><Delete/></Fab></TableCell>
            <TableCell align="left"><Fab color="primary" size="small" onClick={()=>handleEdit(v)}><Edit/></Fab></TableCell>            
          </TableRow>
        )
      })
  }

  const ShowEditForm=()=>{  
    return(
       <TableRow >
          <TableCell align="center"></TableCell>
          <TableCell align="center">
            <FormControl  className={classes.formControl}>
              <Select                       
                  defaultValue={""}      
                  onChange={handleChange}                      
              >
              {ArrEdit.map((v,i)=>{
                return(
                <MenuItem value={v} key={i} >{v.name} : {handleNumberDot(v.price)}</MenuItem>
                )
              })}         
            </Select>    
            <FormHelperText>{itemEdit.name} - {handleNumberDot(itemEdit.price)}</FormHelperText>   
            </FormControl>
         </TableCell>        
          <TableCell align="right"><Fab color="secondary" size="small" onClick={()=>handleSave()}><Save/></Fab></TableCell>
          <TableCell align="left"><Fab color="primary" size="small" onClick={()=>handleCancel()}><Cancel/></Fab></TableCell>            
      </TableRow>
    )   
  }

  //add 
  const HandleAdd=()=>{
      setAddForm(true)
  }
  const HandleSaveAdd=()=>{  
      if(itemAdd===""){
        setAddForm(false)
        return true
      }
      dispatch({
        type:ADD_INFO_USER_DETAIL,
        payload:{item:itemAdd}
      })
      setAddForm(false)
  }
  const HandleCancelAdd=()=>{
    setAddForm(false)
  }
  const handleChangeAdd=(e)=>{
      let itemAdd = e.target.value
      let Item={
          userId:ListInfoDetail.length!==0?ListInfoDetail[0].userId:"",
          typeOfCountEat:ListInfoDetail.length!==0?ListInfoDetail[0].typeOfCountEat:"",
          edit:ListInfoDetail.length!==0?ListInfoDetail[0].edit:"",
          name:itemAdd.name,
          price:itemAdd.price
      }
      setItemAdd(Item)
  }
  const ShowAddHTML=()=>{
    let findArr = FilterSelectCostNotItem(ListCost,ListInfoDetail)
    return(
      <>
         <TableRow >
          <TableCell align="center"></TableCell>
          <TableCell align="center">
            <FormControl  className={classes.formControl}>
            <Select                       
                  defaultValue={""}      
                  onChange={handleChangeAdd}                      
              >
              {findArr.map((v,i)=>{
                return(
                <MenuItem value={v} key={i} >{v.name} : {handleNumberDot(v.price)}</MenuItem>
                )
              })}        
              </Select> 
            </FormControl>
         </TableCell>        
          <TableCell align="right"><Fab color="secondary" size="small" onClick={()=>HandleSaveAdd()}><Save/></Fab></TableCell>
          <TableCell align="left"><Fab color="primary" size="small" onClick={()=>HandleCancelAdd()}><Cancel/></Fab></TableCell>            
      </TableRow>
      </>
    )
  }
  //add

  return (
    <Suspense fallback={()=>{}}>
          {OpenFormDelete&&(<AlertForm
              open={OpenFormDelete}
              handleClose={CloseFormDelete}
              handleSubmit={handleSubmitDelete}
              value={"Bạn có chắc chắn xóa"}
        />)}

        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Stt</TableCell>
                <TableCell align="center">Các loại khoản thu</TableCell>
                {EditForm?(<>
                        <TableCell align="center">Lưu</TableCell>
                        <TableCell align="center">Hủy</TableCell>
                          </>
                  ):(                
                  <>
                    {AddForm===true?(
                    <>
                                <TableCell align="center">Lưu</TableCell>
                                <TableCell align="center">Hủy</TableCell>
                    </>):(
                            <>
                              <TableCell align="center">Giá</TableCell>         
                                <TableCell align="center">Xóa</TableCell>
                                <TableCell align="center">Thay đổi</TableCell>
                                <TableCell align="center"><Fab color="secondary" size="small" onClick={()=>HandleAdd()}><Add/></Fab></TableCell>
                            </>
                    )}
                    
                  </>
                  
                  )}  
              
              </TableRow>
            </TableHead>
            <TableBody>
              {EditForm===true?(
              <>
                  {ShowEditForm()}
              </>):(                
                <>        
                    {AddForm===true?(
                      <>
                        {ShowAddHTML()}
                      </>
                    ):(
                      <>
                        {ShowListHTML()}
                      </>
                    )}    
                    
                </>
              )}            
                
            </TableBody>
          </Table>
        </TableContainer>
    </Suspense>
  );
})

export default Index
