import styled,{keyframes} from "styled-components";
import {device} from '../device'
import { MainColor } from "./color";
import MealImg from './imgMeal/mealAll.jpeg'

// about us

export const gradient = keyframes`
    100% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    0% {
        background-position: 0% 0%;
    }
`

export const DivImg = styled.img`
    height:250px;
    margin-left: 159px;
    margin-top: -48px;
    @media ${device.mobile}{
        height:150px;
        margin-top:50px;
    }
    @media ${device.tablet}{
        height:160px;
        margin-top:100px;
    }
`

export const NameSoftH3= styled.h1`
        color:${MainColor};
        font-size:18px;       
        line-height:0; 
        @media ${device.mobile}{
            font-size:14px;
        }
`

// about us

// tinh năng
    
export const TinhNangMain= styled.div`
    width:100%;
    height:650px;
    position:relative;
    z-index:1;
    top:0;
    left:0;
    background:url(${MealImg}) bottom center / cover no-repeat;
    background-position: center center;
    margin:auto;
    @media ${device.mobile}{
         height:2200px;   
    }
`
export const BGShadow=styled.div`
        width:100%;
        height:100%;
        left:0;
        top:0;
        position:absolute;
        z-index:2;
        ${'' /* background-color:rgba(0,0,0,0.8); */}
        background: linear-gradient(315deg,#34495e 10%, rgba(0, 0, 0, 0.87) 38%, #16a085 68%,#16a085 85%, rgba(0, 0, 0, 0.87) 98%);
        animation: ${gradient} 30s ease infinite;
        background-size: 200% 200%;
        background-attachment: fixed;
`

export const ListTinhNang =styled.div`
    z-index:3;
    position:absolute;
    width:100%;
    height:100%;
`
export const ListTinhNangIn = styled.div`                      
       width:100%;
       height:100%;       
       display:flex;
       justify-content:center;
       @media ${device.mobile}{
            display:inline-block;
       }
`

export const ItemTinhNang = styled.div`
    text-align: center;
    margin: 10px;
    width: calc(100% / 3);
    height: 388px;
    background: #171617;
    border-radius: 5px;
    border-color: #bbc7d1;
    box-shadow: 1px 1px 1px 1px navajowhite;
    &:hover{
        cursor:pointer;
        background: linear-gradient(315deg,#34495e 10%, rgba(0, 0, 0, 0.87) 38%,  #7f8c8d  68%,#16a085 85%, rgba(0, 0, 0, 0.87) 98%);
        animation: ${gradient} 15s ease infinite;
        background-size: 200% 200%;
        background-attachment: fixed;
    }
    @media ${device.mobile}{
        width:80%;   
        margin:auto;
        margin-bottom:20px;
    }
`
//tinh năng

//why choose us
export const MainChooseUs=styled.div`
    width:100%;
    height:580px;
    background:#171616;
    margin:auto;
    padding-top:50px;
    padding-bottom:50px;
    &:hover{
        background: linear-gradient(315deg,#34495e 10%, rgba(0, 0, 0, 0.87) 38%,  #7f8c8d  68%,#16a085 85%, rgba(0, 0, 0, 0.87) 98%);
        animation: ${gradient} 15s ease infinite;
        background-size: 200% 200%;
        background-attachment: fixed;
    }
    @media  (max-width:768px){
        height:600px;
    }
    @media (min-width:769px) and (max-width:1024px){
        height:600px;
    }
`

export const TextChooseUs=styled.div`
    display:block;
    color:${MainColor};
    font-size:40px;
    text-align:center;
    font-weight:bold;   
    padding-bottom:10px;    
    @media ${device.mobile}{
        font-size:25px;
    }
    
`

// export const ContentChoose= styled.div`
//         margin-top:25px;
//         display:flex;
//         justify-content:center;       
// `
export const MainBox = styled.div`
    margin-top:20px;
    @media ${device.mobile}{
        margin-top:-50px;
    }
    @media (max-width:1024px){
        margin-top:-50px;
    }
`

export const ContentRow= styled.div`
        width:70%;
        height:125px;
        display:flex;
        justify-content:center;
        margin-bottom:20px;
        @media ${device.mobile}{
            display:block;
            width:90%;    
            margin:auto;   
            text-align:center;
            height:80px;     
            margin-bottom:20px;
        }
        @media (max-width:1024px){
            display:block;
            width:90%;    
            margin:auto;   
            text-align:center;
            height:80px;     
            margin-bottom:20px;
        }
`

export const ContentRowTwo= styled.div`
        width:70%;
        height:125px;
        display:flex;
        justify-content:center;
        margin-bottom:20px;
        @media ${device.mobile}{
            display:block;
            width:90%;    
            margin:auto;   
            text-align:center;
            height:80px;     
            margin-bottom:20px;
            margin-top:98px;
        }
        @media (max-width:1024px){
            display:block;
            width:90%;    
            margin:auto;   
            text-align:center;
            height:80px;     
            margin-bottom:20px;
            margin-top:98px;
        }
`

export const ContentRowThree= styled.div`
        width:70%;
        height:125px;
        display:flex;
        justify-content:center;
        margin-bottom:20px;
        @media ${device.mobile}{
            display:block;
            width:90%;    
            margin:auto;   
            text-align:center;
            height:80px;     
            margin-bottom:20px;
            margin-top:98px;
        }
`

export const ImgKidTop = styled.img`
        height:100px;
        border-radius:50%;
        argin-top: 10px;
        margin-left: 15px;
        @media ${device.mobile}{
            height:60px;
            border-radius:50%;
            margin-top: 5px;
            margin-left: 8px;
        }
`

export const YoutubeKidTop = styled.img`
        height:100px;
        border-radius:50%;
        argin-top: 10px;
        margin-left: 15px;
        @media ${device.mobile}{
            height:60px;
            border-radius:50%;
            margin-top: -4px;
            margin-left: 8px;
        }
`

export const RowLeft = styled.div`
        background:white;
        width:50%;     
        border:1px solid ${MainColor};
        height:100%;
        display:flex;
        translate: 10px;
        @media ${device.mobile}{
            width:100%;
            height:100%;
            display:flex;
            margin-bottom:20px;
            translate:0;
            margin-top:60px;
        }
        @media (max-width:1024px){
            width:100%;
            height:100%;
            display:flex;
            margin-bottom:20px;
            translate:0;
            margin-top:60px;
        }
`

export const InLeft = styled.div`
    width:30%;
    background:${MainColor};
    @media ${device.mobile}{
        display:none;
    }
    @media (max-width:1024px){
        display:none;
    }
`

export const InLeftMobile = styled.div`
        @media ${device.pc}{
            display:none;
        }
        @media ${device.mobile}{
            display:content;
            width:30%;
            background:${MainColor};            
        }
        @media (max-width:1024px){
            display:content;
            width:30%;
            background:${MainColor};     
      }
`

export const IconMobileLeft = styled.p`
        @media ${device.mobile}{
            
        }
`

export const InRight = styled.div`
    width:70%;
    height:100%;
`

export const Round = styled.div`
        width:45px;
        height:45px;
        border-radius:50%;
        background:${MainColor};
        margin:auto;
        margin-top:20px;
        border:3px solid gray;
        z-index:3;
        @media ${device.mobile}{
            display:none;
        }
        @media (max-width:1024px){
            display:none;
        }
`

export const TextIconOne=styled.svg`
        font-size:120px;
        color:white;
`

export const TextOne = styled.p`
       display:inline-block;
        margin-top:46px;
        margin-left:30px;
        font-size:20px;
        font-weight:bold;
        color: #4e6375;
        font-family: SANS-SERIF;
        @media ${device.mobile}{
            font-size:15px;
            margin-top: 30px;
            margin-left: -3px;
        }
        @media (max-width:1024px){
            font-size:15px;
            margin-top: 30px;
            margin-left: -3px;     
       }
`

export const RowRight = styled.div`
        background:white;
        width:50%;        
        border:1px solid ${MainColor};
        height:100%;
        display:flex;
        translate: -10px;
        @media ${device.mobile}{
            width:100%;
            height:100%;
            display:flex;
            translate:0;
            margin-bottom:20px;
        }
        @media (max-width:1024px){
            width:100%;
            height:100%;
            display:flex;
            translate:0;
            margin-bottom:20px;
        }

`

export const InRightLeftMobile= styled.div`     
    @media ${device.pc}{
        display:none;
    }
    @media ${device.mobile}{
    width:30%;
    background:${MainColor};
    height:100%;
    }
    @media (max-width:1024px){
         width:30%;
         background:${MainColor};
         height:100%;
    }    
`

export const InRightRightMobile= styled.div`
        @media ${device.pc}{
            display:none;
        }
         @media ${device.mobile}{
          width:70%;
          height:100%;
         }
         @media (max-width:1024px){
            width:70%;
            height:100%;
        }    
`

export const InRightLeft = styled.div`
    width:70%;   
    background:white;
    height:100%;
    @media ${device.mobile}{
        display:none;
    }
    @media (max-width:1024px){
           display:none;
    }  
`

export const InRightRight = styled.div`
    width:30%;
    background:${MainColor};
    @media ${device.mobile}{
           display:none;
    }
    @media (max-width:1024px){
           display:none;
    }  
`

export const TextTwo = styled.p`
   display:inline-block;
   margin-left:81px;
   margin-top:46px;
   font-size:20px;
   font-weight:bold;
   color: #4e6375;
   font-family: SANS-SERIF;
   @media ${device.mobile}{
            font-size:15px;
            margin-top: 30px;
            margin-left: -3px;
        }
        @media (max-width:1024px){
            font-size:15px;
            margin-top: 30px;
            margin-left: -3px;
    }  
`
//why choose us
