import { Box } from '@material-ui/core'
import React from 'react'
import InfoCus from '../../../ComponentGov/SoftFoodTabMain/InfoCus'
import { MainDiv } from './style'

const Index=React.memo(()=> {
  return (
  <MainDiv>
    <Box display="flex" justifyContent="center">
          <InfoCus/>
    </Box>  
  </MainDiv>
  )
})

export default Index
