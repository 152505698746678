export const TEXT_ALERT                        = "Danh Mục này đã tồn tại, nhập tên khác"
export const TEXT_ALERT_TYPE_MATERIAL          = "Thực phâm này đã tồn tại, nhập tên khác"

export const TITLE_ADD_MATERIAL                = "Thêm danh mục nguyên liệu"
export const CHECK_DATA                        = "kiểm tra lại dữ liệu nhập"
export const TITLE_ADD_MATERIAL_SUCCESS        = "Thêm danh mục nguyên liệu thành công"

export const TITLE_TYPE_MATERIAL               = "Thêm thực phẩm"
export const FIELD_NOT_EMPTY                   = "chưa nhập liệu!"
export const ADD_TYPE_MATERIAL_SUCCESS_ALERT   = "Thêm Thành công, ấn nút quay lại để cập nhật dữ liệu"
export const REQUIRE_DATA_DELETE               = "Đang có dữ liệu, không thể sửa"
