import {put,call,select, takeLatest, takeEvery} from 'redux-saga/effects'
import{push} from 'connected-react-router'
import {AddBlock,UpdateBlock,ListAllBlockByUserId,AddClass,
  ListAllClassByUserId,UpdateClass,
  AddStudent,DeleteStudent,ListAllStudentByUserId,UpdateStudent,
  AddManyStudent,ListAllYearSchool,AddYearSchool,UpdateYearSchool,addCheckDateFood,listAllCheckDateFood,editCheckDateFood,
  deleteStudentNotEat,DeleteRegisSevenDay, deleteCheckDateFood,AddStudentNow,ListStudentNow,DeleteStudentNow,
  AddStudentRegisSevenDay,AddStudentAfter,AddBuffet,ListBuffet, UpdateBuffet,RemoveBuffet,
  UpdateListStudentNow,DeleteListStudentNow,GetStudentLeaveTemporaty,AddStudentLeaveTemporaty,UpdateStudentLeaveTemporaty, GetStudentLeaveTemporatyNotByDateNow,
  AddReceipts,GetReceiptsByUserClassYearSchool,UpdateReceipts,AddUpdateDailyTuiApi,GetListUpdateDailyTuiApi,UpdateUpdateDailyTuiApi, AddManyCheckDateFoodApi,DeleteClassApi, DeleteStudentInStudentNowApi, DeleteBlockClassApi, ManagerDeleteItemStudentInfoApi, GetListDeleteItemStudentInfoApi
} from '../../../api-services/student/student'
import { ADD_BLOCK,LIST_BLOCK,EDIT_BLOCK,
  ADD_CLASS,LIST_CLASS, EDIT_CLASS,ADD_STUDENT, LIST_ALL_STUDENT,UPLOAD_FILE_EXCEL,
  LIST_ALL_YEAR_SCHOOL,ADD_YEAR_SCHOOL,EDIT_YEAR_SCHOOL,EDIT_STUDENT_YEAR_SCHOOL,DELETE_STUDENT_YEAR_SCHOOL,  
  ADD_CHECK_DATE_FOOD,LIST_CHECK_DATE_FOOD,UPDATE_ITEM_STUDENT_CHECK_DATE_FOOD,DISPACTH_DELETE_STUDENT_ARR,DELETE_ITEM_REGIS_SEVEN_DAY,
  DELETE_ITEM_CHECK_DATE_FOOD,DISPATCH_LIST_STUDENT_CLASS_BY_YEAR_SCHOOL_DB_LIST_STUENT_NOW,DELETE_ITEM_STUDENT_NOW,
  ALL_LIST_STUDENT_NOW,
  DISPATCH_SAVE_LIST_STUDENT_NOW_TO_SAGA,
  ADD_STUDENT_AFTER,
  UPDATE_LIST_STUDENT,
  UPDATE_ALERT_SUCCESS_LOGOUT,
  DELETE_STUDENT_NOW,
  STUDENT_LEAVE_TEMPORATY,
  GET_STUDENT_LEAVE_TEMPORATY,
  UPDATE_STUDENT_LEAVE_TEMPORATY,
  GET_STUDENT_LEAVE_TEMPORATY_NOT_BY_DATE_NOW,DELETE_STUDENT_YEAR_SCHOOL_IN_MANAGER_STUDENT, DELETE_CLASS,
  DELETE_ITEM_STUDENT_IN_STUDENT_NOW,DELETE_BLOCK_CLASS
} from '../../../contant/Gov/student/dispatch'
import {ADD_BUFFET_DB,LIST_BUFFET,UPDATE_BUFFET_DB,REMOVE_BUFFET,ADD_PRINT_RECEIPTS,GET_RECEIPTS_BY_USERID_IDCLASS_IDYEARSCHOOL,UPDATE_RECEIPTS,ADD_UPDATE_DAILY_TUI,GET_LIST_UPDATE_DAILY_TUI,
UPDATE_ITEM_UPDATE_DAILY_TUI,
DISPATCH_SAO_CHEP_TINH_AN
} from '../../../contant/Gov/countEat/dispatch'
import {addBlockToStore,ListTableBlock,
  dispatchAddClassToStore,dispatchListClassToStore, 
  dispatchListAllStudentToStore,closeFormImportExcel,
dispatchAddStudentToStore,updateArrAfterUploadExcel,
openFormAddYearSchool,dispatchAddYearSchoolToStore,dispatchListYearSchoolToStore,
addYearSchoolSuccess,openFormSelect,dispatchAddCheckDateFood,dispatchListCheckDateFood,
updateCheckDateFoodSuccess,DispatchAddStudentRegisSevenDayToStore,
DispatchAddListStudentNowToStore,DispatchAllListStudentNow,DeleteItemStudentNowSuccess,
AddBuffetToStore,ListBuffetToStore,DispatchStudentLeaveTemporatyToStore,AddStudentLeaveTemporatyToStore, UpdateStudentLeaveTemporatyAgain,GetStudentLeaveTemporatyNotByDateNowToStore,
DispatchAddUpdateDailyTuiToStore,DispatchListUpdateDailyTui, dispatchAddManyCheckDateFood, DispatchAddDeleteItemStudentInfo
} from '../../../actions/Gov/student/student'
import {DispatchAddReceiptsToStore,DispatchGetReceiptsToStore}from '../../../actions/Gov/countEat'
import {RegisSevenDayBeginMonth} from '../../../helper/dateTime/handleDMY'
import * as uiLoading from '../../../actions/uiLoading'
import {REDIRECT_LOGIN_PAGE} from '../../../contant/auth'
import { AddManyMaterialMealDateApi } from '../../../api-services/cost/material'
import { dispatchAddManyMaterialMealDateToStore } from '../../../actions/Gov/cost/material'
import { delay } from 'lodash'
import { GET_LIST_DELETE_ITEM_STUDENT_INFO, MANAGER_DELETE_ITEM_STUDENT_INFO } from '../../../contant/Gov/softFood'
import { TimePrvOneMonth } from '../../../utils/DayTime'


function* listStudentSaga(){  
  let userId = yield select(state=>state.GovLogin.userId)
  let id = {id:userId}  
  let res = yield call(ListAllBlockByUserId,id)
  yield put(ListTableBlock(res.data.data))
}

function* addBlockSaga({payload}){
  yield put(uiLoading.loadingSuccess())  
  let res = yield call(AddBlock,payload.item)
  if(res.data.result==='success'){
    yield put(addBlockToStore(res.data.data))   
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
  yield put(uiLoading.loadingFail()) 
}

function* editBlockSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res=yield call(UpdateBlock,payload.item)   
    if(res.data.result==='success'){
       yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }    
}

function* addClassSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(AddClass,payload.item)
  if(res.data.result==='success'){
    yield put(dispatchAddClassToStore(res.data.data))
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }  
}

function* listClassSaga(){
  yield put(uiLoading.loadingSuccess())
  let userId = yield select(state=>state.GovLogin.userId)
  let id={id:userId}
  let res = yield call(ListAllClassByUserId,id)
  if(res.data.result==='success'){
    yield put(dispatchListClassToStore(res.data.data))
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }  
}

function* editClassSaga({payload}){
    yield put(uiLoading.loadingSuccess())    
    let res = yield call(UpdateClass,payload.item)    
    if(res.data.result==='success'){
      yield put(uiLoading.loadingFail())      
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }   
}

function* addStudentSaga({payload}){
  yield put (uiLoading.loadingSuccess())
  let res = yield call(AddStudent,payload.item)
  if(res.data.result==='success'){
    yield put(dispatchAddStudentToStore(res.data.data))
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
}

function* AddStudentAfterSaga({payload}){
    yield put(uiLoading.loadingSuccess())       
    let res = yield call(AddStudentAfter,payload.item)
    if(res.data.result==='success'){
      //let userId = yield select(state=>state.GovLogin.userId)
      ///let id     = {id:userId}  
      //let res2   = yield call(ListStudentNow,id)   
      //let res3   = yield call(listAllCheckDateFood,id)     
      //yield put(DispatchAllListStudentNow(res2.data.data))
      //yield put(dispatchListCheckDateFood(res3.data.data))   
      //yield put(UpdateAlertSuccessLogout())  
      yield put(uiLoading.loadingFail())
    }else{
      alert('Error AddStudentAfterSaga')
    }
}


function* listAllStudentSaga(){
  yield put(uiLoading.loadingSuccess())
  let userId = yield select(state=>state.GovLogin.userId)
  let id={id:userId}
  let res = yield call(ListAllStudentByUserId,id)  
  if(res.data.result==='success'){
    let idYearSchool = yield select(state=>state.Student.NameYearSchool._id)
    let arrStudentByYearSchool = res.data.data.filter(v=>v.idYearSchool===idYearSchool)
    yield put(dispatchListAllStudentToStore(arrStudentByYearSchool))
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }  
}

function* uploadFileExcelSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(AddManyStudent,payload.result)
   if(res.data.result==='success'){
     yield put(closeFormImportExcel())
     yield put(updateArrAfterUploadExcel(res.data.data))
     yield put(uiLoading.loadingFail())
   }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
   }
}

function* ListYearSchoolSaga(){
  yield put (uiLoading.loadingSuccess())
  let userId = yield select(state=>state.GovLogin.userId)
  let ConditionOpenFormSelect = yield select(state=>state.Student.ConditionOpenFormSelect)
  let id = {id:userId} 
  let res = yield call(ListAllYearSchool,id)
  if(res.data.result==='success'){
    if(res.data.data.length===0){     
      yield put(openFormAddYearSchool())
    }else{
      yield put(dispatchListYearSchoolToStore(res.data.data))
      //thuc hien 1 chon nam hoc de khoi tao du lieu
      if(ConditionOpenFormSelect===0){
         yield put(openFormSelect())  
      }         
    }
  }
}

function* addYearSchoolSaga({payload}){
    yield put (uiLoading.loadingSuccess())
    let ConditionOpenFormSelect = yield select(state=>state.Student.ConditionOpenFormSelect)
    let res = yield call(AddYearSchool,payload.item)
    if(res.data.result==='success'){
      yield put(dispatchAddYearSchoolToStore(res.data.data))
      if(ConditionOpenFormSelect===0){
        yield put(openFormSelect())  
      }   
      yield put(addYearSchoolSuccess())
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* editYearSchoolSaga({payload}){
  yield put(uiLoading.loadingSuccess())    
    let res = yield call(UpdateYearSchool,payload.item)
    if(res.data.result==='success'){
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* editStudentYearSchoolSaga({payload}){
  yield put(uiLoading.loadingFail())
  let res = yield call(UpdateStudent,payload.item)  
  if(res.data.result==='success'){
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
}

function* deleteStudentSchoolSaga({payload}){
  yield put(uiLoading.loadingFail())
  let res = yield call(DeleteStudent,payload.item)  
  if(res.data.result==='success'){
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
 } 
}

function* DeleteStudentYearSchoolInManagerStudentSaga({payload}){
  yield put(uiLoading.loadingFail())
  let res = yield call(DeleteStudent,payload.item)  
  if(res.data.result==='success'){
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
 } 
}

function* DeleteItemStudentInStudentNowSaga({payload}){
      yield put(uiLoading.loadingFail())
      let res = yield call(DeleteStudentInStudentNowApi,payload.item)
      if(res.data.result==='success'){
        yield put(uiLoading.loadingFail())
        window.location.reload()       
    }else{
        alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
     } 
    
}


function* DeleteClassSaga({payload}){
    yield put(uiLoading.loadingFail())
    let res = yield call(DeleteClassApi,payload.item)  
    if(res.data.result==='success'){
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  } 
}

function* addCheckDateFoodSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(addCheckDateFood,payload.item)
  if(res.data.result==='success'){
    yield put(addYearSchoolSuccess())
    yield put(dispatchAddCheckDateFood(res.data.data))
    yield put(uiLoading.loadingFail())    
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
 } 
}

function* DispatchSaoChepTinhAnSaga({payload}){
  let res = yield call(AddManyCheckDateFoodApi,payload.item.newArrCheckDate)
  if(res.data.result==='success'){
    yield put(dispatchAddManyCheckDateFood(res.data.data))
    yield put(uiLoading.loadingFail())    
  }
  let res2 = yield call(AddManyMaterialMealDateApi,payload.item.newArrMaterail)
  if(res2.data.result==='success'){
    yield put(dispatchAddManyMaterialMealDateToStore(res2.data.data))
    yield put(uiLoading.loadingFail())    
  }

}

function* listAllCheckDateFoodSaga(){
    yield put(uiLoading.loadingSuccess())
    let userId = yield select(state=>state.GovLogin.userId)
    let id = {id:userId}
    let res = yield call(listAllCheckDateFood,id)   
    if(res.data.result==='success'){
      yield put(dispatchListCheckDateFood(res.data.data))
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* updateItemStudentCheckDateFoodSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res=yield call (editCheckDateFood,payload.item)
  if(res.data.result==='success'){    
    yield put(updateCheckDateFoodSuccess())
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }
}

function* deleteStudentNotEatSaga({payload}){    
    yield put(uiLoading.loadingSuccess())
    let res = yield call(deleteStudentNotEat,payload.deleteArr)
    if(res.data.result==='success'){    
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }    
}

function* deleteRegisSevenDaySaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(DeleteRegisSevenDay,payload.item)
  if(res.data.result==='success'){    
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }    
}

function* deleteCheckDateFoodSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(deleteCheckDateFood,payload.item)
   // console.log(res)
    if(res.data.result==='success'){    
      yield put(uiLoading.loadingFail())
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* AddListStudentNowSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(AddStudentNow,payload.item)
  //console.log(res)
  if(res.data.result==='success'){
    yield put(DispatchAddListStudentNowToStore(res.data.data))
    yield put(uiLoading.loadingFail())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }  
}

function* AllListStudentNowSaga(){
  yield put(uiLoading.loadingSuccess())
    let userId = yield select(state=>state.GovLogin.userId)
    let id = {id:userId}
    let res = yield call(ListStudentNow,id)    
    //console.log(res)
    if(res.data.result==='success'){
      yield put(DispatchAllListStudentNow(res.data.data))
    }else{
      alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
    }
}

function* SaveListStudentNowToSaga({payload}){
    yield put(uiLoading.loadingSuccess())
   
    //console.log(payload.ArrIdUndefined.length)
    let{ArrIdUndefined,FilterIdNotUndefined,ShowDateMonth,FilterListRegisSevenDay} =payload   
    let arrStudentIdNotUndefined =FilterIdNotUndefined.length!==0?FilterIdNotUndefined.map(v=>{let item = {idStudent:v._id,fullName:v.fullName}
      return item
    }):[]
        if(ArrIdUndefined.length!==0){      
          let res = yield call(AddManyStudent,ArrIdUndefined)     
            if(res.data.result==='success'){          
            let arrStudentIdUndefined = res.data.data.map(v=>{
            let item={
              idStudent:v._id,
              fullName:v.fullName
            }
            return item
          })      
            let arrResult = arrStudentIdNotUndefined.concat(arrStudentIdUndefined)
          // console.log(arrResult)
            let item={
              idClass:ArrIdUndefined[0].idClass,
              idYearSchool:ArrIdUndefined[0].idYearSchool,
              idBlockClass:ArrIdUndefined[0].idBlockClass,
              userId:ArrIdUndefined[0].userId,
              dateNow:ShowDateMonth,
              listStudentsNow:arrResult
            }
            let res2 = yield call(AddStudentNow,item)
            if(res2.data.result==='success'){
              yield put(DispatchAddListStudentNowToStore(res2.data.data))
              let CheckThOrMn = yield select(state=>state.GovLogin.ListInfoUserDetail)
            if(CheckThOrMn[0].typeOfCountEat===0){
                let item = RegisSevenDayBeginMonth(FilterListRegisSevenDay,[res2.data.data],ShowDateMonth)                  
                if(!item){
                  alert('Lỗi, liên hệ với quản trị để xử lý, mã lỗi 007BR')
                }
                let resRegis = yield call(AddStudentRegisSevenDay,item)
                if(resRegis.data.result==='success'){
                  yield put(DispatchAddStudentRegisSevenDayToStore(resRegis.data.data))
                }else{
                  alert('Lỗi, Liên hệ với quản trị, Mã Lỗi :0007ARSGA')
                }
            }          
              yield put(uiLoading.loadingFail())
            }else{
            alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
        }  
      }
    }
    if(ArrIdUndefined.length===0){
          let item={
              idClass:FilterIdNotUndefined[0].idClass,
              idYearSchool:FilterIdNotUndefined[0].idYearSchool,
              idBlockClass:FilterIdNotUndefined[0].idBlockClass,
              userId:FilterIdNotUndefined[0].userId,
              dateNow:ShowDateMonth,
              listStudentsNow:arrStudentIdNotUndefined
            }
            let res = yield call(AddStudentNow,item)
            if(res.data.result==='success'){
              yield put(DispatchAddListStudentNowToStore(res.data.data))
              let CheckThOrMn = yield select(state=>state.GovLogin.ListInfoUserDetail)
              if(CheckThOrMn[0].typeOfCountEat===0){
                  let item = RegisSevenDayBeginMonth(FilterListRegisSevenDay,[res.data.data],ShowDateMonth)       
                if(!item){
                  alert('Lỗi, liên hệ với quản trị để xử lý, mã lỗi 007BRSA')
                }
                let resRegis = yield call(AddStudentRegisSevenDay,item)
                if(resRegis.data.result==='success'){
                  yield put(DispatchAddStudentRegisSevenDayToStore(resRegis.data.data))
                }else{
                  alert('Lỗi, Liên hệ với quản trị, Mã Lỗi :0007ARSGA')
                }
              }          
              yield put(uiLoading.loadingFail())
            }else{
              alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
            }  
        }    
}

function* DeleteItemStudentNowSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(DeleteStudentNow,payload.item[0])
  if(res.data.result==='success'){
    yield put(uiLoading.loadingFail())
    yield put(DeleteItemStudentNowSuccess())
  }else{
    alert('Vui lòng đợi, nếu lâu quá,liên hệ với quản trị để hỗ trợ')
  }  
}

function* AddBuffetDbSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(AddBuffet,payload.item)
  if(res.data.result==='success'){
    yield put(uiLoading.loadingFail())
    yield put(AddBuffetToStore(res.data.data))
  }else{
    alert('ADD_BUFFET_DB_SAGA')
  }
}

function* ListBuffetSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  if(payload.item.dateNow!=="") {
    let ItemDate = TimePrvOneMonth(payload.item.dateNow,30)
    let result = {
      userId:payload.item.userId,
      dateNow:payload.item.dateNow,
      prvMM:ItemDate
    }
      let res = yield call(ListBuffet,result)
      if(res.data.result==='success'){
        yield put(uiLoading.loadingFail())
        yield put(ListBuffetToStore(res.data.data))
      }else{
        alert('Lỗi, LIST_BUFFET_SAGA')
      }
  }  
}

function* UpdateBuffetDbSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(UpdateBuffet,payload.item)
  if(res.data.result==='success'){
    yield put(uiLoading.loadingFail())
  }else{
    alert('Lỗi, UPDATE_BUFFET_DB_SAGA')
  }
}

function* RemoveBuffetSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res = yield call(RemoveBuffet,payload.item)
  if(res.data.result==='success'){
    yield put(uiLoading.loadingFail())
  }else{
    alert('Lỗi, REMOVE_BUFFET_DB_SAGA')
  }
}

function* UpdateListStudentSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(UpdateListStudentNow,payload.item)
    if(res.data.result==='success'){ 
       yield put(uiLoading.loadingFail())
    }
}

function* UpdateAlertSuccessLogoutSaga(){
  yield put(push(REDIRECT_LOGIN_PAGE))
}

function* DeleteStudentNowSaga({payload}){
  yield put(uiLoading.loadingSuccess())
  let res= yield call(DeleteListStudentNow,payload.item)
  if(res.data.result==='success'){ 
    yield put(uiLoading.loadingFail())
 }
}

function* StudentLeaveTemporatySaga({payload}){
    //yield put(uiLoading.loadingSuccess())
    let res = yield call(AddStudentLeaveTemporaty,payload.item)
    //console.log(res)
  //  if(res.data.result==='success'&&res.data.data===false){ 
     //  yield put(uiLoading.loadingFail())
  //   }
   // if(res.data.result==='success'&&res.data.data!==false){ 
   //   yield put(uiLoading.loadingFail())
      yield put(AddStudentLeaveTemporatyToStore(res.data.data))
    //}
  }

  function* GetStudentLeaveTemporatySaga({payload}){
     // yield put(uiLoading.loadingSuccess())
      let res = yield call(GetStudentLeaveTemporaty,payload.item)
     // if(res.data.result==='success'){ 
     //   yield put(uiLoading.loadingFail())      
        yield put(DispatchStudentLeaveTemporatyToStore(res.data.data))
      //}
  }

  function* UpdateStudentLeaveTemporatySaga({payload}){
      yield put(uiLoading.loadingSuccess())
      let res =   yield call(UpdateStudentLeaveTemporaty,payload.item)
     if(res.data.result==='success'){
       yield put(uiLoading.loadingFail())
       yield put(UpdateStudentLeaveTemporatyAgain(res.data.data))
     }
  }

  function* GetStudentLeaveTemporatyNotByDateNowSaga({payload}){
      yield put(uiLoading.loadingSuccess())
      let res = yield call(GetStudentLeaveTemporatyNotByDateNow,payload.item)
      if(res.data.result==='success'){
        yield put(uiLoading.loadingFail())
        yield put(GetStudentLeaveTemporatyNotByDateNowToStore(res.data.data))
      }
  }

  function* AddPrintReceiptsSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(AddReceipts,payload.item)
    if(res.data.result==='success'){ 
      yield put(uiLoading.loadingFail())
      yield put(DispatchAddReceiptsToStore(res.data.data))
    }else{
      alert('AddPrintReceiptsSaga Error')
    }
  }

  function* GetReceiptsByUserIdIdClassIdYearSchoolSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(GetReceiptsByUserClassYearSchool,payload.item)
    if(res.data.result==='success'){
      yield put(uiLoading.loadingFail())
      yield put(DispatchGetReceiptsToStore(res.data.data))
    }else{
      alert('GetReceiptsByUserIdIdClassIdYearSchoolSaga Error')
    }
  }

  function* UpdateReceiptsSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res= yield call(UpdateReceipts,payload.item)
    if(res.data.result==='success'){
      yield put(uiLoading.loadingFail())
    }else{
      alert('UpdateReceiptsSaga Error')
    }
  }

  function* AddUpdateDailyTuiSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(AddUpdateDailyTuiApi,payload.item)
    if(res.data.result==='success'){      
      yield put(uiLoading.loadingFail())
      yield put(DispatchAddUpdateDailyTuiToStore(res.data.data))
    }else{
      alert('AddUpdateDailyTuiSaga')
    }
  }

  function* GetListUpdateDailyTuiSaga({payload}){
    let res = yield call(GetListUpdateDailyTuiApi,payload.item)
    if(res.data.result==='success'){      
      yield put(uiLoading.loadingFail())
      yield put(DispatchListUpdateDailyTui(res.data.data))
    }else{
      alert('GetListUpdateDailyTuiSaga')
    }
  }

  function* UpdateItemUpdateDailyTuiSaga({payload}){
    let res = yield call(UpdateUpdateDailyTuiApi,payload.item)
    if(res.data.result==='success'){      
      yield put(uiLoading.loadingFail())
    }else{
      alert('UpdateItemUpdateDailyTuiSaga')
    }
  }

function* DeleteBlockClassSaga({payload}){
    yield put(uiLoading.loadingSuccess())
    let res = yield call(DeleteBlockClassApi,payload.item)
    if(res.data.result==='success'){      
      yield put(uiLoading.loadingFail())
    }else{
      alert('Loi kiem tra')
    }
}

function* ManagerDeleteItemStudentInfoSaga({payload}){
     let res = yield call(ManagerDeleteItemStudentInfoApi,payload.item)
     if(res.data.result==='success'){      
      yield put(DispatchAddDeleteItemStudentInfo(res.data.data))
    }
}

function* GetListDeleteItemStudentInfoSaga({payload}){
    let res = yield call(GetListDeleteItemStudentInfoApi,payload.item)
    if(res.data.result==='success'){      
      yield put(DispatchAddDeleteItemStudentInfo(res.data.data))
    }
}

  function* studentSaga(){
    yield takeLatest(GET_LIST_DELETE_ITEM_STUDENT_INFO,GetListDeleteItemStudentInfoSaga)
    yield takeLatest(MANAGER_DELETE_ITEM_STUDENT_INFO,ManagerDeleteItemStudentInfoSaga)
    yield takeLatest(LIST_BLOCK,listStudentSaga)
    yield takeLatest(ADD_BLOCK,addBlockSaga)
    yield takeLatest(EDIT_BLOCK,editBlockSaga)
    yield takeLatest(ADD_CLASS,addClassSaga)
    yield takeLatest(LIST_CLASS,listClassSaga)
    yield takeLatest(EDIT_CLASS,editClassSaga)
    yield takeLatest(ADD_STUDENT,addStudentSaga)
    yield takeLatest(LIST_ALL_STUDENT,listAllStudentSaga)
    yield takeEvery(UPLOAD_FILE_EXCEL,uploadFileExcelSaga)
    yield takeLatest(LIST_ALL_YEAR_SCHOOL,ListYearSchoolSaga)
    yield takeLatest(ADD_YEAR_SCHOOL,addYearSchoolSaga)
    yield takeLatest(EDIT_YEAR_SCHOOL,editYearSchoolSaga)
    yield takeLatest(EDIT_STUDENT_YEAR_SCHOOL,editStudentYearSchoolSaga)
    yield takeLatest(DELETE_STUDENT_YEAR_SCHOOL,deleteStudentSchoolSaga)
    yield takeEvery(DELETE_STUDENT_YEAR_SCHOOL_IN_MANAGER_STUDENT,DeleteStudentYearSchoolInManagerStudentSaga)
    yield takeLatest(DELETE_ITEM_STUDENT_IN_STUDENT_NOW,DeleteItemStudentInStudentNowSaga)
    yield takeLatest(DELETE_CLASS,DeleteClassSaga)
    yield takeLatest(DELETE_BLOCK_CLASS,DeleteBlockClassSaga)
    yield takeLatest(ADD_CHECK_DATE_FOOD,addCheckDateFoodSaga)
    yield takeLatest(LIST_CHECK_DATE_FOOD,listAllCheckDateFoodSaga)
    yield takeLatest(UPDATE_ITEM_STUDENT_CHECK_DATE_FOOD,updateItemStudentCheckDateFoodSaga)  
    yield takeLatest(DISPACTH_DELETE_STUDENT_ARR,deleteStudentNotEatSaga)
    yield takeLatest(DELETE_ITEM_REGIS_SEVEN_DAY,deleteRegisSevenDaySaga)
    yield takeLatest(DELETE_ITEM_CHECK_DATE_FOOD,deleteCheckDateFoodSaga)
    yield takeLatest(DISPATCH_LIST_STUDENT_CLASS_BY_YEAR_SCHOOL_DB_LIST_STUENT_NOW,AddListStudentNowSaga)  
    yield takeLatest(ALL_LIST_STUDENT_NOW,AllListStudentNowSaga)
    yield takeLatest(DISPATCH_SAVE_LIST_STUDENT_NOW_TO_SAGA,SaveListStudentNowToSaga)
    yield takeLatest(DELETE_ITEM_STUDENT_NOW,DeleteItemStudentNowSaga)
    yield takeLatest(ADD_STUDENT_AFTER,AddStudentAfterSaga)
    yield takeLatest(ADD_BUFFET_DB,AddBuffetDbSaga)  
    yield takeLatest(LIST_BUFFET,ListBuffetSaga)
    yield takeLatest(UPDATE_BUFFET_DB,UpdateBuffetDbSaga)
    yield takeLatest(REMOVE_BUFFET,RemoveBuffetSaga)
    yield takeLatest(UPDATE_LIST_STUDENT,UpdateListStudentSaga)
    yield takeLatest(UPDATE_ALERT_SUCCESS_LOGOUT,UpdateAlertSuccessLogoutSaga)
    yield takeLatest(DELETE_STUDENT_NOW,DeleteStudentNowSaga)
    yield takeLatest(STUDENT_LEAVE_TEMPORATY,StudentLeaveTemporatySaga)
    yield takeLatest(GET_STUDENT_LEAVE_TEMPORATY,GetStudentLeaveTemporatySaga)
    yield takeLatest(UPDATE_STUDENT_LEAVE_TEMPORATY,UpdateStudentLeaveTemporatySaga)
    yield takeLatest(GET_STUDENT_LEAVE_TEMPORATY_NOT_BY_DATE_NOW,GetStudentLeaveTemporatyNotByDateNowSaga)
    yield takeLatest(ADD_PRINT_RECEIPTS,AddPrintReceiptsSaga)
    yield takeLatest(GET_RECEIPTS_BY_USERID_IDCLASS_IDYEARSCHOOL,GetReceiptsByUserIdIdClassIdYearSchoolSaga)
    yield takeLatest(UPDATE_RECEIPTS,UpdateReceiptsSaga)
    yield takeLatest(ADD_UPDATE_DAILY_TUI,AddUpdateDailyTuiSaga)
    yield takeLatest(GET_LIST_UPDATE_DAILY_TUI,GetListUpdateDailyTuiSaga)
    yield takeLatest(UPDATE_ITEM_UPDATE_DAILY_TUI,UpdateItemUpdateDailyTuiSaga)
    yield takeLatest(DISPATCH_SAO_CHEP_TINH_AN,DispatchSaoChepTinhAnSaga)
  }

export default studentSaga