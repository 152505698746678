import {showMMYY} from '../../../helper/dateTime/handleDMY'
import {changeUpperCase}from '../../../helper/fnAllways'
import _ from 'lodash'
export const handleResultClass =(ListClass,itemApproval,ListStudent,DateInput)=>{   
    if(ListStudent!==undefined&&ListStudent.length!==0){
      let findNameClass = ListClass.filter(v=>v._id===itemApproval.idClass)
      let findListStudentCurrent = ListStudent.filter(v=>showMMYY(v.dateNow)===showMMYY(DateInput)&&
                                                      v.idClass===findNameClass[0]._id
      )
      if(findListStudentCurrent!==undefined&&findListStudentCurrent.length!==0){
        let findListStuNotEat = FindListStuNotEat(findListStudentCurrent[0].listStudentsNow,itemApproval.checkDayFood.nameStudentsNotEat)
        if(findNameClass!==undefined&&findListStuNotEat!==undefined){
           return {
              findNameClass,findListStuNotEat
         }         
        } 
      }      
    }    
}

export const FindListStuNotEat =(arr,arrValue)=>{ 
          let newArr = arrValue.map(v=>{
          let item={
            idStudent:v
          }
          return item
        })
        let arrStu = newArr.map(item=>{
              let index = arr.findIndex(value=>value.idStudent===item.idStudent)
              if(index!==-1){ // code bi sua :index >=0
                return{
                  ...item,
                  ...arr[index]
                }
              }else{
                return {...item}
              }
        })
       return arrStu.map(v=>changeUpperCase(v.fullName))       
}

export const CheckDayFoodArr=(arr)=>{
    return arr.map(v=>{
      let item={
        idStudent:v
      }
      return item
    })
}

export const ItemCheckDayFood=(item)=>{
  let result={
      dateNow:item.dateNow,
      idBlockClass:item.idBlockClass,
      idClass:item.idClass,
      idMenuWeekLy:item.idMenuWeekLy,
      idYearSchool:item.idYearSchool,
      nameStudentNotEat:item.nameStudentNotEat,
      students:[],
      userId:item.userId
  }
  return result
}