import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {useDispatch} from 'react-redux'
import { CREATE_APP_ZALO_ACCESS_TOKEN } from '../NotFoundPage/contant/Zalo'

export default function Index({open,handleClose}) {
        const dispatch                                  = useDispatch()        
        const [nameApp,setNameApp]                      = useState(null)
        const [domain,setDomain]                        = useState(null)
        const [callBackUrlDomain,setCallBackUrlDomain]  = useState(null)
        const [app_id,setAppid]             = useState(null)
        const [secret_key,setSecretkey]     = useState(null)
        const [access_token,setAccessToken] = useState(null)
        const [refresh_token,setRefreshToken] = useState(null)
        const [urlRequestAccessToken,setUrlRequestAccessToken] = useState(null)
        
        const CreateApp=()=>{
            let result={
                urlRequestAccessToken,
                nameApp,
                domain,
                callBackUrlDomain,
                app_id,
                secret_key,
                access_token,
                refresh_token
            }
            if(result.urlRequestAccessToken===null||result.nameApp===null||result.domain===null||result.callBackUrlDomain===null||result.app_id===null||result.secret_key===null||result.access_token===null||result.refresh_token===null) {
                alert('Dữ liệu Thiếu! nhập lại')
                return ()=>{}
            }
            dispatch({type:CREATE_APP_ZALO_ACCESS_TOKEN,payload:{item:result}})
            alert('Thêm Thành công')
            handleClose()
            return ()=>{}
        }
  return (
    <div>      
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Nhập Thông Tin Ứng Dụng Zalo Access Token V4</DialogTitle>
        <DialogContent>         

        <TextField
            onChange={(e)=>setUrlRequestAccessToken(e.target.value)}
            margin="dense"
            label="UrlRequestAccessToken"
            type="text"
            fullWidth
            size="small"
          />  
          <TextField
            onChange={(e)=>setDomain(e.target.value)}
            margin="dense"
            label="Domain"
            type="text"
            fullWidth
            size="small"
          />  

          <TextField
            onChange={(e)=>setCallBackUrlDomain(e.target.value)}
            margin="dense"
            label="CallBackDomain"
            type="text"
            fullWidth
            size="small"
          />  

          <TextField
            onChange={(e)=>setNameApp(e.target.value)}
            margin="dense"
            label="Tên Ứng Dụng"
            type="text"
            fullWidth
            size="small"
          />
          <TextField
            onChange={(e)=>setAppid(e.target.value)}
            margin="dense"
            label="app_id"
            type="text"
            fullWidth
            size="small"
          />
           <TextField
            onChange={(e)=>setSecretkey(e.target.value)}
            margin="dense"
            label="secret_key"
            type="text"
            fullWidth
            size="small"          />
           <TextField
            onChange={(e)=>setAccessToken(e.target.value)}
            margin="dense"
            label="access_token"
            type="text"
            fullWidth
            size="small"
          />
           <TextField
            onChange={(e)=>setRefreshToken(e.target.value)}
            margin="dense"
            label="refresh_token"
            type="text"
            fullWidth
            size="small"
          />


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={()=>CreateApp()} color="primary">
            Tạo Mới
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
