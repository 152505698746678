import {DISPATCH_ACCESS_TOKEN_TO_STORE,OPEN_UPDATE_APP_ZALO_ACCESS_TOKEN,CLOSE_UPDATE_APP_ZALO_ACCESS_TOKEN, UPDATE_APP_ZALO_ACCESS_TOKEN} from '../contant/Zalo'
import {updateArrById} from '../../../../helper/fnAllways'
let initialState={
    ListAccessToken:[],
    ItemToUpdateToken:null,
    OpenUpdate:false
}

const IndexReducer = (state=initialState,action)=>{
    switch (action.type){
        case DISPATCH_ACCESS_TOKEN_TO_STORE:
            return{
                ...state,
                ListAccessToken:action.payload.item
            }
        case OPEN_UPDATE_APP_ZALO_ACCESS_TOKEN:
            return{
                ...state,
                ItemToUpdateToken:action.payload.item,
                OpenUpdate:true
            }
        case CLOSE_UPDATE_APP_ZALO_ACCESS_TOKEN:
            return{
                ...state,
                ItemToUpdateToken:null,
                OpenUpdate:false
            }
        case UPDATE_APP_ZALO_ACCESS_TOKEN:
            return{
                ...state,
                ListAccessToken:updateArrById(state.ListAccessToken,action.payload.item)
            }
            default: return state

    }
}

export default IndexReducer