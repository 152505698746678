import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Table,TableContainer,TableHead,TableRow,TableCell,TableBody, Paper, Checkbox} from '@material-ui/core'
import { useSelector,useDispatch } from 'react-redux';
import { 
  //Check, Clear,
   Visibility } from '@material-ui/icons';
import { ArrStudentSort, sortByNameLast } from '../../../../helper/tableList/tableList';
import { changeUpperCase } from '../../../../helper/fnAllways';
import styled from 'styled-components'
import { DISPATCH_ITEM_STU, UPDATE_ITEM_STU_SEND_MESS } from '../../../contant/Class';
import { 
  //FindValue, 
  FindValueEmail, FindValueTel, UpdateByIdStudentEmail, UpdateByIdStudentTel } from './Fn';

const StyledTableRow = styled(TableRow)`
.MuiTableCell-sizeSmall {
    padding: 0px 0px 0px 0px
    }
`
const useStyles = makeStyles({
  table: {
    maxHeight: 650,
    margin:10
  },
});

//Tên Học Sinh Đăng Ký Gửi Tin Thông Tin Học Sinh
const Index=React.memo(()=> {
  const dispatch           = useDispatch()
  const classes            = useStyles();
  const ItemStuSendMess    = useSelector(state=>state.ManagerAccount.ItemStuSendMess) 
  let{listStudent}         = ItemStuSendMess
    const [index,setIndex] = useState(null)
    const [valueInfoStu,setValueInfoStu] = useState(null)
    const AddInfoStu=(value,i)=>{
            setIndex(i)
            setValueInfoStu(value)
            dispatch({type:DISPATCH_ITEM_STU,payload:{item:{value,number:i,List:ItemStuSendMess}}})
    }    

    const HandleItemRow=(value)=>{
      if(value.nameParent!==null&&value.tel!==null&&value.email!==null)return true
      return false
    }

    const handleChangeEmail=(value)=>{
      let valueEmail = FindValueEmail(value)
      let result =UpdateByIdStudentEmail(ItemStuSendMess,value,valueEmail)
      dispatch({type:UPDATE_ITEM_STU_SEND_MESS,payload:{item:result}})        
    }

    const handleChangeTel=(value)=>{
      let valueTel = FindValueTel(value)
      dispatch({type:UPDATE_ITEM_STU_SEND_MESS,payload:{item:UpdateByIdStudentTel(ItemStuSendMess,value,valueTel)}})        
    }

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table  stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <StyledTableRow>            
            <TableCell align="left">Stt</TableCell>
            <TableCell align="left">Tên Học Sinh</TableCell>
            <TableCell align="center">Thông Tin Học Sinh</TableCell>
            <TableCell align="center">Đăng Ký Gửi Tin</TableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>          
            {sortByNameLast(ArrStudentSort(listStudent)).map((v,i)=>{
                return(
                    <StyledTableRow key={i} hover style={{background:index===i?"#e67e22":""}}>              
                            <TableCell align="left">{i+1}</TableCell>
                            <TableCell align="left">{changeUpperCase(v.fullName)}</TableCell>
                            <TableCell align="center">
                              <Visibility style={{color:HandleItemRow(v)?'green':'orange',cursor:'pointer'}} onClick={()=>AddInfoStu(v,i)}/>
                            </TableCell>
                            <TableCell align="center">
                                {HandleItemRow(v)&&
                                      <>
                                        Email <Checkbox label='Email' defaultChecked={v.sendEmail} onChange={()=>handleChangeEmail(v)} color="primary" />&nbsp;&nbsp;
                                        Tel <Checkbox label='Tel'     defaultChecked={v.sendSms}   onChange={()=>handleChangeTel(v)}  color="primary" />
                                      </>
                                }                           
                            </TableCell>
                            {/* <TableCell align="center"><Check style={{color:'green'}}/>&nbsp;&nbsp;<Clear style={{color:'red'}}/></TableCell> */}
                    </StyledTableRow>    
                )                    
            })}                    
        </TableBody>
      </Table>
    </TableContainer>
  );
})

export default Index
