import {put,call, takeLatest} from 'redux-saga/effects'
import {push} from 'connected-react-router'
import * as TypeAuth from '../../contant/Gov/softFood'
import * as TypeAuthRedirect from '../../contant/auth'
import { AddItemListSoftPriceApi, GetListUserPaymentApi, UpdateItemListSoftPriceApi, } from '../../api-services/auth'
import {DispatchGetListUserThanhToanToStore} from '../../actions/Gov/softFood'
import { ADD_ITEM_LIST_SOFT_PRICE, UPDATE_ITEM_LIST_SOFT_PRICE } from '../../contant/Gov/login'
import { DispatchListSoftPriceToStore } from '../../actions/auth'


function* activeSoftFoodFail(){
  yield put(push(TypeAuthRedirect.REDIRECT_LOGIN_USER_CUS_NN_SUCCESS))                              
}

function* GetListUserThanhToanSaga(){
   let res = yield call(GetListUserPaymentApi)
   if(res.data.result === 'success'){
       yield put(DispatchGetListUserThanhToanToStore(res.data.data))
   }
}

function* BlockAppPaymentSaga(){
  yield put(push(TypeAuthRedirect.REDIRECT_LOGIN_USER_CUS_NN_SUCCESS)) 
}

function* AddItemListSoftPriceSaga({payload}){
    let res = yield call(AddItemListSoftPriceApi,payload.item)
    if(res.data.result==='success'){
      yield put(DispatchListSoftPriceToStore(res.data.data))
    }
}
   
function* UpdateItemListSoftPriceSaga({payload}){
  let res = yield call(UpdateItemListSoftPriceApi,payload.item)  
  if(res.data.result==='success'){
    yield put(DispatchListSoftPriceToStore(res.data.data))
  }
}

function* ActiveFoodSaga(){ 
  yield takeLatest(TypeAuth.GET_LIST_USER_THANH_TOAN,GetListUserThanhToanSaga)
  yield takeLatest(TypeAuth.ACTIVE_SOFT_FOOD_FAIL, activeSoftFoodFail)  
  yield takeLatest(TypeAuth.BLOCK_APP_PAYMENT,BlockAppPaymentSaga)
  yield takeLatest(ADD_ITEM_LIST_SOFT_PRICE,AddItemListSoftPriceSaga)
  yield takeLatest(UPDATE_ITEM_LIST_SOFT_PRICE,UpdateItemListSoftPriceSaga)
}


export default ActiveFoodSaga