import axiosServices from '../api'
import {API_ENDPOINT} from '../../contant/index'


const urlAddSupplies         = 'supplies/add'
const urlListSupplies        = 'supplies/get'
const urlEditSupplies        = 'supplies/update'




export const AddSupplies = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddSupplies}`,item)
}
export const ListSupplies = (id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlListSupplies}`,id)
}
export const EditSupplies = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlEditSupplies}`,item)
}


