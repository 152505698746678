import React from 'react';
import {Button,Dialog,DialogActions,DialogContent,Typography} from '@material-ui/core';
import {useStyles} from './styles'
import {changeUpperCase} from '../../../helper/fnAllways'
import { Alert } from '@material-ui/lab';

const AlertForm =({open,handleClose,value,arrList}) => {
 const classes = useStyles()
  return (
    <div>    
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">        
        <Alert severity="error">         
              <Typography className={classes.colorText}>{value}</Typography>                 
        </Alert>
        <DialogContent>
              {arrList.map((v,i)=>{
                return <Typography key={i}>{changeUpperCase(v)}</Typography>
              })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Thoát
          </Button>         
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default AlertForm