import axiosServices from '../../../api-services/api'
import {API_ENDPOINT} from '../../../contant/index'

const urlService = 'adminTypeOfService'
const urlApp     = 'adminApp'
const urlAppActive = 'admin/appActive'
const urlAppActiveGetById = 'admin/appActiveById'
//type of service
export const addTypeOfService =(data)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlService}`,data)
}

export const listTypeOfService =()=>{
    return axiosServices.get(`${API_ENDPOINT}/${urlService}`)
}

export const editTypeOfService=(data)=>{
    return axiosServices.put(`${API_ENDPOINT}/${urlService}`,data)
}

export const deleteTypeOfService=(id)=>{
  return axiosServices.delete(`${API_ENDPOINT}/${urlService}`,id)
}
//type of service

//APP
  export const addApp =(data)=>{
      return axiosServices.post(`${API_ENDPOINT}/${urlApp}`,data)
  }
  export const listApp=()=>{
      return axiosServices.get(`${API_ENDPOINT}/${urlApp}`)
  }
  export const editApp=(data)=>{
      return axiosServices.put(`${API_ENDPOINT}/${urlApp}`,data)
  }
  export const deleteApp=(id)=>{
      return axiosServices.delete(`${API_ENDPOINT}/${urlApp}`,id)
  }
//APP

//App Active 

export const AddAppActive =(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAppActive}`,item)
}

export const ListAppActive=()=>{
    return axiosServices.get(`${API_ENDPOINT}/${urlAppActive}`)
}

export const UpdateItemAppActive=(item)=>{
    return axiosServices.put(`${API_ENDPOINT}/${urlAppActive}`,item)
}

export const GetAppActiveByUserId=(id)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAppActiveGetById}`,id)
}
