import { changeUpperCase } from '../../../../../helper/fnAllways'
import {LEVEL_STAFF} from '../component/Fn'
import _ from 'lodash'
export const handleArrListStaffInfo =(arrLogin,arrStaff)=>{
      return arrStaff.map(v=>{
      return{
        idStaff:v.idStaff,
        fullName:changeUpperCase(v.infoStaff.fullName),
        department:v.department.name,
        level:findLevelStaff(LEVEL_STAFF,v),
        nameLogin:findNameLogin(arrLogin,v)[0].name,
        status:findNameLogin(arrLogin,v)[0].idGroup===8?false:true,
        _id:v._id
      }
    }) 
} 

const findLevelStaff =(arr,item)=>{
  return (arr.filter(v=>v.value===item.infoStaff.typeOf))[0].name
}

const findLevelStaff_ =(arr,item)=>{
  return (arr.filter(v=>v.value===item.level))[0].name
}
const findNameLogin=(arr,item)=>{
  return (arr.filter(v=>v._id===item.idStaff))
}

export const ItemStaff = (user,staff)=>{
return  {
            idStaff:user._id,
            fullName:changeUpperCase(staff.infoStaff.fullName),
            department:staff.department.name,
            level:findLevelStaff(LEVEL_STAFF,staff),
            nameLogin:user.name,
            status:user.idGroup===8?false:true,
            _id:staff._id
        }
}

export const CheckExistListPaymentStaff=(arr,item)=>{
      return arr.some(v=>v.userId===item.userId&&v.idStaff===item.idStaff)
}

export const ConverItemToUpdateEditStaff=(item)=>{
  return{
    department:item.department,
    fullName:item.nameStaff,
    idStaff:item.idStaff,
    level:findLevelStaff_(LEVEL_STAFF,item),
    nameLogin:item.nameLogin,
    status:item.status,
    _id:item._id,  
  }
}

export const HandleTaskJobInfoDetailForCustomerAfter=(arr)=>{
  let price = arr.map(v=>{
      return v.taskJob.map(vv=>{
        return vv
      })
  })
  let newPrice  = _.flatten(price) 
  let newResult = newPrice.filter(v=>v!==undefined&&v!==null)
  let newAfter  = newResult.map(v=>{
    return v.infoDetailForCustomerAfter
  }) 
   let findNotEmpty = newAfter.filter(v=>v.length!==0)
   return _.flatten(findNotEmpty)
}
