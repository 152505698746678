import { DISPATCH_ADD_CREATE_NEW_CRM_TO_STORE ,DISPATCH_GET_LIST_CRM_TO_STORE,DELETE_CRM_SUCCESS, DISPATCH_ITEM_TYPE_CAP_TO_STORE, UPDATE_ITEM_CRM_SUCCESS} from "../contant/CRM";
import { DeleteItemById, updateArrById } from "../helper/fnAllways";
import { toastSuccess } from "../helper/toastHelper";

const initialState = {
    ItemCrm:null,
    ListCrm:[],
    typeCap:7
}

const IndexReducer = (state=initialState,action)=>{
    switch (action.type) {
        case UPDATE_ITEM_CRM_SUCCESS:
            return{
                ...state,
                ListCrm:updateArrById(state.ListCrm,action.payload.item)
            }
        case DISPATCH_ITEM_TYPE_CAP_TO_STORE:
            return{
                ...state,
                typeCap:action.payload.item
            }
        case DELETE_CRM_SUCCESS:
            toastSuccess('Xoá Thành Công!')
            return{
                ...state,
                ListCrm:DeleteItemById(state.ListCrm,action.payload.item)
            }
        case DISPATCH_ADD_CREATE_NEW_CRM_TO_STORE:
            toastSuccess('Thêm Mới Thành Công')
            return{
                ...state,
                ListCrm:[action.payload.item].concat(state.ListCrm)
            }    
        case DISPATCH_GET_LIST_CRM_TO_STORE:
            return{
                ...state,
                ListCrm:action.payload.item.reverse()            
            }                   
    
        default: return state
    }
}

export default IndexReducer