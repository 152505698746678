import React from 'react';
import {LineIn} from './styleLineIn'
const LineInIndex = React.memo(() => {
    return (
        <LineIn>
            
        </LineIn>
    );
});

export default LineInIndex;
