import { DISPATCH_CREATE_CAN_DO_TO_STORE,DISPATCH_ADD_HOC_SINH_CAN_DO_TO_STORE,ADD_UPLOAD_FILE_HOC_SINH_CAN_DO ,
DISPATCH_LIST_HOC_SINH_CAN_DO_TO_STORE,UPLOAD_FILE_CAN_DO_FAIL,DISPATCH_UPDATE_HOC_SINH_CAN_DO_AGAIN
} from "../contant";

export const DispatchUpdateHocSinhCanDoAgain=(item)=>{
    return{
        type:DISPATCH_UPDATE_HOC_SINH_CAN_DO_AGAIN,
        payload:{item}
    }
}

export const UploadFileCanDoFail =()=>{
    return{
        type:UPLOAD_FILE_CAN_DO_FAIL
    }
}

export const DispatchListHocSinhCanDoToStore=(item)=>{
    return{
        type:DISPATCH_LIST_HOC_SINH_CAN_DO_TO_STORE,
        payload:{item}
    }
}
export const DispatchCreateCanDoToStore = (item)=>{
    return{
        type:DISPATCH_CREATE_CAN_DO_TO_STORE,
        payload:{item}
    }
}

export const AddUploadFileHocSinhCanDo=(item)=>{
    return {
        type:ADD_UPLOAD_FILE_HOC_SINH_CAN_DO,
        payload:{item}
    }
}

export const DispatchAddHocSinhCanDoToStore =(item)=>{
    return{
        type:DISPATCH_ADD_HOC_SINH_CAN_DO_TO_STORE,
        payload:{item}
    }
}