import { toastSuccess } from "../../../../helper/toastHelper"
import { OPEN_ADD_TABLE_AGE,CLOSE_ADD_TABLE_AGE,DISPATCH_ADD_FIRST_TABLE_AGE_TO_STORE,
DISPATCH_ADD_TABLE_AGE_TO_STORE
} from "../contant/tableAge"
import { ListMealAgeFn } from "./fn"
export const  initialState={
        OpenAddTableAge:false,
        ItemTableAge:"",
        ListMealAgeFn:[]

}

const Index =(state=initialState,action)=>{
    switch (action.type) {
        case OPEN_ADD_TABLE_AGE:
            return{
                ...state,
                OpenAddTableAge:true,
            }
        case CLOSE_ADD_TABLE_AGE:
            return{
                ...state,
                OpenAddTableAge:false,
            }           
        case DISPATCH_ADD_FIRST_TABLE_AGE_TO_STORE:
            return{
                ...state,
                ItemTableAge:action.payload.item[0],
                ListMealAgeFn:ListMealAgeFn(action.payload.item[0])
            }
        case DISPATCH_ADD_TABLE_AGE_TO_STORE:
            toastSuccess('cập nhật nhật thành công')    
            return{
                ...state,
                ItemTableAge:action.payload.item,
                ListMealAgeFn:ListMealAgeFn(action.payload.item)
            }
    
        default: return state
    }
}

export default Index