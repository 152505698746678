import axiosServices from '../api'
import {API_ENDPOINT} from '../../contant/index'

const urlAddDateMeal = 'menuWeekly'
const urlListDateMeal = 'menuWeekly'
const urlGetListDateMealByUserId = 'menuWeekly/userId'
const urlUpdateOrAddDateMeal = 'menuWeekly'
const urlAddDateMealDB = 'menuWeekly/userId'

export const addDateMeal = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddDateMeal}`,item)
}

export const listDateMeal = ()=>{
  return axiosServices.get(`${API_ENDPOINT}/${urlListDateMeal}`)
}

export const getListDateMealByUserId = (id)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlGetListDateMealByUserId}`,id)
}

//cap nhat
export const updateDateMeal = (item)=>{
  return axiosServices.put(`${API_ENDPOINT}/${urlUpdateOrAddDateMeal}`,item)
}
//them
export const insertDateMeal = (item)=>{
  return axiosServices.post(`${API_ENDPOINT}/${urlAddDateMealDB}`,item)
}
