import React from 'react'
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useToolbarStyles} from './style'
import BoxDelete from './ModalBox/index'
import EditIcon from '@material-ui/icons/Edit';
import Find from './find/index'

const EnhancedTableToolbar = ({
  numSelected,allId,
  handleDeletePerson,
  open,
  handleClickOpen,
  handleClose,
  editPerson,
  searchFn
  
}) => {  
  const classes = useToolbarStyles()
  return (
    <>
    {open===true &&(<BoxDelete
         open={open} 
         handleClose={handleClose}
         handleDeletePerson={handleDeletePerson} 
         allId={allId}
         handleClickOpen={handleClickOpen}   
    />)}
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >     
      
      {(numSelected > 0 ) ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} Đã chọn
        </Typography>
      ) : (
        
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Danh sách chi tiết          
        </Typography>
        
      )}

      {(numSelected > 0) ? (
      <>
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={()=>handleClickOpen()}>
            <DeleteIcon/>
          </IconButton>                 
        </Tooltip>
          {(numSelected===1)?(
             <Tooltip title="Edit">
              <IconButton aria-label="edit" onClick={()=>editPerson(allId)}>
               <EditIcon />
                 </IconButton>                 
            </Tooltip>
          ):''}
       
     </>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
      {(numSelected!==0)?(''):<Find searchFn={searchFn}/>}      
    </Toolbar>
    </>
  );
}

export default EnhancedTableToolbar